import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '@prism-frontend/services/legacy/api/permissions.service';
import { LoginService } from '@prism-frontend/services/legacy/login.service';
import { Permission } from '@prism-frontend/typedefs/permission';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const canActivatePermission: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean> => {
	const loginService: LoginService = inject(LoginService);
	const permissionsService: PermissionsService = inject(PermissionsService);
	const path: string = state.url.split('/')[1].replace(/\?.*$/, '');

	const linkPermissions: Permission[] = route.data.permissions;
	if (!linkPermissions) {
		throw new Error(`No permissions specified for link at path ${path}`);
	}

	return loginService.userLoadedStream$.pipe(
		map((): boolean => {
			return permissionsService.userCan(linkPermissions);
		})
	);
};
