import { Exclude, plainToClass, Type } from 'class-transformer';
import { IsBoolean, IsInt, IsOptional, IsString, Matches } from 'class-validator';

const dateTimePattern: RegExp = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/;

export class PrismDate {
	public constructor(prismDate: Partial<PrismDate>) {
		return plainToClass(PrismDate, prismDate);
	}
	@IsInt()
	public id: number;

	@IsInt()
	public event_id: number;

	@IsInt()
	public hold_id: number;

	@IsInt()
	public stage_id: number;

	@IsString()
	@Matches(dateTimePattern)
	public date: string;

	@Type((): typeof Boolean => {
		return Boolean;
	})
	@IsBoolean()
	public all_day: boolean = true;

	@IsOptional()
	@IsString()
	@Matches(dateTimePattern)
	public start_time?: string;

	@IsOptional()
	@IsString()
	@Matches(dateTimePattern)
	public end_time?: string;

	@Exclude()
	@IsString()
	@Matches(dateTimePattern)
	public created_at: string;

	@Exclude()
	@IsString()
	@Matches(dateTimePattern)
	public updated_at: string;

	@Exclude()
	@IsString()
	@Matches(dateTimePattern)
	public deleted_at: null;
}
