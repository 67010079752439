/* This module is documented in `docs/feature-gating.md` */
import { Injectable } from '@angular/core';
import { OrgSettingsService } from '@prism-frontend/services/legacy/api/org-settings/org-settings.service';
import { ORG_TYPES } from '@prism-frontend/typedefs/enums/organization-types';
import _ from 'lodash';

export const ORGANIZATION_TYPES: typeof ORG_TYPES = ORG_TYPES;

@Injectable({
	providedIn: 'root',
})
export class OrganizationTypeGateService {
	public constructor(private orgSettingsSvc: OrgSettingsService) {}

	public get isAgent(): boolean {
		return _.some(this.orgSettingsSvc.orgFeatures, ['name', ORGANIZATION_TYPES.IS_AGENT]);
	}

	public get isBroadway(): boolean {
		return _.some(this.orgSettingsSvc.orgFeatures, ['name', ORGANIZATION_TYPES.IS_BROADWAY]);
	}

	public get isNotAgent(): boolean {
		return !this.isAgent;
	}
}
