import { ArtistSearchResult } from '@prism-frontend/typedefs/ArtistSearchResult';
import _ from 'lodash';

export function isArtistOnClientRoster(artist: ArtistSearchResult): boolean {
	return artist.agent_data !== null;
}

export function userCanAccessOnArtistClient(artist: ArtistSearchResult): boolean {
	// BE sometimes send an user_can_access_client field, if this isn't sent
	// the artist options should be enabled.
	// This is never sent for non agents users and for agents,
	// only on those artists that are a client
	if (_.isNil(artist.user_can_access_client)) {
		return false;
	}
	// disable the artist if the user doesn't have access to the artist client
	return !artist.user_can_access_client;
}
