import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { LoginService } from '@prism-frontend/services/legacy/login.service';
import { Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

export const canActivateLoggedIn: CanActivateFn = (
	_next: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean> => {
	const loginService: LoginService = inject(LoginService);
	return loginService.isLoggedInStream$.pipe(
		switchMap((isUserLogged: boolean): Observable<boolean> => {
			if (!isUserLogged) {
				loginService.sendToLogin(state.url);
				return of(false);
			}

			return loginService.userLoadedStream$.pipe(
				map((isUsersDataLoaded: boolean): boolean => {
					return isUsersDataLoaded;
				})
			);
		})
	);
};
