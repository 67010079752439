import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { LoginService } from '@prism-frontend/services/legacy/login.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const canActivateLoggedOut: CanActivateFn = (_route: ActivatedRouteSnapshot): Observable<boolean> => {
	const loginService: LoginService = inject(LoginService);
	return loginService.isLoggedInStream$.pipe(
		map((value: boolean): boolean => {
			if (value) {
				loginService.navigateHomeAndReload(false);
			}
			return !value;
		})
	);
};
