import { CustomFieldBackend } from '@prism-frontend/entities/custom-fields/custom-fields-typedefs';
import { EnabledChipConfiguration } from '@prism-frontend/entities/email-templates/data-interpolation/EnabledChipConfiguration';
import { fetchOptionsForFieldSandbox } from '@prism-frontend/entities/email-templates/data-interpolation/fetchOptionsForFieldSandbox';
import { InterpolatedDataOption } from '@prism-frontend/entities/email-templates/data-interpolation/InterpolatedDataOption';
import { findAdditionalRevenueByName } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/findAdditionalRevenueByName';
import { findAndFormatContactsWithRole } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/findAndFormatContactsWithRole';
import { findFixedCostByName } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/findCostByName';
import { findFixedCostGroupByName } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/findCostGroupByName';
import { getDaysUntilEventFunctionalChipFactory } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/getDaysUntilEvent';

/**
 * This function fetches all chip options for use in field sandbox when we are
 * creating or editing advanced computed custom fields. In this case, we filter out any
 * array or object fields so that formula definition is more straightforward.
 * TODO PRSM-XXXX we do not currently do permissions checking on these fields
 * (logic currently lives in fetchOptionsForEmailTemplateType)
 * take event as param for permissions checking
 * @param customFields
 * @returns
 */
export function fetchOptionsForComputedCustomFields(customFields: CustomFieldBackend[]): EnabledChipConfiguration {
	return {
		// TODO PRSM-XXXX use a different list here that we can show to users
		dataChips: fetchOptionsForFieldSandbox(customFields, true).dataChips.filter(
			(chip: InterpolatedDataOption): boolean => {
				// Filter out objects and arrays
				return chip.identifier.indexOf('.*') === -1;
			}
		),
		functionalChips: [
			findAdditionalRevenueByName,
			findFixedCostGroupByName,
			getDaysUntilEventFunctionalChipFactory(false),
		],
	};
}

export function fetchOptionsForComputedEditableCustomFields(): EnabledChipConfiguration {
	return {
		dataChips: [],
		functionalChips: [findFixedCostByName],
	};
}
/**
 * This function fetches all chip options for use in field sandbox when we are
 * creating or editing advanced text custom fields. Contrary to fetchOptionsForComputedCustomFields,
 * we allow numbers or text properties. We still filter arrays and objects.
 * TODO PRSM-XXXX we do not currently do permissions checking on these fields
 * (logic currently lives in fetchOptionsForEmailTemplateType)
 * take event as param for permissions checking
 * @param customFields
 * @returns
 */
export function fetchOptionsForTemplatedStringCustomFields(
	customFields: CustomFieldBackend[]
): EnabledChipConfiguration {
	return {
		// TODO PRSM-XXXX use a different list here that we can show to users
		dataChips: fetchOptionsForFieldSandbox(customFields, false).dataChips.filter(
			(chip: InterpolatedDataOption): boolean => {
				// Filter out objects and arrays
				return chip.identifier.indexOf('.*') === -1;
			}
		),
		functionalChips: [
			findAndFormatContactsWithRole,
			findFixedCostGroupByName,
			getDaysUntilEventFunctionalChipFactory(true),
		],
	};
}
