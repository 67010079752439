import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PrismEvent } from '@prism-frontend/typedefs/event';
import { WithAutoUnsubscribeComponent } from '@prism-frontend/utils/static/auto-unsubscribe';
import _ from 'lodash';
import { SegmentService } from 'ngx-segment-analytics';

const SEGMENT_JUMP_TO_EVENT_NAVIGATION: string = 'jump-to-event-navigation';
export const SEGMENT_JUMP_TO_EVENT_OPEN: string = 'jump-to-event-open';
export const SEGMENT_JUMP_TO_EVENT_CLOSE: string = 'jump-to-event-close';
@Component({
	template: `
		<h4>Jump to Event...</h4>
		<event-picker (selectedEventChange)="handleEventSelected($event)" [multiple]="false"> </event-picker>
	`,
	styleUrls: ['./jump-to-event.component.scss'],
})
export class JumpToEventComponent extends WithAutoUnsubscribeComponent {
	public constructor(
		private router: Router,
		public matDialogRef: MatDialogRef<JumpToEventComponent>,
		public segment: SegmentService
	) {
		super();
	}

	public handleEventSelected(prismEvent: PrismEvent[]): void {
		const theEvent: PrismEvent = _.first(prismEvent);
		if (!theEvent) {
			// There is a chance the component could emit an empty array
			// in the case where it was cleared, so return early and do
			// not navigate in that case.
			// In practice, it is unlikely this code will execute because
			// the moment an event is selected the page nagivates and the
			// dialog closes, and clicking the clear button if no event
			// is yet selected does not actually result in an emit of an empty
			// array.
			return;
		}
		this.segment.track(SEGMENT_JUMP_TO_EVENT_NAVIGATION, {
			eventId: theEvent.id,
		});
		this.router.navigate([`/event/`, theEvent.id]);
		this.matDialogRef.close();
	}
}
