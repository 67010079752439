import { Stage } from '@prism-frontend/typedefs/stage';
import { Venue } from '@prism-frontend/typedefs/venue';
import _ from 'lodash';

/**
 * Filters the input stages based on their 'active' status or if their ids are included in stageIdsThatAlwaysNeedToBeIncluded set.
 * @param {Stage[]} stages - An array of stage objects to be filtered.
 * @param {boolean} includeInactive - A flag indicating whether inactive stages should be included.
 * @param {Set<number>} stageIdsThatAlwaysNeedToBeIncluded - A set of stage ids that should be included regardless of their active status.
 * @return {Stage[]} - A new array containing the filtered stages.
 */
function filterStages(
	stages: Stage[],
	includeInactive: boolean,
	stageIdsThatAlwaysNeedToBeIncluded: Set<number> = new Set<number>()
): Stage[] {
	return _.filter(stages, (stage: Stage): boolean => {
		// Returns true if 'includeInactive' is true OR stage active is true OR stage id is in 'stageIdsThatAlwaysNeedToBeIncluded'
		return includeInactive || stage.active || stageIdsThatAlwaysNeedToBeIncluded.has(stage.id);
	});
}

// /**
//  * Filters the input venues based on their 'active' status, stage count or if their ids are included in the stageIds set.
//  * @param {Venue[]} venues - An array of venue objects to be filtered.
//  * @param {boolean} includeInactive - A flag indicating whether inactive venues should be included.
//  * @param {Set<number>} stageIdsThatAlwaysNeedToBeIncluded - A set of stage ids that need to be included regardless of their active status.
//  * @return {Venue[]} - A new array containing the filtered venues.
//  */
export function filterIrrelevantVenues(
	venues: Venue[],
	includeInactive: boolean,
	stageIdsThatAlwaysNeedToBeIncluded: Set<number> = new Set<number>()
): Venue[] {
	return (
		venues
			// Remove inactive stages from all of the venues, if so desired
			.map((venue: Venue): Venue => {
				// Return a new venue but with filtered stages
				return new Venue({
					...venue,
					stages: filterStages(venue.stages, includeInactive, stageIdsThatAlwaysNeedToBeIncluded),
				});
			})
			// Filter out any stageless venues
			.filter((venue: Venue): boolean => {
				return venue.stages.length > 0; // returns true if the venue has at least one stage
			})
			// Filter out any inactive venues, if so desired
			.filter((venue: Venue): boolean => {
				// By default, include if venue is active or the 'includeInactive' is true
				let include: boolean = includeInactive || venue.active;
				// If the venue is still not included and there are some stages that always need to be included
				if (!include && stageIdsThatAlwaysNeedToBeIncluded.size) {
					// Check if the venue has at least one stage that is in 'stageIdsThatAlwaysNeedToBeIncluded'
					include = venue.stages.some((stage: Stage): boolean => {
						return stageIdsThatAlwaysNeedToBeIncluded.has(stage.id);
					});
				}

				return include;
			})
	);
}
