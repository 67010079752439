import { ApiService, instantiateApiResponse } from '@prism-frontend/services/legacy/api.service';
import { PaginatedData, PaginationDetails } from '@prism-frontend/typedefs/graphql/PaginationDetails';
import { Debug, getDebug } from '@prism-frontend/utils/static/getDebug';
import { DefaultOnPageCallback, OnPageCallback } from '@prism-frontend/utils/static/query-all-entities';
import { Timer, createTimer } from '@prism-frontend/utils/static/timer';
import PQueue from 'p-queue';
const debug: Debug = getDebug('fetch-all-pages-from-endpoint');

type PaginationPostParams = Pick<PaginationDetails, 'per_page'> & { page: number };

export const instantiateApiResponseTimer: Timer = createTimer(`fetch-all-pages-from-endpoint:instantiate-api-response`);
let callCounter: number = 0;

function getPostParams(perPage: number, forPage: number): PaginationPostParams {
	return {
		per_page: perPage,
		page: forPage,
	};
}

// TODO PRSM-9755 rework this API to use GET instead of POST
async function getResultsForPageViaPost<T>(
	endpoint: string | string[],
	apiService: ApiService,
	perPage: number,
	page: number
): Promise<PaginatedData<T>> {
	debug('getting results for page', page, endpoint);
	const nextPage: PaginatedData<T> = (await apiService.postP<PaginationPostParams, T>(
		[...endpoint],
		getPostParams(perPage, page)
	)) as unknown as PaginatedData<T>;

	return nextPage;
}

/**
 * Fetches all pages from a paginated endpoint, and returns the results as an array
 * @param endpoint endpoint to fetch the data from
 * @param apiService instance of the ApiService, which is used to make the request
 * @param EntityClassConstructor @optional constructor for the entity class,
 * 		which is used to instantiate the response. if omitted, nothing in the response will
 * 		be instantiated
 * @param totalNumEntities total number of entities to fetch. If this is not defined,
 * 		the function will fetch the first page to get the total number of entities. Once we have this
 * 		number, we can fetch all the pages concurrently using concurrency. If not passed, the first
 * 		page will be fetched before any concurrent requests go out
 * @param pageSize number of entities to fetch per page
 * @param concurrency number of concurrent requests to make
 * @param onPageCallback callback to call when a page of results is fetched. you can
 * 		use this to update a progress bar or something
 * @returns
 */
export async function fetchAllPagesFromEndpoint<QueriedObjectType>(
	endpoint: string | string[],
	apiService: ApiService,
	EntityClassConstructor: Function | null,
	totalNumEntities: number = undefined,
	pageSize: number = 250,
	concurrency: number = 5,
	onPageCallback: OnPageCallback<QueriedObjectType> = DefaultOnPageCallback,
	getResultsForPage: (
		endpoint: string | string[],
		apiService: ApiService,
		perPage: number,
		page: number
	) => Promise<PaginatedData<QueriedObjectType>> = getResultsForPageViaPost,
	abortSignal?: AbortSignal
): Promise<QueriedObjectType[]> {
	// set up the abort controller at the top of every request
	// and prevent any more API requests from going out if the abort signal is aborted
	let wasAborted: boolean = false;
	abortSignal?.addEventListener('abort', (): void => {
		wasAborted = true;
		debug(`signal aborted`, wasAborted);
	});

	const timer: Timer = createTimer(`fetch-all-pages-from-endpoint ${callCounter++}`);
	timer.start();
	debug('concurrency', concurrency);
	debug('page size', pageSize);
	let totalNumPages: number;

	// TODO PRSM-XXXX total should represent the number of objects, not pages

	// now iterate over all pages and get all the events
	let runningEntityList: QueriedObjectType[] = [];
	async function handlePageResults(pageResults: PaginatedData<QueriedObjectType>): Promise<void> {
		let entities: QueriedObjectType[] = pageResults.data;
		if (EntityClassConstructor) {
			instantiateApiResponseTimer.start();
			entities = (await instantiateApiResponse<QueriedObjectType[]>(EntityClassConstructor, pageResults.data))
				.data;
			instantiateApiResponseTimer.stop();
		}

		const percentage: number = totalNumEntities === 0 ? 0 : (runningEntityList.length / totalNumEntities) * 100;

		onPageCallback({
			data: entities,
			total: totalNumEntities,
			runningCount: runningEntityList.length,
			percentage,
			totalNumberPages: totalNumPages,
			pageNumber: pageResults.current_page,
		});
		runningEntityList = runningEntityList.concat(entities);
		debug('queryAllEntities running list length', runningEntityList.length);
	}

	let firstPageNumber: number = 1;

	if (totalNumEntities === undefined) {
		const firstPage: PaginatedData<QueriedObjectType> = await getResultsForPage(endpoint, apiService, pageSize, 1);
		totalNumEntities = firstPage.total;
		totalNumPages = Math.ceil(totalNumEntities / pageSize);
		await handlePageResults(firstPage);
		firstPageNumber++;
	} else {
		totalNumPages = Math.ceil(totalNumEntities / pageSize);
	}

	debug('totalEntityCount', totalNumEntities);
	debug('totalNumPages', totalNumPages);

	const queue: PQueue = new PQueue({ concurrency });

	for (let curPage: number = firstPageNumber; curPage <= totalNumPages; curPage++) {
		queue.add(async (): Promise<void> => {
			if (wasAborted) {
				return;
			}

			const pageResults: PaginatedData<QueriedObjectType> = await getResultsForPage(
				endpoint,
				apiService,
				pageSize,
				curPage
			);

			if (wasAborted) {
				return;
			}

			await handlePageResults(pageResults);
		});
	}

	debug('queue size', queue.size);
	await queue.onIdle();
	debug('returning runningEntityList', runningEntityList.length);
	timer.stop();
	debug(timer.getTimerOutput());
	return runningEntityList;
}
