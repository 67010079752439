import { AfterViewInit, Component, Input } from '@angular/core';

@Component({
	templateUrl: './prism-updated-dialog.component.html',
	styleUrls: ['./prism-updated-dialog.component.scss'],
})
export class PrismUpdatedDialogComponent implements AfterViewInit {
	@Input() public countDownTime: number = 5;
	public loading: boolean = true;

	private currentTimeElapsed: number = 0;

	public ngAfterViewInit(): void {
		const interval: number = 64;
		const myInterval: number = window.setInterval((): void => {
			this.currentTimeElapsed = this.currentTimeElapsed + interval;
			if (this.currentTimeElapsed >= this.countDownTime * 1000) {
				window.clearInterval(myInterval);
				this.refreshNow();
			}
		}, interval);
	}

	public refreshNow(): void {
		window.location.reload();
	}

	public get remainingProgressPercentage(): number {
		// This has to be converted to a number out of 100.
		return this.currentTimeElapsed / (this.countDownTime * 10);
	}

	public get refreshSecondsRemaining(): number {
		// A nice formatting for the user facing number of seconds left.
		return this.countDownTime - Math.floor(this.currentTimeElapsed / 1000);
	}
}
