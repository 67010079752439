import { Artist } from '@prism-frontend/entities/artists/artist-typedefs';
import { RunOfShow } from '@prism-frontend/entities/run-of-show/run-of-show';
import { AgentUser, AgentUserOnEvent } from '@prism-frontend/typedefs/AgentUser';
import { ArtistSearchResult } from '@prism-frontend/typedefs/ArtistSearchResult';
import { BalancePayment } from '@prism-frontend/typedefs/balance-payment';
import { Bonus } from '@prism-frontend/typedefs/bonus';
import { Deposit, depositTotal, getDepositSummary } from '@prism-frontend/typedefs/deposit';
import { getCustomExchangeRate } from '@prism-frontend/typedefs/determine-exchange-rate';
import { EMSTalentPayout } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { AfterType } from '@prism-frontend/typedefs/enums/AfterType';
import { BonusTypes } from '@prism-frontend/typedefs/enums/BonusTypes';
import { CostCalc } from '@prism-frontend/typedefs/enums/calc';
import { DealTypes } from '@prism-frontend/typedefs/enums/deal-types';
import { DealStatus } from '@prism-frontend/typedefs/enums/DealStatus';
import { PaymentStatusBackend } from '@prism-frontend/typedefs/enums/PaymentStatus';
import { SoldBy } from '@prism-frontend/typedefs/enums/SoldBy';
import { CalculateTotalArtistPayoutOptionsArgs } from '@prism-frontend/typedefs/event-method-api-params';
import { EventPropsForTalentGuarantee } from '@prism-frontend/typedefs/EventPropsForTalentGuarantee';
import { PayoutAdjustment } from '@prism-frontend/typedefs/payoutAdjustment';
import { resolveTalentImageUrl } from '@prism-frontend/typedefs/resolveTalentImageUrl';
import { RetroactiveBonus } from '@prism-frontend/typedefs/retroactiveBonus';
import { Settlement } from '@prism-frontend/typedefs/settlement';
import { Talent } from '@prism-frontend/typedefs/talent';
import { isArtistOnClientRoster } from '@prism-frontend/utils/static/artistSearchResultsUtils';
import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { castToNumber } from '@prism-frontend/utils/transformers/castToNumber';
import { castToUTCDate } from '@prism-frontend/utils/transformers/castToUTCDate';
import { plainToClass, Transform, Type } from 'class-transformer';
import { IsBoolean, IsEnum, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';
import * as _ from 'lodash';
import { isNil, isNumber } from 'lodash';
export interface TalentInfo {
	talent: TalentData;
	payout: EMSTalentPayout;
}
export class TalentData {
	/**
	 * Returns the hard_merch_artist_percentage and who sells it either Artist or Venue.
	 * This function doesn't take into account the value of the hard_merch_artist_percentage.
	 */
	public get hardMerchRate(): string {
		if (!this.has_merch) {
			return '';
		}
		return `${this.hard_merch_artist_percentage}% hard (${this.hard_merch_sold_by} sells)`;
	}

	/**
	 * Returns the soft_merch_artist_percentage and who sells it either Artist or Venue.
	 * This function doesn't take into account the value of the soft_merch_artist_percentage.
	 */
	public get softMerchRate(): string {
		if (!this.has_merch) {
			return '';
		}
		return `${this.soft_merch_artist_percentage}% soft (${this.soft_merch_sold_by} sells)`;
	}

	/**
	 * Takes into account the values of (hard/soft)_merch_artist_percentage,
	 * omitting either or both if they have a value of zero '0'.
	 */
	public get artistMerchRate(): string {
		const filteredArtistMerchRate: string[] = [];
		if (this.soft_merch_artist_percentage !== 0) {
			filteredArtistMerchRate.push(this.softMerchRate);
		}
		filteredArtistMerchRate.push(this.soft_merch_notes);
		if (this.hard_merch_artist_percentage !== 0) {
			filteredArtistMerchRate.push(this.hardMerchRate);
		}
		filteredArtistMerchRate.push(this.hard_merch_notes, this.general_merch_notes);
		return filteredArtistMerchRate
			.filter((artistMerchRate: string): boolean => {
				return !!artistMerchRate;
			})
			.join('\n');
	}

	public constructor(talent?: Partial<TalentData>) {
		this.payout_adjustments = [];
		this.set_times = [];
		return plainToClass(TalentData, talent);
	}

	public get isForTour(): boolean {
		return !!this.tour_id;
	}

	public get hasTalent(): boolean {
		return this.talent !== null;
	}

	public get talentName(): string {
		if (!this.talent) {
			return 'TBD';
		}
		return this.talent.name;
	}

	public get isAgentDeal(): boolean {
		return !!this.talent_agent_id;
	}

	public get talentImage(): string {
		return resolveTalentImageUrl(this.talent?.images);
	}

	public get contributesToSplitPoint(): boolean {
		return this.headliner || (!this.headliner && this.include_in_deal);
	}

	/**
	 * a sum of all the adjustments on this talent deal where affects_external_settlement_only is true
	 */
	public get totalAdjustmentsTowardSettlement(): number {
		return _.sumBy(
			this.payout_adjustments.filter((adjustment: PayoutAdjustment): boolean => {
				return adjustment.affects_external_settlement_only;
			}),
			'amount'
		);
	}

	/**
	 * a sum of all the adjustments on this talent deal where affects_artist_earnings is true
	 */
	public get totalAdjustmentsTowardArtistEarnings(): number {
		return _.sumBy(
			this.payout_adjustments.filter((adjustment: PayoutAdjustment): boolean => {
				return adjustment.affects_artist_earnings;
			}),
			'amount'
		);
	}

	/**
	 * a sum of all the adjustments on this talent deal where affects_withholding is true
	 * but only if this talent deal has withholding turned on
	 */
	public get totalAdjustmentsTowardWithholding(): number {
		// only account for withholding-specific adjustments if the talent
		// deal has adjustments turned on
		if (!this.has_withholding) {
			return 0;
		}

		return _.sumBy(
			this.payout_adjustments.filter((adjustment: PayoutAdjustment): boolean => {
				return adjustment.affects_withholding;
			}),
			'amount'
		);
	}

	/**
	 * a sum of all the adjustments on this talent deal where affects_split_point is true
	 */
	public get totalAdjustmentsTowardSplitPoint(): number {
		return _.sumBy(
			this.payout_adjustments.filter((adjustment: PayoutAdjustment): boolean => {
				return adjustment.affects_split_point;
			}),
			'amount'
		);
	}

	/**
	 * @returns true if the given TalentData has a valid value set for `artists_earnings_override`,
	 * false otherwise
	 */
	public get hasArtistEarningsOverride(): boolean {
		return isNumber(this.artist_earnings_override);
	}

	public get isVerseNetGross(): boolean {
		return this.deal_type === DealTypes.Verse && this.bonuses[0]?.type === BonusTypes.NetGross;
	}

	@IsNumber() public id: number = null;

	@IsString()
	public url_hash: string = '';

	@Type((): typeof Bonus => {
		return Bonus;
	})
	@ValidateNested()
	public bonuses: Bonus[] = [];

	@Type((): typeof Deposit => {
		return Deposit;
	})
	@ValidateNested()
	public deposits: Deposit[] = [];

	@Type((): typeof PayoutAdjustment => {
		return PayoutAdjustment;
	})
	@ValidateNested()
	public payout_adjustments: PayoutAdjustment[] = [];

	@Type((): typeof BalancePayment => {
		return BalancePayment;
	})
	@ValidateNested()
	public balance_payments: BalancePayment[] = [];

	@Type((): typeof RunOfShow => {
		return RunOfShow;
	})
	@ValidateNested()
	public set_times: RunOfShow[] = [];

	@Type((): typeof Talent => {
		return Talent;
	})
	@ValidateNested()
	@IsOptional()
	public talent: Talent | null = null;

	// differs from id. this is the id of the logical talent entity. typically this
	// maps to a spotify artist
	@IsNumber() public talent_id: number | null = null;

	/**
	 * start agent-related fields
	 */
	@Type((): typeof Artist => {
		return Artist;
	})
	@ValidateNested()
	@IsOptional()
	public talent_agent: Artist | null = null;

	/**
	 * Despite the confusing name, this id represents the id for the talentdata's corresponding artist
	 * in the artist roster, if any. Always null for non-agency orgs.
	 * */
	@IsNumber() public talent_agent_id: number | null = null;

	@Type((): typeof AgentUserOnEvent => {
		return AgentUserOnEvent;
	})
	@ValidateNested()
	public agents: AgentUserOnEvent[] = [];

	@IsBoolean()
	@Transform(castToBoolean())
	public is_fully_settled: boolean = false;

	/**
	 * allows the user to input a raw number that will be used in the final payout for the artist
	 * helps agents compute agent commission a little cleaner
	 *
	 * this value can be null - indicating no override is present and all talent data event math behaves as
	 * it did previously
	 *
	 * when the number is a valid, non-null number, all talent data event math is usurped and this raw value
	 * is used in all event math for this talent data
	 *
	 * agency only
	 */
	@IsNumber()
	public artist_earnings_override: number | null = null;
	/**
	 * end agent-related fields
	 */

	@IsEnum(DealStatus)
	public deal_status: DealStatus = null;

	/**
	 * These notes are associated with the talent's row on the deal tracker
	 */
	@IsString()
	public deal_tracker_notes: string = '';

	/**
	 * String representing when the deal was last updated
	 */
	@IsString()
	@Transform(castToUTCDate())
	public deal_status_last_updated_at: string = '';

	/**
	 * start withholding fields
	 */
	@IsBoolean()
	@Transform(castToBoolean())
	public has_withholding: boolean = false;

	@IsNumber()
	public withholding_due_days_after: number = 0;

	@IsNumber()
	public withholding_percentage: number = 0;

	@IsEnum(PaymentStatusBackend)
	public withholding_status: PaymentStatusBackend = null;

	@IsString()
	public withholding_notes: string = '';

	@IsNumber()
	public withholding_threshold: number = 0;
	/**
	 * end withholding fields
	 */

	/**
	 * start merch fields
	 */

	@IsBoolean()
	@Transform(castToBoolean())
	public has_merch: boolean = false;

	@IsNumber()
	public hard_merch_artist_percentage: number | null = null;
	@IsEnum(SoldBy)
	public hard_merch_sold_by: SoldBy | null = SoldBy.Artist;
	@IsString()
	public hard_merch_notes: string | null = null;

	@IsNumber()
	public soft_merch_artist_percentage: number | null = null;
	@IsEnum(SoldBy)
	public soft_merch_sold_by: SoldBy | null = SoldBy.Artist;
	@IsString()
	public soft_merch_notes: string | null = null;

	@IsString()
	public general_merch_notes: string | null = null;
	/**
	 * end merch fields
	 */

	@IsBoolean()
	@Transform(castToBoolean())
	public add_costs: boolean = false;

	@IsEnum(DealTypes) public deal_type: DealTypes;

	@IsString() public deal_description: string;

	@IsString() public email: string = '';

	@IsBoolean()
	@Transform(castToBoolean())
	public edit_mode: boolean = true;

	@IsBoolean()
	@Transform(castToBoolean())
	public headliner: boolean = true;

	@IsBoolean()
	@Transform(castToBoolean())
	public include_in_deal: boolean = true;

	//////////////////////////////////////////////////////////////
	// THESE MUST NOT BE INITIALIZED TO '' BECAUSE WE DON'T WANT TO SEND THEM TO THE BACKEND WHEN WE UPDATE THE TALENT DEAL AND THE USER DIDN'T MODIFY THE TERMS.
	/////////////////////////////////////////////////////////////

	@IsString() public offer_terms: string;

	@IsString() public additional_terms: string;

	@IsString() public settlement_terms: string;

	@IsString() public contract_terms: string;

	/////////////////////////////////////////////////////////////

	@IsNumber() public guarantee: number = 0;

	@IsBoolean()
	@Transform(castToBoolean())
	public retroactive_bonus: boolean = false;

	@IsEnum(AfterType) public retroactive_after_type: AfterType;

	@Type((): typeof RetroactiveBonus => {
		return RetroactiveBonus;
	})
	@ValidateNested()
	public retroactive_bonuses: RetroactiveBonus[] = [];

	@Type((): typeof Settlement => {
		return Settlement;
	})
	@ValidateNested()
	public settlement: Settlement;

	@IsString() public set_time: Date | string = new Date();

	@IsNumber()
	@Transform(castToNumber())
	public set_length: number = 0;

	@IsBoolean()
	@Transform(castToBoolean())
	public capped: boolean = false;

	@IsNumber()
	@Transform(castToNumber())
	public cap_amount: number = 0;

	// only relevant when this.isForTour;
	@IsBoolean()
	@Transform(castToBoolean())
	public cross_collateralized: boolean;

	// only present when for a tour
	@IsNumber()
	@IsOptional()
	public parent_id?: number;

	@IsNumber()
	@IsOptional()
	public tour_id?: number;

	@IsOptional()
	@IsString()
	public created_at?: string;

	@IsOptional()
	@IsString()
	public updated_at?: string;

	@IsNumber()
	@IsOptional()
	@Transform(castToNumber(true))
	public copro_overridden_artist_payout: number | null = null;

	@IsOptional()
	@IsString()
	public copro_artist_payout_last_updated_at: string | null = null;

	@IsBoolean()
	@Transform(castToBoolean())
	public copro_artist_payout_hidden: boolean = false;

	@IsBoolean()
	@Transform(castToBoolean())
	public has_due_date: boolean = false;

	@IsString()
	public contract_due_at: string | null = null;

	// only relevant when this.isForTour
	@IsBoolean()
	public has_royalty: boolean | null = null;

	// only relevant when this.isForTour
	@IsNumber()
	public royalty_percentage: number | null = null;

	public static convertAmount(costCalc: CostCalc, eventProps: EventPropsForTalentGuarantee, amount: number): number {
		if (!eventProps.convert_to_usd) {
			return amount;
		}
		if (!eventProps.use_custom_exchange_rates) {
			return amount * eventProps.usd_exchange_rate;
		}

		if (getCustomExchangeRate(costCalc) === 'settlement') {
			return amount * eventProps.settled_exchange_rate;
		}
		return amount * eventProps.offer_exchange_rate;
	}

	/**
	 * artist earnings override is an agent-only feature.agents mostly only care about External cost calcs,
	 * so only consider this artist earnings override for the ExternalReported, ExternalActual, and ExternalBudgeted cost calcs
	 * which show up in all PDFs, as well as UI places that we want to consider the override
	 *
	 * @returns true if we want to apply the artist overrides, false otherwise
	 */
	private shouldApplyArtistEarningsOverride(costCalc: CostCalc, forSplitPoint: boolean = false): boolean {
		// apply the earnings only on these cost calcs
		if (costCalc !== CostCalc.Actual && costCalc !== CostCalc.Reported && costCalc !== CostCalc.Budgeted) {
			return false;
		}

		// if we're computing the talent deal for split point, and its a verses deal, then dont honor the override value
		if (forSplitPoint && (this.deal_type === DealTypes.Verse || this.deal_type === DealTypes.Plus)) {
			return false;
		}

		// apply the override if it exists
		return this.hasArtistEarningsOverride;
	}

	private calculateArtistBonus(
		costCalc: CostCalc,
		eventProps: EventPropsForTalentGuarantee,
		gross: number,
		splitPoint: number,
		ticketCommissionsTotal: number,
		ticketsSold: number,
		totalSellable: number,
		royalty: number
	): number {
		const stripBonusTypes: BonusTypes[] = [];

		if (this.deal_type === DealTypes.Flat) {
			return 0;
		}

		// ignore per ticket and flat bonuses for verses deals
		if (this.deal_type === DealTypes.Verse) {
			stripBonusTypes.push(BonusTypes.PerTicket);
			stripBonusTypes.push(BonusTypes.Flat);
		}

		// ignore flat bonuses for door deals
		if (this.deal_type === DealTypes.DoorDeal) {
			stripBonusTypes.push(BonusTypes.Flat);
		}

		const bonusesToConsider: Bonus[] = _.filter(this.bonuses, (bonus: Bonus, idx: number): boolean => {
			if (this.deal_type === DealTypes.DoorDeal) {
				// DoorDeals can not have more than one bonus, so only grab
				// the first
				return idx === 0;
			}

			return !stripBonusTypes.includes(bonus.type);
		});

		if (!bonusesToConsider.length) {
			return 0;
		}

		const bonusPayouts: number[] = _.map(bonusesToConsider, (bonus: Bonus): number => {
			let bonusPayout: number = bonus.calculatePayout(
				costCalc,
				eventProps,
				this,
				gross,
				/**
				 * For all intents and purposes, the ticketCommissionsTotal number
				 * should be factored into the splitPoint, however at a high level
				 * we do not want the event's split point to be affected by ticket
				 * commissions. So we add the ticketCommissionsTotal to the splitPoint
				 * here.
				 *
				 * Note that we also factor ticketCommissionsTotal into netRevenueToSplit
				 * at the event level because of this nuance where it shouldn't be
				 * factored into split point.
				 */
				splitPoint + ticketCommissionsTotal,
				ticketsSold,
				totalSellable,
				royalty,
				false
			);

			// For flat/per ticket bonuses, if show is set to convert artist walkout to USD we should convert bonus from USD to base currency
			if (eventProps.convert_to_usd && !bonus.isPercentageBonus) {
				bonusPayout = TalentData.convertAmount(costCalc, eventProps, bonusPayout);
			}

			return bonusPayout;
		});
		let bonusAmount: number = _.sum(bonusPayouts);
		if (this.deal_type === DealTypes.Verse) {
			bonusAmount = _.max(bonusPayouts);
		}

		return bonusAmount;
	}

	public totalAdjustments(external: boolean): number {
		let total: number = 0;
		this.payout_adjustments.forEach((item: PayoutAdjustment): void => {
			// external settlement only adjustments should only apply for external totalAdjustments
			if (item.affects_external_settlement_only && !external) {
				return;
			}

			total += Number(item.amount);
		});
		return total;
	}

	public totalNonEarningsAdjustments(external: boolean): number {
		return this.totalAdjustments(external) - this.totalAdjustmentsTowardArtistEarnings;
	}

	/**
	 * compute the total payout to be used when computing agent commission
	 * the math here is rougly calculateArtistPayout() + totalAdjustmentsTowardArtistEarnings()
	 *
	 * @param eventProps EventProps used to compute guarantees
	 * @param netGross the net gross on the event
	 * @param splitPoint the split point on the event
	 * @param ticketsSold tickets sold on the event
	 * @param totalSellable total sellable tickets on the event
	 * @param ticketCommissionsTotal the total ticket commissions, only relevant for events that are part of a broadway tour
	 * @param costCalc the cost calc being considered here. only used for determining whether or not to apply artist earnings override
	 * @param external true if external, false otherwise
	 * @returns the total artist payout to be used to compute
	 */
	public artistPayoutTowardAgentCommission(
		eventProps: EventPropsForTalentGuarantee,
		netGross: number,
		splitPoint: number,
		ticketsSold: number,
		totalSellable: number,
		ticketCommissionsTotal: number,
		nagbor: number,
		costCalc: CostCalc,
		external: boolean
	): number {
		// when computing the final payout for agent commission when there is an override
		// do NOT include total non-earnigns adjustments like we do in calculateTalentPayout
		// those adustments are not supposed to affect the agents commission
		if (this.shouldApplyArtistEarningsOverride(costCalc)) {
			return this.artist_earnings_override;
		}

		return (
			this.calculateArtistPayout(
				costCalc,
				external,
				eventProps,
				netGross,
				splitPoint,
				ticketsSold,
				totalSellable,
				ticketCommissionsTotal,
				nagbor,
				{
					adjusted: false,
				}
			) + this.totalAdjustmentsTowardArtistEarnings
		);
	}

	/**
	 * given an agent index on this TalentData, compute that agent's total talent payout
	 *
	 * @param eventProps EventProps used to compute guarantees
	 * @param agentIndex the index of the agent for which the payout should be computed
	 * @param netGross the net gross on the event
	 * @param splitPoint the split point on the event
	 * @param ticketsSold tickets sold on the event
	 * @param totalSellable total sellable tickets on the event
	 * @param adjusted if true, apply adjustments, if false dont apply adjustments
	 * @param costCalc the cost calc being considered here. only used for determining whether or not to apply artist earnings override
	 * @param external true if external, false otherwise
	 * @returns the total agent payout for the given agent
	 */
	public calculateAgentPayout(
		eventProps: EventPropsForTalentGuarantee,
		agentIndex: number,
		netGross: number,
		splitPoint: number,
		ticketsSold: number,
		totalSellable: number,
		ticketCommissionsTotal: number,
		nagbor: number,
		costCalc: CostCalc,
		external: boolean
	): number {
		if (agentIndex < 0 || agentIndex >= this.agents.length) {
			throw new Error('invalid agent index. are you calling this function outside of a for loop?');
		}

		// calculate the agent payout based on the artistpayout BEFORE adjustments
		return (
			(this.artistPayoutTowardAgentCommission(
				eventProps,
				netGross,
				splitPoint,
				ticketsSold,
				totalSellable,
				ticketCommissionsTotal,
				nagbor,
				costCalc,
				external
			) *
				this.agents[agentIndex].agent_commission) /
			100
		);
	}

	/**
	 * Compute the final number for this talent payout. Consider various event-level factors
	 * like tickets sold and split points, as well as external and cost calcs to determine the final number
	 *
	 * this method should be considered the lowest level of event math for this TalentData object. this is consumed
	 * in the top-level EMS, and also in other methods like artistPayoutTowardAgentCommission and calculateAdjustedArtistPayout
	 *
	 * @param eventProps EventProps used to compute guarantees
	 * @param netGross the net gross on the event
	 * @param splitPoint the split point on the event
	 * @param ticketsSold tickets sold on the event
	 * @param totalSellable total sellable tickets on the event
	 * @param ticketCommissionsTotal total amount of ticket commissions for the event
	 * @param nagbor amount for the event
	 * @param adjusted if true, apply adjustments, if false dont apply adjustments
	 * @param costCalc the cost calc being considered here. only used for determining whether or not to apply artist earnings override
	 * @param external true if external, false otherwise
	 * @param optionsArg optional arguemnts
	 * @returns a number denoting the total payout for this talent deal
	 */
	public calculateArtistPayout(
		costCalc: CostCalc,
		external: boolean,
		eventProps: EventPropsForTalentGuarantee,
		netGross: number,
		splitPoint: number,
		ticketsSold: number,
		totalSellable: number,
		ticketCommissionsTotal: number,
		nagbor: number,
		optionsArg: CalculateTotalArtistPayoutOptionsArgs = {}
	): number {
		if (optionsArg.computeForCoPro && !isNil(this.copro_overridden_artist_payout)) {
			return this.copro_overridden_artist_payout;
		}
		// when computing the artist payout for an event with an override,
		// return the earnings override + the total non-earnings adjustments
		// this will bubble up the proper numbers into the balance table
		if (this.shouldApplyArtistEarningsOverride(costCalc, optionsArg.forSplitPoint)) {
			const conditionalNonEarningsAdjustments: number = optionsArg.adjusted
				? this.totalNonEarningsAdjustments(external)
				: 0;
			return this.artist_earnings_override + conditionalNonEarningsAdjustments;
		}

		let royalty: number = 0;
		if (this.has_royalty) {
			royalty = this.calculateRoyalty(nagbor);
		}

		const artistBonus: number = this.calculateArtistBonus(
			costCalc,
			eventProps,
			netGross,
			splitPoint,
			ticketCommissionsTotal,
			ticketsSold,
			totalSellable,
			royalty
		);
		let artistPayout: number;
		const guarantee: number = this.convertedGuarantee(costCalc, eventProps);

		// if verse choose the greater of the bonus and guarantee, else the sum
		if (this.deal_type === DealTypes.Verse) {
			artistPayout = Math.max(artistBonus, guarantee);
		} else {
			artistPayout = artistBonus + Number(guarantee);
		}

		// do we need to consider a cap?
		if (this.deal_type !== DealTypes.Flat && this.capped) {
			artistPayout = Math.min(this.cap_amount, artistPayout);
		}

		if (optionsArg.adjusted) {
			artistPayout = artistPayout + Number(this.totalAdjustments(external)) + royalty;
		}

		return artistPayout;
	}

	/**
	 * caluclate arist payouts w adjustments
	 *
	 * @param eventProps EventProps used to compute guarantees
	 * @param netGross the net gross on the event
	 * @param splitPoint the split point on the event
	 * @param ticketsSold tickets sold on the event
	 * @param totalSellable total sellable tickets on the event
	 * @param costCalc the cost calc being considered here. only used for determining whether or not to apply artist earnings override
	 * @param external true if external, false otherwise
	 * @param optionsArg optional arguemnts
	 * @returns adjusted artist payouts
	 */
	public calculateAdjustedArtistPayout(
		eventProps: EventPropsForTalentGuarantee,
		netGross: number,
		splitPoint: number,
		ticketsSold: number,
		totalSellable: number,
		ticketCommissionsTotal: number,
		nagbor: number,
		costCalc: CostCalc,
		external: boolean
	): number {
		return this.calculateArtistPayout(
			costCalc,
			external,
			eventProps,
			netGross,
			splitPoint,
			ticketsSold,
			totalSellable,
			ticketCommissionsTotal,
			nagbor,
			{
				adjusted: true,
			}
		);
	}

	public convertedGuarantee(costCalc: CostCalc, eventProps: EventPropsForTalentGuarantee): number {
		return TalentData.convertAmount(costCalc, eventProps, this.guarantee);
	}

	public convertedDocumentDeposit(costCalc: CostCalc, eventProps: EventPropsForTalentGuarantee): number {
		const depositAmount: number = this.depositAmount(costCalc, eventProps);

		return TalentData.convertAmount(costCalc, eventProps, depositAmount);
	}

	public depositAmount(costCalc: CostCalc, eventProps: EventPropsForTalentGuarantee): number {
		const convertedGuarantee: number = this.convertedGuarantee(costCalc, eventProps);
		return this.deposits.reduce((totalDepositAmount: number, deposit: Deposit): number => {
			return totalDepositAmount + depositTotal(deposit, convertedGuarantee, eventProps, costCalc);
		}, 0);
	}

	public addBonus(): void {
		const newBonus: Bonus = new Bonus();
		if (this.bonuses.length) {
			newBonus.after = this.bonuses[0].after;
			newBonus.after_amount = this.bonuses[0].after_amount;
			newBonus.amount = this.bonuses[0].amount;
			newBonus.type = this.bonuses[0].type;
		}
		this.bonuses.push(newBonus);
	}

	public resetBonusAfterAmounts(): void {
		this.bonuses.forEach((bonus: Bonus): void => {
			bonus.after = undefined;
			bonus.after_amount = 0;
		});
	}

	public resetDeal(): void {
		this.bonuses = [new Bonus()];
	}

	/**
	 * start agent helpers
	 */
	public setSpotifyArtist(artist: ArtistSearchResult): void {
		// create an Artist for agent-represented talent, or a clear it
		if (isArtistOnClientRoster(artist)) {
			// now set talent_agent records
			this.talent_agent = new Artist({ ...artist.agent_data });
			this.talent_agent_id = artist.agent_data.id;

			this.agents = _.map(artist.agent_data.agents, (agent: AgentUser): AgentUserOnEvent => {
				return {
					...agent,
					/**
					 * The suggested id comes from the backend and represents the id
					 * of the talent_agent_agent relationship, but we are copying
					 * it into an object where it will be associated with the
					 * specific talent deal, so we need to clear it out so that
					 * the backend knows to create a new entity instead of updating
					 * an existing one.
					 */
					id: undefined,
					agent_commission: agent.default_commission,
				};
			});
		} else {
			this.clearAgent();
		}

		// now set the `talent` records
		const newTalent: Talent = new Talent();
		if (artist.id) {
			newTalent.id = artist.id;
		}
		newTalent.name = artist.name;
		newTalent.images = artist.images;
		newTalent.spotify_id = artist.spotify_id;
		this.talent = newTalent;
	}

	public clearSpotifyArtist(): void {
		this.clearAgent();
		this.clearTalent();
	}

	/**
	 * clears out any agent-related fields. should remain private
	 */
	private clearAgent(): void {
		this.talent_agent = null;
		this.talent_agent_id = null;
		this.agents = [];
	}

	/**
	 * clears out any talent-related fields. should remain private
	 */
	private clearTalent(): void {
		this.talent = null;
		this.talent_id = null;
	}

	/**
	 * reset all retro bonus related values back to their defaults
	 */
	public clearRetroBonuses(): void {
		this.retroactive_bonuses = [];
		this.retroactive_bonus = false;
		this.retroactive_after_type = null;
	}

	/**
	 * Renders a numbered list of deposits, or single entry if there is only one deposit, that detail the amount, due date (if deposit
	 * isn't paid) or paid date (if the deposit is paid), and who it is/was collected by, either agent or artist.
	 */
	public depositSummary(
		convertedGuarantee: number,
		eventProps: EventPropsForTalentGuarantee,
		costCalc: CostCalc
	): string {
		const nonZeroDepositArray: Deposit[] = _.filter(this.deposits, (deposit: Deposit): boolean => {
			const total: number = depositTotal(deposit, convertedGuarantee, eventProps, costCalc);
			return total !== 0;
		});
		return nonZeroDepositArray
			.map((deposit: Deposit, index: number): string => {
				if (nonZeroDepositArray.length === 1) {
					return `Deposit: ${getDepositSummary(deposit, convertedGuarantee, eventProps, costCalc)}`;
				}
				return `Deposit ${index + 1}: ${getDepositSummary(deposit, convertedGuarantee, eventProps, costCalc)}`;
			})
			.join('\n');
	}

	public calculateRoyalty(ticketCommissionsNagbor: number): number {
		if (!this.has_royalty) {
			return 0;
		}

		// If it is not a broadway tour, ticketCommissionsNagbor will be 0
		return ticketCommissionsNagbor * (this.royalty_percentage / 100);
	}
}
