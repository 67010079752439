import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { OrgSettingsService } from '@prism-frontend/services/legacy/api/org-settings/org-settings.service';
import { FeatureGateService } from '@prism-frontend/services/utils/feature-gate.service';
import { ORG_FEATURE } from '@prism-frontend/typedefs/features.class';
import { errorDebug } from '@prism-frontend/utils/static/getDebug';
import _ from 'lodash';
import { firstValueFrom } from 'rxjs';

// Feature guard for org level features
export const canActivateOrgFeatureGate: CanActivateFn = (route: ActivatedRouteSnapshot): Promise<boolean> => {
	const orgSettingsService: OrgSettingsService = inject(OrgSettingsService);
	const featureGateService: FeatureGateService = inject(FeatureGateService);

	return new Promise(async (resolve: (hasFeature: boolean) => void): Promise<void> => {
		if (!route.data.OrgFeatureGateGuard) {
			// If this gate is running but the above switch does not know
			// why, assume the feature should not be enabled.
			errorDebug(
				`OrgFeatureGateGuard: Run on route with no data.OrgFeatureGateGuard. Assuming canActivate() => false`
			);
			resolve(false);
		}

		await firstValueFrom(orgSettingsService.loaded$);

		// Run permission checks here when the 'features' array
		// is already populated from the server
		if (_.isArray(route.data.OrgFeatureGateGuard.features)) {
			const hasFeature: boolean = _.every(
				route.data.OrgFeatureGateGuard.features,
				(feature: ORG_FEATURE): boolean => {
					return featureGateService.orgHasFeature(feature);
				}
			);
			resolve(hasFeature);
		} else if (route.data.OrgFeatureGateGuard.feature) {
			const hasFeature: boolean = featureGateService.orgHasFeature(route.data.OrgFeatureGateGuard.feature);
			resolve(hasFeature);
		} else if (route.data.OrgFeatureGateGuard.featureFunction) {
			if (!_.isFunction(route.data.OrgFeatureGateGuard.featureFunction)) {
				// eslint-disable-next-line no-console
				console.error('featureFunction is not a function');
				return resolve(false);
			}

			const isAllowed: boolean = route.data.OrgFeatureGateGuard.featureFunction(featureGateService);
			if (!_.isBoolean(isAllowed)) {
				// eslint-disable-next-line no-console
				console.error('featureFunction did not return a boolean');
				return resolve(false);
			}
			resolve(isAllowed);
		} else {
			errorDebug(
				`OrgFeatureGateGuard: Run on route with no data.OrgFeatureGateGuard.feature[s]. Assuming canActivate() => false`
			);
			resolve(false);
		}
	});
};
