import { CostCalc } from '@prism-frontend/typedefs/enums/calc';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';
export function fetchLegacyEMSParamsFromCostCalc2(costCalc2: CostCalc2): [CostCalc, boolean] {
	switch (costCalc2) {
		case CostCalc2.ExternalBudgeted:
			return [CostCalc.Budgeted, true];
		case CostCalc2.ExternalEstimated:
			return [CostCalc.Estimated, true];
		case CostCalc2.ExternalReported:
			return [CostCalc.Reported, true];
		case CostCalc2.InternalActual:
			return [CostCalc.Actual, false];
		case CostCalc2.InternalPotential:
			return [CostCalc.Potential, false];
		case CostCalc2.InternalEstimated:
			return [CostCalc.Estimated, false];
		default:
			throw new Error(`Unrecognized CostCalc2: ${costCalc2}`);
	}
}
