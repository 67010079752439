import _Debug from 'debug';
import { environment } from 'environments/environment';
import _ from 'lodash';

export type Debug = _Debug.Debugger;

/**
 * Wraps the debug package so that (1) it does nothing in higher environments, and
 * (2) the debug logger returned is ALWAYS prefaced with a `prism` namespace.
 * @param namespace a string describing the context in which you will be using
 *   the logger. For a component, this should match its selector. For a logger
 *   that spans multiple components but relates to a single feature, choose a
 *   namespace that matches the feature. e.g. `column-sets`
 * @returns a debug function. See the docs for the
 *   [debug package](https://www.npmjs.com/package/debug) for more info.
 */
export function getDebug(namespace: string): Debug {
	if (!environment.allowDebugOutput) {
		return _.noop as Debug;
	}
	return _Debug(`prism:${namespace}`);
}

/**
 * Debugger for local development. app.component.ts will set localStorage.debug
 * to surface these messaged by default. You will be prevented from shipping
 * code that uses this function.
 */
export const devDebug: Debug = getDebug('dev');
/**
 * Used for logging super verbose messages that you are not going to want to see
 * in the majority of cases.
 */
export const verboseDebug: Debug = getDebug('verbose');
/**
 * Logger for issues we are handling gracefully in the front end, but that we
 * eventually want to smooth over or fix. e.g. when the front-end casts backend
 * numbers from strings to proper numbers, we could use this logger for
 * logging the fact that we needed to cast the property.
 */
export const errorDebug: Debug = getDebug('error');
