import { DocumentNode, gql } from '@apollo/client/core';
// Fragments docs: https://www.apollographql.com/docs/react/data/fragments/
const StagePropsCore: DocumentNode = gql`
	fragment StagePropsCore on Stage {
		id
		name
		can_share_holds
		is_shared_host
		venue_id
		capacity
		active
		color
	}
`;

export const StageProps: DocumentNode = gql`
	fragment StageProps on Stage {
		...StagePropsCore
		host_stage {
			...StagePropsCore
		}
	}
	${StagePropsCore}
`;
