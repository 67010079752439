import { DocumentNode, gql } from '@apollo/client/core';
import {
	CustomFieldBackend,
	CustomFieldEventBackend,
} from '@prism-frontend/entities/custom-fields/custom-fields-typedefs';
import { fetchPaginationProps, PaginationProps } from '@prism-frontend/typedefs/graphql/PaginationDetails';

const CustomFieldPropsFragment: DocumentNode = gql`
	fragment CustomFieldProps on CustomField {
		default_value
		description
		id
		custom_field_id
		name
		organization_id
		type
		is_advanced
	}
`;

export const EventCustomFieldPropsFragment: DocumentNode = gql`
	fragment CustomFieldPropsEvent on CustomField {
		...CustomFieldProps
		event_id
		event_value
	}
	${CustomFieldPropsFragment}
`;

const paginationProps: PaginationProps = fetchPaginationProps();

export const PaginatedListCustomFieldsQuery: DocumentNode = gql`
	query PaginatedCustomFieldsList(
		${paginationProps.queryParams}
	) {
		customFieldList(
			${paginationProps.queryArguments}
		)  {
			data {
				...CustomFieldProps
			}
			${paginationProps.responseProps}
		}
	}
	${CustomFieldPropsFragment}
`;

const COMMON_CUSTOM_FIELD_MUTATION_ARGUMENTS: string = `name: $name,
description: $description,
default_value: $default_value`;

export const CreateCustomFieldMutation: DocumentNode = gql`
	mutation CreateCustomFieldMutation(
		$type: String!,
		$is_advanced: Boolean!,
		$apply_all_events: Boolean!
		$name: String!,
		$description: String,
		$default_value: String
	) {
		createCustomField(
			type: $type,
			is_advanced: $is_advanced,
			apply_all_events: $apply_all_events,
			${COMMON_CUSTOM_FIELD_MUTATION_ARGUMENTS}
		) {
			...CustomFieldProps
		}
	}
	${CustomFieldPropsFragment}
`;

export interface CreateCustomFieldMutationResponse {
	createCustomField: CustomFieldBackend;
}

export const UpdateCustomFieldMutation: DocumentNode = gql`
	mutation UpdateCustomFieldMutation(
		$id: Int!,
		$name: String,
		$description: String,
		$default_value: String
	) {
		updateCustomField(
			id: $id,
			${COMMON_CUSTOM_FIELD_MUTATION_ARGUMENTS}
		) {
			...CustomFieldProps
		}
	}
	${CustomFieldPropsFragment}
`;

export interface UpdateCustomFieldMutationResponse {
	updateCustomField: CustomFieldBackend;
}

export const DeleteCustomFieldMutation: DocumentNode = gql`
	mutation DeleteCustomFieldMutation($id: Int!) {
		deleteCustomField(id: $id)
	}
`;

export interface DeleteCustomFieldMutationResponse {
	deleteCustomField: number;
}

export interface AddCustomFieldToEventMutationVariables {
	eventId: number;
	customFieldId: number;
	eventValue: String;
}

export const AddCustomFieldToEventMutation: DocumentNode = gql`
	mutation AddCustomFieldToEvent($eventId: Int!, $customFieldId: Int!, $eventValue: String) {
		addCustomFieldToEvent(event_id: $eventId, custom_field_id: $customFieldId, event_value: $eventValue) {
			...CustomFieldPropsEvent
		}
	}
	${EventCustomFieldPropsFragment}
`;

export interface AddCustomFieldToEventMutationResponse {
	addCustomFieldToEvent: CustomFieldEventBackend;
}
