export enum DocTypes {
	Offer = 'offer',
	Settlement = 'settlement',
	Advance = 'advance',
	Contract = 'contract',
	// Intentionally excludes Invoice, Itinerary because they are a different type of document
}

export enum AgentDocuments {
	Itinerary = 'itinerary',
	Invoice = 'invoice',
	RouteSheet = 'route-sheet',
}

export type InternalSettlementDocType = 'internal-settlement';

export type AllPDFTypes = DocTypes | AgentDocuments | InternalSettlementDocType;
