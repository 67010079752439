import { Injectable } from '@angular/core';
import { CalendarAPIService, HoldAPIParameters } from '@prism-frontend/services/api/calendar-api.service';
import { AbstractListService } from '@prism-frontend/services/api/list-services/abstract-list.service';
import { ManageHoldsService } from '@prism-frontend/services/api/manage-holds.service';
import { ApiService } from '@prism-frontend/services/legacy/api.service';
import { StorageService } from '@prism-frontend/services/legacy/storage.service';
import { SpinnerService } from '@prism-frontend/services/utils/spinner.service';
import { HoldForCalendar } from '@prism-frontend/typedefs/hold-for-calendar';
import {
	DateRange,
	aggregateAllOverlappingDateRanges,
	anyDateRangeContains,
} from '@prism-frontend/utils/static/dateRangeHelper';
import { Debug, getDebug } from '@prism-frontend/utils/static/getDebug';
import * as _ from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const debug: Debug = getDebug('hold-list-service');
@Injectable({
	providedIn: 'root',
})
export class HoldService extends AbstractListService<HoldForCalendar> {
	public spinnerCount: number = 0;
	public dateRangesLoading: DateRange[] = [];
	public dateRangesLoaded: DateRange[] = [];
	protected override isCacheable: boolean = false;

	private static getFilteredDateRange(
		holds: HoldForCalendar[],
		start: moment.Moment,
		end: moment.Moment,
		// Optionally exclude a date range
		excludeDuration?: {
			excludeFrom: string;
			excludeUntil: string;
		}
	): HoldForCalendar[] {
		return holds.filter((hold: HoldForCalendar): boolean => {
			const inDateRange: boolean =
				moment(hold.hold_date).isSameOrAfter(start) && moment(hold.hold_date).isSameOrBefore(end);
			let inExcludeDateRange: boolean = false;

			if (excludeDuration) {
				inExcludeDateRange =
					moment(hold.hold_date).isSameOrAfter(moment(excludeDuration.excludeFrom)) &&
					moment(hold.hold_date).isSameOrBefore(moment(excludeDuration.excludeUntil));
			}

			return inDateRange && !inExcludeDateRange;
		});
	}

	public constructor(
		private calendarApiService: CalendarAPIService,
		private manageHoldsService: ManageHoldsService,
		protected override apiService: ApiService,
		protected spinnerService: SpinnerService,
		protected override storageService: StorageService
	) {
		super(apiService, spinnerService, storageService);
	}

	public getHoldsInDateRange(params: Pick<HoldAPIParameters, 'start' | 'end'>): HoldForCalendar[] {
		return HoldService.getFilteredDateRange(this._all$.value, moment(params.start), moment(params.end));
	}

	public getHoldById(id: number): HoldForCalendar | undefined {
		return this._all$.value.find((hold: HoldForCalendar): boolean => {
			return hold.id === id;
		});
	}

	public containsHoldsForDateRange(params: Pick<HoldAPIParameters, 'start' | 'end'>): boolean {
		return this.getHoldsInDateRange(params).length > 0;
	}

	public holdsInDateRange$(params: HoldAPIParameters): Observable<HoldForCalendar[]> {
		return this.all$.pipe(
			map((holds: HoldForCalendar[]): HoldForCalendar[] => {
				return HoldService.getFilteredDateRange(holds, moment(params.start), moment(params.end));
			})
		);
	}

	public holdsForEvent$(eventId: number): Observable<HoldForCalendar[]> {
		return this.all$.pipe(
			map((holds: HoldForCalendar[]): HoldForCalendar[] => {
				return holds.filter((hold: HoldForCalendar): boolean => {
					return hold.event_id === eventId;
				});
			})
		);
	}

	public loadHoldsForEvent(eventId: number): Promise<HoldForCalendar[]> {
		return this.manageHoldsService.loadHoldsForEvent(eventId);
	}

	// date string format: 2020-01-01
	public async loadDateRange(
		params: HoldAPIParameters,
		displaySpinnerIfNotLoaded: boolean = true
	): Promise<HoldForCalendar[]> {
		const dateRange: DateRange = {
			end: moment(params.end),
			start: moment(params.start),
		};
		this.dateRangesLoading.push(dateRange);

		// Only display the spinner if the date range is not loaded yet
		const displaySpinner: boolean =
			displaySpinnerIfNotLoaded && !anyDateRangeContains(this.dateRangesLoaded, dateRange);

		if (displaySpinner) {
			this.spinnerCount++;
		}

		const newHolds: HoldForCalendar[] = await this.calendarApiService.loadHoldDateRange(params);

		if (displaySpinner) {
			this.spinnerCount--;
		}

		// we iterate too much here. we should take what we get from the API and add to the array
		// and de-dupe based on ID
		let currentHolds: HoldForCalendar[] = [...this._all$.value];

		const existingHoldsInDateRange: HoldForCalendar[] = HoldService.getFilteredDateRange(
			currentHolds,
			moment(params.start),
			moment(params.end),
			params.excludeDuration
		);

		// Replace existing matching holds with freshly loaded holds
		_.pullAll(currentHolds, existingHoldsInDateRange);

		// If new holds have the same id than currentHolds we should replace them.
		const newHoldsIds: Set<number> = new Set<number>(
			newHolds.map((hold: HoldForCalendar): number => {
				return hold.id;
			})
		);

		currentHolds = currentHolds.filter((hold: HoldForCalendar): boolean => {
			return !newHoldsIds.has(hold.id);
		});
		const allHolds: HoldForCalendar[] = currentHolds.concat(newHolds);
		this.dateRangesLoaded.push(dateRange);
		this.dateRangesLoaded = aggregateAllOverlappingDateRanges(this.dateRangesLoaded);
		_.pull(this.dateRangesLoading, dateRange);

		debug('%c dateRangesLoaded', 'font-weight: bold', this.dateRangesLoaded.length);

		this._all$.next(allHolds);
		return newHolds;
	}

	public override refreshListFromAPI(): Promise<HoldForCalendar[]> {
		throw new Error('loadDateRange() should be used instead of refreshListFromAPI() for holds');
	}

	public override ensureListLoaded(): Promise<HoldForCalendar[]> {
		throw new Error('loadDateRange() should be used instead of ensureListLoaded() for holds');
	}

	protected get endpointUrl(): string {
		return this.apiService.ep.HOLDS;
	}

	protected get ClassConstructor(): typeof HoldForCalendar {
		return HoldForCalendar;
	}
}
