import { ENV_FEATURE, ORG_FEATURE } from '@prism-frontend/typedefs/features.class';

export interface EnvironmentFeaturesConfig {
	organizationFeatures: EnvironmentOrganizationFeaturesConfig;
	environmentFeatures: EnvironmentEnvironmentFeaturesConfig;
}

interface EnvironmentOrganizationFeaturesConfig {
	featuresON: ORG_FEATURE[];
	featuresOFF: ORG_FEATURE[];
}

interface EnvironmentEnvironmentFeaturesConfig {
	featuresON: ENV_FEATURE[];
	featuresOFF: ENV_FEATURE[];
}

export enum EnvironmentNames {
	Demos = 'demos', // new demo environment in AWS
	Local = 'local',
	Prod = 'prod',
	Staging = 'staging', // new staging evn in AWS
	PenTest = 'pen-test',
	Dev = 'dev',
	QA = 'qa',
	AUTOMATION = 'automation',
}

export interface Environment {
	name: EnvironmentNames;
	production: boolean;
	suppressExpressionChangedAfterItHasBeenCheckedError: boolean;
	allowDebugOutput: boolean;
	api_path: string;
	graphql_path: string;
	segment_api_key: string;
	// Cache AJAX GET requests for local development
	api_cache_enabled: boolean;
	// Don't cache the following AJAX GET request URLs (strings/regexps)
	skip_cache_for_urls: string[] | RegExp[];
	cdn_base_url?: string;
	help_center_url?: string;
	pusher: {
		app_key: string;
		cluster: string;
		debug: boolean;
	};
}

export const DefaultLocalEnvironment: Environment = {
	name: EnvironmentNames.Local,
	production: false,
	suppressExpressionChangedAfterItHasBeenCheckedError: true,
	allowDebugOutput: true,
	api_path: null,
	graphql_path: null,
	segment_api_key: null,
	help_center_url: 'https://help.prism.fm',
	api_cache_enabled: null,
	skip_cache_for_urls: null,
	pusher: {
		app_key: null,
		cluster: null,
		debug: false,
	},
};
