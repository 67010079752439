import { Dictionary } from '@fullcalendar/core/internal';
import { RENDER_AS_EMPTY } from '@prism-frontend/components/ems-field/ems-field.typedefs';
import { AllSimpleFormFieldConfigs } from '@prism-frontend/components/simple-form/simple-form.typedefs';
import {
	FindCostGroupCategoryArg,
	getFixedCostGroupFormFields,
	getFixedCostsGroups,
} from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/findCostGroupByName';
import {
	FunctionalChip,
	FunctionalChipFunctionResult,
	FunctionalChipOrgData,
} from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/FunctionalChip';
import { FunctionalChipAdditionalData } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/FunctionalChipAdditionalData';
import { EMS, EMSCost, EMSFixedCost } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { EMSFieldDefs, EMSFieldsMeta } from '@prism-frontend/typedefs/ems/EMSFieldMeta';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';
import _ from 'lodash';

interface FindCostNameyArg extends FindCostGroupCategoryArg {
	property?: keyof EMSCost;
}

const fixedCostPropertyCostCalc: Dictionary = {
	['quantity']: CostCalc2.ExternalBudgeted,
	['costAmount']: CostCalc2.ExternalBudgeted,
	['total']: CostCalc2.ExternalBudgeted,
	['InternalEstimated.computedTotal']: CostCalc2.InternalEstimated,
	['InternalActual.computedTotal']: CostCalc2.InternalActual,
	['ExternalReported.computedTotal']: CostCalc2.ExternalReported,
};

export const findFixedCostByName: FunctionalChip<FindCostNameyArg> = {
	identifier: 'findFixedCostByName',
	name: 'Find Fixed Cost By Name',
	fallbackValue: (): string => {
		return '';
	},
	theFunction(
		argument: FindCostNameyArg,
		_orgData: FunctionalChipOrgData,
		data: FunctionalChipAdditionalData
	): FunctionalChipFunctionResult {
		const costCalc: CostCalc2 = fixedCostPropertyCostCalc[argument.property];
		const ems: EMS = data.emsRollup[costCalc];
		const matchingGroups: EMSFixedCost[] = getFixedCostsGroups(ems, argument);
		const matchingCost: EMSCost = _.chain(matchingGroups)
			.flatMap((group: EMSFixedCost): EMSCost[] => {
				return group.costs;
			})
			.find((cost: EMSCost): boolean => {
				return (cost.name || '').trim().toLowerCase().includes(argument.name.toLowerCase());
			})
			.value();
		if (!matchingCost) {
			return RENDER_AS_EMPTY;
		}
		const emsProperty: string = argument.property.split('.').pop();
		return `${matchingCost.costCalc}.${matchingCost.emsPath}.${emsProperty}` as keyof EMSFieldDefs;
	},
	formFields(data: FunctionalChipOrgData, _value: FindCostNameyArg): AllSimpleFormFieldConfigs<FindCostNameyArg>[] {
		return [
			...getFixedCostGroupFormFields(data.isBroadway),
			{
				label: 'Name',
				description: 'Choose a cost name to search for',
				fieldType: 'text',
				key: 'name',
				required: true,
				editable: true,
			},
			{
				label: 'Property',
				description: 'Choose a cost property to show and edit',
				fieldType: 'select',
				key: 'property',
				required: true,
				editable: true,
				suggestions: [
					// One option per editable field in the fixed cost table
					// Notes is not present here since we don't track this over EMS
					// and we can't pull it from the EventsQuery since its not supported
					{
						// Quantity
						label: EMSFieldsMeta['fixedCosts.*.costs.*.quantity'].userFacingLabel as string,
						value: 'quantity',
					},
					{
						// Cost
						label: EMSFieldsMeta['fixedCosts.*.costs.*.costAmount'].userFacingLabel as string,
						value: 'costAmount',
					},
					{
						// External Budgeted
						label: EMSFieldsMeta['fixedCosts.*.costs.*.total'].userFacingLabel[CostCalc2.ExternalBudgeted],
						value: 'total',
					},
					{
						// Estimated Internal
						label: EMSFieldsMeta['fixedCosts.*.costs.*.computedTotal'].userFacingLabel[
							CostCalc2.InternalEstimated
						],
						value: 'InternalEstimated.computedTotal',
					},
					{
						// Actual Internal
						label: EMSFieldsMeta['fixedCosts.*.costs.*.computedTotal'].userFacingLabel[
							CostCalc2.InternalActual
						],
						value: 'InternalActual.computedTotal',
					},
					{
						// Settlement
						label: EMSFieldsMeta['fixedCosts.*.costs.*.computedTotal'].userFacingLabel[
							CostCalc2.ExternalReported
						],
						value: 'ExternalReported.computedTotal',
					},
				],
			},
		];
	},
	chipText: (value: FindCostNameyArg): string => {
		return `Find Fixed Cost by name: ${value.name} - ${value.property}`;
	},
};
