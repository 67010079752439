import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { OrgSettingsService } from '@prism-frontend/services/legacy/api/org-settings/org-settings.service';
import {
	OrganizationTypeGateService,
	ORGANIZATION_TYPES,
} from '@prism-frontend/services/utils/organization-type-gate.service';
import { ORG_TYPES } from '@prism-frontend/typedefs/enums/organization-types';
import { Debug, getDebug } from '@prism-frontend/utils/static/getDebug';
import { firstValueFrom } from 'rxjs';

const debug: Debug = getDebug('organization-type-gate.guard');

export const canActivateOrganizationTypeGate: CanActivateFn = (route: ActivatedRouteSnapshot): Promise<boolean> => {
	const orgSettingsService: OrgSettingsService = inject(OrgSettingsService);
	const organizationTypeService: OrganizationTypeGateService = inject(OrganizationTypeGateService);

	return new Promise(async (resolve: (hasFeature: boolean) => void): Promise<void> => {
		if (!route.data.OrganizationTypeGateGuard) {
			// If this gate is running but the above switch does not know
			// why, assume the feature should not be enabled.
			debug(
				`OrganizationTypeGateGuard: Run on route with no data.OrganizationTypeGateGuard. Assuming canActivate() => false`
			);
			resolve(false);
		}

		await firstValueFrom(orgSettingsService.loaded$);
		resolve(isEnabledForOrganizationType(route.data.OrganizationTypeGateGuard.type, organizationTypeService));
	});
};

function isEnabledForOrganizationType(
	orgType: ORG_TYPES,
	organizationTypeService: OrganizationTypeGateService
): boolean {
	switch (orgType) {
		case ORGANIZATION_TYPES.IS_AGENT:
			return organizationTypeService.isAgent;
		case ORGANIZATION_TYPES.IS_NOT_AGENT:
			return organizationTypeService.isNotAgent;
		case ORGANIZATION_TYPES.IS_BROADWAY:
			return organizationTypeService.isBroadway;
		default:
			throw new Error(`unsupported ORG_TYPES ${orgType}`);
	}
}
