import { TalentImage } from '@prism-frontend/typedefs/talent';
import { talentBadgePlaceholderSrc } from '@prism-frontend/typedefs/talent-badge-placeholder';
import _ from 'lodash';

type TalentImageSortOptions = 'smallestToLargest' | 'none';

export function resolveTalentImageUrl(
	images: TalentImage[] | undefined,
	/**
	 * If passed, this method will be used to sort the images array before grabbing
	 * the first entry.
	 */
	sortFunction: TalentImageSortOptions = 'none'
): string {
	if (!images || !images.length) {
		// Use our placeholder for missing images
		return talentBadgePlaceholderSrc;
	}
	if (sortFunction === 'smallestToLargest') {
		images = sortImagesSmallestToLargest(images);
	}
	return images[0].url;
}

/**
 * Orders an array of TalentImages with the smallest in the front. To be used as
 * second parameter to resolveTalentImageUrl.
 *
 * This method looks at an image area (height * width) to determine its size.
 * @param images array of TalentImage to sort
 * @returns images array, but sorted with the smallest image first
 */
function sortImagesSmallestToLargest(images: TalentImage[]): TalentImage[] {
	return _.chain(images)
		.sortBy((image: TalentImage): number => {
			return image.height * image.width;
		})
		.value();
}
