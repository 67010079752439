import { Environment, EnvironmentNames } from '@prism-frontend/typedefs/environment.typedef';

export const environment: Environment = {
	name: EnvironmentNames.Demos,
	production: true,
	suppressExpressionChangedAfterItHasBeenCheckedError: true,
	allowDebugOutput: false,
	cdn_base_url: 'https://static.prism.fm/demos/',
	help_center_url: 'https://help.prism.fm',
	api_path: 'api/',
	graphql_path: 'graphql/',
	// API key for TESTING Segment environment
	segment_api_key: '',
	api_cache_enabled: false,
	skip_cache_for_urls: [],
	pusher: {
		app_key: '9175c9b92dc0786bb1f6',
		cluster: 'us2',
		debug: true,
	},
};
