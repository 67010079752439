import { DocumentNode, gql } from '@apollo/client/core';

export const CoreUserFragment: DocumentNode = gql`
	fragment CoreUserFragment on User {
		id
		email
		staff {
			id
			job
			name
		}
	}
`;
