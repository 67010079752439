import {
	AfterViewInit,
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	Inject,
	Injectable,
	ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WindowService } from '@prism-frontend/services/ui/window.service';
import { ViewContainerDirective } from '@prism-frontend/utils/directives/view-cointainer-ref.directive';
import { WithAutoUnsubscribeComponent } from '@prism-frontend/utils/static/auto-unsubscribe';
import {
	applyInputsToComponentInstance,
	ComponentOutputMap,
	initializeComponentOutputs,
} from '@prism-frontend/utils/static/dynamic-component-helpers';
import { ClassConstructor } from 'class-transformer';
import _ from 'lodash';

interface ModalizedComponentArgs<T> {
	componentInputs: Partial<T>;
	componentOutputs: ComponentOutputMap<T>;
	componentFactory: ComponentFactory<T>;
	modalizerModalOptions: ModalizerModalOptions;
}

export type ModalizedDialogRef<ComponentType, ModalResultsType> = MatDialogRef<
	ComponentModalizerModalComponent<ComponentType, ModalResultsType>,
	ModalResultsType
>;

export interface ModalizerModalOptions {
	fullScreenOnMobile?: boolean;
	includeCloseButton?: boolean;
	closeButtonHoverText?: string;
	matDialogOptions?: Omit<MatDialogConfig, 'data'>;
	additionalClasses?: string[];
}

export const DEFAULT_MODALIZER_MODAL_OPTIONS: ModalizerModalOptions = {
	fullScreenOnMobile: true,
	// TODO PRSM-XXXX change to true (requires audit of all calls to openDialog)
	includeCloseButton: false,
	closeButtonHoverText: 'Close',
	matDialogOptions: {},
};

/**
 * Have a component you want to display in a modal? This component is your friend.
 */
@Injectable()
export class ComponentModalizerService {
	public constructor(
		private matDialog: MatDialog,
		private componentFactoryResolver: ComponentFactoryResolver,
		public windowService: WindowService
	) {}

	/**
	 *
	 * @param forComponent
	 * @param componentInputs
	 * @param componentOutputs
	 */
	public openDialog<ComponentType, ModalResultsType>(
		forComponent: ClassConstructor<ComponentType>,
		componentInputs: Partial<ComponentType>,
		componentOutputs: ComponentOutputMap<ComponentType>,
		modalizerModalOptions: ModalizerModalOptions = { ...DEFAULT_MODALIZER_MODAL_OPTIONS }
	): ModalizedDialogRef<ComponentType, ModalResultsType> {
		modalizerModalOptions = {
			...DEFAULT_MODALIZER_MODAL_OPTIONS,
			...modalizerModalOptions,
		};
		const componentFactory: ComponentFactory<ComponentType> =
			this.componentFactoryResolver.resolveComponentFactory(forComponent);
		let classList: string[] = ['modalizer-panel-host', 'js-scrolling-container-for-quill'];
		if (WindowService.isMobile && modalizerModalOptions.fullScreenOnMobile) {
			classList.push('full-screen-modal-mobile');
		}
		if (modalizerModalOptions.additionalClasses) {
			classList = [...classList, ...modalizerModalOptions.additionalClasses];
		}
		if (modalizerModalOptions.matDialogOptions.panelClass) {
			if (_.isArray(modalizerModalOptions.matDialogOptions.panelClass)) {
				classList = [...classList, ...modalizerModalOptions.matDialogOptions.panelClass];
			} else if (_.isString(modalizerModalOptions.matDialogOptions.panelClass)) {
				classList.push(modalizerModalOptions.matDialogOptions.panelClass);
			}
		}
		const dialogRef: MatDialogRef<
			ComponentModalizerModalComponent<ComponentType, ModalResultsType>,
			ModalResultsType
		> = this.matDialog.open<
			ComponentModalizerModalComponent<ComponentType, ModalResultsType>,
			ModalizedComponentArgs<ComponentType>,
			ModalResultsType
		>(ComponentModalizerModalComponent, {
			...modalizerModalOptions.matDialogOptions,
			panelClass: classList,
			data: {
				componentFactory,
				componentInputs: componentInputs,
				componentOutputs: componentOutputs,
				modalizerModalOptions,
			},
		});
		return dialogRef;
	}
}

@Component({
	template: `<div class="modalizer-wrapper" data-testid="js-modal-container">
		<div view-container></div>
		<!-- must keep below the rest of the contents so that whatever is above recieves focus -->
		<div
			class="modalizer-close-button"
			data-testid="js-modalizer-close-button"
			*ngIf="data.modalizerModalOptions.includeCloseButton"
		>
			<button
				mat-icon-button
				class="small-mat-icon"
				[matTooltip]="data.modalizerModalOptions.closeButtonHoverText"
				(click)="dialogRef.close()"
				title="Close"
			>
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</div>`,
	styleUrls: [`./component-modalizer-modal.component.scss`],
})

/* eslint-disable brace-style */
export class ComponentModalizerModalComponent<ComponentType, ModalResultsType>
	extends WithAutoUnsubscribeComponent
	implements AfterViewInit
{
	public componentRef: ComponentRef<ComponentType>;

	public constructor(
		public dialogRef: MatDialogRef<ComponentType, ModalResultsType>,
		@Inject(MAT_DIALOG_DATA) public data: ModalizedComponentArgs<ComponentType>
	) {
		super();
	}

	@ViewChild(ViewContainerDirective, { static: false }) public contentView: ViewContainerDirective;

	public ngAfterViewInit(): void {
		this.componentRef = this.contentView.viewContainerRef.createComponent(this.data.componentFactory);
		// Pass along the inputs
		applyInputsToComponentInstance<ComponentType>(this.componentRef.instance, this.data.componentInputs, true);

		// Attach listeners for the outputs
		this.addSubscriptions(initializeComponentOutputs(this.componentRef.instance, this.data.componentOutputs));
	}
}
