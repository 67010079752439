import { performCalculationTimer } from '@prism-frontend/entities/email-templates/data-interpolation/data-interpolation-service-timers';
import { limitedEvaluate } from '@prism-frontend/entities/email-templates/data-interpolation/limitedEvaluate';
import { MathCalculationResult } from '@prism-frontend/pages/testing-page/components/field-sandbox/MathCalculationResult';
import { Debug, getDebug, verboseDebug } from '@prism-frontend/utils/static/getDebug';
import _ from 'lodash';
const debug: Debug = getDebug('data-interpolation');

export function performCalculation(expression: string): MathCalculationResult {
	performCalculationTimer.start();
	let calculationResult: MathCalculationResult;
	expression = expression.trim().replace(/\\\*/g, '*');
	verboseDebug('evaluating expression:', expression);
	try {
		let result: unknown = limitedEvaluate(expression);
		if (_.isNumber(result) && isNaN(result)) {
			// instead of showing NaN to users, show them 0
			result = 0;
		}
		if (result && (typeof result === 'function' || result.toString().indexOf('function') !== -1)) {
			// this condition can be true when the contents of the text field
			// is something like `mean`. In this case, safeEvaluate will return
			// the function itself, and we wish to treat the calculationResult
			// as an error, rather than a valid value.
			calculationResult = {
				hasError: true,
				errorMessage: 'Formula resolves to a function. Did you mean to call it with `(`?',
				result: null,
				isResultAnObject: false,
				isValueValidAsAComputedCustomValue: false,
			};
		} else {
			const resultAsNumber: number = Number(result);
			// if the formula results in a decimal value, mathjs will output a string
			// e.g. 0.3333 will be '0.3333'. We want to return numbers when possible,
			// so detect strings that contain `.` and are valid numbers, cast the
			// result back to the number type, and round it to 2 decimals.
			if (_.isNumber(resultAsNumber) && !isNaN(resultAsNumber) && result.toString().indexOf('.') !== -1) {
				// round to 2 digits and convert back to number
				result = Number(resultAsNumber.toFixed(2));
			}
			calculationResult = {
				hasError: false,
				errorMessage: null,
				result,
				isResultAnObject: _.isObject(result),
				isValueValidAsAComputedCustomValue: _.isString(result) || _.isNumber(result) || _.isBoolean(result),
			};
		}
	} catch (e) {
		debug('mathjs.evaluate produced an error evaluating', expression);
		debug(e);
		calculationResult = {
			hasError: true,
			errorMessage: e.message,
			result: null,
			isResultAnObject: false,
			isValueValidAsAComputedCustomValue: false,
		};
	}
	performCalculationTimer.stop();
	return calculationResult;
}
