import { errorDebug } from '@prism-frontend/utils/static/getDebug';
import _ from 'lodash';

function formatDuration(milliseconds: number): string {
	const seconds: number = Math.floor(milliseconds / 1000);
	const minutes: number = Math.floor(seconds / 60);
	const hours: number = Math.floor(minutes / 60);

	const secondsStr: string = seconds % 60 < 10 ? `0${seconds % 60}` : `${seconds % 60}`;
	const minutesStr: string = minutes % 60 < 10 ? `0${minutes % 60}` : `${minutes % 60}`;
	const hoursStr: string = hours < 10 ? `0${hours}` : `${hours}`;

	return `${hoursStr}h ${minutesStr}m ${secondsStr}s`;
}
export interface Timer {
	/**
	 * starts the timer; if called again, this is a noop until reset or stop is
	 * called. If you wish to track how much time was spent in a function, for example
	 * you can call start/stop at the start/end of the method and the timer will
	 * sum up the amount of time it was running across calls to start/stop.
	 */
	start: () => void;

	/**
	 * returns the time in milliseconds that the timer has been running so far.
	 */
	stop: () => number;

	/**
	 * Return a human readable string reporting on how long the timer was running.
	 * @param pad should the timer name be left padded to match the length of the
	 * longest timer we have created?
	 * @returns string representing how long the timer has run, and how many times
	 *   it was start/stopped as part of the process
	 */
	getTimerOutput: (pad?: boolean) => string;
	getDuration: () => number;

	reset: () => void;
}

function getDelta(start: Date | undefined, stop: Date | undefined): number {
	if (typeof start === 'undefined' || typeof start === 'undefined') {
		return 0;
	}

	return Math.abs(Number(stop) - Number(start));
}

const AllTimerNames: Set<string> = new Set<string>();
let longestTimerName: string;

export function createTimer(name: string): Timer {
	if (AllTimerNames.has(name)) {
		errorDebug(`name ${name} already taken for timer!`);
	}
	AllTimerNames.add(name);
	longestTimerName = _.first(_.sortBy(Array.from(AllTimerNames), 'length').reverse());
	let start: Date | undefined;
	let runCount: number = 0;
	let duration: number = 0;
	return {
		start: (): void => {
			if (start) {
				return;
			}
			start = new Date();
		},
		stop: (): number => {
			if (!start) {
				errorDebug(`${name}: Timer not running! Did you call .start()?`);
				return;
			}
			const delta: number = getDelta(start, new Date());
			duration += delta;
			start = undefined;
			runCount++;
			return duration;
		},
		getDuration(): number {
			if (start) {
				errorDebug(`${name}: Timer still running! did you call .stop()?`);
				return;
			}
			return duration;
		},
		getTimerOutput: (pad?: boolean): string => {
			if (start) {
				errorDebug(`${name}: Timer still running! did you call .stop()?`);
			}
			const padding: number = pad ? longestTimerName.length - name.length : 0;
			return `${_.times(padding, _.constant(' ')).join('')}${name}: Elapsed time: ${formatDuration(
				duration
			)} Run count: ${runCount}`;
		},
		reset: (): void => {
			start = undefined;
			duration = 0;
			runCount = 0;
		},
	};
}
