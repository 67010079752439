import { Address } from '@prism-frontend/typedefs/Address';
import { Contact } from '@prism-frontend/typedefs/contact';
import { Currency } from '@prism-frontend/typedefs/enums/currency';
import { TaxType } from '@prism-frontend/typedefs/enums/tax';
import { Stage } from '@prism-frontend/typedefs/stage';
import { TypedTransformFnParams } from '@prism-frontend/typedefs/TypedTransformFnParams';
import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { castToNumber } from '@prism-frontend/utils/transformers/castToNumber';
import { plainToClass, Transform, Type } from 'class-transformer';
import { IsArray, IsBoolean, IsEnum, IsInstance, IsInt, IsNumber, IsOptional, IsString } from 'class-validator';
import _ from 'lodash';

export class Venue extends Address {
	public constructor(venue?: Partial<Venue>) {
		super();
		return plainToClass(Venue, venue);
	}

	@IsInt()
	public id: number;

	@IsString()
	public name: string;

	@IsInt()
	public organization_id: number;

	@IsEnum(Currency)
	public currency: Currency;

	@IsBoolean()
	public can_request_to_host_venue: boolean;

	@IsOptional()
	@IsString()
	public created_at: string | null;

	@IsOptional()
	@IsString()
	public updated_at: string | null;

	@Type((): typeof Boolean => {
		return Boolean;
	})
	@IsBoolean()
	public active: boolean;

	@Type((): typeof Boolean => {
		return Boolean;
	})
	@IsBoolean()
	public convert_to_usd: boolean;

	// TODO PRSM-2813 old properties - add validation
	@IsNumber()
	@IsOptional()
	// seems like this is needed by the back end (OrganizationController::newVenue)
	public capacity: number | null;

	@IsNumber()
	@IsOptional()
	@Transform((transformParams: TypedTransformFnParams<number>): number => {
		const valAsNumber: number = castToNumber()(transformParams);
		if (!valAsNumber) {
			return null;
		}
		return valAsNumber;
	})
	public default_template_id: null | string;

	@IsNumber()
	@IsOptional()
	public default_logo_id: null | number;

	@IsNumber()
	@IsOptional()
	public eb_venue_id: number | null;

	@IsNumber()
	@Transform(castToNumber())
	public facility_fee: number;

	@IsArray()
	@IsOptional()
	public permissions?: string[]; // when loaded through api/venues-list

	@Type((): typeof Stage => {
		return Stage;
	})
	@IsInstance(Stage, { each: true })
	public stages: Stage[] = [];

	@IsNumber()
	@Transform(castToNumber())
	public tax_rate: number;

	@IsEnum(TaxType)
	public tax_type: TaxType = TaxType.DIVISOR;

	@IsString()
	public timezone: string;

	@IsBoolean()
	public show_best_available: boolean;

	@IsNumber()
	@IsOptional()
	@Transform(castToNumber(true))
	public central_venue_id: null | number = null;

	@IsArray()
	public contact_ids: number[] = [];

	@IsArray()
	public contacts: Contact[] = [];

	// only used on the front end:
	public label?: string;
	public value?: number;

	/**
	 * used in VenueMutation. This is an accepted param when creating and updating
	 * a venue. ultimately, this gets stored and represented as this.central_venue_id
	 */
	public from_central_venue_id?: number;

	@IsBoolean()
	public should_create_central_venue: boolean = false;

	@IsNumber()
	@Transform(castToNumber(true))
	public default_hold_level: number | null = null;

	@IsBoolean()
	@Transform(castToBoolean(true))
	public auto_promote_holds: boolean | null = null;

	public get totalCapacity(): number {
		return _.reduce(
			this.stages,
			(totalCapacity: number, stage: Stage): number => {
				return totalCapacity + stage.capacity;
			},
			0
		);
	}
}
