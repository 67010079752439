import { DocumentNode, QueryOptions } from '@apollo/client/core';
import { PrismGraphqlService } from '@prism-frontend/services/api/graphql/prism-graphql.service';
import { instantiateApiResponse } from '@prism-frontend/services/legacy/api.service';
import { PaginatedData, PaginationDetails } from '@prism-frontend/typedefs/graphql/PaginationDetails';
import { InstantiatedAPIResponseAndValidationResults } from '@prism-frontend/utils/decorators/validate-children';
import { Debug, getDebug, verboseDebug } from '@prism-frontend/utils/static/getDebug';
import { Timer, createTimer } from '@prism-frontend/utils/static/timer';
import PQueue from 'p-queue';
const debug: Debug = getDebug('query-all-entities');

export interface PageData<T> {
	percentage: number;
	total: number;
	runningCount: number;
	totalNumberPages: number;
	pageNumber: number;
	data: T[];
}
export type OnPageCallback<T> = (pageData: PageData<T>) => void;
export const DefaultOnPageCallback: OnPageCallback<unknown> = (): void => {
	return;
};

export type PaginatedNetworkResponse<T extends PaginationDetails> = Record<string, T>;

/**
 * get an array of PrismEvents via our paginated eventList graphql query
 *
 * @param eventsQueryVariables filter options for events
 * @param onPageCallback called when a page of events is loaded
 * @param onTotal called when the first totals are loaded
 * @param abortSignal an abort signal, passed to cancel any queries
 * @returns Promise<PrismEvent[]>
 */
export const instantiateApiResponseTimer: Timer = createTimer(`query-all-entities-instantiate-api-response`);
let callCounter: number = 0;
export async function queryAllEntities<ObjectQueryVariables, QueriedObjectType>(
	prismGraphqlService: PrismGraphqlService,
	entityQueryVariables: ObjectQueryVariables,
	queryToUse: DocumentNode,
	responseDataKey: string,
	EntityClassConstructor: Function,
	onPageCallback: OnPageCallback<QueriedObjectType> = DefaultOnPageCallback,
	abortSignal?: AbortSignal,
	forceNetworkRequest?: boolean,
	pageSize: number = 250,
	concurrency: number = 5
): Promise<QueriedObjectType[]> {
	// set up the abort controller at the top of every request
	// we no longer pass the abort controller down to graphql, as cancelling an in-progress
	// HTTP request messes with the graphql cache, causing queries to hang if you navigate away from
	// and back from the query in question
	//
	// see more in this github issue here:
	// https://github.com/apollographql/apollo-client/issues/10271
	let wasAborted: boolean = false;
	abortSignal?.addEventListener('abort', (): void => {
		wasAborted = true;
		debug(`signal aborted`, wasAborted);
	});

	const timer: Timer = createTimer(`query-all-entities ${callCounter++}`);
	timer.start();
	debug('querying all entities with variables', entityQueryVariables);
	debug('concurrency', concurrency);
	debug('page size', pageSize);

	function getQueryParams(forPage: number): QueryOptions {
		return {
			query: queryToUse,
			variables: {
				...entityQueryVariables,
				page: forPage,
				limit: pageSize,
			},
			fetchPolicy: forceNetworkRequest ? 'network-only' : undefined,
		};
	}

	async function getResultsForPage<T extends PaginationDetails>(page: number): Promise<T> {
		const nextPage: PaginatedNetworkResponse<T> = (await prismGraphqlService.query<T>(
			getQueryParams(page)
		)) as unknown as PaginatedNetworkResponse<T>;

		if (!nextPage) {
			throw new Error('next page was not found');
		}

		const pageResults: T = nextPage[responseDataKey];

		if (!pageResults) {
			throw new Error(`nothing found in page for key ${responseDataKey}, options: ${Object.keys(nextPage)}`);
		}

		return pageResults;
	}

	if (wasAborted) {
		return [];
	}

	const firstPage: PaginatedData<QueriedObjectType> = await getResultsForPage<PaginatedData<QueriedObjectType>>(1);

	if (wasAborted) {
		return [];
	}

	const totalEntityCount: number = firstPage.total;
	const totalNumPages: number = Math.ceil(totalEntityCount / pageSize);
	debug('totalEntityCount', totalEntityCount);
	debug('totalNumPages', totalNumPages);

	// now iterate over all pages and get all the events
	let runningEntityList: QueriedObjectType[] = [];
	async function handlePageResults(pageResults: PaginatedData<QueriedObjectType>): Promise<void> {
		debug('handling page results', pageResults.current_page, pageResults.data.length);
		if (wasAborted) {
			return;
		}

		timer.stop();
		instantiateApiResponseTimer.start();
		const entities: InstantiatedAPIResponseAndValidationResults<QueriedObjectType[]> = await instantiateApiResponse<
			QueriedObjectType[]
		>(EntityClassConstructor, pageResults.data);
		instantiateApiResponseTimer.stop();

		const percentage: number = totalEntityCount === 0 ? 0 : (runningEntityList.length / totalEntityCount) * 100;

		onPageCallback({
			data: entities.data,
			total: totalEntityCount,
			runningCount: runningEntityList.length,
			percentage,
			totalNumberPages: totalNumPages,
			pageNumber: pageResults.current_page,
		});
		runningEntityList = runningEntityList.concat(entities.data);
		verboseDebug('queryAllEntities running list length', runningEntityList.length);
		timer.start();
	}

	await handlePageResults(firstPage);

	const queue: PQueue = new PQueue({ concurrency });

	for (let curPage: number = 2; curPage <= totalNumPages; curPage++) {
		queue.add(async (): Promise<void> => {
			if (wasAborted) {
				debug('wasAborted, returning');
				return;
			}

			const pageResults: PaginatedData<QueriedObjectType> = await getResultsForPage<
				PaginatedData<QueriedObjectType>
			>(curPage);

			debug('pageResults', pageResults);

			if (wasAborted) {
				debug('wasAborted, returning');
				return;
			}

			await handlePageResults(pageResults);
		});
	}

	debug('queue size', queue.size);
	await queue.onIdle();
	debug('returning runningEntityList', runningEntityList.length);
	timer.stop();
	debug(timer.getTimerOutput());
	return runningEntityList;
}
