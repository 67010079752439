import { Pipe, PipeTransform } from '@angular/core';
import { formatPhoneNumber } from '@prism-frontend/utils/static/formatPhoneNumber';

@Pipe({
	name: 'phone',
})
export class PhonePipe implements PipeTransform {
	public transform(value: unknown): unknown {
		if (!value) {
			return value;
		}

		return formatPhoneNumber(value as string);
	}
}
