import { Currency } from '@prism-frontend/typedefs/enums/currency';

/**
 * Based on a provided currency retrieves its symbol
 *
 * @param currency the currency we want to get its symbol
 * @returns the formatted symbol for the given currency
 */
export function getSymbolForCurrency(currency: Currency): string {
	if (!currency) {
		currency = Currency.USD;
	}
	// gets an international formatter for a given currency
	const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-US', {
		currency,
		style: 'currency',
	});
	// formats the number 0 for the current currency and break the result
	// into parts
	const currencyFormattedParts: Intl.NumberFormatPart[] = currencyFormatter.formatToParts(0);
	// Grab the currency formatted part
	const currencyFormat: Intl.NumberFormatPart = currencyFormattedParts.find(
		(part: Intl.NumberFormatPart): boolean => {
			return part.type === 'currency';
		}
	);
	return currencyFormat ? currencyFormat.value : '$';
}
