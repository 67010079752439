import { Injectable } from '@angular/core';
import { WithAutoUnsubscribeService } from '@prism-frontend/utils/static/auto-unsubscribe';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';

/**
 * add types here to help namespace the queue IDs
 */
type QueueKey =
	| 'additional-revenue'
	| 'attendance'
	| 'contact-roles'
	| 'ems-field'
	| 'event-fees'
	| 'event-search'
	| 'event-service'
	| 'event-template-editor'
	| 'event-template-list'
	| 'fixed-costs'
	| 'flat-ticket-revenue'
	| 'presettlement-ticketing-fees'
	| 'simple-table-column-widths'
	| 'simple-table-inline-edit'
	| 'task-templates'
	| 'tasks'
	| 'ticket-commissions'
	| 'tickets'
	| 'tour'
	| 'tour-event-deals'
	| 'tour-fixed-costs'
	| 'tour-tickets'
	| 'variable-costs';

/**
 * Docs: docs/promise-queue.service.md
 *
 * Passing a queue id makes sure that different resources can be processed in parallel
 * without locking each-other's saving queue.
 * A queue id represents one resource and a queue contains newer versions
 * of the resource to be saved. Only the newest available version is saved
 * when the queue becomes ready to continue processing
 *
 * promiseQueue$ is an RxJS Observable which processes the stream of save functions to call.
 * There's one queue per queueId
 *
 * lastSkippedItemsToProcess is an object tracking the latest version of unsaved data per queue id
 */

interface Payload<T> {
	queueId: string;
	queueKey: QueueKey;
	save: () => Promise<T>;

	// a value in millseconds. if specified when using `PromiseQueueService.push`,
	// this service will wait this many milliseconds before processing the save call
	requestTimeout: number;
}

@Injectable({
	providedIn: 'root',
})
export class PromiseQueueService<T> extends WithAutoUnsubscribeService {
	public static readonly defaultRequestTimeout: number = 400;

	public finishedSaving$: Observable<string>;
	public get busyQueueIds(): string[] {
		return this._busyQueueIds;
	}

	private promiseQueue$: Subject<Payload<T>> = new Subject<Payload<T>>();
	private _finishedSaving$: Subject<string> = new Subject<string>();
	private lastSkippedItemsToProcess: { [key: string]: Payload<T> } = {};
	private timeoutByQueueId: { [key: string]: number } = {};
	private _busyQueueIds: string[] = [];

	public static payloadQueueId(payload: Payload<unknown>): string {
		return `${payload.queueKey}-${payload.queueId}`;
	}

	public constructor() {
		super();
		this.initialize();
		// turn the publicly accessible finishedSaving$ into a read-only Observable
		this.finishedSaving$ = this._finishedSaving$.asObservable();
	}

	public push(
		queueId: string | number = '',
		queueKey: QueueKey,
		save: () => Promise<T>,
		requestTimeout: number = PromiseQueueService.defaultRequestTimeout
	): void {
		this.promiseQueue$.next({
			queueId: String(queueId),
			queueKey,
			save,
			requestTimeout,
		});
	}

	protected initialize(): void {
		this.addSubscription(
			this.promiseQueue$.subscribe((payload: Payload<T>): void => {
				this.processSaving(payload);
			})
		);
	}

	protected processSaving(payload: Payload<T>): void {
		const queueId: string = PromiseQueueService.payloadQueueId(payload);
		const requestTimeout: number = payload.requestTimeout || 0;
		const isQueueBusy: boolean = this._busyQueueIds.includes(queueId);

		if (isQueueBusy) {
			this.lastSkippedItemsToProcess[queueId] = payload;
			return;
		}

		// set up this save function. if specified, we will set a timeout and wait
		// until then to save
		const save: Function = (): void => {
			this._busyQueueIds.push(queueId);

			payload.save().finally((): void => {
				this._busyQueueIds = _.without(this._busyQueueIds, queueId);
				this._finishedSaving$.next(payload.queueId);

				if (!(queueId in this.lastSkippedItemsToProcess)) {
					return;
				}
				const lastSkippedItemToProcess: Payload<T> = this.lastSkippedItemsToProcess[queueId];
				delete this.lastSkippedItemsToProcess[queueId];

				// Push the most recent unsaved data to the promiseQueue$ queue
				this.promiseQueue$.next(lastSkippedItemToProcess);
			});
		};

		// clear old timeouts if they exist
		if (this.timeoutByQueueId[queueId]) {
			clearTimeout(this.timeoutByQueueId[queueId]);
		}

		// set a timeout if we need, or just call the function
		if (requestTimeout) {
			this.timeoutByQueueId[queueId] = setTimeout(save, requestTimeout);
		} else {
			save();
		}
	}

	public cancel(queueId: string | number = '', queueKey: QueueKey): void {
		this.push(
			queueId,
			queueKey,
			(): Promise<T> => {
				return Promise.resolve(undefined);
			},
			0
		);
	}
}
