export enum DealStatus {
	NULL = 'null',
	DRAFT_OFFER = 'draft_offer',
	DRAFT_OFFER_SUBMITTED = 'draft_offer_submitted',
	DRAFT_OFFER_APPROVED = 'draft_offer_approved',
	DRAFT_OFFER_DECLINED = 'draft_offer_declined',
	OFFER_SENT = 'offer_sent',
	OFFER_RECEIVED = 'offer_received',
	OFFER_IN_NEGOTIATION = 'offer_in_negotiation',
	OFFER_CONFIRMED = 'offer_confirmed',
	DRAFT_CONTRACT_SUBMITTED = 'draft_contract_submitted',
	DRAFT_CONTRACT_APPROVED = 'draft_contract_approved',
	CONTRACT_SENT = 'contract_sent',
	CONTRACT_RECEIVED = 'contract_received',
	CONTRACT_IN_NEGOTIATION = 'contract_in_negotiation',
	CONTRACT_SIGNED = 'contract_signed',
	CONTRACT_COUNTERSIGNED = 'contract_countersigned',
	CANCELLED = 'cancelled',
	DECLINED = 'declined',
	INACTIVE = 'inactive',
}
