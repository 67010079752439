import { ADDITIONAL_DATE_FULL_TIME_INPUT_FORMAT, MOMENT_DATE_FORMAT } from '@prism-frontend/utils/static/dateHelper';
import { IsNumber, IsString } from 'class-validator';
import moment from 'moment';

export class AdditionalDate {
	@IsNumber() public id?: number;

	@IsString() public title: string;

	@IsString() public theDate: string;

	@IsString() public theTime: string;

	@IsNumber() public event_id: number;

	public get hasTime(): boolean {
		return this.theTime !== null && this.theDate !== undefined && this.theTime.length > 0;
	}

	public get fullDate(): string {
		let response: string = this.theDate;
		if (this.theTime) {
			response += ' ' + this.theTime;
		}
		return response;
	}

	public get formatted(): string {
		let inputFormat: string = MOMENT_DATE_FORMAT;
		let format: string = 'dddd, MMMM Do YYYY';
		if (this.hasTime) {
			inputFormat = ADDITIONAL_DATE_FULL_TIME_INPUT_FORMAT;
			format += ' hh:mm a';
		}
		return moment(this.fullDate, inputFormat).format(format);
	}
}
