export enum EventFeeType {
	FLAT_PER_TICKET_ALL = 'flat_per_ticket_all',
	FLAT_PER_TICKET_TYPE = 'flat_per_ticket_type',
	PERCENT_PER_TICKET_TYPE = 'percent_per_ticket_type',
	PERCENT_OF_GROSS = 'percent_of_gross',
	PERCENT_OF_ADJUSTED_GROSS = 'percent_of_adjusted_gross',
	FLAT_OUT_OF_GROSS = 'flat_out_of_gross',
	FLAT_OUT_OF_ADJUSTED_GROSS = 'flat_out_of_adjusted_gross',
}

export const EventFeeWithTicket: Set<EventFeeType> = new Set([
	EventFeeType.FLAT_PER_TICKET_TYPE,
	EventFeeType.PERCENT_PER_TICKET_TYPE,
]);

export const eventFeeTypeVisibleSections: Set<EventFeeType> = new Set([
	EventFeeType.PERCENT_OF_GROSS,
	EventFeeType.PERCENT_PER_TICKET_TYPE,
	EventFeeType.FLAT_OUT_OF_GROSS,
	EventFeeType.PERCENT_OF_ADJUSTED_GROSS,
	EventFeeType.FLAT_OUT_OF_ADJUSTED_GROSS,
]);
