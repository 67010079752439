import { Injectable } from '@angular/core';
import { PrismUpdatedDialogComponent } from '@prism-frontend/components/prism-updated-dialog/prism-updated-dialog.component';
import { ComponentModalizerService } from '@prism-frontend/services/ui/component-modalizer.service';

@Injectable({
	providedIn: 'root',
})
export class RefreshService {
	public constructor(private componentModalizerService: ComponentModalizerService) {}
	private REFRESH_INITIATED: boolean = false;

	public doRefresh(): void {
		if (this.REFRESH_INITIATED) {
			return;
		}
		this.REFRESH_INITIATED = true;

		this.componentModalizerService.openDialog<PrismUpdatedDialogComponent, void>(
			PrismUpdatedDialogComponent,
			{
				countDownTime: 5,
			},
			{},
			{
				fullScreenOnMobile: true,
				includeCloseButton: false,
				matDialogOptions: {
					disableClose: true,
					width: '400px',
				},
			}
		);
	}
}
