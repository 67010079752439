<h5 class="prism-updated-header">We've made some updates!</h5>
<p>A new version of Prism is being deployed and requires a refresh of your browser page.</p>
<p>This page will automatically refresh, or you can try to refresh now.</p>
<p>If this warning pops up again please wait a few more moments and try again.</p>

<button mat-button class="btn primary-button refresh-now-button" (click)="refreshNow()">Refresh Now</button>

<div class="countdown-timer">
	<loading-container [loading]="true" [progress]="remainingProgressPercentage"></loading-container>

	<span class="refresh-seconds">{{ refreshSecondsRemaining }}s left</span>
</div>
