import { getReadableForegroundTextFor } from '@prism-frontend/utils/static/static-color-helpers';
import { PrismDate } from '@prism-frontend/typedefs/date';
import { PrismEvent } from '@prism-frontend/typedefs/event';
import { Permission } from '@prism-frontend/typedefs/permission';
import { Stage } from '@prism-frontend/typedefs/stage';
import { TalentData } from '@prism-frontend/typedefs/talentData';
import { plainToClass, Type } from 'class-transformer';
import { IsBoolean, IsInstance, IsInt, IsOptional } from 'class-validator';
import moment from 'moment';

export type TalentDataForHold = Pick<TalentData, 'id' | 'talent_agent_id' | 'talent' | 'headliner'> & {
	event_id: number;
};

export interface ArtistIdFilterable {
	hasArtistId: (artistId: number) => boolean;
}

export class HoldForCalendar implements ArtistIdFilterable {
	public constructor(holdForCalendar: Partial<HoldForCalendar>) {
		return plainToClass(HoldForCalendar, holdForCalendar);
	}

	@IsInt() public id: number;

	@IsInt() public event_id: number;

	@IsInt() public hold_level: number = 0;

	@Type((): typeof Boolean => {
		return Boolean;
	})
	@IsBoolean()
	public cleared: boolean = false;

	@Type((): typeof Boolean => {
		return Boolean;
	})
	@IsBoolean()
	public was_accepted: boolean = false;

	@IsOptional()
	@IsInt()
	public cleared_by: number | null = null;

	// PRSM-3057 this should be optional
	@Type((): typeof PrismEvent => {
		return PrismEvent;
	})
	@IsInstance(PrismEvent)
	public event: PrismEvent = new PrismEvent();

	// This should be named stages and contain all stages on the event associated with the hold
	@Type((): typeof Stage => {
		return Stage;
	})
	@IsInstance(Stage, { each: true })
	public stage: Stage[] = [];

	@Type((): typeof PrismDate => {
		return PrismDate;
	})
	@IsInstance(PrismDate)
	public date: PrismDate;

	public permissions?: Permission[];

	// This denotes whether this hold is a 'placeholder', event name is not visible,
	// but it will show whether there is a confirmed event or a hold and what level
	@Type((): typeof Boolean => {
		return Boolean;
	})
	@IsBoolean()
	public isPlaceholder: boolean = false;

	// User has 'manage-holds' permission and is allowed to drag a hold in the calendar
	@Type((): typeof Boolean => {
		return Boolean;
	})
	@IsBoolean()
	public startEditable: boolean = false;

	// used by the front-end when creating shared holds requests
	@Type((): typeof Boolean => {
		return Boolean;
	})
	@IsBoolean()
	public isRequest: boolean = false;

	@Type((): typeof Boolean => {
		return Boolean;
	})
	@IsBoolean()
	public isSharedAndNew: boolean = false;

	// for backwards compatibility:
	// returns "2020-06-03" format
	public get hold_date(): string {
		return this.date.date.split(' ')[0];
	}

	// for backwards compatibility:
	public get all_day(): boolean {
		return this.date.all_day;
	}

	// for backwards compatibility:
	public set all_day(flag: boolean) {
		this.date.all_day = flag;
	}

	// for backwards compatibility:
	// returns "2020-07-19 00:00:00" format
	public get start_time(): string {
		return this.date.start_time;
	}

	// for backwards compatibility:
	// returns "2020-07-19 00:00:00" format
	public get end_time(): string {
		return this.date.end_time;
	}

	// for backwards compatibility:
	public get stage_id(): number {
		return this.stage[0].id;
	}

	public get color(): string {
		return this.stage[0].color;
	}

	public get readableColor(): string {
		return getReadableForegroundTextFor(this.stage[0].color);
	}

	public get title(): string {
		// Placeholders will not have an event
		if (this.isPlaceholder) {
			return 'Hold';
		}
		return this.event.title;
	}

	public get start(): moment.Moment {
		return moment(this.hold_date);
	}

	/**
	 * returns true if a hold has a talent deal matching the artist ID, false otherwise
	 */
	public hasArtistId(artistId: number): boolean {
		return (
			this.event.talent_data.filter((talent: TalentData): boolean => {
				return talent.talent_agent_id === artistId;
			}).length > 0
		);
	}
}
