import {
	AllCustomFieldConfigs,
	CustomFieldBackend,
	CustomFieldEventBackend,
	CustomFieldProcessType,
	CustomFieldsById,
	isCustomFieldForEvent,
} from '@prism-frontend/entities/custom-fields/custom-fields-typedefs';
import { castCustomValue } from '@prism-frontend/entities/custom-fields/static/castCustomValue';
import _ from 'lodash';

/**
 * Takes an array of custom fields, and "scrubs" them. This means that the
 * default_Value and event_value properties are passed through castCustomValue,
 * and cast to their proper types. This function is intelligent and works on a
 * custom field object weather it is a CustomFieldBackend or CustomFieldEventBackend.
 * Note that for advanced custom fields, this function is effectively a noop.
 *
 * This function only needs to exist because the backend can not return
 * variadic types to us in the event_value and default_value fields–on the backend,
 * these properties are always of type string. Any more strict typing takes place
 * in the front end.
 * @param fields
 * @param customFieldsToProcess when interpolating advanced custom field values,
 *   we need to do 2 passes–first to resolve the basic custom field values, and second
 *   to resolve the advanced custom field values (this is because advanced custom fields
 *    can insert chips representing the value for basic custom fields).
 *    This parameter controls whether or not we should process basic custom fields, or
 *    all custom fields on this pass.
 * @returns list of custom fields matching the given customFieldsToProcess, with all
 * 	of their default_value and event_value properties cast from strings to their
 *  proper (primitive) types.
 */
export function scrubCustomFields<T extends CustomFieldBackend | CustomFieldEventBackend = CustomFieldBackend>(
	fields: T[],
	customFieldsToProcess: CustomFieldProcessType = 'all'
): CustomFieldsById<AllCustomFieldConfigs> {
	return _.chain(fields)
		.filter((value: T): boolean => {
			if (customFieldsToProcess === 'all') {
				return true;
			}
			const isAdvanceCustomField: boolean = value.is_advanced;

			if (customFieldsToProcess === 'basic') {
				return !isAdvanceCustomField;
			}
			throw new Error(`unrecognized customFieldsToProcess ${customFieldsToProcess}`);
		})
		.mapValues((value: T): AllCustomFieldConfigs => {
			let castedValue: AllCustomFieldConfigs = {
				...value,
				default_value: castCustomValue(value.default_value, value),
			} as AllCustomFieldConfigs;
			if (isCustomFieldForEvent(value)) {
				castedValue = {
					...castedValue,
					event_value: castCustomValue(value.event_value, value),
				} as AllCustomFieldConfigs;
			}
			return castedValue;
		})
		.keyBy('custom_field_id')
		.value();
}
