import { FormatFieldByType } from '@prism-frontend/typedefs/ems/ems-field.typedefs';
import { EMSFieldMeta } from '@prism-frontend/typedefs/ems/EMSFieldMeta';
import { Currency } from '@prism-frontend/typedefs/enums/currency';
import { EMSFieldType } from '@prism-frontend/typedefs/enums/EMSFieldType';
import { dumbPluralize } from '@prism-frontend/utils/static/strings';
import * as _ from 'lodash';

export function renderValueForField(value: unknown, field: EMSFieldMeta, currency: Currency, event_id: number): string {
	if (!field) {
		return '';
	}

	if (field.isNullable && value === null) {
		return '';
	}

	if (!field.type.endsWith('[]') && _.isArray(value)) {
		return _.chain(value)
			.map((subVal: unknown, idx: number): string => {
				return `(${idx}) ${renderValueForField(subVal, field, currency, event_id)}`;
			})
			.value()
			.join(',');
	}
	if (FormatFieldByType[field.type]) {
		// never render dollars as a negative number
		if (field.type === EMSFieldType.dollarAmount && _.isNumber(value) && value < 0) {
			value *= -1;
		}
		/**
		 * return nothing if there is no talent deal for status, only possible
		 * on email template form while previewing an event with no talent deal
		 */
		if (field.type === EMSFieldType.DealStatus && !value) {
			return '';
		}
		return FormatFieldByType[field.type](value, currency, event_id);
	}
	if (field.type.endsWith('[]') && _.isArray(value)) {
		return `${value.length} ${dumbPluralize('item', value.length)}`;
	}
	return value as string;
}
