import { CoreAdjustment } from '@prism-frontend/typedefs/CoreAdjustment';
import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { castToNumber } from '@prism-frontend/utils/transformers/castToNumber';
import { plainToClass, Transform } from 'class-transformer';
import { IsBoolean, IsInt, IsNumber, IsString } from 'class-validator';
import _ from 'lodash';

export class PayoutAdjustment implements CoreAdjustment {
	public constructor(adjustment?: Partial<PayoutAdjustment>) {
		return plainToClass(PayoutAdjustment, adjustment);
	}

	@IsInt() public id: number | null = null;

	@IsString() public name: string = '';

	@IsNumber()
	@Transform(castToNumber())
	public amount: number = 0;

	@IsString() public notes: string = '';

	@IsBoolean()
	@Transform(castToBoolean())
	public is_artist_bonus_adjustment: boolean = false;

	@IsBoolean()
	@Transform(castToBoolean())
	public affects_withholding: boolean = false;

	@IsBoolean()
	@Transform(castToBoolean())
	public affects_artist_earnings: boolean = false;

	@IsBoolean()
	@Transform(castToBoolean())
	public affects_split_point: boolean = false;

	@IsBoolean()
	@Transform(castToBoolean())
	public affects_external_settlement_only: boolean = false;

	public isValid(): boolean {
		return this.name && this.name.length > 1 && !isNaN(this.amount);
	}
}

export function isTalentPayoutAdjustment(adjustment: CoreAdjustment): adjustment is PayoutAdjustment {
	return (
		_.has(adjustment, 'is_artist_bonus_adjustment') &&
		_.has(adjustment, 'affects_withholding') &&
		_.has(adjustment, 'affects_artist_earnings') &&
		_.has(adjustment, 'affects_split_point') &&
		_.has(adjustment, 'affects_external_settlement_only')
	);
}
