/**
 * given an object that represents GET params to be passed to an HTTP request,
 * convert to laravel conventions
 *
 *  1. convert arrays to laravel array syntax
 *
 * @param params an object representing HTTP GET params
 * @returns an object that can be passed to angular's HttpClient.get() method
 */
export function convertGETParamsToLaravelConventions(params: Object): Object {
	const returnParams: Object = {
		...params,
	};

	Object.keys(returnParams).forEach((key: string): void => {
		const val: unknown = returnParams[key];
		if (Array.isArray(val)) {
			returnParams[`${key}[]`] = val;
			delete returnParams[key];
		}
	});

	return returnParams;
}
