import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { PrismBadgeModule } from '@prism-frontend/components/prism-badge/prism-badge.module';
import { MaterialModule } from '@prism-frontend/modules/material.module';
import { ThemeService } from '@prism-frontend/services/ui/theme.service';

@Component({
	selector: 'theme-toggle',
	template: `
		<div class="theme-toggle">
			<ng-container *ngIf="!themeService.userPrefersSystemTheme">
				<mat-icon>sunny</mat-icon>
				<mat-slide-toggle
					[checked]="themeService.activeTheme === 'dark'"
					[disabled]="themeService.userPrefersSystemTheme"
					[hideIcon]="true"
					(change)="themeService.toggleLightDarkTheme()"
					(click)="$event.stopPropagation()"
					>Theme</mat-slide-toggle
				>
				<mat-icon>brightness_2</mat-icon>
			</ng-container>
			<ng-container *ngIf="themeService.userPrefersSystemTheme">
				<mat-icon>{{ themeService.activeTheme === 'dark' ? 'brightness_2' : 'sunny' }}</mat-icon>
			</ng-container>
			<prism-badge>Beta</prism-badge>
		</div>
		<div class="system-theme">
			<mat-checkbox
				[checked]="themeService.userPrefersSystemTheme"
				(change)="themeService.toggleSystemThemePreference()"
				(click)="$event.stopPropagation()"
			>
				System theme
			</mat-checkbox>
		</div>
	`,
	styleUrls: ['./theme-toggle.component.scss'],
	standalone: true,
	imports: [CommonModule, MaterialModule, PrismBadgeModule],
})
export class ThemeToggleComponent {
	public constructor(public themeService: ThemeService) {}
}
