export enum DealTypes {
	Flat = 'flat',
	DoorDeal = 'door',
	Plus = 'plus',
	Verse = 'verse',
	None = '',
}
export const DealTypeLabels: Record<DealTypes, string> = {
	flat: 'Flat',
	door: 'Door Deal',
	plus: 'Plus',
	verse: 'Versus',
	'': '(None)',
};

export const ValidDealTypes: DealTypes[] = Object.values(DealTypes).filter((dealType: DealTypes): boolean => {
	return dealType !== DealTypes.None;
});

export const DealTypeClassNames: { [key in DealTypes]: string } = {
	[DealTypes.Flat]: 'js-toggle-deal-type-flat',
	[DealTypes.DoorDeal]: 'js-toggle-deal-type-door',
	[DealTypes.Plus]: 'js-toggle-deal-type-plus',
	[DealTypes.Verse]: 'js-toggle-deal-type-verse',
	[DealTypes.None]: 'js-toggle-deal-type-none',
};
