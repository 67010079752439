import { EMSFieldCategory, EMSFieldMeta } from '@prism-frontend/typedefs/ems/EMSFieldMeta';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';
import { Permission } from '@prism-frontend/typedefs/permission';
import * as _ from 'lodash';

type EMSFieldMetadataValueWithCostCalc2Variance<T> = {
	[key in CostCalc2]?: T;
} & { DEFAULT: T };
export type EMSFieldMetadataValueByCostCalc<T> = T | EMSFieldMetadataValueWithCostCalc2Variance<T>;
export function isEMSFieldMetadataValueByCostCalc<T>(
	config: unknown,
	test: (val: unknown) => val is T
): config is EMSFieldMetadataValueByCostCalc<T> {
	if (test(config)) {
		return true;
	}
	if (_.isObject(config) && !!(config as EMSFieldMetadataValueWithCostCalc2Variance<T>).DEFAULT) {
		return true;
	}
	return false;
}
export function isEMSFieldMetadataValueByCostCalcASingleValue<T>(
	config: unknown,
	test: (val: unknown) => val is T
): config is EMSFieldMetadataValueByCostCalc<T> {
	if (test(config)) {
		return true;
	}
	return false;
}
function resolveProp<T>(
	prop: keyof EMSFieldMeta,
	field: EMSFieldMeta,
	test: (val: unknown) => val is T,
	forCostCalc2?: CostCalc2,
	fallback?: T
): T {
	if (_.isUndefined(field[prop])) {
		if (!_.isUndefined(fallback)) {
			return fallback;
		}
		throw new Error(`field ${field.emsMetadataId} has no ${prop}`);
	}
	const fieldData: unknown = field[prop];
	if (!isEMSFieldMetadataValueByCostCalc<T>(fieldData, test)) {
		if (!_.isUndefined(fallback)) {
			return fallback;
		}
		throw new Error(`field ${field.emsMetadataId}.${prop} is not recognized as a EMSFieldExplainerText`);
	}
	// fieldData is no longer unknown, its for sure a EMSFieldExplainerText
	if (test(fieldData)) {
		return fieldData;
	}

	// fieldData is no longer possibly a string it MUST be a EMSFieldExplainerTextWithCostCalc2Variance
	// IF the caller of this method didn't specify a costCalc2, OR there is no
	// specified CostCalc2 value for this particular field
	// THEN return the default text
	if (!forCostCalc2 || !fieldData[forCostCalc2]) {
		return fieldData.DEFAULT;
	}

	// Otherwise return the value for this particular CostCalc2
	return fieldData[forCostCalc2];
}

export function resolveLabel(field: EMSFieldMeta, forCostCalc2?: CostCalc2, fallback?: string): string {
	return resolveProp('userFacingLabel', field, _.isString, forCostCalc2, fallback);
}

export function resolvePermissions(field: EMSFieldMeta, forCostCalc2?: CostCalc2, fallback?: string[]): Permission[] {
	return resolveProp('permissions', field, _.isArray, forCostCalc2, fallback);
}

export function resolveDescription(field: EMSFieldMeta, forCostCalc2?: CostCalc2, fallback?: string): string {
	return resolveProp('userFacingDescription', field, _.isString, forCostCalc2, fallback);
}

export function resolveInternalDescription(field: EMSFieldMeta, forCostCalc2?: CostCalc2, fallback?: string): string {
	return resolveProp('internalDescription', field, _.isString, forCostCalc2, fallback);
}
/**
 * Given some EMSFieldMeta, return its corresponding EMSFieldCategory
 * @throws if the given metadata lacks a category.
 *   If you have been asked to surface this property in an event table or
 *   the template interpolation dropdown, Talk to product and request a parent
 *   category for this field.
 * @param field
 * @returns the EMSFieldCategory for the field. It is safe to display this string
 *   directly to the user.
 */

export function resolveCategory(field: EMSFieldMeta): EMSFieldCategory {
	if (!field.category) {
		throw new Error(`field ${field.emsMetadataId} must have a category`);
	}
	return field.category;
}
