import { Injectable } from '@angular/core';
import { ApiService } from '@prism-frontend/services/legacy/api.service';
import * as _ from 'lodash';

interface SetUserSettingApiPayload<T> {
	name: string;
	value: T;
	version: number;
	// If the user did not have the setting, a message "No permissions with name..." comes back
	message?: string;
}

@Injectable({ providedIn: 'root' })
export class JSONBlobSettingsService {
	public constructor(private apiService: ApiService) {}

	public storeJSONBlob<T>(key: string, version: number, value: T): Promise<void> {
		const payload: SetUserSettingApiPayload<T> = {
			name: key,
			value,
			version,
		};
		return this.apiService
			.postS<SetUserSettingApiPayload<T>, void>(this.apiService.ep.SET_USER_SETTING, payload)
			.toPromise();
	}

	public fetchStoredBlob<T>(
		key: string,
		defaultValue: T,
		upgrades: { [key: number]: (arg: unknown) => unknown }
	): Promise<T> {
		return (
			this.apiService
				.getS<void, SetUserSettingApiPayload<string>>([this.apiService.ep.GET_USER_SETTING, key])
				.toPromise()
				// needs better typedef
				.then((data: SetUserSettingApiPayload<string>): T => {
					let value: T = defaultValue;
					// Load defaults when there's no result
					if (_.has(data, 'value')) {
						try {
							value = JSON.parse(data.value);
						} catch (e) {}
					}
					if (_.has(data, 'version')) {
						value = performVersionUpgrades<T>(value, data.version, upgrades);
					} else if (!data.message || !data.message.startsWith('No permissions with name')) {
						// eslint-disable-next-line no-console
						console.warn('Backend does not yet support versioning');
					}
					return value;
				})
		);
	}
}

/**
 * This method runs an object through a series of upgrades.
 * If you are familiar with the way DB migrations work across different versions
 * of native apps, this approach is similar.
 * @param startingValue  The current value for the object
 * @param startingVersion  The current value for the object
 * @param upgrades	This is an object mapping numbers to functions. For any
 *   given key, n the upgrade performed by the function is from `n` to `n+1`.
 *   Thus the "latestVersion" of the object represented by key will be assumed
 *   to be the <largest key of upgrades> + 1. The method representing the largest
 *   key in upgrades will return an object of type T, while methods from previous
 *   versions will likely accept and return some version of T from an older version
 *   of the code.
 *   The upgrades will be run in succession, so if the user had version 1 stored,
 *   when fetching this item, upgrades from key 1 => latestVersion - 1 will execute.
 */
export function performVersionUpgrades<T = never>(
	startingValue: T,
	startingVersion: number,
	upgrades: { [key: number]: (arg: unknown) => unknown }
): T {
	const upgradeNums: number[] = Object.keys(upgrades)
		.map((k: string): number => {
			return +k;
		})
		.sort();
	const latestVersion: number = _.last(upgradeNums) + 1;

	// Ensure that the developer has provided an upgrade path for each version
	// from 0 to latestVersion
	for (let i: number = 0; i < latestVersion; i++) {
		if (!_.isFunction(upgrades[i])) {
			throw new Error(`Expected to find an upgrade entry for version ${i} => ${i + 1}`);
		}
	}
	// Apply any version upgrades to the fetched object, if applicable
	for (let i: number = startingVersion; i < latestVersion; i++) {
		// Let's say that latestVersion = 3, but the user hasn't used this feature
		// of prism since the code wrote version 1 of the object to localStorage.
		// Since then, we've enhanced the feature, upgrading it to v3. In doing so,
		// we would have defined upgrades methods for key `2` and key `3`, and passed
		// them to the method here.
		//
		// Since localStorage gave us version 1 of the object, we need to step
		// through the upgrade methods from currentVersion to latestVersion-1
		// and morph the object to ensure the calling code recieves it in the proper
		// format
		const upgradeMethod: (curVal: unknown) => unknown = upgrades[i];
		startingValue = upgradeMethod(startingValue) as T;
	}
	return startingValue;
}
