import { CostCalc } from '@prism-frontend/typedefs/enums/calc';
import { Currency } from '@prism-frontend/typedefs/enums/currency';
import { EventFeeType, EventFeeWithTicket } from '@prism-frontend/typedefs/enums/EventFeeType';
import { FlatTicketRevenue } from '@prism-frontend/typedefs/FlatTicketRevenue';
import { EventPropsForTicket, Ticket } from '@prism-frontend/typedefs/ticket';
import { formatAmount } from '@prism-frontend/utils/static/format-amount';
import { verboseDebug } from '@prism-frontend/utils/static/getDebug';
import { castToNumber } from '@prism-frontend/utils/transformers/castToNumber';
import { plainToInstance, Transform } from 'class-transformer';
import { IsEnum, IsNumber, IsOptional, IsString } from 'class-validator';
import _ from 'lodash';

export class EventFee {
	public constructor(eventFee?: Partial<EventFee>) {
		Object.assign(this, plainToInstance(EventFee, eventFee));
	}
	@IsNumber() public id: number | null = null;

	@IsNumber()
	@Transform(castToNumber(true))
	public order: number;

	@IsString() public name: string = '';

	@IsNumber() public amount: number = 0;

	@IsNumber() public event_id: number | null = null;

	@IsNumber() public estimated_fee: number | null = null;
	@IsNumber() public potential_fee: number | null = null;
	@IsNumber() public actual_fee: number | null = null;

	/**
	 * this value is a number on event pages, and a UUID string in templates
	 */
	@IsNumber()
	@IsOptional()
	public ticket_id: number | string | null = null;

	@IsEnum(EventFeeType) public type: EventFeeType = EventFeeType.FLAT_PER_TICKET_ALL;

	// Use getters to get around a limitation with the mat-select
	// that won't allow us to use nulls as a select value
	public get ticketSelectId(): string {
		return `${this.type}:${this.ticket_id}`;
	}
	public set ticketSelectId(value: string) {
		const parts: string[] = value.split(':');
		this.type = parts[0] as EventFeeType;

		if (EventFeeWithTicket.has(this.type)) {
			this.ticket_id = !isNaN(Number(parts[1])) ? Number(parts[1]) : parts[1];
		} else {
			this.ticket_id = null;
		}
	}

	public getDisplayName(tickets: Ticket[], currency: Currency): string {
		const relevantTicket: Ticket = _.find(tickets, (ticket: Ticket): boolean => {
			return ticket.id === this.ticket_id;
		});
		if (this.eventFeeRequiresTicket && !relevantTicket) {
			// eslint-disable-next-line no-console
			console.error(`Missing ticket associated with eventFee: ${this.name} ${this.id}`);
		}
		if (this.type === EventFeeType.PERCENT_PER_TICKET_TYPE) {
			return `${this.name} (${this.amount.toFixed(2)}% per ticket ${
				relevantTicket ? relevantTicket.name : '(TICKET DELETED)'
			})`;
		}
		if (this.type === EventFeeType.FLAT_PER_TICKET_TYPE) {
			return `${this.name} (${formatAmount(this.amount, currency)} per ticket ${
				relevantTicket ? relevantTicket.name : '(TICKET DELETED)'
			})`;
		}
		if (this.type === EventFeeType.FLAT_PER_TICKET_ALL) {
			return `${this.name} (${formatAmount(this.amount, currency)} per ticket)`;
		}
		if (this.type === EventFeeType.PERCENT_OF_GROSS) {
			return `${this.name} (${this.amount}% of gross)`;
		}

		if (this.type === EventFeeType.PERCENT_OF_ADJUSTED_GROSS) {
			return `${this.name} (${this.amount}% of adjusted gross)`;
		}

		if (this.type === EventFeeType.FLAT_OUT_OF_GROSS) {
			return `${this.name} (Flat Before Tax)`;
		}

		if (this.type === EventFeeType.FLAT_OUT_OF_ADJUSTED_GROSS) {
			return `${this.name} (Flat After Tax)`;
		}

		return `${this.name} (${formatAmount(this.amount, currency)})`;
	}

	public getPercentAmount(totalNumber: number): number {
		return totalNumber - totalNumber / (1 + this.amount / 100);
	}

	public eventFee(
		costCalc: CostCalc,
		tickets: Ticket[],
		flatTickets: FlatTicketRevenue[],
		eventProps: EventPropsForTicket,
		totalFlatPreSettlementFeesOutOfGross: number = 0
	): number {
		const relatedTicket: Ticket = this.findRelatedTicket(tickets);

		switch (this.type) {
			case EventFeeType.FLAT_PER_TICKET_ALL:
				// this type applies to all tiers, and thus has no relatedTicket
				const ticketCount: number = tickets.reduce((memo: number, ticket: Ticket): number => {
					return memo + ticket.ticketsSold(costCalc);
				}, 0);
				return this.amount * ticketCount;
			case EventFeeType.PERCENT_OF_GROSS:
				const ticketGross: number = tickets.reduce((memo: number, ticket: Ticket): number => {
					return memo + ticket.ticketRevenue(costCalc);
				}, 0);
				const flatTicketGross: number = flatTickets.reduce(
					(memo: number, flatTicket: FlatTicketRevenue): number => {
						return memo + flatTicket.ticketRevenue(costCalc);
					},
					0
				);
				// this type applies to all tiers, and thus has no relatedTicket
				const gross: number = ticketGross + flatTicketGross;
				return this.getPercentAmount(gross);
			case EventFeeType.FLAT_PER_TICKET_TYPE:
				// it is assumed this type has a relatedTicket associated
				if (!relatedTicket) {
					// seems like we should throw here, or that it would
					// indicate an invalid fee that should be removed from
					// the event
					verboseDebug(`expected to find a relatedTicket to eventFee ${this.id}`);
					return 0;
				}
				return this.amount * relatedTicket.ticketsSold(costCalc);
			case EventFeeType.PERCENT_PER_TICKET_TYPE:
				// it is assumed this type has a relatedTicket associated
				if (!relatedTicket) {
					// seems like we should throw here, or that it would
					// indicate an invalid fee that should be removed from
					// the event
					verboseDebug(`expected to find a relatedTicket to eventFee ${this.id}`);
					return 0;
				}
				const ticketRevenue: number = relatedTicket.ticketRevenue(costCalc);
				return this.getPercentAmount(ticketRevenue);

			case EventFeeType.PERCENT_OF_ADJUSTED_GROSS:
				const ticketAdjustedGross: number = tickets.reduce((memo: number, ticket: Ticket): number => {
					return memo + ticket.adjustedGross(costCalc, eventProps);
				}, 0);
				const flatTicketAdjustedGross: number = flatTickets.reduce(
					(memo: number, flatTicket: FlatTicketRevenue): number => {
						return memo + flatTicket.adjustedGross(costCalc, eventProps);
					},
					0
				);

				const adjustedGross: number =
					ticketAdjustedGross + flatTicketAdjustedGross - totalFlatPreSettlementFeesOutOfGross;
				return this.getPercentAmount(adjustedGross);

			case EventFeeType.FLAT_OUT_OF_GROSS:
			case EventFeeType.FLAT_OUT_OF_ADJUSTED_GROSS:
				return this.getUserCostCalFee(costCalc);

			default:
				throw new Error(`Unrecognized EventFeeType: ${this.type}`);
		}
	}

	/**
	 * calculate the fees per-ticket on a given ticket tier
	 * for flat fees, this
	 *
	 * note this should only be called for an applicable event fee/ticket pairings
	 *
	 * @param ticket the ticket on which the event fees will be calculated
	 * @returns the total event fees that apply per-ticket
	 */
	public perTicketEventFee(ticket: Ticket): number {
		switch (this.type) {
			// flat types, just total the amount
			case EventFeeType.FLAT_PER_TICKET_ALL:
			case EventFeeType.FLAT_PER_TICKET_TYPE:
				return this.amount;

			// for percent type, get the total and divide by tickets sold
			case EventFeeType.PERCENT_OF_GROSS:
			case EventFeeType.PERCENT_PER_TICKET_TYPE:
				return this.getPercentAmount(ticket.ticket_price);
			case EventFeeType.PERCENT_OF_ADJUSTED_GROSS:
				return 0;
			case EventFeeType.FLAT_OUT_OF_GROSS:
			case EventFeeType.FLAT_OUT_OF_ADJUSTED_GROSS:
				return 0;
			default:
				throw new Error(`Unrecognized EventFeeType: ${this.type}`);
		}
	}

	private findRelatedTicket(tickets: Ticket[]): Ticket {
		if (!this.ticket_id) {
			return null;
		}
		return tickets.find((ticket: Ticket): boolean => {
			return ticket.id === this.ticket_id;
		});
	}

	public get eventFeeRequiresTicket(): boolean {
		return this.type === EventFeeType.PERCENT_PER_TICKET_TYPE || this.type === EventFeeType.FLAT_PER_TICKET_TYPE;
	}

	public computeAdjustedGrossFee(adjustedGross: number): number {
		return this.getPercentAmount(adjustedGross);
	}

	private getUserCostCalFee(costCalc: CostCalc): number {
		switch (costCalc) {
			case CostCalc.Estimated:
				return this.estimated_fee || 0;
			case CostCalc.Potential:
			case CostCalc.Budgeted:
				return this.potential_fee || 0;
			case CostCalc.Reported:
			case CostCalc.Actual:
				return this.actual_fee || 0;
			default:
				return 0;
		}
	}
}
