import { Injectable } from '@angular/core';
import { PrismGraphqlService } from '@prism-frontend/services/api/graphql/prism-graphql.service';
import { instantiateApiResponse } from '@prism-frontend/services/legacy/api.service';
import {
	ArtistInvoiceQuery,
	ArtistInvoiceQueryArguments,
	DEFAULT_LIST_INVOICES_QUERY_ARGUMENTS,
	DeleteInvoiceMutation,
	DeleteInvoiceMutationArguments,
	ListInvoicesQueryArguments,
	PaginatedListInvoicesQuery,
	UpdateInvoiceMutation,
	UpdateInvoiceMutationArguments,
	UpdateInvoiceMutationResponse,
} from '@prism-frontend/typedefs/graphql/Invoice';
import { InvoiceOrReceipt } from '@prism-frontend/typedefs/invoice';
import { InstantiatedAPIResponseAndValidationResults } from '@prism-frontend/utils/decorators/validate-children';
import {
	DefaultOnPageCallback,
	OnPageCallback,
	queryAllEntities,
} from '@prism-frontend/utils/static/query-all-entities';

@Injectable({ providedIn: 'root' })
export class InvoiceGraphqlService {
	public constructor(private prismGraphqlService: PrismGraphqlService) {}

	public getInvoicesForOrg(
		args: Partial<Omit<ListInvoicesQueryArguments, 'orderBy' | 'sortDirection'>>,
		onPageCallback: OnPageCallback<InvoiceOrReceipt> = DefaultOnPageCallback
	): Promise<InvoiceOrReceipt[]> {
		const variables: ListInvoicesQueryArguments = {
			...DEFAULT_LIST_INVOICES_QUERY_ARGUMENTS,
			...args,
		};
		return queryAllEntities(
			this.prismGraphqlService,
			variables,
			PaginatedListInvoicesQuery,
			'invoiceList',
			InvoiceOrReceipt,
			onPageCallback,
			undefined,
			true
		);
	}

	/**
	 * given an artist ID, fetch all the invoices for that artist
	 *
	 * @param artistId the ID of the artist to fetch
	 * @returns an array of InvoiceOrReceipt class instances
	 */
	public getInvoicesByArtist(artistId: number): Promise<InvoiceOrReceipt[]> {
		const variables: ArtistInvoiceQueryArguments = {
			talent_agent_id: artistId,
			orderBy: 'org_invoice_id',
			sortDirection: 'desc',
		};
		return queryAllEntities(
			this.prismGraphqlService,
			variables,
			ArtistInvoiceQuery,
			'invoiceList',
			InvoiceOrReceipt,
			undefined,
			undefined,
			true
		);
	}

	/**
	 * save a new invoice. to start, this only handled payment_status
	 *
	 * @param newInvoice a Pick of the InvoiceOrReceipt class representing everything editable in the Invoice/Receipt
	 * @returns a full InvoiceOrReceipt object
	 */
	public updateInvoice(newInvoice: UpdateInvoiceMutationArguments): Promise<InvoiceOrReceipt> {
		return this.prismGraphqlService
			.mutate<UpdateInvoiceMutationArguments, UpdateInvoiceMutationResponse>({
				mutation: UpdateInvoiceMutation,
				variables: newInvoice,
			})
			.then(async (response: UpdateInvoiceMutationResponse): Promise<InvoiceOrReceipt> => {
				const results: InstantiatedAPIResponseAndValidationResults<InvoiceOrReceipt> =
					await instantiateApiResponse(InvoiceOrReceipt, response.updateInvoice);
				return results.data;
			});
	}

	/**
	 * delete an invoice
	 *
	 * @param invoiceId the database ID of the invoice to be deleted
	 */
	public deleteInvoice(invoiceId: number): Promise<void> {
		return this.prismGraphqlService.mutate<DeleteInvoiceMutationArguments, void>({
			mutation: DeleteInvoiceMutation,
			variables: {
				id: invoiceId,
			},
		});
	}
}
