import { Artist } from '@prism-frontend/entities/artists/artist-typedefs';
import { Address, AddressOmitProps } from '@prism-frontend/typedefs/Address';
import { ContactRole } from '@prism-frontend/typedefs/ContactRole';
import { formatPhoneNumber } from '@prism-frontend/utils/static/formatPhoneNumber';
import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { Transform, Type, plainToClass } from 'class-transformer';
import { IsArray, IsBoolean, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';

// Contact and ContactInterface usage in the code is not consistent unfortunately
type ContactInterface = Omit<Contact, AddressOmitProps>;

export type ContactSuggestion = Pick<Contact, 'id' | 'name' | 'email' | 'phone' | 'contact_roles'>;

export class Contact extends Address implements ContactInterface {
	public constructor(contact?: Partial<Contact>) {
		super();
		return plainToClass(Contact, contact);
	}

	@IsNumber() public id: number;

	@IsString() public name: string;

	@IsString() public email: string = '';

	@IsString() public company: string;

	@IsString()
	@IsOptional()
	public created_at?: string;

	@IsArray()
	@Type((): typeof ContactRole => {
		return ContactRole;
	})
	@ValidateNested()
	public contact_roles: ContactRole[] = [];

	@IsString()
	@IsOptional()
	public phone: string | null;

	@IsString()
	@IsOptional()
	public notes: string | null;

	@IsString()
	@IsOptional()
	public updated_at?: string;

	@IsString()
	@IsOptional()
	public markup?: string;

	/**
	 * talent_agents are defined as setter+getter in order to
	 * populate the artistNames string which will be used for
	 * filtering over contacts table and over the generated csv
	 *
	 * This tech debt can be removed once filterPredicates are
	 * implemented over simple tables api in order to define
	 * custom filtering criterias for configured fields
	 *
	 * A similar thing happens now for contact_roles, which info
	 * comes along the 'position' property as well from the be.
	 * If Back end would send us this info, setters and getters
	 * wouldn't be necessary.
	 */
	private artists: Artist[];
	public artist_names: string;

	@IsArray()
	@Type((): typeof Artist => {
		return Artist;
	})
	@ValidateNested()
	public set talent_agents(talents: Artist[]) {
		this.artists = talents;
		this.artist_names = (this.talent_agents || [])
			.map((talent: Artist): string => {
				return talent.artistName;
			})
			.join(', ');
	}

	public get talent_agents(): Artist[] {
		return this.artists;
	}

	public get rolesString(): string {
		return this.contact_roles
			.map((role: ContactRole): string => {
				return role.name;
			})
			.join(', ');
	}

	public get formattedPhoneNumber(): string {
		return formatPhoneNumber(this.phone);
	}

	public rolesForPDF(): string {
		return this.contact_roles
			.filter((role: ContactRole): boolean => {
				return role.show_in_docs;
			})
			.map((role: ContactRole): string => {
				return role.name;
			})
			.join(', ');
	}
}

export class PrismEventContact extends Contact {
	public constructor(contact?: Partial<PrismEventContact>) {
		super();
		return plainToClass(PrismEventContact, contact);
	}

	@IsString()
	@IsOptional()
	public event_notes?: string;

	@IsBoolean()
	@Transform(castToBoolean())
	public include_in_pdfs: boolean;
}

export interface BulkContactsEdit {
	contact_ids: number[];
	artists: Artist[];
}
export interface AssociateContacts {
	contact_ids: number[];
}
