import { TypedTransformFnParams } from '@prism-frontend/typedefs/TypedTransformFnParams';
import { verboseDebug } from '@prism-frontend/utils/static/getDebug';
import * as _ from 'lodash';

/**
 * This method is a factory for a method that can be passed to @Transform().
 * The decorated property will be cast to a number when it is not one.
 * Usage: @Transform(castToNumber())
 * @param allowNull - If true, the method will return null when the value is null.
 *   if false, the method will return 0 when the value is null.
 */
export function castToNumber(allowNull: boolean = false): (transformParams: TypedTransformFnParams<number>) => number {
	return (transformParams: TypedTransformFnParams<number>): number => {
		const value: number = transformParams.value;
		if (_.isNumber(value)) {
			return value;
		}

		// numbers can sometimes be null, like an ID field in the DB that is optionally null
		if (allowNull && value === null) {
			return value;
		}
		verboseDebug(
			`Neded to cast ${transformParams.key} from ${typeof value} (${value}) to a number. Please fix the backend.`
		);
		const castedValue: number = Number(value);
		if (isNaN(castedValue)) {
			verboseDebug(`NaN detected after casting ${transformParams.key} (${value}) to a number.`);
		}
		return castedValue;
	};
}
