import {
	AllCustomFieldConfigs,
	AllCustomFieldTypes,
	CustomFieldBackend,
	flattenCustomFieldType,
} from '@prism-frontend/entities/custom-fields/custom-fields-typedefs';
import { EMSRollup } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { PrismEvent } from '@prism-frontend/typedefs/event';
import { MOMENT_DATE_FORMAT } from '@prism-frontend/utils/static/dateHelper';
import { formatAmount } from '@prism-frontend/utils/static/format-amount';
import moment from 'moment';

const CustomValueCasterMap: {
	[key in AllCustomFieldTypes]: (value: string) => unknown;
} = {
	text: (value: string): string => {
		return value || '';
	},
	textarea: (value: string): string => {
		return value || '';
	},
	number: (value: string): number => {
		return value ? Number(value) : 0;
	},
	currency_amount: (value: string): number => {
		return value ? Number(value) : 0;
	},
	toggle: (value: string): boolean => {
		return value === '1' || value === 'true';
	},
	date: (value: string): string | null => {
		return value ? moment(value).format(MOMENT_DATE_FORMAT) : null;
	},
	advanced_computed: (value: string): string | null => {
		return value || '';
	},
	advanced_computed_currency: (value: string): string | null => {
		return value || '';
	},
	advanced_string_template: (value: string): string | null => {
		return value || '';
	},
	advanced_editable: (value: string): string | null => {
		return value || '';
	},
};

/**
 * Casts a custom field `value` from a string to its proper type, as defined by `field`.
 * This low level helper exists because the backend can not return variadic types to us
 * in the event_value and default_value fields–on the backend, these properties are always
 * of type string.
 *
 * The frontend needs to cast both the default_value property and the event_value property
 * to their proper types. This function allows us to cast either value, by accepting
 * the value itself as the first argument.
 *
 * In this way, higher-level helpers (such as scrubCustomFields or
 * normalizeEventCustomFieldValues) can call this function twice, once for each
 * property that needs to be cast.
 *
 * Note that for advanced custom fields, this function is effectively a noop.
 *
 * This function is the inverse of stringifyCustomValue.
 *
 * @param value the value to cast. This should be the value for default_value or evrnt_value
 * 	on the field, as it was sent by the backend.
 * @param type
 * @returns
 */
export function castCustomValue(value: string, field: CustomFieldBackend): unknown {
	const flatType: AllCustomFieldTypes = flattenCustomFieldType(field);
	if (CustomValueCasterMap[flatType]) {
		return CustomValueCasterMap[flatType](value);
	}
	throw new Error(`unrecognized type ${flatType}`);
}

const CustomValueStringifierMap: {
	[key in AllCustomFieldTypes]: (value: unknown) => string;
} = {
	text: (value: string): string => {
		return value.toString();
	},
	textarea: (value: string): string => {
		return value.toString();
	},
	number: (value: number): string => {
		return value.toString();
	},
	currency_amount: (value: number): string => {
		return value.toString();
	},
	toggle: (value: boolean): string => {
		return (!!value).toString();
	},
	date: (value: string): string => {
		return value ? moment(value).format(MOMENT_DATE_FORMAT) : '';
	},
	advanced_computed: (value: string): string => {
		return value || '';
	},
	advanced_computed_currency: (value: string): string => {
		return value || '';
	},
	advanced_string_template: (value: string): string => {
		return value || '';
	},
	advanced_editable: (value: string): string => {
		return value || '';
	},
};

/**
 * This function is responsible for taking a custom field's value (either the
 * default_value or the event_value) and converting it BACK in to a string that
 * we can send to the backend. This function is the inverse of castCustomValue.
 *
 * This is necessary:
 *
 * 1. When we update or save a custom field **at the org level**, the backend
 *   expects a string value, regardless of the type of the custom field.
 * 2. The same is true when we save an event-level custom field value.
 *
 * @param value
 * @param field
 * @returns
 */
export function stringifyCustomValue(value: unknown, field: CustomFieldBackend): string {
	const flatType: AllCustomFieldTypes = flattenCustomFieldType(field);
	if (CustomValueStringifierMap[flatType]) {
		return CustomValueStringifierMap[flatType](value || '');
	}
	throw new Error(`unrecognized type ${flatType}`);
}

const CustomValueDisplayValueMap: {
	[key in AllCustomFieldTypes]: (value: unknown, event: PrismEvent | EMSRollup) => string;
} = {
	...CustomValueStringifierMap,
	toggle: (value: boolean | string): string => {
		if (value === true) {
			return 'Yes';
		}
		if (value === false) {
			return 'No';
		}
		return value.toString();
	},
	currency_amount: (value: number, event: PrismEvent | EMSRollup): string => {
		const currencyAmount: number = value ? Number(value) : 0;
		return formatAmount(currencyAmount, event.currency);
	},
	advanced_computed: (value: string, _event: PrismEvent | EMSRollup): string | null => {
		if ((value as unknown as number) === 0) {
			return '0';
		}
		if (!value) {
			return '';
		}
		return value;
	},
	advanced_computed_currency: (value: string, event: PrismEvent | EMSRollup): string | null => {
		if ((value as unknown as number) === 0) {
			return formatAmount(0, event.currency);
		}
		if (!value) {
			return '';
		}
		return formatAmount(Number(value), event.currency);
	},
	number: (value: number): string => {
		return value ? value.toString() : '';
	},
};

/**
 * Not to be confused with stringifyCustomValue, this function ALSO converts a
 * custom field's value to a string, however in this case, it converts it to a string
 * that we can display to the user, in the UI. This means that for custom fields
 * of type currency, we will format the value using the event's currency, etc.
 *
 * Note that advanced custom fields are supported by this function, but it is assumed
 * that their value was resolved external to this function. Presently, we only ever
 * resolve advanced custom field values as part of normalizeEventCustomFieldValues function,
 * which uses this method internally.
 * @param field
 * @param event
 * @returns
 */
export function customFieldDisplayValue(field: AllCustomFieldConfigs, event: PrismEvent | EMSRollup): string {
	const flatType: AllCustomFieldTypes = flattenCustomFieldType(field);
	if (CustomValueDisplayValueMap[flatType]) {
		return CustomValueDisplayValueMap[flatType](field.event_value, event);
	}
	throw new Error(`unrecognized type ${flatType}`);
}
