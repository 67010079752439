import _ from 'lodash';
import { Subscription } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SetUpSubscriptionMethod = (...args: any[]) => void;

export type ComponentOutputMap<T> = {
	[key in keyof T]?: SetUpSubscriptionMethod;
};

/**
 * Helper method for applying a set of values to an intance of a component. Simply
 * let this method know which component you need to set values against, and a
 * mapping of the property names on the component to their desired value, and
 * this method will do the rest.
 * @param component the component instance on which to apply the inputs
 * @param inputs object defining the inputs to apply to the component
 */
export function applyInputsToComponentInstance<T>(
	component: T,
	inputs: Partial<T>,
	simulateChanges: boolean = false
): void {
	const syntheticChanges: Record<string, unknown> = {};
	Object.keys(inputs).forEach((key: string): void => {
		const previousValue: unknown = component[key];
		component[key] = inputs[key];
		syntheticChanges[key] = {
			currentValue: inputs[key],
			previousValue,
		};
	});
	// @ts-ignore
	if (simulateChanges && _.isFunction(component.ngOnChanges)) {
		// @ts-ignore
		component.ngOnChanges(syntheticChanges);
	}
}

/**
 * A method that helps set up subscriptions against a component. Simply
 * let this method know which component you need to listen to events agains, and a
 * mapping of the @Output names on the component to the handler you wish to run when
 * the component emits the corresponding event.
 * @param component the component instance on which to apply the inputs
 * @param outputs object defining which outputs we want to setup on the component
 * 		Each output should be a function that accepts arguments and does something with it
 * @param fixedArguments list of arguments which will be passed, in order, to all of the
 * 		callback methods when the component subscription runs
 * @returns a list of Subscriptions that were setup as part of the call to this method.
 * 		TAKE CARE TO UNSUBSCRIBE TO THESE SUBSCRIPTIONS YOURSELF WHEN YOU NO LONGER NEED THEM
 */
export function initializeComponentOutputs<T>(
	component: T,
	outputs: ComponentOutputMap<T>,
	fixedArguments: unknown[] = []
): Subscription[] {
	const resultingSubscriptions: Subscription[] = [];
	Object.keys(outputs).forEach((key: string): void => {
		if (!_.isFunction(component[key]?.subscribe)) {
			throw new Error(`key ${key} does not appear to name an @Output() on ${component.constructor.name}`);
		}
		resultingSubscriptions.push(
			component[key].subscribe((...args: unknown[]): void => {
				outputs[key](...fixedArguments, ...args);
			})
		);
	});
	return resultingSubscriptions;
}
