import { getMappedColor } from '@prism-frontend/utils/static/static-color-helpers';
import { Stage } from '@prism-frontend/typedefs/stage';

// Tech-debt (this should rather be a Laravel migration): fix colors for stages if they are in green/blue/red format instead of #51bc51/#008bff/#ff887c
export function fixDeprecatedColor(stageOrStages: Stage | Stage[]): void {
	let stages: Stage[];
	if (Array.isArray(stageOrStages)) {
		stages = stageOrStages;
	} else {
		stages = [];
	}

	stages.forEach((stage: Stage): string => {
		return (stage.color = getMappedColor(stage.color));
	});
}

export function stageArrayToLabel(stages: Stage[]): string {
	if (stages.length === 0) {
		return 'TBD';
	}
	if (stages.length === 1) {
		return stages[0].name || 'TBD';
	}
	if (stages.length > 1) {
		return 'Multiple Stages';
	}
}
