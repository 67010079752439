import { Contact } from '@prism-frontend/typedefs/contact';
import { Deposit } from '@prism-frontend/typedefs/deposit';
import { PayoutAdjustment } from '@prism-frontend/typedefs/payoutAdjustment';
import { renterBadgePlaceholderSrc } from '@prism-frontend/typedefs/promoter-badge-placeholder';
import { Settlement } from '@prism-frontend/typedefs/settlement';
import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { castToNumber } from '@prism-frontend/utils/transformers/castToNumber';
import { plainToClass, Transform, Type } from 'class-transformer';
import { IsBoolean, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';

export class RentalData {
	public constructor(rentalData?: Partial<RentalData>) {
		return plainToClass(RentalData, rentalData);
	}
	/**
	 * The id of the rental deal
	 */
	@IsNumber() public id: number | null = null;

	/**
	 * The list of payout adjustments for the rental deal
	 */
	@Type((): typeof PayoutAdjustment => {
		return PayoutAdjustment;
	})
	@ValidateNested()
	public payout_adjustments: PayoutAdjustment[] = [];

	/**
	 * The id of the promoter of the rental deal
	 * TODO PRSM-6192 Change promoter_contact_id to renter_contact_id
	 */
	@IsNumber()
	@Transform(castToNumber())
	public promoter_contact_id: number;

	/**
	 * If the rental deal is for in house tickets
	 */
	@IsBoolean()
	@Transform(castToBoolean())
	public in_house_tickets: boolean = false;

	/**
	 * The percentage of the promoter for the rental deal
	 */
	@IsNumber()
	@Transform(castToNumber())
	// TODO PRSM-6028 Change promoter_percentage to rental/renter_percentage
	public promoter_percentage: number = 0;

	/**
	 * The bonus amount per ticket for the rental deal
	 */
	@IsNumber()
	@Transform(castToNumber())
	public bonus_per_ticket: number = 0;

	/**
	 * The minimum bar amount for the rental deal
	 */
	@IsNumber()
	@Transform(castToNumber())
	public bar_minimum: number = 0;

	/**
	 * The fee amount for the rental deal
	 */
	@IsBoolean()
	@Transform(castToBoolean())
	public manual_room_fee: boolean = true;

	/**
	 * The fee amount for the room of the rental deal
	 */
	@IsNumber()
	@Transform(castToNumber())
	public room_fee: number = 0;

	/**
	 * The list of deposits for the rental deal
	 */
	@Type((): typeof Deposit => {
		return Deposit;
	})
	@ValidateNested()
	public deposits: Deposit[] = [
		new Deposit({
			flat_deposit: true,
			amount: 0,
		}),
	];

	@IsString() public email: string = '';

	/**
	 * The legal terms for the rental deal as html content
	 */
	@IsString()
	@IsOptional()
	public offer_terms: string | null = '';

	/**
	 * The contact data of promoter of the rental deal
	 */
	@Type((): typeof Contact => {
		return Contact;
	})
	@ValidateNested()
	public promoter: Contact = {} as Contact;

	/**
	 * The settlement associated to the rental deal
	 */
	@Type((): typeof Settlement => {
		return Settlement;
	})
	@ValidateNested()
	public settlement: Settlement;

	/**
	 * The renter badge info for the promoter of the rental deal
	 */
	public get renterBadge(): string {
		// Future use case, custom badges for promoters?
		return renterBadgePlaceholderSrc;
	}
	/**
	 * The renter name for the rental deal
	 */
	public get renterName(): string {
		if (this.promoter) {
			return this.promoter.name;
		}
		return '';
	}

	/**
	 * A function to validate the rental deal, checking that the deal
	 * configuration is correct.
	 * @returns {boolean} true if the rental deal is valid, false otherwise
	 */
	public validate(): boolean {
		const fields: string[] = ['bar_minimum', 'room_fee'];

		// zero out empty fields
		fields.forEach((field: string): void => {
			// eslint-disable-next-line eqeqeq
			if (this[field] == '' || !this[field]) {
				this[field] = 0;
			}
		});

		// zero out fields that are hidden
		if (!this.manual_room_fee) {
			this.room_fee = 0;
		}
		// one default deposit of 0
		if (!this.deposits) {
			this.deposits = [
				new Deposit({
					flat_deposit: true,
					amount: 0,
				}),
			];
		}
		return true;
	}

	/**
	 * The total amount of the payout adjustments for the rental deal
	 */
	public get totalRentalAdjustments(): number {
		let total: number = 0;
		this.payout_adjustments.forEach((item: PayoutAdjustment): void => {
			total += Number(item.amount);
		});
		return total;
	}
}
