import { VariableCost } from '@prism-frontend/entities/variable-costs/variable-cost-typedefs';
import { EMSFieldHelperService } from '@prism-frontend/services/ui/ems-field-helper.service';
import { Cost } from '@prism-frontend/typedefs/cost';
import { CostGroup } from '@prism-frontend/typedefs/costGroup';
import { EMSCost, EMSReflectionProps } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';
import { PrismEvent } from '@prism-frontend/typedefs/event';
import { TalentData } from '@prism-frontend/typedefs/talentData';
import { Debug, getDebug } from '@prism-frontend/utils/static/getDebug';
import * as _ from 'lodash';

const debug: Debug = getDebug('ems-field-static-helper');

type EMSCostCalcCostPropertyMapFunction<T> = (record: EMSReflectionProps, value: unknown) => { prop: T; value: number };

type EMSCostCalcCostProperty<T> = {
	[key in CostCalc2]?: T | EMSCostCalcCostPropertyMapFunction<T>;
};

type EMSCostProperty = keyof Cost | keyof VariableCost;

const emsCostsEditPropertyMap: Record<string, EMSCostProperty | EMSCostCalcCostProperty<EMSCostProperty>> = {
	hiddenFromCoPro: 'copro_cost_hidden',
	coProOverriddenAmount: 'copro_overridden_amount',
	expenseTotal: 'copro_overridden_amount',
	name: 'name',
	costAmount: 'budget',
	quantity: 'quantity',
	computedTotal: {
		InternalActual: 'actual_cost',
		InternalEstimated: 'estimated_cost',
		ExternalReported: 'reported_cost',
	},
	total: {
		ExternalBudgeted: (record: EMSCost, value: unknown): { prop: EMSCostProperty; value: number } => {
			return {
				prop: 'budget',
				value: Number(value) / record.quantity,
			};
		},
	},
};

function validateEventUpdate(event: PrismEvent): void {
	if (!event) {
		throw new Error('cant edit an ems field when there is no event');
	}
}

function validateEMSWithIdUpdate(emsEntityToUpdate: unknown, event: PrismEvent): void {
	validateEventUpdate(event);
	if (!emsEntityToUpdate['id']) {
		throw new Error('cant edit an ems field when there is no id');
	}
}

export async function editEMSFixedCost(
	emsFieldEditHelperService: EMSFieldHelperService,
	emsEntityToUpdate: EMSReflectionProps,
	emsPropertyToUpdate: string,
	emsPropertyValue: unknown,
	event: PrismEvent
): Promise<PrismEvent> {
	validateEMSWithIdUpdate(emsEntityToUpdate, event);
	const fixedCost: Cost = getFixedCostToEdit(emsEntityToUpdate, event.cost_groups);
	const costGroupIndex: number = event.cost_groups.findIndex((cost_group: CostGroup): boolean => {
		return cost_group.id === fixedCost.cost_group_id;
	});
	const costGroup: CostGroup = event.cost_groups[costGroupIndex];
	const costIndex: number = costGroup.costs.findIndex((c: Cost): boolean => {
		return c.id === emsEntityToUpdate['id'];
	});
	const editedFixedCost: Cost = new Cost({ ...fixedCost });
	const { fieldToSet, valueToSet } = updateCostFieldToEdit(
		editedFixedCost,
		emsEntityToUpdate,
		emsPropertyToUpdate,
		emsPropertyValue
	);
	if (!Object.prototype.hasOwnProperty.call(editedFixedCost, fieldToSet)) {
		throw new Error(`cant find the underlying event property to set a value for ${fieldToSet}`);
	}

	editedFixedCost[fieldToSet as string] = valueToSet;
	debug('editEMSFixedCost ', editedFixedCost);
	debug('updating at cost group ', costGroupIndex, 'cost ', costIndex, `${emsPropertyToUpdate} to ${valueToSet}`);

	const newCostGroups: CostGroup[] = event.cost_groups;
	newCostGroups[costGroupIndex].costs[costIndex] = editedFixedCost;

	emsFieldEditHelperService.costService.editCostItem(editedFixedCost, costGroup);

	return new PrismEvent({
		...event,
		cost_groups: newCostGroups,
	});
}

export function userCanEditFixedCost(
	emsFieldEditHelperService: EMSFieldHelperService,
	emsEntityToUpdate: unknown,
	event: PrismEvent
): boolean {
	if (event.isSettled) {
		return false;
	}
	const fixedCost: Cost = getFixedCostToEdit(emsEntityToUpdate, event.cost_groups);
	if (!fixedCost) {
		return false;
	}
	const costGroup: CostGroup = event.cost_groups.find((cost_group: CostGroup): boolean => {
		return cost_group.id === fixedCost.cost_group_id;
	});
	if (!costGroup) {
		return false;
	}
	return emsFieldEditHelperService.permissionsService.userCanOnCostCategory('edit', costGroup.category, event);
}

function getFixedCostToEdit(emsEntityToUpdate: unknown, costGroups: CostGroup[]): Cost {
	return _.chain(costGroups)
		.map((costGroup: CostGroup): Cost[] => {
			return costGroup.costs;
		})
		.flatten()
		.value()
		.find((cost: Cost): boolean => {
			return cost.id === emsEntityToUpdate['id'];
		});
}

export async function editEMSVariableCost(
	emsFieldEditHelperService: EMSFieldHelperService,
	emsEntityToUpdate: EMSReflectionProps,
	emsPropertyToUpdate: string,
	emsPropertyValue: unknown,
	event: PrismEvent
): Promise<PrismEvent> {
	validateEMSWithIdUpdate(emsEntityToUpdate, event);
	const editedVariableCosts: VariableCost = new VariableCost({
		...event.variable_costs.find((cost: VariableCost): boolean => {
			return cost.id === emsEntityToUpdate['id'];
		}),
	});
	const { fieldToSet, valueToSet } = updateCostFieldToEdit(
		editedVariableCosts,
		emsEntityToUpdate,
		emsPropertyToUpdate,
		emsPropertyValue
	);
	if (!Object.prototype.hasOwnProperty.call(editedVariableCosts, fieldToSet)) {
		throw new Error(`cant find the underlying event property to set a value for ${fieldToSet}`);
	}

	editedVariableCosts[fieldToSet as string] = valueToSet;
	debug('editEMSVariableCost ', editedVariableCosts);
	await emsFieldEditHelperService.costService.saveVariableCost(event.id, editedVariableCosts);

	return new PrismEvent({
		...event,
		variable_costs: event.variable_costs.map((vc: VariableCost): VariableCost => {
			if (vc.id === editedVariableCosts.id) {
				return editedVariableCosts;
			}
			return vc;
		}),
	});
}

function updateCostFieldToEdit(
	recordToUpdate: Cost | VariableCost,
	emsEntityToUpdate: EMSReflectionProps,
	emsPropertyToUpdate: string,
	emsPropertyValue: unknown
): {
	fieldToSet: EMSCostProperty | EMSCostCalcCostProperty<EMSCostProperty>;
	valueToSet: unknown;
} {
	let fieldToSet: EMSCostProperty | EMSCostCalcCostProperty<EMSCostProperty> =
		emsCostsEditPropertyMap[emsPropertyToUpdate];
	let valueToSet: unknown = emsPropertyValue;
	if (!_.isString(fieldToSet)) {
		const updateField: EMSCostCalcCostProperty<EMSCostProperty> = fieldToSet[
			emsEntityToUpdate.costCalc
		] as EMSCostCalcCostProperty<EMSCostProperty>;
		if (!_.isFunction(updateField)) {
			fieldToSet = updateField;
		} else {
			const { prop, value } = updateField(recordToUpdate, emsPropertyValue);
			fieldToSet = prop;
			valueToSet = value;
		}
	}
	return {
		fieldToSet,
		valueToSet,
	};
}

const emsTalentPayoutEditPropertyMap: Record<string, keyof TalentData> = {
	hiddenFromCoPro: 'copro_artist_payout_hidden',
	actualAdjustedArtistPayout: 'copro_overridden_artist_payout',
	coProOverrideArtistPayout: 'copro_overridden_artist_payout',
};

export async function editEMSTalentPayout(
	emsFieldEditHelperService: EMSFieldHelperService,
	emsEntityToUpdate: unknown,
	emsPropertyToUpdate: string,
	emsPropertyValue: unknown,
	event: PrismEvent
): Promise<PrismEvent> {
	validateEMSWithIdUpdate(emsEntityToUpdate, event);
	const editedTalent: TalentData = new TalentData({
		...event.talent_data.find((talent: TalentData): boolean => {
			return talent.id === emsEntityToUpdate['id'];
		}),
	});
	const fieldToSet: string = emsTalentPayoutEditPropertyMap[emsPropertyToUpdate];

	if (!Object.prototype.hasOwnProperty.call(editedTalent, fieldToSet)) {
		throw new Error(`cant find the underlying event property to set a value for ${fieldToSet}`);
	}

	editedTalent[fieldToSet] = emsPropertyValue;
	debug('editEMSTalentPayout ', editedTalent);

	await emsFieldEditHelperService.dealTrackerGraphqlService.updateDealTrackerDeal({
		id: editedTalent.id,
		copro_overridden_artist_payout: editedTalent.copro_overridden_artist_payout,
		copro_artist_payout_hidden: editedTalent.copro_artist_payout_hidden,
	});

	emsFieldEditHelperService.notifyEMSFieldsaved();

	return new PrismEvent({
		...event,
		talent_data: event.talent_data.map((td: TalentData): TalentData => {
			if (td.id === editedTalent.id) {
				return editedTalent;
			}
			return td;
		}),
	});
}
const emsEventEditPropertyMap: Record<string, keyof PrismEvent> = {
	additionalSupport: 'copro_overridden_additional_support',
	coProOverridenAdditionalSupport: 'copro_overridden_additional_support',
	additionalSupportHiddenFromCoPro: 'copro_additional_support_hidden',
};

export async function editEMSEventData(
	emsFieldEditHelperService: EMSFieldHelperService,
	_emsEntityToUpdate: unknown,
	emsPropertyToUpdate: string,
	emsPropertyValue: unknown,
	event: PrismEvent
): Promise<PrismEvent> {
	validateEventUpdate(event);
	const editedEvent: PrismEvent = new PrismEvent({ ...event });
	const fieldToSet: string = emsEventEditPropertyMap[emsPropertyToUpdate];

	if (!Object.prototype.hasOwnProperty.call(editedEvent, fieldToSet)) {
		throw new Error(`cant find the underlying event property to set a value for ${fieldToSet}`);
	}

	editedEvent[fieldToSet] = emsPropertyValue;
	debug('editEMSEventData ', editedEvent);

	await emsFieldEditHelperService.eventGraphqlService.updateEvent({
		id: editedEvent.id,
		copro_additional_support_hidden: editedEvent.copro_additional_support_hidden,
		copro_overridden_additional_support: editedEvent.copro_overridden_additional_support,
	});

	emsFieldEditHelperService.notifyEMSFieldsaved();

	return editedEvent;
}
