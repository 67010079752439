import { Stage } from '@prism-frontend/typedefs/stage';
import { TalentData } from '@prism-frontend/typedefs/talentData';
import {
	formatDateRange,
	MOMENT_DATE_FORMAT,
	MOMENT_DAY_AND_DATE_ABBREV,
	MOMENT_TIME_MILLITARY,
	MOMENT_TIME_SHORT,
} from '@prism-frontend/utils/static/dateHelper';
import { joinArray } from '@prism-frontend/utils/static/join-array';
import { dumbPluralize } from '@prism-frontend/utils/static/strings';
import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { plainToClass, Transform } from 'class-transformer';
import { IsBoolean, IsNumber, IsOptional, IsString } from 'class-validator';
import _ from 'lodash';
import moment from 'moment';

export interface RunOfShowPostData {
	offset: number;
	duration: number | null;
	event_talent_id: number | null;
	id: number;
	stage_id: number;
	start_time: string;
	title: string;
	event_id: number;
	include_in_all_docs: boolean;
}
export class RunOfShow implements RunOfShowPostData {
	public constructor(runOfShow?: Partial<RunOfShow>) {
		return plainToClass(RunOfShow, runOfShow);
	}

	/**
	 * This is a derived property from this.relative_to + this.offset.
	 * If no relative_to is specified, this value will be undefined.
	 */
	public get dateSortValue(): moment.Moment | undefined {
		if (!this.relative_to) {
			return undefined;
		}
		let date: moment.Moment = moment(this.relative_to);
		if (this.start_time) {
			date = this.includeStartTimeWithDate(date);
		}
		return date.add(this.offset || 0, 'days');
	}

	/**
	 * Returns two pieces of text that can be used to describe this Run of Show item's
	 * offset in the UI.
	 *
	 * 1. Ff there is no relative_to, the text returned will be 100% relative
	 *    e.g. ["${this.offsetDays} before/after event", ""]
	 * 2. If there IS a relative_to the text returned will reflect the concrete
	 *   date of the Run of Show item. e.g. ["Feb 21, 2022", "Monday"]
	 *   	If a full date object is available including the Run of Show time it will
	 *      be used, otherwise the relative_to date reference will be used as fallback
	 */
	public get offsetExplainerText(): [string, string] {
		if (!this.relative_to) {
			if ((this.offset || 0) === 0) {
				return [`Day of event`, ''];
			}
			if (this.offsetIsBeforeEvent) {
				return [`${this.offsetDays} ${dumbPluralize('day', this.offsetDays)} before event`, ''];
			}
			return [`${this.offsetDays} ${dumbPluralize('day', this.offsetDays)} after event`, ''];
		}
		return formatDateRange(this.dateSortValue, this.dateSortValue);
	}

	/**
	 * Returns a string suitable for displaying to users as it pertains to the
	 * date of the show. Either "XX days before/after event" OR "Feb 21, 2022"
	 */
	public get dateDisplayValue(): string {
		return this.offsetExplainerText[0];
	}

	/**
	 * Returns a FULL date object containing the start time of the Run of Show item.
	 * If no start_time, 00:00:00 is assumed.
	 *
	 * If there is no relative_to on this run of show entry (as is the case in templates)
	 * this method will return undefined.
	 */
	public get startTimeSortValue(): moment.Moment {
		// this if catches templates where there is no relative_to and therefore no date
		// i think a better approach is to just use offset + time?
		if (!this.dateSortValue) {
			return undefined;
		}
		return this.includeStartTimeWithDate(this.dateSortValue);
	}

	/**
	 * Returns a formatted version of the Run of Show's start time. Will always return
	 * a time only in the format of XX:YY AM/PM, even if there is no relative_to on this item.
	 */
	public get startTimeDisplayValue(): string {
		if (!this.start_time) {
			return '';
		}
		if (!this.dateSortValue) {
			// if there is no date (which is derived from relative_to), we are likely editing a template
			// where there is no concrete event involved. So we use today and format our response with time only
			return RunOfShow.formatTimeFor(moment(`${moment().format(MOMENT_DATE_FORMAT)} ${this.start_time}`));
		}
		return RunOfShow.formatTimeFor(this.startTimeSortValue);
	}

	/**
	 * Returns a FULL date object containing the end time of the Run of Show item.
	 * If no start_time, 00:00:00 is assumed and the end time is irrelevant, 0, and
	 * equivalent to the start time.
	 */
	public get endTimeSortValue(): moment.Moment {
		// this if catches templates where there is no relative_to and therefore no date
		// i think a better approach is to just use offset + time?
		if (!this.dateSortValue) {
			return undefined;
		}
		return this.startTimeSortValue.add(this.duration || 0, 'minutes');
	}

	/**
	 * Returns a formatted version of the Run of Show's end time. Will always return
	 * a time only in the format of XX:YY AM/PM, even if there is no relative_to on this item.
	 */
	public get endTimeDisplayValue(): string {
		if (!this.start_time) {
			return '';
		}
		if (!this.dateSortValue && this.duration) {
			// if there is no date (which is derived from relative_to), we are likely editing a template
			// where there is no concrete event involved. So we use today and format our response with time only
			return RunOfShow.formatTimeFor(
				moment(`${moment().format(MOMENT_DATE_FORMAT)} ${this.start_time}`).add(this.duration, 'minutes')
			);
		}
		return RunOfShow.formatTimeFor(this.endTimeSortValue);
	}

	/**
	 * ALWAYS a positive number. Represents the absolute value of the offset
	 */
	public get offsetDays(): number {
		return Math.abs(this.offset || 0);
	}
	/**
	 * Allows you to modify the offset on the show, without changing its polarity.
	 * e.g. the previous sign of offset will be honored, regardless of what is
	 * passed for offset here.
	 */
	public set offsetDays(offset: number) {
		this.offset = Math.abs(offset) * (this.offsetIsBeforeEvent ? -1 : 1);
	}

	/**
	 * Returns true if the offset is negative, false otherwise
	 */
	public get offsetIsBeforeEvent(): boolean {
		return (this.offset || 0) < 0;
	}
	/**
	 * Allows you to force the offsets sign in either the positive or negative
	 * direction.
	 */
	public set offsetIsBeforeEvent(isBefore: boolean) {
		this.offset = this.offsetDays * (isBefore ? -1 : 1);
	}
	/**
	 * The user-specified title for this Run of Show entry
	 */
	@IsString() public title: string;

	/**
	 * This is a derived property sent by the backend or passed into the constructor
	 * when constructing the RunOfShow.
	 *
	 * This property is used as the basis for computing absolute dates, start
	 * times and end times.
	 *
	 * This class supportes a relative_to value of undefined for use within
	 * templates, where there is no absolute date.
	 *
	 * This is not a property that users should ever need to edit directly.
	 */
	@IsString() public relative_to: string | undefined;

	/**
	 * The number of days the run of show item is offset from relative_to.
	 * In practice, the backend will populate relative_to with the date of the
	 * first date on the relevant event.
	 *
	 * This number can be either POSITIVE (days after event) or NEGATIVE (days before event)
	 */
	@IsNumber() public offset: number = 0;

	/**
	 * Optional start time for the run of show item. If not specified, there will
	 * be no time associated with the run of shoe entry.
	 */
	@IsString() @IsOptional() public start_time: string;

	/**
	 * Duration of the Run of Show item. start_time + duration = end time.
	 * We display end time to users throughout the app, and we derive it
	 * as described above.
	 */
	@IsNumber() @IsOptional() public duration: number | null = 0;

	/**
	 * The id for the event that this Run of Show item is associated with.
	 * Will be null for Run of Show items that are associated with an event template
	 */
	@IsNumber() @IsOptional() public event_id: number | null;

	/**
	 * Some Run of Show items are associated with a talent deal on the show, others aren't.
	 * This field indicates which, if any talent deal this Run of Show item is associated with.
	 */
	@IsNumber() @IsOptional() public event_talent_id: number | null;

	/**
	 * When false, the ROS will only show up on advance, OR contracts, offers, settlements for
	 * the pertinent artist.
	 * When true, the ROS item will show up on all pdfs, regardless of what artist it
	 * is being generated for. When necessary we will append the artist name in order
	 * to properly disambiguate between entries that span multiple artists
	 */
	@IsBoolean() @Transform(castToBoolean()) public include_in_all_docs: boolean = false;

	/**
	 * The id for this run of show item. Will be null if we are dealing with a Run of Show item inside
	 * of an event template.
	 */
	@IsNumber() public id: number;

	/**
	 * The stage, if any that is associated with the Run of Show item. Generally users
	 * select the stage themselves, but when applying templates the stage will get
	 * automcatically assocaited with some run of show entries. This property is used
	 * in the front-end to display stage metadata where appropriate.
	 */
	@IsNumber() @IsOptional() public stage_id: number;

	/**
	 *
	 * @param date moment representing the time in question
	 * @returns string representing ONLY the time on the passed date
	 */
	private static formatTimeFor(date: moment.Moment): string {
		if (!date) {
			return '';
		}
		return date.format(MOMENT_TIME_SHORT);
	}

	public static doesROSItemsCoverMultipleDates(runOfShow: RunOfShow[]): boolean {
		const dates: string[] = [
			...new Set(
				runOfShow.map((ros: RunOfShow): string => {
					return ros.dateDisplayValue;
				})
			),
		];
		return dates.length > 1;
	}

	public static sortRosByStartTime(runOfShow: RunOfShow[]): RunOfShow[] {
		return _.sortBy(runOfShow, (runOfShowItem: RunOfShow): moment.Moment => {
			return runOfShowItem.start_time ? runOfShowItem.startTimeSortValue : runOfShowItem.dateSortValue;
		});
	}

	public static formatAsString(
		omitStageIfOne: boolean,
		formatWithDate: boolean,
		includeDuration: boolean,
		runOfShow: RunOfShow[],
		eventStages: Stage[],
		eventTalent: TalentData[]
	): string {
		const relevantRunOfShow: RunOfShow[] = RunOfShow.sortRosByStartTime(runOfShow).filter(
			(runOfShowItem: RunOfShow): boolean => {
				return !!runOfShowItem.include_in_all_docs;
			}
		);
		return relevantRunOfShow
			.map((runofShow: RunOfShow): string => {
				return runofShow
					.getRowForPDF(omitStageIfOne, formatWithDate, includeDuration, eventStages, eventTalent)
					.join(' - ')
					.trim();
			})
			.join('\n');
	}

	private includeStartTimeWithDate(date: moment.Moment): moment.Moment {
		return this.start_time ? moment(`${date.format(MOMENT_DATE_FORMAT)} ${this.start_time}`) : date;
	}

	/**
	 * Maps the class instance to a POJO suitable for sending to backend
	 * run of show endpoints
	 * @returns The RunOfShowPostData necessary for persisting the state of this
	 *   item to the backend
	 */
	public getEndpointData(): RunOfShowPostData {
		return {
			offset: this.offset || 0,
			duration: this.duration || 0,
			include_in_all_docs: !!this.include_in_all_docs,
			event_talent_id: this.event_talent_id || null,
			id: this.id || null,
			stage_id: this.stage_id || null,
			start_time: this.start_time
				? moment(`${moment().format(MOMENT_DATE_FORMAT)} ${this.start_time}`)
						.set('seconds', 0)
						.format(MOMENT_TIME_MILLITARY)
				: null,
			title: this.title || null,
			event_id: this.event_id || null,
		};
	}

	public resolveTalent(eventTalent: TalentData[]): TalentData | undefined {
		return _.find(eventTalent, (talent: TalentData): boolean => {
			return talent.id === this.event_talent_id;
		});
	}

	public resolveTalentName(eventTalent: TalentData[]): string {
		const relevantTalent: TalentData = this.resolveTalent(eventTalent);
		let talentName: string = '';
		if (relevantTalent) {
			talentName = relevantTalent.talentName;
		}
		return talentName;
	}

	public resolveStageName(eventStages: Stage[], omitIfOne: boolean): string {
		if (!this.stage_id) {
			return '';
		}
		if (omitIfOne && eventStages.length < 1) {
			return '';
		}
		if (omitIfOne && eventStages.length === 1) {
			return '';
		}
		const relevantStage: Stage = _.find(eventStages, (stage: Stage): boolean => {
			return stage.id === this.stage_id;
		});
		if (!relevantStage) {
			return '';
		}
		return relevantStage.name;
	}

	private getFullTimeString(includeDuration: boolean): string {
		let timeText: string = '';
		if (this.start_time) {
			timeText = `${this.startTimeDisplayValue}`;
			if (includeDuration && this.duration) {
				return (timeText += ` (${this.duration}min)`);
			}
		}
		if (includeDuration && this.duration) {
			timeText = `(${this.duration}min)`;
		}
		return timeText;
	}

	public getRowForPDF(
		omitStageIfOne: boolean,
		formatWithDate: boolean,
		includeDuration: boolean,
		eventStages: Stage[],
		eventTalent: TalentData[]
	): string[] {
		const rosPDFRow: string[] = [
			joinArray([this.resolveTalentName(eventTalent), this.title || '(No Title)'], ' - '),
		];
		const rosData: string = joinArray(
			[
				this.getFullTimeString(includeDuration),
				formatWithDate ? this.dateSortValue?.format(MOMENT_DAY_AND_DATE_ABBREV) || '' : '',
				this.resolveStageName(eventStages, omitStageIfOne),
			],
			' '
		);
		if (rosData !== '') {
			rosPDFRow.push(rosData);
		}
		return rosPDFRow;
	}
}
