import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { plainToInstance, Transform } from 'class-transformer';
import { IsBoolean, IsNumber, IsOptional, IsString } from 'class-validator';

interface ContactRolePayload {
	id: number | null;
	name: string;
	description: string;
	show_in_docs: boolean;
}

/**
 * Roles that can be attached to contacts
 */
export class ContactRole implements ContactRolePayload {
	public constructor(contactRole: Partial<ContactRole>) {
		return plainToInstance(ContactRole, contactRole);
	}

	@IsNumber()
	public id: number | null = null;

	@IsString()
	public name: string = '';

	@IsString()
	@IsOptional()
	public description: string | null = null;

	@IsBoolean()
	@Transform(castToBoolean())
	public show_in_docs: boolean = false;
}
