import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DealTrackerGraphqlService } from '@prism-frontend/services/api/graphql/deal-tracker-graphql.service';
import { EventGraphqlService } from '@prism-frontend/services/api/graphql/event-graphql.service';
import { CostService } from '@prism-frontend/services/legacy/api/cost.service';
import { PermissionsService } from '@prism-frontend/services/legacy/api/permissions.service';

/**
 * This helper services is meant to help edit ems fields. EMSFiledMeta
 * provides a persist function that required an instance of this service
 * to be passed. This service should be used to pull any service which
 * tiggers and api call in order to persist a field, to notify about
 * saved changes, etc. Like this those persist functions can stay static
 * and be easily reused
 */
@Injectable({
	providedIn: 'root',
})
export class EMSFieldHelperService {
	public constructor(
		public costService: CostService,
		public dealTrackerGraphqlService: DealTrackerGraphqlService,
		public eventGraphqlService: EventGraphqlService,
		public snackBar: MatSnackBar,
		public permissionsService: PermissionsService
	) {}

	public notifyEMSFieldsaved(): void {
		this.snackBar.open('Saved successfully!', null, {
			duration: 1500,
		});
	}
}
