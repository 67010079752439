import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrismGraphqlService } from '@prism-frontend/services/api/graphql/prism-graphql.service';
import {
	CreatePartnerDealAdjustmentMutation,
	CreatePartnerDealAdjustmentMutationResponse,
	DeletePartnerDealAdjustmentMutation,
	DeletePartnerDealMutationResponse,
	UpdatePartnerDealAdjustmentMutation,
	UpdatePartnerDealMutationResponse,
} from '@prism-frontend/typedefs/graphql/PartnerDealAdjustments';
import { PartnerAdjustment } from '@prism-frontend/typedefs/partner-deal';
import { omit } from 'lodash';

@Injectable({ providedIn: 'root' })
export class PartnerDealAdjustmentGraphqlService {
	public constructor(private matSnackBar: MatSnackBar, private prismGraphqlService: PrismGraphqlService) {}

	public createNewPartnerDealAdjustment(partnerDealAdjustment: PartnerAdjustment): Promise<PartnerAdjustment> {
		return this.prismGraphqlService
			.mutate({
				mutation: CreatePartnerDealAdjustmentMutation,
				variables: {
					...omit(partnerDealAdjustment, 'id'),
				},
			})
			.then((response: CreatePartnerDealAdjustmentMutationResponse): PartnerAdjustment => {
				this.matSnackBar.open(`Partner Adjustment Created!`, null, {
					duration: 1500,
				});
				return response.createPartnerDealAdjustment;
			});
	}

	public updatePartnerDealAdjustment(partnerDealAdjustment: PartnerAdjustment): Promise<PartnerAdjustment> {
		return this.prismGraphqlService
			.mutate({
				mutation: UpdatePartnerDealAdjustmentMutation,
				variables: {
					...partnerDealAdjustment,
				},
			})
			.then((response: UpdatePartnerDealMutationResponse): PartnerAdjustment => {
				this.matSnackBar.open(`Partner Adjustment Updated!`, null, {
					duration: 1500,
				});
				return response.updatePartnerDealAdjustment;
			});
	}

	public deletePartnerDealAdjustment(partnerDealAdjustmentId: number): Promise<number> {
		return this.prismGraphqlService
			.mutate({
				mutation: DeletePartnerDealAdjustmentMutation,
				variables: {
					id: partnerDealAdjustmentId,
				},
			})
			.then((response: DeletePartnerDealMutationResponse): number => {
				this.matSnackBar.open(`Partner Adjustment Deleted!`, null, {
					duration: 1500,
				});
				return response.deletePartnerDealAdjustment;
			});
	}
}
