import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@prism-frontend/../environments/environment';
import { classToPlain, plainToClass } from 'class-transformer';
import _ from 'lodash';

interface CacheEntry<T> {
	url: string;
	lastRead: number;
	response: HttpResponse<T>;
}

type CacheEntryTuple<T> = [string, CacheEntry<T>];

@Injectable({
	providedIn: 'root',
})
export class RequestCacheService<T> {
	// Toggle this option to enable/disable logging cache events to the console
	public isLoggingEnabled: boolean = true;

	private maxAgeInSeconds: number = 120 * 60 * 1000; // 120 minutes
	private cache: Map<string, CacheEntry<T>> = new Map<string, CacheEntry<T>>();
	private localStorageKeyName: string = 'httpCache';

	public constructor() {
		if (!environment.api_cache_enabled) {
			this.clearLocalStorage();
		}
		this.loadCacheFromLocalStorage();
		if (environment.production) {
			return;
		}
		// Expose global function in dev environment to clear HTTP cache from the console
		// @ts-ignore
		window.clearHttpCache = (): void => {
			this.clearLocalStorage();
		};
	}

	public isLoggingActive(): boolean {
		return this.isLoggingEnabled && environment.api_cache_enabled;
	}

	public isCacheable(req: HttpRequest<T>): boolean {
		const url: string = req.urlWithParams;

		if (!environment.api_cache_enabled) {
			return false;
		}
		if (req.method !== 'GET') {
			this.logIfEnabled('%c dont-cache', 'color: purple; font-weight: bold', `${req.method} ${url}`);
			return false;
		}

		return _.every(environment.skip_cache_for_urls, (urlToSkip: string | RegExp): boolean => {
			const regexp: RegExp = RegExp(urlToSkip);
			return !regexp.test(url);
		});
	}

	public get(req: HttpRequest<T>): HttpResponse<T> | undefined {
		const url: string = req.urlWithParams;

		if (!this.isCacheable(req)) {
			this.logIfEnabled('%c dont-cache', 'color: purple; font-weight: bold', url);
			return;
		}

		const cacheEntry: CacheEntry<T> = this.cache.get(url);

		if (!cacheEntry) {
			this.logIfEnabled('%c cache MISS', 'color: orange; font-weight: bold', url);
			return;
		}
		if (cacheEntry.lastRead < Date.now() - this.maxAgeInSeconds) {
			this.logIfEnabled('%c cache STALE', 'color: red; font-weight: bold', url);
			this.cache.delete(url);
			return;
		}

		this.logIfEnabled('%c cache HIT', 'color: green; font-weight: bold', url);

		return cacheEntry.response;
	}

	public set(req: HttpRequest<T>, response: HttpResponse<T>): void {
		const url: string = req.urlWithParams;

		if (req.method !== 'GET') {
			this.logIfEnabled('%c dont-cache', 'color: purple; font-weight: bold', url);
			return;
		}

		this.logIfEnabled('%c cache SET', 'color: blue; font-weight: bold', url, this.cache.size);
		const cacheEntry: CacheEntry<T> = {
			url,
			response,
			lastRead: Date.now(),
		};
		this.cache.set(url, cacheEntry);

		// clear expired cache entries
		const expirationTime: number = Date.now() - this.maxAgeInSeconds;
		this.cache.forEach((entry: CacheEntry<T>): void => {
			if (entry.lastRead < expirationTime) {
				this.cache.delete(entry.url);
			}
		});

		this.persistToLocalStorage();
	}

	public clearLocalStorage(): void {
		try {
			this.logIfEnabled('%c cache size before clearLocalStorage', 'color: gray', this.cache.size);
			localStorage.removeItem(this.localStorageKeyName);
			this.cache = new Map<string, CacheEntry<T>>();
			this.logIfEnabled('%c cache size after clearLocalStorage', 'color: gray', this.cache.size);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e);
			return;
		}
	}

	private loadCacheFromLocalStorage(): void {
		try {
			const storedValue: string = localStorage.getItem(this.localStorageKeyName);
			if (storedValue) {
				// convert JSON string to array and then to Map
				const parsedData: CacheEntryTuple<T>[] = JSON.parse(storedValue);

				this.cache = new Map(parsedData);

				this.cache.forEach((cacheEntry: CacheEntry<T>): void => {
					cacheEntry.response = plainToClass<HttpResponse<T>, HttpResponse<T>>(
						HttpResponse,
						cacheEntry.response
					);
				});
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e);
			return;
		}
	}

	private persistToLocalStorage(): void {
		try {
			// convert Map to array and then to JSON string
			localStorage.setItem(
				this.localStorageKeyName,
				JSON.stringify(classToPlain(<CacheEntryTuple<T>[]>Array.from(this.cache)))
			);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e);
			return;
		}
	}

	private logIfEnabled(...args: unknown[]): void {
		if (!this.isLoggingActive()) {
			return;
		}
		// eslint-disable-next-line no-console
		console.log(...args);
	}
}
