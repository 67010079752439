import { DocumentNode, gql } from '@apollo/client/core';
import { PrismEvent } from '@prism-frontend/typedefs/event';

export const UpdateEventMutation: DocumentNode = gql`
	mutation UpdateEvent(
		$id: Int!
		$copro_overridden_additional_support: Float
		$copro_additional_support_hidden: Boolean
	) {
		updateEvent(
			id: $id
			copro_overridden_additional_support: $copro_overridden_additional_support
			copro_additional_support_hidden: $copro_additional_support_hidden
		) {
			...CoProEventOverrideFragment
		}
	}
	fragment CoProEventOverrideFragment on Event {
		id
		copro_overridden_additional_support
		copro_additional_support_hidden
	}
`;

export type UpdateEventMutationArguments = Pick<PrismEvent, 'id'> &
	Partial<Pick<PrismEvent, 'copro_overridden_additional_support' | 'copro_additional_support_hidden'>>;

export interface UpdateEventMutationResponse {
	updateEvent: PrismEvent;
}
