export enum Currency {
	ARS = 'ARS',
	AUD = 'AUD',
	BOB = 'BOB',
	BRL = 'BRL',
	CAD = 'CAD',
	CLP = 'CLP',
	COP = 'COP',
	EUR = 'EUR',
	GBP = 'GBP',
	ILS = 'ILS',
	JPY = 'JPY',
	MXN = 'MXN',
	NZD = 'NZD',
	PEN = 'PEN',
	PHP = 'PHP',
	SEK = 'SEK',
	THB = 'THB',
	TWD = 'TWD',
	USD = 'USD',
	UYU = 'UYU',
}

export const currencyList: Currency[] = [
	Currency.ARS,
	Currency.AUD,
	Currency.BOB,
	Currency.BRL,
	Currency.CAD,
	Currency.CLP,
	Currency.COP,
	Currency.EUR,
	Currency.GBP,
	Currency.ILS,
	Currency.JPY,
	Currency.MXN,
	Currency.NZD,
	Currency.PEN,
	Currency.PHP,
	Currency.SEK,
	Currency.THB,
	Currency.TWD,
	Currency.USD,
	Currency.UYU,
];
