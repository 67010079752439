import { EnvironmentNames } from '@prism-frontend/typedefs/environment.typedef';
import { environment } from 'environments/environment';

function isEnvironmentOneOf(options: EnvironmentNames[]): boolean {
	return options.indexOf(environment.name) !== -1;
}

export function isEnvironmentLower(): boolean {
	return isEnvironmentOneOf([EnvironmentNames.Local, EnvironmentNames.Dev, EnvironmentNames.QA]);
}
