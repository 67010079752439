export enum AfterType {
	PercentSellThrough = 'percent_sell_through',
	TicketsSold = 'number_tickets_sold',
	Manual = 'manual',
	Costs = 'costs',
}

export const AfterTypeLabel: { [key in AfterType]: string } = {
	[AfterType.PercentSellThrough]: 'Percent Sold',
	[AfterType.TicketsSold]: 'Tickets',
	[AfterType.Manual]: 'Manual',
	[AfterType.Costs]: 'Costs',
};

export const AfterTypeSuffix: { [key in AfterType]: string } = {
	[AfterType.PercentSellThrough]: '%',
	[AfterType.TicketsSold]: '',
	[AfterType.Manual]: '',
	[AfterType.Costs]: '',
};
