import { IsArray, IsNumber, IsOptional } from 'class-validator';

export class EventData {
	@IsNumber() public logo_id: number;
	// TODO PRSM-1961 rename to chosen_hold_ids as this is an array, not a number

	@IsArray() public chosen_hold_id: number[] = [];

	@IsArray()
	@IsOptional()
	public mad_stage_ids: number[];
}
