import { MatDialog } from '@angular/material/dialog';
import { ApolloError, isApolloError } from '@apollo/client/errors';
import { ConfirmDialogComponent } from '@prism-frontend/components/confirm-dialog/confirm-dialog.component';
import { GraphQLError } from 'graphql';
import _ from 'lodash';

/**
 * given a graphql error, and a key to find, return an array of GraphQLErrors that
 * correspond to validation errors found on that key
 *
 * if no errors for that key is found, return an empty array
 *
 * @param apiError an error returned from a graphql API  call
 * @param key the key for which a validation error will be checked
 * @param matchingText optional text to match for in the errors
 * @returns an array of type GraphQLError[], which is empty if none is found
 */
export function validationErrorsForKey<T>(
	apiError: ApolloError,
	key: keyof T,
	matchingText: string = ''
): GraphQLError[] {
	// if this is not a valid apollo error, return false
	if (!isApolloError(apiError)) {
		return [];
	}

	return apiError.graphQLErrors
		.filter((graphqlError: GraphQLError): boolean => {
			// filter down the graphqlerrors array for validation errors that contain the key specified
			return (
				!!graphqlError.extensions?.validation &&
				key in (graphqlError.extensions['validation'] as Record<string, string>)
			);
		})
		.filter((graphqlError: GraphQLError): boolean => {
			// check for matching text. if not specified, return true
			if (!matchingText) {
				return true;
			}

			// if the consumer specified matching text, filter out anything that doesnt match that text
			return (graphqlError.extensions.validation[key] as string).indexOf(matchingText) >= 0;
		});
}

export function extractGraphqlErrorsByKey(
	apiError: ApolloError,
	keyFixer: (key: string) => string = _.identity
): Record<string, string[]> {
	if (!isApolloError) {
		throw new Error('Expected to be passed an ApolloError');
	}
	const finalErrors: Record<string, string[]> = {};
	_.chain(apiError.graphQLErrors)
		.filter((graphqlError: GraphQLError): boolean => {
			// filter down the graphqlerrors array for those that contain validation errors
			return !!graphqlError.extensions?.validation;
		})
		.map((graphqlError: GraphQLError): Record<string, string> => {
			return graphqlError.extensions['validation'] as Record<string, string>;
		})
		.forEach((validationErrors: Record<string, string>): void => {
			Object.keys(validationErrors).forEach((key: string): void => {
				const fixedKey: string = keyFixer(key);
				if (!finalErrors[fixedKey]) {
					finalErrors[fixedKey] = [];
				}
				finalErrors[fixedKey].push(validationErrors[key]);
			});
		})
		.value();
	return finalErrors;
}

export function surfaceAllApolloValidationErrorsInAMatDialog(matDialog: MatDialog, error: ApolloError): void {
	if (!isApolloError(error)) {
		matDialog.open(ConfirmDialogComponent, {
			data: {
				heading: `An error occurred.`,
				message: 'If this issue persists, contact support.',
				cancelButtonCaption: 'Cancel',
				confirmButtonCaption: 'OK',
			},
		});
		return;
	}
	const allErrors: string[] = _.chain(extractGraphqlErrorsByKey(error))
		.map((errors: string[]): string[] => {
			return errors;
		})
		.flatten()
		.value();
	matDialog.open(ConfirmDialogComponent, {
		data: {
			heading: `An error occurred.`,
			message: allErrors.join('\n'),
			cancelButtonCaption: 'Cancel',
			confirmButtonCaption: 'OK',
		},
	});
}
