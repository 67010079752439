/**
 * Copied from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value#Examples
 *
 * Since the JSON format doesn't support object references, a TypeError will be thrown
 * if one attempts to encode an object with circular references.
 */
export function circularJSONReplacer(): (_key: string, value: unknown) => unknown {
	const seen: WeakSet<object> = new WeakSet();
	return (_key: string, value: unknown): unknown => {
		if (typeof value === 'object' && value !== null) {
			if (seen.has(value)) {
				return;
			}
			seen.add(value);
		}
		return value;
	};
}
