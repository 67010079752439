import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LinkDealApiService, LinkDealMetadataAndTokenHash } from '@prism-frontend/services/api/link-deal-api.service';
import { PrismAnalyticsService } from '@prism-frontend/services/utils/prism-analytics.service';

@Injectable({ providedIn: 'root' })
export class LinkDealService {
	private linkDealMetadata: LinkDealMetadataAndTokenHash | null = null;

	public constructor(
		private linkDealApiService: LinkDealApiService,
		private prismAnalyticsService: PrismAnalyticsService,
		private router: Router
	) {}

	private navigateToEvent(linkDealMetadata: LinkDealMetadataAndTokenHash): void {
		this.linkDealMetadata = linkDealMetadata;
		this.router.navigate(['/event', this.linkDealMetadata.local_event.id]);
	}

	/**
	 * hit the API with the linked event hash from the URL. there are three possible outcomes here:
	 *
	 * 	1) 404 - the hash doesnt exist; do nothing in this case
	 * 	2) the hash exists, but is not yet linked to an event in this org; show the LinkDeal modal
	 * 		with the data returned from the API in this case
	 * 	3) the hash exists, and is already linked to an event; store the event ID and redirect to that event
	 */
	private async checkToken(dealToken: string): Promise<void> {
		const response: LinkDealMetadataAndTokenHash = (await this.linkDealApiService.checkLinkDealToken(
			dealToken
		)) as LinkDealMetadataAndTokenHash;

		if (response.local_event !== null) {
			this.prismAnalyticsService.fireEvent({
				eventName: 'link-deal-redirect-to-existing-event',
				destinationEventId: response.local_event.id,
				hash: dealToken,
			});
			this.navigateToEvent(response);
			return;
		}

		this.prismAnalyticsService.fireEvent({
			eventName: 'link-deal-hash-check-load',
			hash: dealToken,
		});
		this.linkDealMetadata = response;
		return;
	}

	/**
	 * call this function once per app load; if there is a valid URL param that indicates the user
	 * wants to link an event, hit the API and see if that's its a valid hash
	 */
	public async linkDealGuardCheck(dealHash: string): Promise<void> {
		await this.checkToken(dealHash);
	}

	/**
	 * if we're on a new `link-deal/:hash` link, get the linkDealMetadata that the LinkDeal
	 * guard should have stored for us
	 *
	 * @returns LinkDealMetadataAndTokenHash if it exists, or null if it doesn't
	 */
	public evaluateLinkDealOnFirstLoad(): LinkDealMetadataAndTokenHash | null {
		const linkDealMetadata: LinkDealMetadataAndTokenHash | null = this.linkDealMetadata;
		this.linkDealMetadata = null;
		return linkDealMetadata;
	}

	/**
	 * evaluatreReImportLinkedEvent evaluates the linkDealMetadata and the eventURL to see if we need the "Import Deal"
	 * modal to pop up or not.
	 *
	 * We call this function once per event page load. If the current event ID on the page matches the event
	 * ID we stored from checkHas(), then show the reimport modal. If not, do nothing.
	 *
	 * Calling this function checks this.linkDealMetadata, then clears it out and throws it away,
	 * so that variable only exists for at most one page load.
	 * @returns LinkDealMetadataAndTokenHash if it exists, or null if it doesn't
	 */
	public evaluateReImportLinkedEvent(): LinkDealMetadataAndTokenHash | null {
		// Declares temp var to use within method scope because we clear out the actual linkDealMetadata
		const linkDealMetadata: LinkDealMetadataAndTokenHash | null = this.linkDealMetadata;
		this.linkDealMetadata = null;
		const eventURLCheck: RegExpExecArray | null = /\/event\/(\d+)/.exec(this.router.url);

		// Return false if we have no linkDealMetadata, or we're not on an event page
		if (linkDealMetadata === null || eventURLCheck === null) {
			return null;
		}

		// If the event ID in the URL doesn't match the one cached from the redirect,
		// return
		const currentEventId: number = Number(eventURLCheck[1]);
		if (currentEventId !== linkDealMetadata.local_event?.id) {
			return null;
		}

		return linkDealMetadata;
	}
}
