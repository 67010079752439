import { DocumentNode, gql } from '@apollo/client/core';
import { Artist } from '@prism-frontend/entities/artists/artist-typedefs';
import { AgentUserFragment } from '@prism-frontend/typedefs/graphql/AgentUserFragment';
import {
	ContactPropsWithArtistsFragment,
	CoreContactPropsFragment,
} from '@prism-frontend/typedefs/graphql/CoreContactFragment';
import { fetchPaginationProps, PaginationProps } from '@prism-frontend/typedefs/graphql/PaginationDetails';
import { TalentFragment } from '@prism-frontend/typedefs/graphql/TalentFragment';

/**
 * CoreArtistFragment represents the top-level properties of an artist object,
 * and specifically pulls CoreContactPropsFragment instead of CoreContactPropsFragmentWithArtists
 *
 * this keeps the payload much smaller on reporting pages, and on the artist roster page
 */
const CoreArtistFragment: DocumentNode = gql`
	fragment CoreArtistFragment on TalentAgent {
		id
		notes
		manager_contact_id
		manager {
			...CoreContactPropsFragment
		}
		contract_signer_id
		contract_signer {
			...CoreContactPropsFragment
		}
		talent_id
		is_archived
		talent {
			...CoreTalent
		}
		contacts {
			...CoreContactPropsFragment
		}
	}
	${CoreContactPropsFragment}
	${TalentFragment}
`;

// Fragments docs: https://www.apollographql.com/docs/react/data/fragments/
export const ArtistFragment: DocumentNode = gql`
	fragment ArtistFragment on TalentAgent {
		...CoreArtistFragment
		agents {
			...AgentUserFragment
		}
	}
	${CoreArtistFragment}
	${AgentUserFragment}
`;

/**
 * ArtistFragmentWithFilesAndFullContactsFragment is used when loading an artist's roster page
 * in that case, we want to load contacts with all of their related artists, since users are able
 * to edit those contacts from the artist's contact page
 */
const ArtistFragmentWithFilesAndFullContactsFragment: DocumentNode = gql`
	fragment ArtistFragmentWithFilesAndFullContactsFragment on TalentAgent {
		...ArtistFragment
		files {
			visible_all
			user_id
			tags_string
			tags
			roles_string
			permissions
			name
			id
			full_path
			file
			download_code
			notes
			created_at
		}
		contacts {
			...ContactPropsWithArtistsFragment
		}
	}
	${ContactPropsWithArtistsFragment}
	${ArtistFragment}
`;

const paginationProps: PaginationProps = fetchPaginationProps();

export const PaginatedArtistsQuery: DocumentNode = gql`
	query PaginatedArtistsList(
		${paginationProps.queryParams},
		$is_archived: Boolean,
	) {
		talentAgentList(
			${paginationProps.queryArguments},
			is_archived: $is_archived,
		)  {
			data {
				...ArtistFragment
			}
			${paginationProps.responseProps}
		}
	}
	${ArtistFragment}
`;

export const ArtistByIdQuery: DocumentNode = gql`
	query ArtistById($id: Int!) {
		talentAgentList(id: $id) {
			data {
				...ArtistFragmentWithFilesAndFullContactsFragment
			}
		}
	}
	${ArtistFragmentWithFilesAndFullContactsFragment}
`;

export type ArtistByIDArguments = Pick<Artist, 'id'>;
