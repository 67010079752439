export const COLOR_WHITE: string = '#ffffff';
export const COLOR_BLACK: string = '#000000';

export function getMappedColor(color: string): string {
	switch (color) {
		case 'green':
			return '#51bc51';
		case 'blue':
			return '#008bff';
		case 'red':
			return '#ff887c';
		default:
			return color;
	}
}

function computeChromaLuminance(hexcolor: string): number {
	if (!hexcolor) return 0;
	const r: number = parseInt(hexcolor.substr(1, 2), 16);
	const g: number = parseInt(hexcolor.substr(3, 2), 16);
	const b: number = parseInt(hexcolor.substr(5, 2), 16);
	const yiq: number = (r * 299 + g * 587 + b * 114) / 1000;
	return yiq;
}

export function getReadableForegroundTextFor(hexcolor: string): string {
	return isColorLight(hexcolor) ? 'black' : 'white';
}

function isColorLight(hexcolor: string): boolean {
	const yiq: number = computeChromaLuminance(hexcolor);
	return yiq >= 188;
}
