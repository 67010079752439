import { Organization, Staff } from '@prism-frontend/typedefs/organization';
import { Permission } from '@prism-frontend/typedefs/permission';
import { PermissionRole } from '@prism-frontend/typedefs/PermissionRole';
import { plainToClass } from 'class-transformer';

export class User {
	public id: number;
	public apiState: string;
	public name: string;
	public email: string;
	public organization: Organization;
	public organization_name: string;
	public organization_id: number;
	public is_admin: boolean;
	public is_prism_admin: boolean;
	public permissions: Permission[];
	public global_roles: PermissionRole[];
	public staff?: Staff;

	public constructor(user?: Partial<User>) {
		return plainToClass(User, user);
	}
}
