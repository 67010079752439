import { DocumentNode, gql } from '@apollo/client/core';
// import { CoreContactPropsFragment } from '@prism-frontend/typedefs/graphql/CoreContactFragment';
import { StageProps } from '@prism-frontend/typedefs/graphql/StageProps';
import { Venue } from '@prism-frontend/typedefs/venue';
// a graphql fragment to be used in other queries when a full venue model is needed

export const VenueProps: DocumentNode = gql`
	fragment VenueProps on Venue {
		active
		convert_to_usd
		central_venue_id
		currency
		facility_fee
		location_migration_failure
		location
		address_1_long
		address_1_short
		address_2_long
		address_2_short
		can_request_to_host_venue
		central_venue_id
		city_long
		city_short
		convert_to_usd
		country_long
		country_short
		created_at
		currency
		default_logo_id
		default_template_id
		eb_venue_id
		facility_fee
		google_place_id
		highest_allowable_hold_level
		id
		location
		name
		organization_id
		show_best_available
		stages {
			...StageProps
		}
		contacts {
			id
			name
			email
		}
		contact_ids
		state_long
		state_short
		tax_rate
		tax_type
		timezone
		updated_at
		zip
		auto_promote_holds
		default_hold_level
	}
	${StageProps}
`;

// TODO PRSM-8373 use this in venue list service, venue graphql service when the b/e gql types are updated
// export const VenueWithContactsProps: DocumentNode = gql`
// 	fragment VenueWithContactsProps on Venue {
// 		...VenueProps
// 		contacts {
//			id
// 			name
// 		}
// 	}
// 	${VenueProps}
// `;

export const SingleVenueQuery: DocumentNode = gql`
	query VenueQuery($id: Int!) {
		venues(id: $id) {
			...VenueProps
		}
	}
	${VenueProps}
`;

// a query for loading all venues for our venue DB page
export const AllVenuesQuery: DocumentNode = gql`
	query VenuesQuery {
		venues {
			...VenueProps
		}
	}
	${VenueProps}
`;
export interface AllVenuesResponse {
	venues: Venue[];
}
