import { DocumentNode } from '@apollo/client/core';
import { FinalMutationThings, generateMutations } from '@prism-frontend/typedefs/graphql/query-generator-helpers';
import { CreateStageMutationVariables } from '@prism-frontend/typedefs/graphql/StageMutation';
import { VenueProps } from '@prism-frontend/typedefs/graphql/VenueQuery';
import { Venue } from '@prism-frontend/typedefs/venue';

const mutationThings: FinalMutationThings = generateMutations<Venue>(
	'Venue',
	VenueProps,
	{
		name: 'String',
		currency: 'String',
		tax_rate: 'Float',
		tax_type: 'String',
		timezone: 'String',
		convert_to_usd: 'Boolean',
		contact_ids: `[Int]`,
		facility_fee: 'Float',
		default_logo_id: 'Int',
		default_template_id: 'Int',
		active: 'Boolean',
		from_central_venue_id: 'Int',
		address_1_long: 'String',
		address_1_short: 'String',
		address_2_long: 'String',
		address_2_short: 'String',
		country_short: 'String',
		country_long: 'String',
		city_long: 'String',
		city_short: 'String',
		state_long: 'String',
		state_short: 'String',
		google_place_id: 'String',
		should_create_central_venue: 'Boolean',
		zip: 'String',
		default_hold_level: 'Int',
		auto_promote_holds: 'Boolean',
	},
	{}
);

export type SharedVenueProps = Pick<
	Venue,
	| 'convert_to_usd'
	| 'currency'
	| 'default_logo_id'
	| 'default_template_id'
	| 'facility_fee'
	| 'name'
	| 'tax_rate'
	| 'tax_type'
	| 'timezone'
	| 'active'
	| 'from_central_venue_id'
	| 'should_create_central_venue'
	| 'address_1_long'
	| 'address_1_short'
	| 'address_2_long'
	| 'address_2_short'
	| 'country_short'
	| 'country_long'
	| 'city_long'
	| 'city_short'
	| 'state_long'
	| 'state_short'
	| 'google_place_id'
	| 'zip'
	| 'default_hold_level'
	| 'auto_promote_holds'
	| 'contact_ids'
>;

export type UpdateVenueMutationVariables = SharedVenueProps & Pick<Venue, 'id'>;

type StageVariablesForCreateVenueMutation = Omit<CreateStageMutationVariables, 'venue_id'>;

export type CreateVenueMutationVariables = SharedVenueProps & {
	stages: StageVariablesForCreateVenueMutation[];
};

export const CreateVenueMutation: DocumentNode = mutationThings.createMutation;

export interface CreateVenueMutationResponse {
	createVenue: Venue;
}

export const UpdateVenueMutation: DocumentNode = mutationThings.updateMutation;

export interface UpdateVenueMutationResponse {
	updateVenue: Venue;
}
