import { DocumentNode, gql } from '@apollo/client/core';
import { ArtistFragment } from '@prism-frontend/entities/artists/artist-graphql/artist-graphql-query';
import { ImporterContact } from '@prism-frontend/entities/contacts/contacts-table-contact-importer/contact-importer-modal.service';
import { Contact, ContactSuggestion } from '@prism-frontend/typedefs/contact';
import { ContactRolePropsFragment } from '@prism-frontend/typedefs/graphql/ContactRole';
import { ContactPropsWithArtistsFragment } from '@prism-frontend/typedefs/graphql/CoreContactFragment';

// a graphql fragment to be used in other queries when a full contact model is needed
export const ContactPropsFragment: DocumentNode = gql`
	fragment ContactProps on Contact {
		...ContactPropsWithArtistsFragment
		talent_agents {
			...ArtistFragment
		}
	}
	${ContactPropsWithArtistsFragment}
	${ArtistFragment}
`;

// a query to populate the contact select dropdown component
export const ContactSuggestionsQuery: DocumentNode = gql`
	query ContactsSuggestionsQuery {
		contacts {
			id
			name
			email
			phone
			company
			contact_roles {
				...ContactRoleProps
			}
		}
	}
	${ContactRolePropsFragment}
`;

export const SingleContactQuery: DocumentNode = gql`
	query ContactQuery($id: Int!) {
		contact(id: $id) {
			...ContactProps
		}
	}
	${ContactPropsFragment}
`;

// a query for loading all contacts for our contact DB page
export const AllContactsQuery: DocumentNode = gql`
	query ContactsQuery {
		contacts {
			...ContactProps
		}
	}
	${ContactPropsFragment}
`;

const contactMutationArguments: string = `
	$address: String
	$address_1_long: String
	$address_1_short: String
	$address_2_long: String
	$address_2_short: String
	$city_long: String
	$city_short: String
	$company: String
	$contact_role_ids: [Int]
	$country_long: String
	$country_short: String
	$email: String
	$eventId: Int
	$google_place_id: String
	$name: String!
	$notes: String
	$phone: String
	$state_long: String
	$state_short: String
	$talent_agent_ids: [Int]
	$zip: String
`;

const contactMutationArgumentMapping: string = `
	address: $address
	address_1_long: $address_1_long
	address_1_short: $address_1_short
	address_2_long: $address_2_long
	address_2_short: $address_2_short
	city_long: $city_long
	city_short: $city_short
	company: $company
	contact_role_ids: $contact_role_ids
	country_long: $country_long
	country_short: $country_short
	email: $email
	event_id: $eventId
	google_place_id: $google_place_id
	name: $name
	notes: $notes
	phone: $phone
	state_long: $state_long
	state_short: $state_short
	talent_agent_ids: $talent_agent_ids
	zip: $zip
`;

export const CreateContactMutation: DocumentNode = gql`
	mutation createContact(
		${contactMutationArguments}
	) {
		createContact(
			${contactMutationArgumentMapping}
		) {
			...ContactProps
		}
	}
	${ContactPropsFragment}
`;

export const UpdateContactMutation: DocumentNode = gql`
	mutation updateContact(
		$id: Int!
		${contactMutationArguments}
	) {
		updateContact(
			id: $id
			${contactMutationArgumentMapping}
		) {
			...ContactProps
		}
	}
	${ContactPropsFragment}
`;

export type DeleteContactMutationArguments = Pick<Contact, 'id'>;

export const DeleteContactMutation: DocumentNode = gql`
	mutation DeleteContact($id: Int) {
		deleteContact(id: $id)
	}
`;

export type FinalImporterContact = Omit<ImporterContact, 'contact_roles' | 'contact_roles_string' | 'location'> & {
	contact_role_names: string[];
};

export interface DeleteContactMutationResponse {
	deleteContact: number;
}

export interface ImportContactListMutationVariables {
	contacts: FinalImporterContact[];
}

export interface ImportContactListMutationResponse {
	importContactList: Contact[];
}

export const ImportContactListMutation: DocumentNode = gql`
	mutation importContactList($contacts: [ContactImportInput]) {
		importContactList(contacts: $contacts) {
			...ContactProps
		}
	}
	${ContactPropsFragment}
`;

export interface ContactSuggestionsResponse {
	contacts: ContactSuggestion[];
}

export interface AllContactsResponse {
	contacts: Contact[];
}

export interface UpdateContactMutationResponse {
	updateContact: Contact;
}

export interface CreateContactMutationResponse {
	createContact: Contact;
}

export interface FullContactResponse {
	contact: Contact;
}

export interface CreateContactMutationVariables {
	address_1_long: string;
	address_1_short: string;
	address_2_long: string;
	address_2_short: string;
	city_long: string;
	city_short: string;
	company: string;
	contact_role_ids: number[];
	country_long: string;
	country_short: string;
	email: string;
	// optional event ID to patch event permission issue
	eventId: number | null;
	google_place_id: string;
	name: string;
	notes: string;
	phone: string;
	state_long: string;
	state_short: string;
	talent_agent_ids: number[];
	zip: string;
}

export interface UpdateContactMutationVariable extends CreateContactMutationVariables {
	id: number;
}

export const BulkEditContactMutation: DocumentNode = gql`
	mutation BulkAddContactsClients($talentAgentIds: [Int], $contactIds: [Int]) {
		bulkAddContactsClients(talent_agent_ids: $talentAgentIds, contact_ids: $contactIds) {
			...ContactPropsWithArtistsFragment
			talent_agents {
				...ArtistFragment
			}
		}
	}
	${ContactPropsWithArtistsFragment}
	${ArtistFragment}
`;
export interface BulkEditContactsMutationResponse {
	bulkAddContactsClients: Contact[];
}

export interface BulkEditContactsMutationVariables {
	contactIds: number[];
	talentAgentIds: number[];
}

export interface BulkEditContactsResult {
	contacts: Contact[];
	hasErrors: boolean;
}
