import {
	animate,
	animateChild,
	animation,
	AnimationGroupMetadata,
	AnimationQueryMetadata,
	AnimationReferenceMetadata,
	AnimationTriggerMetadata,
	group,
	keyframes,
	query,
	style,
	transition,
	trigger,
	useAnimation,
} from '@angular/animations';
/**
 * This code was taken from https://github.com/filipows/angular-animations This library wasn't
 * installed in the project due to some dependencies conflicts, so we copied the animations we
 * use over here. Hopefully once we reach Angular 15 we will be able to install this without
 * conflicts.
 */

/**
 * An interface to pass down configurable options to an animation
 */
interface IAnimationOptions {
	/**
	 * Name of the animation anchor that will be used in a template
	 */
	anchor?: string;
	/**
	 * Duration in ms
	 */
	duration?: number;
	/**
	 * Delay in ms
	 *
	 * Default: 0
	 */
	delay?: number;
	/**
	 * This parameter can only be set in a component's decorator.
	 * Cannot be set in a template.
	 *
	 * Whether children animation should run 'before', 'together' or 'after' animation.
	 * When set to 'none' chldren are not animated.
	 *
	 * Default: 'together'
	 */
	animateChildren?: 'before' | 'together' | 'after' | 'none';
	/**
	 * Translate, possible units: px, %, em, rem, vw, vh
	 *
	 * Default: 100%
	 */
	translate?: string;
}

const DEFAULT_DURATION: number = 1000;

/**
 * A helper function used to handle animations for child elements
 *
 * @param animationRef the animation reference metadata
 * @param options The animation options
 * @returns the grouped animations including the children
 */
function useAnimationIncludingChildren(
	animationRef: AnimationReferenceMetadata,
	options?: IAnimationOptions
): (AnimationGroupMetadata | AnimationQueryMetadata)[] {
	return [
		...(options && options.animateChildren === 'before' ? [query('@*', animateChild(), { optional: true })] : []),
		group([
			useAnimation(animationRef),
			...(!options || !options.animateChildren || options.animateChildren === 'together'
				? [query('@*', animateChild(), { optional: true })]
				: []),
		]),
		...(options && options.animateChildren === 'after' ? [query('@*', animateChild(), { optional: true })] : []),
	];
}

// FADE IN

const fadeIn: () => AnimationReferenceMetadata = (): AnimationReferenceMetadata => {
	return animation([
		animate(
			'{{duration}}ms {{delay}}ms',
			keyframes([
				style({ visibility: 'visible', opacity: 0, easing: 'ease', offset: 0 }),
				style({ opacity: 1, easing: 'ease', offset: 1 }),
			])
		),
	]);
};

export function fadeInAnimation(options?: IAnimationOptions): AnimationTriggerMetadata {
	return trigger((options && options.anchor) || 'fadeIn', [
		transition(':enter', [style({ visibility: 'hidden' }), ...useAnimationIncludingChildren(fadeIn(), options)], {
			params: {
				delay: (options && options.delay) || 0,
				duration: (options && options.duration) || DEFAULT_DURATION,
			},
		}),
	]);
}

// FADE IN DOWN

const fadeInDown: () => AnimationReferenceMetadata = (): AnimationReferenceMetadata => {
	return animation([
		animate(
			'{{duration}}ms {{delay}}ms',
			keyframes([
				style({
					visibility: 'visible',
					opacity: 0,
					transform: 'translate3d(0, -{{translate}}, 0)',
					easing: 'ease',
					offset: 0,
				}),
				style({ opacity: 1, transform: 'translate3d(0, 0, 0)', easing: 'ease', offset: 1 }),
			])
		),
	]);
};

export function fadeInDownAnimation(options?: IAnimationOptions): AnimationTriggerMetadata {
	return trigger((options && options.anchor) || 'fadeInDown', [
		transition(
			':enter',
			[style({ visibility: 'hidden' }), ...useAnimationIncludingChildren(fadeInDown(), options)],
			{
				params: {
					delay: (options && options.delay) || 0,
					duration: (options && options.duration) || DEFAULT_DURATION,
					translate: (options && options.translate) || '100%',
				},
			}
		),
	]);
}

// FADE OUT

const fadeOut: () => AnimationReferenceMetadata = (): AnimationReferenceMetadata => {
	return animation([
		animate(
			'{{duration}}ms {{delay}}ms',
			keyframes([
				style({ opacity: 1, easing: 'ease', offset: 0 }),
				style({ opacity: 0, easing: 'ease', offset: 1 }),
			])
		),
	]);
};

export function fadeOutAnimation(options?: IAnimationOptions): AnimationTriggerMetadata {
	return trigger((options && options.anchor) || 'fadeOut', [
		transition(':leave', [...useAnimationIncludingChildren(fadeOut(), options)], {
			params: {
				delay: (options && options.delay) || 0,
				duration: (options && options.duration) || DEFAULT_DURATION,
			},
		}),
	]);
}

// FADE OUT UP

const fadeOutUp: () => AnimationReferenceMetadata = (): AnimationReferenceMetadata => {
	return animation([
		animate(
			'{{duration}}ms {{delay}}ms',
			keyframes([
				style({ opacity: 1, transform: 'translate3d(0, 0, 0)', easing: 'ease', offset: 0 }),
				style({ opacity: 0, transform: 'translate3d(0, -{{translate}}, 0)', easing: 'ease', offset: 1 }),
			])
		),
	]);
};

export function fadeOutUpAnimation(options?: IAnimationOptions): AnimationTriggerMetadata {
	return trigger((options && options.anchor) || 'fadeOutUp', [
		transition(':leave', [...useAnimationIncludingChildren(fadeOutUp(), options)], {
			params: {
				delay: (options && options.delay) || 0,
				duration: (options && options.duration) || DEFAULT_DURATION,
				translate: (options && options.translate) || '100%',
			},
		}),
	]);
}
