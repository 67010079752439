import { Directive, Injectable, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

class WithAutoUnsubscribe {
	private subscriptions: Subscription[] = [];

	protected addSubscription(sub: Subscription): void {
		this.subscriptions.push(sub);
	}

	protected addSubscriptions(subscriptions: Subscription[]): void {
		subscriptions.forEach(this.addSubscription.bind(this));
	}

	protected clearSubscriptions(): void {
		this.subscriptions.forEach((subscription: Subscription): void => {
			subscription.unsubscribe();
		});
		this.subscriptions = [];
	}

	protected oneAndDone<T = unknown>(
		subject: Subject<T> | BehaviorSubject<T> | Observable<T> | ReplaySubject<T>
	): Promise<T> {
		/**
		 * this is an explicit promise now because we were running into rxjs errors in testing
		 * we kept surfacing a 'no elements in sequence' error, which according to StackOverflow
		 * can occur when a `pipe` is called on an rxjs value that no longer exists. im assuming this
		 * is a race condition somewhere in the function calls here, so stubbing out a explicit promise
		 * and resolve seemed to have fixed the issue
		 */
		return new Promise<T>((resolve: (value: T) => void): void => {
			this.addSubscription(
				subject.pipe(untilDestroyed(this), take(1)).subscribe((value: T): void => {
					resolve(value);
				})
			);
		});
	}
}

/**
 * This Component class can be extended by components in order to unsubscribe from all
 * Subscriptions automatically in a component's onDestroy method. In you implement
 * your own onDestroy method in your component, you must call super.ngOnDestroy()
 * in order to take advantage of this.
 *
 * This Component is not intended to be instantiated directly.
 */
@UntilDestroy({
	checkProperties: true,
	arrayName: 'subscriptions',
})
@Directive({})
export class WithAutoUnsubscribeComponent extends WithAutoUnsubscribe implements OnDestroy {
	public ngOnDestroy(): void {
		this.clearSubscriptions();
	}
}

/**
 * This Service class can be extended by components in order to unsubscribe from all
 * Subscriptions automatically in a component's onDestroy method. In you implement
 * your own onDestroy method in your component, you must call super.ngOnDestroy()
 * in order to take advantage of this.
 *
 * This Component is not intended to be instantiated directly.
 */

@UntilDestroy({
	checkProperties: true,
	arrayName: 'subscriptions',
})
@Injectable()
export class WithAutoUnsubscribeService extends WithAutoUnsubscribe implements OnDestroy {
	public ngOnDestroy(): void {
		this.clearSubscriptions();
	}
}
