import { NgModule } from '@angular/core';
import { UserInitialsComponent } from '@prism-frontend/components/user-initials/user-initials.component';

@NgModule({
	imports: [],
	exports: [UserInitialsComponent],
	declarations: [UserInitialsComponent],
	providers: [],
})
export class UserInitialsModule {}
