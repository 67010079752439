import { Currency } from '@prism-frontend/typedefs/enums/currency';
import { plainToClass } from 'class-transformer';
import { IsBoolean, IsDefined, IsEnum, IsInt, IsNumber, IsOptional, IsString, MaxLength } from 'class-validator';

/**
 * the local storage key used for getting and setting the most recent value for
 * the is broadway tour flag in a form
 */
export const NEW_TOUR_BROADWAY_FLAG_KEY: string = 'broadwayTourDefault';

export class Tour {
	@IsInt() public id: number;

	@IsString()
	@MaxLength(100)
	public name: string = '';

	@IsEnum(Currency) public currency: Currency = Currency.USD;

	@IsBoolean() public is_confirmed: boolean = false;

	@IsOptional()
	@IsInt()
	public offer_logo_id: number | null = null;

	@IsInt() public organization_id: number;

	@IsInt() public created_by: number;

	@IsString()
	@IsDefined()
	public created_at: string;

	@IsString()
	@IsDefined()
	public updated_at: string;

	@IsBoolean()
	public calculate_by_net_gross: boolean = false;

	@IsBoolean()
	public is_broadway_tour: boolean = false;

	@IsBoolean()
	public has_settled_events: boolean = false;

	@IsBoolean()
	public has_talent_deals: boolean = false;

	@IsOptional()
	@IsNumber()
	public last_applied_template_id: number | null = null;

	public constructor(user?: Partial<TourInterface>) {
		return plainToClass(Tour, user);
	}
}

export interface TourInterface extends Tour {}
