import { DocumentNode, gql } from '@apollo/client/core';
import { ArtistFragment } from '@prism-frontend/entities/artists/artist-graphql/artist-graphql-query';
import { Currency } from '@prism-frontend/typedefs/enums/currency';
import { PaymentStatusBackend } from '@prism-frontend/typedefs/enums/PaymentStatus';
import { fetchPaginationProps, PaginationProps } from '@prism-frontend/typedefs/graphql/PaginationDetails';
import { InvoiceOrReceipt } from '@prism-frontend/typedefs/invoice';

const FullBalancePaymentFragment: DocumentNode = gql`
	fragment FullBalancePaymentFragment on TalentDealBalancePayment {
		id
		amount
		created_at
		deal_id
		invoice_id
		updated_at
	}
`;

export const FullInvoiceFragment: DocumentNode = gql`
	fragment FullInvoiceFragment on Invoice {
		id
		created_at
		updated_at
		amount
		artist_payout_towards_agent_commission
		agent_commission_payout
		currency
		agency_received_total_payments
		remaining_agent_artist_balance
		artist_show_balance
		adjustments_total
		paid_date
		notes
		invoice_terms
		payment_status
		org_invoice_id
		pdf_file_id
		pdfFile {
			id
			name
			file
			download_code
		}
		talent_agent_id
		talent_agent {
			...ArtistFragment
		}
		payments {
			...FullBalancePaymentFragment
		}
	}
	${FullBalancePaymentFragment}
	${ArtistFragment}
`;

export const ArtistInvoiceQuery: DocumentNode = gql`
	query ArtistInvoiceQuery($talent_agent_id: Int!, $orderBy: String!, $sortDirection: String!) {
		invoiceList(talent_agent_id: $talent_agent_id, orderBy: $orderBy, sortDirection: $sortDirection) {
			data {
				...FullInvoiceFragment
			}
		}
	}
	${FullInvoiceFragment}
`;

export interface ListInvoicesQueryArguments {
	// talent_agent_id: number;
	orderBy: keyof InvoiceOrReceipt;
	sortDirection: 'asc' | 'desc';
	currencies: Currency[];
	talent_agent_ids: number[];
	agent_ids: number[];
	payment_status: PaymentStatusBackend[];
	invoice_type: ('invoice' | 'receipt')[];
	created_start: string;
	created_end: string;
	paid_start: string;
	paid_end: string;
}

export const DEFAULT_LIST_INVOICES_QUERY_ARGUMENTS: ListInvoicesQueryArguments = {
	orderBy: 'org_invoice_id',
	sortDirection: 'desc',
	currencies: [],
	talent_agent_ids: [],
	agent_ids: [],
	payment_status: [],
	invoice_type: [],
	created_start: undefined,
	created_end: undefined,
	paid_start: undefined,
	paid_end: undefined,
};

const paginationProps: PaginationProps = fetchPaginationProps();

export const PaginatedListInvoicesQuery: DocumentNode = gql`
	query ArtistInvoiceQuery(
		$currencies: [String]
		$talent_agent_id: Int
		$talent_agent_ids: [Int]
		$agent_ids: [Int]
		$payment_status: [String]
		$invoice_type: [String]
		$orderBy: String!
		$sortDirection: String!
		$created_start: String
		$created_end: String
		$paid_start: String
		$paid_end: String
		${paginationProps.queryParams}
	) {
		invoiceList(
			currencies: $currencies
			talent_agent_id: $talent_agent_id
			talent_agent_ids: $talent_agent_ids
			agent_ids: $agent_ids
			payment_status: $payment_status
			invoice_type: $invoice_type
			orderBy: $orderBy
			sortDirection: $sortDirection
			created_start: $created_start
			created_end: $created_end
			paid_start: $paid_start
			paid_end: $paid_end
			${paginationProps.queryArguments}
		) {
			data {
				...FullInvoiceFragment
			}
			${paginationProps.responseProps}
		}
	}
	${FullInvoiceFragment}
`;

export const UpdateInvoiceMutation: DocumentNode = gql`
	mutation UpdateInvoice($id: Int!, $payment_status: String, $paid_date: String, $notes: String) {
		updateInvoice(id: $id, payment_status: $payment_status, paid_date: $paid_date, notes: $notes) {
			...FullInvoiceFragment
		}
	}
	${FullInvoiceFragment}
`;

export const DeleteInvoiceMutation: DocumentNode = gql`
	mutation DeleteInvoice($id: Int) {
		deleteInvoice(id: $id)
	}
`;

export interface ArtistInvoiceQueryArguments {
	talent_agent_id: number;
	orderBy: keyof InvoiceOrReceipt;
	sortDirection: 'asc' | 'desc';
}

export type UpdateInvoiceMutationArguments = Pick<InvoiceOrReceipt, 'id'> & Partial<Omit<InvoiceOrReceipt, 'id'>>;

export interface UpdateInvoiceMutationResponse {
	updateInvoice: InvoiceOrReceipt;
}

export type DeleteInvoiceMutationArguments = Pick<InvoiceOrReceipt, 'id'>;
