import { Component, Input } from '@angular/core';
import { getInitials } from '@prism-frontend/utils/static/getInitials';

@Component({
	selector: 'user-initials',
	template: ` <span class="user-initials">{{ userInitials }}</span> `,
	styleUrls: ['./user-initials.component.scss'],
})
export class UserInitialsComponent {
	@Input() public name: string = '';

	public get userInitials(): string {
		return getInitials(this.name || '');
	}
}
