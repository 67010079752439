import { EventPropsForTalentGuarantee } from '@prism-frontend/typedefs/EventPropsForTalentGuarantee';
import { CostCalc } from '@prism-frontend/typedefs/enums/calc';

/**
 * A function that returns the exchange rate to be applied while calculating
 * amounts on an event
 * @param costCalc The CostCalc to check for
 * @param eventProps The event settings for talent guarantee, containing currency conversions and rates
 * @returns the exchange rate to be used
 */
export function getExchangeRate(costCalc: CostCalc, eventProps: EventPropsForTalentGuarantee): number {
	if (!eventProps.use_custom_exchange_rates) {
		return eventProps.usd_exchange_rate;
	}

	if (getCustomExchangeRate(costCalc) === 'settlement') {
		return eventProps.settled_exchange_rate;
	}

	return eventProps.offer_exchange_rate;
}

export function getCustomExchangeRate(costCalc: CostCalc): 'offer' | 'settlement' {
	switch (costCalc) {
		case CostCalc.Reported:
		case CostCalc.Actual:
			return 'settlement';
		case CostCalc.Budgeted:
		case CostCalc.Potential:
		case CostCalc.Estimated:
			return 'offer';
		default:
			throw new Error(`Unrecognized CostCalc ${costCalc}.`);
	}
}
