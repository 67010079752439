import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogButtonRowComponentOptsBaseComponent } from '@prism-frontend/components/dialog-button-row/dialog-button-row.component';
import { fadeInAnimation, fadeOutAnimation } from '@prism-frontend/utils/animations/animations';

/**
 * ConfirmDialogContainer provides a way to easily spin up a new dialog components
 * with consistent styling. This component relies heavily on content projection
 * instead of @Inputs for the content of the dialog, so components can render any HTML
 * they want while still leaning on the styling and button rows defined in this component
 */
@Component({
	selector: 'confirm-dialog-container',
	template: ` <div class="confirm-dialog-container" [@fadeIn] [@fadeOut]>
		<div *ngIf="!suppressHeader" class="confirm-dialog-container-header">
			<ng-content select="[dialog-header]"></ng-content>
			<ng-content select="[dialog-sub-header]"></ng-content>
		</div>
		<div class="confirm-dialog-container-body">
			<ng-content select="[dialog-body]"></ng-content>
		</div>
		<dialog-button-row
			*ngIf="!suppressButtons"
			[buttonLayout]="buttonLayout"
			[inProgress]="inProgress"
			[disableCancel]="disableCancel"
			[extraClassesCancel]="extraClassesCancel"
			[buttonTitleTextCancel]="buttonTitleTextCancel"
			[buttonTextCancel]="buttonTextCancel"
			[iconCancel]="iconCancel"
			[disableConfirm]="disableConfirm"
			[extraClassesConfirm]="extraClassesConfirm"
			[buttonTitleTextConfirm]="buttonTitleTextConfirm"
			[buttonTextConfirm]="buttonTextConfirm"
			[iconConfirm]="iconConfirm"
			(confirm)="confirm.emit()"
			(cancel)="cancel.emit()"
		>
		</dialog-button-row>
		<div class="close-button" *ngIf="showCloseButton">
			<button mat-icon-button class="small-mat-icon" (click)="cancel.emit()" title="Close">
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</div>`,
	styleUrls: ['./confirm-dialog-container.component.scss'],
	animations: [fadeInAnimation({ duration: 1000 }), fadeOutAnimation({ duration: 300 })],
})
export class ConfirmDialogContainerComponent extends DialogButtonRowComponentOptsBaseComponent {
	// IO which is specific to this component
	@Input() public suppressHeader: boolean = false;
	@Input() public suppressButtons: boolean = false;

	@Input() public showCloseButton: boolean = false;

	@Output() public confirm: EventEmitter<void> = new EventEmitter<void>();
	@Output() public cancel: EventEmitter<void> = new EventEmitter<void>();
}
