import { CoreContactPropsFragment } from '@prism-frontend/typedefs/graphql/CoreContactFragment';
import { PartnerDealAdjustmentFragment } from '@prism-frontend/typedefs/graphql/PartnerDealAdjustments';
import { PartnerDealBackend } from '@prism-frontend/typedefs/partner-deal';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

const partnerDealMutationArguments: string = `
	$copro_share_percentage: Float!,
	$per_ticket_bonus: Float,
	$promoter_contact_id: Int,
`;

const partnerDealMutationArgumentMapping: string = `
	copro_share_percentage: $copro_share_percentage
	per_ticket_bonus: $per_ticket_bonus
    promoter_contact_id: $promoter_contact_id
`;

export const PartnerDealPropsFragment: DocumentNode = gql`
	fragment PartnerDealProps on PartnerDeal {
		id
		event_id
		organization_id
		promoter_contact_id
		copro_share_percentage
		per_ticket_bonus
		promoter {
			...CoreContactPropsFragment
		}
		adjustments {
			...PartnerDealAdjustmentProps
		}
	}
	${PartnerDealAdjustmentFragment}
`;

export const CreatePartnerDealMutation: DocumentNode = gql`
	mutation createPartnerDeal(
		$event_id: Int!
		${partnerDealMutationArguments}
	) {
		createPartnerDeal(
			event_id: $event_id
			${partnerDealMutationArgumentMapping}
		) {
			...PartnerDealProps
		}
	}
	${PartnerDealPropsFragment}
	${CoreContactPropsFragment}
`;

export const UpdatePartnerDealMutation: DocumentNode = gql`
	mutation updatePartnerDeal(
		$id: Int!
		${partnerDealMutationArguments}
	) {
		updatePartnerDeal(
			id: $id
			${partnerDealMutationArgumentMapping}
		) {
			...PartnerDealProps
		}
	}
	${PartnerDealPropsFragment}
	${CoreContactPropsFragment}
`;

export const DeletePartnerDealMutation: DocumentNode = gql`
	mutation DeletePartnerDeal($id: Int!) {
		deletePartnerDeal(id: $id)
	}
`;

export interface CreatePartnerDealMutationResponse {
	createPartnerDeal: PartnerDealBackend;
}

export interface UpdatePartnerDealMutationResponse {
	updatePartnerDeal: PartnerDealBackend;
}

export interface DeletePartnerDealMutationResponse {
	deletePartnerDeal: number;
}

interface PartnerDealMutationVariables {
	copro_share_percentage: number;
	per_ticket_bonus: number;
	promoter_contact_id: number;
}

export interface DeletePartnerDealMutationVariables {
	id: number;
}

export type CreatePartnerDealMutationVariables = PartnerDealMutationVariables & {
	event_id: number;
};

export type UpdatePartnerDealMutationVariable = PartnerDealMutationVariables & {
	id: number;
};
