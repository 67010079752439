import { IsInt, IsNumber, IsString } from 'class-validator';

export class AgentUserOnEvent {
	@IsNumber()
	public agent_user_id: number | null = null;

	@IsNumber()
	public agent_commission: number;

	@IsInt()
	public id: number;

	@IsString()
	public email: string;

	@IsString()
	public name: string;
}

/**
 * representing the data model for agents that are stored on
 * roster artists
 */
export class AgentUser extends AgentUserOnEvent {
	@IsNumber()
	public default_commission: number;
}
