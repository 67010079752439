import _ from 'lodash';

export function listAllPropsOnObject(obj: Object, stack: string = '', allProps: string[] = []): string[] {
	for (const property in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, property)) {
			const prop: string = `${stack ? `${stack}.` : ''}${property}`;
			if (typeof obj[property] === 'object') {
				listAllPropsOnObject(obj[property], prop, allProps);
			}
			allProps.push(prop);
		}
	}
	return allProps;
}

export function starify<T = string>(propertyName: string, trailingDigitReplacer: '' | '.*' = ''): T {
	// translate from prop names of prop.<index>.prop2[.<index2>] as
	// returned by listAllPropsOnObject() to the format prop.*.prop2[<trailingDigitReplacer>]
	return (
		propertyName
			.split(/\.\d+\./)
			.join('.*.')
			// drop prop.<index> since those props
			// are not valid field names
			.split(/\.\d+/)
			.join(trailingDigitReplacer) as unknown as T
	);
}

export function listUniquePropsOnObject(obj: Object, propsToOmit: string[] = []): string[] {
	return _.chain(listAllPropsOnObject(obj))
		.map((prop: string): string => {
			return starify(prop, '.*');
		})
		.filter((prop: string): boolean => {
			return !_.some(propsToOmit, (propToOmit: string): boolean => {
				return prop === propToOmit;
			});
		})
		.sort()
		.uniq()
		.value();
}
