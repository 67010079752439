import { CostCalc } from '@prism-frontend/typedefs/enums/calc';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';
export function fetchCostCalc2FromLegacyEMSParams(external: boolean, costCalc: CostCalc): CostCalc2 {
	let costCalc2: CostCalc2;
	switch (costCalc) {
		case CostCalc.Actual:
			costCalc2 = external ? CostCalc2.ExternalReported : CostCalc2.InternalActual;
			break;
		case CostCalc.Budgeted:
			costCalc2 = CostCalc2.ExternalBudgeted;
			if (!external) {
				throw new Error('It is erroneous to request "Internal Budgeted" numbers');
			}
			break;
		case CostCalc.Estimated:
			costCalc2 = external ? CostCalc2.ExternalEstimated : CostCalc2.InternalEstimated;
			break;
		case CostCalc.Reported:
			costCalc2 = CostCalc2.ExternalReported;
			if (!external) {
				throw new Error('It is erroneous to request "Internal Reported" numbers');
			}
			break;
		case CostCalc.Potential:
			costCalc2 = CostCalc2.InternalPotential;
			if (external) {
				throw new Error('It is erroneous to request "External Potential" numbers');
			}
			break;
		default:
			throw new Error(`Unrecognized costCalc: ${costCalc}`);
	}
	return costCalc2;
}
