import { IsNumber, IsString, IsUrl } from 'class-validator';

const downloadPath: string = `/files/download/`;
const previewPath: string = `/files/preview/`;

export interface Tag {
	label: string;
	value: string;
}

export interface EventFileAttachmentUpdate {
	new_name: string;
	new_notes: string;
}

export interface EventFileAttachmentInterface extends EventFileAttachment {}

export class EventFileAttachment {
	@IsString() public created_at: string;

	@IsNumber() public event_id: number;

	@IsString() public file: string;

	@IsUrl() public fullPath: string;

	@IsNumber() public id: number;

	@IsString() public name: string;

	@IsString() public notes: string;

	@IsNumber() public organization_id: number;

	public permissions: string[];

	// graphql queries give us roles as roles_string
	public roles_string: string;
	// legacy event files endpoint gives us roles as rolesString
	public rolesString: string;
	// the role string we display in the files tables is normalized via this getter
	public get rolesAsString(): string {
		return this.roles_string || this.rolesString;
	}

	public tags: string[];
	// graphql queries give us tags as tags_string
	public tags_string: string;
	// legacy event files endpoint gives us tags as tagsString
	public tagsString: string;
	// the tags string we display in the files tables is normalized via this getter
	public get tagsAsString(): string {
		if (this.tags) {
			return this.tags.join(', ');
		}
		return this.tags_string || this.tagsString;
	}

	public download_code: string;
	public downloadCode: string;
	public get downloadCodeString(): string {
		if (this.download_code) {
			return this.download_code;
		}
		return this.downloadCode;
	}

	public get downloadUrl(): string {
		return `${downloadPath}${this.downloadCodeString}`;
	}

	public get previewUrl(): string {
		return `${previewPath}${this.downloadCodeString}`;
	}

	public updated_at: string;
	public user_id: string;
	public visible_all: number;

	/**
	 * get the first part of a filename for editing
	 *
	 * 'some-cool-pic.png' -> 'some-cool-pic'
	 */
	public get fileName(): string {
		return this.name.split('.').shift();
	}

	/**
	 * set the filename; be sure to preserve the attachment
	 */
	public set fileName(newFileName: string) {
		this.name = `${newFileName}.${this.fileAttachment}`;
	}

	/**
	 * get the file attachment
	 *
	 * 'some-cool-pic.png' -> '.png'
	 */
	public get fileAttachment(): string {
		return this.name.split('.').pop();
	}
}
