import { Debug, getDebug } from '@prism-frontend/utils/static/getDebug';
import * as _ from 'lodash';
import moment from 'moment';
const debug: Debug = getDebug('dateRangeHelper');

export interface DateRange {
	start: moment.Moment;
	end: moment.Moment;
}

// Overlapping or continuous date ranges
function dateRangesOverlap(dr1: DateRange, dr2: DateRange): boolean {
	if (
		dr1.start.isSame(dr2.start, 'day') ||
		dr1.start.isSame(dr2.end, 'day') ||
		dr1.end.isSame(dr2.start, 'day') ||
		dr1.end.isSame(dr2.end, 'day') ||
		// Consider neighbour date ranges too (e.g. 05.06-05-18 <-> 05.19-05.30)
		dr1.end.clone().add(1, 'day').isSame(dr2.start, 'day') ||
		dr2.end.clone().add(1, 'day').isSame(dr1.start, 'day')
	) {
		return true;
	}

	return (
		dr1.start.isBetween(dr2.start, dr2.end) ||
		dr1.end.isBetween(dr2.start, dr2.end) ||
		dr2.start.isBetween(dr1.start, dr1.end) ||
		dr2.end.isBetween(dr1.start, dr1.end)
	);
}

function dateRangeContains(dr1: DateRange, dr2: DateRange): boolean {
	return dr1.start.isSameOrBefore(dr2.start) && dr1.end.isSameOrAfter(dr2.end);
}

export function anyDateRangeContains(dateRanges: DateRange[], dr: DateRange): boolean {
	return dateRanges.some((dr1: DateRange): boolean => {
		return dateRangeContains(dr1, dr);
	});
}

function joinDateRanges(dr1: DateRange, dr2: DateRange): DateRange {
	if (!dateRangesOverlap(dr1, dr2)) {
		// eslint-disable-next-line no-console
		console.error('data ranges are not overlapping');
	}
	return {
		start: (dr1.start.isSameOrBefore(dr2.start) ? dr1.start : dr2.start).clone(),
		end: (dr1.end.isSameOrAfter(dr2.end) ? dr1.end : dr2.end).clone(),
	};
}

function joinAllDateRanges(dateRanges: DateRange[]): DateRange {
	return dateRanges.reduce((acc: DateRange, curr: DateRange): DateRange => {
		return joinDateRanges(acc, curr);
	}, dateRanges[0]);
}

function logDateRange(dr: DateRange): void {
	// eslint-disable-next-line no-console
	console.log(
		JSON.stringify({
			start: dr.start.format('YYYY-MM-DD'),
			end: dr.end.format('YYYY-MM-DD'),
		})
	);
}

export function logDateRanges(dateRanges: DateRange[], aggregate: boolean = true): void {
	if (!aggregate) {
		dateRanges.forEach((dr: DateRange): void => {
			logDateRange(dr);
		});
	} else {
		debug(
			JSON.stringify(
				dateRanges.map((dr: DateRange): { start: string; end: string } => {
					return {
						start: dr.start.format('YYYY-MM-DD'),
						end: dr.end.format('YYYY-MM-DD'),
					};
				})
			)
		);
	}
}

// This function combines the overlapping and neighbour date ranges and returns a list of mutually exclusive DateRange items
export function aggregateAllOverlappingDateRanges(dateRanges: DateRange[]): DateRange[] {
	dateRanges = sortDateRanges(cloneDateRanges(dateRanges));
	const resultDateRanges: DateRange[] = [];

	if (dateRanges.length === 1) {
		return dateRanges;
	}

	while (dateRanges.length > 0) {
		const dr1: DateRange = dateRanges[0];
		const overlappingDateRanges: DateRange[] = [dr1];
		_.without(dateRanges, dr1).forEach((dr2: DateRange): void => {
			if (dateRangesOverlap(dr1, dr2)) {
				overlappingDateRanges.push(dr2);
			}
		});
		resultDateRanges.push(joinAllDateRanges(overlappingDateRanges));
		_.pullAll(dateRanges, overlappingDateRanges);
	}

	return resultDateRanges;
}

// This function shortens a date range, it subtracts the passed months from the date range's both ends if possible
export function subtractDateRangesFromDateRange(
	start: moment.Moment,
	end: moment.Moment,
	dateRanges: DateRange[]
): DateRange {
	dateRanges = cloneDateRanges(dateRanges);
	let result: DateRange = {
		start: start.clone(),
		end: end.clone(),
	};

	dateRanges.forEach((dateRange: DateRange): void => {
		// Full overlap
		if (dateRange.end.isSameOrAfter(end) && dateRange.start.isSameOrBefore(result.start)) {
			result.end = result.start.clone();
			return;
		}
		// Before
		if (dateRange.end.isSameOrAfter(result.start) && dateRange.start.isSameOrBefore(result.start)) {
			result.start = dateRange.end.clone().add(1, 'day');
			if (result.start.isAfter(result.end)) {
				result.start = result.end.clone();
			}
			result = subtractDateRangesFromDateRange(result.start, result.end, _.without(dateRanges, dateRange));
		}
		// After
		if (dateRange.end.isSameOrAfter(end) && dateRange.start.isSameOrBefore(end)) {
			result.end = dateRange.start.clone().subtract(1, 'day');
			result = subtractDateRangesFromDateRange(result.start, result.end, _.without(dateRanges, dateRange));
		}
	});

	if (result.start.isAfter(result.end)) {
		result.end = result.start;
	}

	return result;
}

function cloneDateRange(dr: DateRange): DateRange {
	return {
		start: dr.start.clone(),
		end: dr.end.clone(),
	};
}

function cloneDateRanges(dateRanges: DateRange[]): DateRange[] {
	return dateRanges.map((dr: DateRange): DateRange => {
		return cloneDateRange(dr);
	});
}

function sortDateRanges(dateRanges: DateRange[]): DateRange[] {
	return dateRanges.sort((dr1: DateRange, dr2: DateRange): number => {
		if (dr1.start.isBefore(dr2.start)) return -1;
		if (dr1.start.isAfter(dr2.start)) return 1;
		if (dr1.end.isBefore(dr2.end)) return -1;
		return 0;
	});
}
