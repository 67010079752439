/**
 * List extracted from this page, using the following code snippet:
 *
 * https://fonts.google.com/icons?icon.set=Material+Icons
 *
 * all = []; document.querySelectorAll('.icon-asset').forEach(a => all.push(a.innerText)); copy(all)
 *
 * NOTE you must scroll to the bottom of the page first to get the page to load
 */

export enum MatIconEnum {
	'10k' = '10k',
	'10mp' = '10mp',
	'11mp' = '11mp',
	'12mp' = '12mp',
	'13mp' = '13mp',
	'14mp' = '14mp',
	'15mp' = '15mp',
	'16mp' = '16mp',
	'17mp' = '17mp',
	'18_up_rating' = '18_up_rating',
	'18mp' = '18mp',
	'19mp' = '19mp',
	'1k_plus' = '1k_plus',
	'1k' = '1k',
	'1x_mobiledata' = '1x_mobiledata',
	'20mp' = '20mp',
	'21mp' = '21mp',
	'22mp' = '22mp',
	'23mp' = '23mp',
	'24mp' = '24mp',
	'2k_plus' = '2k_plus',
	'2k' = '2k',
	'2mp' = '2mp',
	'30fps_select' = '30fps_select',
	'30fps' = '30fps',
	'3d_rotation' = '3d_rotation',
	'3g_mobiledata' = '3g_mobiledata',
	'3k_plus' = '3k_plus',
	'3k' = '3k',
	'3mp' = '3mp',
	'3p' = '3p',
	'4g_mobiledata' = '4g_mobiledata',
	'4g_plus_mobiledata' = '4g_plus_mobiledata',
	'4k_plus' = '4k_plus',
	'4k' = '4k',
	'4mp' = '4mp',
	'5g' = '5g',
	'5k_plus' = '5k_plus',
	'5k' = '5k',
	'5mp' = '5mp',
	'6_ft_apart' = '6_ft_apart',
	'60fps_select' = '60fps_select',
	'60fps' = '60fps',
	'6k_plus' = '6k_plus',
	'6k' = '6k',
	'6mp' = '6mp',
	'7k_plus' = '7k_plus',
	'7k' = '7k',
	'7mp' = '7mp',
	'8k_plus' = '8k_plus',
	'8k' = '8k',
	'8mp' = '8mp',
	'9k_plus' = '9k_plus',
	'9k' = '9k',
	'9mp' = '9mp',
	'abc' = 'abc',
	'ac_unit' = 'ac_unit',
	'access_alarm' = 'access_alarm',
	'access_alarms' = 'access_alarms',
	'access_time_filled' = 'access_time_filled',
	'access_time' = 'access_time',
	'accessibility_new' = 'accessibility_new',
	'accessibility' = 'accessibility',
	'accessible_forward' = 'accessible_forward',
	'accessible' = 'accessible',
	'account_balance_wallet' = 'account_balance_wallet',
	'account_balance' = 'account_balance',
	'account_box' = 'account_box',
	'account_circle' = 'account_circle',
	'account_tree' = 'account_tree',
	'ad_units' = 'ad_units',
	'adb' = 'adb',
	'add_a_photo' = 'add_a_photo',
	'add_alarm' = 'add_alarm',
	'add_alert' = 'add_alert',
	'add_box' = 'add_box',
	'add_business' = 'add_business',
	'add_card' = 'add_card',
	'add_chart' = 'add_chart',
	'add_circle_outline' = 'add_circle_outline',
	'add_circle' = 'add_circle',
	'add_comment' = 'add_comment',
	'add_home_work' = 'add_home_work',
	'add_home' = 'add_home',
	'add_ic_call' = 'add_ic_call',
	'add_link' = 'add_link',
	'add_location_alt' = 'add_location_alt',
	'add_location' = 'add_location',
	'add_moderator' = 'add_moderator',
	'add_photo_alternate' = 'add_photo_alternate',
	'add_reaction' = 'add_reaction',
	'add_road' = 'add_road',
	'add_shopping_cart' = 'add_shopping_cart',
	'add_task' = 'add_task',
	'add_to_drive' = 'add_to_drive',
	'add_to_home_screen' = 'add_to_home_screen',
	'add_to_photos' = 'add_to_photos',
	'add_to_queue' = 'add_to_queue',
	'add' = 'add',
	'addchart' = 'addchart',
	'adf_scanner' = 'adf_scanner',
	'adjust' = 'adjust',
	'admin_panel_settings' = 'admin_panel_settings',
	'ads_click' = 'ads_click',
	'agriculture' = 'agriculture',
	'air' = 'air',
	'airline_seat_flat_angled' = 'airline_seat_flat_angled',
	'airline_seat_flat' = 'airline_seat_flat',
	'airline_seat_individual_suite' = 'airline_seat_individual_suite',
	'airline_seat_legroom_extra' = 'airline_seat_legroom_extra',
	'airline_seat_legroom_normal' = 'airline_seat_legroom_normal',
	'airline_seat_legroom_reduced' = 'airline_seat_legroom_reduced',
	'airline_seat_recline_extra' = 'airline_seat_recline_extra',
	'airline_seat_recline_normal' = 'airline_seat_recline_normal',
	'airline_stops' = 'airline_stops',
	'airlines' = 'airlines',
	'airplane_ticket' = 'airplane_ticket',
	'airplanemode_active' = 'airplanemode_active',
	'airplanemode_inactive' = 'airplanemode_inactive',
	'airplay' = 'airplay',
	'airport_shuttle' = 'airport_shuttle',
	'alarm_add' = 'alarm_add',
	'alarm_off' = 'alarm_off',
	'alarm_on' = 'alarm_on',
	'alarm' = 'alarm',
	'album' = 'album',
	'align_horizontal_center' = 'align_horizontal_center',
	'align_horizontal_left' = 'align_horizontal_left',
	'align_horizontal_right' = 'align_horizontal_right',
	'align_vertical_bottom' = 'align_vertical_bottom',
	'align_vertical_center' = 'align_vertical_center',
	'align_vertical_top' = 'align_vertical_top',
	'all_inbox' = 'all_inbox',
	'all_inclusive' = 'all_inclusive',
	'all_out' = 'all_out',
	'alt_route' = 'alt_route',
	'alternate_email' = 'alternate_email',
	'analytics' = 'analytics',
	'anchor' = 'anchor',
	'android' = 'android',
	'animation' = 'animation',
	'announcement' = 'announcement',
	'aod' = 'aod',
	'apartment' = 'apartment',
	'api' = 'api',
	'app_blocking' = 'app_blocking',
	'app_registration' = 'app_registration',
	'app_settings_alt' = 'app_settings_alt',
	'app_shortcut' = 'app_shortcut',
	'approval' = 'approval',
	'apps_outage' = 'apps_outage',
	'apps' = 'apps',
	'architecture' = 'architecture',
	'archive' = 'archive',
	'area_chart' = 'area_chart',
	'arrow_back_ios_new' = 'arrow_back_ios_new',
	'arrow_back_ios' = 'arrow_back_ios',
	'arrow_back' = 'arrow_back',
	'arrow_circle_down' = 'arrow_circle_down',
	'arrow_circle_left' = 'arrow_circle_left',
	'arrow_circle_right' = 'arrow_circle_right',
	'arrow_circle_up' = 'arrow_circle_up',
	'arrow_downward' = 'arrow_downward',
	'arrow_drop_down_circle' = 'arrow_drop_down_circle',
	'arrow_drop_down' = 'arrow_drop_down',
	'arrow_drop_up' = 'arrow_drop_up',
	'arrow_forward_ios' = 'arrow_forward_ios',
	'arrow_forward' = 'arrow_forward',
	'arrow_left' = 'arrow_left',
	'arrow_outward' = 'arrow_outward',
	'arrow_right_alt' = 'arrow_right_alt',
	'arrow_right' = 'arrow_right',
	'arrow_upward' = 'arrow_upward',
	'art_track' = 'art_track',
	'article' = 'article',
	'aspect_ratio' = 'aspect_ratio',
	'assessment' = 'assessment',
	'assignment_ind' = 'assignment_ind',
	'assignment_late' = 'assignment_late',
	'assignment_return' = 'assignment_return',
	'assignment_returned' = 'assignment_returned',
	'assignment_turned_in' = 'assignment_turned_in',
	'assignment' = 'assignment',
	'assist_walker' = 'assist_walker',
	'assistant_direction' = 'assistant_direction',
	'assistant_photo' = 'assistant_photo',
	'assistant' = 'assistant',
	'assured_workload' = 'assured_workload',
	'atm' = 'atm',
	'attach_email' = 'attach_email',
	'attach_file' = 'attach_file',
	'attach_money' = 'attach_money',
	'attachment' = 'attachment',
	'attractions' = 'attractions',
	'attribution' = 'attribution',
	'audio_file' = 'audio_file',
	'audiotrack' = 'audiotrack',
	'auto_awesome_mosaic' = 'auto_awesome_mosaic',
	'auto_awesome_motion' = 'auto_awesome_motion',
	'auto_awesome' = 'auto_awesome',
	'auto_delete' = 'auto_delete',
	'auto_fix_high' = 'auto_fix_high',
	'auto_fix_normal' = 'auto_fix_normal',
	'auto_fix_off' = 'auto_fix_off',
	'auto_graph' = 'auto_graph',
	'auto_mode' = 'auto_mode',
	'auto_stories' = 'auto_stories',
	'autofps_select' = 'autofps_select',
	'autorenew' = 'autorenew',
	'av_timer' = 'av_timer',
	'baby_changing_station' = 'baby_changing_station',
	'back_hand' = 'back_hand',
	'backpack' = 'backpack',
	'backspace' = 'backspace',
	'backup_table' = 'backup_table',
	'backup' = 'backup',
	'badge' = 'badge',
	'bakery_dining' = 'bakery_dining',
	'balance' = 'balance',
	'balcony' = 'balcony',
	'ballot' = 'ballot',
	'bar_chart' = 'bar_chart',
	'batch_prediction' = 'batch_prediction',
	'bathroom' = 'bathroom',
	'bathtub' = 'bathtub',
	'battery_0_bar' = 'battery_0_bar',
	'battery_1_bar' = 'battery_1_bar',
	'battery_2_bar' = 'battery_2_bar',
	'battery_3_bar' = 'battery_3_bar',
	'battery_4_bar' = 'battery_4_bar',
	'battery_5_bar' = 'battery_5_bar',
	'battery_6_bar' = 'battery_6_bar',
	'battery_alert' = 'battery_alert',
	'battery_charging_full' = 'battery_charging_full',
	'battery_full' = 'battery_full',
	'battery_saver' = 'battery_saver',
	'battery_std' = 'battery_std',
	'battery_unknown' = 'battery_unknown',
	'beach_access' = 'beach_access',
	'bed' = 'bed',
	'bedroom_baby' = 'bedroom_baby',
	'bedroom_child' = 'bedroom_child',
	'bedroom_parent' = 'bedroom_parent',
	'bedtime_off' = 'bedtime_off',
	'bedtime' = 'bedtime',
	'beenhere' = 'beenhere',
	'bento' = 'bento',
	'bike_scooter' = 'bike_scooter',
	'biotech' = 'biotech',
	'blender' = 'blender',
	'blind' = 'blind',
	'blinds_closed' = 'blinds_closed',
	'blinds' = 'blinds',
	'block' = 'block',
	'bloodtype' = 'bloodtype',
	'bluetooth_audio' = 'bluetooth_audio',
	'bluetooth_connected' = 'bluetooth_connected',
	'bluetooth_disabled' = 'bluetooth_disabled',
	'bluetooth_drive' = 'bluetooth_drive',
	'bluetooth_searching' = 'bluetooth_searching',
	'bluetooth' = 'bluetooth',
	'blur_circular' = 'blur_circular',
	'blur_linear' = 'blur_linear',
	'blur_off' = 'blur_off',
	'blur_on' = 'blur_on',
	'bolt' = 'bolt',
	'book_online' = 'book_online',
	'book' = 'book',
	'bookmark_add' = 'bookmark_add',
	'bookmark_added' = 'bookmark_added',
	'bookmark_border' = 'bookmark_border',
	'bookmark_remove' = 'bookmark_remove',
	'bookmark' = 'bookmark',
	'bookmarks' = 'bookmarks',
	'border_all' = 'border_all',
	'border_bottom' = 'border_bottom',
	'border_clear' = 'border_clear',
	'border_color' = 'border_color',
	'border_horizontal' = 'border_horizontal',
	'border_inner' = 'border_inner',
	'border_left' = 'border_left',
	'border_outer' = 'border_outer',
	'border_right' = 'border_right',
	'border_style' = 'border_style',
	'border_top' = 'border_top',
	'border_vertical' = 'border_vertical',
	'boy' = 'boy',
	'branding_watermark' = 'branding_watermark',
	'breakfast_dining' = 'breakfast_dining',
	'brightness_1' = 'brightness_1',
	'brightness_2' = 'brightness_2',
	'brightness_3' = 'brightness_3',
	'brightness_4' = 'brightness_4',
	'brightness_5' = 'brightness_5',
	'brightness_6' = 'brightness_6',
	'brightness_7' = 'brightness_7',
	'brightness_auto' = 'brightness_auto',
	'brightness_high' = 'brightness_high',
	'brightness_low' = 'brightness_low',
	'brightness_medium' = 'brightness_medium',
	'broadcast_on_home' = 'broadcast_on_home',
	'broadcast_on_personal' = 'broadcast_on_personal',
	'broken_image' = 'broken_image',
	'browse_gallery' = 'browse_gallery',
	'browser_not_supported' = 'browser_not_supported',
	'browser_updated' = 'browser_updated',
	'brunch_dining' = 'brunch_dining',
	'brush' = 'brush',
	'bubble_chart' = 'bubble_chart',
	'bug_report' = 'bug_report',
	'build_circle' = 'build_circle',
	'build' = 'build',
	'bungalow' = 'bungalow',
	'burst_mode' = 'burst_mode',
	'bus_alert' = 'bus_alert',
	'business_center' = 'business_center',
	'business' = 'business',
	'cabin' = 'cabin',
	'cable' = 'cable',
	'cached' = 'cached',
	'cake' = 'cake',
	'calculate' = 'calculate',
	'calendar_month' = 'calendar_month',
	'calendar_today' = 'calendar_today',
	'calendar_view_day' = 'calendar_view_day',
	'calendar_view_month' = 'calendar_view_month',
	'calendar_view_week' = 'calendar_view_week',
	'call_end' = 'call_end',
	'call_made' = 'call_made',
	'call_merge' = 'call_merge',
	'call_missed_outgoing' = 'call_missed_outgoing',
	'call_missed' = 'call_missed',
	'call_received' = 'call_received',
	'call_split' = 'call_split',
	'call_to_action' = 'call_to_action',
	'call' = 'call',
	'camera_alt' = 'camera_alt',
	'camera_enhance' = 'camera_enhance',
	'camera_front' = 'camera_front',
	'camera_indoor' = 'camera_indoor',
	'camera_outdoor' = 'camera_outdoor',
	'camera_rear' = 'camera_rear',
	'camera_roll' = 'camera_roll',
	'camera' = 'camera',
	'cameraswitch' = 'cameraswitch',
	'campaign' = 'campaign',
	'cancel_presentation' = 'cancel_presentation',
	'cancel_schedule_send' = 'cancel_schedule_send',
	'cancel' = 'cancel',
	'candlestick_chart' = 'candlestick_chart',
	'car_crash' = 'car_crash',
	'car_rental' = 'car_rental',
	'car_repair' = 'car_repair',
	'card_giftcard' = 'card_giftcard',
	'card_membership' = 'card_membership',
	'card_travel' = 'card_travel',
	'carpenter' = 'carpenter',
	'cases' = 'cases',
	'casino' = 'casino',
	'cast_connected' = 'cast_connected',
	'cast_for_education' = 'cast_for_education',
	'cast' = 'cast',
	'castle' = 'castle',
	'catching_pokemon' = 'catching_pokemon',
	'category' = 'category',
	'celebration' = 'celebration',
	'cell_tower' = 'cell_tower',
	'cell_wifi' = 'cell_wifi',
	'center_focus_strong' = 'center_focus_strong',
	'center_focus_weak' = 'center_focus_weak',
	'chair_alt' = 'chair_alt',
	'chair' = 'chair',
	'chalet' = 'chalet',
	'change_circle' = 'change_circle',
	'change_history' = 'change_history',
	'charging_station' = 'charging_station',
	'chat_bubble_outline' = 'chat_bubble_outline',
	'chat_bubble' = 'chat_bubble',
	'chat' = 'chat',
	'check_box_outline_blank' = 'check_box_outline_blank',
	'check_box' = 'check_box',
	'check_circle_outline' = 'check_circle_outline',
	'check_circle' = 'check_circle',
	'check' = 'check',
	'checklist_rtl' = 'checklist_rtl',
	'checklist' = 'checklist',
	'checkroom' = 'checkroom',
	'chevron_left' = 'chevron_left',
	'chevron_right' = 'chevron_right',
	'child_care' = 'child_care',
	'child_friendly' = 'child_friendly',
	'chrome_reader_mode' = 'chrome_reader_mode',
	'church' = 'church',
	'circle_notifications' = 'circle_notifications',
	'circle' = 'circle',
	'class' = 'class',
	'clean_hands' = 'clean_hands',
	'cleaning_services' = 'cleaning_services',
	'clear_all' = 'clear_all',
	'clear' = 'clear',
	'close_fullscreen' = 'close_fullscreen',
	'close' = 'close',
	'closed_caption_disabled' = 'closed_caption_disabled',
	'closed_caption_off' = 'closed_caption_off',
	'closed_caption' = 'closed_caption',
	'cloud_circle' = 'cloud_circle',
	'cloud_done' = 'cloud_done',
	'cloud_download' = 'cloud_download',
	'cloud_off' = 'cloud_off',
	'cloud_queue' = 'cloud_queue',
	'cloud_sync' = 'cloud_sync',
	'cloud_upload' = 'cloud_upload',
	'cloud' = 'cloud',
	'co_present' = 'co_present',
	'co2' = 'co2',
	'code_off' = 'code_off',
	'code' = 'code',
	'coffee_maker' = 'coffee_maker',
	'coffee' = 'coffee',
	'collections_bookmark' = 'collections_bookmark',
	'collections' = 'collections',
	'color_lens' = 'color_lens',
	'colorize' = 'colorize',
	'comment_bank' = 'comment_bank',
	'comment' = 'comment',
	'comments_disabled' = 'comments_disabled',
	'commit' = 'commit',
	'commute' = 'commute',
	'compare_arrows' = 'compare_arrows',
	'compare' = 'compare',
	'compass_calibration' = 'compass_calibration',
	'compost' = 'compost',
	'compress' = 'compress',
	'computer' = 'computer',
	'confirmation_number' = 'confirmation_number',
	'connect_without_contact' = 'connect_without_contact',
	'connected_tv' = 'connected_tv',
	'connecting_airports' = 'connecting_airports',
	'construction' = 'construction',
	'contact_emergency' = 'contact_emergency',
	'contact_mail' = 'contact_mail',
	'contact_page' = 'contact_page',
	'contact_phone' = 'contact_phone',
	'contact_support' = 'contact_support',
	'contactless' = 'contactless',
	'contacts' = 'contacts',
	'content_copy' = 'content_copy',
	'content_cut' = 'content_cut',
	'content_paste_go' = 'content_paste_go',
	'content_paste_off' = 'content_paste_off',
	'content_paste_search' = 'content_paste_search',
	'content_paste' = 'content_paste',
	'contrast' = 'contrast',
	'control_camera' = 'control_camera',
	'control_point_duplicate' = 'control_point_duplicate',
	'control_point' = 'control_point',
	'cookie' = 'cookie',
	'copy_all' = 'copy_all',
	'copyright' = 'copyright',
	'coronavirus' = 'coronavirus',
	'corporate_fare' = 'corporate_fare',
	'cottage' = 'cottage',
	'countertops' = 'countertops',
	'create_new_folder' = 'create_new_folder',
	'create' = 'create',
	'credit_card_off' = 'credit_card_off',
	'credit_card' = 'credit_card',
	'credit_score' = 'credit_score',
	'crib' = 'crib',
	'crisis_alert' = 'crisis_alert',
	'crop_16_9' = 'crop_16_9',
	'crop_3_2' = 'crop_3_2',
	'crop_5_4' = 'crop_5_4',
	'crop_7_5' = 'crop_7_5',
	'crop_din' = 'crop_din',
	'crop_free' = 'crop_free',
	'crop_landscape' = 'crop_landscape',
	'crop_original' = 'crop_original',
	'crop_portrait' = 'crop_portrait',
	'crop_rotate' = 'crop_rotate',
	'crop_square' = 'crop_square',
	'crop' = 'crop',
	'cruelty_free' = 'cruelty_free',
	'css' = 'css',
	'currency_bitcoin' = 'currency_bitcoin',
	'currency_exchange' = 'currency_exchange',
	'currency_franc' = 'currency_franc',
	'currency_lira' = 'currency_lira',
	'currency_pound' = 'currency_pound',
	'currency_ruble' = 'currency_ruble',
	'currency_rupee' = 'currency_rupee',
	'currency_yen' = 'currency_yen',
	'currency_yuan' = 'currency_yuan',
	'curtains_closed' = 'curtains_closed',
	'curtains' = 'curtains',
	'cyclone' = 'cyclone',
	'dangerous' = 'dangerous',
	'dark_mode' = 'dark_mode',
	'dashboard_customize' = 'dashboard_customize',
	'dashboard' = 'dashboard',
	'data_array' = 'data_array',
	'data_exploration' = 'data_exploration',
	'data_object' = 'data_object',
	'data_saver_off' = 'data_saver_off',
	'data_saver_on' = 'data_saver_on',
	'data_thresholding' = 'data_thresholding',
	'data_usage' = 'data_usage',
	'dataset_linked' = 'dataset_linked',
	'dataset' = 'dataset',
	'date_range' = 'date_range',
	'deblur' = 'deblur',
	'deck' = 'deck',
	'dehaze' = 'dehaze',
	'delete_forever' = 'delete_forever',
	'delete_outline' = 'delete_outline',
	'delete_sweep' = 'delete_sweep',
	'delete' = 'delete',
	'delivery_dining' = 'delivery_dining',
	'density_large' = 'density_large',
	'density_medium' = 'density_medium',
	'density_small' = 'density_small',
	'departure_board' = 'departure_board',
	'description' = 'description',
	'deselect' = 'deselect',
	'design_services' = 'design_services',
	'desk' = 'desk',
	'desktop_access_disabled' = 'desktop_access_disabled',
	'desktop_mac' = 'desktop_mac',
	'desktop_windows' = 'desktop_windows',
	'details' = 'details',
	'developer_board_off' = 'developer_board_off',
	'developer_board' = 'developer_board',
	'developer_mode' = 'developer_mode',
	'device_hub' = 'device_hub',
	'device_thermostat' = 'device_thermostat',
	'device_unknown' = 'device_unknown',
	'devices_fold' = 'devices_fold',
	'devices_other' = 'devices_other',
	'devices' = 'devices',
	'dialer_sip' = 'dialer_sip',
	'dialpad' = 'dialpad',
	'diamond' = 'diamond',
	'difference' = 'difference',
	'dining' = 'dining',
	'dinner_dining' = 'dinner_dining',
	'directions_bike' = 'directions_bike',
	'directions_boat_filled' = 'directions_boat_filled',
	'directions_boat' = 'directions_boat',
	'directions_bus_filled' = 'directions_bus_filled',
	'directions_bus' = 'directions_bus',
	'directions_car_filled' = 'directions_car_filled',
	'directions_car' = 'directions_car',
	'directions_off' = 'directions_off',
	'directions_railway_filled' = 'directions_railway_filled',
	'directions_railway' = 'directions_railway',
	'directions_run' = 'directions_run',
	'directions_subway_filled' = 'directions_subway_filled',
	'directions_subway' = 'directions_subway',
	'directions_transit_filled' = 'directions_transit_filled',
	'directions_transit' = 'directions_transit',
	'directions_walk' = 'directions_walk',
	'directions' = 'directions',
	'dirty_lens' = 'dirty_lens',
	'disabled_by_default' = 'disabled_by_default',
	'disabled_visible' = 'disabled_visible',
	'disc_full' = 'disc_full',
	'discount' = 'discount',
	'display_settings' = 'display_settings',
	'diversity_1' = 'diversity_1',
	'diversity_2' = 'diversity_2',
	'diversity_3' = 'diversity_3',
	'dns' = 'dns',
	'do_disturb_alt' = 'do_disturb_alt',
	'do_disturb_off' = 'do_disturb_off',
	'do_disturb_on' = 'do_disturb_on',
	'do_disturb' = 'do_disturb',
	'do_not_disturb_alt' = 'do_not_disturb_alt',
	'do_not_disturb_off' = 'do_not_disturb_off',
	'do_not_disturb_on_total_silence' = 'do_not_disturb_on_total_silence',
	'do_not_disturb_on' = 'do_not_disturb_on',
	'do_not_disturb' = 'do_not_disturb',
	'do_not_step' = 'do_not_step',
	'do_not_touch' = 'do_not_touch',
	'dock' = 'dock',
	'document_scanner' = 'document_scanner',
	'domain_add' = 'domain_add',
	'domain_disabled' = 'domain_disabled',
	'domain_verification' = 'domain_verification',
	'domain' = 'domain',
	'done_all' = 'done_all',
	'done_outline' = 'done_outline',
	'done' = 'done',
	'donut_large' = 'donut_large',
	'donut_small' = 'donut_small',
	'door_back' = 'door_back',
	'door_front' = 'door_front',
	'door_sliding' = 'door_sliding',
	'doorbell' = 'doorbell',
	'double_arrow' = 'double_arrow',
	'downhill_skiing' = 'downhill_skiing',
	'download_done' = 'download_done',
	'download_for_offline' = 'download_for_offline',
	'download' = 'download',
	'downloading' = 'downloading',
	'drafts' = 'drafts',
	'drag_handle' = 'drag_handle',
	'drag_indicator' = 'drag_indicator',
	'draw' = 'draw',
	'drive_eta' = 'drive_eta',
	'drive_file_move_rtl' = 'drive_file_move_rtl',
	'drive_file_move' = 'drive_file_move',
	'drive_file_rename_outline' = 'drive_file_rename_outline',
	'drive_folder_upload' = 'drive_folder_upload',
	'dry_cleaning' = 'dry_cleaning',
	'dry' = 'dry',
	'duo' = 'duo',
	'dvr' = 'dvr',
	'dynamic_feed' = 'dynamic_feed',
	'dynamic_form' = 'dynamic_form',
	'e_mobiledata' = 'e_mobiledata',
	'earbuds_battery' = 'earbuds_battery',
	'earbuds' = 'earbuds',
	'east' = 'east',
	'edgesensor_high' = 'edgesensor_high',
	'edgesensor_low' = 'edgesensor_low',
	'edit_attributes' = 'edit_attributes',
	'edit_calendar' = 'edit_calendar',
	'edit_location_alt' = 'edit_location_alt',
	'edit_location' = 'edit_location',
	'edit_note' = 'edit_note',
	'edit_notifications' = 'edit_notifications',
	'edit_off' = 'edit_off',
	'edit_road' = 'edit_road',
	'edit' = 'edit',
	'egg_alt' = 'egg_alt',
	'egg' = 'egg',
	'eject' = 'eject',
	'elderly_woman' = 'elderly_woman',
	'elderly' = 'elderly',
	'electric_bike' = 'electric_bike',
	'electric_bolt' = 'electric_bolt',
	'electric_car' = 'electric_car',
	'electric_meter' = 'electric_meter',
	'electric_moped' = 'electric_moped',
	'electric_rickshaw' = 'electric_rickshaw',
	'electric_scooter' = 'electric_scooter',
	'electrical_services' = 'electrical_services',
	'elevator' = 'elevator',
	'email' = 'email',
	'emergency_recording' = 'emergency_recording',
	'emergency_share' = 'emergency_share',
	'emergency' = 'emergency',
	'emoji_emotions' = 'emoji_emotions',
	'emoji_events' = 'emoji_events',
	'emoji_food_beverage' = 'emoji_food_beverage',
	'emoji_nature' = 'emoji_nature',
	'emoji_objects' = 'emoji_objects',
	'emoji_people' = 'emoji_people',
	'emoji_symbols' = 'emoji_symbols',
	'emoji_transportation' = 'emoji_transportation',
	'energy_savings_leaf' = 'energy_savings_leaf',
	'engineering' = 'engineering',
	'enhanced_encryption' = 'enhanced_encryption',
	'equalizer' = 'equalizer',
	'error_outline' = 'error_outline',
	'error' = 'error',
	'escalator_warning' = 'escalator_warning',
	'escalator' = 'escalator',
	'euro_symbol' = 'euro_symbol',
	'euro' = 'euro',
	'ev_station' = 'ev_station',
	'event_available' = 'event_available',
	'event_busy' = 'event_busy',
	'event_note' = 'event_note',
	'event_repeat' = 'event_repeat',
	'event_seat' = 'event_seat',
	'event' = 'event',
	'exit_to_app' = 'exit_to_app',
	'expand_circle_down' = 'expand_circle_down',
	'expand_less' = 'expand_less',
	'expand_more' = 'expand_more',
	'expand' = 'expand',
	'explicit' = 'explicit',
	'explore_off' = 'explore_off',
	'explore' = 'explore',
	'exposure_neg_1' = 'exposure_neg_1',
	'exposure_neg_2' = 'exposure_neg_2',
	'exposure_plus_1' = 'exposure_plus_1',
	'exposure_plus_2' = 'exposure_plus_2',
	'exposure_zero' = 'exposure_zero',
	'exposure' = 'exposure',
	'extension_off' = 'extension_off',
	'extension' = 'extension',
	'face_2' = 'face_2',
	'face_3' = 'face_3',
	'face_4' = 'face_4',
	'face_5' = 'face_5',
	'face_6' = 'face_6',
	'face_retouching_natural' = 'face_retouching_natural',
	'face_retouching_off' = 'face_retouching_off',
	'face_unlock' = 'face_unlock',
	'face' = 'face',
	'fact_check' = 'fact_check',
	'factory' = 'factory',
	'family_restroom' = 'family_restroom',
	'fast_forward' = 'fast_forward',
	'fast_rewind' = 'fast_rewind',
	'fastfood' = 'fastfood',
	'favorite_border' = 'favorite_border',
	'favorite' = 'favorite',
	'fax' = 'fax',
	'featured_play_list' = 'featured_play_list',
	'featured_video' = 'featured_video',
	'feed' = 'feed',
	'feedback' = 'feedback',
	'female' = 'female',
	'fence' = 'fence',
	'festival' = 'festival',
	'fiber_dvr' = 'fiber_dvr',
	'fiber_manual_record' = 'fiber_manual_record',
	'fiber_new' = 'fiber_new',
	'fiber_pin' = 'fiber_pin',
	'fiber_smart_record' = 'fiber_smart_record',
	'file_copy' = 'file_copy',
	'file_download_done' = 'file_download_done',
	'file_download_off' = 'file_download_off',
	'file_download' = 'file_download',
	'file_open' = 'file_open',
	'file_present' = 'file_present',
	'file_upload' = 'file_upload',
	'filter_1' = 'filter_1',
	'filter_2' = 'filter_2',
	'filter_3' = 'filter_3',
	'filter_4' = 'filter_4',
	'filter_5' = 'filter_5',
	'filter_6' = 'filter_6',
	'filter_7' = 'filter_7',
	'filter_8' = 'filter_8',
	'filter_9_plus' = 'filter_9_plus',
	'filter_9' = 'filter_9',
	'filter_alt_off' = 'filter_alt_off',
	'filter_alt' = 'filter_alt',
	'filter_b_and_w' = 'filter_b_and_w',
	'target' = 'target',
	'filter_drama' = 'filter_drama',
	'filter_frames' = 'filter_frames',
	'filter_hdr' = 'filter_hdr',
	'filter_list_off' = 'filter_list_off',
	'filter_list' = 'filter_list',
	'filter_none' = 'filter_none',
	'filter_tilt_shift' = 'filter_tilt_shift',
	'filter_vintage' = 'filter_vintage',
	'filter' = 'filter',
	'find_in_page' = 'find_in_page',
	'find_replace' = 'find_replace',
	'fingerprint' = 'fingerprint',
	'fire_extinguisher' = 'fire_extinguisher',
	'fire_hydrant_alt' = 'fire_hydrant_alt',
	'fire_truck' = 'fire_truck',
	'fireplace' = 'fireplace',
	'first_page' = 'first_page',
	'fit_screen' = 'fit_screen',
	'fitbit' = 'fitbit',
	'fitness_center' = 'fitness_center',
	'flag_circle' = 'flag_circle',
	'flag' = 'flag',
	'flaky' = 'flaky',
	'flare' = 'flare',
	'flash_auto' = 'flash_auto',
	'flash_off' = 'flash_off',
	'flash_on' = 'flash_on',
	'flashlight_off' = 'flashlight_off',
	'flashlight_on' = 'flashlight_on',
	'flatware' = 'flatware',
	'flight_class' = 'flight_class',
	'flight_land' = 'flight_land',
	'flight_takeoff' = 'flight_takeoff',
	'flight' = 'flight',
	'flip_camera_android' = 'flip_camera_android',
	'flip_camera_ios' = 'flip_camera_ios',
	'flip_to_back' = 'flip_to_back',
	'flip_to_front' = 'flip_to_front',
	'flip' = 'flip',
	'flood' = 'flood',
	'fluorescent' = 'fluorescent',
	'flutter_dash' = 'flutter_dash',
	'fmd_bad' = 'fmd_bad',
	'fmd_good' = 'fmd_good',
	'folder_copy' = 'folder_copy',
	'folder_delete' = 'folder_delete',
	'folder_off' = 'folder_off',
	'folder_open' = 'folder_open',
	'folder_shared' = 'folder_shared',
	'folder_special' = 'folder_special',
	'folder_zip' = 'folder_zip',
	'folder' = 'folder',
	'follow_the_signs' = 'follow_the_signs',
	'font_download_off' = 'font_download_off',
	'font_download' = 'font_download',
	'food_bank' = 'food_bank',
	'forest' = 'forest',
	'fork_left' = 'fork_left',
	'fork_right' = 'fork_right',
	'format_align_center' = 'format_align_center',
	'format_align_justify' = 'format_align_justify',
	'format_align_left' = 'format_align_left',
	'format_align_right' = 'format_align_right',
	'format_bold' = 'format_bold',
	'format_clear' = 'format_clear',
	'format_color_fill' = 'format_color_fill',
	'format_color_reset' = 'format_color_reset',
	'format_color_text' = 'format_color_text',
	'format_indent_decrease' = 'format_indent_decrease',
	'format_indent_increase' = 'format_indent_increase',
	'format_italic' = 'format_italic',
	'format_line_spacing' = 'format_line_spacing',
	'format_list_bulleted' = 'format_list_bulleted',
	'format_list_numbered_rtl' = 'format_list_numbered_rtl',
	'format_list_numbered' = 'format_list_numbered',
	'format_overline' = 'format_overline',
	'format_paint' = 'format_paint',
	'format_quote' = 'format_quote',
	'format_shapes' = 'format_shapes',
	'format_size' = 'format_size',
	'format_strikethrough' = 'format_strikethrough',
	'format_textdirection_l_to_r' = 'format_textdirection_l_to_r',
	'format_textdirection_r_to_l' = 'format_textdirection_r_to_l',
	'format_underlined' = 'format_underlined',
	'fort' = 'fort',
	'forum' = 'forum',
	'forward_10' = 'forward_10',
	'forward_30' = 'forward_30',
	'forward_5' = 'forward_5',
	'forward_to_inbox' = 'forward_to_inbox',
	'forward' = 'forward',
	'foundation' = 'foundation',
	'free_breakfast' = 'free_breakfast',
	'free_cancellation' = 'free_cancellation',
	'front_hand' = 'front_hand',
	'fullscreen_exit' = 'fullscreen_exit',
	'fullscreen' = 'fullscreen',
	'functions' = 'functions',
	'g_mobiledata' = 'g_mobiledata',
	'g_translate' = 'g_translate',
	'gamepad' = 'gamepad',
	'games' = 'games',
	'garage' = 'garage',
	'gas_meter' = 'gas_meter',
	'gavel' = 'gavel',
	'generating_tokens' = 'generating_tokens',
	'gesture' = 'gesture',
	'get_app' = 'get_app',
	'gif_box' = 'gif_box',
	'gif' = 'gif',
	'girl' = 'girl',
	'gite' = 'gite',
	'golf_course' = 'golf_course',
	'gpp_bad' = 'gpp_bad',
	'gpp_good' = 'gpp_good',
	'gpp_maybe' = 'gpp_maybe',
	'gps_fixed' = 'gps_fixed',
	'gps_not_fixed' = 'gps_not_fixed',
	'gps_off' = 'gps_off',
	'grade' = 'grade',
	'gradient' = 'gradient',
	'grading' = 'grading',
	'grain' = 'grain',
	'graphic_eq' = 'graphic_eq',
	'grass' = 'grass',
	'grid_3x3' = 'grid_3x3',
	'grid_4x4' = 'grid_4x4',
	'grid_goldenratio' = 'grid_goldenratio',
	'grid_off' = 'grid_off',
	'grid_on' = 'grid_on',
	'grid_view' = 'grid_view',
	'group_add' = 'group_add',
	'group_off' = 'group_off',
	'group_remove' = 'group_remove',
	'group_work' = 'group_work',
	'group' = 'group',
	'groups_2' = 'groups_2',
	'groups_3' = 'groups_3',
	'groups' = 'groups',
	'h_mobiledata' = 'h_mobiledata',
	'h_plus_mobiledata' = 'h_plus_mobiledata',
	'hail' = 'hail',
	'handshake' = 'handshake',
	'handyman' = 'handyman',
	'hardware' = 'hardware',
	'hd' = 'hd',
	'hdr_auto_select' = 'hdr_auto_select',
	'hdr_auto' = 'hdr_auto',
	'hdr_enhanced_select' = 'hdr_enhanced_select',
	'hdr_off_select' = 'hdr_off_select',
	'hdr_off' = 'hdr_off',
	'hdr_on_select' = 'hdr_on_select',
	'hdr_on' = 'hdr_on',
	'hdr_plus' = 'hdr_plus',
	'hdr_strong' = 'hdr_strong',
	'hdr_weak' = 'hdr_weak',
	'headphones_battery' = 'headphones_battery',
	'headphones' = 'headphones',
	'headset_mic' = 'headset_mic',
	'headset_off' = 'headset_off',
	'headset' = 'headset',
	'healing' = 'healing',
	'health_and_safety' = 'health_and_safety',
	'hearing_disabled' = 'hearing_disabled',
	'hearing' = 'hearing',
	'heart_broken' = 'heart_broken',
	'heat_pump' = 'heat_pump',
	'height' = 'height',
	'help_center' = 'help_center',
	'help_outline' = 'help_outline',
	'help' = 'help',
	'hevc' = 'hevc',
	'hexagon' = 'hexagon',
	'hide_image' = 'hide_image',
	'hide_source' = 'hide_source',
	'high_quality' = 'high_quality',
	'highlight_alt' = 'highlight_alt',
	'highlight_off' = 'highlight_off',
	'highlight' = 'highlight',
	'hiking' = 'hiking',
	'history_edu' = 'history_edu',
	'history_toggle_off' = 'history_toggle_off',
	'history' = 'history',
	'hive' = 'hive',
	'hls_off' = 'hls_off',
	'hls' = 'hls',
	'holiday_village' = 'holiday_village',
	'home_max' = 'home_max',
	'home_mini' = 'home_mini',
	'home_repair_service' = 'home_repair_service',
	'home_work' = 'home_work',
	'home' = 'home',
	'horizontal_distribute' = 'horizontal_distribute',
	'horizontal_rule' = 'horizontal_rule',
	'horizontal_split' = 'horizontal_split',
	'hot_tub' = 'hot_tub',
	'hotel_class' = 'hotel_class',
	'hotel' = 'hotel',
	'hourglass_bottom' = 'hourglass_bottom',
	'hourglass_disabled' = 'hourglass_disabled',
	'hourglass_empty' = 'hourglass_empty',
	'hourglass_full' = 'hourglass_full',
	'hourglass_top' = 'hourglass_top',
	'house_siding' = 'house_siding',
	'house' = 'house',
	'houseboat' = 'houseboat',
	'how_to_reg' = 'how_to_reg',
	'how_to_vote' = 'how_to_vote',
	'html' = 'html',
	'http' = 'http',
	'https' = 'https',
	'hub' = 'hub',
	'hvac' = 'hvac',
	'ice_skating' = 'ice_skating',
	'icecream' = 'icecream',
	'image_aspect_ratio' = 'image_aspect_ratio',
	'image_not_supported' = 'image_not_supported',
	'image_search' = 'image_search',
	'image' = 'image',
	'imagesearch_roller' = 'imagesearch_roller',
	'import_contacts' = 'import_contacts',
	'import_export' = 'import_export',
	'important_devices' = 'important_devices',
	'inbox' = 'inbox',
	'incomplete_circle' = 'incomplete_circle',
	'indeterminate_check_box' = 'indeterminate_check_box',
	'info' = 'info',
	'input' = 'input',
	'insert_chart_outlined' = 'insert_chart_outlined',
	'insert_chart' = 'insert_chart',
	'insert_comment' = 'insert_comment',
	'insert_drive_file' = 'insert_drive_file',
	'insert_emoticon' = 'insert_emoticon',
	'insert_invitation' = 'insert_invitation',
	'insert_link' = 'insert_link',
	'insert_page_break' = 'insert_page_break',
	'insert_photo' = 'insert_photo',
	'insights' = 'insights',
	'install_desktop' = 'install_desktop',
	'install_mobile' = 'install_mobile',
	'integration_instructions' = 'integration_instructions',
	'interests' = 'interests',
	'interpreter_mode' = 'interpreter_mode',
	'inventory_2' = 'inventory_2',
	'inventory' = 'inventory',
	'invert_colors_off' = 'invert_colors_off',
	'invert_colors' = 'invert_colors',
	'ios_share' = 'ios_share',
	'iron' = 'iron',
	'iso' = 'iso',
	'javascript' = 'javascript',
	'join_full' = 'join_full',
	'join_inner' = 'join_inner',
	'join_left' = 'join_left',
	'join_right' = 'join_right',
	'kayaking' = 'kayaking',
	'kebab_dining' = 'kebab_dining',
	'key_off' = 'key_off',
	'key' = 'key',
	'keyboard_alt' = 'keyboard_alt',
	'keyboard_arrow_down' = 'keyboard_arrow_down',
	'keyboard_arrow_left' = 'keyboard_arrow_left',
	'keyboard_arrow_right' = 'keyboard_arrow_right',
	'keyboard_arrow_up' = 'keyboard_arrow_up',
	'keyboard_backspace' = 'keyboard_backspace',
	'keyboard_capslock' = 'keyboard_capslock',
	'keyboard_command_key' = 'keyboard_command_key',
	'keyboard_control_key' = 'keyboard_control_key',
	'keyboard_double_arrow_down' = 'keyboard_double_arrow_down',
	'keyboard_double_arrow_left' = 'keyboard_double_arrow_left',
	'keyboard_double_arrow_right' = 'keyboard_double_arrow_right',
	'keyboard_double_arrow_up' = 'keyboard_double_arrow_up',
	'keyboard_hide' = 'keyboard_hide',
	'keyboard_option_key' = 'keyboard_option_key',
	'keyboard_return' = 'keyboard_return',
	'keyboard_tab' = 'keyboard_tab',
	'keyboard_voice' = 'keyboard_voice',
	'keyboard' = 'keyboard',
	'king_bed' = 'king_bed',
	'kitchen' = 'kitchen',
	'kitesurfing' = 'kitesurfing',
	'label_important' = 'label_important',
	'label_off' = 'label_off',
	'label' = 'label',
	'lan' = 'lan',
	'landscape' = 'landscape',
	'landslide' = 'landslide',
	'language' = 'language',
	'laptop_chromebook' = 'laptop_chromebook',
	'laptop_mac' = 'laptop_mac',
	'laptop_windows' = 'laptop_windows',
	'laptop' = 'laptop',
	'last_page' = 'last_page',
	'launch' = 'launch',
	'layers_clear' = 'layers_clear',
	'layers' = 'layers',
	'leaderboard' = 'leaderboard',
	'leak_add' = 'leak_add',
	'leak_remove' = 'leak_remove',
	'legend_toggle' = 'legend_toggle',
	'lens_blur' = 'lens_blur',
	'lens' = 'lens',
	'library_add_check' = 'library_add_check',
	'library_add' = 'library_add',
	'library_books' = 'library_books',
	'library_music' = 'library_music',
	'light_mode' = 'light_mode',
	'light' = 'light',
	'lightbulb_circle' = 'lightbulb_circle',
	'lightbulb' = 'lightbulb',
	'line_axis' = 'line_axis',
	'line_style' = 'line_style',
	'line_weight' = 'line_weight',
	'linear_scale' = 'linear_scale',
	'link_off' = 'link_off',
	'link' = 'link',
	'linked_camera' = 'linked_camera',
	'liquor' = 'liquor',
	'list_alt' = 'list_alt',
	'list' = 'list',
	'live_help' = 'live_help',
	'live_tv' = 'live_tv',
	'living' = 'living',
	'local_activity' = 'local_activity',
	'local_airport' = 'local_airport',
	'local_atm' = 'local_atm',
	'local_bar' = 'local_bar',
	'local_cafe' = 'local_cafe',
	'local_car_wash' = 'local_car_wash',
	'local_convenience_store' = 'local_convenience_store',
	'local_dining' = 'local_dining',
	'local_drink' = 'local_drink',
	'local_fire_department' = 'local_fire_department',
	'local_florist' = 'local_florist',
	'local_gas_station' = 'local_gas_station',
	'local_grocery_store' = 'local_grocery_store',
	'local_hospital' = 'local_hospital',
	'local_hotel' = 'local_hotel',
	'local_laundry_service' = 'local_laundry_service',
	'local_library' = 'local_library',
	'local_mall' = 'local_mall',
	'local_movies' = 'local_movies',
	'local_offer' = 'local_offer',
	'local_parking' = 'local_parking',
	'local_pharmacy' = 'local_pharmacy',
	'local_phone' = 'local_phone',
	'local_pizza' = 'local_pizza',
	'local_play' = 'local_play',
	'local_police' = 'local_police',
	'local_post_office' = 'local_post_office',
	'local_printshop' = 'local_printshop',
	'local_see' = 'local_see',
	'local_shipping' = 'local_shipping',
	'local_taxi' = 'local_taxi',
	'location_city' = 'location_city',
	'location_disabled' = 'location_disabled',
	'location_off' = 'location_off',
	'location_on' = 'location_on',
	'location_searching' = 'location_searching',
	'lock_clock' = 'lock_clock',
	'lock_open' = 'lock_open',
	'lock_person' = 'lock_person',
	'lock_reset' = 'lock_reset',
	'lock' = 'lock',
	'login' = 'login',
	'logo_dev' = 'logo_dev',
	'logout' = 'logout',
	'looks_3' = 'looks_3',
	'looks_4' = 'looks_4',
	'looks_5' = 'looks_5',
	'looks_6' = 'looks_6',
	'looks_one' = 'looks_one',
	'looks_two' = 'looks_two',
	'looks' = 'looks',
	'loop' = 'loop',
	'loupe' = 'loupe',
	'low_priority' = 'low_priority',
	'loyalty' = 'loyalty',
	'lte_mobiledata' = 'lte_mobiledata',
	'lte_plus_mobiledata' = 'lte_plus_mobiledata',
	'luggage' = 'luggage',
	'lunch_dining' = 'lunch_dining',
	'lyrics' = 'lyrics',
	'macro_off' = 'macro_off',
	'mail_lock' = 'mail_lock',
	'mail_outline' = 'mail_outline',
	'mail' = 'mail',
	'male' = 'male',
	'man_2' = 'man_2',
	'man_3' = 'man_3',
	'man_4' = 'man_4',
	'man' = 'man',
	'manage_accounts' = 'manage_accounts',
	'manage_history' = 'manage_history',
	'manage_search' = 'manage_search',
	'map' = 'map',
	'maps_home_work' = 'maps_home_work',
	'maps_ugc' = 'maps_ugc',
	'margin' = 'margin',
	'mark_as_unread' = 'mark_as_unread',
	'mark_chat_read' = 'mark_chat_read',
	'mark_chat_unread' = 'mark_chat_unread',
	'mark_email_read' = 'mark_email_read',
	'mark_email_unread' = 'mark_email_unread',
	'mark_unread_chat_alt' = 'mark_unread_chat_alt',
	'markunread_mailbox' = 'markunread_mailbox',
	'markunread' = 'markunread',
	'masks' = 'masks',
	'maximize' = 'maximize',
	'media_bluetooth_off' = 'media_bluetooth_off',
	'media_bluetooth_on' = 'media_bluetooth_on',
	'mediation' = 'mediation',
	'medical_information' = 'medical_information',
	'medical_services' = 'medical_services',
	'medication_liquid' = 'medication_liquid',
	'medication' = 'medication',
	'meeting_room' = 'meeting_room',
	'memory' = 'memory',
	'menu_book' = 'menu_book',
	'menu_open' = 'menu_open',
	'menu' = 'menu',
	'merge_type' = 'merge_type',
	'merge' = 'merge',
	'message' = 'message',
	'mic_external_off' = 'mic_external_off',
	'mic_external_on' = 'mic_external_on',
	'mic_none' = 'mic_none',
	'mic_off' = 'mic_off',
	'mic' = 'mic',
	'microwave' = 'microwave',
	'military_tech' = 'military_tech',
	'minimize' = 'minimize',
	'minor_crash' = 'minor_crash',
	'miscellaneous_services' = 'miscellaneous_services',
	'missed_video_call' = 'missed_video_call',
	'mms' = 'mms',
	'mobile_friendly' = 'mobile_friendly',
	'mobile_off' = 'mobile_off',
	'mobile_screen_share' = 'mobile_screen_share',
	'mobiledata_off' = 'mobiledata_off',
	'mode_comment' = 'mode_comment',
	'mode_edit_outline' = 'mode_edit_outline',
	'mode_edit' = 'mode_edit',
	'mode_fan_off' = 'mode_fan_off',
	'mode_night' = 'mode_night',
	'mode_of_travel' = 'mode_of_travel',
	'mode_standby' = 'mode_standby',
	'mode' = 'mode',
	'model_training' = 'model_training',
	'monetization_on' = 'monetization_on',
	'money_off_csred' = 'money_off_csred',
	'money_off' = 'money_off',
	'money' = 'money',
	'monitor_heart' = 'monitor_heart',
	'monitor_weight' = 'monitor_weight',
	'monitor' = 'monitor',
	'monochrome_photos' = 'monochrome_photos',
	'mood_bad' = 'mood_bad',
	'mood' = 'mood',
	'moped' = 'moped',
	'more_horiz' = 'more_horiz',
	'more_time' = 'more_time',
	'more_vert' = 'more_vert',
	'more' = 'more',
	'mosque' = 'mosque',
	'motion_photos_auto' = 'motion_photos_auto',
	'motion_photos_off' = 'motion_photos_off',
	'motion_photos_on' = 'motion_photos_on',
	'motion_photos_pause' = 'motion_photos_pause',
	'motion_photos_paused' = 'motion_photos_paused',
	'mouse' = 'mouse',
	'move_down' = 'move_down',
	'move_to_inbox' = 'move_to_inbox',
	'move_up' = 'move_up',
	'movie_creation' = 'movie_creation',
	'movie_filter' = 'movie_filter',
	'movie' = 'movie',
	'moving' = 'moving',
	'mp' = 'mp',
	'multiline_chart' = 'multiline_chart',
	'multiple_stop' = 'multiple_stop',
	'museum' = 'museum',
	'music_note' = 'music_note',
	'music_off' = 'music_off',
	'music_video' = 'music_video',
	'my_location' = 'my_location',
	'nat' = 'nat',
	'nature_people' = 'nature_people',
	'nature' = 'nature',
	'navigate_before' = 'navigate_before',
	'navigate_next' = 'navigate_next',
	'navigation' = 'navigation',
	'near_me_disabled' = 'near_me_disabled',
	'near_me' = 'near_me',
	'nearby_error' = 'nearby_error',
	'nearby_off' = 'nearby_off',
	'nest_cam_wired_stand' = 'nest_cam_wired_stand',
	'network_cell' = 'network_cell',
	'network_check' = 'network_check',
	'network_locked' = 'network_locked',
	'network_ping' = 'network_ping',
	'network_wifi_1_bar' = 'network_wifi_1_bar',
	'network_wifi_2_bar' = 'network_wifi_2_bar',
	'network_wifi_3_bar' = 'network_wifi_3_bar',
	'network_wifi' = 'network_wifi',
	'new_label' = 'new_label',
	'new_releases' = 'new_releases',
	'newspaper' = 'newspaper',
	'next_plan' = 'next_plan',
	'next_week' = 'next_week',
	'nfc' = 'nfc',
	'night_shelter' = 'night_shelter',
	'nightlife' = 'nightlife',
	'nightlight_round' = 'nightlight_round',
	'nightlight' = 'nightlight',
	'nights_stay' = 'nights_stay',
	'no_accounts' = 'no_accounts',
	'no_adult_content' = 'no_adult_content',
	'no_backpack' = 'no_backpack',
	'no_cell' = 'no_cell',
	'no_crash' = 'no_crash',
	'no_drinks' = 'no_drinks',
	'no_encryption_gmailerrorred' = 'no_encryption_gmailerrorred',
	'no_encryption' = 'no_encryption',
	'no_flash' = 'no_flash',
	'no_food' = 'no_food',
	'no_luggage' = 'no_luggage',
	'no_meals' = 'no_meals',
	'no_meeting_room' = 'no_meeting_room',
	'no_photography' = 'no_photography',
	'no_sim' = 'no_sim',
	'no_stroller' = 'no_stroller',
	'no_transfer' = 'no_transfer',
	'noise_aware' = 'noise_aware',
	'noise_control_off' = 'noise_control_off',
	'nordic_walking' = 'nordic_walking',
	'north_east' = 'north_east',
	'north_west' = 'north_west',
	'north' = 'north',
	'not_accessible' = 'not_accessible',
	'not_interested' = 'not_interested',
	'not_listed_location' = 'not_listed_location',
	'not_started' = 'not_started',
	'note_add' = 'note_add',
	'note_alt' = 'note_alt',
	'note' = 'note',
	'notes' = 'notes',
	'notification_add' = 'notification_add',
	'notification_important' = 'notification_important',
	'notifications_active' = 'notifications_active',
	'notifications_none' = 'notifications_none',
	'notifications_off' = 'notifications_off',
	'notifications_paused' = 'notifications_paused',
	'notifications' = 'notifications',
	'numbers' = 'numbers',
	'offline_bolt' = 'offline_bolt',
	'offline_pin' = 'offline_pin',
	'offline_share' = 'offline_share',
	'oil_barrel' = 'oil_barrel',
	'on_device_training' = 'on_device_training',
	'ondemand_video' = 'ondemand_video',
	'online_prediction' = 'online_prediction',
	'opacity' = 'opacity',
	'open_in_browser' = 'open_in_browser',
	'open_in_full' = 'open_in_full',
	'open_in_new_off' = 'open_in_new_off',
	'open_in_new' = 'open_in_new',
	'open_with' = 'open_with',
	'other_houses' = 'other_houses',
	'outbound' = 'outbound',
	'outbox' = 'outbox',
	'outdoor_grill' = 'outdoor_grill',
	'outlet' = 'outlet',
	'outlined_flag' = 'outlined_flag',
	'output' = 'output',
	'padding' = 'padding',
	'pages' = 'pages',
	'pageview' = 'pageview',
	'paid' = 'paid',
	'palette' = 'palette',
	'pan_tool_alt' = 'pan_tool_alt',
	'pan_tool' = 'pan_tool',
	'panorama_fish_eye' = 'panorama_fish_eye',
	'panorama_horizontal_select' = 'panorama_horizontal_select',
	'panorama_horizontal' = 'panorama_horizontal',
	'panorama_photosphere_select' = 'panorama_photosphere_select',
	'panorama_photosphere' = 'panorama_photosphere',
	'panorama_vertical_select' = 'panorama_vertical_select',
	'panorama_vertical' = 'panorama_vertical',
	'panorama_wide_angle_select' = 'panorama_wide_angle_select',
	'panorama_wide_angle' = 'panorama_wide_angle',
	'panorama' = 'panorama',
	'paragliding' = 'paragliding',
	'park' = 'park',
	'party_mode' = 'party_mode',
	'password' = 'password',
	'pattern' = 'pattern',
	'pause_circle_filled' = 'pause_circle_filled',
	'pause_circle_outline' = 'pause_circle_outline',
	'pause_circle' = 'pause_circle',
	'pause_presentation' = 'pause_presentation',
	'pause' = 'pause',
	'payment' = 'payment',
	'payments' = 'payments',
	'pedal_bike' = 'pedal_bike',
	'pending_actions' = 'pending_actions',
	'pending' = 'pending',
	'pentagon' = 'pentagon',
	'people_alt' = 'people_alt',
	'people_outline' = 'people_outline',
	'people' = 'people',
	'percent' = 'percent',
	'perm_camera_mic' = 'perm_camera_mic',
	'perm_contact_calendar' = 'perm_contact_calendar',
	'perm_data_setting' = 'perm_data_setting',
	'perm_device_information' = 'perm_device_information',
	'perm_identity' = 'perm_identity',
	'perm_media' = 'perm_media',
	'perm_phone_msg' = 'perm_phone_msg',
	'perm_scan_wifi' = 'perm_scan_wifi',
	'person_2' = 'person_2',
	'person_3' = 'person_3',
	'person_4' = 'person_4',
	'person_add_alt_1' = 'person_add_alt_1',
	'person_add_alt' = 'person_add_alt',
	'person_add_disabled' = 'person_add_disabled',
	'person_add' = 'person_add',
	'person_off' = 'person_off',
	'person_outline' = 'person_outline',
	'person_pin_circle' = 'person_pin_circle',
	'person_pin' = 'person_pin',
	'person_remove_alt_1' = 'person_remove_alt_1',
	'person_remove' = 'person_remove',
	'person_search' = 'person_search',
	'person' = 'person',
	'personal_injury' = 'personal_injury',
	'personal_video' = 'personal_video',
	'pest_control_rodent' = 'pest_control_rodent',
	'pest_control' = 'pest_control',
	'pets' = 'pets',
	'phishing' = 'phishing',
	'phone_android' = 'phone_android',
	'phone_bluetooth_speaker' = 'phone_bluetooth_speaker',
	'phone_callback' = 'phone_callback',
	'phone_disabled' = 'phone_disabled',
	'phone_enabled' = 'phone_enabled',
	'phone_forwarded' = 'phone_forwarded',
	'phone_in_talk' = 'phone_in_talk',
	'phone_iphone' = 'phone_iphone',
	'phone_locked' = 'phone_locked',
	'phone_missed' = 'phone_missed',
	'phone_paused' = 'phone_paused',
	'phone' = 'phone',
	'phonelink_erase' = 'phonelink_erase',
	'phonelink_lock' = 'phonelink_lock',
	'phonelink_off' = 'phonelink_off',
	'phonelink_ring' = 'phonelink_ring',
	'phonelink_setup' = 'phonelink_setup',
	'phonelink' = 'phonelink',
	'photo_album' = 'photo_album',
	'photo_camera_back' = 'photo_camera_back',
	'photo_camera_front' = 'photo_camera_front',
	'photo_camera' = 'photo_camera',
	'photo_filter' = 'photo_filter',
	'photo_library' = 'photo_library',
	'photo_size_select_actual' = 'photo_size_select_actual',
	'photo_size_select_large' = 'photo_size_select_large',
	'photo_size_select_small' = 'photo_size_select_small',
	'photo' = 'photo',
	'php' = 'php',
	'piano_off' = 'piano_off',
	'piano' = 'piano',
	'picture_as_pdf' = 'picture_as_pdf',
	'picture_in_picture_alt' = 'picture_in_picture_alt',
	'picture_in_picture' = 'picture_in_picture',
	'pie_chart_outline' = 'pie_chart_outline',
	'pie_chart' = 'pie_chart',
	'pin_drop' = 'pin_drop',
	'pin_end' = 'pin_end',
	'pin_invoke' = 'pin_invoke',
	'pin' = 'pin',
	'pinch' = 'pinch',
	'pivot_table_chart' = 'pivot_table_chart',
	'pix' = 'pix',
	'place' = 'place',
	'plagiarism' = 'plagiarism',
	'play_arrow' = 'play_arrow',
	'play_circle_filled' = 'play_circle_filled',
	'play_circle_outline' = 'play_circle_outline',
	'play_circle' = 'play_circle',
	'play_disabled' = 'play_disabled',
	'play_for_work' = 'play_for_work',
	'play_lesson' = 'play_lesson',
	'playlist_add_check_circle' = 'playlist_add_check_circle',
	'playlist_add_check' = 'playlist_add_check',
	'playlist_add_circle' = 'playlist_add_circle',
	'playlist_add' = 'playlist_add',
	'playlist_play' = 'playlist_play',
	'playlist_remove' = 'playlist_remove',
	'plumbing' = 'plumbing',
	'plus_one' = 'plus_one',
	'podcasts' = 'podcasts',
	'point_of_sale' = 'point_of_sale',
	'policy' = 'policy',
	'poll' = 'poll',
	'polyline' = 'polyline',
	'polymer' = 'polymer',
	'pool' = 'pool',
	'portable_wifi_off' = 'portable_wifi_off',
	'portrait' = 'portrait',
	'post_add' = 'post_add',
	'power_input' = 'power_input',
	'power_off' = 'power_off',
	'power_settings_new' = 'power_settings_new',
	'power' = 'power',
	'precision_manufacturing' = 'precision_manufacturing',
	'pregnant_woman' = 'pregnant_woman',
	'present_to_all' = 'present_to_all',
	'preview' = 'preview',
	'price_change' = 'price_change',
	'price_check' = 'price_check',
	'print_disabled' = 'print_disabled',
	'print' = 'print',
	'priority_high' = 'priority_high',
	'privacy_tip' = 'privacy_tip',
	'private_connectivity' = 'private_connectivity',
	'production_quantity_limits' = 'production_quantity_limits',
	'propane_tank' = 'propane_tank',
	'propane' = 'propane',
	'psychology_alt' = 'psychology_alt',
	'psychology' = 'psychology',
	'public_off' = 'public_off',
	'public' = 'public',
	'publish' = 'publish',
	'published_with_changes' = 'published_with_changes',
	'punch_clock' = 'punch_clock',
	'push_pin' = 'push_pin',
	'qr_code_2' = 'qr_code_2',
	'qr_code_scanner' = 'qr_code_scanner',
	'qr_code' = 'qr_code',
	'query_builder' = 'query_builder',
	'query_stats' = 'query_stats',
	'question_answer' = 'question_answer',
	'question_mark' = 'question_mark',
	'queue_music' = 'queue_music',
	'queue_play_next' = 'queue_play_next',
	'queue' = 'queue',
	'quickreply' = 'quickreply',
	'quiz' = 'quiz',
	'r_mobiledata' = 'r_mobiledata',
	'radar' = 'radar',
	'radio_button_checked' = 'radio_button_checked',
	'radio_button_unchecked' = 'radio_button_unchecked',
	'radio' = 'radio',
	'railway_alert' = 'railway_alert',
	'ramen_dining' = 'ramen_dining',
	'ramp_left' = 'ramp_left',
	'ramp_right' = 'ramp_right',
	'rate_review' = 'rate_review',
	'raw_off' = 'raw_off',
	'raw_on' = 'raw_on',
	'read_more' = 'read_more',
	'real_estate_agent' = 'real_estate_agent',
	'receipt_long' = 'receipt_long',
	'receipt' = 'receipt',
	'recent_actors' = 'recent_actors',
	'recommend' = 'recommend',
	'record_voice_over' = 'record_voice_over',
	'rectangle' = 'rectangle',
	'recycling' = 'recycling',
	'redeem' = 'redeem',
	'redo' = 'redo',
	'reduce_capacity' = 'reduce_capacity',
	'refresh' = 'refresh',
	'remember_me' = 'remember_me',
	'remove_circle_outline' = 'remove_circle_outline',
	'remove_circle' = 'remove_circle',
	'remove_done' = 'remove_done',
	'remove_from_queue' = 'remove_from_queue',
	'remove_moderator' = 'remove_moderator',
	'remove_red_eye' = 'remove_red_eye',
	'remove_road' = 'remove_road',
	'remove_shopping_cart' = 'remove_shopping_cart',
	'remove' = 'remove',
	'reorder' = 'reorder',
	'repartition' = 'repartition',
	'repeat_on' = 'repeat_on',
	'repeat_one_on' = 'repeat_one_on',
	'repeat_one' = 'repeat_one',
	'repeat' = 'repeat',
	'replay_10' = 'replay_10',
	'replay_30' = 'replay_30',
	'replay_5' = 'replay_5',
	'replay_circle_filled' = 'replay_circle_filled',
	'replay' = 'replay',
	'reply_all' = 'reply_all',
	'reply' = 'reply',
	'report_gmailerrorred' = 'report_gmailerrorred',
	'report_off' = 'report_off',
	'report_problem' = 'report_problem',
	'report' = 'report',
	'request_page' = 'request_page',
	'request_quote' = 'request_quote',
	'reset_tv' = 'reset_tv',
	'restart_alt' = 'restart_alt',
	'restaurant_menu' = 'restaurant_menu',
	'restaurant' = 'restaurant',
	'restore_from_trash' = 'restore_from_trash',
	'restore_page' = 'restore_page',
	'restore' = 'restore',
	'reviews' = 'reviews',
	'rice_bowl' = 'rice_bowl',
	'ring_volume' = 'ring_volume',
	'rocket_launch' = 'rocket_launch',
	'rocket' = 'rocket',
	'roller_shades_closed' = 'roller_shades_closed',
	'roller_shades' = 'roller_shades',
	'roller_skating' = 'roller_skating',
	'roofing' = 'roofing',
	'room_preferences' = 'room_preferences',
	'room_service' = 'room_service',
	'room' = 'room',
	'rotate_90_degrees_ccw' = 'rotate_90_degrees_ccw',
	'rotate_90_degrees_cw' = 'rotate_90_degrees_cw',
	'rotate_left' = 'rotate_left',
	'rotate_right' = 'rotate_right',
	'roundabout_left' = 'roundabout_left',
	'roundabout_right' = 'roundabout_right',
	'rounded_corner' = 'rounded_corner',
	'route' = 'route',
	'router' = 'router',
	'rowing' = 'rowing',
	'rss_feed' = 'rss_feed',
	'rsvp' = 'rsvp',
	'rtt' = 'rtt',
	'rule_folder' = 'rule_folder',
	'rule' = 'rule',
	'run_circle' = 'run_circle',
	'running_with_errors' = 'running_with_errors',
	'rv_hookup' = 'rv_hookup',
	'safety_check' = 'safety_check',
	'safety_divider' = 'safety_divider',
	'sailing' = 'sailing',
	'sanitizer' = 'sanitizer',
	'satellite_alt' = 'satellite_alt',
	'satellite' = 'satellite',
	'save_alt' = 'save_alt',
	'save_as' = 'save_as',
	'save' = 'save',
	'saved_search' = 'saved_search',
	'savings' = 'savings',
	'scale' = 'scale',
	'scanner' = 'scanner',
	'scatter_plot' = 'scatter_plot',
	'schedule_send' = 'schedule_send',
	'schedule' = 'schedule',
	'schema' = 'schema',
	'school' = 'school',
	'science' = 'science',
	'score' = 'score',
	'scoreboard' = 'scoreboard',
	'screen_lock_landscape' = 'screen_lock_landscape',
	'screen_lock_portrait' = 'screen_lock_portrait',
	'screen_lock_rotation' = 'screen_lock_rotation',
	'screen_rotation_alt' = 'screen_rotation_alt',
	'screen_rotation' = 'screen_rotation',
	'screen_search_desktop' = 'screen_search_desktop',
	'screen_share' = 'screen_share',
	'screenshot_monitor' = 'screenshot_monitor',
	'screenshot' = 'screenshot',
	'scuba_diving' = 'scuba_diving',
	'sd_card_alert' = 'sd_card_alert',
	'sd_card' = 'sd_card',
	'sd_storage' = 'sd_storage',
	'sd' = 'sd',
	'search_off' = 'search_off',
	'search' = 'search',
	'search_insights' = 'search_insights',
	'security_update_good' = 'security_update_good',
	'security_update_warning' = 'security_update_warning',
	'security_update' = 'security_update',
	'security' = 'security',
	'segment' = 'segment',
	'select_all' = 'select_all',
	'self_improvement' = 'self_improvement',
	'sell' = 'sell',
	'send_and_archive' = 'send_and_archive',
	'send_time_extension' = 'send_time_extension',
	'send_to_mobile' = 'send_to_mobile',
	'send' = 'send',
	'sensor_door' = 'sensor_door',
	'sensor_occupied' = 'sensor_occupied',
	'sensor_window' = 'sensor_window',
	'sensors_off' = 'sensors_off',
	'sensors' = 'sensors',
	'sentiment_dissatisfied' = 'sentiment_dissatisfied',
	'sentiment_neutral' = 'sentiment_neutral',
	'sentiment_satisfied_alt' = 'sentiment_satisfied_alt',
	'sentiment_satisfied' = 'sentiment_satisfied',
	'sentiment_very_dissatisfied' = 'sentiment_very_dissatisfied',
	'sentiment_very_satisfied' = 'sentiment_very_satisfied',
	'set_meal' = 'set_meal',
	'settings_accessibility' = 'settings_accessibility',
	'settings_applications' = 'settings_applications',
	'settings_backup_restore' = 'settings_backup_restore',
	'settings_bluetooth' = 'settings_bluetooth',
	'settings_brightness' = 'settings_brightness',
	'settings_cell' = 'settings_cell',
	'settings_ethernet' = 'settings_ethernet',
	'settings_input_antenna' = 'settings_input_antenna',
	'settings_input_component' = 'settings_input_component',
	'settings_input_composite' = 'settings_input_composite',
	'settings_input_hdmi' = 'settings_input_hdmi',
	'settings_input_svideo' = 'settings_input_svideo',
	'settings_overscan' = 'settings_overscan',
	'settings_phone' = 'settings_phone',
	'settings_power' = 'settings_power',
	'settings_remote' = 'settings_remote',
	'settings_suggest' = 'settings_suggest',
	'settings_system_daydream' = 'settings_system_daydream',
	'settings_voice' = 'settings_voice',
	'settings' = 'settings',
	'severe_cold' = 'severe_cold',
	'shape_line' = 'shape_line',
	'share_location' = 'share_location',
	'share' = 'share',
	'shield_moon' = 'shield_moon',
	'shield' = 'shield',
	'shop_2' = 'shop_2',
	'shop_two' = 'shop_two',
	'shop' = 'shop',
	'shopping_bag' = 'shopping_bag',
	'shopping_basket' = 'shopping_basket',
	'shopping_cart_checkout' = 'shopping_cart_checkout',
	'shopping_cart' = 'shopping_cart',
	'short_text' = 'short_text',
	'shortcut' = 'shortcut',
	'show_chart' = 'show_chart',
	'shower' = 'shower',
	'shuffle_on' = 'shuffle_on',
	'shuffle' = 'shuffle',
	'shutter_speed' = 'shutter_speed',
	'sick' = 'sick',
	'sign_language' = 'sign_language',
	'signal_cellular_0_bar' = 'signal_cellular_0_bar',
	'signal_cellular_4_bar' = 'signal_cellular_4_bar',
	'signal_cellular_alt_1_bar' = 'signal_cellular_alt_1_bar',
	'signal_cellular_alt_2_bar' = 'signal_cellular_alt_2_bar',
	'signal_cellular_alt' = 'signal_cellular_alt',
	'signal_cellular_connected_no_internet_0_bar' = 'signal_cellular_connected_no_internet_0_bar',
	'signal_cellular_connected_no_internet_4_bar' = 'signal_cellular_connected_no_internet_4_bar',
	'signal_cellular_no_sim' = 'signal_cellular_no_sim',
	'signal_cellular_nodata' = 'signal_cellular_nodata',
	'signal_cellular_null' = 'signal_cellular_null',
	'signal_cellular_off' = 'signal_cellular_off',
	'signal_wifi_0_bar' = 'signal_wifi_0_bar',
	'signal_wifi_4_bar_lock' = 'signal_wifi_4_bar_lock',
	'signal_wifi_4_bar' = 'signal_wifi_4_bar',
	'signal_wifi_bad' = 'signal_wifi_bad',
	'signal_wifi_connected_no_internet_4' = 'signal_wifi_connected_no_internet_4',
	'signal_wifi_off' = 'signal_wifi_off',
	'signal_wifi_statusbar_4_bar' = 'signal_wifi_statusbar_4_bar',
	'signal_wifi_statusbar_connected_no_internet_4' = 'signal_wifi_statusbar_connected_no_internet_4',
	'signal_wifi_statusbar_null' = 'signal_wifi_statusbar_null',
	'signpost' = 'signpost',
	'sim_card_alert' = 'sim_card_alert',
	'sim_card_download' = 'sim_card_download',
	'sim_card' = 'sim_card',
	'single_bed' = 'single_bed',
	'sip' = 'sip',
	'skateboarding' = 'skateboarding',
	'skip_next' = 'skip_next',
	'skip_previous' = 'skip_previous',
	'sledding' = 'sledding',
	'slideshow' = 'slideshow',
	'slow_motion_video' = 'slow_motion_video',
	'smart_button' = 'smart_button',
	'smart_display' = 'smart_display',
	'smart_screen' = 'smart_screen',
	'smart_toy' = 'smart_toy',
	'smartphone' = 'smartphone',
	'smoke_free' = 'smoke_free',
	'smoking_rooms' = 'smoking_rooms',
	'sms_failed' = 'sms_failed',
	'sms' = 'sms',
	'snippet_folder' = 'snippet_folder',
	'snooze' = 'snooze',
	'snowboarding' = 'snowboarding',
	'snowmobile' = 'snowmobile',
	'snowshoeing' = 'snowshoeing',
	'soap' = 'soap',
	'social_distance' = 'social_distance',
	'solar_power' = 'solar_power',
	'sort_by_alpha' = 'sort_by_alpha',
	'sort' = 'sort',
	'sos' = 'sos',
	'soup_kitchen' = 'soup_kitchen',
	'source' = 'source',
	'south_america' = 'south_america',
	'south_east' = 'south_east',
	'south_west' = 'south_west',
	'south' = 'south',
	'spa' = 'spa',
	'space_bar' = 'space_bar',
	'space_dashboard' = 'space_dashboard',
	'spatial_audio_off' = 'spatial_audio_off',
	'spatial_audio' = 'spatial_audio',
	'spatial_tracking' = 'spatial_tracking',
	'speaker_group' = 'speaker_group',
	'speaker_notes_off' = 'speaker_notes_off',
	'speaker_notes' = 'speaker_notes',
	'speaker_phone' = 'speaker_phone',
	'speaker' = 'speaker',
	'speed' = 'speed',
	'spellcheck' = 'spellcheck',
	'splitscreen' = 'splitscreen',
	'spoke' = 'spoke',
	'sports_bar' = 'sports_bar',
	'sports_baseball' = 'sports_baseball',
	'sports_basketball' = 'sports_basketball',
	'sports_cricket' = 'sports_cricket',
	'sports_esports' = 'sports_esports',
	'sports_football' = 'sports_football',
	'sports_golf' = 'sports_golf',
	'sports_gymnastics' = 'sports_gymnastics',
	'sports_handball' = 'sports_handball',
	'sports_hockey' = 'sports_hockey',
	'sports_kabaddi' = 'sports_kabaddi',
	'sports_martial_arts' = 'sports_martial_arts',
	'sports_mma' = 'sports_mma',
	'sports_motorsports' = 'sports_motorsports',
	'sports_rugby' = 'sports_rugby',
	'sports_score' = 'sports_score',
	'sports_soccer' = 'sports_soccer',
	'sports_tennis' = 'sports_tennis',
	'sports_volleyball' = 'sports_volleyball',
	'sports' = 'sports',
	'square_foot' = 'square_foot',
	'square' = 'square',
	'ssid_chart' = 'ssid_chart',
	'stacked_bar_chart' = 'stacked_bar_chart',
	'stacked_line_chart' = 'stacked_line_chart',
	'stadium' = 'stadium',
	'stairs' = 'stairs',
	'star_border_purple500' = 'star_border_purple500',
	'star_border' = 'star_border',
	'star_half' = 'star_half',
	'star_outline' = 'star_outline',
	'star_purple500' = 'star_purple500',
	'star_rate' = 'star_rate',
	'star' = 'star',
	'stars' = 'stars',
	'start' = 'start',
	'stay_current_landscape' = 'stay_current_landscape',
	'stay_current_portrait' = 'stay_current_portrait',
	'stay_primary_landscape' = 'stay_primary_landscape',
	'stay_primary_portrait' = 'stay_primary_portrait',
	'sticky_note_2' = 'sticky_note_2',
	'stop_circle' = 'stop_circle',
	'stop_screen_share' = 'stop_screen_share',
	'stop' = 'stop',
	'storage' = 'storage',
	'store_mall_directory' = 'store_mall_directory',
	'store' = 'store',
	'storefront' = 'storefront',
	'storm' = 'storm',
	'straight' = 'straight',
	'straighten' = 'straighten',
	'stream' = 'stream',
	'streetview' = 'streetview',
	'strikethrough_s' = 'strikethrough_s',
	'stroller' = 'stroller',
	'style' = 'style',
	'subdirectory_arrow_left' = 'subdirectory_arrow_left',
	'subdirectory_arrow_right' = 'subdirectory_arrow_right',
	'subject' = 'subject',
	'subscript' = 'subscript',
	'subscriptions' = 'subscriptions',
	'subtitles_off' = 'subtitles_off',
	'subtitles' = 'subtitles',
	'subway' = 'subway',
	'summarize' = 'summarize',
	'superscript' = 'superscript',
	'supervised_user_circle' = 'supervised_user_circle',
	'supervisor_account' = 'supervisor_account',
	'support_agent' = 'support_agent',
	'support' = 'support',
	'surfing' = 'surfing',
	'surround_sound' = 'surround_sound',
	'swap_calls' = 'swap_calls',
	'swap_horiz' = 'swap_horiz',
	'swap_horizontal_circle' = 'swap_horizontal_circle',
	'swap_vert' = 'swap_vert',
	'swap_vertical_circle' = 'swap_vertical_circle',
	'swipe_down_alt' = 'swipe_down_alt',
	'swipe_down' = 'swipe_down',
	'swipe_left_alt' = 'swipe_left_alt',
	'swipe_left' = 'swipe_left',
	'swipe_right_alt' = 'swipe_right_alt',
	'swipe_right' = 'swipe_right',
	'swipe_up_alt' = 'swipe_up_alt',
	'swipe_up' = 'swipe_up',
	'swipe_vertical' = 'swipe_vertical',
	'swipe' = 'swipe',
	'switch_access_shortcut_add' = 'switch_access_shortcut_add',
	'switch_access_shortcut' = 'switch_access_shortcut',
	'switch_account' = 'switch_account',
	'switch_camera' = 'switch_camera',
	'switch_left' = 'switch_left',
	'switch_right' = 'switch_right',
	'switch_video' = 'switch_video',
	'synagogue' = 'synagogue',
	'sync_alt' = 'sync_alt',
	'sync_disabled' = 'sync_disabled',
	'sync_lock' = 'sync_lock',
	'sync_problem' = 'sync_problem',
	'sync' = 'sync',
	'system_security_update_good' = 'system_security_update_good',
	'system_security_update_warning' = 'system_security_update_warning',
	'system_security_update' = 'system_security_update',
	'system_update_alt' = 'system_update_alt',
	'system_update' = 'system_update',
	'tab_unselected' = 'tab_unselected',
	'tab' = 'tab',
	'table_bar' = 'table_bar',
	'table_chart' = 'table_chart',
	'table_restaurant' = 'table_restaurant',
	'table_rows' = 'table_rows',
	'table_view' = 'table_view',
	'tablet_android' = 'tablet_android',
	'tablet_mac' = 'tablet_mac',
	'tablet' = 'tablet',
	'tag_faces' = 'tag_faces',
	'tag' = 'tag',
	'takeout_dining' = 'takeout_dining',
	'tap_and_play' = 'tap_and_play',
	'tapas' = 'tapas',
	'task_alt' = 'task_alt',
	'task' = 'task',
	'taxi_alert' = 'taxi_alert',
	'temple_buddhist' = 'temple_buddhist',
	'temple_hindu' = 'temple_hindu',
	'terminal' = 'terminal',
	'terrain' = 'terrain',
	'text_decrease' = 'text_decrease',
	'text_fields' = 'text_fields',
	'text_format' = 'text_format',
	'text_increase' = 'text_increase',
	'text_rotate_up' = 'text_rotate_up',
	'text_rotate_vertical' = 'text_rotate_vertical',
	'text_rotation_angledown' = 'text_rotation_angledown',
	'text_rotation_angleup' = 'text_rotation_angleup',
	'text_rotation_down' = 'text_rotation_down',
	'text_rotation_none' = 'text_rotation_none',
	'text_snippet' = 'text_snippet',
	'textsms' = 'textsms',
	'texture' = 'texture',
	'theater_comedy' = 'theater_comedy',
	'theaters' = 'theaters',
	'thermostat_auto' = 'thermostat_auto',
	'thermostat' = 'thermostat',
	'thumb_down_alt' = 'thumb_down_alt',
	'thumb_down_off_alt' = 'thumb_down_off_alt',
	'thumb_down' = 'thumb_down',
	'thumb_up_alt' = 'thumb_up_alt',
	'thumb_up_off_alt' = 'thumb_up_off_alt',
	'thumb_up' = 'thumb_up',
	'thumbs_up_down' = 'thumbs_up_down',
	'thunderstorm' = 'thunderstorm',
	'time_to_leave' = 'time_to_leave',
	'timelapse' = 'timelapse',
	'timeline' = 'timeline',
	'timer_10_select' = 'timer_10_select',
	'timer_10' = 'timer_10',
	'timer_3_select' = 'timer_3_select',
	'timer_3' = 'timer_3',
	'timer_off' = 'timer_off',
	'timer' = 'timer',
	'tips_and_updates' = 'tips_and_updates',
	'tire_repair' = 'tire_repair',
	'title' = 'title',
	'toc' = 'toc',
	'today' = 'today',
	'toggle_off' = 'toggle_off',
	'toggle_on' = 'toggle_on',
	'token' = 'token',
	'toll' = 'toll',
	'tonality' = 'tonality',
	'topic' = 'topic',
	'tornado' = 'tornado',
	'touch_app' = 'touch_app',
	'tour' = 'tour',
	'toys' = 'toys',
	'track_changes' = 'track_changes',
	'traffic' = 'traffic',
	'train' = 'train',
	'tram' = 'tram',
	'transcribe' = 'transcribe',
	'transfer_within_a_station' = 'transfer_within_a_station',
	'transform' = 'transform',
	'transgender' = 'transgender',
	'transit_enterexit' = 'transit_enterexit',
	'translate' = 'translate',
	'travel_explore' = 'travel_explore',
	'trending_down' = 'trending_down',
	'trending_flat' = 'trending_flat',
	'trending_up' = 'trending_up',
	'trip_origin' = 'trip_origin',
	'troubleshoot' = 'troubleshoot',
	'try' = 'try',
	'tsunami' = 'tsunami',
	'tty' = 'tty',
	'tune' = 'tune',
	'tungsten' = 'tungsten',
	'turn_left' = 'turn_left',
	'turn_right' = 'turn_right',
	'turn_sharp_left' = 'turn_sharp_left',
	'turn_sharp_right' = 'turn_sharp_right',
	'turn_slight_left' = 'turn_slight_left',
	'turn_slight_right' = 'turn_slight_right',
	'turned_in_not' = 'turned_in_not',
	'turned_in' = 'turned_in',
	'tv_off' = 'tv_off',
	'tv' = 'tv',
	'two_wheeler' = 'two_wheeler',
	'type_specimen' = 'type_specimen',
	'u_turn_left' = 'u_turn_left',
	'u_turn_right' = 'u_turn_right',
	'umbrella' = 'umbrella',
	'unarchive' = 'unarchive',
	'undo' = 'undo',
	'unfold_less_double' = 'unfold_less_double',
	'unfold_less' = 'unfold_less',
	'unfold_more_double' = 'unfold_more_double',
	'unfold_more' = 'unfold_more',
	'unpublished' = 'unpublished',
	'unsubscribe' = 'unsubscribe',
	'upcoming' = 'upcoming',
	'update_disabled' = 'update_disabled',
	'update' = 'update',
	'upgrade' = 'upgrade',
	'upload_file' = 'upload_file',
	'upload' = 'upload',
	'usb_off' = 'usb_off',
	'usb' = 'usb',
	'vaccines' = 'vaccines',
	'vape_free' = 'vape_free',
	'vaping_rooms' = 'vaping_rooms',
	'verified_user' = 'verified_user',
	'verified' = 'verified',
	'vertical_align_bottom' = 'vertical_align_bottom',
	'vertical_align_center' = 'vertical_align_center',
	'vertical_align_top' = 'vertical_align_top',
	'vertical_distribute' = 'vertical_distribute',
	'vertical_shades_closed' = 'vertical_shades_closed',
	'vertical_shades' = 'vertical_shades',
	'vertical_split' = 'vertical_split',
	'vibration' = 'vibration',
	'video_call' = 'video_call',
	'video_camera_back' = 'video_camera_back',
	'video_camera_front' = 'video_camera_front',
	'video_chat' = 'video_chat',
	'video_file' = 'video_file',
	'video_label' = 'video_label',
	'video_library' = 'video_library',
	'video_settings' = 'video_settings',
	'video_stable' = 'video_stable',
	'videocam_off' = 'videocam_off',
	'videocam' = 'videocam',
	'videogame_asset_off' = 'videogame_asset_off',
	'videogame_asset' = 'videogame_asset',
	'view_agenda' = 'view_agenda',
	'view_array' = 'view_array',
	'view_carousel' = 'view_carousel',
	'view_column' = 'view_column',
	'view_comfy_alt' = 'view_comfy_alt',
	'view_comfy' = 'view_comfy',
	'view_compact_alt' = 'view_compact_alt',
	'view_compact' = 'view_compact',
	'view_cozy' = 'view_cozy',
	'view_day' = 'view_day',
	'view_headline' = 'view_headline',
	'view_in_ar' = 'view_in_ar',
	'view_kanban' = 'view_kanban',
	'view_list' = 'view_list',
	'view_module' = 'view_module',
	'view_quilt' = 'view_quilt',
	'view_sidebar' = 'view_sidebar',
	'view_stream' = 'view_stream',
	'view_timeline' = 'view_timeline',
	'view_week' = 'view_week',
	'vignette' = 'vignette',
	'villa' = 'villa',
	'visibility_off' = 'visibility_off',
	'visibility' = 'visibility',
	'voice_chat' = 'voice_chat',
	'voice_over_off' = 'voice_over_off',
	'voicemail' = 'voicemail',
	'volcano' = 'volcano',
	'volume_down' = 'volume_down',
	'volume_mute' = 'volume_mute',
	'volume_off' = 'volume_off',
	'volume_up' = 'volume_up',
	'volunteer_activism' = 'volunteer_activism',
	'vpn_key_off' = 'vpn_key_off',
	'vpn_key' = 'vpn_key',
	'vpn_lock' = 'vpn_lock',
	'vrpano' = 'vrpano',
	'wallet' = 'wallet',
	'wallpaper' = 'wallpaper',
	'warehouse' = 'warehouse',
	'warning_amber' = 'warning_amber',
	'warning' = 'warning',
	'wash' = 'wash',
	'watch_later' = 'watch_later',
	'watch_off' = 'watch_off',
	'watch' = 'watch',
	'water_damage' = 'water_damage',
	'water_drop' = 'water_drop',
	'water' = 'water',
	'waterfall_chart' = 'waterfall_chart',
	'waves' = 'waves',
	'waving_hand' = 'waving_hand',
	'wb_auto' = 'wb_auto',
	'wb_cloudy' = 'wb_cloudy',
	'wb_incandescent' = 'wb_incandescent',
	'wb_iridescent' = 'wb_iridescent',
	'wb_shade' = 'wb_shade',
	'wb_sunny' = 'wb_sunny',
	'wb_twilight' = 'wb_twilight',
	'wc' = 'wc',
	'web_asset_off' = 'web_asset_off',
	'web_asset' = 'web_asset',
	'web_stories' = 'web_stories',
	'web' = 'web',
	'webhook' = 'webhook',
	'weekend' = 'weekend',
	'west' = 'west',
	'whatsapp' = 'whatsapp',
	'whatshot' = 'whatshot',
	'wheelchair_pickup' = 'wheelchair_pickup',
	'where_to_vote' = 'where_to_vote',
	'widgets' = 'widgets',
	'width_full' = 'width_full',
	'width_normal' = 'width_normal',
	'width_wide' = 'width_wide',
	'wifi_1_bar' = 'wifi_1_bar',
	'wifi_2_bar' = 'wifi_2_bar',
	'wifi_calling_3' = 'wifi_calling_3',
	'wifi_calling' = 'wifi_calling',
	'wifi_channel' = 'wifi_channel',
	'wifi_find' = 'wifi_find',
	'wifi_lock' = 'wifi_lock',
	'wifi_off' = 'wifi_off',
	'wifi_password' = 'wifi_password',
	'wifi_protected_setup' = 'wifi_protected_setup',
	'wifi_tethering_error' = 'wifi_tethering_error',
	'wifi_tethering_off' = 'wifi_tethering_off',
	'wifi_tethering' = 'wifi_tethering',
	'wifi' = 'wifi',
	'wind_power' = 'wind_power',
	'window' = 'window',
	'wine_bar' = 'wine_bar',
	'woman_2' = 'woman_2',
	'woman' = 'woman',
	'work_history' = 'work_history',
	'work_off' = 'work_off',
	'work_outline' = 'work_outline',
	'work' = 'work',
	'workspace_premium' = 'workspace_premium',
	'workspaces' = 'workspaces',
	'wrap_text' = 'wrap_text',
	'wrong_location' = 'wrong_location',
	'wysiwyg' = 'wysiwyg',
	'yard' = 'yard',
	'youtube_searched_for' = 'youtube_searched_for',
	'zoom_in_map' = 'zoom_in_map',
	'zoom_in' = 'zoom_in',
	'zoom_out_map' = 'zoom_out_map',
	'zoom_out' = 'zoom_out',
}
