import { DocumentNode, gql } from '@apollo/client/core';
import { Contact, PrismEventContact } from '@prism-frontend/typedefs/contact';
import { ContactPropsWithArtistsFragment } from '@prism-frontend/typedefs/graphql/CoreContactFragment';

// a graphql fragment to be used in other queries when a full contact model is needed
const eventContactPropsFragment: DocumentNode = gql`
	fragment EventContactProps on Contact {
		...ContactPropsWithArtistsFragment
		include_in_pdfs
		event_notes
	}
	${ContactPropsWithArtistsFragment}
`;

const baseEventContactArguments: string = `
	$event_id: Int!
	$contact_id: Int!
`;

const createOrUpdateEventContactArguments: string = `
	${baseEventContactArguments}
	$include_in_pdfs: Boolean!
	$event_notes: String!
`;

const baseEventContactParameters: string = `
	event_id: $event_id
	contact_id: $contact_id
`;

const createOrUpdateEventContactParameters: string = `
	${baseEventContactParameters}
	include_in_pdfs: $include_in_pdfs
	event_notes: $event_notes
`;

export const UpdateEventContactMutation: DocumentNode = gql`
	mutation updateEventContact(
		${createOrUpdateEventContactArguments}
	) {
		updateContactEventRelationship(
			${createOrUpdateEventContactParameters}
		) {
			...EventContactProps
		}
	}
	${eventContactPropsFragment}

	`;

export const AddEventContactMutation: DocumentNode = gql`
	mutation addEventContact(
		${createOrUpdateEventContactArguments}
	) {
		addContactToEvent(
			${createOrUpdateEventContactParameters}
		) {
			...EventContactProps
		}
	}
	${eventContactPropsFragment}
`;

export const RemoveEventContactMutation: DocumentNode = gql`
	mutation removeEventContact(
		${baseEventContactArguments}
	) {
		removeContactFromEvent(
			${baseEventContactParameters}
		) {
			...ContactPropsWithArtistsFragment
		}
	}
	${ContactPropsWithArtistsFragment}
`;

export interface UpdateEventContactResponse {
	updateContactEventRelationship: PrismEventContact;
}

export interface CreateEventContactResponse {
	addContactToEvent: PrismEventContact;
}

export interface RemoveEventContactResponse {
	removeContactFromEvent: Contact;
}

export interface EventContactMutationArgugments {
	event_id: number;
	contact_id: number;
	include_in_pdfs: boolean;
	event_notes: string;
}

export interface RemoveEventContactMutationArgugments {
	event_id: number;
	contact_id: number;
}
