import moment from 'moment';

// https://stackoverflow.com/a/69032498/1337683
const enForamtter: Intl.ListFormat = new Intl.ListFormat('en', {
	style: 'long',
	type: 'conjunction',
});

/**
 * given a list of strings, pluralize them using Intl.ListFormat
 *
 * @param list a list of values
 */
export function listToString(list: string[], formatter?: Intl.ListFormat): string {
	const formatterToUse: Intl.ListFormat = formatter || enForamtter;
	return formatterToUse.format(list);
}

export function dumbPluralize(word: string, count: number, includeNumber: boolean = false): string {
	const pluralization: string = count === 1 ? '' : 's';
	return `${includeNumber ? `${count} ` : ''}${word}${pluralization}`;
}

export function daysBeforeEvent(n: number): string {
	return `${dumbPluralize('day', n)} before event`;
}

function daysAfterEvent(n: number): string {
	return `${dumbPluralize('day', n)} after event`;
}

export function nDaysBeforeEvent(n: number): string {
	return `${n} ${daysBeforeEvent(n)}`;
}

export function nDaysAfterEvent(n: number): string {
	return `${n} ${daysAfterEvent(n)}`;
}

export function daysDiffText(dueDate: moment.Moment): string {
	const daysDifference: number = moment().diff(dueDate, 'days');
	const isOverdue: boolean = daysDifference > 0;
	const absDayDiff: number = Math.abs(daysDifference);
	const daysPluralized: string = dumbPluralize('day', absDayDiff);

	if (isOverdue) {
		return `${absDayDiff.toLocaleString()} ${daysPluralized} late`;
	}
	return `In ${absDayDiff.toLocaleString()} ${daysPluralized}`;
}

export function getSelectedText(numSelected: number, total?: number): string {
	const demonimatorString: string = total ? ` / ${total}` : '';
	return `${numSelected}${demonimatorString} selected`;
}
