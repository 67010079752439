import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AllSimpleFormFieldConfigs } from '@prism-frontend/components/simple-form/simple-form.typedefs';
import { StatusPickerOption } from '@prism-frontend/components/status-picker/status-picker.component';
import { mapDealStatusToStatusPickerOptions } from '@prism-frontend/entities/deal-tracker/ALL_DEAL_STATUS_OPTIONS';
import { VenueService } from '@prism-frontend/services/api/list-services/venue.service';
import { ApiService } from '@prism-frontend/services/legacy/api.service';
import { PermissionsService } from '@prism-frontend/services/legacy/api/permissions.service';
import { DealStatus } from '@prism-frontend/typedefs/enums/DealStatus';
import { EnvironmentNames } from '@prism-frontend/typedefs/environment.typedef';
import { ENV_FEATURE, ORG_FEATURE } from '@prism-frontend/typedefs/features.class';
import { Logo, LogoInterface } from '@prism-frontend/typedefs/logo';
import {
	Feature,
	OrgSettingsEndpointResponse,
	OrgSettingsEndpointResponseWithLogos,
	OrganizationSettings,
	OrganizationSettingsForm,
} from '@prism-frontend/typedefs/organization';
import { WithAutoUnsubscribeService } from '@prism-frontend/utils/static/auto-unsubscribe';
import { environment } from 'environments/environment';
import _, { indexOf } from 'lodash';
import { ReplaySubject, firstValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class OrgSettingsService extends WithAutoUnsubscribeService {
	public orgInfoPulled: ReplaySubject<OrgSettingsEndpointResponse> = new ReplaySubject<OrgSettingsEndpointResponse>(
		1
	);
	public loaded$: ReplaySubject<boolean> = new ReplaySubject(1);
	public logoList: Logo[] = [];
	private _settings: OrganizationSettings;
	// Organization Features
	// the actual enabled organization features for the current org
	private _orgFeatures: Set<Feature> = new Set();
	// the org features that should be enabled based on the evnironment config
	private _envConfigOrgFeatures: Set<Feature> = new Set();
	// the org features that should be disabled based on the environment config
	private _envConfigExcludedOrgFeatures: Set<ORG_FEATURE> = new Set();
	private _orgSettingsResponse: OrgSettingsEndpointResponse;
	// Environment Features
	// the actual enabled env features for the current environment
	private _envFeatures: Set<Feature> = new Set();
	// the env features that should be enabled based on the evnironment config
	private _envConfigEnvFeatures: Set<Feature> = new Set();
	// the env features that should be disabled based on the environment config
	private _envConfigExcludedEnvFeatures: Set<ENV_FEATURE> = new Set();

	public get defaultLogoId(): number | undefined {
		const defaultLogos: number[] = this.logoList
			.filter((logo: LogoInterface): number | boolean => {
				return logo.default;
			})
			.map((logo: LogoInterface): number => {
				return logo.id;
			});
		if (!defaultLogos.length) {
			return undefined;
		}
		return defaultLogos[0];
	}

	public get settings(): OrganizationSettings {
		return { ...this._settings };
	}

	public get orgFeatures(): Feature[] {
		const activeOrgFeatures: Feature[] = [...this._orgFeatures, ...this._envConfigOrgFeatures];
		if (!this._envConfigExcludedOrgFeatures.size) {
			return activeOrgFeatures;
		}
		return activeOrgFeatures.filter((feature: Feature): boolean => {
			return !this._envConfigExcludedOrgFeatures.has(feature.name as ORG_FEATURE);
		});
	}

	public get envFeatures(): Feature[] {
		const activeEnvFeatures: Feature[] = [...this._envFeatures, ...this._envConfigEnvFeatures];
		if (!this._envConfigExcludedEnvFeatures.size) {
			return activeEnvFeatures;
		}
		return activeEnvFeatures.filter((feature: Feature): boolean => {
			return !this._envConfigExcludedEnvFeatures.has(feature.name as ENV_FEATURE);
		});
	}

	public constructor(
		private apiService: ApiService,
		private permissionsService: PermissionsService,
		private matSnackBar: MatSnackBar,
		private venueService: VenueService
	) {
		super();
		this.loadEnvironmentConfigFeatures();
	}

	/**
	 * Loads any defined 'enviornment feature' flags from the environment.ts config
	 * both for organization and environment features flags.
	 * These features that should enable/disable will remain empty for other environments
	 * different than local
	 */
	private loadEnvironmentConfigFeatures(): void {
		if (environment.name !== EnvironmentNames.Local || !environment['features']) {
			return;
		}

		this.loadEnvironmentConfigOrgFeatures();
		this.loadEnvironmentConfigEnvFeatures();
	}

	/**
	 * Loads any defined 'enviornment feature' flags from the environment.ts config
	 * in order to explcitily enable/disable features for the given environment
	 * These features that should enable/disable will remain empty for other environments
	 * different than local
	 */
	private loadEnvironmentConfigEnvFeatures(): void {
		if (!environment['features'].environmentFeatures) {
			return;
		}

		this._envConfigEnvFeatures = new Set(
			_.map(environment['features'].environmentFeatures.featuresON, (feature: ENV_FEATURE): Feature => {
				return {
					name: feature,
					display_name: feature,
				};
			})
		);
		this._envConfigExcludedEnvFeatures = new Set(environment['features'].environmentFeatures.featuresOFF);
	}

	/**
	 * Loads any defined 'organization feature' flags from the environment.ts config
	 * in order to explcitily enable/disable features for the given environment
	 * These features that should enable/disable will remain empty for other environments
	 * different than local
	 * Notice these features will get enabled/disabled for any organization you are logged into
	 */
	private loadEnvironmentConfigOrgFeatures(): void {
		if (!environment['features'].organizationFeatures) {
			return;
		}

		this._envConfigOrgFeatures = new Set(
			_.map(environment['features'].organizationFeatures.featuresON, (feature: ORG_FEATURE): Feature => {
				return {
					name: feature,
					display_name: feature,
				};
			})
		);
		this._envConfigExcludedOrgFeatures = new Set(environment['features'].organizationFeatures.featuresOFF);
	}

	public async handleLoginServiceLoaded(): Promise<void> {
		await this.pullOrgInfo();
		await this.venueService.refreshListFromAPI();
	}

	public userIsAdmin(): boolean {
		return this.permissionsService.isAdmin;
	}

	public async pullOrgInfo(loadStatically: boolean = false): Promise<OrgSettingsEndpointResponseWithLogos> {
		const logos: Logo[] = await this.loadLogos();
		const data: OrgSettingsEndpointResponse = await firstValueFrom(
			this.apiService.get<string, OrgSettingsEndpointResponse>(this.apiService.ep.ORG_SETTINGS)
		);

		if (!loadStatically) {
			// do emits and all that extra stuff
			this.setSettings(data);
			this.loaded$.next(true);
			this.orgInfoPulled.next(data);
		}
		return {
			...data,
			logos,
		};
	}

	public async loadLogos(): Promise<Logo[]> {
		const logoList: Logo[] = await firstValueFrom(
			this.apiService.getS<string, Logo[]>(this.apiService.ep.LOGO_LIST)
		);
		this.logoList = logoList;
		return logoList;
	}

	public async saveOrgLogos(logos: Logo[]): Promise<Logo[]> {
		this.logoList = logos;
		const payload: { logos: LogoInterface[] } = {
			logos: this.logoList,
		};
		const result: Logo[] = await this.apiService.postP(this.apiService.ep.LOGO_UPDATE, payload);
		this.matSnackBar.open('Logo Saved', null, { duration: 1500 });
		this.logoList = result;
		return result;
	}

	public async saveTopLevelOrganizationSettings(settings: OrganizationSettingsForm): Promise<void> {
		const settingsResponse: OrgSettingsEndpointResponse = await this.saveSettings(settings);
		this.setSettings(settingsResponse);
		this.orgInfoPulled.next(settingsResponse);
	}

	public async saveSettings(settings: Partial<OrganizationSettings>): Promise<OrgSettingsEndpointResponse> {
		const settingsResponse: OrgSettingsEndpointResponse = await this.apiService.patchP<
			Partial<OrganizationSettings>,
			OrgSettingsEndpointResponse
		>(this.apiService.ep.ORG_SETTINGS, settings);
		this.setSettings(settingsResponse);
		return settingsResponse;
	}

	public async pullFields(): Promise<AllSimpleFormFieldConfigs<OrganizationSettingsForm>[]> {
		const fields: AllSimpleFormFieldConfigs<OrganizationSettingsForm>[] = await firstValueFrom(
			this.apiService.get<string, AllSimpleFormFieldConfigs<OrganizationSettingsForm>[]>(
				this.apiService.ep.ORG_LIST_ALL_SETTINGS
			)
		);

		return Promise.resolve(fields);
	}

	private setSettings(data: OrgSettingsEndpointResponse): void {
		this._orgSettingsResponse = data;
		this._settings = { ...data.organization_settings };
		this._orgFeatures = new Set(data.org_features);
		this._envFeatures = new Set(data.env_features);
	}

	/**
	 * return the configured deal statuses for the org, or all deal statuses if none are configured
	 * this should be called everywhere deal statuses are displayed in the app; right now this is
	 * both the deal status picker (shows in the deal tracker table) and the deal status filter component
	 *
	 * @returns the configured deal statuses for the org, or all deal statuses if none are configured
	 */
	public orgConfiguredDealStatuses(): StatusPickerOption<DealStatus>[] {
		const dealStatuses: StatusPickerOption<DealStatus>[] = mapDealStatusToStatusPickerOptions(
			this._orgSettingsResponse?.organization_settings.configured_deal_statuses || []
		);

		if (!dealStatuses.length) {
			return mapDealStatusToStatusPickerOptions(
				this._orgSettingsResponse.organization_settings.available_deal_statuses
			);
		}

		return dealStatuses;
	}

	/**
	 * return all possible deal status options. this should only ever be surfaced in the org settings
	 * page, where users can configure which deal statuses are available to them
	 *
	 * if you are looking to surface the deal statuses that are available to the org, use orgConfiguredDealStatuses
	 *
	 * @returns all deal statuses
	 */
	public allDealStatuses(): StatusPickerOption<DealStatus>[] {
		return mapDealStatusToStatusPickerOptions(
			this._orgSettingsResponse.organization_settings.available_deal_statuses
		);
	}

	public determineDealStatusIndex(dealStatus: DealStatus): number {
		return indexOf(this._orgSettingsResponse.organization_settings.available_deal_statuses, dealStatus);
	}

	/**
	 * return true if a given deal status is enabled for the org, false otherwise.
	 * account for an empty array of configured deal statuses, which means that all statuses are valid
	 *
	 * @param status the deal status in question
	 * @returns true if a given deal status is enabled, false otherwise
	 */
	public isStatusEnabledForOrg(status: DealStatus): boolean {
		const configuredOrgStatuses: DealStatus[] = this.orgConfiguredDealStatuses().map(
			(orgStatus: StatusPickerOption<DealStatus>): DealStatus => {
				return orgStatus.value;
			}
		);

		// if an org has zero statuses configured, this means all the statuses are enabled
		if (configuredOrgStatuses.length === 0) {
			return true;
		}

		return new Set(configuredOrgStatuses).has(status);
	}
}
