import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@prism-frontend/services/legacy/api.service';
import { PermissionsService } from '@prism-frontend/services/legacy/api/permissions.service';
import { LoginService } from '@prism-frontend/services/legacy/login.service';
import { SimpleAlert } from '@prism-frontend/typedefs/simple-alert';

@Component({
	providers: [ApiService],
	templateUrl: './login-page.component.html',
	styleUrls: ['./login-page.component.css'],
})
export class LoginPageComponent {
	public alert?: SimpleAlert;
	public reset_url: string;

	public constructor(
		public router: Router,
		public route: ActivatedRoute,
		public permissionsService: PermissionsService,
		public loginService: LoginService
	) {
		this.reset_url = '/password/reset';
	}

	public loginSubmit(username: string, password: string): void {
		this.loginService.login(username, password).subscribe(
			// login succeeded response
			(): void => {
				this.loginService.navToForwardPath();
			},
			// login failed response
			(message: string): void => {
				this.alert = {
					type: 'error',
					msg: message,
				};
			}
		);
	}
}
