import { Injectable } from '@angular/core';
import { ApiService, instantiateApiResponse } from '@prism-frontend/services/legacy/api.service';
import { StorageService } from '@prism-frontend/services/legacy/storage.service';
import { SpinnerService } from '@prism-frontend/services/utils/spinner.service';
import { InstantiatedAPIResponseAndValidationResults } from '@prism-frontend/utils/decorators/validate-children';
import { WithAutoUnsubscribeService } from '@prism-frontend/utils/static/auto-unsubscribe';
import { Debug, getDebug } from '@prism-frontend/utils/static/getDebug';
import { ClassConstructor, instanceToInstance, plainToClass } from 'class-transformer';
import _ from 'lodash';
import { BehaviorSubject, from } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
const debug: Debug = getDebug('abstract-list.service');

/**
 * Docs:
 * If you need to fetch a list of entities from a RESTful API endpoint, use this
 * service.
 * https://www.notion.so/prismfm/New-Data-Services-35888998d3604dccb9cfae5cd77d4185
 */
@Injectable({
	providedIn: 'root',
})
export abstract class AbstractListService<T> extends WithAutoUnsubscribeService {
	protected _all$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
	protected isCacheable: boolean = true;
	/**
	 * The difference between refreshedFromAPISincePageload and initialDataLoaded
	 * is that data can be loaded from LocalStorage too, not only the API.
	 * Loading from LocalStorage happens on page load but refreshing from the API is useful to get updates
	 *
	 * refreshedFromAPISincePageload and _initialDataLoaded are only set to true when loading a list of items,
	 * not when only a single item is loaded from the API
	 */
	protected refreshedFromAPISincePageload: boolean = false;
	// Useful to show the loading spinner on pages
	protected _initialDataLoaded: boolean = false;

	public constructor(
		protected apiService: ApiService,
		protected spinner: SpinnerService,
		protected storageService: StorageService
	) {
		super();
		this.loadListFromLocalStorage();
		this.addSubscription(
			this.all$.pipe(debounceTime(15 * 1000)).subscribe((values: T[]): void => {
				if (this.isCacheable) {
					this.saveListToLocalStorage(values);
				}
			})
		);
		this.addSubscription(
			this._all$.subscribe((values: T[]): void => {
				debug(this.endpointUrl, values.length);
			})
		);
	}

	public get all$(): BehaviorSubject<T[]> {
		const subject: BehaviorSubject<T[]> = new BehaviorSubject<T[]>(this._all$.value);

		from(this._all$).subscribe(subject);

		return subject;
	}

	/**
	 * Synchronous accessor
	 */
	public getAll(): T[] {
		return instanceToInstance(this._all$.value);
	}

	/**
	 * Synchronous accessor
	 */
	public byId(id: unknown): T | undefined {
		const item: T = this._all$.value.find((obj: T & { id: unknown }): boolean => {
			return obj.id === id;
		});
		return instanceToInstance(item);
	}

	/**
	 * Returns item by id as BehaviorSubject. Caller can subscribe for changes
	 */
	public byId$(id: unknown, options: { loadFromAPIIfMissing?: boolean } = {}): BehaviorSubject<T | undefined> {
		const currentValue: T | undefined = this.byId(id);
		const subject: BehaviorSubject<T | undefined> = new BehaviorSubject<T | undefined>(currentValue);

		from(this._all$)
			.pipe(
				map((items: T[]): T => {
					return instanceToInstance(
						items.find((item: T & { id: unknown }): boolean => {
							return item.id === id;
						})
					);
				})
			)
			.subscribe(subject);

		if (!currentValue && options.loadFromAPIIfMissing) {
			this.loadOneById(id as number);
		}

		return subject;
	}

	/**
	 * This method only calls the API only once per pageload
	 */
	public ensureListLoaded(): Promise<T[]> {
		if (this.refreshedFromAPISincePageload) {
			return Promise.resolve(this._all$.value);
		}
		return this.refreshListFromAPI();
	}

	/**
	 * Reload a list of items from the API
	 */
	public refreshListFromAPI(): Promise<T[]> {
		return this.apiService
			.getS(this.endpointUrl)
			.toPromise()
			.then((results: object[]): Promise<T[]> => {
				return instantiateApiResponse<T[]>(this.ClassConstructor, results).then(
					(response: InstantiatedAPIResponseAndValidationResults<T[]>): T[] => {
						this.refreshedFromAPISincePageload = true;
						this._initialDataLoaded = true;
						this._all$.next(response.data);
						return response.data;
					}
				);
			});
	}

	/**
	 * Load a single item from the API
	 */
	public async loadOneById(id: number, options?: { fromCacheIfAvailable?: boolean }): Promise<T> {
		if (options && options.fromCacheIfAvailable) {
			const cachedValue: T | undefined = this.byId(id);
			if (cachedValue) {
				return cachedValue;
			}
		}

		const item: object = await this.apiService.getS<void, object>([this.endpointUrlById, id]).toPromise();
		const response: InstantiatedAPIResponseAndValidationResults<T> = await instantiateApiResponse<T>(
			this.ClassConstructor,
			item
		);

		const allValues: T[] = this._all$.value;
		_.remove(allValues, (obj: T & { id: unknown }): boolean => {
			return obj.id === id;
		});

		this._all$.next([response.data, ...allValues]);
		return response.data;
	}

	public save(_data: T): Promise<T> {
		throw new Error('save method is not implemented yet for ' + this.endpointUrl);
	}

	public async deleteById(id: number): Promise<void> {
		await this.apiService
			.deleteS([this.endpointUrlById, id])
			.toPromise()
			.then((): void => {
				const allValues: T[] = this._all$.value;
				_.remove(allValues, (obj: T & { id: unknown }): boolean => {
					return obj.id === id;
				});
				this._all$.next([...allValues]);
			});
	}

	// Useful to show the loading spinner on pages
	public get initialDataLoaded(): boolean {
		return this._initialDataLoaded;
	}

	/**
	 * RESTful API endpoint for resource list, e.g. "tours"
	 */
	protected abstract get endpointUrl(): string;

	/**
	 * RESTful single resource URL. Automatically generated based on RESTful conventions:
	 * e.g. list: "/api/tours" -> single item: "/api/tours/%id"
	 */
	protected get endpointUrlById(): string {
		return this.endpointUrl + '/%i';
	}

	protected abstract get ClassConstructor(): unknown;

	private saveListToLocalStorage(values: T[]): void {
		const startTime: number = new Date().getTime();
		debug('saveListToLocalStorage', values.length, this.endpointUrl);
		if (values.length > 500) {
			// Avoid memory issues and blocking the UI, limit to 500 items
			// We can raise this number after we flatten the classes
			values = values.slice(values.length - 500, values.length);
		}

		const key: string = 'data-service-' + this.endpointUrl;
		try {
			this.storageService.setItem(key, JSON.stringify(values));

			// Report slow JSON serialization so we know about it and improve it
			// The average measured time for serializing 100-500 events is 1-40 ms
			const duration: number = new Date().getTime() - startTime;
			if (duration > 200) {
				// eslint-disable-next-line no-console
				console.warn('Slow JSON serialization detected');
			}
			if (duration > 1000) {
				// eslint-disable-next-line no-console
				console.error('Slow JSON serialization detected');
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error('Caught LocalStorage exception', e);
		}
	}

	private loadListFromLocalStorage(): void {
		try {
			const key: string = 'data-service-' + this.endpointUrl;
			const jsonString: string = this.storageService.getItem(key);
			const values: Object[] = JSON.parse(jsonString);
			if (!jsonString) {
				this._all$.next([]);
				return;
			}
			const objects: T[] = plainToClass(this.ClassConstructor as ClassConstructor<T>, values);
			debug(`loading ${objects.length} ${this.endpointUrl} from LocalStorage`);
			this._all$.next(objects);
			this._initialDataLoaded = true;
		} catch (e) {
			// this was causing tests to fail; as far as i know this error doesnt
			// ever get hit or we'd know about it in sentry
			// since this function gets called in the constructor, we need to be careful
			// what we do here, as the constructor was throwing an error and throwing off calendar tests
			// eslint-disable-next-line no-console
			console.warn(e);
			this._all$.next([]);
		}
	}
}
