import { DocumentNode, gql } from '@apollo/client/core';
import { ArtistForm } from '@prism-frontend/entities/artists/artist-form/artist-form-typedefs';
import { ArtistFragment } from '@prism-frontend/entities/artists/artist-graphql/artist-graphql-query';
import { Artist } from '@prism-frontend/entities/artists/artist-typedefs';
import { AgentUser } from '@prism-frontend/typedefs/AgentUser';
import { Talent } from '@prism-frontend/typedefs/talent';

const artistMutationArguments: string = `
	$talent: TalentInput!
	$manager_contact_id: Int
	$contract_signer_id: Int
	$contact_ids: [Int]
	$notes: String
	$agents: [TalentAgentInput]!
`;

const artistMutationArgumentMappings: string = `
	talent: $talent
	manager_contact_id: $manager_contact_id
	contract_signer_id: $contract_signer_id
	contact_ids: $contact_ids
	notes: $notes
	agents: $agents
`;

export const CreateArtistMutation: DocumentNode = gql`
	mutation CreateArtist(
		${artistMutationArguments}
	) {
		createTalentAgent(
			${artistMutationArgumentMappings}
		) {
			...ArtistFragment
		}
	}
	${ArtistFragment}
`;

export const UpdateArtistMutation: DocumentNode = gql`
	mutation UpdateArtist(
		$id: Int!
		${artistMutationArguments}
		$is_archived: Boolean
	) {
		updateTalentAgent(
			id: $id
			${artistMutationArgumentMappings}
			is_archived: $is_archived
		) {
			...ArtistFragment
		}
	}
	${ArtistFragment}
`;

/**
 * this represents the minimum amount of data needed to send a talent payload to the b/e mutation
 */
export type TalentInput = Pick<Talent, 'id' | 'name' | 'spotify_id' | 'images'>;

export type CreateArtistArguments = Pick<
	ArtistForm,
	'manager_contact_id' | 'contract_signer_id' | 'notes' | 'contact_ids'
> & {
	talent: TalentInput;
	agents: TalentAgentInput[];
};

export type TalentAgentInput = Pick<AgentUser, 'agent_user_id' | 'default_commission'>;

export type UpdateArtistArguments = CreateArtistArguments & Pick<Artist, 'id' | 'is_archived'>;

export interface CreateArtistMutationResponse {
	createTalentAgent: Artist;
}

export interface UpdateArtistMutationResponse {
	updateTalentAgent: Artist;
}
