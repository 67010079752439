import { DocumentNode, gql } from '@apollo/client/core';

export const AvailsQuery: DocumentNode = gql`
	query AvailsQuery($date_gte: String!, $date_lte: String!, $venue_ids: [Int]) {
		avails(date_gte: $date_gte, date_lte: $date_lte, venue_ids: $venue_ids) {
			stage_id
			stage_name
			avails {
				day
				hasConfirmedEvent
				holdCount
			}
		}
	}
`;

export interface AvailsByStage {
	stage_id: number;
	stage_name: string;
	avails: DayAvailability[];
}

export interface DayAvailability {
	day: string;
	hasConfirmedEvent: boolean;
	holdCount: number;
}

export interface AvailsQueryResponse {
	avails: AvailsByStage[];
}
