<div class="page-wrap-login">
	<div align="center" style="margin: 0 auto; padding-top: 45px; margin-bottom: 0px">
		<img alt="Prism Logo" style="width: 150px" [src]="'assets/images/prism-login.png' | cdnUrl" />
	</div>
	<div class="login-page">
		<div class="form">
			<form class="login-form" data-testid="js-login-form">
				<div *ngIf="alert">
					<div data-testid="js-login-alert" class="alert {{ alert.type }}">{{ alert.msg }}</div>
				</div>
				<input type="text" data-testid="js-username-field" placeholder="username" #username />
				<input type="password" data-testid="js-password-field" placeholder="password" #password />
				<button data-testid="js-login-button" (click)="loginSubmit(username.value, password.value)">
					login
				</button>
				<p class="message">
					<a href="{{ reset_url }}" target="_new" data-testid="js-reset-pw-link">Forgot/Reset Password</a>
				</p>
			</form>
		</div>
	</div>
	<!--
	<div align=center style="margin: 0 auto; width:400px; font-size:14px; color:#727272;">
		{{output}}
	</div>
--></div>
<script>
	$('.message a').click(function () {
		$('form').animate({ height: 'toggle', opacity: 'toggle' }, 'slow');
	});
</script>
