import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { EventPickerComponent } from '@prism-frontend/components/event-picker/event-picker.component';
import { EventStatusIconComponent } from '@prism-frontend/components/event-status-icon/event-status-icon.component';

@NgModule({
	imports: [CommonModule, EventStatusIconComponent, FormsModule, NgSelectModule],
	exports: [EventPickerComponent],
	declarations: [EventPickerComponent],
	providers: [],
})
export class EventPickerModule {}
