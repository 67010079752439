import { StatusPickerOption } from '@prism-frontend/components/status-picker/status-picker.component';
import { DealStatus } from '@prism-frontend/typedefs/enums/DealStatus';
import _ from 'lodash';

export function dealStatusToLabel(status: DealStatus): string {
	switch (status) {
		case DealStatus.NULL:
		case null:
			return 'None';
		default:
			return _.capitalize(status.replace(/_/g, ' '));
	}
}

export function mapDealStatusToStatusPickerOptions(dealStatuses: DealStatus[]): StatusPickerOption<DealStatus>[] {
	return dealStatuses.map((dealStatus: DealStatus): StatusPickerOption<DealStatus> => {
		return {
			value: dealStatus,
			label: dealStatusToLabel(dealStatus),
		};
	});
}
