import { VariableCost } from '@prism-frontend/entities/variable-costs/variable-cost-typedefs';
import { Bonus } from '@prism-frontend/typedefs/bonus';
import { Cost } from '@prism-frontend/typedefs/cost';
import { CostGroup } from '@prism-frontend/typedefs/costGroup';
import { CostGroupTypeMap } from '@prism-frontend/typedefs/CostGroupTypeOptions';
import { resolveLabel } from '@prism-frontend/typedefs/ems/ems-field-explainer-helpers';
import {
	EMS,
	EMSBonus,
	EMSCost,
	EMSCostCalcIndependentProps,
	EMSEventFee,
	EMSFixedCost,
	EMSPropsForBonus,
	EMSRentalRoomCost,
	EMSRentalRoomCosts,
	EMSRetroactiveBonus,
	EMSRollup,
	EMSTalentPayout,
	EMSVariableCost,
	TMS,
} from '@prism-frontend/typedefs/ems/ems-typedefs';
import {
	EMSFieldDefs,
	EMSFieldMeta,
	EMSFieldsMeta,
	EMSTopLevelFieldsMetaKeys,
} from '@prism-frontend/typedefs/ems/EMSFieldMeta';
import { AfterType } from '@prism-frontend/typedefs/enums/AfterType';
import { BonusTypes } from '@prism-frontend/typedefs/enums/BonusTypes';
import { CostCalc } from '@prism-frontend/typedefs/enums/calc';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';
import { Currency } from '@prism-frontend/typedefs/enums/currency';
import { DealTypes } from '@prism-frontend/typedefs/enums/deal-types';
import { EventFeeType } from '@prism-frontend/typedefs/enums/EventFeeType';
import { MathSummaryType } from '@prism-frontend/typedefs/enums/MathSummaryType';
import { VariableCostType } from '@prism-frontend/typedefs/enums/VariableCostType';
import { PrismEvent } from '@prism-frontend/typedefs/event';
import { FixedCostsOptions, FixedCostsOptionsArg } from '@prism-frontend/typedefs/event-method-api-params';
import { EventPropsForTalentGuarantee } from '@prism-frontend/typedefs/EventPropsForTalentGuarantee';
import { TalentData } from '@prism-frontend/typedefs/talentData';
import { fetchLegacyEMSParamsFromCostCalc2 } from '@prism-frontend/utils/static/fetchLegacyEMSParamsFromCostCalc2';
import { formatAmount } from '@prism-frontend/utils/static/format-amount';
import { verboseDebug } from '@prism-frontend/utils/static/getDebug';
import * as _ from 'lodash';

interface OneLinerChunks {
	// The dealOneLiner is built as follows:
	/** dealPrefix - e.g. [Plus|Versus] */
	dealPrefix: string;
	/** formattedAmount - e.g. <X>$|% */
	formattedAmount: string;
	/** bonusTypeDescriptor - e.g. per ticket|of Gross|of Net Revenue */
	bonusTypeDescriptor: string;
	/** afterString - e.g. after <Y>[% sold, tickets sold] */
	afterString: string;
	/** retroactiveBonus - e.g. [(switches to <X%> after <Y>[% sold, tickets sold])] */
	retroactiveBonus: string;
	/** capped - e.g. [(Capped)] */
	capped: string;
}

function sumEMSCostByProp(
	costs: EMSCost[],
	propName: 'quantity' | 'costAmount' | 'computedTotal' | 'overUnderReported' | 'difference'
): number {
	return _.sum(
		_.map(costs, (c: EMSCost): number => {
			return c[propName];
		})
	);
}

/**
 * For a given rental event, itemize the costs that count towards room fees. These include
 * the external reported fixed or variable cost that is reported.
 *
 * if a non-rental event is passed, logical defaults will be returned
 *
 * @param event an event for which the EMS is being generated
 * @param costCalc2 - the costCalc2 in question
 */
export function getRentalCostsTowardRoomFee(event: PrismEvent, costCalc2: CostCalc2): EMSRentalRoomCosts {
	// return default values for non-rental events, so we dont inflate EMS sizes for non-rental events.
	// also return default values for rental events with in-house ticket sales, since
	// roomfee is 0 in this case
	if (!event.outside_promoter) {
		return {
			costCalc: costCalc2,
			emsPath: `rentalData.rentalRoomCosts`,
			emsMetadataId: `rentalData.rentalRoomCosts`,
			fixedCosts: [],
			variableCosts: [],
		};
	}

	// we definitely do not want any internal CostCalc2s to dictate the list
	// of costs that go into rentalRoomCosts so we override any Internal*
	// costCalc2s here
	let costCalc2CostOverride: CostCalc2 = costCalc2;
	switch (costCalc2) {
		case CostCalc2.ExternalBudgeted:
			// intentionally left blank
			break;
		case CostCalc2.ExternalEstimated:
			// intentionally left blank
			break;
		case CostCalc2.ExternalReported:
			// intentionally left blank
			break;
		case CostCalc2.InternalActual:
			costCalc2CostOverride = CostCalc2.ExternalReported;
			break;
		case CostCalc2.InternalPotential:
			costCalc2CostOverride = CostCalc2.ExternalBudgeted;
			break;
		case CostCalc2.InternalEstimated:
			costCalc2CostOverride = CostCalc2.ExternalEstimated;
			break;
		default:
			throw new Error(`Unrecognized CostCalc2: ${costCalc2}`);
	}

	// fixed costs being counted towards room fees are External Reported
	let costCount: number = 0;
	const fixedCosts: EMSRentalRoomCost[] = _.flatten(
		fetchEMSFixedCosts(event.cost_groups, costCalc2CostOverride).map(
			// this is a section, like general - venue or marketing - advertising
			(fixedCost: EMSFixedCost): EMSRentalRoomCost[] => {
				const label: string = `${fixedCost.groupCategory} - ${
					CostGroupTypeMap[fixedCost.groupType] || fixedCost.groupType
				}`;
				return fixedCost.costs.map((cost: EMSCost): EMSRentalRoomCost => {
					const costIndex: number = costCount;
					costCount = costCount + 1;
					return {
						costCalc: costCalc2,
						emsPath: `rentalData.rentalRoomCosts.fixedCosts.${costIndex}`,
						emsMetadataId: `rentalData.rentalRoomCosts.fixedCosts.*`,
						id: cost.id,
						name: cost.name,
						label,
						value: cost.computedTotal,
						isReported: cost.isReported,
					};
				});
			}
		)
	);

	// same with variable costs: Reported external
	const variableCosts: EMSRentalRoomCost[] = fetchEMSVariableCostsFromEvent(event, costCalc2CostOverride, false).map(
		(variableCost: EMSVariableCost, variableCostIndex: number): EMSRentalRoomCost => {
			return {
				costCalc: costCalc2,
				emsPath: `rentalData.rentalRoomCosts.variableCosts.${variableCostIndex}`,
				emsMetadataId: `rentalData.rentalRoomCosts.variableCosts.*`,
				name: variableCost.displayName,
				id: variableCost.id,
				label: '',
				value: variableCost.total,
				isReported: true,
			};
		}
	);

	return {
		costCalc: costCalc2,
		emsPath: `rentalData.rentalRoomCosts`,
		emsMetadataId: `rentalData.rentalRoomCosts`,
		fixedCosts,
		variableCosts,
	};
}

/**
 * reduce the cost groups for a given cost calc/external combination and return an EMSFixexCost array
 *
 * @param the cost groups of the event or tour which the EMS is being computed
 * @param costCalc the current cost calc in use
 * @param external whether we're looking internal or external
 * @param computeForCoPro if we should compute co pro values or not
 * @param isForTourFixedCosts - A boolean value indicating if the fetched costs are for tour fixed costs or not, defaults to 'false'
 */
export function fetchEMSFixedCosts(
	costGroups: CostGroup[],
	costCalc2: CostCalc2,
	isForTourFixedCosts: boolean = false
): EMSFixedCost[] {
	const [costCalc, external]: [CostCalc, boolean] = fetchLegacyEMSParamsFromCostCalc2(costCalc2);
	const basePath: string = isForTourFixedCosts ? 'tourFixedCosts' : 'fixedCosts';
	return _.map(
		fixedCostGroups(costGroups, costCalc, external),
		(costGroup: CostGroup, costGroupIndex: number): EMSFixedCost => {
			let reportedTotal: number = 0;
			let reportedCostsOverUnder: number = 0;
			const costs: EMSCost[] = _.sortBy(costGroup.costs, 'order').map(
				(cost: Cost, costIndex: number): EMSCost => {
					const computedTotal: number = cost.totalByCostCalc(costCalc, external, false, false);
					const hasCoProOverriddenAmount: boolean = !_.isNil(cost.copro_overridden_amount);
					const coProOverriddenAmount: number = hasCoProOverriddenAmount
						? cost.copro_overridden_amount
						: computedTotal;

					if (cost.reported) {
						reportedTotal += computedTotal;
						reportedCostsOverUnder += cost.over_under_reported;
					}

					return {
						emsPath: `${basePath}.${costGroupIndex}.costs.${costIndex}`,
						emsMetadataId: `${basePath}.*.costs.*` as keyof EMSFieldDefs,
						id: cost.id,
						costCalc: costCalc2,
						name: cost.name,
						overUnderReported: cost.over_under_reported,
						quantity: cost.quantityByCostCalc(costCalc),
						costAmount: cost.costAmountByCostCalc(costCalc, external, false),
						total: cost.totalByCostCalc(costCalc, external, false, true),
						computedTotal,
						hiddenFromCoPro: cost.copro_cost_hidden,
						hasCoProOverriddenAmount,
						coProOverriddenAmount,
						difference: cost.differenceByCostCalc(costCalc),
						isReported: cost.reported,
					};
				}
			);
			return {
				id: costGroup.id,
				emsPath: `${basePath}.${costGroupIndex}`,
				emsMetadataId: `${basePath}.*` as keyof EMSFieldDefs,
				costCalc: costCalc2,
				groupCategory: costGroup.category,
				groupType: costGroup.type,
				costsOverUnderReportedTotal: sumEMSCostByProp(costs, 'overUnderReported'),
				reportedCostsOverUnderReportedTotal: reportedCostsOverUnder,
				costsAmount: sumEMSCostByProp(costs, 'costAmount'),
				costsComputedTotal: sumEMSCostByProp(costs, 'computedTotal'),
				reportedCostsComputedTotal: reportedTotal,
				costsDifference: sumEMSCostByProp(costs, 'difference'),
				hasSomeCostReported: _.some(costs, 'isReported'),
				costs,
			};
		}
	);
}

// Helper for fixed costs that returns the filtered set of cost groups
// to be used when summing up fixedCosts
export function fixedCostGroups(
	costGroups: CostGroup[],
	costCalc: CostCalc,
	external: boolean,
	optionsArg: FixedCostsOptionsArg = {}
): CostGroup[] {
	checkLegacyArgs(costCalc, external);
	const defaultOptions: FixedCostsOptions = {
		costGroups,
		category: false,
		computeForCoPro: false,
	};
	const options: FixedCostsOptions = _.defaults({}, optionsArg, defaultOptions);

	let filteredGroups: CostGroup[] = _.cloneDeep(options.costGroups);

	// filter by category
	filteredGroups = filteredGroups.filter((group: CostGroup): boolean => {
		if (!options.category) return true;
		return group.category === options.category;
	});

	return filteredGroups;
}

export function fetchEMSVariableCostsFromEvent(
	event: PrismEvent,
	costCalc2: CostCalc2,
	computeForCoPro: boolean
): EMSVariableCost[] {
	const [costCalc, external]: [CostCalc, boolean] = fetchLegacyEMSParamsFromCostCalc2(costCalc2);
	return _.sortBy(event.variableCosts(external), 'order').map(
		(variableCost: VariableCost, variableCostIndex: number): EMSVariableCost => {
			const flatVariableOptions: VariableCostType[] = [
				VariableCostType.FLAT_PER_ATTENDEE,
				VariableCostType.FLAT_PER_TICKET_ANY,
				VariableCostType.FLAT_PER_TICKET_TYPE,
			];

			const ticketTotal: number = variableCost.parseTicketTotalSold(
				costCalc,
				event.tickets,
				event.ticketsSold(costCalc)
			);
			const total: number = variableCost.total(event, costCalc, external, computeForCoPro);

			const hasCoProOverriddenAmount: boolean = !_.isNil(variableCost.copro_overridden_amount);
			const coProOverriddenAmount: number = hasCoProOverriddenAmount
				? variableCost.copro_overridden_amount
				: total;

			return {
				emsPath: `variableCosts.${variableCostIndex}`,
				emsMetadataId: `variableCosts.*`,
				id: variableCost.id,
				costCalc: costCalc2,
				displayName: variableCost.getDisplayName(event.tickets, event.currency),
				displayAmount:
					(+variableCost.amount).toFixed(4) +
					(flatVariableOptions.includes(<VariableCostType>variableCost.terms) ? '' : '%'),
				terms: variableCost.terms,
				amount: +variableCost.amount,
				name: variableCost.name,
				ticketTotal,
				total,
				hiddenFromCoPro: variableCost.copro_cost_hidden,
				hasCoProOverriddenAmount,
				coProOverriddenAmount,
			};
		}
	);
}

export const reduceFixedCosts: (fixedCosts: EMSFixedCost[]) => EMSFixedCost[] = (
	fixedCosts: EMSFixedCost[]
): EMSFixedCost[] => {
	const EmptyFixedCost: EMSFixedCost = {
		id: null,
		emsPath: undefined,
		emsMetadataId: undefined,
		costCalc: null,
		costs: [],
		costsAmount: 0,
		costsComputedTotal: 0,
		reportedCostsComputedTotal: 0,
		costsDifference: 0,
		costsOverUnderReportedTotal: 0,
		reportedCostsOverUnderReportedTotal: 0,
		groupCategory: null,
		groupType: null,
		hasSomeCostReported: false,
	};

	const addFixedCostToMemo: (memo: EMSFixedCost, cost: EMSFixedCost) => EMSFixedCost = (
		memo: EMSFixedCost,
		cost: EMSFixedCost
	): EMSFixedCost => {
		return {
			id: null,
			emsPath: undefined,
			emsMetadataId: undefined,
			costCalc: cost.costCalc,
			costs: memo.costs.concat(cost.costs),
			costsAmount: memo.costsAmount + cost.costsAmount,
			costsComputedTotal: memo.costsComputedTotal + cost.costsComputedTotal,
			reportedCostsComputedTotal: memo.reportedCostsComputedTotal + cost.reportedCostsComputedTotal,
			costsDifference: memo.costsDifference + cost.costsDifference,
			costsOverUnderReportedTotal: memo.costsOverUnderReportedTotal + cost.costsOverUnderReportedTotal,
			reportedCostsOverUnderReportedTotal:
				memo.reportedCostsOverUnderReportedTotal + cost.reportedCostsOverUnderReportedTotal,
			groupCategory: cost.groupCategory,
			groupType: cost.groupType,
			hasSomeCostReported: memo.hasSomeCostReported || cost.hasSomeCostReported,
		};
	};
	return _.chain(fixedCosts)
		.groupBy((fixedCost: EMSFixedCost): string => {
			return `${fixedCost.groupCategory}`;
		})
		.map((fixedCostGroup: EMSFixedCost[]): EMSFixedCost => {
			return _.reduce(fixedCostGroup, addFixedCostToMemo, EmptyFixedCost);
		})
		.value();
};

/**
 * Stub out this function for use elsewhere in our codebase. We need to map
 * bonuses to EMSBonuses in multiple places.
 *
 * @param partialEMS - a partial EMS used to compute values on bonuses
 * @param bonus - a bonus on a talent deal
 * @param talent - the talent deal for which the bonus is being computed
 * @param eventPropsForGuarantee - a subset of fields on the PrismEvent type that are required to compute
 * 	 a guarantee
 */
export function mapBonusToEMSBonus(
	costCalc: CostCalc,
	partialEMS: EMSPropsForBonus,
	bonus: Bonus,
	bonusIndex: number,
	talent: TalentData,
	talentIndex: number,
	eventPropsForGuarantee: EventPropsForTalentGuarantee
): EMSBonus {
	const royalty: number = talent.calculateRoyalty(partialEMS.nagbor);
	const payoutTotal: number = bonus.calculatePayout(
		costCalc,
		eventPropsForGuarantee,
		talent,
		partialEMS.netGross,
		partialEMS.convertedDocumentSplitPoint,
		partialEMS.ticketsSold,
		partialEMS.totalSellable,
		royalty,
		false
	);
	const payoutTotalWithNegative: number = bonus.calculatePayout(
		costCalc,
		eventPropsForGuarantee,
		talent,
		partialEMS.netGross,
		partialEMS.convertedDocumentSplitPoint,
		partialEMS.ticketsSold,
		partialEMS.totalSellable,
		royalty,
		true
	);
	return {
		emsPath: `talentPayouts.${talentIndex}.bonuses.${bonusIndex}`,
		emsMetadataId: `talentPayouts.*.bonuses.*`,
		costCalc: partialEMS.costCalc2,
		id: bonus.id,
		parentId: bonus.event_talent_id,
		// The dealOneLiner is set at the end of singleEMS using the
		// static dealOneLiner method. We must do it at the end,
		// after generating the rest of EMS because dealOneLiner
		// requires an EMS in order to function
		dealOneLiner: '',
		type: bonus.type,
		after: bonus.after,
		afterAmount: Number(bonus.after_amount),
		amount: Number(bonus.amount),
		isPercentageBonus: bonus.isPercentageBonus,
		currency: bonus.currency(partialEMS),
		payoutTotal,
		selectedRetroBonusId: bonus.getSelectedRetroBonusId(
			talent,
			partialEMS.netGross,
			partialEMS.convertedDocumentSplitPoint,
			partialEMS.ticketsSold,
			partialEMS.totalSellable,
			royalty
		),
		convertedPayoutTotal: payoutTotal / partialEMS.exchangeRate,
		payoutTotalWithNegative,
	};
}

/**
 * Given an EMS or TMS, take its list of eventFees and reduce them down to a
 * single EMSEventFee with name "Event Fees". Useful for summing up all fees on
 * a single event, or rolling up all fees across all events in a tour into a
 * single line item.
 * @param ems an EMS or TMS for which to summarize eventFees
 * @param name event fee name
 */
export function reduceEventFees(ems: EMS | TMS, name: string = 'Total Event Fees'): EMSEventFee {
	const summary: EMSEventFee = _.chain(ems.eventFees)
		.reduce(
			(memo: EMSEventFee, fee: EMSEventFee): EMSEventFee => {
				return {
					emsPath: undefined,
					emsMetadataId: undefined,
					costCalc: fee.costCalc,
					name,
					id: null,
					amount: memo.amount + fee.amount,
					value: memo.value + fee.value,
					type: `${memo.type},${fee.type}` as EventFeeType,
					displayName: `${memo.displayName},${fee.displayName}`,
				};
			},
			{
				name,
				id: null,
				amount: 0,
				value: 0,
				type: null,
				displayName: '',
				emsPath: undefined,
				emsMetadataId: undefined,
				costCalc: undefined,
			}
		)
		.value();
	// Because the fee amount is multiplied times ticket sales numbers before
	// arriving at the value, it does not make sense to simply sum up the eventFees
	// amount property. This will result in reporting larger per-ticket fees
	// than were charged, and make the resulting line item (value) look
	// comparatively low. Thus, amount is converted to an average here.
	summary.amount = summary.amount / ems.eventFees.length;
	return summary;
}

export function reduceBonuses(ems: EMS | TMS, fromPayout: EMSTalentPayout, talentPayoutIndex: number): [EMSBonus] | [] {
	if (!fromPayout.bonuses.length) {
		return [];
	}
	const theBonus: EMSBonus = _.chain(fromPayout.bonuses)
		.reduce(
			(memo: EMSBonus, bonus: EMSBonus, idx: number): EMSBonus => {
				const payoutTotal: number = memo.payoutTotal + bonus.payoutTotal;
				return {
					// the idx is intentionally ignored because we are explicitly
					// reducing multiple bonuses down to a list of 1 bonus in this
					// reduceBonuses method
					emsPath: `talentPayouts.${talentPayoutIndex}.bonuses.0`,
					emsMetadataId: bonus.emsMetadataId,
					costCalc: bonus.costCalc,
					id: null,
					parentId: fromPayout.id,
					type: bonus.type,
					payoutTotal,
					amount: memo.amount + bonus.amount,
					isPercentageBonus: bonus.isPercentageBonus,
					currency: bonus.currency,
					// should be the same across all bonuses
					after: bonus.after,
					// will vary across bonuses
					afterAmount: memo.afterAmount + bonus.afterAmount,
					// the value we put here is irrelevant. we overwrite the
					// final value at the bottom of the file
					dealOneLiner: `${memo.dealOneLiner}\n(${idx})${bonus.dealOneLiner}`,
					selectedRetroBonusId: memo.selectedRetroBonusId || bonus.selectedRetroBonusId,
					convertedPayoutTotal: payoutTotal / ems.exchangeRate,
					payoutTotalWithNegative: memo.payoutTotalWithNegative + bonus.payoutTotalWithNegative,
				};
			},
			{
				emsPath: undefined,
				emsMetadataId: undefined,
				costCalc: undefined,
				id: null,
				parentId: fromPayout.id,
				type: null,
				payoutTotal: 0,
				amount: 0,
				isPercentageBonus: true,
				currency: Currency.USD,
				after: null,
				afterAmount: 0,
				dealOneLiner: ``,
				selectedRetroBonusId: null,
				convertedPayoutTotal: 0,
				payoutTotalWithNegative: 0,
			}
		)
		.value();
	// amount and afterAmount needs to be an average
	theBonus.amount = theBonus.amount / fromPayout.bonuses.length;
	theBonus.afterAmount = theBonus.afterAmount / fromPayout.bonuses.length;
	theBonus.dealOneLiner = dealOneLiner(ems, fromPayout, theBonus);
	// console.log(fromPayout, fromPayout.bonuses, '=> reduced to =>', theBonus);
	return [theBonus];
}

export function dealOneLiner(
	ems:
		| Pick<EMS, 'type' | 'currency' | 'artistPayCurrency'>
		| Pick<TMS, 'type' | 'costCalc2' | 'currency' | 'artistPayCurrency'>,
	forPayout: EMSTalentPayout,
	forBonus: EMSBonus
): string {
	if (ems.type === MathSummaryType.TMS) {
		return tourDealOneLiner(<TMS>ems, forPayout, forBonus);
	}
	return eventDealOneLiner(ems, forPayout, forBonus);
}

function areAllEqual(reference: EMSBonus, compare: EMSBonus[], prop: keyof EMSBonus): boolean {
	return _.reduce(
		compare,
		(memo: boolean, bonus: EMSBonus): boolean => {
			return memo && reference[prop] === bonus[prop];
		},
		true
	);
}

function tourDealOneLiner(
	tms: Pick<TMS, 'costCalc2' | 'currency' | 'artistPayCurrency'>,
	forPayout: EMSTalentPayout,
	// TODO PRSM-XXXX omit dealOneLiner?
	forBonus: EMSBonus
): string {
	if (forPayout.dealType === DealTypes.Flat) {
		throw new Error('tourDealOneLinerChunks does not support payouts deals of type DealTypes.Flat');
	}

	const areBonusTypesTheSame: boolean = areAllEqual(forBonus, forPayout.bonuses, 'type');
	const areBonusAmountTheSame: boolean = areAllEqual(forBonus, forPayout.bonuses, 'amount');
	const areBonusAfterTheSame: boolean = areAllEqual(forBonus, forPayout.bonuses, 'after');
	const areBonusAfterAmountTheSame: boolean = areAllEqual(forBonus, forPayout.bonuses, 'afterAmount');
	const areBonusesTheSame: boolean = _.every([
		areBonusTypesTheSame,
		areBonusAfterTheSame,
		areBonusAmountTheSame,
		areBonusAfterAmountTheSame,
	]);

	const oneLinerChunks: OneLinerChunks = eventDealOneLinerChunks(tms, forPayout, forBonus);

	if (!areBonusesTheSame) {
		return `${oneLinerChunks.dealPrefix} + ${forPayout.bonuses.length} bonuses`;
	}

	let afterString: string = oneLinerChunks.afterString.trim();

	if (!forPayout.crossCollateralized) {
		// If the tour is not crossed
		// If there’s a bonus based on Costs, the one-liner should say “after costs” (bc the costs will be different on each show)
		// If there’s a bonus based on Manual Split Point, the one-liner should say “after [$5000] each show” (brackets being whatever they enter into the manual split point field)
		// If there’s a bonus based on % Tickets Sold, the one-liner should say “after [60%] sold each show” (brackets being whatever they enter into the percent sold field)
		// If there’s a bonus based on # Tickets Sold, the one-liner should say “after [600] tickets sold each show” (brackets being whatever they enter into the percent sold field)
		switch (forBonus.after) {
			case AfterType.Costs:
				afterString = `after costs`;
				break;
			case AfterType.Manual:
			case AfterType.PercentSellThrough:
			case AfterType.TicketsSold:
				afterString = `${afterString} each show`;
				break;
		}
	}
	// for crossed tours, we are utilizing the underlying logic from events, but on the reduced bonus object, which is what we are passing to the tour
	// If the tour is crossed
	// If there’s a bonus based on Costs, the one-liner should explicitly say the value of the tour costs (ex. “after $5000”)
	// If there’s a bonus based on Manual Split Point, the one-liner should say “after [$25,000]” (brackets being whatever they enter into the manual split point field)
	// If there’s a bonus based on % Tickets Sold, the one-liner should say “after [60%] sold” (brackets being whatever they enter into the percent sold field)
	// If there’s a bonus based on # Tickets Sold, the one-liner should say “after [600] tickets sold” (brackets being whatever they enter into the percent sold field)

	const newOneLiner: string = cleanUpOneLinerChunksArray([
		oneLinerChunks.dealPrefix,
		oneLinerChunks.formattedAmount,
		oneLinerChunks.bonusTypeDescriptor,
		afterString,
		oneLinerChunks.retroactiveBonus,
		oneLinerChunks.capped,
	]);

	// useful for debugging
	// return `${newOneLiner}\n\nwas: ${forBonus.dealOneLiner}`;
	return `${newOneLiner}`;
}

function talentDealPrefix(forPayout: EMSTalentPayout): string {
	let dealPrefix: string = '';
	switch (forPayout.dealType) {
		// unhandled types: DealTyoes.Flat, DealTypes.DoorDeal, DealTypes.None
		case DealTypes.Plus:
			dealPrefix = 'Plus';
			break;
		case DealTypes.Verse:
			dealPrefix = 'Versus';
			break;
	}
	return dealPrefix;
}

function formattedBonusAmount(forBonus: Omit<EMSBonus, 'dealOneLiner'>, currency: Currency): string {
	// formattedAmount = <X>$|%
	let formattedAmount: string = forBonus.amount.toString();
	switch (forBonus.type) {
		case BonusTypes.Flat:
		// falls through
		case BonusTypes.PerTicket:
			formattedAmount = formatAmount(forBonus.amount, currency);
			break;
		case BonusTypes.Net:
		// falls through
		case BonusTypes.NetGross:
			formattedAmount = `${forBonus.amount.toFixed(2)}%`;
			break;
	}
	return formattedAmount;
}

function getBonusTypeDescriptor(forBonus: Omit<EMSBonus, 'dealOneLiner'>): string {
	// bonusTypeDescriptor = per ticket|of Gross|of Net Revenue
	let bonusTypeDescriptor: string = '';
	switch (forBonus.type) {
		case BonusTypes.Flat:
			break;
		case BonusTypes.PerTicket:
			bonusTypeDescriptor = 'per ticket';
			break;
		case BonusTypes.Net:
			bonusTypeDescriptor = 'of Net Revenue';
			break;
		case BonusTypes.NetGross:
			bonusTypeDescriptor = `of ${resolveLabel(EMSFieldsMeta.netGross)}`;
			break;
	}
	return bonusTypeDescriptor;
}

function getCappedText(payout: EMSTalentPayout): string {
	return payout.isCapped ? '(Capped)' : '';
}

function eventDealOneLinerChunks(
	ems: Pick<EMS, 'currency' | 'artistPayCurrency'>,
	forPayout: EMSTalentPayout,
	forBonus: Omit<EMSBonus, 'dealOneLiner'>
): OneLinerChunks {
	if (forPayout.dealType === DealTypes.Flat) {
		throw new Error('eventDealOneLinerChunks does not support payouts deals of type DealTypes.Flat');
	}

	// dealPrefix = [Plus|Versus]
	const dealPrefix: string = talentDealPrefix(forPayout);

	// formattedAmount = <X>$|%
	const formattedAmount: string = formattedBonusAmount(forBonus, ems.artistPayCurrency);

	// bonusTypeDescriptor = per ticket|of Gross|of Net
	const bonusTypeDescriptor: string = getBonusTypeDescriptor(forBonus);

	// afterString = after <Y>[% sold, tickets sold]
	const afterString: string = buildAfterString(ems, forBonus, forPayout);

	// If the deal contains a retroactiveBonus, build out the text to indicate
	// as such. e.g.
	// retroactiveBonus = [(switches to <X%> after <Y>[% sold, tickets sold])]
	const retroactiveBonus: string = singleTalentPayoutRetroString(forPayout, forBonus);

	// capped = [(Capped)]
	const capped: string = getCappedText(forPayout);

	return {
		dealPrefix,
		formattedAmount,
		bonusTypeDescriptor,
		afterString,
		retroactiveBonus,
		capped,
	};
}

function eventDealOneLiner(
	ems: Pick<EMS, 'currency' | 'artistPayCurrency'>,
	forPayout: EMSTalentPayout,
	forBonus: Omit<EMSBonus, 'dealOneLiner'>
): string {
	if (forPayout.dealType === DealTypes.Flat) {
		return '';
	}
	const oneLinerChunks: OneLinerChunks = eventDealOneLinerChunks(ems, forPayout, forBonus);

	// The dealOneLiner is built as follows:
	// dealPrefix          |  [Plus|Versus]
	// formattedAmount     |  <X>$|%
	// bonusTypeDescriptor |  per ticket|of Gross|of Net
	// afterString         |  after <Y>[% sold, tickets sold]
	// retroactiveBonus    |  [(switches to <X%> after <Y>[% sold, tickets sold])]
	// dealDescription     |  [forPayout.dealDescription]
	// capped              |  [(Capped)]
	return cleanUpOneLinerChunksArray([
		oneLinerChunks.dealPrefix,
		oneLinerChunks.formattedAmount,
		oneLinerChunks.bonusTypeDescriptor,
		oneLinerChunks.afterString,
		oneLinerChunks.retroactiveBonus,
		oneLinerChunks.capped,
	]);
}

function buildAfterString(
	ems: Pick<EMS, 'currency' | 'artistPayCurrency'>,
	forBonus: Omit<EMSBonus, 'dealOneLiner'>,
	payout: EMSTalentPayout
): string {
	if (forBonus.type === BonusTypes.NetGross) {
		return '';
	}
	let afterAmount: string | number = forBonus.afterAmount;
	switch (forBonus.after) {
		case AfterType.Costs:
			afterAmount = payout.talentConvertedDocumentSplitPoint;
		// falls through
		case AfterType.Manual:
			afterAmount = formatAmount(<number>afterAmount, ems.currency);
			break;
		case AfterType.PercentSellThrough:
			afterAmount = `${afterAmount.toFixed(2)}%`;
			break;
		case AfterType.TicketsSold:
			break;
	}
	return `after ${afterAmount} ${buildAfterTypeString(forBonus.after)}`.trim();
}

/**
 * render a retro string for a talent payout. this chooses the highest-percentaged
 *
 * @param forPayout the payout for which a retro string will be chosen
 * @param forBonus the bonus, if any, on the deal we're adding the retro string to
 * @returns
 */
export function singleTalentPayoutRetroString(
	forPayout: EMSTalentPayout,
	forBonus: Omit<EMSBonus, 'dealOneLiner'> | undefined
): string {
	if (!forPayout.retroactiveBonus || !forPayout.retroactiveBonuses.length || !forBonus) {
		return '';
	}

	const highestNumberedRetro: EMSRetroactiveBonus = _.orderBy(
		forPayout.retroactiveBonuses,
		'retroactiveAfterAmount',
		'desc'
	)[0];
	const selectedRetroBonus: EMSRetroactiveBonus | undefined = forPayout.retroactiveBonuses.find(
		(bonus: EMSRetroactiveBonus): boolean => {
			return bonus.id === forBonus.selectedRetroBonusId;
		}
	);

	return `(switches to ${buildRetroActiveBonusString(
		forPayout.retroactiveAfterType,
		selectedRetroBonus || highestNumberedRetro
	)})`;
}

export function buildRetroActiveBonusString(retroactiveAfterType: AfterType, retroBonus: EMSRetroactiveBonus): string {
	let afterAmount: number | string = retroBonus.retroactiveAfterAmount;
	if (retroactiveAfterType === AfterType.PercentSellThrough) {
		afterAmount = `${afterAmount.toFixed(2)}%`;
	}
	afterAmount = `${afterAmount} ${buildAfterTypeString(retroactiveAfterType)}`;
	return `${retroBonus.retroactiveBonusPercentage.toFixed(2)}% ${retroBonus.thresholdType} ${afterAmount}`;
}

function buildAfterTypeString(type: AfterType): string {
	switch (type) {
		case AfterType.PercentSellThrough:
			return 'sold';
		case AfterType.TicketsSold:
			return 'tickets sold';
		default:
			return '';
	}
}

function cleanUpOneLinerChunksArray(chunks: string[]): string {
	return _.chain(chunks)
		.map((str: string): string => {
			return str.trim();
		})
		.filter((str: string): boolean => {
			return !!str.length;
		})
		.value()
		.join(' ')
		.trim();
}

export function transliterateEMSTalentPayout(payout: EMSTalentPayout, currency: Currency): string {
	if (!payout.isValid) {
		return 'Invalid Deal';
	}
	const bonusText: string = payout.bonuses
		.map((b: EMSBonus): string => {
			return b.dealOneLiner;
		})
		.join(', ');

	const descriptionText: string = payout.dealDescription ? `- ${payout.dealDescription}` : '';
	return `${formatAmount(payout.guarantee, currency)} ${bonusText} ${descriptionText}`.trim();
}

export function doesPayoutHaveThePotentialForMultipleBonuses(payout: EMSTalentPayout): boolean {
	if (!payout.isValid) {
		return false;
	}
	if (payout.dealType === DealTypes.Flat) {
		return false;
	}
	const firstBonus: EMSBonus = _.first(payout.bonuses);
	// this shouldn't be the case, but some event templates that are non-flat payouts
	// do not have any bonuses attached to them (bad data, but not resolved so this code
	// must guard against it)
	if (!firstBonus) {
		return false;
	}
	if (firstBonus.type === BonusTypes.PerTicket || firstBonus.type === BonusTypes.Flat) {
		return true;
	}
	return false;
}

function doesDealContainAgreedExpenses(payout: EMSTalentPayout): boolean {
	// If the payout is invalid then it doesn't contain agreed expenses
	if (!payout.isValid) {
		return false;
	}
	// also if the deal type is flat
	if (payout.dealType === DealTypes.Flat) {
		return false;
	}
	// this shouldn't be the case, but some event templates that are non-flat payouts
	// do not have any bonuses attached to them (bad data, but not resolved so this code
	// must guard against it)
	const firstBonus: EMSBonus = _.first(payout.bonuses);
	// If the bonus type is not NET then the deal doesn't contain agreed expenses
	if (!firstBonus || firstBonus.type !== BonusTypes.Net) {
		return false;
	}
	// These deal types contain agreed expenses, if the deal type isn't any of them
	// then there are no agreed expenses
	const agreedExpensesDeals: DealTypes[] = [DealTypes.Verse, DealTypes.Plus, DealTypes.DoorDeal];
	return agreedExpensesDeals.indexOf(payout.dealType) !== -1;
}

export function simpleDealTransliteration(
	ems: Pick<EMS, 'currency' | 'artistPayCurrency'>,
	payout: EMSTalentPayout
): string {
	if (!payout.isValid) {
		return 'Invalid Deal';
	}
	if (payout.dealType === DealTypes.Flat) {
		return 'Guarantee';
	}
	if (payout.dealType === DealTypes.Plus && doesPayoutHaveThePotentialForMultipleBonuses(payout)) {
		return 'Guarantee';
	}
	let payoutGuaranteeString: string = '';
	if (payout.guarantee) {
		payoutGuaranteeString = formatAmount(payout.guarantee, ems.artistPayCurrency);
	}
	const dealPrefix: string = talentDealPrefix(payout);
	let formattedAmount: string = '';
	let bonusTypeDescriptor: string = '';
	let afterString: string = '';
	if (payout.bonuses.length) {
		const firstBonus: EMSBonus = _.first(payout.bonuses);
		formattedAmount = formattedBonusAmount(firstBonus, ems.artistPayCurrency);
		bonusTypeDescriptor = getBonusTypeDescriptor(firstBonus);
		if (firstBonus.after === AfterType.Manual) {
			afterString = buildAfterString(ems, firstBonus, payout).replace('after', 'and');
		}
	}
	const taxesText: string = 'after Taxes,';
	let feesText: string = 'Fees,';
	let expensesText: string = '';
	// we only set after string if the bonus is a manual split, in which case
	// agreed expenses are irrelevant because the manual split point is preferred
	// so in that case, we do not set expenses text
	if (!afterString && doesDealContainAgreedExpenses(payout)) {
		expensesText = 'and Agreed Expenses';
	}
	if (!expensesText && !afterString) {
		feesText = 'and Fees';
	}
	const capped: string = getCappedText(payout);
	return cleanUpOneLinerChunksArray([
		payoutGuaranteeString,
		dealPrefix,
		formattedAmount,
		bonusTypeDescriptor,
		taxesText,
		feesText,
		expensesText,
		afterString,
		capped,
	]);
}

/**
 * fetch all the top level props for a particular ems rollup
 * @param rollup the current ems rollup
 * @returns the corresponding ems top level props
 */
export function getEMSTopLevelProps(rollup: EMSRollup): EMSCostCalcIndependentProps {
	const emsTopLevelProps: EMSCostCalcIndependentProps = {} as EMSCostCalcIndependentProps;
	for (const topLevelMetaKey of EMSTopLevelFieldsMetaKeys) {
		emsTopLevelProps[topLevelMetaKey] = rollup[topLevelMetaKey];
	}
	return emsTopLevelProps;
}

/**
 * fetch the particular EMSFieldMeta for a particular ems field meta id
 * @param emsMetadataId the given ems field meta id
 * @returns the corresponding ems field meta data
 */
export function getEMSMetadataForField(emsMetadataId: keyof EMSFieldDefs | string): EMSFieldMeta {
	return EMSFieldsMeta[standardizeFieldNameWithStars(emsMetadataId)];
}

export function standardizeFieldNameWithStars(emsMetadataId: keyof EMSFieldDefs | string): keyof EMSFieldDefs {
	return emsMetadataId.replace(/\.\d+/g, '.*') as keyof EMSFieldDefs;
}

/**
 * Determines if a currency conversion is required based on the properties of an EMS object.
 *
 * @param {EMS} ems - The EMS object containing information about the event, currency, and artist.
 * @returns {boolean} Returns `true` if a currency conversion is required, `false` otherwise.
 */
export function currencyConversionApplies(ems: EMS | EMSRollup): boolean {
	return !!ems.artistPayCurrency && ems.currency !== ems.artistPayCurrency;
}

export function checkLegacyArgs(costCalc: CostCalc, external: boolean): void {
	if (external && costCalc === CostCalc.Actual) {
		verboseDebug(`Invalid {ex,in}ternal:costCalc combo`, `${external ? 'external' : 'internal'}:${costCalc}`);
	}
}

/**
 * compute break even for an event.
 * at a high level, break even is a simple equation:
 *
 *   costs / ticket price
 *
 * this gives you the number of tickets you will need to sell for the show to become profitable,
 * or to "break even". compile your costs, compute your ticket prices, and pass them to this function
 * to get your break even number
 *
 * this function does this simple equation with a few exceptions
 *
 *   - if ticket price is zero, break even is zero
 *   - if the computed break even is negative, it is set to zero
 *
 * @param costs the costs of the show
 * @param averageTicketPrice the adjusted ticket price (after fees and tax are removed)
 * @returns the smallest number of tickets needed to sell at that ticket price to break even
 */
export function computeBreakEven(costs: number, averageTicketPrice: number): number {
	if (averageTicketPrice === 0) {
		return 0;
	}
	return Math.ceil(Math.max(0, costs / averageTicketPrice));
}

/**
 * Calculates the promoter's profit based on the total fixed costs and the promoter's profit percentage.
 * This function takes an object containing the total fixed costs of the event or tour and the profit percentage,
 * and computes the amount of the promoter's profit by applying the given percentage to the fixed costs.
 *
 * @param {{ promoterProfitPercentage: number; costsTotal: number }} promoterProfitParams - An object containing:
 *   - promoterProfitPercentage: The percentage of the fixed costs that represents the promoter's profit.
 *                               It should be provided as a whole number (e.g., 20 for 20%).
 *   - costsTotal: The total fixed costs of the event or tour. This amount is used as the base to calculate the profit.
 * @returns {number} The calculated profit of the promoter based on the provided fixed costs and profit percentage.
 */
export function calculatePromoterProfit(promoterProfitParams: {
	promoterProfitPercentage: number;
	costsTotal: number;
}): number {
	return (promoterProfitParams.promoterProfitPercentage / 100) * promoterProfitParams.costsTotal;
}

export function areAllEventsOnSameVenue(events: PrismEvent[]): boolean {
	const venueIdReference: number = events[0].venue?.id;
	if (!venueIdReference) {
		throw Error(`There is no venue defined for ${events[0].name}`);
	}
	return _.every(events, (event: PrismEvent): boolean => {
		return event.venue?.id === venueIdReference;
	});
}
