import { CollectedBy } from '@prism-frontend/typedefs/enums/CollectedBy';
import { PaymentStatusBackend } from '@prism-frontend/typedefs/enums/PaymentStatus';
import { castToUTCDate } from '@prism-frontend/utils/transformers/castToUTCDate';
import { Transform } from 'class-transformer';
import { IsEnum, IsNumber, IsOptional, IsString } from 'class-validator';

/**
 * The base class for all payment data. This class is abstract
 * and should be extended by the concrete payment data classes
 */
export abstract class PaymentData {
	/**
	 * The id related to the payment data. This is entity specific.
	 * It will be either the deposit id, the settlement id or null for withholding
	 */
	@IsNumber() public id: number | null = null;

	/**
	 * The status of the payment data. This status is a BE status
	 * which doesn't know about an overdue payment state which
	 * is determined in the FE
	 */
	@IsEnum(PaymentStatusBackend) public status: PaymentStatusBackend = PaymentStatusBackend.UNTRACKED;

	/**
	 * The notes associated to the payment
	 */
	@IsString() public notes: string | null = null;

	/**
	 * The resposible of collecting that payment. Either the agent
	 * or the artist
	 */
	@IsEnum(CollectedBy)
	public collected_by: CollectedBy;

	/**
	 * The date when the payment was created
	 */
	@IsOptional()
	@IsString()
	@Transform(castToUTCDate())
	public created_at?: string;

	/**
	 * The date when the payment was updated the last time
	 */
	@IsOptional()
	@IsString()
	@Transform(castToUTCDate())
	public updated_at?: string;
}
