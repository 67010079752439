import { EMSFieldHelperService } from '@prism-frontend/services/ui/ems-field-helper.service';
import { AllEMSFields } from '@prism-frontend/typedefs/ems/AllEMSFields';
import {
	editEMSEventData,
	editEMSFixedCost,
	editEMSTalentPayout,
	editEMSVariableCost,
	userCanEditFixedCost,
} from '@prism-frontend/typedefs/ems/ems-static-helpers/emsCostsEditPropertyMap';
import { PrismEvent } from '@prism-frontend/typedefs/event';

export interface PersistFieldEditMeta {
	/**
	 * A function used to persist the ems field edit. emsFieldEditHelperService
	 * contains all needed services to be used for persisting the edited data
	 * while the fieldEditData contains some information about the field that's
	 * been edited and its index if its an array based field, then at last
	 * the edited value to be peristed. Fields that don't provide this function
	 * will throw an erorr when trying to edit a value
	 */
	persistFieldEdit: (
		emsFieldEditHelperService: EMSFieldHelperService,
		emsEntityToUpdate: unknown,
		emsPropertyToUpdate: string,
		emsPropertyValue: unknown,
		event: PrismEvent
	) => Promise<PrismEvent>;

	userCanEdit?: (
		emsFieldEditHelperService: EMSFieldHelperService,
		emsEntityToUpdate: unknown,
		event: PrismEvent
	) => boolean;
}

export const EMSFieldsMetaPersistence: Partial<AllEMSFields<PersistFieldEditMeta>> = {
	'coProSummary.fixedCosts.*.coProOverriddenAmount': {
		persistFieldEdit: editEMSFixedCost,
	},
	'coProSummary.fixedCosts.*.expenseTotal': {
		persistFieldEdit: editEMSFixedCost,
	},
	'coProSummary.fixedCosts.*.hiddenFromCoPro': {
		persistFieldEdit: editEMSFixedCost,
	},
	'coProSummary.variableCosts.*.hiddenFromCoPro': {
		persistFieldEdit: editEMSVariableCost,
	},
	'coProSummary.variableCosts.*.coProOverriddenAmount': {
		persistFieldEdit: editEMSVariableCost,
	},
	'coProSummary.variableCosts.*.expenseTotal': {
		persistFieldEdit: editEMSVariableCost,
	},
	'fixedCosts.*.costs.*.name': {
		persistFieldEdit: editEMSFixedCost,
		userCanEdit: userCanEditFixedCost,
	},
	'fixedCosts.*.costs.*.costAmount': {
		persistFieldEdit: editEMSFixedCost,
		userCanEdit: userCanEditFixedCost,
	},
	'fixedCosts.*.costs.*.quantity': {
		persistFieldEdit: editEMSFixedCost,
		userCanEdit: userCanEditFixedCost,
	},
	'fixedCosts.*.costs.*.total': {
		persistFieldEdit: editEMSFixedCost,
		userCanEdit: userCanEditFixedCost,
	},
	'fixedCosts.*.costs.*.computedTotal': {
		persistFieldEdit: editEMSFixedCost,
		userCanEdit: userCanEditFixedCost,
	},
	additionalSupport: {
		persistFieldEdit: editEMSEventData,
	},
	additionalSupportHiddenFromCoPro: {
		persistFieldEdit: editEMSEventData,
	},
	'talentPayouts.*.actualAdjustedArtistPayout': {
		persistFieldEdit: editEMSTalentPayout,
	},
	'talentPayouts.*.coProOverrideArtistPayout': {
		persistFieldEdit: editEMSTalentPayout,
	},
	'talentPayouts.*.hiddenFromCoPro': {
		persistFieldEdit: editEMSTalentPayout,
	},
	coProOverridenAdditionalSupport: {
		persistFieldEdit: editEMSEventData,
	},
};
