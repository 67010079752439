import { Injectable } from '@angular/core';
import { GooglePlaceDetailsResponse } from '@prism-frontend/services/api/graphql/venue-graphql.service';
import { ApiService } from '@prism-frontend/services/legacy/api.service';
import { firstValueFrom } from 'rxjs';

export interface AddressResult {
	main_text: string;
	secondary_text: string;
	place_id: string;
}
export interface GoogleSuggestion {
	// main_text: "Liquid Church - Somerset County Campus",
	main_text: string;
	// secondary_text: "Davenport Street, Somerville, NJ, USA",
	secondary_text: string;
	// place_id: "ChIJU6-4pUmVw4kRE8WkrYuIY0o",
	place_id: string;
}

@Injectable({ providedIn: 'root' })
export class AddressSearchService {
	public constructor(private apiService: ApiService) {}

	public addressSearch(search: string): Promise<AddressResult[]> {
		return this.apiService
			.getS(this.apiService.ep.GOOGLE_PLACE_ADDRESS_SEARCH, {
				params: {
					search,
				},
			})
			.toPromise()
			.then((response: AddressResult[]): AddressResult[] => {
				return response;
			});
	}

	public async getGooglePlaceDetails(placeId: string): Promise<GooglePlaceDetailsResponse> {
		return await firstValueFrom(
			this.apiService.getS<{ params: { search: string } }, GooglePlaceDetailsResponse>([
				this.apiService.ep.GOOGLE_PLACE_DETAILS,
				placeId,
			])
		).then((response: GooglePlaceDetailsResponse): GooglePlaceDetailsResponse => {
			return response;
		});
	}
}
