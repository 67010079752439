import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { empty, fromEvent, merge, Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ConnectionService {
	private readonly connectionMonitor: Observable<boolean>;

	public constructor(@Inject(PLATFORM_ID) platform: Object) {
		if (!isPlatformBrowser(platform)) {
			this.connectionMonitor = empty();
			return;
		}

		const offline$: Observable<boolean> = fromEvent(window, 'offline').pipe(mapTo(false));
		const online$: Observable<boolean> = fromEvent(window, 'online').pipe(mapTo(true));
		this.connectionMonitor = merge(offline$, online$);
	}
}
