import { DocumentNode, gql } from '@apollo/client/core';

export const AgentUserFragment: DocumentNode = gql`
	fragment AgentUserFragment on TalentAgentAgent {
		id
		email
		name
		agent_user_id
		default_commission
	}
`;

export const AgentUserFragmentOnEvent: DocumentNode = gql`
	fragment AgentUserFragmentOnEvent on EventTalentAgent {
		id
		email
		name
		agent_user_id
		agent_commission
	}
`;
