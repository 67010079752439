import {
	CustomFieldBackend,
	flattenCustomFieldType,
	NUMBER_CUSTOM_FIELDS,
} from '@prism-frontend/entities/custom-fields/custom-fields-typedefs';
import { EnabledChipConfiguration } from '@prism-frontend/entities/email-templates/data-interpolation/EnabledChipConfiguration';
import { InterpolatedDataOption } from '@prism-frontend/entities/email-templates/data-interpolation/InterpolatedDataOption';
import {
	mapSurfacedCustomFieldPropConfigToInterpolatedDataOption,
	mapSurfacedEMSPropConfigToInterpolatedDataOption,
} from '@prism-frontend/entities/email-templates/data-interpolation/mapSurfacedPropConfigToInterpolatedDataOption';
import { AllFunctionalChips } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/AllFunctionalChips';
import { AllCostCalc2 } from '@prism-frontend/typedefs/AllCostCalc2';
import { resolveDescription, resolveLabel } from '@prism-frontend/typedefs/ems/ems-field-explainer-helpers';
import { EMSFieldCategory, EMSFieldMeta, EMSFieldsMeta } from '@prism-frontend/typedefs/ems/EMSFieldMeta';
import { EMSPropSpecifier, SurfacedEMSPropConfig } from '@prism-frontend/typedefs/ems/EMSPropSpecifier';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';
import { EMSFieldType } from '@prism-frontend/typedefs/enums/EMSFieldType';
import _ from 'lodash';

/**
 * This function returns ALL ems fields as options for use in the field sandbox
 * in debugging mode.
 * @param customFields
 * @param numbersOnly
 * @returns
 */
export function fetchOptionsForFieldSandbox(
	customFields: CustomFieldBackend[] = [],
	numbersOnly: boolean = false
): EnabledChipConfiguration {
	const emsPropOptions: InterpolatedDataOption[] = fetchEMSOptionsForFieldSandbox(numbersOnly);
	const customFieldOptions: InterpolatedDataOption[] = [];
	if (customFields) {
		customFieldOptions.push(...fetchCustomFieldOptionsForFieldSandbox(customFields, numbersOnly));
	}
	return {
		dataChips: [...emsPropOptions, ...customFieldOptions],
		functionalChips: [...AllFunctionalChips],
	};
}

function fetchEMSOptionsForFieldSandbox(numbersOnly: boolean): InterpolatedDataOption[] {
	return (
		_.chain(EMSFieldsMeta)
			// Surface everything so Ben can make an offer
			.filter((metadata: EMSFieldMeta): boolean => {
				if (numbersOnly) {
					return metadata.type === EMSFieldType.number || metadata.type === EMSFieldType.dollarAmount;
				}
				return true;
			})
			.map((metadata: EMSFieldMeta): SurfacedEMSPropConfig[] => {
				if (!metadata.costCalcDependent) {
					return [
						mapEMSPropSpecifierToSurfacedEMSPropConfigForFieldSandbox(
							{
								emsMetadataId: metadata.emsMetadataId,
								costCalc: CostCalc2.ExternalReported,
							},
							false
						),
					];
				}
				return AllCostCalc2.map((costCalc: CostCalc2): SurfacedEMSPropConfig => {
					return mapEMSPropSpecifierToSurfacedEMSPropConfigForFieldSandbox(
						{
							emsMetadataId: metadata.emsMetadataId,
							costCalc,
						},
						true
					);
				});
			})
			.flatten()
			.map(mapSurfacedEMSPropConfigToInterpolatedDataOption)
			.sortBy('identifier')
			.value()
	);
}

function fetchCustomFieldOptionsForFieldSandbox(
	customFields: CustomFieldBackend[],
	numbersOnly: boolean
): InterpolatedDataOption[] {
	return _.chain(customFields)
		.filter((customField: CustomFieldBackend): boolean => {
			// we don't support showing advance custom fields, if we decide to do so
			// we need to prevent cyclic dependencies
			const isAdvanceCustomField: boolean = customField.is_advanced;
			// if we don't need numbers only ensure its not an advance field
			if (!numbersOnly) {
				return !isAdvanceCustomField;
			}
			// check for numbers only, which already excludes any advance field
			const isNumberCustomField: boolean =
				!customField.is_advanced && NUMBER_CUSTOM_FIELDS.has(flattenCustomFieldType(customField));
			return isNumberCustomField;
		})
		.map((customField: CustomFieldBackend): InterpolatedDataOption => {
			return mapSurfacedCustomFieldPropConfigToInterpolatedDataOption(customField);
		})
		.sortBy('identifier')
		.value();
}

function mapEMSPropSpecifierToSurfacedEMSPropConfigForFieldSandbox(
	specifier: EMSPropSpecifier,
	includeCostCalc: boolean
): SurfacedEMSPropConfig {
	const fieldMetadata: EMSFieldMeta = EMSFieldsMeta[specifier.emsMetadataId];
	const costCalcText: string = includeCostCalc ? `${specifier.costCalc}.` : '';
	const resolvedLabel: string = resolveLabel(fieldMetadata, specifier.costCalc, '');
	const resolvedDescription: string = resolveDescription(fieldMetadata, specifier.costCalc, '');
	const label: string = `${costCalcText}${specifier.emsMetadataId}${resolvedLabel ? ` - ${resolvedLabel}` : ''}`;
	const description: string = `(${fieldMetadata.type})${resolvedDescription ? ` - ${resolvedDescription}` : ''}`;
	const category: EMSFieldCategory = fieldMetadata.category || ('Uncategorized' as EMSFieldCategory);
	return {
		...specifier,
		label,
		description,
		category,
		fieldMetadata,
	};
}
