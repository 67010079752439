import { getEMSMetadataForField } from '@prism-frontend/typedefs/ems/ems-static-helpers/ems-static-helpers';
import { emsValue } from '@prism-frontend/typedefs/ems/ems-static-helpers/emsValue';
import { renderValueForField } from '@prism-frontend/typedefs/ems/ems-static-helpers/renderValueForField';
import { EMS } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { EMSFieldDefs, EMSFieldMeta } from '@prism-frontend/typedefs/ems/EMSFieldMeta';
import { Currency } from '@prism-frontend/typedefs/enums/currency';

export function formatFieldValue(
	ems: Partial<EMS>,
	emsMetadataId: keyof EMSFieldDefs,
	currencyOverride: Currency = ems.currency
): string {
	const fieldMeta: EMSFieldMeta = getEMSMetadataForField(emsMetadataId);
	return renderValueForField(emsValue(ems, emsMetadataId), fieldMeta, currencyOverride, ems.id);
}
