import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrderItemsService } from '@prism-frontend/services/api/order-items.service';
import { OrgSettingsService } from '@prism-frontend/services/legacy/api/org-settings/org-settings.service';
import { PermissionsService } from '@prism-frontend/services/legacy/api/permissions.service';
import { ComponentModalizerService } from '@prism-frontend/services/ui/component-modalizer.service';
import { SnackbarService } from '@prism-frontend/services/ui/snackbar.service';
import { OrganizationTypeGateService } from '@prism-frontend/services/utils/organization-type-gate.service';
import { PromiseQueueService } from '@prism-frontend/services/utils/promise-queue.service';

@Injectable({
	providedIn: 'root',
})
export class EntityHelperServiceUtils {
	public constructor(
		public componentModalizerService: ComponentModalizerService,
		public matDialog: MatDialog,
		public orderItemsService: OrderItemsService,
		public orgSettingsService: OrgSettingsService,
		public organizationTypeGate: OrganizationTypeGateService,
		public permissionsService: PermissionsService,
		public promiseQueueService: PromiseQueueService<unknown>,
		public snackbarService: SnackbarService
	) {}
}
