import { Injectable } from '@angular/core';
import { PrismGraphqlService } from '@prism-frontend/services/api/graphql/prism-graphql.service';
import { ApiService, instantiateApiResponse } from '@prism-frontend/services/legacy/api.service';
import { EMSRollup } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { PrismEvent } from '@prism-frontend/typedefs/event';
import {
	DuplicateConactResponse,
	DuplicateEventArguments,
	DuplicateEventMutation,
} from '@prism-frontend/typedefs/graphql/DuplicateEvent';
import {
	UpdateEventMutation,
	UpdateEventMutationArguments,
	UpdateEventMutationResponse,
} from '@prism-frontend/typedefs/graphql/Event';
import {
	EmsRollupsBatchIdQuery,
	EventsQueryVariables,
	PaginatedEventQuery,
	PaginatedEventQueryResult,
	PaginatedEventsQueryVariables,
	SimpleEventQuery,
} from '@prism-frontend/typedefs/graphql/EventsQuery';
import { InstantiatedAPIResponseAndValidationResults } from '@prism-frontend/utils/decorators/validate-children';
import { fetchAllPagesFromEndpoint } from '@prism-frontend/utils/static/fetch-all-pages-from-endpoint';
import { OnPageCallback, queryAllEntities } from '@prism-frontend/utils/static/query-all-entities';
import { plainToClass } from 'class-transformer';

export interface EMSBatchStatus {
	total: number;
	progress: number;
	cacheGeneratedAt: string | null;
}

interface EMSBatchCancelResponse {
	code: number;
	message: string;
}

export interface EMSBatchItem {
	id: number;
	batch_id: string;
	event_id: string;
	created_at: string;
	updated_at: string;
	ems_result: EMSRollup & { event: PrismEvent };
}

export interface EMSBatchData {
	batchId: string;
	cacheGeneratedAt: string | null;
}

@Injectable()
export class EventGraphqlService {
	public constructor(private prismGraphqlService: PrismGraphqlService, private apiService: ApiService) {}

	/**
	 * get an array of PrismEvents via our paginated eventList graphql query
	 *
	 * @param eventsQueryVariables filter options for events
	 * @param onPageCallback called when a page of events is loaded
	 * @param onTotal called when the first totals are loaded
	 * @param abortSignal an abort signal, passed to cancel any queries
	 * @returns Promise<PrismEvent[]>
	 */
	public async fetchEvents(
		eventsQueryVariables: EventsQueryVariables,
		onPageCallback?: OnPageCallback<PrismEvent>,
		abortSignal?: AbortSignal,
		forceNetworkRequest?: boolean
	): Promise<PrismEvent[]> {
		return queryAllEntities<EventsQueryVariables, PrismEvent>(
			this.prismGraphqlService,
			eventsQueryVariables,
			PaginatedEventQuery,
			'eventList',
			PrismEvent,
			onPageCallback,
			abortSignal,
			forceNetworkRequest,
			Number(localStorage.getItem('reportsPageSize')) || 500,
			Number(localStorage.getItem('reportsPageConcurrency')) || 5
		);
	}

	public async fetchEMSServiceBatchId(
		bypassCache: boolean,
		eventsQueryVariables: EventsQueryVariables
	): Promise<EMSBatchData> {
		const { emsList }: { emsList: EMSBatchData } = await this.prismGraphqlService.query<{
			emsList: EMSBatchData;
		}>({
			query: EmsRollupsBatchIdQuery,
			variables: {
				bypassCache,
				...eventsQueryVariables,
			},

			// we want this graphql query to bypass the cache. that means a new batch ID gets generated every time
			// and we never pull an old batch ID from the cache
			fetchPolicy: 'network-only',
		});
		return emsList;
	}

	public async fetchEMSRollupBatchProgress(batchId: string): Promise<EMSBatchStatus> {
		const result: EMSBatchStatus = await this.apiService.postP([
			this.apiService.ep.PULL_EMS_ROLLUPS_BATCH_STATUS,
			batchId,
		]);
		return result;
	}

	public async cancelEMSRollupBatch(batchId: string): Promise<EMSBatchCancelResponse> {
		const result: EMSBatchCancelResponse = await this.apiService.postP([
			this.apiService.ep.PULL_EMS_ROLLUPS_BATCH_CANCEL,
			batchId,
		]);
		return result;
	}

	public async clearEMSRollupBatchFromCache(batchId: string): Promise<EMSBatchCancelResponse> {
		const result: EMSBatchCancelResponse = await this.apiService.postP([
			this.apiService.ep.PULL_EMS_ROLLUPS_BATCH_CLEAR,
			batchId,
		]);
		return result;
	}

	public async fetchEMSRollupBatchResults(
		batchId: string,
		batchTotal: number,
		concurrency: number = 5,
		onPageCallback?: OnPageCallback<EMSBatchItem>
	): Promise<EMSRollup[]> {
		const results: EMSBatchItem[] = await fetchAllPagesFromEndpoint<EMSBatchItem>(
			[this.apiService.ep.PULL_EMS_ROLLUPS_BATCH, batchId],
			this.apiService,
			null,
			batchTotal,
			Number(localStorage.getItem('reportsPageSize')) || 500,
			concurrency,
			onPageCallback
		);
		return results.map((result: EMSBatchItem): EMSRollup => {
			return result.ems_result;
		});
	}

	public async simpleEventSearch(
		nameSearch: string,
		currentEventCount: number,
		abortSignal?: AbortSignal,
		forceNetworkRequest?: boolean
	): Promise<PaginatedEventQueryResult> {
		const pageSize: number = 50;
		const currentPage: number = Math.floor(currentEventCount / pageSize) + 1;

		return this.prismGraphqlService
			.query<PaginatedEventQueryResult>({
				query: SimpleEventQuery,
				variables: {
					name: nameSearch,
					page: currentPage,
					limit: pageSize,
					orderBy: 'EventDate',
					sortDirection: 'asc',
				},
				fetchPolicy: forceNetworkRequest ? 'network-only' : undefined,
				context: {
					// add the ability to cancel queries by overriding options passed to fetch()
					// https://www.apollographql.com/docs/react/networking/advanced-http-networking/#overriding-options
					// https://developer.mozilla.org/en-US/docs/Web/API/AbortController/signal
					fetchOptions: {
						signal: abortSignal,
					},
				},
			})
			.then(async (result: PaginatedEventQueryResult): Promise<PaginatedEventQueryResult> => {
				const entities: InstantiatedAPIResponseAndValidationResults<PrismEvent[]> =
					await instantiateApiResponse<PrismEvent[]>(PrismEvent, result.eventList.data);
				return {
					...result,
					eventList: {
						...result.eventList,
						data: entities.data,
					},
				};
			});
	}

	public async paginatedEventList(
		paginatedEventsQueryVariables: PaginatedEventsQueryVariables,
		abortSignal?: AbortSignal,
		forceNetworkRequest?: boolean
	): Promise<PaginatedEventQueryResult> {
		return this.prismGraphqlService
			.query<PaginatedEventQueryResult>({
				query: PaginatedEventQuery,
				variables: paginatedEventsQueryVariables,
				fetchPolicy: forceNetworkRequest ? 'network-only' : undefined,
				context: {
					// add the ability to cancel queries by overriding options passed to fetch()
					// https://www.apollographql.com/docs/react/networking/advanced-http-networking/#overriding-options
					// https://developer.mozilla.org/en-US/docs/Web/API/AbortController/signal
					fetchOptions: {
						signal: abortSignal,
					},
				},
			})
			.then(async (result: PaginatedEventQueryResult): Promise<PaginatedEventQueryResult> => {
				const entities: InstantiatedAPIResponseAndValidationResults<PrismEvent[]> =
					await instantiateApiResponse<PrismEvent[]>(PrismEvent, result.eventList.data);
				return {
					...result,
					eventList: {
						...result.eventList,
						data: entities.data,
					},
				};
			});
	}

	public duplicateEvent(duplicateEventData: DuplicateEventArguments): Promise<number> {
		return this.prismGraphqlService
			.mutate<DuplicateEventArguments, DuplicateConactResponse>({
				mutation: DuplicateEventMutation,
				variables: {
					...duplicateEventData,
				},
			})
			.then((response: DuplicateConactResponse): number => {
				return response.duplicateEvent;
			});
	}

	public updateEvent(variables: UpdateEventMutationArguments): Promise<PrismEvent> {
		return this.prismGraphqlService
			.mutate<UpdateEventMutationArguments, UpdateEventMutationResponse>({
				mutation: UpdateEventMutation,
				variables,
			})
			.then((response: UpdateEventMutationResponse): PrismEvent => {
				const updatedEvent: PrismEvent = plainToClass(PrismEvent, response.updateEvent);
				return updatedEvent;
			});
	}
}
