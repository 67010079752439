import { DocumentNode } from '@apollo/client/core';
import { FinalMutationThings, generateMutations } from '@prism-frontend/typedefs/graphql/query-generator-helpers';
import { StageProps } from '@prism-frontend/typedefs/graphql/StageProps';
import { Stage } from '@prism-frontend/typedefs/stage';

const mutationThings: FinalMutationThings = generateMutations<Stage>(
	'Stage',
	StageProps,
	{
		name: 'String!',
		color: 'String!',
		capacity: 'Int!',
		active: 'Boolean',
	},
	{
		venue_id: 'Int!',
	}
);

type SharedStageProps = Pick<Stage, 'name' | 'color' | 'capacity' | 'active'>;

export type UpdateStageMutationVariables = SharedStageProps & Pick<Stage, 'id'>;

export type CreateStageMutationVariables = SharedStageProps & Pick<Stage, 'venue_id'>;

export const CreateStageMutation: DocumentNode = mutationThings.createMutation;

export interface CreateStageMutationResponse {
	createStage: Stage;
}

export const UpdateStageMutation: DocumentNode = mutationThings.updateMutation;
export interface UpdateStageMutationResponse {
	updateStage: Stage;
}
