export enum BonusTypes {
	Net = 'net',
	NetGross = 'gross',
	PerTicket = 'per_ticket',
	Flat = 'flat',
}

export const BonusTypeClassNames: { [key in BonusTypes]: string } = {
	[BonusTypes.Net]: '[data-testid="js-toggle-bonus-type-net"]',
	[BonusTypes.NetGross]: '[data-testid="js-toggle-bonus-type-netGross"]',
	[BonusTypes.PerTicket]: '[data-testid="js-toggle-bonus-type-perTicket"]',
	[BonusTypes.Flat]: '[data-testid="js-toggle-bonus-type-flat"]',
};
