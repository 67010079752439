import { Type } from 'class-transformer';
import { IsString } from 'class-validator';

export class AdvanceEventDetails {
	@IsString() public fbEventURL: string = '';

	@IsString() public ticketingURL: string = '';

	@IsString() public staffing: string = '';
}

export class AdvanceData {
	@Type((): typeof AdvanceEventDetails => {
		return AdvanceEventDetails;
	})
	public advanceEventDetails: AdvanceEventDetails = new AdvanceEventDetails();

	@IsString() public additionalAdvanceText: string = '';
}
