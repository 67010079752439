export interface PaginationDetails {
	total: number;
	last_page: number;
	current_page: number;
	has_more_pages: boolean;
	per_page: number;
}

export interface PaginationQueryProps {
	page: number;
	limit: number;
}

export type PaginatedData<T> = {
	data: T[];
} & PaginationDetails;

export interface PaginationProps {
	queryParams: string;
	queryArguments: string;
	responseProps: string;
}

export function fetchPaginationProps(): PaginationProps {
	return {
		queryParams: `
$page: Int!
$limit: Int!`,
		queryArguments: `
page: $page
limit: $limit`,
		responseProps: `
total
last_page
current_page
has_more_pages`,
	};
}
