import { DocumentNode, gql } from '@apollo/client/core';

export const TalentFragment: DocumentNode = gql`
	fragment CoreTalent on Talent {
		id
		name
		fb_id
		nbs_id
		support
		spotify_id
		genre_strings
		images {
			url
			width
			height
		}
	}
`;
