import { Stage } from '@prism-frontend/typedefs/stage';
import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { Transform, Type, plainToInstance } from 'class-transformer';
import { IsBoolean, IsNumber, IsOptional, IsString } from 'class-validator';
export class Datez {
	public constructor(data?: Partial<Datez>) {
		return plainToInstance(Datez, data);
	}

	@IsString()
	public date: string;

	@IsNumber()
	public hold_id: number;

	@IsBoolean()
	@Transform(castToBoolean())
	public all_day: boolean;

	@IsString()
	@IsOptional()
	public start_time?: string;

	@IsString()
	@IsOptional()
	public end_time?: string;

	@IsNumber()
	@IsOptional()
	public stage_id?: number;

	@Type((): typeof Stage => {
		return Stage;
	})
	public stage?: Stage;
}

export interface DatezWithFormat extends Datez {
	formatted_hold_date: string;
	formatted_time: string;
}
