import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { FeatureGateService } from '@prism-frontend/services/utils/feature-gate.service';
import { errorDebug } from '@prism-frontend/utils/static/getDebug';

export const canActivateFeatureGate: CanActivateFn = (route: ActivatedRouteSnapshot): boolean => {
	const featureGateService: FeatureGateService = inject(FeatureGateService);
	if (!route.data.FeatureGateGuard) {
		// If this gate is running but the above switch does not know
		// why, assume the feature should not be enabled.
		errorDebug(`FeatureGateGuard: Run on route with no data.FeatureGateGuard. Assuming canActivate() => false`);
		return false;
	}

	return featureGateService.isFeatureEnabled(route.data.FeatureGateGuard.feature);
};
