import { TypedTransformFnParams } from '@prism-frontend/typedefs/TypedTransformFnParams';
import { TransformFnParams } from 'class-transformer';
import moment from 'moment';

/**
 * take date strings from the b/e API, and ensure they have a `Z` appended to them if they dont have it already
 *
 * note that its possible that we solve this in the API one day. if that is ever the case, this method and its uses
 * should be deprecated
 *
 * TODO PRSM-6314 deprecate this decorator if needed
 *
 * @param propName string the prop name to target
 */
export function castToUTCDate(): (transformParams: TransformFnParams) => string {
	return (transformParams: TypedTransformFnParams<string>): string => {
		const dateString: string = transformParams.value;
		const dateMoment: moment.Moment = moment(dateString);

		if (!dateMoment.isValid()) {
			return dateString;
		}

		if (dateString.toLowerCase().indexOf('z') >= 0) {
			return dateString;
		}

		return `${dateString}Z`;
	};
}
