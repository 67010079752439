import { EMS, EMSRollup } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { PrismEvent } from '@prism-frontend/typedefs/event';
import {
	EVENT_STATUS_ARCHIVED_COLOR,
	EVENT_STATUS_CONFIRMED_COLOR,
	EVENT_STATUS_HOLD_COLOR,
	EVENT_STATUS_SETTLED_COLOR,
	EVENT_STATUS_SETTLEMENT_COLOR,
} from '@prism-frontend/utils/static/colors';

export enum EventStatus {
	Hold = 0,
	N_A = 1,
	Confirmed = 2,
	Settlement = 3,
	Settled = 4,
}

export const AllEventStatuses: EventStatus[] = [
	EventStatus.Hold,
	EventStatus.N_A,
	EventStatus.Confirmed,
	EventStatus.Settlement,
	EventStatus.Settled,
];

export const DefaultConfirmStatus: EventStatus[] = [...AllEventStatuses];

export function isEventStatusConfirmedOrHigher(eventStatus: EventStatus): boolean {
	return eventStatus >= EventStatus.Confirmed;
}

export const AllActualEventStatuses: EventStatus[] = [
	EventStatus.Hold,
	EventStatus.Confirmed,
	EventStatus.Settlement,
	EventStatus.Settled,
];

export function eventStatusToString(status: EventStatus, event?: PrismEvent | EMS | EMSRollup): string {
	if (event && event.isArchived) {
		return 'Archived';
	}
	switch (status) {
		case EventStatus.Hold:
		// cost-calc-toggle.component.spec explicit checks that eventStatus="null" is handled as a Hold
		case null:
			return 'Hold';
		case EventStatus.Confirmed:
			return 'Confirmed';
		case EventStatus.Settlement:
			return 'Settlement';
		case EventStatus.Settled:
			return 'Settled';
		case EventStatus.N_A:
			return 'N/A';
		default:
			throw new Error(`Unrecognized EventStatus: ${status}`);
	}
}

export function eventStatusStringToColor(
	statusString: AllEventStatusStrings,
	event?: PrismEvent | EMS | EMSRollup
): string {
	if (event && event.isArchived) {
		return EVENT_STATUS_ARCHIVED_COLOR;
	}
	switch (statusString) {
		case 'Hold':
			return EVENT_STATUS_HOLD_COLOR;
		case 'Confirmed':
			return EVENT_STATUS_CONFIRMED_COLOR;
		case 'Settlement':
			return EVENT_STATUS_SETTLEMENT_COLOR;
		case 'Settled':
			return EVENT_STATUS_SETTLED_COLOR;
		case 'N/A':
			return EVENT_STATUS_CONFIRMED_COLOR;
		default:
			throw new Error(`Unrecognized event status string: ${statusString}`);
	}
}

export type AllEventStatusStrings = 'Hold' | 'Confirmed' | 'Settlement' | 'Settled' | 'Archived' | 'N/A';
