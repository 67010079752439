import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';
import { environment } from '@prism-frontend/../environments/environment';
import { EnvironmentNames } from '@prism-frontend/typedefs/environment.typedef';
import { WithAutoUnsubscribeService } from '@prism-frontend/utils/static/auto-unsubscribe';
import * as _ from 'lodash';

const STACK_SEPARATOR: string = ' > ';
const TITLE_SEPARATOR: string = ' | ';
const VAR_REGEX: RegExp = /{{(.*?)}}/g;

@Injectable({
	providedIn: 'root',
})
export class PrismTitleService extends WithAutoUnsubscribeService {
	private curTitle: string[] = [];
	private providedData: { [key: string]: string } = {};
	private providedDataTimeout: number | null = null;

	public constructor(private router: Router, private titleService: Title) {
		super();
		this.initRouterSubscription();
	}

	private initRouterSubscription(): void {
		this.addSubscription(
			this.router.events.subscribe((evt: ActivationEnd): void => {
				if (!(evt instanceof ActivationEnd)) {
					return;
				}
				const snapshot: ActivatedRouteSnapshot = evt.snapshot;
				this.updateTitleFromSnapshot(snapshot);
			})
		);
	}

	private updateTitleFromSnapshot(snapshot: ActivatedRouteSnapshot): void {
		const title: string[] = [];
		do {
			if (snapshot.data.pageTitle) {
				title.push(snapshot.data.pageTitle);
			}
			snapshot = snapshot.firstChild;
		} while (snapshot);
		this.setTitle(title);
	}

	public updateTitleOnInit(snapshot: ActivatedRouteSnapshot): void {
		this.updateTitleFromSnapshot(snapshot);
	}

	public provideData(key: string, value: string): void {
		this.providedData[key] = value;
		this.updatePageTitle();
	}

	public deleteData(key: string): void {
		delete this.providedData[key];
		this.updatePageTitle();
	}

	public getProvidedData(key: string): string {
		return this.providedData[key];
	}

	public pushTitleStack(title: string): void {
		this.curTitle.push(title);
		this.setTitle(this.curTitle);
	}

	public popTitleStack(): string {
		const popped: string = this.curTitle.pop();
		this.setTitle(this.curTitle);
		return popped;
	}

	private setTitle(title?: string | string[]): void {
		if (!_.isArray(title)) {
			title = [title];
		}
		title = _.chain(title)
			.flatten()
			.filter(_.identity)
			.filter((t: string): boolean => {
				return t !== `${TITLE_SEPARATOR}Prism`;
			})
			.uniq()
			.value();
		title.push(`${TITLE_SEPARATOR}Prism`);
		if (environment.name !== EnvironmentNames.Prod) {
			title.unshift(`(${environment.name})`);
		}
		this.curTitle = title;
		this.updatePageTitle();
	}

	/**
	 * Updates the page title with the contents of this.curTitle.
	 * @param {boolean} fromTimeout = false Used internally when calling itself
	 *   from the set timeout.
	 */
	private updatePageTitle(fromTimeout: boolean = false): void {
		if (this.providedDataTimeout) {
			clearTimeout(this.providedDataTimeout);
			this.providedDataTimeout = null;
		}

		let titleStr: string = this.curTitle
			.join(STACK_SEPARATOR)
			.replace(`${STACK_SEPARATOR}${TITLE_SEPARATOR}`, TITLE_SEPARATOR);

		_.each(Object.keys(this.providedData), (key: string): void => {
			titleStr = titleStr.replace(`{{${key}}}`, this.providedData[key]);
		});

		const hasReplacements: RegExpMatchArray | null = titleStr.match(VAR_REGEX);
		if (!hasReplacements) {
			// If we replaced everything, update the title
			this.titleService.setTitle(titleStr);
		} else if (fromTimeout) {
			// We didn't replace everything :(
			// If we are running from the timeout, log a warning

			// eslint-disable-next-line no-console
			console.warn(
				`Not updating page title because all required data has been provided to provideData. Missing:`,
				titleStr
					.match(VAR_REGEX)
					.map((s: string): string => {
						return s.substr(2, s.length - 4);
					})
					.join(', ')
			);
		} else {
			// We didn't replace everything :(
			// wait 8 seconds and try again
			this.providedDataTimeout = <number>(<unknown>setTimeout((): void => {
				// try again in hopes that all missing data was provided
				// if it still wasn't, a warning will be logged
				this.updatePageTitle(true);
			}, 10000));
		}
	}
}
