import { Injectable } from '@angular/core';
import { PrismAnalyticsEvent } from '@prism-frontend/typedefs/analytics/analytics-events';
import { omit } from 'lodash';
import { SegmentService } from 'ngx-segment-analytics';

/**
 * a service that requires proper typings for analytics events.
 * requires developers to fill out all proper information for a given analytics event, defined in @prism-frontend/typedefs/analytics-events
 */
@Injectable({ providedIn: 'root' })
export class PrismAnalyticsService {
	public constructor(private segmentService: SegmentService) {}

	/**
	 * fire a typed analytics event
	 *
	 * @param analyticsEvent the analytics event, and any requisiste data that needs to be passed along
	 */
	public fireEvent(analyticsEvent: PrismAnalyticsEvent): void {
		// get the event name
		const { eventName }: PrismAnalyticsEvent = analyticsEvent;

		// get any additional analytics data, if it exists
		const additionalAnalyticsData: Record<string, unknown> = {
			...omit<PrismAnalyticsEvent>(analyticsEvent, 'eventName'),
		};

		/**
		 * fire a segment event
		 */
		this.segmentService.track(
			eventName as string,
			Object.keys(additionalAnalyticsData).length === 0 ? undefined : additionalAnalyticsData
		);
	}
}
