import { DocumentNode, gql } from '@apollo/client/core';

export const CoreTourFragment: DocumentNode = gql`
	fragment CoreTourFragment on Tour {
		id
		name
		currency
		organization_id
		created_by
		updated_at
		offer_logo_id
		promoter_profit
		budgeted_additional_support
		actual_additional_support
		calculate_by_net_gross
		is_broadway_tour
	}
`;
