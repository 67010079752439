import { Component, Input } from '@angular/core';
import { IconName, IconNameMap } from '@prism-frontend/typedefs/enums/IconName';

@Component({
	selector: 'prism-icon',
	templateUrl: './prism-icons.component.html',
	styleUrls: ['./prism-icons.component.scss'],
})
export class PrismIconsComponent {
	@Input() public iconName: IconName;

	// this is `$prismIconGrey` in our CSS
	@Input() public fill: string = '#939393';

	@Input() public stroke: string = '#ffffff';

	public IconName: typeof IconName = IconName;

	public get iconAttribute(): string {
		// return a falsy value to prevent the data attribute from binding
		if (!IconNameMap.has(this.iconName)) {
			return '';
		}

		return this.iconName;
	}
}
