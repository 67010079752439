import _ from 'lodash';

export function keyObjectsBy<T, TValue extends string | number | symbol>(
	objects: T[],
	key: keyof T
): Record<TValue, T> {
	return _.chain(objects)
		.map((object: T): [TValue, T] => {
			const theKey: TValue = object[key] as unknown as TValue;
			if (_.isObjectLike(theKey)) {
				throw new Error(
					`keyObjectsBy found value at ${key.toString()} to be objectLink. This cannot be used for a key.`
				);
			}
			return [theKey, object];
		})
		.fromPairs()
		.value() as unknown as Record<TValue, T>;
}
