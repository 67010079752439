import { Injectable } from '@angular/core';
import { PrismGraphqlService } from '@prism-frontend/services/api/graphql/prism-graphql.service';
import { instantiateApiResponse } from '@prism-frontend/services/legacy/api.service';
import { SettleUpArguments, SettleUpMutation, SettleUpResponse } from '@prism-frontend/typedefs/graphql/SettleUp';
import { InvoiceOrReceipt } from '@prism-frontend/typedefs/invoice';
import { InstantiatedAPIResponseAndValidationResults } from '@prism-frontend/utils/decorators/validate-children';

@Injectable({ providedIn: 'root' })
export class SettleUpGrapqhlService {
	public constructor(private prismGraphqlService: PrismGraphqlService) {}

	public settleUp(settleUpArguments: SettleUpArguments): Promise<InvoiceOrReceipt> {
		return this.prismGraphqlService
			.mutate<SettleUpArguments, SettleUpResponse>({
				mutation: SettleUpMutation,
				variables: settleUpArguments,
			})
			.then(async (response: SettleUpResponse): Promise<InvoiceOrReceipt> => {
				const instantiatedData: InstantiatedAPIResponseAndValidationResults<InvoiceOrReceipt> =
					await instantiateApiResponse<InvoiceOrReceipt>(InvoiceOrReceipt, response.settleUp);

				return instantiatedData.data;
			});
	}
}
