import { ErrorHandler, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@prism-frontend/../environments/environment';
import { SnackbarErrorComponent } from '@prism-frontend/components/snackbar-error/snackbar-error.component';
import { isEnvironmentLower } from '@prism-frontend/typedefs/environment-checkers';
import { circularJSONReplacer } from '@prism-frontend/utils/static/circularJSONReplacer';

@Injectable()
export class PrismErrorHandler implements ErrorHandler {
	private _lastError: string = '';

	public constructor(private snackBar: MatSnackBar) {}
	public handleError(error: unknown): void {
		const wasExpected: boolean = this.handleErrorIfExpected(error);
		if (wasExpected) {
			return;
		}
		if (isEnvironmentLower()) {
			const thisErrorMessage: string = `Uncaught error: ${error.toString()}<br>(see console for details)`;
			if (thisErrorMessage === this._lastError) {
				return;
			}
			this.snackBar.openFromComponent(SnackbarErrorComponent, {
				panelClass: ['snack-bar-error'],
				duration: 5000,
				data: {
					message: thisErrorMessage,
				},
			});
			// eslint-disable-next-line no-console
			console.error(`Uncaught error:`, error);
			this._lastError = thisErrorMessage;
		} else {
			try {
				// eslint-disable-next-line no-console
				console.error(`Uncaught error: ${JSON.stringify(error, circularJSONReplacer, 2)}`);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error('Cyclic reference detected in JSON while logging the error');
			}
		}
	}

	public handleErrorIfExpected(error: unknown): boolean {
		const message: string = error['indexOf'] ? error : error['message'];
		if (message && message.indexOf('ExpressionChangedAfterItHasBeenCheckedError') !== -1) {
			if (!environment.suppressExpressionChangedAfterItHasBeenCheckedError) {
				// eslint-disable-next-line no-console
				console.warn(error['message']);
				// eslint-disable-next-line no-console
				console.log(error['stack']);
			}
			return true;
		}
		return false;
	}
}
