import { isString } from 'lodash';

/**
 * given an array of strings and undefined values, filter out the array for and only include
 * valid, non-empty strings, and join them again
 *
 * @param array array of strings or undefined;
 * @param separator the separator with which to join the array
 * @returns a string joined by the separator, with an array filtered for empty strings, undefined, and space-only strings
 */
export function joinArray(array: (string | undefined)[], separator: string = ', '): string {
	return array
		.filter((value: string | undefined): boolean => {
			return isString(value) && !!value.trim();
		})
		.map((value: string): string => {
			return value.trim();
		})
		.join(separator);
}

export function joinArrayByKey<T>(array: T[], key: keyof T, separator: string = ', '): string {
	return joinArray(
		array.map((item: T): string | undefined => {
			const value: unknown = item[key];
			if (!value) {
				return undefined;
			}
			return `${item[key]}`;
		}),
		separator
	);
}
