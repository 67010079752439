import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@prism-frontend/../environments/environment';
import { PermissionsService } from '@prism-frontend/services/legacy/api/permissions.service';
import { LoginService } from '@prism-frontend/services/legacy/login.service';
import { StorageService } from '@prism-frontend/services/legacy/storage.service';
import { WithAutoUnsubscribeService } from '@prism-frontend/utils/static/auto-unsubscribe';

const ADMIN_MODE_KEY: string = 'admin-mode';

@Injectable({
	providedIn: 'root',
})
export class AdminModeService extends WithAutoUnsubscribeService {
	public get adminMode(): boolean {
		return this._adminMode && this.shouldDisplayAdminModeAsOption;
	}
	public set adminMode(val: boolean) {
		this.adminMode$.next(val);
	}
	private _adminMode: boolean = false;

	public adminMode$: EventEmitter<boolean> = new EventEmitter<boolean>(false);

	public constructor(
		private storageService: StorageService,
		private permissionsService: PermissionsService,
		private loginService: LoginService
	) {
		super();
		if (!environment.production) {
			(<{ toggleAdminMode: () => void }>(<unknown>window)).toggleAdminMode = (): void => {
				this.adminMode$.next(!this.adminMode);
			};
		}

		this.oneAndDone(this.permissionsService.globalPermissionsLoaded).then((): void => {
			this.initAdminMode();
		});

		this.addSubscription(
			this.adminMode$.subscribe((newValue: boolean): void => {
				this._adminMode = newValue;
			})
		);
	}

	public get shouldDisplayAdminModeAsOption(): boolean {
		return this.permissionsService.isPrismAdmin || this.loginService.hasPrismAdminKey;
	}

	public persistAdminModeSetting(): void {
		this.storageService.setItem(ADMIN_MODE_KEY, JSON.stringify(this.adminMode));
	}

	private initAdminMode(): void {
		if (!(this.permissionsService.isPrismAdmin || this.loginService.hasPrismAdminKey)) {
			return;
		}
		try {
			const adminMode: string = this.storageService.getItem(ADMIN_MODE_KEY);
			if (adminMode) {
				this.adminMode$.next(Boolean(JSON.parse(adminMode)));
			}
		} catch (e) {
			// if we failed to parse, no worries. we initialize adminMode to false
		}
	}
}
