import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '@prism-frontend/services/legacy/api/permissions.service';
import { LoginService } from '@prism-frontend/services/legacy/login.service';
import { AdminModeService } from '@prism-frontend/services/ui/admin-mode.service';
import { FeatureGateService } from '@prism-frontend/services/utils/feature-gate.service';
import { Observable, map } from 'rxjs';

export function insightsRouteCheck(
	adminModeService: AdminModeService,
	featureGateService: FeatureGateService,
	permissionsService: PermissionsService
): boolean {
	// allow prism admins to see insights
	if (adminModeService.adminMode) {
		return true;
	}

	// dont show insights if the CAN_USERS_SEE_INSIGHTS feature is disabled
	// this will get set to true when we are ready to launch
	if (!featureGateService.isFeatureEnabled('CAN_USERS_SEE_INSIGHTS')) {
		return false;
	}

	// if we have turned off DATA_SHARE for the environment or org, do not show the link
	if (!featureGateService.isFeatureEnabled('DATA_SHARE') || !featureGateService.orgHasFeature('DATA_SHARE')) {
		return false;
	}

	// if we got here, only show the link if users have the proper permissions to do so
	return permissionsService.userCan('view-insights');
}

/**
 * insights-specific guard. we have a case where users can activate the page if one of the
 * conditions passes, but angular routing requires all guards to pass.
 *
 * as far as i can tell this is the only way to do a nuanced guard
 */
export const CanActivateInsightsGuard: CanActivateFn = (
	_route: ActivatedRouteSnapshot,
	_state: RouterStateSnapshot
): Observable<boolean> => {
	const featureGateService: FeatureGateService = inject(FeatureGateService);
	const permissionsService: PermissionsService = inject(PermissionsService);
	const adminModeService: AdminModeService = inject(AdminModeService);
	const loginService: LoginService = inject(LoginService);

	return loginService.userLoadedStream$.pipe(
		map((): boolean => {
			return insightsRouteCheck(adminModeService, featureGateService, permissionsService);
		})
	);
};
