import { UserInterface } from '@prism-frontend/typedefs/organization';

export type TaskTemplatePrePost = 'pre' | 'post';
export type TaskTemplateTimeLength = 'day' | 'week' | 'month';
export type TaskTemplateDateReference =
	| 'pre_sale_date'
	| 'on_sale_date'
	| 'announce_date'
	| 'created_at'
	| 'confirmed_time'
	| 'event_date';

export class TaskTemplate {
	public id?: number;
	public organization_id?: number;
	public group_id?: number;
	public title: string;
	public description: string;
	public assignees_ids?: number[] | null = null;
	public assignees?: UserInterface[];
	// Reactive date fields
	public period?: number | null = null;
	public pre_post?: TaskTemplatePrePost | null = null;
	public time_length?: TaskTemplateTimeLength | null = null;
	public date_reference?: TaskTemplateDateReference | null = null;
}

export class TaskTemplateGroup {
	/**
	 * id of the taskTemplateGroup. Unique and used to identify the taskTemplateGroup in question.
	 */
	public id: number;
	/**
	 * organization_id. ID of the organization that the taskTemplateGroup belongs to.
	 */
	public organization_id: number;
	/**
	 * name. The title of the taskTemplateGroup.
	 */
	public name: string;
	/**
	 * Description. The descriptive contents of the taskTemplateGroup
	 */
	public description: string;
	/**
	 * Templates. These are the individual Task Templates associated with the particular taskTemplateGroup.
	 */
	public templates?: TaskTemplate[];
}
