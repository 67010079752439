import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ArtistForm } from '@prism-frontend/entities/artists/artist-form/artist-form-typedefs';
import {
	CreateArtistArguments,
	CreateArtistMutation,
	CreateArtistMutationResponse,
	TalentAgentInput,
	TalentInput,
	UpdateArtistArguments,
	UpdateArtistMutation,
	UpdateArtistMutationResponse,
} from '@prism-frontend/entities/artists/artist-graphql/artist-graphql-mutation';
import {
	ArtistByIDArguments,
	ArtistByIdQuery,
	PaginatedArtistsQuery,
} from '@prism-frontend/entities/artists/artist-graphql/artist-graphql-query';
import { Artist } from '@prism-frontend/entities/artists/artist-typedefs';
import { EventGraphqlService } from '@prism-frontend/services/api/graphql/event-graphql.service';
import { PrismGraphqlService } from '@prism-frontend/services/api/graphql/prism-graphql.service';
import { AgentUser } from '@prism-frontend/typedefs/AgentUser';
import { EventStatus } from '@prism-frontend/typedefs/enums/event-status';
import { PrismEvent } from '@prism-frontend/typedefs/event';
import { AllContactsQuery } from '@prism-frontend/typedefs/graphql/ContactsQuery';
import { EventsQueryVariables } from '@prism-frontend/typedefs/graphql/EventsQuery';
import { TalentImage } from '@prism-frontend/typedefs/talent';
import {
	DefaultOnPageCallback,
	OnPageCallback,
	queryAllEntities,
} from '@prism-frontend/utils/static/query-all-entities';
import _ from 'lodash';

@Injectable()
export class ArtistsGraphqlService {
	public constructor(
		private eventGraphqlService: EventGraphqlService,
		private matSnackBar: MatSnackBar,
		private prismGraphqlService: PrismGraphqlService
	) {}

	/**
	 * get an array of PrismEvents via our paginated eventList graphql query
	 *
	 * @param eventsQueryVariables filter options for events
	 * @param onPageCallback called when a page of events is loaded
	 * @param onTotal called when the first totals are loaded
	 * @param abortSignal an abort signal, passed to cancel any queries
	 * @returns Promise<PrismEvent[]>
	 */
	public async fetchArtists(
		/**
		 * null => fetch all artists regardless of is_archived status
		 * true => fetch only archived artists
		 * false => fetch only non-archived artists
		 */
		is_archived: boolean | null,
		onPageCallback?: OnPageCallback<Artist>,
		abortSignal?: AbortSignal,
		forceNetworkRequest?: boolean
	): Promise<Artist[]> {
		let variables: {
			is_archived?: boolean;
		} = {};
		if (_.isBoolean(is_archived)) {
			variables = {
				is_archived,
			};
		}
		return queryAllEntities<{}, Artist>(
			this.prismGraphqlService,
			variables,
			PaginatedArtistsQuery,
			'talentAgentList',
			Artist,
			onPageCallback,
			abortSignal,
			forceNetworkRequest || false
		);
	}

	/**
	 * get an artist by ID
	 *
	 * @param artistId number - the ID of the artist to be fetched
	 * @returns Artist
	 */
	public async artistById(artistId: number): Promise<Artist> {
		// fetch a list, filtered by artist ID
		const artists: Artist[] = await queryAllEntities<ArtistByIDArguments, Artist>(
			this.prismGraphqlService,
			{
				id: artistId,
			},
			ArtistByIdQuery,
			'talentAgentList',
			Artist,
			(): void => {},
			undefined,
			true
		);

		// make sure the array is usable
		if (!artists.length) {
			throw new Error(`Artist not found: ${artistId}`);
		}

		if (artists.length > 1) {
			throw new Error(`More than one artist found for this ID: ${artistId}`);
		}

		// grab the artist result
		return Promise.resolve(artists[0]);
	}

	public async saveArtist(artist: ArtistForm, creatingNew: boolean): Promise<Artist> {
		const {
			id,
			talentId,
			talentName,
			talentSpotifyId,
			talentImages,
			manager_contact_ids,
			contract_signer_ids,
			notes,
			contact_ids,
		}: ArtistForm = artist;
		const managerContactId: number | null = manager_contact_ids[0] || null;
		const contractSignerId: number | null = contract_signer_ids[0] || null;

		const talent: TalentInput = {
			id: talentId,
			name: talentName,
			spotify_id: talentSpotifyId,
			images: talentImages.map((image: TalentImage): TalentImage => {
				return {
					height: image.height,
					width: image.width,
					url: image.url,
				};
			}),
		};

		const agents: TalentAgentInput[] = artist.agents.map((agent: AgentUser): TalentAgentInput => {
			return {
				agent_user_id: agent.agent_user_id,
				default_commission: agent.default_commission,
			};
		});

		let savedArtist: Artist;
		const createArtistArgs: CreateArtistArguments = {
			talent,
			manager_contact_id: managerContactId,
			contract_signer_id: contractSignerId,
			notes,
			agents,
			contact_ids,
		};
		if (creatingNew) {
			savedArtist = new Artist({
				...(await this.createNewArtist({
					...createArtistArgs,
				})),
			});
		} else {
			savedArtist = new Artist({
				...(await this.updateExistingArtist({
					id,
					...createArtistArgs,
					is_archived: artist.is_archived,
				})),
			});
		}

		this.matSnackBar.open(`Artist ${talentName} Saved!`, null, {
			duration: 1500,
		});

		return savedArtist;
	}

	public fetchEventsForArtist(
		artistId: number,
		scopeForBalanceTable: boolean = true,
		onPage: OnPageCallback<PrismEvent> = DefaultOnPageCallback,
		abortSignal: AbortSignal = new AbortController().signal
	): Promise<PrismEvent[]> {
		let extraParams: Partial<EventsQueryVariables> = {};
		if (scopeForBalanceTable) {
			// When building the balance table, we only consider events that are
			// 	1) settled
			// 	2) have an un-balanced event_talent deal for this artist
			extraParams = {
				event_status: [EventStatus.Settled],
				has_unsettled_deal: true,
			};
		}
		return this.eventGraphqlService.fetchEvents(
			{
				dates__date__gte: undefined,
				dates__date__lte: undefined,
				created_at_gte: undefined,
				created_at_lte: undefined,
				event_status: [],
				deal_status: [],
				payment_status: [],
				payment_types: [],
				name: undefined,
				stages: [],
				places: [],
				states: [],
				artists: [],
				talent_ids: [],
				// the word client means nothing, so instead we call client id
				// (which is really the artist roster entry id) "talent_agent_id"
				// because thats WAY more clear than "client" /sarcasm
				talent_agent_ids: [artistId],
				manager_ids: [],
				agent_ids: [],
				companies: [],
				contacts: [],
				genres: [],
				currencies: [],
				isOutsidePromoter: [],
				event_owner: [],
				include_mad: false,
				only_mad: false,
				include_archived_events: true,
				contract_due_at_gte: undefined,
				contract_due_at_lte: undefined,
				...extraParams,
			},
			onPage,
			abortSignal,
			true
		);
	}

	private createNewArtist(artistArguments: CreateArtistArguments): Promise<Artist> {
		return this.prismGraphqlService
			.mutate<CreateArtistArguments, CreateArtistMutationResponse>({
				mutation: CreateArtistMutation,
				variables: artistArguments,
			})
			.then((response: CreateArtistMutationResponse): Artist => {
				return response.createTalentAgent;
			});
	}

	public updateExistingArtist(variables: UpdateArtistArguments): Promise<Artist> {
		return this.prismGraphqlService
			.mutate<UpdateArtistArguments, UpdateArtistMutationResponse>({
				mutation: UpdateArtistMutation,
				variables,
				// Refetch contacts to update associations when archiving/unarchiving an artist
				refetchQueries: [
					{
						query: AllContactsQuery,
					},
				],
			})
			.then((response: UpdateArtistMutationResponse): Artist => {
				return response.updateTalentAgent;
			});
	}
}
