import { AllSimpleFormFieldConfigs } from '@prism-frontend/components/simple-form/simple-form.typedefs';
import { ADMIN_OBJ } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/ADMIN_OBJ';
import {
	FunctionalChip,
	FunctionalChipFunctionResult,
	FunctionalChipOrgData,
} from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/FunctionalChip';
import { FunctionalChipAdditionalData } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/FunctionalChipAdditionalData';
import { EMSAdditionalRevenue } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';
import FuzzySearch from 'fuzzy-search';
import _ from 'lodash';

interface FindAdditionalRevenueByNameArg {
	name: string;
	costCalc: CostCalc2;
	property: keyof EMSAdditionalRevenue | typeof ADMIN_OBJ;
}

export const findAdditionalRevenueByName: FunctionalChip<FindAdditionalRevenueByNameArg> = {
	identifier: 'findAdditionalRevenueByName',
	name: 'Find Additional Revenue Item',
	fallbackValue: (): string => {
		return '0';
	},
	theFunction(
		argument: FindAdditionalRevenueByNameArg,
		_orgData: FunctionalChipOrgData,
		data: FunctionalChipAdditionalData
	): FunctionalChipFunctionResult {
		const additionalRevenue: EMSAdditionalRevenue[] =
			data.emsRollup[argument.costCalc || CostCalc2.InternalEstimated].additionalRevenue;
		const searcher: FuzzySearch<EMSAdditionalRevenue> = new FuzzySearch(additionalRevenue, ['name'], {
			caseSensitive: false,
			sort: true,
		});
		// very forgiving fuzzy search, should users be able to opt into this?
		const result: EMSAdditionalRevenue[] = searcher.search(argument.name);
		if (argument.property === ADMIN_OBJ) {
			return JSON.stringify(_.first(result)) as never;
		}
		if (!result.length) {
			return null;
		}
		return _.first(result)[argument.property] || 0;
	},
	formFields(
		data: FunctionalChipOrgData,
		value: FindAdditionalRevenueByNameArg
	): AllSimpleFormFieldConfigs<FindAdditionalRevenueByNameArg>[] {
		const suggestions: (keyof EMSAdditionalRevenue | typeof ADMIN_OBJ)[] = [
			// 'name',
			'amount',
			// 'includeInCoProSplit',
			// 'postfix',
		];
		if (data.isAdminModeEnabled) {
			suggestions.push(ADMIN_OBJ);
		}
		let costCalcField: AllSimpleFormFieldConfigs<FindAdditionalRevenueByNameArg>;
		if (value.property === 'amount' || value.property === ADMIN_OBJ) {
			costCalcField = {
				label: 'Calculation',
				description: '',
				fieldType: 'select',
				// TODO PRSM-XXXX map cost calc to human readable
				suggestions: [CostCalc2.InternalEstimated, CostCalc2.InternalActual],
				key: 'costCalc',
				required: true,
				editable: true,
			};
		}
		return [
			{
				label: 'Name',
				description: 'Enter a name for the Additional Revenue you wish to find',
				fieldType: 'text',
				key: 'name',
				required: true,
				editable: true,
			},
			{
				label: 'Property',
				description: '',
				fieldType: 'select',
				// TODO PRSM-XXXX map to human readable
				// TODO PRSM-XXXX associate with ems metadata for field
				suggestions,
				key: 'property',
				required: true,
				editable: true,
			},
			costCalcField,
		];
	},
	chipText: (value: FindAdditionalRevenueByNameArg): string => {
		// TODO PRSM-XXXX PRSM-XXXX map cost calc to human readable
		return `Find Additional Revenue: "${value.name}" - ${value.costCalc || CostCalc2.InternalEstimated} - ${
			value.property
		}`;
	},
};
