import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@prism-frontend/services/legacy/api.service';
import { OrgSettingsService } from '@prism-frontend/services/legacy/api/org-settings/org-settings.service';
import { PermissionsService } from '@prism-frontend/services/legacy/api/permissions.service';
import { UserService } from '@prism-frontend/services/legacy/api/user.service';
import { LocalStorageKeys } from '@prism-frontend/services/legacy/LocalStorageKeys';
import { invalidateLocalStorageCaches, StorageService } from '@prism-frontend/services/legacy/storage.service';
import { UserTokenStorageService } from '@prism-frontend/services/legacy/user-token-storage.service';
import { OrganizationTypeGateService } from '@prism-frontend/services/utils/organization-type-gate.service';
import { PusherService } from '@prism-frontend/services/utils/pusher.service';
import { User } from '@prism-frontend/typedefs/user';
import _ from 'lodash';
import { SegmentService } from 'ngx-segment-analytics';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
	providedIn: 'root',
})
export class LoginService {
	/**
	 * There are 2 main app loading phases, when the login
	 * token authenticity is verified, and when the user info
	 * is loaded. The app component and several guards will wait
	 * until these respond with a status, otherwise the user
	 * might end up on a blank page.
	 */
	// Use the logged in status of the login as a stream
	public isLoggedInStream$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
	// Use the user loaded status as a stream
	public userLoadedStream$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

	public constructor(
		public http: HttpClient,
		public apiService: ApiService,
		public router: Router,
		public permissionsService: PermissionsService,
		public route: ActivatedRoute,
		public userService: UserService,
		public segment: SegmentService,
		public storageService: StorageService,
		private pusherService: PusherService,
		private userTokenStorageService: UserTokenStorageService,
		private orgSettingsService: OrgSettingsService,
		private organizationTypeService: OrganizationTypeGateService
	) {}

	public validateLogin(): void {
		// No token, not logged in
		if (!this.userTokenStorageService.hasToken) {
			this.isLoggedInStream$.next(false);
			return;
		}
		// Has a token, but let's make sure it works
		this.apiService.getS(this.apiService.ep.CHECK_TOKEN).subscribe((response: { token_valid?: boolean }): void => {
			if (!_.isUndefined(response.token_valid) && response.token_valid) {
				this.isLoggedInStream$.next(true);
				return;
			}
			// Handle condition where logged-in-token is expired
			if (this.isLoggedInAsUser) {
				this.restoreTempToken();
				this.killAdminTokens();
				location.reload();
				return;
			}
			this.isLoggedInStream$.next(false);
		});
	}

	public get isLoggedInAsUser(): boolean {
		return !!this.storageService.getItem(LocalStorageKeys.ADMIN_TOKEN_KEY);
	}

	public get hasPrismAdminKey(): boolean {
		return this.storageService.getItem(LocalStorageKeys.PRISM_ADMIN_KEY) === 'y';
	}

	private setToken(token: string): void {
		this.userTokenStorageService.setToken(token);
		// Assume the token received is good
		this.isLoggedInStream$.next(true);
	}

	public killToken(sendToLogin: boolean = false): void {
		this.userTokenStorageService.killToken();

		// Restore a backed up session if we are
		//  logged-in-as
		if (this.isLoggedInAsUser) {
			this.redirectAfterLogInAs();
			return;
		}

		// Update app state
		this.isLoggedInStream$.next(false);

		if (sendToLogin) {
			this.sendToLogin();
		}
	}

	private rememberReturnUrl(url: string): void {
		this.storageService.setItem(LocalStorageKeys.ADMIN_RETURN_URL, url);
	}

	private createLogInAsTokenBackup(isAdmin: boolean = false): void {
		// back up current login token
		this.storageService.setItem(
			LocalStorageKeys.ADMIN_TOKEN_KEY,
			this.storageService.getItem(LocalStorageKeys.USER_TOKEN_KEY)
		);
		// Remember if we are swapping out as a prism admin
		this.storageService.setItem(LocalStorageKeys.PRISM_ADMIN_KEY, isAdmin ? 'y' : 'n');
	}

	public createLogInAsToken(token: string): void {
		this.storageService.setItem(LocalStorageKeys.USER_TOKEN_KEY, token);
	}

	public restoreTempToken(): void {
		this.storageService.setItem(
			LocalStorageKeys.USER_TOKEN_KEY,
			this.storageService.getItem(LocalStorageKeys.ADMIN_TOKEN_KEY)
		);
		this.killAdminTokens();
	}

	public killAdminTokens(): void {
		this.storageService.removeItem(LocalStorageKeys.ADMIN_TOKEN_KEY);
		this.storageService.removeItem(LocalStorageKeys.PRISM_ADMIN_KEY);
		this.storageService.removeItem(LocalStorageKeys.ADMIN_RETURN_URL);
	}

	public logOut(): void {
		this.apiService.postS([this.apiService.ep.LOGOUT]).subscribe((): void => {
			this.killToken(true);
			localStorage.clear();
		});
	}

	public async loadUserData(): Promise<void> {
		await this.orgSettingsService.handleLoginServiceLoaded();

		// segment identify
		this.apiService.getS<never, User>([this.apiService.ep.USER]).subscribe((data: User): void => {
			const context: object = {
				name: data.name,
				email: data.email,
				organization: data.organization_name,
				'Pricing Tier': data.organization.pricing_tier,
				'Agency Account': this.organizationTypeService.isAgent,
			};
			// don't identify logged-in-as
			if (!this.isLoggedInAsUser) {
				this.segment.identify(String(data.id), context);
				this.segment.group(String(data.organization_id), {
					name: data.organization_name,
				});
			}
			this.userService.setUser(data);
			this.permissionsService.startUp();
			this.permissionsService.loadGlobalPermissions(
				data.is_prism_admin,
				data.is_admin,
				data.global_roles,
				data.permissions
			);
			// Initialize pusher
			this.pusherService.initialize(data.organization_id);
			this.userLoadedStream$.next(true);
		});
	}

	public navigateHomeAndReload(reload: boolean = false): void {
		// Nav to home screen and refresh
		this.router.navigate(['']).then((): void => {
			if (reload) {
				location.reload();
			}
		});
	}

	public navigateAndReload(url: string): void {
		// Nav to home screen and refresh
		this.router.navigate([url]).then((): void => {
			location.reload();
		});
	}

	public sendToLogin(forwardPath: string = '/'): void {
		let params: object | undefined;
		if (forwardPath !== '/') {
			params = {
				queryParams: {
					forwardPath: forwardPath,
				},
			};
		}
		this.router.navigate(['/login'], params);
	}

	public navToForwardPath(): void {
		let forwardPath: string = this.route.snapshot.queryParamMap.get('forwardPath');
		if (forwardPath) {
			let params: object = {};
			if (forwardPath.indexOf('?') !== -1) {
				params = this.sortParams(forwardPath);
				forwardPath = forwardPath.split('?')[0];
			}
			this.router.navigate([forwardPath], params);
			return;
		}
		this.navigateHomeAndReload(false);
	}

	public sortParams(url: string): object {
		const queryParams: string = url.split('?')[1];
		const params: string[] = queryParams.split('&');
		const data: { [key: string]: string } = {};
		params.forEach((d: string): void => {
			const pair: string[] = d.split('=');
			data[`${pair[0]}`] = pair[1];
		});
		return data;
	}

	public login(username: string, password: string): Observable<boolean> {
		invalidateLocalStorageCaches();
		return this.http
			.post<{
				token_type: string;
				access_token: string;
			}>(
				'/oauth/token',
				JSON.stringify({
					username,
					password,
					grant_type: 'password',
					client_id: '1',
					client_secret: 'qLo8CKyJvLyghdX3W8A5xyUHOAiMWdMKBgvdFtkt',
					scope: '*',
				})
			)
			.pipe(
				map((res: { token_type: string; access_token: string }): boolean => {
					if (res.token_type === 'Bearer') {
						this.setToken(res.access_token);
					}
					return true;
				})
			);
	}

	public redirectAfterLogInAs(): void {
		let redirectTarget: string = this.storageService.getItem(LocalStorageKeys.ADMIN_RETURN_URL);
		if (!redirectTarget) {
			redirectTarget = '';
		}
		// Go back to specified redirect target and refresh
		this.router.navigate([redirectTarget]).then((): void => {
			// once we pass the router guard, reload
			// page with our original auth token
			this.restoreTempToken();
			location.reload();
		});
	}

	public logOutAsUser(): void {
		// Hit the back end to delete all client tokens
		this.apiService.postS(this.apiService.ep.LOG_OUT_AS).subscribe((): void => {
			invalidateLocalStorageCaches();
			this.redirectAfterLogInAs();
		});
	}

	public logInAsUser(user_id: number, returnUrl: string, forwardUrl: string = ''): Promise<void> {
		if (!this.permissionsService.isAdmin) {
			return Promise.resolve();
		}
		const payload: { id: number } = { id: user_id };
		// Retrieve a personal access token for the target user
		return this.apiService
			.post<{ id: number }, { accessToken?: string }>(this.apiService.ep.LOG_IN_AS, payload)
			.toPromise()
			.then((result: { accessToken?: string }): Promise<void> => {
				invalidateLocalStorageCaches();
				if (!result.accessToken) {
					return Promise.resolve();
				}
				// If we are already logged in as someone,
				//  don't inceptionize our backup token,
				//  let's keep the original backup token we created
				if (!this.isLoggedInAsUser) {
					// Create a backup of the original logged in admin
					this.rememberReturnUrl(returnUrl);
					this.createLogInAsTokenBackup(this.permissionsService.isPrismAdmin);
					this.createLogInAsToken(result.accessToken);
					if (forwardUrl === '') {
						this.navigateHomeAndReload(true);
					} else {
						this.navigateAndReload(forwardUrl);
					}
				} else {
					// Log out of the previous session and then swap sessions
					return this.apiService
						.postS(this.apiService.ep.LOG_OUT_AS)
						.toPromise()
						.then((): void => {
							this.createLogInAsToken(result.accessToken);
							if (forwardUrl === '') {
								this.navigateHomeAndReload(true);
							} else {
								this.navigateAndReload(forwardUrl);
							}
						});
				}
				return Promise.resolve();
			});
	}
}
