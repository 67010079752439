import { VariableCostType } from '@prism-frontend//typedefs/enums/VariableCostType';
import { resolveLabel } from '@prism-frontend/typedefs/ems/ems-field-explainer-helpers';
import { EMSFieldsMeta } from '@prism-frontend/typedefs/ems/EMSFieldMeta';
import { CostCalc } from '@prism-frontend/typedefs/enums/calc';
import { Currency } from '@prism-frontend/typedefs/enums/currency';
import { OrderedModel } from '@prism-frontend/typedefs/enums/OrderedModel';
import { PrismEvent } from '@prism-frontend/typedefs/event';
import { Orderable } from '@prism-frontend/typedefs/orderable';
import { Ticket } from '@prism-frontend/typedefs/ticket';
import { getSymbolForCurrency } from '@prism-frontend/utils/static/format-currency';
import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { castToNumber } from '@prism-frontend/utils/transformers/castToNumber';
import { plainToClass, Transform } from 'class-transformer';
import { IsBoolean, IsEnum, IsNumber, IsOptional, IsString } from 'class-validator';
import _, { isNil } from 'lodash';

interface VariableCostInterface {
	id?: number;
	name: string;
	amount: number;
	order: number;
	reported: boolean;
	terms: VariableCostType;
	ticket_id: number | string | null;
	copro_overridden_amount: number;
	copro_cost_hidden: boolean;
}

export class VariableCost implements VariableCostInterface, Orderable {
	public readonly modelName: OrderedModel.VariableCost = OrderedModel.VariableCost;

	public constructor(variableCost?: Partial<VariableCost>) {
		return plainToClass(VariableCost, variableCost);
	}

	@IsNumber() public id?: number;

	@IsNumber()
	@IsOptional()
	public event_id?: number;

	@IsString() public name: string = '';

	@IsNumber() public amount: number = 0;

	@IsNumber() public order: number;

	@IsBoolean()
	@Transform(castToBoolean())
	public reported: boolean = true;

	@IsEnum(VariableCostType)
	public terms: VariableCostType = VariableCostType.PERCENT_OF_NET_GROSS;

	/**
	 * ticket_id may be null for any VariableCostType that is not associated
	 * with a specific ticket tier ticket
	 *
	 * ticket_id will be a UUID (string) when appearing in a template
	 */
	@IsNumber()
	@IsOptional()
	public ticket_id: number | string | null = null;

	@IsNumber()
	@IsOptional()
	@Transform(castToNumber(true))
	public copro_overridden_amount: number | null = null;

	@IsOptional()
	@IsString()
	public copro_last_updated_at: string | null = null;

	@IsBoolean()
	@Transform(castToBoolean())
	public copro_cost_hidden: boolean = false;

	/**
	 * We are spoofing the cost terms field
	 *  to avoid having to use separate fields
	 *  to choose a ticket type, but the back
	 *  end expects a ticket id.
	 */
	public set termsLabel(value: string) {
		// Separate actual terms type from the chosen ticket id
		if (value.indexOf(VariableCostType.FLAT_PER_TICKET_TYPE) !== -1) {
			const parts: string[] = value.split(':');
			this.ticket_id = !isNaN(Number(parts[1])) ? Number(parts[1]) : parts[1];
			this.terms = VariableCostType.FLAT_PER_TICKET_TYPE;
		} else {
			this.terms = <VariableCostType>value;
			this.ticket_id = null;
		}
	}

	/**
	 * Return spoofed value
	 */
	public get termsLabel(): string {
		if (this.terms === VariableCostType.FLAT_PER_TICKET_TYPE) {
			return `${VariableCostType.FLAT_PER_TICKET_TYPE}:${this.ticket_id}`;
		}
		return this.terms;
	}

	/**
	 * Get a display name for this cost for document generation
	 * @param tickets
	 */
	public getDisplayName(tickets: Ticket[], currency: Currency): string {
		const dispName: string = this.name;

		// get amount
		let amountText: string = '';
		switch (this.terms) {
			case VariableCostType.PERCENT_OF_NET_GROSS:
			case VariableCostType.PERCENT_OF_GROSS:
			case VariableCostType.PERCENT_OF_ADJUSTED_GROSS:
				amountText = `${this.amount}%`;
				break;
			case VariableCostType.FLAT_PER_ATTENDEE:
			case VariableCostType.FLAT_PER_TICKET_ANY:
			case VariableCostType.FLAT_PER_TICKET_TYPE:
				amountText = `${getSymbolForCurrency(currency)}${this.amount}`;
				break;
		}

		// get post text
		let postText: string = '';
		switch (this.terms) {
			case VariableCostType.PERCENT_OF_NET_GROSS:
				postText = `of ${_.toLower(resolveLabel(EMSFieldsMeta.netGross))}`;
				break;
			case VariableCostType.PERCENT_OF_ADJUSTED_GROSS:
				postText = `of ${_.toLower(resolveLabel(EMSFieldsMeta.adjustedGross))}`;
				break;
			case VariableCostType.PERCENT_OF_GROSS:
				postText = 'of gross';
				break;
			case VariableCostType.FLAT_PER_ATTENDEE:
				postText = 'per attendee';
				break;
			case VariableCostType.FLAT_PER_TICKET_ANY:
				postText = 'per ticket - all tiers';
				break;
			case VariableCostType.FLAT_PER_TICKET_TYPE:
				const targetTic: Ticket = tickets.find((ticket: Ticket): boolean => {
					return this.ticket_id === ticket.id;
				});

				// this is an error case that is technically possible and
				// currently being aggravated. it should hypothetically never happen
				// as there should never be a case when a variable cost exists on a ticket tier
				// that doesn't exist. its probably a race condition with our front end code that
				// is not waiting for the ticaket delete to completely happen
				if (!targetTic) {
					// console.warn(
					// 	'No ticket tier exists for this variable cost; this is a front-end error that is being triggered by invalid event data'
					// );
					return dispName;
				}

				postText = 'per ticket - ' + targetTic.name;
				break;
		}

		return `${dispName} (${amountText} ${postText})`;
	}

	private getTicketProp(costCalc: CostCalc): 'sold' | 'allotment' | 'est_sold' {
		switch (costCalc) {
			case CostCalc.Reported:
			case CostCalc.Actual:
				return 'sold';
			case CostCalc.Budgeted:
			case CostCalc.Potential:
				return 'allotment';
			case CostCalc.Estimated:
				return 'est_sold';
		}
	}

	// Rename after migration to this method and removal of "budgetedVariableCosts"
	public total(event: PrismEvent, costCalc: CostCalc, external: boolean, computeForCoPro: boolean): number {
		const gross: number = event.gross(costCalc, external);
		const netGross: number = event.netGross(costCalc, external);
		const adjustedGross: number = event.adjustedGross(costCalc, external);
		const attendance: number = event.attendance(costCalc);
		const ticketsSold: number = event.ticketsSold(costCalc);
		const ticketTotal: number = this.parseTicketTotalSold(costCalc, event.tickets, ticketsSold);
		return this.calcTotal({
			ticketTotal,
			netGross,
			adjustedGross,
			gross,
			attendance,
			computeForCoPro,
		});
	}

	/**
	 * Determine What value we should send back for our ticket situation
	 * @param tickets
	 * @param field
	 * @param givenTotal
	 */
	public parseTicketTotalSold(costCalc: CostCalc, tickets: Ticket[], givenTotal?: number): number {
		const field: 'sold' | 'allotment' | 'est_sold' = this.getTicketProp(costCalc);
		if (this.terms === VariableCostType.FLAT_PER_TICKET_TYPE) {
			const ticket: Ticket = tickets.find((tic: Ticket): boolean => {
				return tic.id === this.ticket_id;
			});
			if (ticket) {
				return ticket[field];
			}
			return 0; // Ticket might be missing
		}
		return givenTotal;
	}

	/**
	 * Perform actual calculation for the variable cost
	 * use co pro override values if present
	 *
	 * @param ticketTotal the amount of total tickets sold
	 * @param netGross the net amount for the event
	 * @param adjustedGross the adjusted gross amount for the event
	 * @param gross the gross amount for the event
	 * @param attendance the attendance to the event
	 * @param computeForCoPro if we should consider co pro specific cost to override values
	 */
	public calcTotal(options: {
		ticketTotal: number;
		netGross: number;
		adjustedGross: number;
		gross: number;
		attendance: number;
		computeForCoPro?: boolean;
	}): number {
		if (options.computeForCoPro && !isNil(this.copro_overridden_amount)) {
			return this.copro_overridden_amount;
		}

		switch (this.terms) {
			case VariableCostType.PERCENT_OF_NET_GROSS:
				return Number((this.amount / 100) * options.netGross);
			case VariableCostType.PERCENT_OF_ADJUSTED_GROSS:
				return Number((this.amount / 100) * options.adjustedGross);
			case VariableCostType.PERCENT_OF_GROSS:
				return Number((this.amount / 100) * options.gross);
			case VariableCostType.FLAT_PER_ATTENDEE:
				return Number(this.amount * options.attendance);
			case VariableCostType.FLAT_PER_TICKET_ANY:
			case VariableCostType.FLAT_PER_TICKET_TYPE:
				return Number(this.amount * options.ticketTotal);
		}
	}
}

export class VariableCostTableRow extends VariableCost {
	public emsPath: string;
	public isCostTypeChangeRequestPending?: boolean;
}
