export class OfferSettings {
	public subtitle: boolean | number = true;
	public generic_deal_text: boolean | number = false;
	public show_expenses: boolean | number = true;
	public total_expenses: boolean | number = true;
	public net_potential: boolean | number = true;
	public cost_summary: boolean | number = true;
	public cost_per_item: boolean | number = true;
	public show_header_city_name: boolean | number = true;
	public logo_max_height: number | null = 0;
	public display_holds: boolean | number = true;
	public terms_bottom: boolean | number = true;
	public show_prism_logo: boolean | number = true;
	public show_comps: boolean | number = true;
	public show_breakeven: boolean | number = true;
	public show_promoter_profit: boolean | number = true;
	public show_total_artist_earnings: boolean | number = true;
	public display_attendance_on_settlement: boolean | number = true;
	public display_tax_rate_if_zero: boolean | number = true;
	public display_expenses_if_zero: boolean | number = true;
	public hide_kills: boolean | number = false;
	public hide_scaling_on_simple: boolean | number = true;
	public hide_budget_and_variance_on_settlement_costs: boolean | number = false;
	public event_summary_below_variable_expenses: boolean | number = false;
	public display_bonus_percentage: boolean | number = false;
	public show_event_details_on_simple_docs: boolean | number = false;
	public hide_ticket_price_on_ticket_table: boolean | number = false;
	public hide_per_ticket_fees_on_ticket_table: boolean | number = false;
	public hide_net_price_on_ticket_table: boolean | number = false;
	// advance_ prefixed settings are only honored in advance documents
	public advance_show_summary: boolean | number = true;
	public advance_show_estimated_attendance: boolean | number = true;
	public advance_show_additional_dates: boolean | number = true;
	public advance_show_ticket_scaling: boolean | number = true;
	public advance_show_ros: boolean | number = true;
	public advance_op_show_bar_minimum: boolean | number = true;
	public advance_show_net_potential_instead_of_expenses: boolean | number = true;
	// optional because it does not come from the db, it is a front end only prop that defaults to false
	public display_ems_props?: boolean | number = false;
	public profit_and_loss_line: boolean | number = false;
	public begin_event_summary_at_adjusted_gross: boolean | number = false;
}
