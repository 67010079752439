import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@prism-frontend/../environments/environment';
import {
	HTTPDeleteOptions,
	HTTPPatchOptions,
	HTTPPostOptions,
	HTTPPutOptions,
} from '@prism-frontend/services/legacy/http-request-param-types';
import { SpinnerService } from '@prism-frontend/services/utils/spinner.service';
import {
	InstantiatedAPIResponseAndValidationResults,
	validateClassInstances,
} from '@prism-frontend/utils/decorators/validate-children';
import { ApiConfig } from '@prism-frontend/utils/static/app.api.config';
import { plainToClass } from 'class-transformer';
import { ValidatorOptions } from 'class-validator';
import { Observable, firstValueFrom } from 'rxjs';
import { finalize } from 'rxjs/operators';

export interface StandardApiResponse {
	status: 'ok' | unknown;
}
export interface StandardApiResponse2 {
	success: boolean;
}
export interface StandardAPIIDPostPayload {
	id: number;
}
@Injectable({
	providedIn: 'root',
})
export class ApiService {
	public isExternal: boolean = false;

	public constructor(
		public http: HttpClient,
		public spinner: SpinnerService,
		// endpoints
		public ep: ApiConfig
	) {}

	public get apiUrl(): string {
		return '/' + environment.api_path;
	}

	/**
	 * Sends a POST request to a backend endpoint AND shows a full-page spinner.
	 * Do not use this method. Use postP instead, and thoughtfully include a
	 * spinner in your ui component's context.
	 * @deprecated
	 * @param endpoint the endpoint to send the POST request to
	 * @param data parameters to pass to the endpoint
	 * @param options http POST options
	 * @returns Observable on the endpoint's response
	 */
	public post<ParamType, ReturnType>(
		endpoint: string | (string | number)[],
		data?: ParamType,
		options?: Omit<HTTPPostOptions, 'responseType'>
	): Observable<ReturnType> {
		this.spinner.addRequest();
		return this.intercept<ReturnType>(
			true,
			this.http.post(
				this.compileUrl(endpoint),
				data,
				options as unknown as HTTPPostOptions
			) as unknown as Observable<ReturnType>
		);
	}

	/**
	 * Sends a POST request to a backend endpoint.
	 * Do not use this method. Use postP instead.
	 * @deprecated
	 * @param endpoint the endpoint to send the POST request to
	 * @param data parameters to pass to the endpoint
	 * @param options http POST options
	 * @returns Observable on the endpoint's response
	 */
	public postS<ParamType, ReturnType>(
		endpoint: string | (string | number)[],
		data?: ParamType,
		options?: Omit<HTTPPostOptions, 'responseType'>
	): Observable<ReturnType> {
		return this.intercept<ReturnType>(
			false,
			this.http.post(
				this.compileUrl(endpoint),
				data,
				options as unknown as HTTPPostOptions
			) as unknown as Observable<ReturnType>
		);
	}

	/**
	 * Sends a POST request to a backend endpoint and returns a Promise for
	 * the endpoint's response. Does not show a full-page spinner to the user.
	 * When hitting the API from a UI interaction, take care to thoughtfully
	 * include a spinner or toast in your ui component's context while requests
	 * are going out. When loading data for a table or page, also take time to
	 * include contextually relevant spinners.
	 * @param endpoint the endpoint to send the POST request to
	 * @param data parameters to pass to the endpoint
	 * @param options http POST options
	 * @throws if the endpoint returns a non-200 code. You should catch and handle
	 *   errors. If you don't they will get surfaced as a generic error to the user.
	 * @returns Promise for the response from the endpoint
	 */
	public postP<ParamType, ReturnType>(
		endpoint: string | (string | number)[],
		data?: ParamType,
		options?: Omit<HTTPPostOptions, 'responseType'>
	): Promise<ReturnType> {
		return firstValueFrom(this.postS(endpoint, data, options));
	}

	/**
	 * Sends a DELETE request to a backend endpoint.
	 * Do not use this method. Use deleteP instead.
	 * @deprecated
	 * @param endpoint the endpoint to send the DELETE request to
	 * @param options http DELETE options
	 * @returns Observable on the endpoint's response
	 */
	public deleteS<ReturnType>(
		endpoint: string | (string | number)[],
		options?: Omit<HTTPDeleteOptions, 'responseType'>
	): Observable<ReturnType> {
		return this.intercept<ReturnType>(
			false,
			this.http.delete(
				this.compileUrl(endpoint),
				options as unknown as HTTPDeleteOptions
			) as unknown as Observable<ReturnType>
		);
	}

	/**
	 * Sends a DELETE request to a backend endpoint and returns a Promise for
	 * the endpoint's response. Does not show a full-page spinner to the user.
	 * When hitting the API from a UI interaction, take care to thoughtfully
	 * include a spinner or toast in your ui component's context while requests
	 * are going out. When loading data for a table or page, also take time to
	 * include contextually relevant spinners.
	 * @param endpoint the endpoint to send the DELETE request to
	 * @param options http DELETE options
	 * @throws if the endpoint returns a non-200 code. You should catch and handle
	 *   errors. If you don't they will get surfaced as a generic error to the user.
	 * @returns Promise for the response from the endpoint
	 */
	public deleteP<ReturnType>(
		endpoint: string | (string | number)[],
		options?: Omit<HTTPDeleteOptions, 'responseType'>
	): Promise<ReturnType> {
		return firstValueFrom(this.deleteS(endpoint, options));
	}

	/**
	 * Sends a PATCH request to a backend endpoint AND shows a full-page spinner.
	 * Do not use this method. Use patchP instead.
	 * @deprecated
	 * @param endpoint the endpoint to send the PATCH request to
	 * @param data parameters to pass to the endpoint
	 * @param options http PATCH options
	 * @returns Observable on the endpoint's response
	 */
	public patch<ParamType, ReturnType>(
		endpoint: string | (string | number)[],
		data?: ParamType,
		options?: Omit<HTTPDeleteOptions, 'responseType'>
	): Observable<ReturnType> {
		this.spinner.addRequest();
		return this.intercept<ReturnType>(
			true,
			this.http.patch(
				this.compileUrl(endpoint),
				data,
				options as unknown as HTTPDeleteOptions
			) as unknown as Observable<ReturnType>
		);
	}

	/**
	 * Sends a PATCH request to a backend endpoint.
	 * Do not use this method. Use patchP instead.
	 * @deprecated
	 * @param endpoint the endpoint to send the PATCH request to
	 * @param data parameters to pass to the endpoint
	 * @param options http PATCH options
	 * @returns Observable on the endpoint's response
	 */
	public patchS<ParamType, ReturnType>(
		endpoint: string | (string | number)[],
		data?: ParamType,
		options?: Omit<HTTPPatchOptions, 'responseType'>
	): Observable<ReturnType> {
		return this.intercept<ReturnType>(
			false,
			this.http.patch(
				this.compileUrl(endpoint),
				data,
				options as unknown as HTTPPatchOptions
			) as unknown as Observable<ReturnType>
		);
	}

	/**
	 * Sends a PATCH request to a backend endpoint and returns a Promise for
	 * the endpoint's response. Does not show a full-page spinner to the user.
	 * When hitting the API from a UI interaction, take care to thoughtfully
	 * include a spinner or toast in your ui component's context while requests
	 * are going out. When loading data for a table or page, also take time to
	 * include contextually relevant spinners.
	 * @param endpoint the endpoint to send the PATCH request to
	 * @param data any params you need to pass to the endpoint
	 * @param options http PATCH options
	 * @throws if the endpoint returns a non-200 code. You should catch and handle
	 *   errors. If you don't they will get surfaced as a generic error to the user.
	 * @returns Promise for the response from the endpoint
	 */
	public patchP<ParamType, ReturnType>(
		endpoint: string | (string | number)[],
		data?: ParamType,
		options?: Omit<HTTPDeleteOptions, 'responseType'>
	): Promise<ReturnType> {
		return firstValueFrom(this.patchS(endpoint, data, options));
	}

	/**
	 * Sends a PUT request to a backend endpoint AND shows a full-page spinner.
	 * Do not use this method. Use putP instead.
	 * @deprecated
	 * @param endpoint the endpoint to send the PUT request to
	 * @param data parameters to pass to the endpoint
	 * @param options http PUT options
	 * @returns Observable on the endpoint's response
	 */
	public put<ParamType, ReturnType>(
		endpoint: string | (string | number)[],
		data?: ParamType,
		options?: Omit<HTTPPutOptions, 'responseType'>
	): Observable<ReturnType> {
		this.spinner.addRequest();
		return this.intercept<ReturnType>(
			true,
			this.http.put(
				this.compileUrl(endpoint),
				data,
				options as unknown as HTTPPutOptions
			) as unknown as Observable<ReturnType>
		);
	}

	/**
	 * Sends a PUT request to a backend endpoint.
	 * Do not use this method. Use putP instead.
	 * @deprecated
	 * @param endpoint the endpoint to send the PUT request to
	 * @param data parameters to pass to the endpoint
	 * @param options http PUT options
	 * @returns Observable on the endpoint's response
	 */
	public putS<ParamType, ReturnType>(
		endpoint: string | (string | number)[],
		data?: ParamType,
		options?: Omit<HTTPPutOptions, 'responseType'>
	): Observable<ReturnType> {
		return this.intercept<ReturnType>(
			false,
			this.http.put(
				this.compileUrl(endpoint),
				data,
				options as unknown as HTTPPutOptions
			) as unknown as Observable<ReturnType>
		);
	}

	/**
	 * Sends a PUT request to a backend endpoint and returns a Promise for
	 * the endpoint's response. Does not show a full-page spinner to the user.
	 * When hitting the API from a UI interaction, take care to thoughtfully
	 * include a spinner or toast in your ui component's context while requests
	 * are going out. When loading data for a table or page, also take time to
	 * include contextually relevant spinners.
	 * @param endpoint the endpoint to send the PUT request to
	 * @param data any params you need to pass to the endpoint
	 * @param options http PUT options
	 * @throws if the endpoint returns a non-200 code. You should catch and handle
	 *   errors. If you don't they will get surfaced as a generic error to the user.
	 * @returns Promise for the response from the endpoint
	 */
	public putP<ParamType, ReturnType>(
		endpoint: string | (string | number)[],
		data?: ParamType,
		options?: Omit<HTTPPutOptions, 'responseType'>
	): Promise<ReturnType> {
		return firstValueFrom(this.putS(endpoint, data, options));
	}

	/**
	 * Sends a GET request to a backend endpoint AND shows a full-page spinner.
	 * Do not use this method. Use getP instead.
	 * @deprecated
	 * @param endpoint the endpoint to send the GET request to
	 * @param data parameters to pass to the endpoint
	 * @returns Observable on the endpoint's response
	 */
	public get<ParamType, ReturnType>(
		endpoint: string | (string | number)[],
		params?: ParamType
	): Observable<ReturnType> {
		this.spinner.addRequest();
		return this.intercept<ReturnType>(
			true,
			this.http.get(this.compileUrl(endpoint), params) as unknown as Observable<ReturnType>
		);
	}

	/**
	 * Sends a GET request to a backend endpoint.
	 * Do not use this method. Use getP instead.
	 * @deprecated
	 * @param endpoint the endpoint to send the GET request to
	 * @param data parameters to pass to the endpoint
	 * @returns Observable on the endpoint's response
	 */
	public getS<ParamType, ReturnType>(
		endpoint: string | (string | number)[],
		params?: ParamType
	): Observable<ReturnType> {
		return this.intercept<ReturnType>(
			false,
			this.http.get(this.compileUrl(endpoint), params) as unknown as Observable<ReturnType>
		);
	}

	/**
	 * Sends a GET request to a backend endpoint and returns a Promise for
	 * the endpoint's response. Does not show a full-page spinner to the user.
	 * When hitting the API from a UI interaction, take care to thoughtfully
	 * include a spinner or toast in your ui component's context while requests
	 * are going out. When loading data for a table or page, also take time to
	 * include contextually relevant spinners.
	 * @param endpoint the endpoint to send the GET request to
	 * @param data any params you need to pass to the endpoint
	 * @throws if the endpoint returns a non-200 code. You should catch and handle
	 *   errors. If you don't they will get surfaced as a generic error to the user.
	 * @returns Promise for the response from the endpoint
	 */
	public getP<ParamType, ReturnType>(
		endpoint: string | (string | number)[],
		params?: ParamType
	): Promise<ReturnType> {
		return firstValueFrom(this.getS(endpoint, params));
	}

	private intercept<ReturnType>(hadSpinnerRequest: boolean, obs: Observable<ReturnType>): Observable<ReturnType> {
		return obs.pipe(
			finalize((): void => {
				return this.handleFinish(hadSpinnerRequest);
			})
		);
	}

	private handleFinish(hadSpinnerRequest: boolean): void {
		if (!hadSpinnerRequest) {
			return;
		}
		this.spinner.removeRequest();
	}

	public compileUrl(endpoint: string | (string | number)[]): string {
		let theUrl: string = '';
		if (!this.isExternal) {
			theUrl += this.apiUrl;
		}
		if (typeof endpoint === 'string') {
			theUrl += endpoint;
		} else {
			// Note: we are assuming the first value passed to any call to apiService is a string
			// we have been doing this the whole time, we just now have to type this as a result of
			// tighter TS build steps
			const template: string = <string>endpoint.shift();
			theUrl += this.ep.compileString(template, ...endpoint);
		}
		return theUrl;
	}
}

/**
 * Converts a list of plain objects (or a single object) to class instance(s) using `plainToClass`,
 * then validates class instance(s) using `class-validator` and returns validation results as InstantiatedAPIResponseAndValidationResults(s)
 */
export async function instantiateApiResponse<T>(
	// reference to a class itself
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ClassConstructor: any,
	items: object | object[],
	validatorOptions?: ValidatorOptions
): Promise<InstantiatedAPIResponseAndValidationResults<T>> {
	if (!items) {
		throw new Error('instantiateApiResponse(): API response is empty');
	}
	const data: T = plainToClass(ClassConstructor, items);
	return validateClassInstances(data, validatorOptions);
}
