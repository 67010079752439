import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestCacheService } from '@prism-frontend/services/legacy/request-cache.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class CachingInterceptor<T> implements HttpInterceptor {
	public constructor(private requestCacheService: RequestCacheService<T>) {}

	public intercept(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
		// Continue if not cacheable.
		if (!this.requestCacheService.isCacheable(req)) {
			if (this.requestCacheService.isLoggingActive()) {
				// eslint-disable-next-line no-console
				console.log('%c dont-cache', 'color: purple; font-weight: bold', req.urlWithParams);
			}
			return next.handle(req);
		}

		const cachedResponse: HttpResponse<T> | undefined = this.requestCacheService.get(req);
		return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next, this.requestCacheService);
	}

	/**
	 * Get server response observable by sending request to `next()`.
	 * Will add the response to the cache on the way out.
	 */
	private sendRequest(
		req: HttpRequest<T>,
		next: HttpHandler,
		requestCacheService: RequestCacheService<T>
	): Observable<HttpEvent<T>> {
		return next.handle(req).pipe(
			tap((event: HttpEvent<T>): void => {
				// There may be other events besides the response.
				if (event instanceof HttpResponse && (<HttpResponse<T>>event).status < 300) {
					requestCacheService.set(req, event); // Update the cache.
				}
			})
		);
	}
}
