import { RENDER_AS_EMPTY } from '@prism-frontend/components/ems-field/ems-field.typedefs';
import { AllSimpleFormFieldInputTypes } from '@prism-frontend/components/simple-form-field/simple-form-field.typedefs';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';
import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { Transform } from 'class-transformer';
import { IsBoolean, IsNumber, IsString } from 'class-validator';
import _ from 'lodash';

/**
 * Base class from which a few other CustomFields classes/interfaces inherit.
 * Contains common properties that exist across the other interfaces.
 */
class CustomFieldBase {
	/**
	 * The name for the custom field, as input by the user in the custom field form
	 */
	@IsString() public name: string;

	/**
	 * The description for the custom field, as input by the user in the custom field form
	 */
	@IsString() public description: string;
	/**
	 * This id is mostly irrelevant. If we are dealing with
	 * an org-level custom field, it will be the same as custom_field_id.
	 * If we are dealing with an event-level custom field, the field's event-level
	 * id is not super relevant. For the most part, we only care about the field's
	 * identity at the org level.
	 */
	@IsNumber() public id: number;

	/**
	 * This is the relevant id for the custom field.
	 * If we are dealing with an org-level custom field, it will be the same as id.
	 * If we are dealing with an event-level custom field, it will be the id of the
	 * org-level custom field that the event-level custom field is overriding.
	 */
	@IsNumber() public custom_field_id: number;

	/**
	 * The id for the organization that this custom field pertains to
	 */
	@IsNumber() public organization_id: number;
	/**
	 * The type of the custom field, as set by the user at the time of creation.
	 * Note that once a custom field is created with a certain type, it cannot be
	 * changed.
	 */
	@IsString() public type: AllAmbiguousCustomFieldTypes;

	/**
	 * Indicates if the custom field is an advanced custom field.
	 * NOTE that there are only two types of advanced custom fields. All other
	 *      custom field types should never be flagged as is_advanced. This is
	 *      undefined behavior.
	 * 	1. textarea
	 * 	2. number
	 * Initially, we did not have this boolean, and the type field was flattened,
	 * and such ambiguity was not possible. In the front end, we still use the
	 * flattened type. Use flattenCustomFieldType when you need to map a custom
	 * field to a flat type, which will be unique.
	 */
	@IsBoolean() @Transform(castToBoolean()) public is_advanced: boolean;
}

/**
 * Represents a custom field that has been defined at the org level.
 */
export class CustomFieldBackend extends CustomFieldBase {
	@IsString()
	public default_value: string;

	/**
	 * This property is only relevant when creating a simple custom field
	 * (that is not of type date).
	 *
	 * We do not store or read/display this property after the custom field
	 * is created. This property is present for supporting the CustomFieldFormComponent.
	 */
	@IsBoolean() public apply_all_events?: boolean;
}

/**
 * Represents a custom field that has had a specific event-level value set.
 * This is the object that is returned from the backend as part of the PrismEvent
 * model's custom_fields property.
 */
export class CustomFieldEventBackend extends CustomFieldBackend {
	/**
	 * The id of the event that this custom field's event_value is associated with.
	 * In practice, we only load CustomFieldEventValues within a PrismEvent model,
	 * so this event_id is effectively superfulous information, since we can always
	 * glean it from the parent PrismEvent object.
	 * I assume that its presence is an implementation detail on the backend rather
	 * than a necessary component for the Prism UI.
	 */
	@IsNumber() public event_id: number;
	/**
	 * The value that has been set for this custom field for this event.
	 * Note that this is the event level value, as stored in the database.
	 * If the custom field is of type advanced, the backend will never
	 * actually send it in the event level custom_fields array, since
	 * the value for advanced custom fields is computed using the advanced
	 * custom fields formula (as defined in its default_value property), which
	 * is treated as a template containing data chips.
	 */
	@IsString() public event_value: string;
}

/**
 * This is derived from the AllCustomFieldConfigs type union below. It represents
 * all possible custom field types that we might receive from the backend.
 *
 * **DO NOT USE THIS TO DIFFERENTIATE BETWEEN CUSTOM FIELDS** These types are
 * ambiguous. If you need to differentiate between custom fields, use AllCustomFieldTypes,
 * and determine the AllCustomFieldTypes for a given custom field using the flattenCustomFieldType
 * function.
 */
export type AllAmbiguousCustomFieldTypes = AllCustomFieldConfigs['type'];

type BasicCustomFieldTypes = 'toggle' | 'text' | 'textarea' | 'number' | 'date' | 'currency_amount';

export type AdvancedCustomFieldTypes =
	| 'advanced_computed'
	| 'advanced_computed_currency'
	| 'advanced_string_template'
	| 'advanced_editable';

/**
 * This is the unambiguous list of custom field types that we use in the front end
 * custom field code. The AllCustomFieldTypes for any given custom field is derived
 * from the field's is_advanced,type tuple, and is done so by flattenCustomFieldType.
 *
 * **When you are working with custom fields in the front end, and need to disambiguate
 * them by type (to know how to format, display, etc.), you should use this interface.**
 */
export type AllCustomFieldTypes = BasicCustomFieldTypes | AdvancedCustomFieldTypes;

/**
 * In PRSM-9178 the backend team migrated custom field's data model so that
 * the type field is now ambiguous. We must now refer to both type AND is_advanced
 * in order to know how we need to treat a given custom field. Much of the front
 * end code was already relient on their being a single, unambigous property
 * on a custom field for determining the type.
 *
 * Use this flattenCustomFieldType or the expandCustomFieldType methods
 * to move between the two formats.
 *
 * @param field
 * @returns
 */
export function flattenCustomFieldType(field: CustomFieldBackend | CustomFieldBase): AllCustomFieldTypes {
	if (!field.type) {
		return null;
	}
	if (field.is_advanced) {
		switch (field.type) {
			case 'editable':
				return 'advanced_editable';
			case 'textarea':
				return 'advanced_string_template';
			case 'number':
				return 'advanced_computed';
			case 'currency_amount':
				return 'advanced_computed_currency';
			default:
				throw new Error(`Invalid advanced custom field type: ${field.type}`);
		}
	}
	switch (field.type) {
		case 'toggle':
		case 'text':
		case 'textarea':
		case 'number':
		case 'date':
		case 'currency_amount':
			return field.type;
		default:
			throw new Error(`Invalid !advanced custom field type: ${field.type}`);
	}
}

export function expandCustomFieldType(type: AllCustomFieldTypes): Pick<CustomFieldBackend, 'type' | 'is_advanced'> {
	if (type.startsWith('advanced_')) {
		switch (type) {
			case 'advanced_string_template':
				return {
					type: 'textarea',
					is_advanced: true,
				};
			case 'advanced_computed':
				return {
					type: 'number',
					is_advanced: true,
				};
			case 'advanced_computed_currency':
				return {
					type: 'currency_amount',
					is_advanced: true,
				};
			case 'advanced_editable':
				return {
					type: 'editable',
					is_advanced: true,
				};
			default:
				throw new Error(`Invalid advanced custom field type: ${type}`);
		}
	}
	switch (type) {
		case 'toggle':
		case 'text':
		case 'textarea':
		case 'number':
		case 'date':
		case 'currency_amount':
			return {
				type: type,
				is_advanced: false,
			};
		default:
			throw new Error(`Invalid !advanced custom field type: ${type}`);
	}
}

/**
 * This represents the type union of all possible custom fields. This is represents
 * the normalized, and scrubbed custom field format, AFTER the front end does its
 * processing. Namely, there are two differences between this type and the
 * CustomFieldBackend type:
 * 		1. the default_value and event_value properties are typed properly on this type
 * 		   whereas in CustomFieldBackend, all values are of type string
 * 		2. each individual entry in this type union has a distinct type property
 */
export type AllCustomFieldConfigs =
	| CustomFieldToggle
	| CustomFieldText
	| CustomFieldTextArea
	| CustomFieldNumber
	| CustomFieldDate
	| CustomFieldCurrencyAmount
	| CustomFieldAdvancedComputed
	| CustomFieldAdvancedComputedCurrency
	| CustomFieldAdvancedStringTemplate
	| CustomFieldAdvancedComputedEditable;

/**
 * Represents a custom field of type toggle, AFTER the front end has cast
 * the string value that is returned for default_value and event_value
 */
class CustomFieldToggle extends CustomFieldBase {
	public override is_advanced: false;
	public override type: 'toggle';
	public default_value: boolean;
	public event_value?: boolean;
}

/**
 * Represents a custom field of type text.
 */
class CustomFieldText extends CustomFieldBase {
	public override is_advanced: false;
	public override type: 'text';
	public default_value: string;
	public event_value?: string;
}

/**
 * Represents a custom field of type textarea. Identical to CustomFieldText,
 * except that the text input is a multi-line textarea, and the values
 * are allowed to break on to multiple lines in table cells
 */
class CustomFieldTextArea extends CustomFieldBase {
	public override is_advanced: false;
	public override type: 'textarea';
	public default_value: string;
	public event_value?: string;
}

/**
 * Represents a custom field of type number, AFTER the front end has cast
 * the string value that is returned for default_value and event_value
 */
class CustomFieldNumber extends CustomFieldBase {
	public override is_advanced: false;
	public override type: 'number';
	public default_value: number;
	public event_value?: number;
}

/**
 * Represents a custom field of type date, AFTER the front end has cast
 * the string value that is returned for default_value and event_value
 */
class CustomFieldDate extends CustomFieldBase {
	public override is_advanced: false;
	public override type: 'date';
	public default_value: moment.Moment;
	public event_value?: moment.Moment;
}

/**
 * Represents a custom field of type number, AFTER the front end has cast
 * the string value that is returned for default_value and event_value
 */
class CustomFieldCurrencyAmount extends CustomFieldBase {
	public override is_advanced: false;
	public override type: 'currency_amount';
	public default_value: number;
	public event_value?: number;
}

/**
 * Represents a custom field of type advanced_computed
 */
class CustomFieldAdvancedComputed extends CustomFieldBase {
	public override is_advanced: true;
	public override type: 'number';
	public default_value: string;
	public event_value?: string | number | boolean;
}

/**
 * Represents a custom field of type advanced_currency_amount
 */
class CustomFieldAdvancedComputedCurrency extends CustomFieldBase {
	public override is_advanced: true;
	public override type: 'currency_amount';
	public default_value: string;
	public event_value?: string | number | boolean;
}

/**
 * Represents a custom field of type advanced_text_template
 */
class CustomFieldAdvancedStringTemplate extends CustomFieldBase {
	public override is_advanced: true;
	public override type: 'textarea';
	public default_value: string;
	public event_value?: string;
}

/**
 * Represents a custom field of type editable
 */
class CustomFieldAdvancedComputedEditable extends CustomFieldBase {
	public override is_advanced: true;
	public override type: 'editable';
	public default_value: string;
	public event_value?: string | number | boolean;
}

/**
 * Used by CustomFieldsFormComponrnt for rendering options in the typ epicker.
 * Right now, this form is used to _view_ advanced custom fields, but not
 * _edit_ them (you can edit name and description, just not the formula).
 * This is subject to change in PRSM-9220, where we may expose an advanced
 * toggle in the UI.
 * Until then the custom fields form component uses this flattened map in order to
 * populate the suggestions into the form, based on the type of custom field it
 * was passed (when editing), or if creating, then it will always filter to
 * display only simple custom field types here.
 */
export const CustomValueSuggestionsMap: {
	[key in AllCustomFieldTypes]: { label: CustomFieldTypeLabels; value: AllAmbiguousCustomFieldTypes };
} = {
	text: { label: 'Text', value: 'text' },
	textarea: { label: 'Text Area', value: 'textarea' },
	number: { label: 'Number', value: 'number' },
	currency_amount: { label: 'Currency', value: 'currency_amount' },
	toggle: { label: 'Toggle', value: 'toggle' },
	date: { label: 'Date', value: 'date' },
	// NOTE THAT THE VALUES MATCH ARE THE BACKEND, AMBIGUOUS VALUES.
	// THIS IS WHAT THE CUSTOM FIELDS FORM COMPONENT EXPECTS
	advanced_computed: { label: 'Computed Value', value: 'number' },
	advanced_computed_currency: { label: 'Computed Currency Value', value: 'currency_amount' },
	advanced_string_template: { label: 'Templated Text', value: 'textarea' },
	advanced_editable: { label: 'Editable EMS Field', value: 'editable' },
};

/**
 * The backend returns two types of custom field objects, depending on the context.
 * (1) within the org level list of custom fields
 * (2) within the event level list of custom fields
 * In both cases, the data model is very similar, so the front-end code handles
 * both via the same set of helpers. Sometimes, we need to differentiate between
 * an org level or an event level custom field object. This method allows us
 * to do so.
 * @param field
 * @returns
 */
export function isCustomFieldForEvent(
	field: CustomFieldBackend | CustomFieldEventBackend
): field is CustomFieldEventBackend {
	return !_.isUndefined((field as CustomFieldEventBackend).event_id);
}

/**
 * Used when calling scrubCustomFields to tell it which type of custom fields to
 * process when calling.
 *
 * This is necessary becasue we allow advanced custom fields to reference
 * basic custom fields, and so we need to process basic custom fields first.
 *
 * We never call this method and process only `advanced` custom fields, which
 * is why that is omitted as an option here.
 */
export type CustomFieldProcessType = 'all' | 'basic';

/**
 * Represents the types of custom fields that represent numeric values. Useful
 * when generating a list of fields to display in field sandbox–as sometimes
 * you want to ensure that users are only able to select numerical properties.
 */
export const NUMBER_CUSTOM_FIELDS: Set<AllCustomFieldTypes> = new Set<AllCustomFieldTypes>([
	'number',
	'currency_amount',
]);

const SimpleCustomFieldTypeToSimpleFormFieldTypeMap: {
	[key in AllCustomFieldTypes]: AllSimpleFormFieldInputTypes;
} = {
	text: 'text',
	textarea: 'textarea',
	number: 'number',
	currency_amount: 'number',
	toggle: 'boolean',
	date: 'date',
	advanced_computed: 'markdown',
	advanced_computed_currency: 'markdown',
	advanced_string_template: 'markdown',
	advanced_editable: 'markdown',
};

/**
 * Maps custom field (flattened) types to the field type that we use in simple
 * form when allowing users to customize these fields. Note that there are different
 * mappings that get used in EventCustomValues form, where users are not editing
 * formulas for advanced custom fields, but instead simply viewing their resolved values.
 */
export function customFieldTypeToSimpleFormFieldType(
	field: CustomFieldBackend | CustomFieldEventBackend
): AllSimpleFormFieldInputTypes {
	return SimpleCustomFieldTypeToSimpleFormFieldTypeMap[flattenCustomFieldType(field)];
}

/**
 * Utility object representing a totally new custom field. Useful for CustomFieldForm
 * and other contexts where we are spinning up a brand new custom field via the UI.
 */
export const EMPTY_CUSTOM_FIELD: CustomFieldBackend = {
	name: '',
	description: '',
	type: undefined,
	is_advanced: false,
	default_value: '',
	id: null,
	custom_field_id: null,
	organization_id: null,
};

/**
 * Bookkeeping interface that is useful when you want to map your custom fields
 * by their id.
 */
export type CustomFieldsById<T extends CustomFieldBase = CustomFieldBackend> = Record<number, T>;

type BasicCustomFieldTypesLabels = 'Text' | 'Text Area' | 'Number' | 'Currency' | 'Toggle' | 'Date';

export type AdvancedCustomFieldTypesLabels =
	| 'Computed Value'
	| 'Computed Currency Value'
	| 'Templated Text'
	| 'Editable EMS Field';

type CustomFieldTypeLabels = BasicCustomFieldTypesLabels | AdvancedCustomFieldTypesLabels;

/**
 * Determines the label for a custom field of a certain type. Useful in components
 * like Custom Field Form, where we display a type picker, or in Custom Fields Table
 * where we display a column representing the type for each custom field.
 */
const BASIC_CUSTOM_FIELD_TYPE_LABELS: Record<BasicCustomFieldTypes, BasicCustomFieldTypesLabels> = {
	number: 'Number',
	toggle: 'Toggle',
	text: 'Text',
	textarea: 'Text Area',
	date: 'Date',
	currency_amount: 'Currency',
};

export const ADVANCE_CUSTOM_FIELD_TYPE_LABELS: Record<AdvancedCustomFieldTypes, AdvancedCustomFieldTypesLabels> = {
	advanced_computed: 'Computed Value',
	advanced_computed_currency: 'Computed Currency Value',
	advanced_string_template: 'Templated Text',
	advanced_editable: 'Editable EMS Field',
};

export const CUSTOM_FIELD_TYPE_LABELS: Record<AllCustomFieldTypes, CustomFieldTypeLabels> = {
	...BASIC_CUSTOM_FIELD_TYPE_LABELS,
	...ADVANCE_CUSTOM_FIELD_TYPE_LABELS,
};

const CUSTOM_FIELD_KEY_PREFIX: string = 'custom-field--';

/**
 * Generates a unique key for a given custom field. Simply put, the key is
 * the string "custom-field--" followed by the custom field's (org level) id.
 * These kays are used when we need to store custom fields in things like
 * column sets, or other custom fields.
 *
 * @param field CustomField object whose key you wish to generate
 * @returns unique string to be used as a key for a custom field
 */
export function customFieldKey(customField: CustomFieldBackend | AllCustomFieldConfigs): string {
	return `${CUSTOM_FIELD_KEY_PREFIX}${customField.custom_field_id}`;
}

export function editableCustomFieldEMSCostCalc(value: string): CostCalc2 {
	if (value.indexOf('.') === -1) {
		return CostCalc2.ExternalReported;
	}
	return value.split('.')[0] as CostCalc2;
}

export function editableCustomFieldEMSPath(value: string): string {
	if (value === RENDER_AS_EMPTY) {
		return value;
	}
	const divider: number = value.indexOf('.');
	if (divider === -1) {
		return '';
	}
	const path: string = value.slice(value.indexOf('.') + 1);
	return path;
}
