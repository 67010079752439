import { Injectable } from '@angular/core';
import { PrismStrings } from '@prism-frontend/utils/static/common-user-facing-strings/interface-for-strings';
import { PRISM_STRINGS_EN_US } from '@prism-frontend/utils/static/common-user-facing-strings/strings-en-us';

@Injectable({
	providedIn: 'root',
})
export class PrismStringsService {
	public strings: PrismStrings = PRISM_STRINGS_EN_US;
}
