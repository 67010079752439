export enum CostCalc2 {
	ExternalBudgeted = 'ExternalBudgeted',
	ExternalEstimated = 'ExternalEstimated',
	// only when confirmed
	ExternalReported = 'ExternalReported',
	// only when confirmed
	InternalActual = 'InternalActual',
	InternalPotential = 'InternalPotential',
	InternalEstimated = 'InternalEstimated',
}
