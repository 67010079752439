import { findAdditionalRevenueByName } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/findAdditionalRevenueByName';
import { findAndFormatContactsWithRole } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/findAndFormatContactsWithRole';
import { findFixedCostByName } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/findCostByName';
import { findFixedCostGroupByName } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/findCostGroupByName';
import { getDaysUntilEventFunctionalChipFactory } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/getDaysUntilEvent';
import { FunctionalChip } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/FunctionalChip';
import _ from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AllFunctionalChips: FunctionalChip<any>[] = [
	findAdditionalRevenueByName,
	findFixedCostGroupByName,
	findFixedCostByName,
	findAndFormatContactsWithRole,
	getDaysUntilEventFunctionalChipFactory(true),
];

const allFuncNames: string[] = _.map(AllFunctionalChips, 'identifier');
const allFunctionCallsRegexString: string = `${allFuncNames
	.map((f: string): string => {
		return `${f}\\([^)]+\\)`;
	})
	.join('|')}`;
export const AllFunctionalChipCallsRegex: RegExp = new RegExp(allFunctionCallsRegexString, 'g');
