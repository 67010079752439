import { Injectable } from '@angular/core';
import { VERSION } from '@prism-frontend/app.version';
import { LocalStorageKeys } from '@prism-frontend/services/legacy/LocalStorageKeys';
import { StorageService } from '@prism-frontend/services/legacy/storage.service';

interface AuthHeaders {
	Authorization: string;
	'App-Version': string;
}

@Injectable({
	providedIn: 'root',
})
export class UserTokenStorageService {
	public constructor(private storageService: StorageService) {}

	public get hasToken(): boolean {
		return !!this.getToken();
	}

	public setToken(token: string): void {
		this.storageService.setItem(LocalStorageKeys.USER_TOKEN_KEY, token);
	}

	public getToken(): string {
		return this.storageService.getItem(LocalStorageKeys.USER_TOKEN_KEY);
	}

	public killToken(): void {
		this.storageService.removeItem(LocalStorageKeys.USER_TOKEN_KEY);
	}

	public authHeaders(): AuthHeaders {
		const headers: Partial<AuthHeaders> = {
			'App-Version': VERSION.version,
		};
		if (this.getToken()) {
			headers.Authorization = `Bearer ${this.getToken()}`;
		}
		return headers as AuthHeaders;
	}
}
