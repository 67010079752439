import { DocumentNode, gql } from '@apollo/client/core';
import { Currency } from '@prism-frontend/typedefs/enums/currency';
import { FullInvoiceFragment } from '@prism-frontend/typedefs/graphql/Invoice';
import { InvoiceOrReceipt } from '@prism-frontend/typedefs/invoice';

export const SettleUpMutation: DocumentNode = gql`
	mutation SettleUp(
		$org_invoice_id: Int!
		$talent_agent_id: Int!
		$amount: Float!
		$artist_payout_towards_agent_commission: Float!
		$agent_commission_payout: Float!
		$agency_received_total_payments: Float!
		$remaining_agent_artist_balance: Float!
		$artist_show_balance: Float!
		$payment_status: String!
		$generated_pdf_file: String!
		$file_name: String!
		$currency: String!
		$payments: [TalentDealBalancePaymentInput]
		$invoice_terms: String
		$adjustments_total: Float!
		$included_invoice_adjustments: [Int]!
	) {
		settleUp(
			org_invoice_id: $org_invoice_id
			talent_agent_id: $talent_agent_id
			amount: $amount
			artist_payout_towards_agent_commission: $artist_payout_towards_agent_commission
			agent_commission_payout: $agent_commission_payout
			agency_received_total_payments: $agency_received_total_payments
			remaining_agent_artist_balance: $remaining_agent_artist_balance
			artist_show_balance: $artist_show_balance
			payment_status: $payment_status
			generated_pdf_file: $generated_pdf_file
			file_name: $file_name
			currency: $currency
			payments: $payments
			invoice_terms: $invoice_terms
			adjustments_total: $adjustments_total
			included_invoice_adjustments: $included_invoice_adjustments
		) {
			...FullInvoiceFragment
		}
	}
	${FullInvoiceFragment}
`;

export interface TalentDealBalancePaymentInput {
	dealId: number;
	amount: number;
	isDealFullySettled: boolean;
	exchange_rate_usd: number;
}

export type SettleUpArguments = Pick<
	InvoiceOrReceipt,
	| 'talent_agent_id'
	| 'org_invoice_id'
	| 'amount'
	| 'artist_payout_towards_agent_commission'
	| 'agent_commission_payout'
	| 'agency_received_total_payments'
	| 'remaining_agent_artist_balance'
	| 'artist_show_balance'
	| 'payment_status'
	| 'invoice_terms'
> & {
	generated_pdf_file: string;
	file_name: string;
	currency: Currency;
	payments: TalentDealBalancePaymentInput[];
	adjustments_total: number;
	included_invoice_adjustments: number[];
};

export interface SettleUpResponse {
	settleUp: InvoiceOrReceipt;
}
