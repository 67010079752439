import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'capitalize',
})
export class CapitalPipe implements PipeTransform {
	public transform(value: string): string {
		if (value) {
			return value.charAt(0).toUpperCase() + value.slice(1);
		}
		return value;
	}
}
