/* This module is documented in `docs/feature-gating.md` */
import { Injectable } from '@angular/core';
import { OrgSettingsService } from '@prism-frontend/services/legacy/api/org-settings/org-settings.service';
import { isEnvironmentLower } from '@prism-frontend/typedefs/environment-checkers';
import { ENV_FEATURE, ORG_FEATURE } from '@prism-frontend/typedefs/features.class';
import { errorDebug } from '@prism-frontend/utils/static/getDebug';
import * as _ from 'lodash';

export interface PDFFeatureGateFlags {
	isConnectedShowEnabled?: boolean;
	isContractDueEnabled?: boolean;
	isBroadway?: boolean;
	isOfferContractTerms?: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class FeatureGateService {
	public constructor(private orgSettingsSvc: OrgSettingsService) {}

	public shouldConsiderLocalStorageForFeature(feature: ENV_FEATURE | ORG_FEATURE): boolean {
		if (!isEnvironmentLower()) {
			return false;
		}
		// if the feature has ANY value in localStorage, we will honor that over the org flag
		return !!localStorage.getItem(feature);
	}
	private getFeatureLocalStorageValue(feature: ENV_FEATURE | ORG_FEATURE): boolean | null {
		if (!isEnvironmentLower()) {
			return null;
		}
		// check local storage for feature flag and return true if its parsed value
		// is truthy, false otherwise
		try {
			const localStorageFlag: unknown = JSON.parse(localStorage.getItem(feature));
			if (localStorageFlag === null) {
				return null;
			}
			return !!localStorageFlag;
		} catch (e) {
			errorDebug(`failed to parse feature ${feature} value from localStorage`, localStorage.getItem(feature));
			localStorage.removeItem(feature);
			return false;
		}
	}

	public isFeatureEnabled(feature: ENV_FEATURE): boolean {
		if (this.shouldConsiderLocalStorageForFeature(feature)) {
			const localStorageValue: boolean | null = this.getFeatureLocalStorageValue(feature);
			if (localStorageValue !== null) {
				// eslint-disable-next-line no-console
				console.warn(
					`!!!!!!!! value for feature ${feature}: ${localStorageValue} was pulled from localStorage !!!!!!!!!`
				);
				// eslint-disable-next-line no-console
				console.log(`!!!!!!!! revert with \`localStorage.removeItem('${feature}')\` in the console !!!!!!!!!`);
				return localStorageValue;
			}
		}

		return _.some(this.orgSettingsSvc.envFeatures, ['name', feature]);
	}

	public orgHasFeature(feature: ORG_FEATURE): boolean {
		if (this.shouldConsiderLocalStorageForFeature(feature)) {
			const localStorageValue: boolean | null = this.getFeatureLocalStorageValue(feature);
			if (localStorageValue !== null) {
				// eslint-disable-next-line no-console
				console.warn(
					`!!!!!!!! value for feature ${feature}: ${localStorageValue} was pulled from localStorage !!!!!!!!!`
				);
				// eslint-disable-next-line no-console
				console.log(`!!!!!!!! revert with \`localStorage.removeItem('${feature}')\` in the console !!!!!!!!!`);
				return localStorageValue;
			}
		}
		// Check to see if org has proper feature permissions
		return _.some(this.orgSettingsSvc.orgFeatures, ['name', feature]);
	}

	/**
	 * @deprecated
	 * Use OrganizationTypeGateService instead.
	 * TODO PRSM-7109 drop isAgent usage on FeatureGateService
	 */
	public get isAgent(): boolean {
		return this.orgHasFeature('IS_AGENT');
	}

	public get shouldRenderConnectedShowLink(): boolean {
		// only render the link for orgs that have the LINKED_EVENTS feature flag
		return this.orgHasFeature('LINKED_EVENTS');
	}

	public get pdfFeatureGateFlags(): PDFFeatureGateFlags {
		return {
			isConnectedShowEnabled: this.shouldRenderConnectedShowLink,
			isContractDueEnabled: this.isFeatureEnabled('CONTRACT_DUE'),
			isOfferContractTerms: this.isFeatureEnabled('OFFER_CONTRACT_TERMS'),
		};
	}
}
