import { DocumentNode, gql } from '@apollo/client/core';
import { PartnerAdjustment } from '@prism-frontend/typedefs/partner-deal';

const partnerDealMutationArguments: string = `
	$amount: Float!,
	$name: String!,
    $notes: String,
`;

const partnerDealMutationArgumentMapping: string = `
	amount: $amount
	name: $name
    notes: $notes
`;

export const PartnerDealAdjustmentFragment: DocumentNode = gql`
	fragment PartnerDealAdjustmentProps on PartnerDealAdjustment {
		id
		name
		amount
		notes
	}
`;

export const CreatePartnerDealAdjustmentMutation: DocumentNode = gql`
    mutation CreatePartnerDealAdjustment(
        $partner_deal_id: Int!,
        ${partnerDealMutationArguments}
    ) {
        createPartnerDealAdjustment(
            partner_deal_id: $partner_deal_id
            ${partnerDealMutationArgumentMapping}
        ) {
            ...PartnerDealAdjustmentProps
        }
    }
    ${PartnerDealAdjustmentFragment}
`;

export const UpdatePartnerDealAdjustmentMutation: DocumentNode = gql`
    mutation UpdatePartnerDealAdjustment(
        $id: Int!,
        ${partnerDealMutationArguments}
    ) {
        updatePartnerDealAdjustment(
            id: $id
            ${partnerDealMutationArgumentMapping}
        ) {
            ...PartnerDealAdjustmentProps
        }
    }
    ${PartnerDealAdjustmentFragment}
`;

export const DeletePartnerDealAdjustmentMutation: DocumentNode = gql`
	mutation DeletePartnerDealAdjustment($id: Int!) {
		deletePartnerDealAdjustment(id: $id)
	}
`;

export interface CreatePartnerDealAdjustmentMutationResponse {
	createPartnerDealAdjustment: PartnerAdjustment;
}

export interface UpdatePartnerDealMutationResponse {
	updatePartnerDealAdjustment: PartnerAdjustment;
}

export interface DeletePartnerDealMutationResponse {
	deletePartnerDealAdjustment: number;
}
