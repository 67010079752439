import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@prism-frontend/../environments/environment';

@Pipe({
	name: 'cdnUrl',
})
export class CdnUrlPipe implements PipeTransform {
	private cdnBaseUrl: string = environment.cdn_base_url || 'https://static.prism.fm/dev/';

	public transform(value: string): string {
		return `${this.cdnBaseUrl}${value}`;
	}
}
