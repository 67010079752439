import { OrderedModel } from '@prism-frontend//typedefs/enums/OrderedModel';

// Only allowed sort group keys
export type OrderableKey = 'event_id' | 'cost_group_id';

// Map the orderable model types to the sort group keys
export const OrderedKeyMap: { [key in OrderedModel]: OrderableKey } = {
	[OrderedModel.VariableCost]: 'event_id',
	[OrderedModel.CostItem]: 'cost_group_id',
	[OrderedModel.AdditionalRevenue]: 'event_id',
	[OrderedModel.Ticket]: 'event_id',
};

export interface Orderable {
	readonly modelName: OrderedModel;
	id?: number | string | undefined;
	order?: number;
}

export interface OrderChangeItem {
	id: number | string;
	order: number;
}

export interface OrderChangePayload {
	model: OrderedModel;
	items: OrderChangeItem[];
	key: OrderableKey;
	keyId: number;
}
