import { Venue } from '@prism-frontend/typedefs/venue';
import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { plainToClass, Transform, Type } from 'class-transformer';
import { IsBoolean, IsHexColor, IsInt, IsOptional, IsString, Matches, ValidateNested } from 'class-validator';

const dateTimePattern: RegExp = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/;

export interface StageMap {
	[key: number]: Stage;
}

export class Stage {
	public constructor(stage: Partial<Stage>) {
		return plainToClass(Stage, stage);
	}

	@IsBoolean()
	@Transform(castToBoolean())
	public active: boolean;

	@IsInt() public capacity: number;

	@IsBoolean()
	@Transform(castToBoolean())
	public can_share_holds: boolean;

	@IsBoolean()
	@Transform(castToBoolean())
	public is_shared_host: boolean;

	@IsHexColor() public color: string;

	@IsOptional()
	@IsString()
	@Matches(dateTimePattern)
	public created_at: string;

	@IsInt() public id: number;

	@IsString() public name: string;

	@IsInt() public venue_id: number;

	@IsOptional()
	@IsInt()
	public organization_id?: number;

	@IsOptional()
	@IsString()
	@Matches(dateTimePattern)
	public updated_at: string;

	@IsOptional()
	@Type((): typeof Stage => {
		return Stage;
	})
	@ValidateNested()
	// will be defined if this stage is linked with a partner
	public host_stage?: Stage;

	// only used on the front end:
	// TODO PRSM-4141 decorate these? why are these frnot-end only?
	// some are unused, i dont see `max_hold` anywhere in the code base
	public checked?: boolean;
	public label?: string;
	public value?: number;
	public max_hold?: number;
	public venue?: Venue;
}
