import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApolloError } from '@apollo/client/errors';
import {
	AddCustomFieldToEventMutation,
	AddCustomFieldToEventMutationResponse,
	AddCustomFieldToEventMutationVariables,
	CreateCustomFieldMutation,
	CreateCustomFieldMutationResponse,
	DeleteCustomFieldMutation,
	DeleteCustomFieldMutationResponse,
	PaginatedListCustomFieldsQuery,
	UpdateCustomFieldMutation,
	UpdateCustomFieldMutationResponse,
} from '@prism-frontend/entities/custom-fields/custom-fields-graphql/CustomFieldsQuery';
import {
	AllCustomFieldConfigs,
	CustomFieldBackend,
	CustomFieldEventBackend,
	CustomFieldsById,
} from '@prism-frontend/entities/custom-fields/custom-fields-typedefs';
import { stringifyCustomValue } from '@prism-frontend/entities/custom-fields/static/castCustomValue';
import { ContactRoleGraphqlService } from '@prism-frontend/services/api/graphql/contact-role-graphql.service';
import { PrismGraphqlService } from '@prism-frontend/services/api/graphql/prism-graphql.service';
import { TourService } from '@prism-frontend/services/api/tour.service';
import { AdminModeService } from '@prism-frontend/services/ui/admin-mode.service';
import { FeatureGateService } from '@prism-frontend/services/utils/feature-gate.service';
import { EMSHydrationAddons, EMSRollup } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { Tour } from '@prism-frontend/typedefs/tour';
import { normalizeEventCustomFieldValues } from '@prism-frontend/utils/static/custom-fields/normalizeEventCustomFieldValues';
import { scrubCustomFields } from '@prism-frontend/utils/static/custom-fields/scrubCustomFields';
import { OnPageCallback, queryAllEntities } from '@prism-frontend/utils/static/query-all-entities';
import { dumbPluralize } from '@prism-frontend/utils/static/strings';
import { GraphQLErrorExtensions } from 'graphql';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

/**
 * Data sent by the BE under a custom field delete error
 * due to contrains on the DB with other custom fields
 */
interface CustomFieldErrorData {
	/**
	 * the error message
	 */
	message: string[];
	/**
	 * the custom field id intended to be deleted
	 */
	custom_field_id: number[];
	/**
	 * the list of custom fields ids that have references
	 * to the custom field intended to be deleted. This
	 * format is wonky and should be flatten to just an
	 * array of numbers
	 */
	edt_ids: number[][];
}

export const CUSTOM_FIELD_REFERENCE_ERROR: string = 'CUSTOM_FIELD_REFERENCE_ERROR';

@Injectable()
export class CustomFieldsGraphqlService {
	public customFields$: BehaviorSubject<CustomFieldsById> = new BehaviorSubject<CustomFieldsById>([]);

	public constructor(
		private adminModeService: AdminModeService,
		private contactRoleGraphQlService: ContactRoleGraphqlService,
		private matSnackBar: MatSnackBar,
		private prismGraphqlService: PrismGraphqlService,
		private tourService: TourService,
		private featureGateService: FeatureGateService
	) {}

	public async listCustomFields(
		onPageCallback?: OnPageCallback<CustomFieldBackend>,
		abortSignal?: AbortSignal
	): Promise<CustomFieldsById> {
		const customFields: CustomFieldBackend[] = await this.listUnscrubbedCustomFields(onPageCallback, abortSignal);
		const currentCustomFields: CustomFieldsById = scrubCustomFields(customFields) as CustomFieldsById;
		this.customFields$.next(currentCustomFields);
		return currentCustomFields;
	}

	/**
	 * NEVER make this function public. We never want any code, outside of this service
	 * to have to access the unscrubbed custom fields.
	 * @param onPageCallback
	 * @param abortSignal
	 * @returns
	 */
	private async listUnscrubbedCustomFields(
		onPageCallback?: OnPageCallback<CustomFieldBackend>,
		abortSignal?: AbortSignal
	): Promise<CustomFieldBackend[]> {
		const customFields: CustomFieldBackend[] = await queryAllEntities<{}, CustomFieldBackend>(
			this.prismGraphqlService,
			{},
			PaginatedListCustomFieldsQuery,
			'customFieldList',
			CustomFieldBackend,
			onPageCallback,
			abortSignal,
			true
		);
		return customFields;
	}

	/**
	 * Fetches a complete EMSHydrationAddons object to be passed as the second
	 * argument to fetchEMSRollup. The object returned by this function will
	 * cause fetchEMSRollup to populate both tourName AND the customFields array
	 * on the resulting EMSRollup.
	 * @returns EMSHydrationAddons to be passed as the second argument to fetchEMSRollup
	 */
	public async fetchHydrationAddons(): Promise<EMSHydrationAddons> {
		// TODO PRSM-9345 hit endpoint instead
		const tours: Tour[] = this.tourService.getAll();
		const toursById: Record<number, Pick<Tour, 'name'>> = _.chain(tours)
			.keyBy('id')
			.mapValues((tour: Tour): Pick<Tour, 'name'> => {
				return _.pick(tour, 'name');
			})
			.value();
		const hydrationAddons: EMSHydrationAddons = {
			customFieldData: {
				orgCustomFields: await this.listUnscrubbedCustomFields(),
				isAdminModeEnabled: this.adminModeService.adminMode,
				rolesById: await this.contactRoleGraphQlService.fetchContactRolesById(),
				isBroadway: this.featureGateService.orgHasFeature('IS_BROADWAY'),
			},
			toursById,
		};
		return hydrationAddons;
	}

	/**
	 * @deprecated because we could pdate EventCustomValues form to work with
	 * EMSCustomField data model
	 * @param emsRollup
	 * @param eventCustomValuesList
	 * @returns
	 */
	public async fetchAndNormalizeEventCustomFieldValues(
		emsRollup: EMSRollup,
		eventCustomValuesList: CustomFieldEventBackend[]
	): Promise<CustomFieldsById<AllCustomFieldConfigs>> {
		const hydrationAddons: EMSHydrationAddons = await this.fetchHydrationAddons();
		return normalizeEventCustomFieldValues(emsRollup, eventCustomValuesList, hydrationAddons);
	}

	public async createCustomField(field: CustomFieldBackend): Promise<CustomFieldBackend> {
		this.matSnackBar.open(`Creating Custom Field: ${field.name}...`);
		return this.prismGraphqlService
			.mutateAndIWillHandleTheErrorMyself<CustomFieldBackend, CreateCustomFieldMutationResponse>({
				mutation: CreateCustomFieldMutation,
				variables: {
					...field,
					default_value: stringifyCustomValue(field.default_value, field),
					apply_all_events: !!field.apply_all_events,
				},
			})
			.then((response: CreateCustomFieldMutationResponse): CustomFieldBackend => {
				this.matSnackBar.dismiss();
				this.matSnackBar.open(`Successfully created Custom Field: ${field.name}`, undefined, {
					duration: 2500,
				});
				return response.createCustomField;
			})
			.catch((e: unknown): CustomFieldBackend => {
				this.matSnackBar.dismiss();
				// throw for downstream code to handle the error
				throw e;
			});
	}

	public async updateCustomField(field: CustomFieldBackend): Promise<CustomFieldBackend> {
		this.matSnackBar.open(`Saving Custom Field: ${field.name}...`);
		return this.prismGraphqlService
			.mutateAndIWillHandleTheErrorMyself<CustomFieldBackend, UpdateCustomFieldMutationResponse>({
				mutation: UpdateCustomFieldMutation,
				variables: {
					...field,
					default_value: stringifyCustomValue(field.default_value, field),
				},
			})
			.then((response: UpdateCustomFieldMutationResponse): CustomFieldBackend => {
				this.matSnackBar.dismiss();
				this.matSnackBar.open(`Successfully saved Custom Field: ${field.name}`, undefined, {
					duration: 2500,
				});
				return response.updateCustomField;
			})
			.catch((e: unknown): CustomFieldBackend => {
				this.matSnackBar.dismiss();
				// throw for downstream code to handle the error
				throw e;
			});
	}

	public async deleteCustomField(field: CustomFieldBackend): Promise<number> {
		this.matSnackBar.open(`Deleting Custom Field: ${field.name}...`);
		return this.prismGraphqlService
			.mutateAndIWillHandleTheErrorMyself<Pick<CustomFieldBackend, 'id'>, DeleteCustomFieldMutationResponse>({
				mutation: DeleteCustomFieldMutation,
				variables: {
					id: field.id,
				},
			})
			.then((response: DeleteCustomFieldMutationResponse): number => {
				this.matSnackBar.dismiss();
				this.matSnackBar.open(`Successfully deleted Custom Field: ${field.name}`, undefined, {
					duration: 2500,
				});
				return response.deleteCustomField;
			})
			.catch((error: ApolloError): number => {
				this.matSnackBar.dismiss();
				// grab the graphQL mutation error data that BE sends
				const errorExtension: GraphQLErrorExtensions = error.graphQLErrors[0]?.extensions;
				if (!errorExtension) {
					this.prismGraphqlService.showGraphQLError();
					throw error;
				}
				const errorData: CustomFieldErrorData = errorExtension['validation'] as CustomFieldErrorData;
				if (!errorData) {
					this.prismGraphqlService.showGraphQLError();
					throw error;
				}
				throw new Error(CUSTOM_FIELD_REFERENCE_ERROR, {
					cause: this.getCustomFieldDeleteErrorMessage(field, errorData),
				});
			});
	}

	private getCustomFieldDeleteErrorMessage(field: CustomFieldBackend, data: CustomFieldErrorData): string {
		const allCustomFields: CustomFieldsById = this.customFields$.value;
		// grab the associated list of custom fields related to the error
		const referencedCustomFields: CustomFieldBackend[] = _.chain(data.edt_ids)
			.flatten()
			.map((custom_field_id: number): CustomFieldBackend => {
				return allCustomFields[custom_field_id];
			})
			.filter((customField: CustomFieldBackend): boolean => {
				return !_.isNil(customField);
			})
			.value();
		// concat their names on an html list
		const referencedCustomFieldsList: string = _.chain(referencedCustomFields)
			.map((customField: CustomFieldBackend): string => {
				return `<li>${customField.name}</li>`;
			})
			.value()
			.join('\n');
		// determine some singluar/plural strings
		const fieldString: string = dumbPluralize('Field', referencedCustomFields.length);
		const thisString: string = referencedCustomFields.length > 1 ? 'these' : 'this';
		// arrange the html message to show the user
		return `<p>
				You tried to delete <b>${field.name}</b>, however this field is
				referenced in ${thisString} Enhanced Data ${fieldString}:
			</p>
			\n\n<ul>${referencedCustomFieldsList}</ul>\n\n
			<p>
				Please reach out to <a href="mailto:support@prism.fm" target="_blank">support@prism.fm</a>
				to either update or remove the Enhanced Data ${fieldString} to resolve ${thisString}
				${dumbPluralize('reference', referencedCustomFields.length)} and allow for deletion.
			</p>`;
	}

	public async updateCustomFieldOnEvent(
		eventId: number,
		field: CustomFieldBackend,
		newValue: string
	): Promise<CustomFieldEventBackend> {
		const variables: AddCustomFieldToEventMutationVariables = {
			eventId,
			customFieldId: field.custom_field_id,
			eventValue: newValue,
		};
		return this.prismGraphqlService
			.mutate<AddCustomFieldToEventMutationVariables, AddCustomFieldToEventMutationResponse>({
				mutation: AddCustomFieldToEventMutation,
				variables,
			})
			.then((response: AddCustomFieldToEventMutationResponse): CustomFieldEventBackend => {
				this.matSnackBar.dismiss();
				this.matSnackBar.open(`Successfully saved value for: ${field.name}`, undefined, {
					duration: 2500,
				});
				return response.addCustomFieldToEvent;
			})
			.catch((e: unknown): CustomFieldEventBackend => {
				this.matSnackBar.dismiss();
				// throw for downstream code to handle the error
				throw e;
			});
	}
}
