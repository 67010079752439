import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '@prism-frontend/components/confirm-dialog/confirm-dialog.component';
import { EventDealSummary } from '@prism-frontend/components/talent-deal-summary/talent-deal-summary.component';
import { AbstractListService } from '@prism-frontend/services/api/list-services/abstract-list.service';
import { ApiService, instantiateApiResponse } from '@prism-frontend/services/legacy/api.service';
import { StorageService } from '@prism-frontend/services/legacy/storage.service';
import { PrismTitleService } from '@prism-frontend/services/ui/prism-title.service';
import { SpinnerService } from '@prism-frontend/services/utils/spinner.service';
import { PrismEvent, PrismEventInterface } from '@prism-frontend/typedefs/event';
import { TalentData } from '@prism-frontend/typedefs/talentData';
import { Tour, TourInterface } from '@prism-frontend/typedefs/tour';
import { TourTicket } from '@prism-frontend/typedefs/tour-ticket';
import { InstantiatedAPIResponseAndValidationResults } from '@prism-frontend/utils/decorators/validate-children';
import { classToPlain } from 'class-transformer';
import _ from 'lodash';
import moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class TourService extends AbstractListService<Tour> {
	public constructor(
		public snackBar: MatSnackBar,
		protected override apiService: ApiService,
		protected override spinner: SpinnerService,
		protected override storageService: StorageService,
		private dialog: MatDialog,
		private prismTitleService: PrismTitleService,
		private router: Router
	) {
		super(apiService, spinner, storageService);
	}

	public override save(tour: Tour): Promise<Tour> {
		let request: Promise<TourInterface>;
		const data: TourInterface = <TourInterface>classToPlain(tour);

		if (!tour.id) {
			// Create new -> POST request
			request = this.apiService.postP<TourInterface, TourInterface>(this.apiService.ep.TOURS, data);
		} else {
			// Update existing -> PATCH request
			request = this.apiService.patchP([this.apiService.ep.TOUR, tour.id], tour);
		}

		return request
			.then((response: TourInterface): Promise<InstantiatedAPIResponseAndValidationResults<Tour>> => {
				return instantiateApiResponse<Tour>(Tour, response);
			})
			.then((results: InstantiatedAPIResponseAndValidationResults<Tour>): Tour => {
				return results.data;
			})
			.then((savedTour: Tour): Tour => {
				const allTours: Tour[] = this.all$.value;
				// Remove saved tour draft from the list to prevent duplicates
				_.pull(allTours, tour);
				this._all$.next([savedTour, ...allTours]);
				this.refreshListFromAPI();
				return savedTour;
			});
	}

	public deleteTour(tourId: number): Promise<boolean> {
		const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
			data: {
				heading: 'Are you sure you want to delete this tour?',
				message: 'If you delete this tour, all events within the tour will be deleted.',
				cancelButtonCaption: 'Cancel',
				confirmButtonCaption: 'Delete',
				extraClassesConfirm: 'danger-button',
			},
		});

		return dialogRef
			.afterClosed()
			.toPromise()
			.then(async (result: boolean): Promise<boolean> => {
				if (!result) {
					return false;
				}
				await this.deleteById(tourId);
				const tourName: string = this.prismTitleService.getProvidedData('tourName');
				this.snackBar.open(`${tourName} tour has been deleted`, null, {
					duration: 1500,
				});
				this.router.navigate(['/']);
				return true;
			});
	}

	public async loadEventsForTour(tourId: number): Promise<PrismEvent[]> {
		return this.apiService
			.getP([this.apiService.ep.TOUR_EVENTS, tourId])
			.then(async (events: PrismEventInterface[]): Promise<PrismEvent[]> => {
				const results: InstantiatedAPIResponseAndValidationResults<PrismEvent[]> = await instantiateApiResponse<
					PrismEvent[]
				>(PrismEvent, events);
				if (!results.data) {
					return [];
				}
				return _.sortBy(results.data, (event: PrismEvent): moment.Moment => {
					return moment(event.calculated_start_date);
				});
			});
	}

	public async loadTicketsForTour(tourId: number): Promise<TourTicket[]> {
		/**
		 * Adding the format parameter to the endpoint will return the tour event ticket data
		 * as a property of the tour ticket instead of as an element in the array. This is better
		 * suited for the tour tickets simple table.
		 * E.g.: with format=named-keys:
		 * 	{
		 *		"name": "*New Ticket*",
		 *		"potential_gross": 0,
		 *		...
		 *		"event${Id}": {
		 *			"event_id": 1002879,
		 *			"ticket_id": 1528084,
		 *      	"ticket_price": 0,
		 *			...
		 *  	}
		 * 	}
		 * without format=named-keys:
		 * 	{
		 *		"name": "*New Ticket*",
		 *		"potential_gross": 0,
		 *		...
		 *		"events": [
		 *			{
		 *				"event_id": 1002879,
		 *				"ticket_id": 1528084,
		 *      		"ticket_price": 0,
		 *				...
		 *  		}
		 * 		]
		 * 	}
		 */
		const url: string = `${this.apiService.ep.TOUR_TICKETS}?format=named-keys`;
		const response: { data: TourTicket[] } = await this.apiService.getP<unknown, { data: TourTicket[] }>([
			url,
			tourId,
		]);
		const results: InstantiatedAPIResponseAndValidationResults<TourTicket[]> = await instantiateApiResponse<
			TourTicket[]
		>(TourTicket, response.data);
		return results.data;
	}

	public async fetchTourTalents(tourId: number): Promise<EventDealSummary> {
		return this.apiService
			.getP([this.apiService.ep.TOUR_TALENT, tourId])
			.then(async (data: EventDealSummary): Promise<EventDealSummary> => {
				data.dealTerms = <TalentData[]>(await instantiateApiResponse(TalentData, data.dealTerms)).data;
				return data;
			});
	}

	protected get endpointUrl(): string {
		return this.apiService.ep.TOURS;
	}

	protected get ClassConstructor(): typeof Tour {
		return Tour;
	}
}
