import { AllSimpleFormFieldConfigs } from '@prism-frontend/components/simple-form/simple-form.typedefs';
import {
	escapeDataInterpolationWidgets,
	unescapeDataInterpolationWidgets,
} from '@prism-frontend/entities/email-templates/data-interpolation/escapeDataInterpolationWidgets';
import { InterpolatedDataOption } from '@prism-frontend/entities/email-templates/data-interpolation/InterpolatedDataOption';
import { interpolateValuesIntoString } from '@prism-frontend/entities/email-templates/data-interpolation/interpolateValuesIntoString';
import { SIMPLE_PROPERTY_INTERPOLATOR } from '@prism-frontend/entities/email-templates/data-interpolation/interpolators/SIMPLE_PROPERTY_INTERPOLATOR';
import {
	FunctionalChip,
	FunctionalChipFunctionResult,
	FunctionalChipOrgData,
} from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/FunctionalChip';
import { FunctionalChipAdditionalData } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/FunctionalChipAdditionalData';
import { PrismEventContact } from '@prism-frontend/typedefs/contact';
import { ContactRole } from '@prism-frontend/typedefs/ContactRole';
import { EMSFieldCategory } from '@prism-frontend/typedefs/ems/EMSFieldMeta';
import { dumbPluralize } from '@prism-frontend/utils/static/strings';
import _ from 'lodash';

interface FindAndFormatContactsByRoleArg {
	role: string;
	format: string;
}

interface FindAndFormatContactsByRoleFormObj {
	role: ContactRole[];
	format: string;
}

const availableContactFields: InterpolatedDataOption[] = [
	{
		label: 'Name',
		description: 'The name of the contact',
		identifier: 'contact.name',
		category: 'Contact Details' as EMSFieldCategory,
	},
	{
		label: 'Phone Number',
		description: 'The phone number for the contact',
		identifier: 'contact.formattedPhoneNumber',
		category: 'Contact Details' as EMSFieldCategory,
	},
	{
		label: 'Email Address',
		description: 'The email address for the contact',
		identifier: 'contact.email',
		category: 'Contact Details' as EMSFieldCategory,
	},
	{
		label: 'Address',
		description: 'The address for the contact',
		identifier: 'contact.concatenatedAddressFields',
		category: 'Contact Details' as EMSFieldCategory,
	},
	{
		label: 'Roles',
		description: 'A list of all of the contacts roles',
		identifier: 'contact.rolesString',
		category: 'Contact Details' as EMSFieldCategory,
	},
	{
		label: 'Company',
		description: 'The contacts company',
		identifier: 'contact.company',
		category: 'Contact Details' as EMSFieldCategory,
	},
	{
		label: 'Notes',
		description: 'The contacts notes',
		identifier: 'contact.notes',
		category: 'Contact Details' as EMSFieldCategory,
	},
];

export const findAndFormatContactsWithRole: FunctionalChip<
	FindAndFormatContactsByRoleArg,
	FindAndFormatContactsByRoleFormObj
> = {
	identifier: 'findAndFormatContactsWithRole',
	name: 'Find & Format Contacts with Role',
	fallbackValue: (): string => {
		return ``;
	},
	mapFormValueForStoring: (value: FindAndFormatContactsByRoleFormObj): FindAndFormatContactsByRoleArg => {
		// our for spits out a format containing data interpolation chips
		// we need to escape them now. We will unescape them in our function when it
		// is invoked by mathJS
		const format: string = escapeDataInterpolationWidgets(value.format);
		return {
			...value,
			format,
			role: value.role
				.map((role: ContactRole): number => {
					return role.id;
				})
				.join(','),
		};
	},
	theFunction(
		argument: FindAndFormatContactsByRoleArg,
		orgData: FunctionalChipOrgData,
		data: FunctionalChipAdditionalData
	): FunctionalChipFunctionResult {
		const matchingContacts: PrismEventContact[] = data.event.contacts.filter(
			(contact: PrismEventContact): boolean => {
				return _.some(contact.contact_roles, (role: ContactRole): boolean => {
					return argument.role.indexOf(`${role.id}`) !== -1;
				});
			}
		);
		return matchingContacts
			.map((contact: PrismEventContact): string => {
				const interpolated: string = interpolateValuesIntoString(
					unescapeDataInterpolationWidgets(argument.format),
					{ contact },
					{
						dataChips: availableContactFields,
						functionalChips: [],
					},
					{
						rolesById: {},
						isAdminModeEnabled: false,
						isBroadway: orgData.isBroadway,
					},
					{
						contact: SIMPLE_PROPERTY_INTERPOLATOR,
					}
				);
				return interpolated;
			})
			.join('\n');
	},
	formFields: (): AllSimpleFormFieldConfigs<FindAndFormatContactsByRoleArg>[] => {
		return [
			{
				label: 'Role',
				description: 'What contact role(s) would you like to match?',
				fieldType: 'contact-role-id',
				key: 'role',
				required: true,
				editable: true,
				allowCreatingNew: false,
			},
			{
				label: 'Format',
				description: 'Template for how each contact should be formatted',
				key: 'format',
				required: true,
				editable: true,
				fieldType: 'markdown',
				editorId: 'contact-format-template',
				availableChips: {
					dataChips: availableContactFields,
					functionalChips: [],
				},
				allowFormatting: false,
				allowImages: false,
				initialValue:
					'$$widget0 (~contact.name~)$$ $$widget0 (~contact.rolesString~)$$ $$widget0 (~contact.email~)$$ $$widget0 (~contact.formattedPhoneNumber~)$$',
			},
		];
	},
	chipText: (value: FindAndFormatContactsByRoleArg, orgData: FunctionalChipOrgData): string => {
		// We handle deleted roles with the filter at the end, but we should
		// likely clear them from the stored custom fields when a role is deleted
		const roles: ContactRole[] = value.role
			.split(',')
			.map(Number)
			.map((id: number): ContactRole => {
				return orgData.rolesById[id];
			})
			.filter(_.identity);
		return `Find and Format Contact ${dumbPluralize('Role', roles.length)}: ${_.map(roles, 'name').join(', ')}`;
	},
};
