import { getExchangeRate } from '@prism-frontend/typedefs/determine-exchange-rate';
import { CostCalc } from '@prism-frontend/typedefs/enums/calc';
import { CollectedBy } from '@prism-frontend/typedefs/enums/CollectedBy';
import { isEventStatusConfirmedOrHigher } from '@prism-frontend/typedefs/enums/event-status';
import { EventPropsForTalentGuarantee } from '@prism-frontend/typedefs/EventPropsForTalentGuarantee';
import { PaymentData } from '@prism-frontend/typedefs/paymentData';
import { DISPLAY_DATE_FORMAT_ABBREVIATED, MOMENT_DATE_FORMAT } from '@prism-frontend/utils/static/dateHelper';
import { getSymbolForCurrency } from '@prism-frontend/utils/static/format-currency';
import { nDaysBeforeEvent } from '@prism-frontend/utils/static/strings';
import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { castToNumber } from '@prism-frontend/utils/transformers/castToNumber';
import { plainToClass, Transform } from 'class-transformer';
import { IsBoolean, IsEnum, IsNumber, IsString } from 'class-validator';
import _ from 'lodash';
import moment from 'moment';

/**
 * The Deposit class represents a deposit payment for an event
 * they can be linked either with a talent or a rental deal
 */
export class Deposit extends PaymentData {
	public constructor(deposit?: Partial<Deposit>) {
		super();
		return plainToClass(Deposit, deposit);
	}

	/**
	 * The id of the talent deal associated with this deposit.
	 * Will be defined on talent events but null on rentals.
	 * This is mutually exclusive with `event_promoter_id`
	 * maps with `event.talent_data[*].id`
	 */
	@IsNumber() public event_talent_id: number | null = null;

	/**
	 * The id of the rental deal associated with this deposit.
	 * Will be defined on rental events but null on talent events.
	 * This is mutually exclusive with `event_talent_id`
	 * maps with `event.promoter_data.id`
	 */
	@IsNumber() public event_promoter_id: number | null = null;

	/**
	 * The defined amount of the deposit. Depending on other configurations
	 * this can be a flat amount or a percentage of the talent guarantee
	 */
	@IsNumber()
	@Transform(castToNumber())
	public amount: number = 0;

	/**
	 * The paid amount of the deposit. Only will have a value when
	 * The status of the deposit is PAID and null otherwise
	 */
	@IsNumber()
	@Transform(castToNumber(true))
	public paid_amount: number | null = null;

	/**
	 * Whether the deposit is a flat amount or a percentage of the talent guarantee
	 */
	@IsBoolean()
	@Transform(castToBoolean())
	public flat_deposit: boolean = true;

	/**
	 * The paid date for the deposit. Only will have a value when
	 * The status of the deposit is PAID and null otherwise
	 */
	@IsString() public paid_date: string | null = null;

	/**
	 * The amount of days before the event the deposit will be due
	 * This will be null when use specific date is true
	 */
	@IsNumber()
	@Transform(castToNumber(true))
	public deposit_days: number | null = 0;

	/**
	 * If the deposit is due on a specific date or a number of days before the event
	 */
	@IsBoolean()
	public use_specific_date: boolean = false;

	/**
	 * The specific date the deposit is due. Only will have a value when
	 * the use specfici date is true
	 */
	@IsString()
	public specific_due_date: string | null = null;

	/**
	 * Override the default collected by value by setting deposits
	 * to be collected by agents by default
	 */
	@IsEnum(CollectedBy)
	public override collected_by: CollectedBy = CollectedBy.Agent;
}

/**
 * A function that returns the total Deposit Amount for a talent guarantee considering
 * the paid amount, type of deposit, and making the correct calculations when having to deal
 * with percentages and currency exchanges
 *
 * @param deposit The deposit data
 * @param convertedGuarantee The event's talent guarantee, in the event's currency
 * @param eventProps The event settings for talent guarantee, containing currency conversions and rates
 * @returns The total deposit amount
 */
export function depositTotal(
	deposit: Deposit,
	convertedGuarantee: number,
	eventProps: EventPropsForTalentGuarantee,
	costCalc: CostCalc
): number {
	if (!_.isNull(deposit.paid_amount)) {
		return deposit.paid_amount;
	}

	return depositRawTotal(deposit, convertedGuarantee, eventProps, costCalc);
}

/**
 * Returns the total Deposit Amount for a talent guarantee considering
 * the type of deposit, and making the correct calculations when having to deal
 * with percentages and currency exchanges. This doesn't take into account
 * whether the deposit has been paid and its amount
 *
 * @param deposit The deposit data
 * @param convertedGuarantee The event's talent guarantee, in the event's currency
 * @param eventProps The event settings for talent guarantee, containing currency conversions and rates
 * @returns The total deposit amount wihout considering paid amount
 */
export function depositRawTotal(
	deposit: Deposit,
	convertedGuarantee: number,
	eventProps: EventPropsForTalentGuarantee,
	costCalc: CostCalc
): number {
	if (deposit.flat_deposit) {
		return deposit.amount;
	}
	let depositAmount: number = (deposit.amount / 100) * convertedGuarantee;
	if (eventProps?.convert_to_usd) {
		depositAmount = depositAmount / getExchangeRate(costCalc, eventProps);
	}
	return depositAmount;
}

/**
 * On the payments table, we render the event currency instead of the artist pay currency
 * this means that we must ensure all deposits are in event currency
 * For percentage deposits this is easy - it just calculates a % of the already-covered value `covertedGuarantee`
 * but for flat deposits, we need to convert the flat number entered in the deal card back into the event
 * currency
 * As an example: if an artist is being payed a flat $100 deposit, but the event currency is in
 * Euros, then that $100 will need to be converted into euros for the payemnts table
 * @param deposit The deposit data
 * @param convertedGuarantee The event's talent guarantee, in the event's currency
 * @param eventProps The event settings for talent guarantee, containing currency conversions and rates
 * @returns The total payment amount
 */
export function paymentTotalForDeposit(
	deposit: Deposit,
	convertedGuarantee: number,
	eventProps: EventPropsForTalentGuarantee,
	costCalc: CostCalc
): number {
	let total: number = depositRawTotal(
		deposit,
		convertedGuarantee,
		{
			...eventProps,

			convert_to_usd: false,
		},
		costCalc
	);
	if (deposit.flat_deposit && eventProps.convert_to_usd) {
		total *= getExchangeRate(costCalc, eventProps);
	}
	return total;
}

export function getDepositSummary(
	deposit: Deposit,
	convertedGuarantee: number,
	eventProps: EventPropsForTalentGuarantee,
	costCalc: CostCalc
): string {
	const total: number = depositTotal(deposit, convertedGuarantee, eventProps, costCalc);
	const totalWithCurrency: string = `${getSymbolForCurrency(eventProps.artistPayCurrency)}${total}`;
	let eventDate: moment.Moment;
	if (eventProps.dateRange.length) {
		eventDate = _.first(eventProps.dateRange);
	}
	// TODO PRSM-7757 unify dueDate = eventDate... codeis
	const dueOrReceived: string = deposit.paid_date ? 'paid' : 'due';
	const collectedBy: string = deposit.collected_by === CollectedBy.Agent ? 'agent' : 'artist';
	const isEventOwnedByAgency: boolean = eventProps.eventOwnedByAgency;
	let collectedByText: string = '';
	if (isEventOwnedByAgency) {
		collectedByText = deposit.paid_date ? ` (collected by ${collectedBy})` : ` (to be collected by ${collectedBy})`;
	}

	const renderRawDate: boolean = isEventStatusConfirmedOrHigher(eventProps.event_status) || deposit.use_specific_date;
	if (renderRawDate) {
		let dueDate: string = '';
		if (eventDate && !deposit.use_specific_date) {
			dueDate = eventDate.clone().subtract(deposit.deposit_days, 'days').format(MOMENT_DATE_FORMAT);
		} else if (deposit.use_specific_date) {
			dueDate = moment(deposit.specific_due_date).format(MOMENT_DATE_FORMAT);
		}
		if (deposit.paid_date) {
			dueDate = moment(deposit.paid_date).format(MOMENT_DATE_FORMAT);
		}
		return `${totalWithCurrency} ${dueOrReceived} on ${moment(dueDate).format(
			DISPLAY_DATE_FORMAT_ABBREVIATED
		)}${collectedByText}`;
	}

	// At this point, we know the deposit is relative AND the event is in hold state
	let relativeDateText: string = nDaysBeforeEvent(deposit.deposit_days);
	if (deposit.deposit_days === 0) {
		relativeDateText = 'day of event';
	}
	if (deposit.paid_date) {
		relativeDateText = `on ${moment(deposit.paid_date).format(MOMENT_DATE_FORMAT)}`;
	}
	return `${totalWithCurrency} ${dueOrReceived} ${relativeDateText}${collectedByText}`;
}
