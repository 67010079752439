import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { LinkDealService } from '@prism-frontend/services/ui/link-deal.service';

/**
 * this should match the variable named in ROUTES `app.routing.module` in
 * for the `link-deal/:dealHash` route
 */
const LINK_EVENT_URL_PARAM: string = 'dealHash';

export const canActivateLinkDeal: CanActivateFn = async (
	route: ActivatedRouteSnapshot,
	_state: RouterStateSnapshot
): Promise<boolean> => {
	const linkDealService: LinkDealService = inject(LinkDealService);
	const router: Router = inject(Router);

	const dealHash: string = currentDealHash(route);
	if (!dealHash) {
		return false;
	}

	try {
		await linkDealService.linkDealGuardCheck(dealHash);
	} catch (e) {
		router.navigate(['/']);
		return false;
	}

	return true;
};

function currentDealHash(route: ActivatedRouteSnapshot): string {
	const currentURLParam: string = route.params[LINK_EVENT_URL_PARAM];
	return currentURLParam || '';
}
