import { Injectable } from '@angular/core';
import { PrismGraphqlService } from '@prism-frontend/services/api/graphql/prism-graphql.service';
import { instantiateApiResponse } from '@prism-frontend/services/legacy/api.service';
import {
	CreateTicketCommissionMutation,
	CreateTicketCommissionMutationResponse,
	CreateTicketCommissionMutationVariables,
	DeleteTicketCommissionMutation,
	DeleteTicketCommissionMutationResponse,
	DeleteTicketCommissionMutationVariables,
	UpdateTicketCommissionMutation,
	UpdateTicketCommissionMutationResponse,
	UpdateTicketCommissionMutationVariables,
} from '@prism-frontend/typedefs/graphql/TicketCommissionQuery';
import { TicketCommission } from '@prism-frontend/typedefs/ticket-commission';
import { InstantiatedAPIResponseAndValidationResults } from '@prism-frontend/utils/decorators/validate-children';

@Injectable({
	providedIn: 'root',
})
export class TicketCommissionGraphqlService {
	public constructor(private prismGraphql: PrismGraphqlService) {}

	public saveNewTicketCommission(ticketCommission: TicketCommission): Promise<TicketCommission> {
		const variables: CreateTicketCommissionMutationVariables = {
			event_id: ticketCommission.event_id,
			category: ticketCommission.category,
			gross_revenue: ticketCommission.gross_revenue,
			commission_percentage: ticketCommission.commission_percentage,
		};

		return this.prismGraphql
			.mutate<CreateTicketCommissionMutationVariables, CreateTicketCommissionMutationResponse>({
				mutation: CreateTicketCommissionMutation,
				variables,
			})
			.then(async (result: CreateTicketCommissionMutationResponse): Promise<TicketCommission> => {
				const response: InstantiatedAPIResponseAndValidationResults<TicketCommission> =
					await instantiateApiResponse(TicketCommission, result.createTicketCommission);
				return response.data;
			});
	}

	public updateTicketCommission(ticketCommission: TicketCommission): Promise<TicketCommission> {
		const variables: UpdateTicketCommissionMutationVariables = {
			id: ticketCommission.id,
			category: ticketCommission.category,
			gross_revenue: Number(ticketCommission.gross_revenue),
			commission_percentage: Number(ticketCommission.commission_percentage),
		};

		return this.prismGraphql
			.mutate<UpdateTicketCommissionMutationVariables, UpdateTicketCommissionMutationResponse>({
				mutation: UpdateTicketCommissionMutation,
				variables,
			})
			.then(async (result: UpdateTicketCommissionMutationResponse): Promise<TicketCommission> => {
				const response: InstantiatedAPIResponseAndValidationResults<TicketCommission> =
					await instantiateApiResponse(TicketCommission, result.updateTicketCommission);
				return response.data;
			});
	}

	public async deleteTicketCommission(ticketCommission: TicketCommission): Promise<number> {
		return this.prismGraphql
			.mutate<DeleteTicketCommissionMutationVariables, DeleteTicketCommissionMutationResponse>({
				mutation: DeleteTicketCommissionMutation,
				variables: {
					id: ticketCommission.id,
				},
			})
			.then((response: DeleteTicketCommissionMutationResponse): number => {
				return response.deleteTicketCommission;
			});
	}
}
