import { TicketCommission } from '@prism-frontend/typedefs/ticket-commission';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

interface TicketCommissionMutationVariables {
	category: string;
	gross_revenue: number;
	commission_percentage: number;
}

export type CreateTicketCommissionMutationVariables = TicketCommissionMutationVariables & {
	event_id: number;
};

export type UpdateTicketCommissionMutationVariables = TicketCommissionMutationVariables & {
	id: number;
};

export interface DeleteTicketCommissionMutationVariables {
	id: number;
}

export interface CreateTicketCommissionMutationResponse {
	createTicketCommission: TicketCommission;
}

export interface UpdateTicketCommissionMutationResponse {
	updateTicketCommission: TicketCommission;
}

export interface DeleteTicketCommissionMutationResponse {
	deleteTicketCommission: number;
}

const ticketCommissionMutationArguments: string = `
	$category: String!,
	$gross_revenue: Float,
    $commission_percentage: Float
`;

const ticketCommissionMutationArgumentMapping: string = `
	category: $category
    gross_revenue: $gross_revenue
    commission_percentage: $commission_percentage
`;

const TicketCommissionPropsFragment: DocumentNode = gql`
	fragment TicketCommissionProps on TicketCommission {
		id
		event_id
		category
		gross_revenue
		commission_percentage
	}
`;

export const CreateTicketCommissionMutation: DocumentNode = gql`
	mutation createTicketCommission(
        $event_id: Int!,
		${ticketCommissionMutationArguments}
	) {
		createTicketCommission(
            event_id: $event_id
			${ticketCommissionMutationArgumentMapping}
		) {
			...TicketCommissionProps
		}
	}
	${TicketCommissionPropsFragment}
`;

export const UpdateTicketCommissionMutation: DocumentNode = gql`
	mutation updateTicketCommission(
		$id: Int!,
		${ticketCommissionMutationArguments}
	) {
		updateTicketCommission(
			id: $id
			${ticketCommissionMutationArgumentMapping}
		) {
			...TicketCommissionProps
		}
	}
	${TicketCommissionPropsFragment}
`;

export const DeleteTicketCommissionMutation: DocumentNode = gql`
	mutation DeleteTicketCommissionMutation($id: Int!) {
		deleteTicketCommission(id: $id)
	}
`;
