import { CollectedBy } from '@prism-frontend/typedefs/enums/CollectedBy';
import { PaymentData } from '@prism-frontend/typedefs/paymentData';
import { plainToClass } from 'class-transformer';
import { IsEnum } from 'class-validator';

/**
 * The Settlement class represents a settlement payment for an event
 * they can be linked either with a talent or a rental deal
 */
export class Settlement extends PaymentData {
	public constructor(settlement?: Partial<Settlement>) {
		super();
		return plainToClass(Settlement, settlement);
	}

	/**
	 * Override the default collected by value by setting settelments
	 * to be collected by artists by default
	 */
	@IsEnum(CollectedBy)
	public override collected_by: CollectedBy = CollectedBy.Artist;
}
