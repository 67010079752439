import { NgModule } from '@angular/core';
import { AbsoluteValuePipe } from '@prism-frontend/utils/pipes/absolute-value.pipe';
import { CapitalPipe } from '@prism-frontend/utils/pipes/capital-pipe.pipe';
import { CdnUrlPipe } from '@prism-frontend/utils/pipes/cdn-url.pipe';
import { PhonePipe } from '@prism-frontend/utils/pipes/phone-number.pipe';
import { SafeHtmlPipe, SafePipe, SafeStylePipe } from '@prism-frontend/utils/pipes/safe.pipe';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const declaredAndExported: any[] = [
	AbsoluteValuePipe,
	CapitalPipe,
	CdnUrlPipe,
	PhonePipe,
	SafePipe,
	SafeHtmlPipe,
	SafeStylePipe,
];

@NgModule({
	imports: [],
	exports: [...declaredAndExported],
	declarations: [...declaredAndExported],
	providers: [],
})
export class PipesModule {}
