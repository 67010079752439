import { Artist } from '@prism-frontend/entities/artists/artist-typedefs';
import { BalancePayment } from '@prism-frontend/typedefs/balance-payment';
import { Currency } from '@prism-frontend/typedefs/enums/currency';
import { doesAgentOwe, invoiceOrReceipt, InvoiceType } from '@prism-frontend/typedefs/enums/InvoiceType';
import { PaymentStatusBackend } from '@prism-frontend/typedefs/enums/PaymentStatus';
import { EventFileAttachment } from '@prism-frontend/typedefs/file-attachment';
import { plainToClass, Type } from 'class-transformer';
import { IsArray, IsEnum, IsInt, IsNumber, IsString, ValidateNested } from 'class-validator';

export class InvoiceOrReceipt {
	public constructor(invoice?: Partial<InvoiceOrReceipt>) {
		return plainToClass(InvoiceOrReceipt, invoice);
	}
	// db fields
	@IsInt() public id: number = null;
	@IsInt() public org_invoice_id: number;
	@IsString() public created_at: string;

	// amount, artist_payout_towards_agent_commission, agent_commission_payout,
	// agency_received_total_payments, remaining_agent_artist_balance,
	// artist_show_balance, paid_date, notes, status, and currency
	@IsNumber() public amount: number = 0;
	@IsNumber() public artist_payout_towards_agent_commission: number = 0;
	@IsNumber() public agent_commission_payout: number = 0;
	@IsNumber() public agency_received_total_payments: number = 0;
	@IsNumber() public remaining_agent_artist_balance: number = 0;
	@IsNumber() public artist_show_balance: number = 0;
	@IsNumber() public adjustments_total: number = 0;
	@IsString() public paid_date: string = null;
	@IsString() public invoice_terms: string = null;
	@IsString() public notes: string = null;
	@IsEnum(PaymentStatusBackend) public payment_status: PaymentStatusBackend;
	@IsEnum(Currency) public currency: Currency;

	// payments
	@IsArray()
	@Type((): typeof BalancePayment => {
		return BalancePayment;
	})
	@ValidateNested()
	public payments: BalancePayment[] = [];

	// talent ID and model
	@IsInt() public talent_agent_id: number = null;
	@Type((): typeof Artist => {
		return Artist;
	})
	@ValidateNested()
	public talent_agent: Artist;

	// org ID and model
	@IsInt() public organization_id: number = null;

	// PDF File ID and model
	// TODO PRSM-XXXX this should pull from a non-event-specific file typdef
	@IsInt() public pdf_file_id: number = null;

	@Type((): typeof EventFileAttachment => {
		return EventFileAttachment;
	})
	@ValidateNested()
	public pdfFile: EventFileAttachment;

	@IsString()
	public file_name: string = '';

	public get invoiceType(): InvoiceType {
		return invoiceOrReceipt(this.amount);
	}

	public get doesAgentOwe(): boolean {
		return doesAgentOwe(this.amount);
	}

	public get displayText(): string {
		const artistName: string = this.talent_agent.artistName;
		const preText: string = this.doesAgentOwe ? 'Agent balance for' : artistName;
		const postText: string = this.doesAgentOwe ? artistName : 'to pay agent balance';
		return `${preText} ${postText}`;
	}
}
