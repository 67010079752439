import { ThresholdType } from '@prism-frontend/typedefs/thresholdType';
import { castToNumber } from '@prism-frontend/utils/transformers/castToNumber';
import { plainToClass, Transform } from 'class-transformer';
import { IsNumber, IsString } from 'class-validator';

/**
 * the value used in bonus.ts to compute which retro bonus will be triggered.
 * contains retro totals, and trigger amounts, as well as a boolean to determine whether
 * or not the retro bonus was triggered
 */
export interface RetroactiveBonusResult {
	retroBonusId: number;
	isBonusTriggered: boolean;
	bonusAmount: number;
	triggerAmount: number;
}

export class RetroactiveBonus {
	public constructor(retroBonus?: Partial<RetroactiveBonus>) {
		return plainToClass(RetroactiveBonus, retroBonus);
	}

	@IsNumber() public id: number = null;

	@IsNumber()
	@Transform(castToNumber())
	public retroactive_bonus_percentage: number = 0;

	@IsNumber()
	@Transform(castToNumber())
	public retroactive_after_amount: number = 0;

	@IsString()
	public bonus_threshold_type: ThresholdType = 'at';

	/**
	 * checks:
	 * 	1. actual > threshold if this.bonus_threshold_type === 'after'
	 * 	2. actual >= threshold if this.bonus_threshold_type === 'at'
	 *
	 * @param actual the actual event number (like tickets sold) to check against the threshold
	 * @param threshold the threshold to check against
	 * @returns true if actual meets the criteria, false otherwise
	 */
	public checkThreshold(actual: number, threshold: number): boolean {
		// 'after' logic - greater than sign
		if (this.bonus_threshold_type === 'after') {
			return actual > threshold;
		}

		// 'at' logic - greater than or equal to sign
		return actual >= threshold;
	}

	/**
	 * returns true if the retro bonus is valid, false otherwise
	 */
	public isValid(): boolean {
		return this.retroactive_after_amount !== null && this.retroactive_bonus_percentage !== null;
	}
}
