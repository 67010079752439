import { ContextInterpolatorFn } from '@prism-frontend/entities/email-templates/data-interpolation/ContextInterpolatorFn';
import { PropertyInterpolatorDictionary } from '@prism-frontend/entities/email-templates/data-interpolation/interpolators/SIMPLE_PROPERTY_INTERPOLATOR';
import {
	customFieldFromRollup,
	emsValueFromRollup,
} from '@prism-frontend/typedefs/ems/ems-static-helpers/emsValueFromRollup';
import { formatFieldValueFromRollup } from '@prism-frontend/typedefs/ems/ems-static-helpers/formatFieldValueFromRollup';
import { EMSCustomField, EMSRollup } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { EMSFieldDefs } from '@prism-frontend/typedefs/ems/EMSFieldMeta';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';
import { Currency } from '@prism-frontend/typedefs/enums/currency';
import _ from 'lodash';

export const EMS_FORMATTED_VALUE_INTERPOLATOR: ContextInterpolatorFn<PropertyInterpolatorDictionary> = (
	identifier: string,
	emsRollup: EMSRollup,
	currencyOverride?: Currency
): string => {
	const [costCalc2, ...rest]: string[] = identifier.split('.');
	const emsPath: keyof EMSFieldDefs = rest.join('.') as keyof EMSFieldDefs;
	const currency: Currency = currencyOverride || emsRollup[costCalc2 as CostCalc2].currency;
	return formatFieldValueFromRollup(emsRollup, costCalc2 as CostCalc2, emsPath, currency);
};

export const EMS_UNFORMATTED_VALUE_INTERPOLATOR: ContextInterpolatorFn<PropertyInterpolatorDictionary> = (
	identifier: string,
	context: { ems: EMSRollup }
): string => {
	if (identifier.includes('customField.')) {
		return EMS_CUSTOM_FIELD_INTERPOLATOR(identifier, context);
	}
	return EMS_FIELD_INTERPOLATOR(identifier, context);
};

const EMS_CUSTOM_FIELD_INTERPOLATOR: ContextInterpolatorFn<PropertyInterpolatorDictionary> = (
	identifier: string,
	context: { ems: EMSRollup }
): string => {
	// break identifier which has format: context.customField.customFieldKey
	const parts: string[] = identifier.split('.');
	const customFieldsByKey: Record<string, EMSCustomField> = _.chain(context.ems.customFields).keyBy('key').value();
	const customFieldKey: string = parts[parts.length - 1];
	return customFieldFromRollup(customFieldsByKey, customFieldKey) as string;
};

const EMS_FIELD_INTERPOLATOR: ContextInterpolatorFn<PropertyInterpolatorDictionary> = (
	identifier: string,
	context: { ems: EMSRollup }
): string => {
	let value: unknown;
	// break identifier which has format: ems.costCalc2.emsPath
	const [ems, costCalc2, ...rest]: string[] = identifier.split('.');
	const emsPath: keyof EMSFieldDefs = rest.join('.') as keyof EMSFieldDefs;
	value = emsValueFromRollup(context[ems], costCalc2 as CostCalc2, emsPath);
	if (_.isArray(value)) {
		value = value.map((thisValue: unknown): string | number => {
			return JSON.stringify(thisValue, null, 2);
		});
		return `[${(value as []).join(',')}]`;
	}
	return value as string;
};
