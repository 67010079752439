import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { VenueService } from '@prism-frontend/services/api/list-services/venue.service';
import { ApiService } from '@prism-frontend/services/legacy/api.service';
import { OfferSettings } from '@prism-frontend/services/legacy/api/OfferSettings';
import { OfferSettingsAndStages, OrgSettingsEndpointResponse } from '@prism-frontend/typedefs/organization';
import { Stage } from '@prism-frontend/typedefs/stage';
import { User } from '@prism-frontend/typedefs/user';
import { plainToClass } from 'class-transformer';

export const DEFAULT_LOGO_MAX_HEIGHT: number = 40;

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private _user: User = new User();

	public constructor(public apiService: ApiService, public router: Router, private venueService: VenueService) {}

	public get user(): User {
		return this._user;
	}

	public setUser(user: User): void {
		this._user = user;
	}

	public async loadOfferSettingsData(): Promise<OfferSettingsAndStages> {
		return this.apiService
			.getS<never, OrgSettingsEndpointResponse>([this.apiService.ep.ORG_SETTINGS])
			.toPromise()
			.then((data: OrgSettingsEndpointResponse): OfferSettingsAndStages => {
				const offerSettings: OfferSettings = plainToClass(OfferSettings, data.offer_settings[0]);
				const stages: Stage[] = this.venueService.stages$.value;
				return {
					offerSettings,
					stages,
				};
			});
	}
}
