import { TaxType } from '@prism-frontend/typedefs/enums/tax';

/**
 * given a tax rate and a tax type, return a value that be used to multiply a number by
 * to get a number without tax:
 *
 * 	an example, with a 5% tax rate, and a multiplier tax type
 * 		totalWithoutTax = total * withoutTaxMultiplier(5, 'multiplier')
 *
 * 		$95,000 = $100,000 * withoutTaxMultiplier(5, 'multiplier')
 */
export function withoutTaxMultiplier(taxRate: number, taxType: TaxType): number {
	const taxRateDecimal: number = taxRate / 100;

	if (taxType === TaxType.MULTIPLIER) {
		// multiplier tax logic, converted for us as a multipliers
		return 1 - taxRateDecimal;
	}

	// Divisor tax logic, converted for use as a multiplier
	return 1 / (taxRateDecimal + 1);
}
