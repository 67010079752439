import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApolloCache } from '@apollo/client/cache';
import { PrismGraphqlService } from '@prism-frontend/services/api/graphql/prism-graphql.service';
import { ContactRole } from '@prism-frontend/typedefs/ContactRole';
import {
	CreateContactRole,
	CreateContactRoleMutationArgs,
	CreateContactRoleMutationResponse,
	DeleteContactRoleMutation,
	DeleteContactRoleMutationArgs,
	PaginatedContactRoleArguments,
	PaginatedContactRoleQuery,
	PaginatedContactRoleQueryResult,
	UpdateContactRoleMutation,
	UpdateContactRoleMutationArgs,
	UpdateContactRoleMutationResponse,
} from '@prism-frontend/typedefs/graphql/ContactRole';
import { keyObjectsBy } from '@prism-frontend/utils/static/keyObjectsBy';
import { queryAllEntities } from '@prism-frontend/utils/static/query-all-entities';
import { plainToInstance } from 'class-transformer';

@Injectable({ providedIn: 'root' })
export class ContactRoleGraphqlService {
	private forceNetworkRequestOnNextFetch: boolean = false;

	public constructor(private matSnackBar: MatSnackBar, private prismGraphqlService: PrismGraphqlService) {}

	/**
	 * return all unique roles that exist for this org in the DB
	 *
	 * @returns a list of all Organization roles for a given org
	 */
	public async fetchContactRoles(): Promise<ContactRole[]> {
		// TODO PRSM-6416 hook up graphql query when available
		const shouldForceNetworkRequest: boolean = this.forceNetworkRequestOnNextFetch;
		this.forceNetworkRequestOnNextFetch = false;
		return queryAllEntities(
			this.prismGraphqlService,
			{},
			PaginatedContactRoleQuery,
			'contactRoleList',
			ContactRole,
			undefined,
			undefined,
			shouldForceNetworkRequest
		);
	}

	public async fetchContactRolesById(): Promise<Record<number, ContactRole>> {
		const allContactRoles: ContactRole[] = await this.fetchContactRoles();
		return keyObjectsBy(allContactRoles, 'id');
	}

	public async fetchPaginatedContactRoles(
		filterString: string,
		currentEventCount: number,
		abortSignal?: AbortSignal
	): Promise<PaginatedContactRoleQueryResult> {
		const limit: number = 50;
		const currentPage: number = Math.floor(currentEventCount / limit);

		return this.prismGraphqlService.query<PaginatedContactRoleQueryResult>({
			query: PaginatedContactRoleQuery,
			variables: {
				limit,
				page: currentPage,
				search: filterString,
			} as PaginatedContactRoleArguments,
			fetchPolicy: 'network-only',
			context: {
				fetchOptions: {
					signal: abortSignal,
				},
			},
		});
	}

	/**
	 * create a new role for this org
	 *
	 * @param role the role to be created, with a null ID
	 * @returns the created role, with a valid ID
	 */
	public async createContactRole(role: ContactRole): Promise<ContactRole> {
		this.forceNetworkRequestOnNextFetch = true;
		return this.prismGraphqlService
			.mutate<CreateContactRoleMutationArgs, CreateContactRoleMutationResponse>({
				mutation: CreateContactRole,
				variables: {
					name: role.name,
					description: role.description || null,
					show_in_docs: role.show_in_docs,
				},
			})
			.then((response: CreateContactRoleMutationResponse): ContactRole => {
				this.matSnackBar.open('New contact role created!', null, {
					duration: 1500,
				});
				return plainToInstance(ContactRole, response.createContactRole);
			});
	}

	/**
	 * edit an existing organization's role
	 *
	 * @param role the role being edited
	 * @returns the role after editing
	 */
	public async editContactRole(role: ContactRole): Promise<ContactRole> {
		return this.prismGraphqlService
			.mutate<UpdateContactRoleMutationArgs, UpdateContactRoleMutationResponse>({
				mutation: UpdateContactRoleMutation,
				variables: {
					...role,
				},
			})
			.then((response: UpdateContactRoleMutationResponse): ContactRole => {
				this.matSnackBar.open('Contact role updated!', null, {
					duration: 1500,
				});
				return plainToInstance(ContactRole, response.updateContactRole);
			});
	}

	/**
	 * delete an existing organization's role
	 *
	 * @param role the role being edited
	 * @returns the role after editing
	 */
	public async deleteContactRole(role: ContactRole): Promise<void> {
		this.forceNetworkRequestOnNextFetch = true;
		return this.prismGraphqlService
			.mutate<DeleteContactRoleMutationArgs, void>({
				mutation: DeleteContactRoleMutation,
				variables: {
					id: role.id,
				},
				// first attempt at apollo cache garbage collecting
				// this will remove the role from the apollo cache, so that after deleting if a user
				// navigates to the contact book page, the role will no longer be there
				// this code removes the roles from any contacts they might exist
				// https://stackoverflow.com/a/66713628
				update: (cache: ApolloCache<unknown>): void => {
					const normalizedId: string | undefined = cache.identify({ id: role.id, __typename: 'ContactRole' });
					cache.evict({ id: normalizedId });
					cache.gc();
				},
			})
			.then((): void => {
				this.matSnackBar.open('Contact role deleted!', null, {
					duration: 1500,
				});
			});
	}
}
