import { formatFieldValue } from '@prism-frontend/typedefs/ems/ems-static-helpers/formatFieldValue';
import { EMSRollup } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { EMSFieldDefs } from '@prism-frontend/typedefs/ems/EMSFieldMeta';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';
import { Currency } from '@prism-frontend/typedefs/enums/currency';

export function formatFieldValueFromRollup(
	emsRollup: EMSRollup,
	costCalc2: CostCalc2,
	emsMetadataId: keyof EMSFieldDefs,
	currencyOverride: Currency = emsRollup[costCalc2].currency
): string {
	return formatFieldValue(emsRollup[costCalc2], emsMetadataId, currencyOverride);
}
