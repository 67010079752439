import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { castToNumber } from '@prism-frontend/utils/transformers/castToNumber';
import { plainToInstance, Transform } from 'class-transformer';
import { IsBoolean, IsNumber, IsString } from 'class-validator';
import { v4 as uuidv4 } from 'uuid';

export const TOUR_TICKET_BASE_PROPS: Set<string> = new Set([
	'id',
	'name',
	'potential_gross',
	'average_kills',
	'average_sellable',
	'is_platform',
]);

/**
 * This class provides the base data for a tour ticket.
 * For Tours we group all event tickets by their name,
 * so we can have a single row for each ticket in a tour
 * and the corresponding ticket data for each event in the tour.
 */
abstract class TourTicketBase {
	/**
	 * The name of the ticket in the tour.
	 */
	@IsString() public name: string = '';

	/**
	 * The potential gross amount for the ticket in the tour
	 * across all events. This is sellable * ticket price.
	 */
	@IsNumber()
	@Transform(castToNumber())
	public potential_gross: number = 0;

	/**
	 * The average amount of kills for this ticket
	 * across all events in the tour.
	 */
	@IsNumber()
	@Transform(castToNumber())
	public average_kills: number = 0;

	/**
	 * The average amount of sellable tickets for this ticket
	 * across all events in the tour.
	 */
	@IsNumber()
	@Transform(castToNumber())
	public average_sellable: number = 0;

	/**
	 * If the tour ticket belongs to a ticket integration
	 */
	@IsBoolean()
	@Transform(castToBoolean())
	public is_platform: boolean = false;
}

/**
 * The tour ticket class represents the data sent from the endpoint
 * for the tour ticket table. Contains the inherited properties from
 * the base class and the corresponding ticket data for each event
 * in the tour, even if a particular event does not have the ticket.
 */
export class TourTicket extends TourTicketBase {
	public constructor(ticket?: Partial<TourTicket>) {
		super();
		return plainToInstance(TourTicket, ticket);
	}

	/**
	 * There will be a key for each event in the tour containing
	 * the ticket data for that event. The key is arranged using the event id
	 * example:
	 * event12441: {
	 * 		eventId: 12441,
	 * 		eventName: 'Event Name',
	 * 		ticketId: 123,
	 * 		ticketPrice: 100,
	 * }
	 */
	// typescript forces us to define it as 'string | number | boolean |' to compile
	[eventId: string]: string | number | boolean | TourEventTicket;
}

/**
 * Represents the ticket data for a particular event in the tour.
 */
export interface TourEventTicket {
	/**
	 * The id of the tour event
	 */
	event_id: number;
	/**
	 * The name of the tour event
	 */
	event_name: string;
	/**
	 * The id of the ticket in the event. If the ticket
	 * is not present in the event, the value is null.
	 */
	ticket_id: number;
	/**
	 * The price of the ticket in the event. If the ticket
	 * is not present in the event, the value is null.
	 */
	ticket_price: number;
	/**
	 * A flag that indicates if there is conflicts for the ticket
	 * over the tour events. This can happen when having multiple
	 * tickets for the same name in the tour event.
	 */
	warning_duplicate_name?: boolean;
}

/**
 * Represents the row data for the tour ticket table.
 * This is a derived class from the TourTicket class
 * which gets remapped over the simple table to
 * properly inline edit their value.
 * The TourTicket will remain as the _original object
 * to be used when calling the endpoint for editing so
 * to grab the event and ticket ids.
 */
export class TourTicketRow extends TourTicketBase {
	public constructor(ticket?: Partial<TourTicketRow>) {
		super();
		return plainToInstance(TourTicketRow, ticket);
	}

	@IsNumber() public id: number;

	/**
	 * There will be a key for each event in the tour containing
	 * the ticket price for that event. The key is arranged using the event id
	 * example:
	 * event12441: 100
	 */
	// typescript forces us to define it as 'string | boolean' to compile
	[eventId: string]: string | boolean | number;
}

/**
 * A function to map a tour ticket object to a table row,
 * flattening the event ticket data into the particular ticket price
 * to be used over the table to inline edit their value.
 * @param tourTicket The tour ticket object to map to a table row
 * @returns The table row data for the tour ticket
 */
export function mapTourTicketToTableDataRow(tourTicket: TourTicket): TourTicketRow {
	const eventTicketPrices: { [eventId: string]: number } = {};
	// base properties dont need remmaping. Id is included, since once we define it
	// we need to keep track of it for further inline edit so we don't lose the reference
	// of the record over the table
	for (const key of Object.keys(tourTicket)) {
		if (TOUR_TICKET_BASE_PROPS.has(key)) {
			continue;
		}
		const eventId: string = key;
		const eventTicket: TourEventTicket = tourTicket[eventId] as TourEventTicket;
		// map the ticket data to the ticket price for the event if any
		eventTicketPrices[eventId] = eventTicket ? eventTicket.ticket_price : null;
	}
	return new TourTicketRow({
		// if there was already an id defined use it, otherwise generate it.
		id: tourTicket['id'] ? tourTicket.id : uuidv4(),
		name: tourTicket.name,
		potential_gross: tourTicket.potential_gross,
		average_kills: tourTicket.average_kills,
		average_sellable: tourTicket.average_sellable,
		is_platform: tourTicket.is_platform,
		...eventTicketPrices,
	});
}
