import { AsYouType, CountryCode, parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';

export function formatPhoneNumber(phone: string): string {
	let displayValue: string = `${phone || ''}`;
	// AsYouType is a utility in the libphonenumber-js library that allows you to format phone numbers as the user types them.
	// As the user enters digits, AsYouType formats the number according to the rules for the country the number is from, adding parentheses, hyphens, spaces, and other formatting characters as needed.
	displayValue = new AsYouType(getCountryAndRegionFromPhoneNumber(phone)).input(displayValue);
	// remove any phone numbers that end in an empty close paren.
	// e.g. 313 -> (313
	// 		3134 -> (313) 4
	// this is to make it so that after formatting our number, we can allow back
	// spacing on only numbers for our field change Event
	// we have specific tests for this
	return displayValue.replace(/\)$/, '');
}

/**
 * Returns the country code of the phone number in phoneNumberString, as determined by the defaultCountry parameter. If phoneNumberString is not a valid phone number, returns the defaultCountry.
 * @param phoneNumberString The phone number as a string.
 * @param defaultCountry The default country code to use if phoneNumberString does not include an international code. Defaults to 'US'.
 * @returns The country code of the phone number in phoneNumberString, as determined by the defaultCountry parameter.
 */
function getCountryAndRegionFromPhoneNumber(
	phoneNumberString: string,
	defaultCountry: CountryCode = 'US'
): CountryCode {
	let parsedPhoneNumber: PhoneNumber;

	try {
		// Set the second parameter to the default region if the input does not
		// include an international code
		parsedPhoneNumber = parsePhoneNumber(phoneNumberString, defaultCountry);
	} catch {
		return defaultCountry;
	}

	return parsedPhoneNumber.country;
}
