import { CostOption } from '@prism-frontend/typedefs/cost';
import { CostGroupCategory } from '@prism-frontend/typedefs/enums/CostGroupCategory';

/**
 * Represents ALL cost group category options. Used in event template editor
 * and the findCostGroupByName functional chip. When building cost group
 * type options at the event level, we apply event-level permissions
 * using getCategoryOptionsForEvent
 */
export const CostGroupCategoryOptions: CostOption[] = [
	{ label: 'General', value: CostGroupCategory.General },
	{ label: 'Production', value: CostGroupCategory.Production },
	{ label: 'Marketing', value: CostGroupCategory.Marketing },
	{ label: 'Talent', value: CostGroupCategory.Talent },
];

export const CostGroupLabels: Record<CostGroupCategory, string> = CostGroupCategoryOptions.reduce(
	(memo: Record<CostGroupCategory, string>, group: CostOption): Record<CostGroupCategory, string> => {
		memo[group.value] = group.label;
		return memo;
	},
	{} as Record<CostGroupCategory, string>
);
