import { OfferSettings } from '@prism-frontend/services/legacy/api/OfferSettings';
import { Currency } from '@prism-frontend/typedefs/enums/currency';
import { DealStatus } from '@prism-frontend/typedefs/enums/DealStatus';
import { PricingTier } from '@prism-frontend/typedefs/enums/PricingTier';
import { ENV_FEATURE, ORG_FEATURE } from '@prism-frontend/typedefs/features.class';
import { Logo } from '@prism-frontend/typedefs/logo';
import { PermissionRole } from '@prism-frontend/typedefs/PermissionRole';
import { Stage } from '@prism-frontend/typedefs/stage';
import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { castToNumber } from '@prism-frontend/utils/transformers/castToNumber';
import { Transform, Type } from 'class-transformer';
import { IsArray, IsBoolean, IsEnum, IsNumber, IsString, ValidateNested } from 'class-validator';

export interface Feature {
	name: ORG_FEATURE | ENV_FEATURE;
	/**
	 * Only returned for ORG_FEATUREs
	 */
	display_name?: string;
}

interface ArrayFixed<T, L extends number> extends Array<T> {
	0: T;
	length: L;
}

export interface OrgSettingsEndpointResponse {
	organization_settings: OrganizationSettings;
	org_features: Feature[];
	env_features: Feature[];
	offer_settings: ArrayFixed<OfferSettings, 1>;
}

export interface OfferSettingsAndStages {
	offerSettings: OfferSettings;
	stages: Stage[];
}

export interface OrgSettingsEndpointResponseWithLogos extends OrgSettingsEndpointResponse {
	logos: Logo[];
}

export class OrganizationSettings {
	@IsString()
	public org_name: string;

	@IsEnum(Currency)
	public default_currency: Currency;

	@IsNumber()
	@Transform(castToNumber())
	public default_hold_level: number;

	@IsBoolean()
	@Transform(castToBoolean())
	public auto_promote_holds?: boolean;

	@IsBoolean()
	@Transform(castToBoolean())
	public convert_to_usd?: boolean;

	@IsBoolean()
	@Transform(castToBoolean())
	public send_confirmation_email?: boolean;

	@IsBoolean()
	@Transform(castToBoolean())
	public copy_costs_on_confirm?: boolean;

	@IsBoolean()
	@Transform(castToBoolean())
	public detailed_copy_holds?: boolean;

	@IsArray()
	public available_deal_statuses: DealStatus[];

	@IsArray()
	public configured_deal_statuses: DealStatus[];
}

class CoreOrg {
	@IsNumber()
	@Transform(castToNumber())
	public id: number;

	@IsString()
	public name: string;

	@IsString()
	public location: string;
}

export class Organization extends CoreOrg {
	@Type((): typeof OrganizationSettings => {
		return OrganizationSettings;
	})
	@ValidateNested()
	public settings: OrganizationSettings = new OrganizationSettings();

	// TODO PRSM-6144 drop this field completely
	@IsEnum(PricingTier)
	public pricing_tier: PricingTier;
}

export class PartnerOrganization extends CoreOrg {}

export class User {
	public id: number;
	public email: string;
	public staff: Staff;
	public password: string;
	public is_admin: boolean;
	public is_prism_admin: boolean;
}

export interface UserInterface {
	id: number;
	name?: string;
	email: string;
	is_admin: boolean | number;
	is_prism_admin: boolean | number;
	org_roles?: string[];
	email_summary_frequency?: string;
	email_summary_last_sent?: string;
	send_confirmation_email?: boolean;
	organization?: Organization;
	organization_id?: number;
	organization_name?: string;
	apiState?: string;
	enabled?: boolean | number;
	permissions?: string[];
	staff?: Staff;
}

export interface UserDTO {
	id: number;
	email?: string;
	name?: string;
	roles?: number[];
}

export class Staff {
	public id: number;
	public job: string;
	public user_id: number;
	public organization_id: number;
	public name: string;
	public created_at?: string;
	public updated_at?: string;
}

export interface UserWithStaffAndRole {
	roledata: PermissionRole[];
	staffdata: Staff & {
		user: UserInterface;
	};
	userdata: UserInterface;
}

export type OrganizationSettingsForm = Omit<OrganizationSettings, 'send_confirmation_email'>;
