import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
	ApolloClient,
	ApolloError,
	ApolloLink,
	ApolloQueryResult,
	createHttpLink,
	FetchResult,
	GraphQLRequest,
	InMemoryCache,
	MutationOptions,
	NormalizedCacheObject,
	QueryOptions,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { environment } from '@prism-frontend/../environments/environment';
import { SnackbarErrorComponent } from '@prism-frontend/components/snackbar-error/snackbar-error.component';
import { UserTokenStorageService } from '@prism-frontend/services/legacy/user-token-storage.service';
import { sha256 } from 'crypto-hash';

const graphQLURI: string = `/${environment.graphql_path}`;

@Injectable()
export class PrismGraphqlService {
	// the apollo client, through which queries can be run
	public client: ApolloClient<NormalizedCacheObject>;

	// a signal that gets passed to every query; use this to cancel in-progress queries
	private controller: AbortController = window.AbortController ? new AbortController() : undefined;

	public constructor(private userTokenStorageService: UserTokenStorageService, private snackBar: MatSnackBar) {
		// https://www.apollographql.com/docs/react/networking/authentication/#header
		const httpLink: ApolloLink = createHttpLink({
			uri: graphQLURI,
			// add the ability to cancel queries by overriding options passed to fetch()
			// https://www.apollographql.com/docs/react/networking/advanced-http-networking/#overriding-options
			fetchOptions: {
				signal: this.controller.signal,
			},
		});

		const authLink: ApolloLink = setContext(
			(_gqlRequest: GraphQLRequest, { headers }: { headers: unknown[] }): unknown => {
				// return the headers to the context so httpLink can read them
				return {
					headers: {
						...headers,
						...this.userTokenStorageService.authHeaders(),
					},
				};
			}
		);

		// persisted queries
		// https://www.apollographql.com/docs/react/api/link/persisted-queries/
		// this requires a b/e env flag to be set: https://github.com/rebing/graphql-laravel#automatic-persisted-queries-support
		const persistedQueriesLink: ApolloLink = createPersistedQueryLink({
			sha256,
			useGETForHashedQueries: true,
		});

		this.client = new ApolloClient({
			link: authLink.concat(persistedQueriesLink).concat(httpLink),
			cache: new InMemoryCache(),

			// connect to Apollo dev tools in non-productin environments
			connectToDevTools: !environment.production,
		});
	}

	public query<T>(queryOptions: QueryOptions): Promise<T> {
		return this.client
			.query<T>(queryOptions)
			.then((result: ApolloQueryResult<T>): T => {
				return result.data;
			})
			.catch((apolloError: ApolloError): T => {
				if (!environment.production) {
					// eslint-disable-next-line no-console
					console.warn(`An ApolloError was thrown`, apolloError);
					for (const thisError of apolloError.graphQLErrors) {
						// eslint-disable-next-line no-console
						console.warn(thisError.message, thisError);
					}
				}

				if (!queryOptions.context?.fetchOptions?.signal?.aborted) {
					this.showGraphQLError();
				}

				throw apolloError;
			});
	}

	public mutate<T, U>(mutationOptions: MutationOptions<U, T>): Promise<U> {
		return this.mutateAndIWillHandleTheErrorMyself(mutationOptions).catch((apolloError: ApolloError): U => {
			if (!environment.production) {
				// eslint-disable-next-line no-console
				console.warn(`An ApolloError was thrown`);
				for (const thisError of apolloError.graphQLErrors) {
					// eslint-disable-next-line no-console
					console.warn(thisError.message, thisError);
				}
			}
			this.showGraphQLError();
			throw apolloError;
		});
	}

	public showGraphQLError(): void {
		this.showErrorSnackbar(`An error occured. If this issue persists, contact support.`);
	}

	public mutateAndIWillHandleTheErrorMyself<T, U>(mutationOptions: MutationOptions<U, T>): Promise<U> {
		return this.client.mutate<U, T>(mutationOptions).then((result: FetchResult<U>): U => {
			return result.data;
		});
	}

	private showErrorSnackbar(message: string): void {
		this.snackBar.openFromComponent(SnackbarErrorComponent, {
			panelClass: ['snack-bar-error'],
			duration: 5000,
			data: {
				message,
			},
		});
		// eslint-disable-next-line no-console
		console.error(message);
	}
}
