import { Injectable } from '@angular/core';
import { PermissionTypeMap } from '@prism-frontend/components/role-permissions/PermissionTypeMap';
import { VenueService } from '@prism-frontend/services/api/list-services/venue.service';
import { ApiService } from '@prism-frontend/services/legacy/api.service';
import { UserService } from '@prism-frontend/services/legacy/api/user.service';
import { CostGroupCategory } from '@prism-frontend/typedefs/enums/CostGroupCategory';
import { PrismEvent } from '@prism-frontend/typedefs/event';
import { HoldForCalendar } from '@prism-frontend/typedefs/hold-for-calendar';
import { UserInterface } from '@prism-frontend/typedefs/organization';
import { CostPermissions, isCostPermission, Permission } from '@prism-frontend/typedefs/permission';
import { PermissionRole } from '@prism-frontend/typedefs/PermissionRole';
import { Venue } from '@prism-frontend/typedefs/venue';
import moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';

export type PrismEventPermissionsProps = Pick<PrismEvent, 'id' | 'created_by'>;
export type HoldForCalendarPermissionsProps = Pick<HoldForCalendar, 'permissions' | 'event'>;

export interface PermissionsById {
	[key: number]: Permission[];
}

interface GoofyPermissionObjectLegacy {
	is_prism_admin?: number;
	is_admin?: number;
}

@Injectable({
	providedIn: 'root',
})
export class PermissionsService {
	private userIsAdmin: boolean = false;
	private userIsPrismAdmin: boolean = false;
	private venues: Venue[] | undefined;
	private baseUserPermissions: Permission[] | GoofyPermissionObjectLegacy = {};
	private eventPermissions: PermissionsById = {};
	private eventPermissionsAccessedLastTime: { [key: number]: number } = {};
	protected venuePermissions: PermissionsById = {};
	private orgRoles: PermissionRole[] = [];
	public globalPermissionsLoaded: Subject<void> = new Subject<void>();
	public venuePermissionsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private cacheTime: number = 86400;

	public constructor(
		public userService: UserService,
		protected apiService: ApiService,
		protected venueService: VenueService
	) {}

	public get isAdmin(): boolean {
		return !!this.userIsAdmin;
	}

	public get isPrismAdmin(): boolean {
		return !!this.userIsPrismAdmin;
	}

	public get orgWideRoles(): PermissionRole[] {
		return this.orgRoles;
	}

	private static hasPermission(
		permission: Permission | Permission[],
		repo: Permission[] | GoofyPermissionObjectLegacy
	): boolean {
		if (!repo) return false;
		if (Array.isArray(permission)) {
			for (const perm of permission) {
				if (Array.isArray(repo) && repo.indexOf(perm) >= 0) {
					return true;
				}
				if (typeof repo === 'object' && perm in repo && !!(repo as PermissionTypeMap)[perm]) {
					return true;
				}
			}
		} else if (typeof permission === 'string') {
			if (Array.isArray(repo)) {
				return repo.indexOf(permission) >= 0;
			}
			if (typeof repo === 'object') {
				return permission in repo && !!(repo as PermissionTypeMap)[permission];
			}
		}

		// Default Action
		return false;
	}

	public startUp(): void {
		this.loadVenuePermissions();
	}

	public userCanCreateEvents(): boolean {
		// if admin or can globally
		if (this.isAdmin || this.userCan('create-holds')) {
			return true;
		}
		// otherwise check venue by venue
		if (this.venues) {
			for (const venue of this.venues) {
				if (this.userCanOnVenue('create-holds', venue.id)) {
					return true;
				}
			}
		}
		return false;
	}

	public loadGlobalPermissions(
		isPrismAdmin: boolean,
		isOrgAdmin: boolean,
		roles: PermissionRole[],
		permissions: Permission[]
	): void {
		this.userIsAdmin = isOrgAdmin;
		this.userIsPrismAdmin = isPrismAdmin;
		this.baseUserPermissions = permissions;
		this.orgRoles = roles;
		this.globalPermissionsLoaded.next();
	}

	public loadVenuePermissions(): void {
		this.venueService.ensureListLoaded().then((venues: Venue[]): void => {
			this.venues = venues;
		});

		// Load venue permissions
		this.apiService
			.getP<void, PermissionsById>(this.apiService.ep.VENUE_PERMISSIONS)
			.then((permissions: PermissionsById): void => {
				this.venuePermissions = permissions;
				this.venuePermissionsLoaded.next(true);
			});
	}

	public storeEventPermissions(event_id: number, permissions: Permission[]): void {
		if (this.isAdmin) return;
		this.setEventPermissions(event_id, permissions);
		this.cleanExpiredEventPermissions();
	}

	private setEventPermissions(event_id: number, permissions: Permission[]): void {
		// @ts-ignore
		this.eventPermissions[event_id] = permissions;
		// @ts-ignore
		this.eventPermissionsAccessedLastTime[event_id] = moment().unix();
	}

	private cleanExpiredEventPermissions(): void {
		for (const eventId in this.eventPermissionsAccessedLastTime) {
			// For...in wants to cast members as a string
			if (this.isExpired(Number(eventId))) {
				// @ts-ignore
				delete this.eventPermissions[eventId];
				// @ts-ignore
				delete this.eventPermissionsAccessedLastTime[eventId];
			}
		}
	}

	private isExpired(eventId: number): boolean {
		// @ts-ignore
		return moment().unix() - this.eventPermissionsAccessedLastTime[eventId] > this.cacheTime;
	}

	public userCan(permission: Permission | Permission[]): boolean {
		if (this.isAdmin) {
			return true;
		}
		if (!this.baseUserPermissions) {
			return false;
		}
		return PermissionsService.hasPermission(permission, this.baseUserPermissions);
	}

	public userCanOnHold(permission: Permission | Permission[], hold: HoldForCalendarPermissionsProps): boolean {
		if (!hold) {
			return false;
		}
		// Check base permissions first
		if (this.userOwnsEvent(hold.event) || this.userCan(permission)) {
			return true;
		}

		// Check for empty permissionsServiceissions
		if (!hold.permissions || hold.permissions.length === 0) {
			return false;
		}

		return PermissionsService.hasPermission(permission, hold.permissions);
	}

	// Checks permissions against event
	public userCanOnEvent(
		permission: Permission | Permission[],
		event: PrismEventPermissionsProps | undefined
	): boolean {
		if (!event) return false;

		// Check base permissions first
		if (this.userOwnsEvent(event) || this.userCan(permission)) {
			return true;
		}
		if (!(event.id in this.eventPermissions)) {
			return false;
		}
		// @ts-ignore
		this.eventPermissionsAccessedLastTime[event.id] = moment().unix();
		// @ts-ignore
		return PermissionsService.hasPermission(permission, this.eventPermissions[event.id]);
	}

	public userCanAllOnEvent(permissions: Permission[], event: PrismEvent): boolean {
		return permissions.reduce((userCan: boolean, permission: Permission): boolean => {
			return userCan && this.userCanOnEvent(permission, event);
		}, true);
	}

	/**
	 * Given a list of venues, return the venues that the user has the given permission on
	 *
	 * @param venues The list of venues to check
	 * @param permission The permission to check
	 * @param meetAllPermissions If true, the user must have all permissions on the venue to be included
	 * if not, the user must have at least one permission on the venue to be included
	 * @returns The list of active venues that the user has the given permission on
	 */
	public getActiveVenuesForPermission(
		venues: Venue[],
		permission: Permission[],
		meetAllPermissions: boolean = false
	): Venue[] {
		if (meetAllPermissions) {
			return venues.filter((venue: Venue): boolean => {
				return this.userCanAllOnVenue(permission, venue.id);
			});
		}
		return venues.filter((venue: Venue): boolean => {
			return this.userCanSomeOnVenue(permission, venue.id);
		});
	}

	public userCanOnVenue(permission: Permission | Permission[], venue_id: number): boolean {
		if (this.userCan(permission)) {
			return true;
		}
		if (!(venue_id in this.venuePermissions)) {
			return false;
		}
		// @ts-ignore
		return PermissionsService.hasPermission(permission, this.venuePermissions[venue_id]);
	}

	// returns true if the user has all the requisite permissions
	// on a venue
	public userCanAllOnVenue(permissions: Permission[], venue_id: number): boolean {
		return permissions.reduce((userCan: boolean, permission: Permission): boolean => {
			return userCan && this.userCanOnVenue(permission, venue_id);
		}, true);
	}

	// returns true if the user has at least one of the requisite
	// permissions on a venue
	public userCanSomeOnVenue(permissions: Permission[], venue_id: number): boolean {
		return permissions.reduce((userCan: boolean, permission: Permission): boolean => {
			return userCan || this.userCanOnVenue(permission, venue_id);
		}, false);
	}

	public userOwnsEvent(event: PrismEventPermissionsProps): boolean {
		const user: UserInterface = this.userService.user;
		return user.id === event.created_by;
	}

	/**
	 * a helper function to call userCanOnCostCategory, which only requires a string permission to be specified
	 * This function pulls the permission or list of permissions, validate that they belong to cost related
	 * permissions and check if user can on event for that/those cost permissions
	 *
	 * @param permission a permission in the form `${'view' | 'edit'}-${CostGroupCategoryType}-budget`
	 * either a single one or an array of permission to check
	 * @param event the event to check the user permission on
	 */
	public userCanOnCostCategoryPermission(
		permissions: CostPermissions | CostPermissions[],
		event: PrismEvent
	): boolean {
		if (!Array.isArray(permissions)) {
			return this.userCanForCostCategoryPermission(permissions, event);
		}
		return (permissions as CostPermissions[]).reduce((userCan: boolean, permission: CostPermissions): boolean => {
			return userCan && this.userCanForCostCategoryPermission(permission, event);
		}, true);
	}

	private userCanForCostCategoryPermission(permission: CostPermissions, event: PrismEvent): boolean {
		if (!isCostPermission(permission)) {
			throw new Error(`Permission check on non-cost permission (${permission})`);
		}

		const [action, category]: string[] = permission.split('-');
		return this.userCanOnCostCategory(action as 'view' | 'edit', category as CostGroupCategory, event);
	}

	public userCanOnCostCategory(
		action: 'view' | 'edit',
		category: CostGroupCategory,
		event: PrismEvent,
		checkGlobalPermissions: boolean = false
	): boolean {
		if (!event && !checkGlobalPermissions) {
			throw new Error('Event must be provided if not checking global permissions');
		}
		// if no category, allow view (for old cost groups without a category)
		if (!category) return true;
		if (category === 'all') {
			// Check against one of any categories available in permissions list
			const fullList: Permission[] = Object.keys(CostGroupCategory)
				// @ts-ignore
				.filter((key: CostGroupCategory): boolean => {
					return CostGroupCategory[key] !== CostGroupCategory.All;
				})
				.map(
					// @ts-ignore
					(key: CostGroupCategory): Permission => {
						return <Permission>`${action}-${CostGroupCategory[key]}-budget`;
					}
				);

			if (checkGlobalPermissions) {
				return this.userCan(fullList);
			}

			return this.userCanOnEvent(fullList, event);
		}

		const permission: Permission = <Permission>`${action}-${category}-budget`;
		// Check against singular category
		if (checkGlobalPermissions) {
			return this.userCan(permission);
		}

		return this.userCanOnEvent(permission, event);
	}
}
