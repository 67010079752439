import { TypedTransformFnParams } from '@prism-frontend/typedefs/TypedTransformFnParams';
import { verboseDebug } from '@prism-frontend/utils/static/getDebug';
import { TransformFnParams } from 'class-transformer';
import * as _ from 'lodash';

/**
 * This method is a factory for a method that can be passed to @Transform().
 * The decorated property will be cast to a boolean when it is not one.
 * Usage: @Transform(castToBoolean())
 * @param propName: string - This is used for logging purposes only and warning
 *   in cases where the property was not a number pre-transform.
 */
export function castToBoolean(allowNull: boolean = false): (transformParams: TransformFnParams) => boolean {
	return (transformParams: TypedTransformFnParams<boolean>): boolean => {
		const value: boolean = transformParams.value;
		if (_.isBoolean(value)) {
			return value;
		}
		if (allowNull && value === null) {
			return value;
		}
		verboseDebug(
			`Neded to cast ${transformParams.key} from ${typeof value} (${value}) to a boolean. Please fix the backend.`
		);
		if (value === '1' || value === 1 || value === 'true') {
			return true;
		}
		if (value === '0' || value === 0 || value === 'false') {
			return false;
		}
		verboseDebug(`Boolean property not of expected value ${transformParams.key} = ${value}.`);
		return Boolean(value);
	};
}
