import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgSelectModule } from '@ng-select/ng-select';
import { EventPickerModule } from '@prism-frontend/components/event-picker/event-picker.module';
import { JumpToEventComponent } from '@prism-frontend/components/jump-to-event/jump-to-event.component';

@NgModule({
	imports: [NgSelectModule, CommonModule, EventPickerModule, MatIconModule, MatButtonModule],
	exports: [JumpToEventComponent],
	declarations: [JumpToEventComponent],
	providers: [],
})
export class JumpToEventModule {}
