import { verboseDebug } from '@prism-frontend/utils/static/getDebug';
import { validate, ValidationError, ValidatorOptions } from 'class-validator';

export interface InstantiatedAPIResponseAndValidationResults<T> {
	data: T;
	validationErrors: ValidationError[];
}

/**
 * This function receives a single object or an array of objects of type <T>
 * and runs validation on them using the 'class-validator' package
 */
export function validateClassInstances<T>(
	data: T,
	validatorOptions?: ValidatorOptions
): Promise<InstantiatedAPIResponseAndValidationResults<T>> {
	const validationErrors: ValidationError[] = [];
	const dataAsArray: unknown[] = Array.isArray(data) ? data : [data];

	return Promise.all(
		dataAsArray.map((value: T): Promise<void> => {
			return validate(value as unknown as object, validatorOptions).then((errors: ValidationError[]): void => {
				if (errors.length) {
					verboseDebug(`class-validator has detected validation errors after calling plainToClass`, errors);
					errors.forEach((err: ValidationError): void => {
						validationErrors.push(err);
					});
				}
			});
		})
	).then((): Promise<{ validationErrors: ValidationError[]; data: T }> => {
		return Promise.resolve({
			validationErrors: validationErrors,
			data: data,
		});
	});
}
