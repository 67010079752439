import { Injectable } from '@angular/core';
import { GoogleSuggestion } from '@prism-frontend/services/api/address-search.service';
import { PrismGraphqlService } from '@prism-frontend/services/api/graphql/prism-graphql.service';
import { ApiService, instantiateApiResponse } from '@prism-frontend/services/legacy/api.service';
import {
	CreateStageMutation,
	CreateStageMutationResponse,
	CreateStageMutationVariables,
	UpdateStageMutation,
	UpdateStageMutationResponse,
	UpdateStageMutationVariables,
} from '@prism-frontend/typedefs/graphql/StageMutation';
import {
	CreateVenueMutation,
	CreateVenueMutationResponse,
	CreateVenueMutationVariables,
	SharedVenueProps,
	UpdateVenueMutation,
	UpdateVenueMutationResponse,
	UpdateVenueMutationVariables,
} from '@prism-frontend/typedefs/graphql/VenueMutation';
import { AllVenuesQuery, AllVenuesResponse, SingleVenueQuery } from '@prism-frontend/typedefs/graphql/VenueQuery';
import { Stage } from '@prism-frontend/typedefs/stage';
import { Venue } from '@prism-frontend/typedefs/venue';
import { InstantiatedAPIResponseAndValidationResults } from '@prism-frontend/utils/decorators/validate-children';
import { fixDeprecatedColor } from '@prism-frontend/utils/static/stageHelper';
import _ from 'lodash';

type VenueStageMutationTypes = UpdateStageMutationVariables | (CreateStageMutationVariables & Pick<Stage, 'id'>);

type VenueForEditVenueFormComponent = Omit<CreateVenueMutationVariables, 'stages'> &
	Pick<UpdateVenueMutationVariables, 'id'> & {
		stages: VenueStageMutationTypes[];
	};

interface VenueAddressSuggestion {
	city_short: string;
	city_long: string;
	state_short: string;
	state_long: string;
	country_long: string;
	country_short: string;
	zip: string;
	address_1_short: string;
	address_1_long: string;
	address_2_short: string;
	address_2_long: string;
	google_place_id: string;
}

export interface GoogleVenueSuggestion {
	// main_text: "Liquid Church - Somerset County Campus",
	main_text: string;
	// secondary_text: "Davenport Street, Somerville, NJ, USA",
	secondary_text: string;
	// place_id: "ChIJU6-4pUmVw4kRE8WkrYuIY0o",
	place_id: string;
}

type FullGoogleVenueSuggestion = GoogleVenueSuggestion & {
	address: string;
	utc_offset: number;
};

export interface PrismVenueSuggestion extends VenueAddressSuggestion {
	// address: "606 E 7th St, Austin, TX 78701, USA";
	address: string;
	// coordinates_latitude: "30.2674008";
	coordinates_latitude: string;
	// coordinates_longitude: "-97.7360551";
	coordinates_longitude: string;
	// id: 1;
	id: number;
	// name: "Empire Control Room & Garage";
	name: string;
	// utc_offset: -360; (minutes offset from UTC)
	utc_offset: number;
	location_migration_failure: boolean;
}

export function isPrismVenueSuggestion(suggestion: VenueSuggestionTypes): suggestion is PrismVenueSuggestion {
	return suggestion && !!(suggestion as PrismVenueSuggestion).google_place_id;
}

export function isGoogleVenueSuggestion(suggestion: VenueSuggestionTypes): suggestion is GoogleVenueSuggestion {
	return suggestion && !!(suggestion as GoogleVenueSuggestion).place_id;
}

export function isFullGoogleVenueSuggestion(suggestion: VenueSuggestionTypes): suggestion is FullGoogleVenueSuggestion {
	return isGoogleVenueSuggestion(suggestion) && !!(suggestion as FullGoogleVenueSuggestion).address;
}

export type VenueSuggestionTypes = GoogleVenueSuggestion | FullGoogleVenueSuggestion | PrismVenueSuggestion;

export interface GooglePlaceDetailsResponse {
	status: 'OK';
	html_attributions: unknown[];
	result: {
		address_components: unknown[];
		formatted_address: string;
		formatted_phone_number: string;
		geometry: {
			location: {};
			viewport: {};
		};
		icon: string;
		international_phone_number: string;
		name: string;
		place_id: string;
		types: string[];
		url: string;
		utc_offset: number;
		vicinity: string;
	};
	structured_address_fields: {
		city_short: string;
		city_long: string;
		state_short: string;
		state_long: string;
		country_short: string;
		country_long: string;
		address_1_short: string;
		address_1_long: string;
		address_2_short: string;
		address_2_long: string;
		google_place_id: string;
		zip: string;
	};
}
export interface VenueSuggestionsResponse {
	search: string;
	prismVenueSuggestions: PrismVenueSuggestion[];
	googleVenueSuggestions: GoogleSuggestion[];
}
@Injectable()
export class VenueGraphqlService {
	public constructor(private prismGraphql: PrismGraphqlService, private apiService: ApiService) {}

	public fetchSingleVenue(id: number): Promise<Venue> {
		return this.prismGraphql
			.query<AllVenuesResponse>({
				query: SingleVenueQuery,
				variables: { id },
			})
			.then(async (response: AllVenuesResponse): Promise<Venue> => {
				return this.getInstantiatedVenue(response.venues[0]);
			});
	}

	public fetchVenues(): Promise<Venue[]> {
		return this.prismGraphql
			.query<AllVenuesResponse>({
				query: AllVenuesQuery,
			})
			.then(async (response: AllVenuesResponse): Promise<Venue[]> => {
				const results: InstantiatedAPIResponseAndValidationResults<Venue[]> = await instantiateApiResponse(
					Venue,
					response.venues
				);

				// Tech-debt (this should rather be a Laravel migration): fix colors for
				// stages if they are in green/blue/red format instead of #51bc51/#008bff/#ff887c
				fixDeprecatedColor(
					_.flatMap(results.data, (v: Venue): Stage[] => {
						return v.stages;
					})
				);

				// make sure to filter out users who don't have a name or an email
				return results.data;
			});
	}

	public createOrUpdateStage(stage: Stage): Promise<Stage> {
		if (stage.id) {
			return this.updateStage(stage);
		}
		return this.createStage(stage);
	}

	public createStage(stage: Stage): Promise<Stage> {
		return this.prismGraphql
			.mutate<CreateStageMutationVariables, CreateStageMutationResponse>({
				mutation: CreateStageMutation,
				variables: stage,
				awaitRefetchQueries: true,
				refetchQueries: [{ query: AllVenuesQuery }],
			})
			.then((response: CreateStageMutationResponse): Promise<Stage> => {
				return this.getInstantiatedStage(response.createStage);
			});
	}

	public updateStage({ id, name, color, capacity, active }: Stage): Promise<Stage> {
		const variables: UpdateStageMutationVariables = {
			id,
			name,
			color,
			capacity,
			active,
		};
		return this.prismGraphql
			.mutate<UpdateStageMutationVariables, UpdateStageMutationResponse>({
				mutation: UpdateStageMutation,
				variables,
				awaitRefetchQueries: true,
				refetchQueries: [{ query: AllVenuesQuery }],
			})
			.then((response: UpdateStageMutationResponse): Promise<Stage> => {
				return this.getInstantiatedStage(response.updateStage);
			});
	}

	public async createVenue({
		convert_to_usd,
		currency,
		default_logo_id,
		default_template_id,
		facility_fee,
		from_central_venue_id,
		should_create_central_venue,
		name,
		tax_rate,
		tax_type,
		timezone,
		stages,
		active,
		address_1_long,
		address_1_short,
		address_2_long,
		address_2_short,
		country_short,
		country_long,
		city_long,
		city_short,
		state_long,
		state_short,
		google_place_id,
		zip,
		default_hold_level,
		auto_promote_holds,
		contact_ids,
	}: Venue): Promise<Venue> {
		const variables: CreateVenueMutationVariables = {
			convert_to_usd,
			currency,
			default_logo_id,
			default_template_id,
			facility_fee,
			from_central_venue_id,
			should_create_central_venue,
			name,
			tax_rate,
			tax_type,
			timezone,
			active,
			stages,
			address_1_long,
			address_1_short,
			address_2_long,
			address_2_short,
			country_short,
			country_long,
			city_long,
			city_short,
			state_long,
			state_short,
			google_place_id,
			zip,
			default_hold_level,
			auto_promote_holds,
			contact_ids,
		};
		const response: CreateVenueMutationResponse = await this.prismGraphql.mutate<
			SharedVenueProps,
			CreateVenueMutationResponse
		>({
			mutation: CreateVenueMutation,
			variables: _.omit(variables, 'stages'),
			awaitRefetchQueries: true,
			refetchQueries: [{ query: AllVenuesQuery }],
		});
		const updatedVenue: Venue = await this.getInstantiatedVenue(response.createVenue);
		const stagePromises: Promise<Stage>[] = _.map(stages, (stage: Stage): Promise<Stage> => {
			return this.createStage({
				...stage,
				venue_id: updatedVenue.id,
			});
		});
		return Promise.all(stagePromises).then((newStages: Stage[]): Venue => {
			return new Venue({
				...updatedVenue,
				stages: newStages,
			});
		});
	}

	public updateVenue({
		id,
		convert_to_usd,
		currency,
		default_logo_id,
		default_template_id,
		facility_fee,
		from_central_venue_id,
		should_create_central_venue,
		name,
		tax_rate,
		tax_type,
		timezone,
		stages,
		active,
		address_1_long,
		address_1_short,
		address_2_long,
		address_2_short,
		country_short,
		country_long,
		city_long,
		city_short,
		state_long,
		state_short,
		google_place_id,
		zip,
		default_hold_level,
		auto_promote_holds,
		contact_ids,
	}: Venue): Promise<Venue> {
		const variables: VenueForEditVenueFormComponent = {
			id,
			convert_to_usd,
			currency,
			default_logo_id,
			default_template_id,
			facility_fee,
			from_central_venue_id,
			should_create_central_venue,
			name,
			tax_rate,
			tax_type,
			timezone,
			active,
			stages,
			address_1_long,
			address_1_short,
			address_2_long,
			address_2_short,
			country_short,
			country_long,
			city_long,
			city_short,
			state_long,
			state_short,
			google_place_id,
			zip,
			default_hold_level,
			auto_promote_holds,
			contact_ids,
		};
		const venueMutationVariables: Omit<VenueForEditVenueFormComponent, 'stages'> = _.omit(variables, ['stages']);
		return this.prismGraphql
			.mutate<Omit<VenueForEditVenueFormComponent, 'stages'>, UpdateVenueMutationResponse>({
				mutation: UpdateVenueMutation,
				variables: venueMutationVariables,
				awaitRefetchQueries: true,
				refetchQueries: [{ query: AllVenuesQuery }],
			})
			.then(async (response: Omit<UpdateVenueMutationResponse, 'stages'>): Promise<Venue> => {
				const updatedVenue: Venue = await this.getInstantiatedVenue(response.updateVenue);
				if (!active) {
					return updatedVenue;
				}
				const stagePromises: Promise<Stage>[] = _.map(stages, (stage: Stage): Promise<Stage> => {
					return this.createOrUpdateStage(stage);
				});
				return Promise.all(stagePromises).then((newStages: Stage[]): Venue => {
					return new Venue({
						...updatedVenue,
						stages: newStages,
					});
				});
			});
	}

	private async getInstantiatedEntity<T>(constructor: unknown, entity: T): Promise<T> {
		const result: InstantiatedAPIResponseAndValidationResults<T> = await instantiateApiResponse(
			constructor,
			entity as unknown as object
		);
		return result.data;
	}

	private async getInstantiatedVenue(venue: Venue): Promise<Venue> {
		return this.getInstantiatedEntity<Venue>(Venue, venue);
	}

	private async getInstantiatedStage(venue: Stage): Promise<Stage> {
		return this.getInstantiatedEntity<Stage>(Stage, venue);
	}

	public async getVenueSuggestions(search: string): Promise<VenueSuggestionsResponse> {
		return this.apiService
			.getS<{ params: { search: string } }, VenueSuggestionsResponse>(this.apiService.ep.VENUE_SUGGESTIONS, {
				params: { search },
			})
			.toPromise()
			.then((response: VenueSuggestionsResponse): VenueSuggestionsResponse => {
				return {
					search,
					...response,
				};
			});
	}
}
