import { Currency } from '@prism-frontend/typedefs/enums/currency';
import _ from 'lodash';

export function formatAmount(amount: number, currency: string): string {
	if (!_.isNumber(amount) || isNaN(amount)) {
		return '';
	}
	// Sometimes this function was producing -0 as formatted output.
	// by casting to a string and back to a number, if the number is 0,
	// we effectively drop the leading -. JS is fun.
	amount = Number(String(amount));
	if (!currency) {
		currency = Currency.USD;
	}
	return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(amount);
}
