import { AfterType } from '@prism-frontend//typedefs/enums/AfterType';
import { BonusTypes } from '@prism-frontend//typedefs/enums/BonusTypes';
import { EMSPropsForBonus } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { CostCalc } from '@prism-frontend/typedefs/enums/calc';
import { Currency } from '@prism-frontend/typedefs/enums/currency';
import { DealTypes } from '@prism-frontend/typedefs/enums/deal-types';
import { EventPropsForTalentGuarantee } from '@prism-frontend/typedefs/EventPropsForTalentGuarantee';
import { RetroactiveBonus, RetroactiveBonusResult } from '@prism-frontend/typedefs/retroactiveBonus';
import { TalentData } from '@prism-frontend/typedefs/talentData';
import { castToNumber } from '@prism-frontend/utils/transformers/castToNumber';
import { plainToClass, Transform } from 'class-transformer';
import { IsEnum, IsNumber, IsOptional } from 'class-validator';

/**
 * the interface for bonus numbers that are stored in EMS. `amount` is the total amount for the
 * bonus, and `selectedRetroBonusId` is a valid retro bonus ID if one was triggered and its value was
 * used, or null otherwise
 */
interface BonusAmountAndRetroTrigger {
	amount: number;
	selectedRetroBonusId: number | null;
}

export class Bonus {
	public constructor(adjustment?: Partial<Bonus>) {
		return plainToClass(Bonus, adjustment);
	}

	@IsNumber() public id: number;

	@IsNumber() public event_talent_id: number;

	@IsEnum(BonusTypes)
	@IsOptional()
	public type: BonusTypes | undefined = BonusTypes.Net;

	@IsNumber()
	@Transform(castToNumber())
	public amount: number = 0;

	@IsEnum(AfterType)
	@IsOptional()
	public after: AfterType | undefined = undefined;

	@IsNumber()
	@Transform(castToNumber())
	public after_amount: number = 0;

	/**
	 * Calculates the bonus amount for the artist payout
	 * considering the talent deal configuration for the
	 * current bonus type. This will be part of the artist
	 * payout
	 * @param costCalc The cost calc
	 * @param eventProps The talent guarantee event props
	 * @param dealTerm The talent deal data
	 * @param gross the gross amount
	 * @param splitPoint the split point
	 * @param ticketsSold the amount of ticket solds
	 * @param totalSellable the total sellable amount
	 * @param royalty the royality amount
	 * @param showNegative if the payout should show negative
	 * @returns The bonus amount for the artist payout
	 */
	public calculatePayout(
		costCalc: CostCalc,
		eventProps: EventPropsForTalentGuarantee,
		dealTerm: TalentData,
		gross: number,
		splitPoint: number,
		ticketsSold: number,
		totalSellable: number,
		royalty: number,
		showNegative: boolean
	): number {
		if (dealTerm.deal_type === DealTypes.Flat) {
			return 0;
		}

		let bonusAmount: number = this.calculateBonusByType(
			dealTerm,
			gross,
			ticketsSold,
			totalSellable,
			splitPoint,
			royalty
		).amount;

		// Enforce deal term cap, if there is one
		if (
			// UI only lets you add a cap to Net or Gross bonuses, this first check is
			// theoretically unnecessary
			(this.type === BonusTypes.Net || this.type === BonusTypes.NetGross) &&
			dealTerm.capped
		) {
			const uncappedSum: number = bonusAmount + dealTerm.convertedGuarantee(costCalc, eventProps);
			if (
				dealTerm.deal_type === DealTypes.Plus &&
				// For Plus deals, the cap applies to the total of the bonus and the guarantee
				uncappedSum > dealTerm.cap_amount
			) {
				bonusAmount = dealTerm.cap_amount - dealTerm.convertedGuarantee(costCalc, eventProps);
			} else if (
				// implicitely, the dealTerm.deal_type here is either Versus or DoorDeal.
				bonusAmount > dealTerm.cap_amount
			) {
				// in a Versus or DoorDeal, the artist is paid the the guarantee OR the bonus. Not both
				bonusAmount = dealTerm.cap_amount;
			}
		}

		if (bonusAmount < 0 && !showNegative) {
			bonusAmount = 0;
		}

		return Number(bonusAmount);
	}

	public getSelectedRetroBonusId(
		talentData: TalentData,
		gross: number,
		splitPoint: number,
		ticketsSold: number,
		totalSellable: number,
		royalty: number
	): number {
		return this.calculateBonusByType(talentData, gross, ticketsSold, totalSellable, splitPoint, royalty)
			.selectedRetroBonusId;
	}

	public get isPercentageBonus(): boolean {
		return this.type === BonusTypes.Net || this.type === BonusTypes.NetGross;
	}

	// When convert artist pay to USD is toggled on, per_ticket and flat bonuses are calculated based on a fixed USD amount and the currency symbol should reflect that
	// This doesn't apply to the other bonus types which are percentage(net/gross) based and calculated from the base currency.
	public currency(emsPropsForBonus: EMSPropsForBonus): Currency {
		if (emsPropsForBonus.artistPayCurrency === Currency.USD && !this.isPercentageBonus) {
			return Currency.USD;
		}
		return emsPropsForBonus.currency;
	}

	private calculateBonusByType(
		talentData: TalentData,
		gross: number,
		ticketsSold: number,
		totalSellable: number,
		splitPoint: number,
		royalty: number
	): BonusAmountAndRetroTrigger {
		switch (this.type) {
			case BonusTypes.Net:
				return this.calculateNetBonus(talentData, gross, ticketsSold, totalSellable, splitPoint, royalty);
			case BonusTypes.NetGross:
				return this.calculateGrossBonus(talentData, gross, ticketsSold, totalSellable);
			case BonusTypes.PerTicket:
				return {
					amount: this.calculatePerTicketBonus(gross, ticketsSold, totalSellable),

					// per-ticket bonuses dont have retroactive bonuses
					selectedRetroBonusId: null,
				};
			case BonusTypes.Flat:
				return {
					amount: this.calculateFlatBonus(gross, ticketsSold, totalSellable),

					// per-ticket bonuses dont have retroactive bonuses
					selectedRetroBonusId: null,
				};
			default:
				throw new Error(`invalid bonus type: ${this.type}`);
		}
	}

	/**
	 * for this bonus, evaluate all retroactive bonuses attached to the talent deal to determine
	 * their payout value, and whether the retro bounses are triggered based on the tickets sold
	 * return the bonuses in a re-mapped array, making it easy to determine the retro bonus winner
	 * if any
	 *
	 * @param talentData the talent deal on which the bonus and retro bonuses live
	 * @param ticketsSold the number of tickets sold; used to determine if the retro bonus triggers
	 * @param totalSellable number of total sellable tickets; used to determine % thresholds
	 * @param totalForBonus the total used for the retro bonus percentage
	 * @returns an array of mapped retro bonuses, with their amounts, and if they were triggered or not
	 */
	private getAllRetroResults(
		talentData: TalentData,
		ticketsSold: number,
		totalSellable: number,
		totalForBonus: number
	): RetroactiveBonusResult[] {
		// fail out early if there are no retro bonuses
		if (!talentData.retroactive_bonus) {
			return [];
		}

		return talentData.retroactive_bonuses.map((retroBonus: RetroactiveBonus): RetroactiveBonusResult => {
			// calculate the retro bonus based on the total
			const calculatedRetroctiveBonus: number = totalForBonus * (retroBonus.retroactive_bonus_percentage / 100);

			// store the at/after amount for comparison later on
			let retroactiveAmount: number = 0;
			if (talentData.retroactive_after_type === AfterType.TicketsSold) {
				retroactiveAmount = retroBonus.retroactive_after_amount;
			}

			if (talentData.retroactive_after_type === AfterType.PercentSellThrough) {
				retroactiveAmount = Math.round((retroBonus.retroactive_after_amount / 100) * totalSellable);
			}

			return {
				retroBonusId: retroBonus.id,
				isBonusTriggered: retroBonus.checkThreshold(ticketsSold, retroactiveAmount),
				bonusAmount: calculatedRetroctiveBonus,
				triggerAmount: retroBonus.retroactive_after_amount,
			};
		});
	}

	/**
	 * for a talent deal, and some event metrics, return a retro bonus amount, and a boolean that is true
	 * if the bonus should be triggered, false otherwise
	 *
	 * @param talentData the talent data on which the retroactive bonus is being applied
	 * @param ticketsSold the number of tickets sold at the event, used for checking to see if the bonnus triggered or not
	 * @param totalSellable the number of total sellable tickets, used to calculate the retroactive amount for percent sell through
	 * @param totalForBonus the total number to be used to compute the bonus, based on the selected bonus' bonus percentage
	 * @returns a retro bonus amount, and a boolean that is true if the bonus should be triggered, false otherwise
	 */
	private selectedRetroBonus(
		talentData: TalentData,
		ticketsSold: number,
		totalSellable: number,
		totalForBonus: number
	): RetroactiveBonusResult {
		// get all retro results, filter out non-triggered results, and sort in the following order
		// 	1) sort highest `retroactive_after_amount`s first
		// 	2) on ties, sort highest bonus amount first
		const triggeredRetroResults: RetroactiveBonusResult[] = this.getAllRetroResults(
			talentData,
			ticketsSold,
			totalSellable,
			totalForBonus
		)
			.filter((result: RetroactiveBonusResult): boolean => {
				return result.isBonusTriggered;
			})
			.sort((a: RetroactiveBonusResult, b: RetroactiveBonusResult): number => {
				// if triggerAmounts are the same, sort the higher payout amount higher
				if (a.triggerAmount === b.triggerAmount) {
					return a.bonusAmount >= b.bonusAmount ? -1 : 1;
				}

				// by default, sort by trigger amount
				return a.triggerAmount > b.triggerAmount ? -1 : 1;
			});

		// return early if we dont have any triggerd retro bonus results
		if (!triggeredRetroResults.length) {
			return {
				retroBonusId: null,
				bonusAmount: 0,
				isBonusTriggered: false,
				triggerAmount: 0,
			};
		}

		// if we've gotten to this point, this array is sorted in descending order of
		// retro bonus payout, and has at least one value
		// this first value in the array is the "selected" retro bonus - the highest %
		// retro bonus in this deal
		return triggeredRetroResults[0];
	}

	private calculateNetBonus(
		talentData: TalentData,
		gross: number,
		ticketsSold: number,
		totalSellable: number,
		splitPoint: number,
		royalty: number
	): BonusAmountAndRetroTrigger {
		const percentage: number = this.amount / 100;
		let afterAmount: number = 0;
		if (this.after === AfterType.Manual) {
			afterAmount = this.after_amount;
		}
		if (this.after === AfterType.Costs) {
			/**
			 * Royalty affects the afterAmount when it exists.
			 * However we don't want the original split point to be affected by the royalty.
			 * We also factor the royalty into netRevenueToSplit
			 * at the event level because of this nuance where it shouldn't be
			 * factored into split point.
			 */
			afterAmount = splitPoint + royalty;
		}
		const netAmount: number = gross - afterAmount;

		// get the standard and retro bonuses
		const standardBonus: number = netAmount * percentage;
		const selectedRetroBonus: RetroactiveBonusResult = this.selectedRetroBonus(
			talentData,
			ticketsSold,
			totalSellable,
			netAmount
		);

		// there is a retroactive bonus and the requirement is met, use the retro bonus
		// else use the standard bonus
		return {
			amount: selectedRetroBonus.isBonusTriggered ? selectedRetroBonus.bonusAmount : standardBonus,
			selectedRetroBonusId: selectedRetroBonus.isBonusTriggered ? selectedRetroBonus.retroBonusId : null,
		};
	}

	private calculateGrossBonus(
		talentData: TalentData,
		gross: number,
		ticketsSold: number,
		totalSellable: number
	): BonusAmountAndRetroTrigger {
		const percentage: number = this.amount / 100;
		// this is the standard bonus
		const standardBonus: number = gross * percentage;

		const selectedRetroBonus: RetroactiveBonusResult = this.selectedRetroBonus(
			talentData,
			ticketsSold,
			totalSellable,
			gross
		);

		// there is a retroactive bonus and the requirement is met
		return {
			amount: selectedRetroBonus.isBonusTriggered ? selectedRetroBonus.bonusAmount : standardBonus,
			selectedRetroBonusId: selectedRetroBonus.isBonusTriggered ? selectedRetroBonus.retroBonusId : null,
		};
	}

	private calculatePerTicketBonus(_gross: number, ticketsSold: number, totalSellable: number): number {
		let bonusTickets: number;
		// Plus $5 per ticket after 20 tickets sold gets $5 at 21 tickets
		if (this.after === AfterType.TicketsSold) {
			// if negative lets use 0
			bonusTickets = Math.max(0, ticketsSold - this.after_amount);
			return this.amount * bonusTickets;
		}
		if (this.after !== AfterType.PercentSellThrough) {
			return 0;
		}
		// TODO PRSM-XXXX should this only be for tickets after the after amount? Or is retroactive
		const numberOfTicketsRequired: number = Math.round((this.after_amount / 100) * totalSellable);
		// if negative lets use 0
		bonusTickets = Math.max(0, ticketsSold - numberOfTicketsRequired);
		return this.amount * bonusTickets;
	}

	private calculateFlatBonus(gross: number, ticketsSold: number, totalSellable: number): number {
		let conditionMet: 0 | 1;
		if (this.after === AfterType.TicketsSold) {
			// if negative lets use 0
			conditionMet = ticketsSold >= this.after_amount ? 1 : 0;
			return this.amount * conditionMet;
		}
		if (this.after === AfterType.PercentSellThrough) {
			const numberOfTicketsRequired: number = Math.round((this.after_amount / 100) * totalSellable);
			// if negative lets use 0
			conditionMet = ticketsSold >= numberOfTicketsRequired ? 1 : 0;
			return this.amount * conditionMet;
		}
		if (this.after !== AfterType.Manual) {
			return 0;
		}
		// TODO PRSM-XXXX should this only be for tickets after the after amount? Or is retroactive
		const manualSplitPoint: number = this.after_amount;
		conditionMet = gross > manualSplitPoint ? 1 : 0;
		return this.amount * conditionMet;
	}
}
