import { AllSimpleFormFieldConfigs } from '@prism-frontend/components/simple-form/simple-form.typedefs';
import {
	FunctionalChip,
	FunctionalChipFunctionResult,
	FunctionalChipOrgData,
} from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/FunctionalChip';
import { FunctionalChipAdditionalData } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/FunctionalChipAdditionalData';
import { AllCostCalc2 } from '@prism-frontend/typedefs/AllCostCalc2';
import { CostOption } from '@prism-frontend/typedefs/cost';
import { CostGroupCategoryOptions } from '@prism-frontend/typedefs/CostGroupCategoryOptions';
import { BroadwayCostGroupTypeOptions, CostGroupTypeOptions } from '@prism-frontend/typedefs/CostGroupTypeOptions';
import { EMS, EMSFixedCost } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';
import { CostGroupCategory } from '@prism-frontend/typedefs/enums/CostGroupCategory';
import { CostGroupType } from '@prism-frontend/typedefs/enums/CostGroupType';
import _ from 'lodash';

const ALL_CATEGORIES_LABEL: string = '(All Categories)';
const ALL_TYPES_LABEL: string = '(All Types)';
const HAS_NO_TYPE_LABEL: string = '(Has No Type)';

export interface FindCostGroupCategoryArg {
	name: string;
	costCalc: CostCalc2;
	category: CostGroupCategory | 'meta--all-categories';
	type: CostGroupType | 'meta--all-types' | 'meta--type-is-not-set';
}

export function getFixedCostGroupFormFields(
	isBroadway: boolean
): AllSimpleFormFieldConfigs<FindCostGroupCategoryArg>[] {
	// Add the 'General > Documented' option if the org has Broadway feature
	let costGroupTypeOptions: CostOption[] = CostGroupTypeOptions;
	if (isBroadway) {
		costGroupTypeOptions = costGroupTypeOptions.concat(BroadwayCostGroupTypeOptions);
	}
	return [
		{
			label: 'Category',
			description: 'Choose a cost group Category',
			fieldType: 'select',
			key: 'category',
			required: true,
			editable: true,
			suggestions: [{ label: ALL_CATEGORIES_LABEL, value: 'meta--all-categories' }, ...CostGroupCategoryOptions],
		},
		{
			label: 'Type',
			description: 'Choose a cost group Type',
			fieldType: 'select',
			key: 'type',
			required: true,
			editable: true,
			suggestions: [
				{ label: ALL_TYPES_LABEL, value: 'meta--all-types' },
				{ label: HAS_NO_TYPE_LABEL, value: 'meta--type-is-not-set' },
				...costGroupTypeOptions,
			],
		},
	];
}

export function getFixedCostsGroups(ems: EMS, argument: FindCostGroupCategoryArg): EMSFixedCost[] {
	return _.filter(ems.fixedCosts, (costGroup: EMSFixedCost): boolean => {
		// If 'category' is 'meta--all-categories', then it includes all categories.

		// If type is also 'meta--all-types', then all types are included, so everything matches.
		// If type is 'meta--type-is-not-set', it only includes those costs without a specified group type.
		// Otherwise, it only includes groups with the type specified in the argument.
		// If 'type' is 'meta--all-types', it only includes those costs in the specified category.

		// If type is 'meta--type-is-not-set', it only includes those costs in the specified category without a specified group type.

		// Otherwise, it only includes groups with both the category and type specified in the arguments.
		if (argument.category === 'meta--all-categories') {
			if (argument.type === 'meta--all-types') {
				return true;
			}
			if (argument.type === 'meta--type-is-not-set') {
				return !costGroup.groupType;
			}
			return costGroup.groupType === argument.type;
		}
		if (argument.type === 'meta--all-types') {
			return costGroup.groupCategory === argument.category;
		}
		if (argument.type === 'meta--type-is-not-set') {
			return costGroup.groupCategory === argument.category && !costGroup.groupType;
		}
		return costGroup.groupCategory === argument.category && costGroup.groupType === argument.type;
	});
}

export const findFixedCostGroupByName: FunctionalChip<FindCostGroupCategoryArg> = {
	identifier: 'findFixedCostGroupByName',
	name: 'Find Fixed Cost Group',
	fallbackValue: (): string => {
		return '0';
	},
	theFunction(
		argument: FindCostGroupCategoryArg,
		_orgData: FunctionalChipOrgData,
		data: FunctionalChipAdditionalData
	): FunctionalChipFunctionResult {
		const matchingGroups: EMSFixedCost[] = getFixedCostsGroups(
			data.emsRollup[argument.costCalc || CostCalc2.ExternalReported],
			argument
		);
		const values: FunctionalChipFunctionResult[] = _.map(
			matchingGroups,
			(group: EMSFixedCost): FunctionalChipFunctionResult => {
				return group.costsComputedTotal || 0;
			}
		);
		return _.sum(values);
	},
	formFields(
		data: FunctionalChipOrgData,
		_value: FindCostGroupCategoryArg
	): AllSimpleFormFieldConfigs<FindCostGroupCategoryArg>[] {
		return [
			...getFixedCostGroupFormFields(data.isBroadway),
			{
				label: 'Calculation',
				description: '',
				fieldType: 'select',
				// TODO PRSM-XXXX map cost calc to human readable
				suggestions: AllCostCalc2,
				key: 'costCalc',
				required: true,
				editable: true,
			},
		];
	},
	chipText: (value: FindCostGroupCategoryArg): string => {
		let displayCategory: string = value.category;
		if (value.category === 'meta--all-categories') {
			displayCategory = ALL_CATEGORIES_LABEL;
		}
		let displayType: string = value.type;
		if (value.type === 'meta--all-types') {
			displayType = ALL_TYPES_LABEL;
		}
		if (value.type === 'meta--type-is-not-set') {
			displayType = HAS_NO_TYPE_LABEL;
		}
		// TODO PRSM-XXXX map cost calc to human readable when we make this a user-facing feature
		return `Find Fixed Cost Group Total: ${displayCategory} - ${displayType} - ${value.costCalc}`;
	},
};
