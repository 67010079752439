import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApolloError } from '@apollo/client/errors';
import { ConfirmDialogComponent } from '@prism-frontend/components/confirm-dialog/confirm-dialog.component';
import { Artist } from '@prism-frontend/entities/artists/artist-typedefs';
import { ImporterContact } from '@prism-frontend/entities/contacts/contacts-table-contact-importer/contact-importer-modal.service';
import { ContactImporterResult } from '@prism-frontend/pages/admin-page/components/contact-importer/contact-importer.component';
import { PrismGraphqlService } from '@prism-frontend/services/api/graphql/prism-graphql.service';
import { instantiateApiResponse } from '@prism-frontend/services/legacy/api.service';
import { BulkContactsEdit, Contact, ContactSuggestion } from '@prism-frontend/typedefs/contact';
import { ContactRole } from '@prism-frontend/typedefs/ContactRole';
import {
	AllContactsQuery,
	AllContactsResponse,
	BulkEditContactMutation,
	BulkEditContactsMutationResponse,
	BulkEditContactsMutationVariables,
	BulkEditContactsResult,
	ContactSuggestionsQuery,
	ContactSuggestionsResponse,
	CreateContactMutation,
	CreateContactMutationResponse,
	CreateContactMutationVariables,
	DeleteContactMutation,
	DeleteContactMutationArguments,
	DeleteContactMutationResponse,
	FinalImporterContact,
	FullContactResponse,
	ImportContactListMutation,
	ImportContactListMutationResponse,
	ImportContactListMutationVariables,
	SingleContactQuery,
	UpdateContactMutation,
	UpdateContactMutationResponse,
	UpdateContactMutationVariable,
} from '@prism-frontend/typedefs/graphql/ContactsQuery';
import { InstantiatedAPIResponseAndValidationResults } from '@prism-frontend/utils/decorators/validate-children';
import {
	extractGraphqlErrorsByKey,
	surfaceAllApolloValidationErrorsInAMatDialog,
} from '@prism-frontend/utils/static/graphq-validation-errors';

@Injectable()
export class ContactGraphqlService {
	public constructor(private prismGraphql: PrismGraphqlService, private matDialog: MatDialog) {}

	public fetchSingleContact(id: number): Promise<Contact> {
		return this.prismGraphql
			.query<FullContactResponse>({
				query: SingleContactQuery,
				variables: { id },
			})
			.then(async (response: FullContactResponse): Promise<Contact> => {
				const results: InstantiatedAPIResponseAndValidationResults<Contact> = await instantiateApiResponse(
					Contact,
					response.contact
				);

				return results.data;
			});
	}

	/**
	 * fetch contact suggestions, which power the contact-select dropdown
	 */
	public fetchContactSuggestions(): Promise<ContactSuggestion[]> {
		return this.prismGraphql
			.query<ContactSuggestionsResponse>({
				query: ContactSuggestionsQuery,
			})
			.then(async (response: ContactSuggestionsResponse): Promise<ContactSuggestion[]> => {
				const results: InstantiatedAPIResponseAndValidationResults<Contact[]> = await instantiateApiResponse(
					Contact,
					response.contacts
				);

				// make sure to filter out users who don't have a name or an email
				return results.data;
			});
	}

	/**
	 * fetch contact list
	 */
	public fetchContacts(): Promise<Contact[]> {
		return this.prismGraphql
			.query<AllContactsResponse>({
				query: AllContactsQuery,
			})
			.then(async (response: AllContactsResponse): Promise<Contact[]> => {
				const results: InstantiatedAPIResponseAndValidationResults<Contact[]> = await instantiateApiResponse(
					Contact,
					response.contacts
				);
				// make sure to filter out users who don't have a name or an email
				return results.data;
			});
	}

	public saveNewContact(contact: Contact, eventId: number | null = null): Promise<Contact> {
		const {
			name,
			company,
			email,
			notes,
			phone,
			contact_roles,
			address_1_long,
			address_1_short,
			address_2_long,
			address_2_short,
			country_short,
			country_long,
			city_long,
			city_short,
			state_long,
			state_short,
			google_place_id,
			talent_agents,
			zip,
		}: Contact = contact;
		const variables: CreateContactMutationVariables = {
			eventId,
			name,
			company,
			email,
			notes,
			phone,
			contact_role_ids: contact_roles.map((role: ContactRole): number => {
				return role.id;
			}),
			address_1_long,
			address_1_short,
			address_2_long,
			address_2_short,
			country_short,
			country_long,
			city_long,
			city_short,
			state_long,
			state_short,
			google_place_id,
			talent_agent_ids: talent_agents?.map((talent: Artist): number => {
				return talent.id;
			}),
			zip,
		};
		return this.prismGraphql
			.mutate<CreateContactMutationVariables, CreateContactMutationResponse>({
				mutation: CreateContactMutation,
				variables,
				refetchQueries: [
					{
						query: AllContactsQuery,
					},
					{
						query: ContactSuggestionsQuery,
					},
				],
			})
			.then(async (result: CreateContactMutationResponse): Promise<Contact> => {
				const response: InstantiatedAPIResponseAndValidationResults<Contact> = await instantiateApiResponse(
					Contact,
					result.createContact
				);
				return response.data;
			});
	}

	public updateContact(contact: Contact, eventId: number | null = null): Promise<Contact> {
		const {
			id,
			name,
			company,
			email,
			notes,
			phone,
			contact_roles,
			address_1_long,
			address_1_short,
			address_2_long,
			address_2_short,
			country_short,
			country_long,
			city_long,
			city_short,
			state_long,
			state_short,
			google_place_id,
			talent_agents,
			zip,
		}: Contact = contact;
		const variables: UpdateContactMutationVariable = {
			id,
			name,
			company,
			email,
			notes,
			phone,
			contact_role_ids: contact_roles.map((role: ContactRole): number => {
				return role.id;
			}),
			eventId,
			address_1_long,
			address_1_short,
			address_2_long,
			address_2_short,
			country_short,
			country_long,
			city_long,
			city_short,
			state_long,
			state_short,
			google_place_id,
			talent_agent_ids: talent_agents?.map((talent: Artist): number => {
				return talent.id;
			}),
			zip,
		};
		return this.prismGraphql
			.mutate<UpdateContactMutationVariable, UpdateContactMutationResponse>({
				mutation: UpdateContactMutation,
				variables,
				refetchQueries: [
					{
						query: AllContactsQuery,
					},
					{
						query: ContactSuggestionsQuery,
					},
				],
			})
			.then(async (result: UpdateContactMutationResponse): Promise<Contact> => {
				const response: InstantiatedAPIResponseAndValidationResults<Contact> = await instantiateApiResponse(
					Contact,
					result.updateContact
				);
				return response.data;
			});
	}

	public bulkUpdateContacts(bulkEdit: BulkContactsEdit): Promise<BulkEditContactsResult> {
		const variables: BulkEditContactsMutationVariables = {
			contactIds: bulkEdit.contact_ids,
			talentAgentIds: bulkEdit.artists.map((artist: Artist): number => {
				return artist.id;
			}),
		};
		return this.prismGraphql
			.mutate<BulkEditContactsMutationVariables, BulkEditContactsMutationResponse>({
				mutation: BulkEditContactMutation,
				variables,
				refetchQueries: [
					{
						query: AllContactsQuery,
					},
					{
						query: ContactSuggestionsQuery,
					},
				],
			})
			.then((response: BulkEditContactsMutationResponse): BulkEditContactsResult => {
				return {
					contacts: response.bulkAddContactsClients.map((contact: Contact): Contact => {
						return new Contact(contact);
					}),
					hasErrors: false,
				};
			});
	}

	public async deleteContact(contact: Contact): Promise<boolean> {
		const contactName: string = contact.name || 'this Contact';
		const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.matDialog.open(ConfirmDialogComponent, {
			data: {
				heading: `Delete ${contactName}`,
				message: `Are you sure? This will remove ${contactName} from all associated events. This action cannot be undone.`,
				cancelButtonCaption: 'Cancel',
				confirmButtonCaption: 'Delete',
			},
		});

		const result: boolean = await dialogRef.afterClosed().toPromise();
		if (!result) {
			return false;
		}
		return this.prismGraphql
			.mutateAndIWillHandleTheErrorMyself<DeleteContactMutationArguments, DeleteContactMutationResponse>({
				mutation: DeleteContactMutation,
				variables: {
					id: contact.id,
				},
				refetchQueries: [
					{
						query: AllContactsQuery,
					},
					{
						query: ContactSuggestionsQuery,
					},
				],
			})
			.then((_results: DeleteContactMutationResponse): boolean => {
				return true;
			})
			.catch((error: ApolloError): boolean => {
				surfaceAllApolloValidationErrorsInAMatDialog(this.matDialog, error);
				return false;
			});
	}

	public importContactList(contacts: ImporterContact[]): Promise<ContactImporterResult> {
		return this.prismGraphql
			.mutateAndIWillHandleTheErrorMyself<ImportContactListMutationVariables, ImportContactListMutationResponse>({
				variables: {
					contacts: contacts.map((contact: ImporterContact): FinalImporterContact => {
						const importerContact: FinalImporterContact = {
							address_2_short: contact.address_2_short,
							city_long: contact.city_long,
							city_short: contact.city_short,
							company: contact.company,
							google_place_id: contact.google_place_id,
							id: contact.id,
							name: contact.name,
							state_long: contact.state_long,
							state_short: contact.state_short,
							zip: contact.zip,
							address_1_long: contact.address_1_long,
							address_1_short: contact.address_1_short,
							address_2_long: contact.address_2_long,
							country_long: contact.country_long,
							country_short: contact.country_short,
							email: contact.email,
							notes: contact.notes,
							phone: contact.phone,
							contact_role_names: contact.contact_roles_string.split(/,\s+/),
							artist_names: contact.artist_names?.replaceAll(', ', ','),
						};
						return importerContact;
					}),
				},
				mutation: ImportContactListMutation,
				refetchQueries: [
					{
						query: AllContactsQuery,
					},
					{
						query: ContactSuggestionsQuery,
					},
				],
			})
			.then((response: ImportContactListMutationResponse): ContactImporterResult => {
				return {
					contacts: response.importContactList,
					hasErrors: false,
				};
			})
			.catch((error: ApolloError): ContactImporterResult => {
				const errorsByIndex: Record<number, string[]> = extractGraphqlErrorsByKey(
					error,
					(key: string): string => {
						// keys from backend are something like
						// `contact.4.email`. We just want the 4
						return key.match(/(\d+)/g)[0];
					}
				);
				return {
					contacts: [],
					hasErrors: true,
					errorsByIndex,
				};
			});
	}
}
