import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadingStrategy, Route, RouterModule } from '@angular/router';
import { environment } from '@prism-frontend/../environments/environment';
import { AppComponent } from '@prism-frontend/app.component';
import { ROUTES } from '@prism-frontend/app.routing.module';
import { VERSION } from '@prism-frontend/app.version';
import { JumpToEventModule } from '@prism-frontend/components/jump-to-event/jump-to-event.module';
import { ThemeToggleComponent } from '@prism-frontend/components/theme-toggle/theme-toggle.component';
import { UserInitialsModule } from '@prism-frontend/components/user-initials/user-initials.module';
import { SmartTableHelperService } from '@prism-frontend/entities/_base/smart-table/smart-table-helper.service';
import { MaterialModule } from '@prism-frontend/modules/material.module';
import { LoginPageComponent } from '@prism-frontend/pages/login-page/login-page.component';
import { APIServicesModule } from '@prism-frontend/services/api/api-services.module';
import { PrismGraphqlModule } from '@prism-frontend/services/api/graphql/prism-graphql.module';
import { LinkDealApiService } from '@prism-frontend/services/api/link-deal-api.service';
import { RefreshService } from '@prism-frontend/services/deprecated/refresh.service';
import { ApiService } from '@prism-frontend/services/legacy/api.service';
import { PermissionsService } from '@prism-frontend/services/legacy/api/permissions.service';
import { UserService } from '@prism-frontend/services/legacy/api/user.service';
import { CachingInterceptor } from '@prism-frontend/services/legacy/http-interceptors/caching-interceptor.service';
import { ErrorInterceptor } from '@prism-frontend/services/legacy/http-interceptors/error-interceptor.service';
import { JwtInterceptor } from '@prism-frontend/services/legacy/http-interceptors/jwt-interceptor.service';
import { LoginService } from '@prism-frontend/services/legacy/login.service';
import { RequestCacheService } from '@prism-frontend/services/legacy/request-cache.service';
import { StorageService } from '@prism-frontend/services/legacy/storage.service';
import { UserTokenStorageService } from '@prism-frontend/services/legacy/user-token-storage.service';
import { AdminModeService } from '@prism-frontend/services/ui/admin-mode.service';
import { ComponentModalizerService } from '@prism-frontend/services/ui/component-modalizer.service';
import { EMSFieldService } from '@prism-frontend/services/ui/ems-field.service';
import { PrismStringsService } from '@prism-frontend/services/ui/prism-strings.service';
import { PrismTitleService } from '@prism-frontend/services/ui/prism-title.service';
import { WindowService } from '@prism-frontend/services/ui/window.service';
import { ConnectionService } from '@prism-frontend/services/utils/connection.service';
import { FeatureGateService } from '@prism-frontend/services/utils/feature-gate.service';
import { PrismAnalyticsService } from '@prism-frontend/services/utils/prism-analytics.service';
import { PromiseQueueService } from '@prism-frontend/services/utils/promise-queue.service';
import { PusherService } from '@prism-frontend/services/utils/pusher.service';
import { SpinnerService } from '@prism-frontend/services/utils/spinner.service';
import { EnvironmentNames } from '@prism-frontend/typedefs/environment.typedef';
import { PipesModule } from '@prism-frontend/utils/pipes/pipes.module';
import { ApiConfig } from '@prism-frontend/utils/static/app.api.config';
import { PrismErrorHandler } from '@prism-frontend/utils/static/error.handler';
import { packageVersionJson } from '@prism-frontend/utils/static/packageVersionJson';
import { WINDOW_WITH_PRISM_VERSIONS } from '@prism-frontend/utils/static/WindowWithPrismVersions';
import { MarkdownModule, MarkdownService } from 'ngx-markdown';
import { SegmentConfig, SegmentModule, SegmentService, SEGMENT_CONFIG } from 'ngx-segment-analytics';
import { Observable, of, timer } from 'rxjs';
import { flatMap } from 'rxjs/operators';

window.process = require('process/browser');

export function segmentConfigProvided(): SegmentConfig {
	const envIsProdOrTest: boolean =
		environment.name === EnvironmentNames.Prod ||
		environment.name === EnvironmentNames.Dev ||
		environment.name === EnvironmentNames.QA;
	const envIsLocal: boolean = environment.name === EnvironmentNames.Local;

	return {
		apiKey: environment.segment_api_key,
		debug: envIsProdOrTest || envIsLocal,
		loadOnInitialization: envIsProdOrTest || !!environment.segment_api_key,
	};
}

@Injectable({
	providedIn: 'root',
})
export class PrismPreloadingStrategyProvided implements PreloadingStrategy {
	public preload(route: Route, load: () => Observable<unknown>): Observable<unknown> {
		if (route.data && route.data.preload) {
			return timer(2000).pipe(
				flatMap((_: number): Observable<unknown> => {
					return load();
				})
			);
		}
		return of(null);
	}
}

/**
 * the top level app module. only put things in this module that contain logic
 * that can be shown to an un-authed user
 *
 * put all remaining business logic in their respective page-level modules, if possible
 */
@NgModule({
	declarations: [AppComponent, LoginPageComponent],
	imports: [
		CommonModule,
		BrowserAnimationsModule,
		BrowserModule,
		HttpClientModule,
		JumpToEventModule,
		FormsModule,
		MaterialModule,
		MarkdownModule.forRoot(),
		PipesModule,
		PrismGraphqlModule,
		RouterModule.forRoot(ROUTES, {
			preloadingStrategy: PrismPreloadingStrategyProvided,
		}),
		SegmentModule.forRoot(),
		ThemeToggleComponent,
		UserInitialsModule,
	],
	exports: [CommonModule],
	providers: [
		ApiService,
		APIServicesModule,
		PermissionsService,
		{ provide: SEGMENT_CONFIG, useFactory: segmentConfigProvided },
		SegmentService,
		WindowService,
		ComponentModalizerService,
		StorageService,
		PusherService,
		LoginService,
		UserTokenStorageService,
		UserService,
		PrismTitleService,
		EMSFieldService,
		AdminModeService,
		LinkDealApiService,
		RefreshService,
		FeatureGateService,
		PromiseQueueService,
		SmartTableHelperService,
		MarkdownService,
		SpinnerService,
		RequestCacheService,
		PrismAnalyticsService,
		PrismStringsService,
		ConnectionService,
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CachingInterceptor,
			multi: true,
		},
		{ provide: ErrorHandler, useClass: PrismErrorHandler },
		ApiConfig,
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	public constructor() {
		WINDOW_WITH_PRISM_VERSIONS.APP_VERSION = `${VERSION.version}`;
		WINDOW_WITH_PRISM_VERSIONS.PACKAGE_VERSION = `v${packageVersionJson.version}`;
	}
}
