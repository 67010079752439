import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrismGraphqlService } from '@prism-frontend/services/api/graphql/prism-graphql.service';
import { instantiateApiResponse } from '@prism-frontend/services/legacy/api.service';
import {
	TalentSuggestionResponse,
	TalentSuggestionsQuery,
	UpdateDealOverrideArguments,
	UpdateDealOverrideMutation,
	UpdateDealOverrideResponse,
} from '@prism-frontend/typedefs/graphql/TalentData';
import { Talent } from '@prism-frontend/typedefs/talent';
import { TalentData } from '@prism-frontend/typedefs/talentData';
import { InstantiatedAPIResponseAndValidationResults } from '@prism-frontend/utils/decorators/validate-children';

@Injectable()
export class TalentGraphqlService {
	public constructor(private matSnackbar: MatSnackBar, private prismGraphqlService: PrismGraphqlService) {}

	public talentSuggestionsIdentifierKey: keyof Talent = 'name';
	public fetchTalentSuggestions(): Promise<Talent[]> {
		return this.prismGraphqlService
			.query<TalentSuggestionResponse>({
				query: TalentSuggestionsQuery,
			})
			.then(async (response: TalentSuggestionResponse): Promise<Talent[]> => {
				const results: InstantiatedAPIResponseAndValidationResults<Talent[]> = await instantiateApiResponse(
					Talent,
					response.talents
				);

				return results.data;
			});
	}

	public setArtistEarningsOverride(talentId: number, artistEarningsOverride: number | null): Promise<TalentData> {
		return this.prismGraphqlService
			.mutate<UpdateDealOverrideArguments, UpdateDealOverrideResponse>({
				mutation: UpdateDealOverrideMutation,
				variables: {
					id: talentId,
					artist_earnings_override: artistEarningsOverride,
				},
			})
			.then(async (response: UpdateDealOverrideResponse): Promise<TalentData> => {
				const results: InstantiatedAPIResponseAndValidationResults<TalentData> = await instantiateApiResponse(
					TalentData,
					response.updateDeal
				);
				this.matSnackbar.open('Talent override saved!', '', {
					duration: 3000,
				});
				return results.data;
			});
	}
}
