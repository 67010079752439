import { plainToClass } from 'class-transformer';
import { IsNumber, IsString } from 'class-validator';

class TicketCommissionBackend {
	@IsNumber()
	public id: number;

	@IsNumber()
	public event_id: number;

	@IsString()
	public category: string;

	@IsNumber()
	public gross_revenue: number = 0;

	@IsNumber()
	public commission_percentage: number = 0;
}

export class TicketCommission extends TicketCommissionBackend {
	public constructor(ticketCommission?: Partial<TicketCommission>) {
		super();
		return plainToClass(TicketCommission, ticketCommission);
	}

	public calculateCommission(netGrossRevenue: number, calculateTicketCommissionByNetGross: boolean): number {
		const revenue: number = calculateTicketCommissionByNetGross ? netGrossRevenue : this.gross_revenue;
		return (revenue * this.commission_percentage) / 100;
	}

	public calculateTax(withoutTaxMultiplier: number): number {
		return this.gross_revenue * (1 - withoutTaxMultiplier);
	}

	public calculateNetGrossRevenue(withoutTaxMultiplier: number): number {
		return this.gross_revenue - this.calculateTax(withoutTaxMultiplier);
	}

	public calculateNagbor(withoutTaxMultiplier: number, calculateTicketCommissionByNetGross: boolean): number {
		const netGrossRevenue: number = this.calculateNetGrossRevenue(withoutTaxMultiplier);
		return netGrossRevenue - this.calculateCommission(netGrossRevenue, calculateTicketCommissionByNetGross);
	}
}

export function createUniqueTicketCommission(
	ticketCommissions: TicketCommission[],
	eventId?: number
): TicketCommission {
	const baseName: string = '*New Ticket Commission*';
	const namesSet: Set<string> = new Set(
		ticketCommissions.map((tc: TicketCommission): string => {
			return tc.category.trim().toLowerCase();
		})
	);

	let counter: number = 1;
	let categoryName: string = baseName;

	while (namesSet.has(categoryName.trim().toLowerCase())) {
		categoryName = `${baseName} (${counter})`;
		counter++;
	}

	return new TicketCommission({
		category: categoryName,
		event_id: eventId,
	});
}
