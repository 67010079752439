import { EventStatus } from '@prism-frontend/typedefs/enums/event-status';
import { ArtistIdFilterable, TalentDataForHold } from '@prism-frontend/typedefs/hold-for-calendar';
import { Permission } from '@prism-frontend/typedefs/permission';
import { Venue } from '@prism-frontend/typedefs/venue';
import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { Transform, Type, plainToClass } from 'class-transformer';
import { IsArray, IsBoolean, IsEnum, IsHexColor, IsInt, IsOptional, IsString, Matches } from 'class-validator';
import _ from 'lodash';

const dateTimePattern: RegExp = /\d{4}-\d{2}-\d{2}( \d{2}:\d{2} (AM|PM))?/;
const datePattern: RegExp = /\d{4}-\d{2}-\d{2}/;

/**
 * 2 subtypes:
 * - Confirmed date
 * - Meta date (announce / on sale / pre sale)
 */
export class ConfirmedOrMetadate implements ArtistIdFilterable {
	public constructor(confirmOrMetadate: Partial<ConfirmedOrMetadate>) {
		return plainToClass(ConfirmedOrMetadate, confirmOrMetadate);
	}

	// PrismDate id
	@IsInt() public id: number;

	@IsInt() public event_id: number;

	@IsOptional()
	@IsInt()
	public tour_id: number | null = null;

	@IsInt() public stage_id: number;

	@IsInt() public venue_id: number;

	@IsHexColor() public color: string;

	@IsEnum(EventStatus) public confirmed: EventStatus = EventStatus.Confirmed;

	@IsOptional()
	@IsString()
	public meta_date?: 'announce' | 'on sale' | 'pre sale';

	@IsString() public title: string;

	// Formats: 2019-06-03 / 2019-06-03 10:10 AM
	@IsOptional()
	@IsString()
	@Matches(dateTimePattern)
	public start_timestring?: string;

	@IsOptional()
	@IsString()
	@Matches(datePattern)
	public start: string;

	@IsOptional()
	@IsString()
	@Matches(datePattern)
	public start_time: string | null = null;

	@IsOptional()
	@IsString()
	@Matches(datePattern)
	public end_time: string | null = null;

	@IsBoolean()
	@Transform(castToBoolean())
	public all_day: boolean = false;

	public permissions?: Permission[];

	// Stage is active
	@Type((): typeof Boolean => {
		return Boolean;
	})
	@IsBoolean()
	public active_stage: boolean;

	@Type((): typeof Venue => {
		return Venue;
	})
	public venue: Venue;

	@IsString()
	public stage: string;

	// This denotes whether this event is a 'placeholder', event name is not visible,
	// but it will show whether there is a confirmed event or a hold and what level
	@Type((): typeof Boolean => {
		return Boolean;
	})
	@IsBoolean()
	public isPlaceholder?: boolean = false;

	// User has 'manage-holds' permission and is allowed to drag a hold in the calendar
	@Type((): typeof Boolean => {
		return Boolean;
	})
	@IsBoolean()
	public startEditable: boolean = false;

	@IsBoolean() public is_shared: boolean = false;
	@IsBoolean() public is_promoter_shared_event: boolean = false;
	@IsBoolean() public is_venue_shared_event: boolean = false;

	@IsArray()
	public talent_data: TalentDataForHold[] = [];

	public get isConfirmedDate(): boolean {
		return !_.has(this, 'meta_date');
	}

	public get isMetaDate(): boolean {
		return _.has(this, 'meta_date');
	}

	// "end": "2019-05-01",

	/**
	 * returns true if a hold has a talent deal matching the artist ID, false otherwise
	 */
	public hasArtistId(artistId: number): boolean {
		return (
			this.talent_data.filter((talent: TalentDataForHold): boolean => {
				return talent.talent_agent_id === artistId;
			}).length > 0
		);
	}
}
