import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { plainToClass, Transform, Type } from 'class-transformer';
import { IsArray, IsBoolean, IsNumber, IsString, ValidateNested } from 'class-validator';

export class Talent {
	public constructor(event?: Partial<Talent>) {
		return plainToClass(Talent, event);
	}

	@IsNumber() public id: number = null;

	@IsBoolean()
	@Transform(castToBoolean())
	public support: boolean = false;

	@IsString() public name: string = '';

	@Type((): typeof TalentImage => {
		return TalentImage;
	})
	@ValidateNested()
	public images: TalentImage[] = [];

	@IsString() public spotify_id: string = '';

	@IsArray()
	@ValidateNested()
	public genre_strings: string[] = [];
}

export class TalentImage {
	@IsString() public url: string;

	@IsNumber() public width: number;

	@IsNumber() public height: number;
}
