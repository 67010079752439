export type CostGroupCategoryType = 'general' | 'marketing' | 'production' | 'talent';

export enum CostGroupCategory {
	General = 'general',
	Marketing = 'marketing',
	Production = 'production',
	Talent = 'talent',
	All = 'all',
}

const costGroupCaregoryMap: Record<CostGroupCategory, boolean> = {
	general: true,
	marketing: true,
	production: true,
	talent: true,
	all: true,
};

export const allCostGroupCategories: string[] = Object.keys(costGroupCaregoryMap);
