import { DocumentNode, gql } from '@apollo/client/core';
import { CoreEventTalentFragment } from '@prism-frontend/typedefs/graphql/TalentData';
import { TalentData } from '@prism-frontend/typedefs/talentData';

export const UpdateDealMutation: DocumentNode = gql`
	mutation UpdateDeal(
		$id: Int!
		$deal_status: String
		$deal_tracker_notes: String
		$copro_overridden_artist_payout: Float
		$copro_artist_payout_hidden: Boolean
		$contract_due_at: String
		$guarantee: Float
	) {
		updateDeal(
			id: $id
			deal_status: $deal_status
			deal_tracker_notes: $deal_tracker_notes
			copro_overridden_artist_payout: $copro_overridden_artist_payout
			copro_artist_payout_hidden: $copro_artist_payout_hidden
			contract_due_at: $contract_due_at
			guarantee: $guarantee
		) {
			...CoreEventTalent
		}
	}
	${CoreEventTalentFragment}
`;

export type UpdateDealMutationArguments = Pick<TalentData, 'id'> &
	Partial<
		Pick<
			TalentData,
			| 'deal_status'
			| 'deal_tracker_notes'
			| 'copro_overridden_artist_payout'
			| 'copro_artist_payout_hidden'
			| 'contract_due_at'
			| 'guarantee'
		>
	>;

export interface UpdateDealMutationResponse {
	updateDeal: TalentData;
}
