import { Routes } from '@angular/router';
import {
	ARTIST_ROSTER_URL_PART,
	CONTACT_BOOK_BASE_PATH,
} from '@prism-frontend/pages/contact-book-page/contact-book-paths';
import { LoginPageComponent } from '@prism-frontend/pages/login-page/login-page.component';
import { VENUES_BOOK_URL_PART } from '@prism-frontend/pages/venues-book-page/venues-book-paths';
import { ORGANIZATION_TYPES } from '@prism-frontend/services/utils/organization-type-gate.service';
import { CanActivateInsightsGuard } from '@prism-frontend/utils/guards/can-activate-insights.guard';
import { canActivateFeatureGate } from '@prism-frontend/utils/guards/feature-gate.guard';
import { canActivateLinkDeal } from '@prism-frontend/utils/guards/link-deal.guard';
import { canActivateLoggedIn } from '@prism-frontend/utils/guards/logged-in.guard';
import { canActivateLoggedOut } from '@prism-frontend/utils/guards/logged-out.guard';
import { canActivateOrgFeatureGate } from '@prism-frontend/utils/guards/org-feature-gate.guard';
import { canActivateOrganizationTypeGate } from '@prism-frontend/utils/guards/organization-type-gate.guard';
import { canActivatePermission } from '@prism-frontend/utils/guards/permission.guard';
import { canActivatePrismAdmin } from '@prism-frontend/utils/guards/prism-admin-guard';

export const ROUTES: Routes = [
	/**
	 * System & Misc Routes
	 */
	{
		path: '',
		// eslint-disable-next-line
		loadComponent: () => {
			return import('@prism-frontend/pages/event-list-page/event-list-page.component').then(
				// eslint-disable-next-line
				(m) => m.EventListPageComponent
			);
		},
		canActivate: [canActivateLoggedIn],
		data: { pageTitle: 'Event List' },
	},
	{
		path: 'home',
		redirectTo: '',
	},
	{
		path: 'login',
		component: LoginPageComponent,
		canActivate: [canActivateLoggedOut],
		data: { pageTitle: 'Login' },
	},
	{
		path: 'link-deal/:dealHash',
		// eslint-disable-next-line
		loadComponent: () => {
			return import('@prism-frontend/pages/link-deal-page/link-deal-page.component').then(
				// eslint-disable-next-line
				(m) => m.LinkDealPageComponent
			);
		},
		canActivate: [canActivateLoggedIn, canActivateLinkDeal],
		data: { pageTitle: 'Link a Prism deal' },
	},

	/**
	 * Event Components
	 */
	{
		path: 'event',
		// eslint-disable-next-line
		loadChildren: () => {
			return import('@prism-frontend/pages/event-page/event-page.module').then(
				// eslint-disable-next-line
				(m) => m.EventPageModule
			);
		},
		canActivate: [canActivateLoggedIn],
		data: { pageTitle: '{{eventName}}', preload: true },
	},

	/**
	 * Tour routes
	 */
	{
		path: 'tours',
		// eslint-disable-next-line
		loadChildren: () => {
			return import('@prism-frontend/pages/tours-page/tours-page.module').then(
				// eslint-disable-next-line
				(m) => m.ToursPageModule
			);
		},
		canActivate: [canActivateLoggedIn, canActivateOrgFeatureGate, canActivatePermission],
		data: {
			pageTitle: 'Tours',
			OrgFeatureGateGuard: {
				feature: 'TOURING',
			},
			permissions: ['view-tour-index'],
		},
	},
	{
		path: 'tour',
		// eslint-disable-next-line
		loadChildren: () => {
			return import('@prism-frontend/pages/tours-page/tours-page.module').then(
				// eslint-disable-next-line
				(m) => m.ToursPageModule
			);
		},
		canActivate: [canActivateLoggedIn, canActivateOrgFeatureGate],
		data: {
			pageTitle: ['Tour', '{{tourName}}'],
			OrgFeatureGateGuard: { feature: 'TOURING' },
		},
	},
	/**
	 * Event Components
	 */

	/*
	{ path: 'event/:id/:page',  component: EventDashboardComponent, canActivate: [LoggedInGuard] },
	*/

	{
		path: 'manage-holds/:event_id',
		redirectTo: 'calendar/event/:event_id',
	},
	{
		path: 'select-dates/new',
		redirectTo: 'calendar/new',
	},
	{
		path: 'select-dates/:event_id',
		redirectTo: 'calendar/event/:event_id',
	},
	{
		path: 'select-dates',
		redirectTo: 'calendar',
	},
	{
		path: 'calendar',
		// eslint-disable-next-line
		loadChildren: () => {
			return import('@prism-frontend/pages/calendar-page/calendar-page.module').then(
				// eslint-disable-next-line
				(m) => m.CalendarPageModule
			);
		},
		canActivate: [canActivateLoggedIn],
		data: { pageTitle: 'Calendar', preload: true },
	},
	{
		path: 'tasks',
		// eslint-disable-next-line
		loadComponent: () => {
			return import('@prism-frontend/pages/tasks-page/tasks-page.component').then(
				// eslint-disable-next-line
				(m) => m.TasksPageComponent
			);
		},
		canActivate: [canActivateLoggedIn],
		data: { pageTitle: 'Tasks' },
	},
	{
		path: CONTACT_BOOK_BASE_PATH,
		// eslint-disable-next-line
		loadChildren: () => {
			return import('@prism-frontend/pages/contact-book-page/contact-book-page.module').then(
				// eslint-disable-next-line
				(m) => m.ContactBookPageModule
			);
		},
		canActivate: [canActivateLoggedIn, canActivatePermission],
		data: { pageTitle: 'Contacts', permissions: ['view-contactbook', 'edit-contactbook'] },
	},
	{
		path: VENUES_BOOK_URL_PART,
		// eslint-disable-next-line
		loadChildren: () => {
			return import('@prism-frontend/pages/venues-book-page/venues-book-page.module').then(
				// eslint-disable-next-line
				(m) => m.VenuesBookPageModule
			);
		},
		canActivate: [canActivateLoggedIn, canActivateOrganizationTypeGate],
		data: {
			pageTitle: 'Venues & Stages',
			OrganizationTypeGateGuard: { type: ORGANIZATION_TYPES.IS_AGENT },
		},
	},
	{
		path: ARTIST_ROSTER_URL_PART,
		// eslint-disable-next-line
		loadChildren: () => {
			return import('@prism-frontend/pages/artist-roster-page/artist-roster-page.module').then(
				// eslint-disable-next-line
				(m) => m.ArtistRosterPageModule
			);
		},
		canActivate: [canActivateLoggedIn, canActivatePermission],
		data: { pageTitle: 'Artist Roster', permissions: ['view-roster', 'edit-roster'] },
	},

	/**
	 * Permission Routes (Lazy-loaded) (TeamCollaboratorsModule)
	 */
	{
		path: 'permissions',
		// eslint-disable-next-line
		loadChildren: () => {
			return import('@prism-frontend/pages/permissions-page/permissions-page.module').then(
				// eslint-disable-next-line
				(m) => m.PermissionsPageModule
			);
		},
		canActivate: [canActivateLoggedIn],
		data: { pageTitle: 'Permissions' },
	},

	/**
	 * Settings Routes (Lazy-loaded)
	 */
	{
		path: 'settings',
		// eslint-disable-next-line
		loadChildren: () => {
			return import('@prism-frontend/pages/settings-page/settings-page.module').then(
				// eslint-disable-next-line
				(m) => m.SettingsPageModule
			);
		},
		canActivate: [canActivateLoggedIn],
		data: { pageTitle: 'Settings' },
	},

	/**
	 * Admin Portal Routes (Lazy-loaded)
	 */
	{
		path: 'portal',
		// eslint-disable-next-line
		loadChildren: () => {
			return import('@prism-frontend/pages/admin-page/admin-page.module').then(
				// eslint-disable-next-line
				(m) => m.AdminModule
			);
		},
		canActivate: [canActivateLoggedIn],
		data: { pageTitle: 'Portal' },
	},

	/**
	 * Reports Routes (Lazy-loaded)
	 */
	{
		path: 'reports',
		canActivate: [canActivateLoggedIn, canActivatePermission],
		// eslint-disable-next-line
		loadChildren: () => {
			return import('@prism-frontend/pages/reports-page/reports-root.module').then(
				// eslint-disable-next-line
				(m) => m.ReportsRootModule
			);
		},
		data: { pageTitle: 'Reports', permissions: ['view-reporting'] },
	},

	/**
	 * Insights (Lazy-loaded)
	 */
	{
		path: 'insights',
		canActivate: [CanActivateInsightsGuard],
		// eslint-disable-next-line
		loadChildren: () => {
			return import('@prism-frontend/pages/insights-page/insights-page.routing.module').then(
				// eslint-disable-next-line
				(m) => m.InsightsPageRoutingModule
			);
		},
		data: {
			pageTitle: 'Prism Insights',
		},
	},

	{
		path: 'styleguide',
		// eslint-disable-next-line
		loadChildren: () => {
			return import('@prism-frontend/pages/style-guide-page/style-guide-page.module').then(
				// eslint-disable-next-line
				(m) => m.StyleGuidePageModule
			);
		},
		canActivate: [canActivateLoggedIn, canActivatePrismAdmin, canActivateFeatureGate],
		data: {
			FeatureGateGuard: { feature: 'STYLE_GUIDE' },
			pageTitle: 'Style Guide',
		},
	},
	{
		path: 'testing',
		// eslint-disable-next-line
		loadChildren: () => {
			return import('@prism-frontend/pages/testing-page/testing-page.module').then(
				// eslint-disable-next-line
				(m) => m.TestingPageModule
			);
		},
		canActivate: [canActivateLoggedIn, canActivatePrismAdmin],
		data: { pageTitle: 'EMS Testing' },
	},
	{ path: '**', redirectTo: '' },
];
