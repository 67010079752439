import { ContextInterpolatorFn } from '@prism-frontend/entities/email-templates/data-interpolation/ContextInterpolatorFn';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PropertyInterpolatorDictionary = Record<string, any>;

export const SIMPLE_PROPERTY_INTERPOLATOR: ContextInterpolatorFn<PropertyInterpolatorDictionary> = (
	identifier: string,
	context: PropertyInterpolatorDictionary
): string => {
	return context[identifier];
};
