import { NgModule, Provider } from '@angular/core';
import { ArtistsGraphqlService } from '@prism-frontend/entities/artists/services/artist-graphql.service';
import { CustomFieldsGraphqlService } from '@prism-frontend/entities/custom-fields/services/custom-fields-graphql.service';
import { AgentGraphqlService } from '@prism-frontend/services/api/graphql/agent-graphql.service';
import { AvailsGraphqlService } from '@prism-frontend/services/api/graphql/avails-graphql.service';
import { ContactGraphqlService } from '@prism-frontend/services/api/graphql/contact-graphql.service';
import { ContactRoleGraphqlService } from '@prism-frontend/services/api/graphql/contact-role-graphql.service';
import { DealTrackerGraphqlService } from '@prism-frontend/services/api/graphql/deal-tracker-graphql.service';
import { EventContactGraphqlService } from '@prism-frontend/services/api/graphql/event-contact-graphql.service';
import { EventGraphqlService } from '@prism-frontend/services/api/graphql/event-graphql.service';
import { InvoiceGraphqlService } from '@prism-frontend/services/api/graphql/invoice-graphql.service';
import { PartnerDealAdjustmentGraphqlService } from '@prism-frontend/services/api/graphql/partner-deal-adjustments.service';
import { PrismGraphqlService } from '@prism-frontend/services/api/graphql/prism-graphql.service';
import { SettleUpGrapqhlService } from '@prism-frontend/services/api/graphql/settle-up-graphql.service';
import { TalentGraphqlService } from '@prism-frontend/services/api/graphql/talent-graphql.services';
import { TicketCommissionGraphqlService } from '@prism-frontend/services/api/graphql/ticket-commission-graphql.service';
import { VenueGraphqlService } from '@prism-frontend/services/api/graphql/venue-graphql.service';

// this array is used in both the `imports` and `exports` for this module
const ALL_PRISM_GRAPHQL_SERVICES: Provider[] = [
	AgentGraphqlService,
	ArtistsGraphqlService,
	AvailsGraphqlService,
	ContactGraphqlService,
	CustomFieldsGraphqlService,
	DealTrackerGraphqlService,
	EventContactGraphqlService,
	EventGraphqlService,
	InvoiceGraphqlService,
	ContactRoleGraphqlService,
	PartnerDealAdjustmentGraphqlService,
	PrismGraphqlService,
	SettleUpGrapqhlService,
	TalentGraphqlService,
	VenueGraphqlService,
	TicketCommissionGraphqlService
];

@NgModule({
	providers: [...ALL_PRISM_GRAPHQL_SERVICES],
})
export class PrismGraphqlModule {}
