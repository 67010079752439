import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarErrorComponent } from '@prism-frontend/components/snackbar-error/snackbar-error.component';
import { AdminModeService } from '@prism-frontend/services/ui/admin-mode.service';
import { EMSFieldHelperService } from '@prism-frontend/services/ui/ems-field-helper.service';
import { PromiseQueueService } from '@prism-frontend/services/utils/promise-queue.service';
import { emsValue } from '@prism-frontend/typedefs/ems/ems-static-helpers/emsValue';
import { EMS } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { EMSFieldDefs, EMSFieldMeta } from '@prism-frontend/typedefs/ems/EMSFieldMeta';
import { EMSFieldsMetaPersistence, PersistFieldEditMeta } from '@prism-frontend/typedefs/ems/EMSFieldMetaPersistence';
import { PrismEvent } from '@prism-frontend/typedefs/event';
import { Debug, getDebug } from '@prism-frontend/utils/static/getDebug';
import { BehaviorSubject, Subject } from 'rxjs';

const debug: Debug = getDebug('ems-field-service');

export interface EMSFieldEditMeta {
	emsFieldPath: keyof EMSFieldDefs | string;
	previousValue: unknown;
}
export interface EMSFieldEditEventData extends EMSFieldEditMeta {
	newValue: unknown;
	updatedEntity: unknown;
	updatedEvent: PrismEvent;
}

@Injectable({
	providedIn: 'root',
})
export class EMSFieldService {
	public displayFieldNames$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public emsFieldEdited$: Subject<EMSFieldEditEventData> = new Subject<EMSFieldEditEventData>();
	public emsFieldEditError$: Subject<EMSFieldEditMeta> = new Subject<EMSFieldEditMeta>();

	public constructor(
		private adminModeService: AdminModeService,
		private emsFieldHelperService: EMSFieldHelperService,
		private promiseQueueService: PromiseQueueService<unknown>,
		private snackBarService: MatSnackBar
	) {
		try {
			const value: boolean = JSON.parse(localStorage.getItem('displayEMSFieldNames'));
			if (value) {
				this.displayFieldNames$.next(true);
			}
		} catch (e) {
			// do nothing
		}
		this.displayFieldNames$.subscribe((newVal: boolean): void => {
			localStorage.setItem('displayEMSFieldNames', JSON.stringify(newVal));
		});
		this.adminModeService.adminMode$.subscribe((newVal: boolean): void => {
			if (!newVal) {
				this.displayFieldNames$.next(false);
			}
		});
	}

	/**
	 * updates an ems field if there is a method for persisting its data
	 *
	 * @param fieldName the ems field name that is been edited
	 * @param value the new value for the ems field
	 * @param event the current event
	 * @param tableContext the table where the ems field is been edited from
	 */
	public updateEMSField(
		emsFieldPath: keyof EMSFieldDefs | string,
		emsFieldMeta: EMSFieldMeta,
		newValue: unknown,
		ems: EMS,
		event: PrismEvent,
		debounceTime: number,
		callAPIFromEMSFieldComponent: boolean = true
	): boolean {
		// get the edit callback defined for the ems field Meta we intend to update
		const persistMeta: PersistFieldEditMeta = EMSFieldsMetaPersistence[emsFieldMeta.emsMetadataId];
		// check that emsField meta has a way to persist the field edit
		if (!persistMeta || !callAPIFromEMSFieldComponent) {
			debug(`Persisting ems field ${emsFieldPath} is not supported. Can't edit this field`);
			return false;
		}
		// split the ems path between the entity to update and the inner field to update
		// e.g. 'fixedCosts.2.costs.0.budget' => 'fixedCosts.2.costs.0' and 'budget'
		const entityPath: keyof EMSFieldDefs = emsFieldPath.slice(
			0,
			emsFieldPath.lastIndexOf('.')
		) as keyof EMSFieldDefs;
		// get the property to update from the ems field path
		const entityProperty: string = emsFieldPath.slice(emsFieldPath.lastIndexOf('.') + 1) as keyof EMSFieldDefs;
		// get the entity to be updated from the entityPath with all its data
		const emsEntityToUpdate: unknown = emsValue(ems, entityPath);
		const previousValue: unknown = emsEntityToUpdate[entityProperty];
		// now invoke the persistFieldEdit method to update the ems field with its corresponding service
		this.promiseQueueService.push(
			`editing-ems-field-${event.id}-${emsFieldPath}`,
			'ems-field',
			async (): Promise<void> => {
				try {
					const updatedEvent: PrismEvent = await persistMeta.persistFieldEdit(
						this.emsFieldHelperService,
						emsEntityToUpdate,
						entityProperty,
						newValue,
						event
					);
					// notify the edit event, both tour page and event service will be listening to this
					// to update the current loaded TMS or EMS respectively, which will make sure
					// the UI is updated with all the new values
					this.emsFieldEdited$.next({
						emsFieldPath,
						previousValue,
						newValue,
						updatedEntity: emsEntityToUpdate,
						updatedEvent,
					});
				} catch (error) {
					debug(`Error persisting ems field ${emsFieldPath}. ${error}`);
					this.snackBarService.openFromComponent(SnackbarErrorComponent, {
						panelClass: ['snack-bar-error'],
						duration: 5000,
						data: {
							message: error,
						},
					});
					this.emsFieldEditError$.next({
						emsFieldPath,
						previousValue,
					});
				}
			},
			debounceTime
		);
		return true;
	}
}
