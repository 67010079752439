import { Injectable } from '@angular/core';
import { WithAutoUnsubscribeService } from '@prism-frontend/utils/static/auto-unsubscribe';
import * as bodyScrollLock from 'body-scroll-lock';
import * as _ from 'lodash';
import { BehaviorSubject, fromEvent, interval, Observable } from 'rxjs';
import { debounce, debounceTime, distinctUntilChanged, map, pluck } from 'rxjs/operators';

export const WINDOW_EVENT_CREATE_NEW_EVENT: string = 'create-new-event';
const LOCK_SCROLL_BODY_CLASS: string = 'lock-scroll';
const eventFiltersMobileBreakpoint: number = 1110;

/**
 * below are the breakpoints we use in sass (see src/scss/redesign/vars.scss), redefined
 * here for ease of use in typescript
 *
 * the two commented out are unused, but here for use when needed
 */
// const breakpointXs: number = 0;
const breakpointSm: number = 576;
const breakpointMd: number = 768;
const breakpointLg: number = 992;
const breakpointXl: number = 1200;

interface WindowSize {
	width: number;
	isMobile: boolean;
	height: number;
}

@Injectable({
	providedIn: 'root',
})
export class WindowService extends WithAutoUnsubscribeService {
	public isMobile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(WindowService.isMobile());
	public isTouchDevice: boolean;
	public width$: Observable<number>;
	public resize$: Observable<WindowSize>;
	public showMobileContent$: Observable<boolean>;
	public onScroll$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public constructor() {
		super();
		const windowSize$: BehaviorSubject<WindowSize> = new BehaviorSubject(WindowService.getWindowSize());

		this.isTouchDevice = WindowService.isTouchDevice();

		this.width$ = (windowSize$.pipe(pluck('width')) as Observable<number>)
			.pipe(
				debounce((): Observable<number> => {
					return interval(150);
				})
			)
			.pipe(distinctUntilChanged());

		this.addSubscription(
			windowSize$
				.pipe(pluck('isMobile'))
				.pipe(distinctUntilChanged())
				.subscribe((isMobile: boolean): void => {
					return this.isMobile$.next(isMobile);
				})
		);

		this.resize$ = windowSize$.asObservable().pipe(debounceTime(150));

		this.showMobileContent$ = this.width$.pipe(
			map((width: number): boolean => {
				return width < 1000;
			})
		);

		fromEvent(window, 'resize').pipe(map(WindowService.getWindowSize)).subscribe(windowSize$);

		if (window) {
			window.addEventListener('scroll', (): void => {
				this.onScroll$.next(true);
			});
		}
	}

	public get innerHeight(): number {
		return window.innerHeight;
	}

	public get innerWidth(): number {
		return window.innerWidth;
	}

	/**
	 * this will return true when the screen is smaller than 768
	 *
	 * @param element (optional) an optional object with innerWidth; defaults to window
	 * @returns true if the screen is smaller than $breakpoint-md
	 */
	public static isMobile(element: Pick<Window, 'innerWidth'> = window): boolean {
		return element.innerWidth < breakpointMd;
	}

	/**
	 * this will return true when the screen is smaller than 576px
	 *
	 * @param element (optional) an optional object with innerWidth; defaults to window
	 * @returns true if the screen is smaller than $breakpoint-sm
	 */
	public static isTinyScreen(element: Pick<Window, 'innerWidth'> = window): boolean {
		return element.innerWidth < breakpointSm;
	}

	/**
	 * this will return true when the screen is larger than 992
	 *
	 * @param element (optional) an optional object with innerWidth; defaults to window
	 * @returns true if the screen is greater than to $breakpointlg
	 */
	public static isLargeScreen(element: Pick<Window, 'innerWidth'> = window): boolean {
		return element.innerWidth > breakpointLg;
	}

	/**
	 * this will return true when the screen is larger than 1200
	 *
	 * @param element (optional) an optional object with innerWidth; defaults to window
	 * @returns true if the screen is greater than to $breakpointlg
	 */
	public static isExtraLargeScreen(element: Pick<Window, 'innerWidth'> = window): boolean {
		return element.innerWidth > breakpointXl;
	}

	/**
	 * this will return true when the screen is smaller than 768
	 *
	 * @param element (optional) an optional object with innerWidth; defaults to window
	 * @returns true if the screen is less than or equal to $breakpointXl
	 */
	public static isIpadPortraitOrSmaller(element: Pick<Window, 'innerWidth'> = window): boolean {
		return element.innerWidth <= breakpointMd;
	}

	/**
	 * this will return true when the screen is smaller than 1100
	 *
	 * @param element (optional) an optional object with innerWidth; defaults to window
	 * @returns true if the screen is less than or equal to 1100
	 */
	public static isEventFiltersMobileBreakpointActive(element: Pick<Window, 'innerWidth'> = window): boolean {
		return element.innerWidth <= eventFiltersMobileBreakpoint;
	}

	private static getWindowSize(): WindowSize {
		return {
			isMobile: WindowService.isMobile(),
			width: window.innerWidth,
			height: window.innerHeight,
		};
	}

	// this really just duplicates this small modernizr case (as of v.3.9.1), minus some
	// window-related checks that caused typescript to fail and seem to be deprecated DOM features:
	//
	// https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js
	public static isTouchDevice(): boolean {
		return !!('ontouchstart' in window);
	}

	// TODO PRSM-4099 remove these legacy methods
	public lockScrollLegacy(): void {
		document.body.classList.add(LOCK_SCROLL_BODY_CLASS);
	}

	// TODO PRSM-4099 remove these legacy methods
	public unlockScrollLegacy(): void {
		document.body.classList.remove(LOCK_SCROLL_BODY_CLASS);
	}

	public lockScrollToElement($onElement: JQuery, allowScrollClasses: string[] = []): void {
		if ($onElement.length === 0) {
			throw new Error(`an empty collection was passed`);
		}
		const elt: HTMLElement = $onElement.get(0);
		bodyScrollLock.disableBodyScroll(elt, {
			// Adapded from module documentation
			// https://www.npmjs.com/package/body-scroll-lock#allowtouchmove
			allowTouchMove: (el: HTMLElement): boolean => {
				const allowScrollClassList: string[] = [
					'ng-dropdown-panel',
					'cdk-overlay-container',
					...allowScrollClasses,
				];
				while (el && el !== document.body) {
					const doesNodeAllowScroll: boolean = _.reduce(
						el.className.split(' '),
						(memo: boolean, thisClassName: string): boolean => {
							return memo || _.indexOf(allowScrollClassList, thisClassName) !== -1;
						},
						false
					);
					if (doesNodeAllowScroll) {
						return true;
					}
					el = el.parentNode as HTMLElement;
				}
				return false;
			},
		});
	}

	public clearAllScrollLocks(): void {
		bodyScrollLock.clearAllBodyScrollLocks();
		this.unlockScrollLegacy();
	}
}
