import { ContextInterpolatorFn } from '@prism-frontend/entities/email-templates/data-interpolation/ContextInterpolatorFn';
import { interpolateFunctionalChipDataIntoStringTimer } from '@prism-frontend/entities/email-templates/data-interpolation/data-interpolation-service-timers';
import { EnabledChipConfiguration } from '@prism-frontend/entities/email-templates/data-interpolation/EnabledChipConfiguration';
import { getDataChipFieldMatches } from '@prism-frontend/entities/email-templates/data-interpolation/getDataChipFieldMatches';
import { MatchedProp } from '@prism-frontend/entities/email-templates/data-interpolation/MatchedProp';
import {
	FunctionalChip,
	FunctionalChipFunctionResult,
	FunctionalChipOrgData,
} from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/FunctionalChip';
import { FunctionalChipAdditionalData } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/FunctionalChipAdditionalData';
import { isTemplateContextWithFunctionalChipContext } from '@prism-frontend/pages/testing-page/components/field-sandbox/isTemplateContextWithFunctionalChipContext';
import { WithFunctionalChipsContext } from '@prism-frontend/pages/testing-page/components/field-sandbox/WithFunctionalChipsContext';
import { Debug, getDebug } from '@prism-frontend/utils/static/getDebug';
import { keyObjectsBy } from '@prism-frontend/utils/static/keyObjectsBy';
import _ from 'lodash';
const debug: Debug = getDebug('data-interpolation');

export function interpolateValuesIntoString<T, U>(
	text: string,
	templateContext: U,
	availableChips: EnabledChipConfiguration,
	functionalChipOrgData: FunctionalChipOrgData,
	interpolators: Record<string, ContextInterpolatorFn<T>>
): string {
	let result: string = interpolateDataChipIntoString(text, templateContext, availableChips, interpolators);
	if (isTemplateContextWithFunctionalChipContext(templateContext)) {
		result = interpolateFunctionalChipDataIntoString(
			result,
			functionalChipOrgData,
			(templateContext as WithFunctionalChipsContext).functionalChipsContext,
			keyObjectsBy(availableChips.functionalChips, 'identifier')
		);
	}
	return result;
}

function interpolateDataChipIntoString<T, U>(
	text: string,
	templateContext: U,
	availableChips: EnabledChipConfiguration,
	interpolators: Record<string, ContextInterpolatorFn<T>>
): string {
	const matches: MatchedProp[] = getDataChipFieldMatches(text, availableChips.dataChips);
	matches.forEach((match: MatchedProp): void => {
		const [contextKey, ...rest]: string[] = match.propIdentifier.split('.');
		if (!interpolators[contextKey]) {
			debug(`No interpolator found for ${contextKey}.`);
			return;
		}
		let value: string = interpolators[contextKey](rest.join('.'), templateContext[contextKey]);
		// Be extra safe to ensure we keep "undefined" and "null" out of the
		// output
		value = _.isNumber(value) ? value : value || '';
		text = text.replace(match.replaceText, String(match.propOption ? value : ''));
	});
	return text;
}

function interpolateFunctionalChipDataIntoString(
	targetText: string,
	functionalChipOrgData: FunctionalChipOrgData,
	data: FunctionalChipAdditionalData,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	functionalChips: Record<string, FunctionalChip<any, any>>
): string {
	interpolateFunctionalChipDataIntoStringTimer.start();
	// $$widget1 findAdditionalRevenueByName({...})$$
	const WIDGET_RE: RegExp = /\$\$widget1 ([^$]+)\$\$/gm;
	const matches: RegExpExecArray[] = [];
	let match: RegExpExecArray = WIDGET_RE.exec(targetText);
	while (match) {
		matches.push(match);
		match = WIDGET_RE.exec(targetText);
	}
	matches.forEach((curMatch: RegExpExecArray): void => {
		// 2 capture groups:
		//   1. the functionName
		//   2. the object passed to the function
		const FN_REGEX: RegExp = /([a-zA-Z0-9]+)\(([^)]+)\)/gm;
		// $$widget1 functionName({...})$$
		const replaceText: string = curMatch[0];
		// functionName({...})
		const functionCall: string = curMatch[1];
		const matched: RegExpExecArray = FN_REGEX.exec(functionCall);
		const functionalChipId: string = matched[1];
		const chip: FunctionalChip<unknown> = functionalChips[functionalChipId];
		const text: string = matched[2];
		let arg: unknown = {};
		try {
			arg = JSON.parse(text);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.warn('An error occured parsing json text for interpolation', text);
			// eslint-disable-next-line no-console
			console.warn(e);
		}
		const functionResult: FunctionalChipFunctionResult = chip.theFunction(arg, functionalChipOrgData, data);
		targetText = targetText.replace(
			replaceText,
			functionResult?.toString() || chip.fallbackValue(arg, functionalChipOrgData, data)
		);
	});
	interpolateFunctionalChipDataIntoStringTimer.stop();
	return targetText.trim();
}
