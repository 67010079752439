/**
 * returns true if the navigator platform is a mac, false otherwise
 * @paramn avigatorOverride is for unit testing only. DO NOT USE THIS PARAMETER
 * 	IN PRACTICE
 * @returns true if navigator platform is a mac, false otherwise
 */
export function isMacintosh(navigatorOverride: never = undefined as never): boolean {
	const navigatorToUse: Navigator = navigatorOverride || window.navigator;
	// https://stackoverflow.com/a/11752084/239242 recommands navigator.platform, but its deprecated
	// so we fall back to navigator.userAgentData.platform, if it exists, as discussed here
	// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform
	// @ts-ignore
	return (navigatorToUse.userAgentData?.platform || navigatorToUse.platform || '').toLowerCase().indexOf('mac') > -1;
}
