import { customFieldValue, emsValue } from '@prism-frontend/typedefs/ems/ems-static-helpers/emsValue';
import { EMSCustomField, EMSRollup } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { EMSFieldDefs } from '@prism-frontend/typedefs/ems/EMSFieldMeta';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';

export function emsValueFromRollup(ems: EMSRollup, costCalc: CostCalc2, emsMetadataId: keyof EMSFieldDefs): unknown {
	return emsValue(ems[costCalc], emsMetadataId);
}

export function customFieldFromRollup(
	customFieldsByKey: Record<string, EMSCustomField>,
	customFieldKey: string
): unknown {
	return customFieldValue(customFieldsByKey, customFieldKey);
}
