import { Injectable } from '@angular/core';
import { ApiService } from '@prism-frontend/services/legacy/api.service';
import { OrderedModel } from '@prism-frontend/typedefs/enums/OrderedModel';
import {
	Orderable,
	OrderableKey,
	OrderChangeItem,
	OrderChangePayload,
	OrderedKeyMap,
} from '@prism-frontend/typedefs/orderable';

@Injectable({ providedIn: 'root' })
export class OrderItemsService {
	public constructor(private apiService: ApiService) {}

	/**
	 * given an list of Orderable items, hit the UPDATE_RECORED_ORDER API endpoint to save the new order
	 *
	 * note that the model in question also has to be added to the b/e `DatabaseController.update_row_order` method
	 * to work
	 *
	 * @param items the newly re-ordered items
	 * @returns a promise that resolves after the API has been hit
	 */
	public saveNewItemOrder(items: Orderable[]): Promise<void> {
		if (!items.length) {
			return;
		}
		const model: OrderedModel = items[0].modelName;
		const key: OrderableKey = OrderedKeyMap[model];
		const payload: OrderChangePayload = {
			// object model
			model,
			// Send new ordering
			items: items.map((item: Orderable): OrderChangeItem => {
				return {
					id: item.id,
					order: item.order,
				};
			}),
			// key info
			key: key,
			keyId: items[0][key],
		};

		return this.apiService.postP(this.apiService.ep.UPDATE_RECORD_ORDER, payload);
	}
}
