import { Injectable } from '@angular/core';
import { PrismGraphqlService } from '@prism-frontend/services/api/graphql/prism-graphql.service';
import { HoldService } from '@prism-frontend/services/api/list-services/hold.service';
import {
	AvailsByStage,
	AvailsQuery,
	AvailsQueryResponse,
	DayAvailability,
} from '@prism-frontend/typedefs/graphql/AvailsQuery';
import { HoldForCalendar } from '@prism-frontend/typedefs/hold-for-calendar';
import { MOMENT_DATE_FORMAT } from '@prism-frontend/utils/static/dateHelper';
import _ from 'lodash';
import moment from 'moment';

@Injectable()
export class AvailsGraphqlService {
	public constructor(private prismGraphql: PrismGraphqlService, private holdService: HoldService) {}

	public async fetchAvails(
		dateGreaterThanOrEqual: moment.Moment,
		dateLessThanOrEqual: moment.Moment,
		venueIds: number[]
	): Promise<AvailsByStage[]> {
		const response: AvailsQueryResponse = await this.prismGraphql.query<AvailsQueryResponse>({
			query: AvailsQuery,
			variables: {
				date_gte: dateGreaterThanOrEqual.format(MOMENT_DATE_FORMAT),
				date_lte: dateLessThanOrEqual.format(MOMENT_DATE_FORMAT),
				venue_ids: venueIds,
			},
			fetchPolicy: 'no-cache',
		});
		return response.avails;
	}

	public async fetchDayAvailabilityByStageIdForEvent(eventId: number): Promise<Record<number, DayAvailability[]>> {
		const holds: HoldForCalendar[] = await this.holdService.loadHoldsForEvent(eventId);

		if (holds.length === 0) {
			return {};
		}

		const sortedHolds: HoldForCalendar[] = _.sortBy(holds, (hold: HoldForCalendar): moment.Moment => {
			return moment(hold.date.date);
		});

		const momentisedStartDate: moment.Moment = moment(_.first(sortedHolds).date.date);
		const momentisedEndDate: moment.Moment = moment(_.last(sortedHolds).date.date);

		const allAvails: AvailsByStage[] = await this.fetchAvails(momentisedStartDate, momentisedEndDate, [
			holds[0].event.venue_id,
		]);
		return _.chain(allAvails)
			.keyBy((availsEntry: AvailsByStage): number => {
				return availsEntry.stage_id;
			})
			.mapValues((avails: AvailsByStage): DayAvailability[] => {
				return avails.avails;
			})
			.value();
	}
}
