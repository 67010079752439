import { getDataChipFieldMatchesTimer } from '@prism-frontend/entities/email-templates/data-interpolation/data-interpolation-service-timers';
import { InterpolatedDataOption } from '@prism-frontend/entities/email-templates/data-interpolation/InterpolatedDataOption';
import { MatchedProp } from '@prism-frontend/entities/email-templates/data-interpolation/MatchedProp';
import { Debug, getDebug } from '@prism-frontend/utils/static/getDebug';
import _ from 'lodash';
const debug: Debug = getDebug('data-interpolation');

export function getDataChipFieldMatches(text: string, availableFields: InterpolatedDataOption[]): MatchedProp[] {
	getDataChipFieldMatchesTimer.start();
	const WIDGET_RE: RegExp = /\$\$widget0 \(~([a-zA-Z0-9.*-]+)~\)\$\$/gm;
	const matches: RegExpExecArray[] = [];
	let match: RegExpExecArray = WIDGET_RE.exec(text);
	while (match) {
		matches.push(match);
		match = WIDGET_RE.exec(text);
	}
	const retVal: MatchedProp[] = matches.map((curMatch: RegExpMatchArray): MatchedProp => {
		const propIdentifier: string = curMatch[1];
		const replaceText: string = curMatch[0];
		const propOption: InterpolatedDataOption = _.find(
			availableFields,
			(option: InterpolatedDataOption): boolean => {
				return option.identifier === propIdentifier;
			}
		);
		if (!propOption) {
			// don't need to throw because the UI component will indicate
			// that the reference is invalid. we will interpolate an empty
			// string below to ensure we don't leak any data
			debug(`${propIdentifier} not an available option`);
		}
		return {
			propIdentifier,
			replaceText,
			propOption,
		};
	});
	getDataChipFieldMatchesTimer.stop();
	return retVal;
}
