import { Injectable } from '@angular/core';
import { PrismGraphqlService } from '@prism-frontend/services/api/graphql/prism-graphql.service';
import { instantiateApiResponse } from '@prism-frontend/services/legacy/api.service';
import { Contact, PrismEventContact } from '@prism-frontend/typedefs/contact';
import {
	AddEventContactMutation,
	CreateEventContactResponse,
	EventContactMutationArgugments,
	RemoveEventContactMutation,
	RemoveEventContactMutationArgugments,
	RemoveEventContactResponse,
	UpdateEventContactMutation,
	UpdateEventContactResponse,
} from '@prism-frontend/typedefs/graphql/EventContacts';
import { InstantiatedAPIResponseAndValidationResults } from '@prism-frontend/utils/decorators/validate-children';

@Injectable({ providedIn: 'root' })
export class EventContactGraphqlService {
	public constructor(private graphqlService: PrismGraphqlService) {}

	public updateEventContact(variables: EventContactMutationArgugments): Promise<PrismEventContact> {
		return this.graphqlService
			.mutate<EventContactMutationArgugments, UpdateEventContactResponse>({
				mutation: UpdateEventContactMutation,
				variables,
			})
			.then(async (response: UpdateEventContactResponse): Promise<PrismEventContact> => {
				const results: InstantiatedAPIResponseAndValidationResults<PrismEventContact> =
					await instantiateApiResponse(PrismEventContact, response.updateContactEventRelationship);

				return results.data;
			});
	}

	public addEventContact(variables: EventContactMutationArgugments): Promise<PrismEventContact> {
		return this.graphqlService
			.mutate<EventContactMutationArgugments, CreateEventContactResponse>({
				mutation: AddEventContactMutation,
				variables,
			})
			.then(async (response: CreateEventContactResponse): Promise<PrismEventContact> => {
				const results: InstantiatedAPIResponseAndValidationResults<PrismEventContact> =
					await instantiateApiResponse(PrismEventContact, response.addContactToEvent);

				return results.data;
			});
	}

	public removeEventContact(variables: RemoveEventContactMutationArgugments): Promise<Contact> {
		return this.graphqlService
			.mutate<RemoveEventContactMutationArgugments, RemoveEventContactResponse>({
				mutation: RemoveEventContactMutation,
				variables,
			})
			.then(async (response: RemoveEventContactResponse): Promise<Contact> => {
				const results: InstantiatedAPIResponseAndValidationResults<Contact> = await instantiateApiResponse(
					Contact,
					response.removeContactFromEvent
				);

				return results.data;
			});
	}
}
