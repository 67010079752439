import { EventEmitter, Injectable } from '@angular/core';
import { PrismGraphqlService } from '@prism-frontend/services/api/graphql/prism-graphql.service';
import { DealStatus } from '@prism-frontend/typedefs/enums/DealStatus';
import {
	UpdateDealMutation,
	UpdateDealMutationArguments,
	UpdateDealMutationResponse,
} from '@prism-frontend/typedefs/graphql/DealTracker';
import { TalentData } from '@prism-frontend/typedefs/talentData';
import { plainToInstance } from 'class-transformer';

@Injectable({ providedIn: 'root' })
export class DealTrackerGraphqlService {
	public constructor(private prismGraphqlService: PrismGraphqlService) {}

	public dealTrackerDealUpdated: EventEmitter<TalentData> = new EventEmitter<TalentData>();

	public updateDealTrackerDeal(variables: UpdateDealMutationArguments): Promise<TalentData> {
		return this.prismGraphqlService
			.mutate<UpdateDealMutationArguments, UpdateDealMutationResponse>({
				mutation: UpdateDealMutation,
				variables,
			})
			.then((response: UpdateDealMutationResponse): TalentData => {
				const newTalent: TalentData = plainToInstance(TalentData, response.updateDeal);
				if (newTalent.deal_status === null) {
					newTalent.deal_status = DealStatus.NULL;
				}
				this.dealTrackerDealUpdated.emit(newTalent);
				return newTalent;
			});
	}
}
