<div class="full-screen-spinner spinner-step spinner-show" *ngIf="!showSpinner.flag">
	<div class="spinner-graphic">
		<div class="loading"></div>
	</div>
</div>

<main
	[ngClass]="[appThemeCSSClass, 'app-container', 'not-blurry', appRouteToCSSClass]"
	[class.blurry]="!showSpinner.flag"
	[class.user-logged-in]="isLoggedIn"
>
	<nav
		id="menu"
		data-testid="js-sidebar-menu"
		[class.show-menu-items]="showMenuItems"
		[class.impersonate]="isLoggedInAsUser"
		[class.production]="isProduction"
		*ngIf="isLoggedIn"
	>
		<button mat-icon-button class="mobile-menu-toggle" (click)="toggleNavMenuItems()" title="Toggle Menu">
			<mat-icon>menu</mat-icon>
		</button>
		<a routerLink="/" (click)="userMenuLogoClick()" aria-label="Home">
			<img
				alt="Prism Logo"
				class="menu-logo"
				src="https://prism-logo-images.s3.us-east-2.amazonaws.com/newlogo.png"
			/>
		</a>
		<div class="menu-overlay" (click)="toggleNavMenuItems()"></div>
		<div class="menu-items-container">
			<ul class="menu-items">
				<ng-container *ngFor="let item of navbarItems">
					<li>
						<a
							*ngIf="item.isVisibleToUser() && (!item.children?.length || item.children?.length === 1)"
							[ngClass]="['menu-item', navbarItemClassName(item)]"
							[class.active]="isLinkActive(item)"
							[title]="item.title"
							[routerLink]="item.link"
							[matTooltip]="item.title"
							[attr.aria-label]="item.title"
							matTooltipPosition="after"
							touchGestures="off"
							(click)="menuItemClick($event, item)"
						>
							<mat-icon [class.material-icons-outlined]="!item.noOutline" aria-hidden="true">{{
								item.matIcon
							}}</mat-icon>
							<span class="menu-item-text">{{ item.title }}</span>
						</a>
						<!-- Render top-level menu button with children nav items -->
						<ng-container *ngIf="item.isVisibleToUser() && item.children?.length > 1">
							<div
								[ngClass]="['menu-item', 'nav-menu-trigger', navbarItemClassName(item)]"
								[class.active]="isLinkActive(item)"
								[matTooltip]="item.title"
								matTooltipPosition="after"
								touchGestures="off"
								[matMenuTriggerFor]="myMenu"
								role="button"
								title="Open menu for {{ item.title }}"
							>
								<mat-icon [class.material-icons-outlined]="!item.noOutline" aria-hidden="true">{{
									item.matIcon
								}}</mat-icon>
								<span class="menu-item-text">{{ item.title }}</span>
							</div>
							<mat-menu #myMenu="matMenu" class="user-nav-menu">
								<ng-container *ngFor="let child of item.children">
									<a
										*ngIf="child.isVisibleToUser()"
										mat-menu-item
										[title]="child.title"
										[routerLink]="[child.link]"
										routerLinkActive="active"
										(click)="menuItemClick($event, item)"
									>
										<mat-icon [class.material-icons-outlined]="!item.noOutline" aria-hidden="true">
											{{ child.matIcon }}
										</mat-icon>
										<span>{{ child.title }}</span>
									</a>
								</ng-container>
							</mat-menu>
						</ng-container>
					</li>
				</ng-container>
			</ul>
			<ng-container *ngIf="isIpadPortraitOrSmaller()" [ngTemplateOutlet]="jumpToEventLink"></ng-container>
			<ng-container *ngIf="isIpadPortraitOrSmaller()" [ngTemplateOutlet]="knowledgeBaseLink"></ng-container>
		</div>

		<div class="pull-down">
			<ng-container *ngIf="!isIpadPortraitOrSmaller()" [ngTemplateOutlet]="jumpToEventLink"></ng-container>
			<ng-container *ngIf="!isIpadPortraitOrSmaller()" [ngTemplateOutlet]="knowledgeBaseLink"></ng-container>
			<div class="user-menu" *ngIf="isLoggedIn">
				<div
					class="user-menu-button nav-menu-trigger"
					data-testid="js-user-menu-button"
					[class.menu-item]="!isIpadPortraitOrSmaller()"
				>
					<div
						class="user-badge"
						matTooltip="User Menu"
						matTooltipPosition="after"
						touchGestures="off"
						[matMenuTriggerFor]="userMenu"
						role="button"
						#userMenuTrigger="matMenuTrigger"
						(click)="userMenuClick()"
						title="Open user menu"
					>
						<user-initials
							*ngIf="!isLoggedInAsUser"
							[name]="loginService.userService.user.name"
						></user-initials>
						<mat-icon *ngIf="isLoggedInAsUser">security</mat-icon>
					</div>

					<mat-menu #userMenu="matMenu" class="user-nav-menu">
						<label mat-menu-item *ngIf="adminModeService.shouldDisplayAdminModeAsOption">
							<input
								prismTriggerChangeDetection
								type="checkbox"
								[(ngModel)]="adminModeService.adminMode"
								(change)="adminModeService.persistAdminModeSetting()"
							/>
							Admin Mode
						</label>
						<label mat-menu-item *ngIf="adminModeService.adminMode">
							<input
								prismTriggerChangeDetection
								type="checkbox"
								[ngModel]="emsFieldService.displayFieldNames$ | async"
								(ngModelChange)="emsFieldService.displayFieldNames$.next($event)"
							/>
							Show EMS field names
						</label>
						<div mat-menu-item *ngIf="adminModeService.adminMode">
							prism-frontend{{ '@'
							}}<a
								target="_blank"
								href="https://onesolstice.atlassian.net/projects/PRSM?contains=v{{
									APP_VERSION
								}}&orderField=RANK&selectedItem=com.atlassian.jira.jira-projects-plugin%3Arelease-page&status=all"
								>v{{ APP_VERSION }}</a
							>
						</div>

						<div mat-menu-item *ngIf="adminModeService.adminMode" (click)="copyOrganizationId()">
							<strong>Organization:</strong>&nbsp;{{ orgSettingsService.settings.org_name }}
							<strong>Id:</strong>&nbsp;{{ userService.user.organization_id }}
						</div>
						<button
							*ngIf="isLoggedInAsUser"
							mat-menu-item
							title="{{ userDetails }} "
							(click)="logout($event, false)"
							class="active-admin-logout"
							data-testid="js-active-admin-logout-button"
						>
							<mat-icon aria-hidden="true">exit_to_app</mat-icon>
							<span>Logout of session As: {{ userService.user.name }}</span>
						</button>
						<div mat-menu-item class="theme-option" title="Configure your Prism theme">
							<theme-toggle></theme-toggle>
						</div>
						<a
							title="Settings"
							[routerLink]="[settingsNavbarItem.link]"
							routerLinkActive="active"
							(click)="menuItemClick($event, settingsNavbarItem)"
						>
							<button data-testid="js-settings-button" mat-menu-item>
								<mat-icon aria-hidden="true">{{ settingsNavbarItem.matIcon }}</mat-icon>
								<span>{{ settingsNavbarItem.title }}</span>
							</button>
						</a>
						<button data-testid="js-logout-button" mat-menu-item title="Logout" (click)="logout($event)">
							<mat-icon aria-hidden="true">exit_to_app</mat-icon>
							<span>Logout</span>
						</button>
					</mat-menu>
				</div>
			</div>
		</div>
	</nav>
	<div
		id="panel"
		#panel
		[class.undoMenu]="!isLoggedIn"
		[class.bg-gray-0]="isGrayBackgroundActivated"
		[ngClass]="'scrollable ' + appRouteToCSSClass"
	>
		<router-outlet></router-outlet>
	</div>
</main>

<ng-template #knowledgeBaseLink>
	<a
		class="menu-item"
		data-testid="js-knowledge-base-link"
		title="Knowledge Base"
		[href]="knowledgeBaseURL"
		target="_prism-knowledge-base"
		matTooltip="Knowledge Base"
		matTooltipPosition="after"
		touchGestures="off"
	>
		<mat-icon aria-hidden="true">help</mat-icon>
		<span class="menu-item-text">Knowledge Base</span>
	</a>
</ng-template>

<ng-template #jumpToEventLink>
	<a
		class="menu-item"
		data-testid="js-jump-to-event-link"
		title="{{ jumpToEventTooltip }}"
		(click)="openJumpToEvent('app-sidebar')"
		[matTooltip]="jumpToEventTooltip"
		matTooltipPosition="after"
		touchGestures="off"
	>
		<mat-icon aria-hidden="true">keyboard_command_key</mat-icon>
		<span class="menu-item-text">{{ jumpToEventTooltip }}</span>
	</a>
</ng-template>
