import { verboseDebug } from '@prism-frontend/utils/static/getDebug';
import * as _ from 'lodash';
export function sumPropOverObjects<T>(fromObjects: T[], propName: keyof T): number {
	const sum: number = _.sumBy(fromObjects, (thisObject: T): number => {
		if (!_.isNumber(thisObject[propName])) {
			verboseDebug(
				`sumPropOverObjects can only sum numbers: ${String(propName)} is ${typeof thisObject[
					propName
				]}. value: ${thisObject[propName]}`
			);
			// TODO PRSM-XXXX why do we not throw an error here...?
		}
		return Number(thisObject[propName]);
	});
	return sum;
}

export function averagePropOverObjects<T>(fromObjects: T[], propName: keyof T): number {
	if (!fromObjects.length) {
		return 0;
	}
	return sumPropOverObjects(fromObjects, propName) / fromObjects.length;
}
