import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeStyle } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
	public constructor(public sanitizer: DomSanitizer) {}
	public transform(url: string): SafeResourceUrl {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
	public constructor(private sanitizer: DomSanitizer) {}

	public transform(html: string): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}
}

@Pipe({ name: 'safeStyle' })
export class SafeStylePipe implements PipeTransform {
	public constructor(private sanitizer: DomSanitizer) {}

	public transform(style: string): SafeStyle {
		return this.sanitizer.bypassSecurityTrustStyle(style);
	}
}
