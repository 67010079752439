import { DocumentNode, gql } from '@apollo/client/core';
import { CollectedBy } from '@prism-frontend/typedefs/enums/CollectedBy';
import { PaymentStatusBackend } from '@prism-frontend/typedefs/enums/PaymentStatus';
import { Settlement } from '@prism-frontend/typedefs/settlement';
import { TalentData } from '@prism-frontend/typedefs/talentData';

export const FullSettlementFragment: DocumentNode = gql`
	fragment FullSettlementFragment on Settlement {
		event_talent_id
		event_promoter_id
		status
		notes
		collected_by
	}
`;

export const UpdateSettlementMutation: DocumentNode = gql`
	mutation UpdateSettlement(
		$event_talent_id: Int
		$event_promoter_id: Int
		$notes: String
		$status: String
		$collected_by: String
	) {
		UpdateSettlement(
			event_talent_id: $event_talent_id
			event_promoter_id: $event_promoter_id
			notes: $notes
			status: $status
			collected_by: $collected_by
		) {
			...FullSettlementFragment
		}
	}
	${FullSettlementFragment}
`;

export interface UpdateSettlementResponse {
	UpdateSettlement: Pick<TalentData, 'id'> & Pick<Settlement, 'status' | 'notes' | 'collected_by'>;
}

export interface UpdateSettlementMutationVariables {
	event_talent_id: number;
	event_promoter_id: number;
	notes?: string;
	status?: PaymentStatusBackend;
	collected_by?: CollectedBy;
}
