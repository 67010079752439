import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarErrorComponent } from '@prism-frontend/components/snackbar-error/snackbar-error.component';
import { RefreshService } from '@prism-frontend/services/deprecated/refresh.service';
import { DISABLE_AUTO_ERROR_HANDLING } from '@prism-frontend/services/legacy/http-interceptors/http-context-tokens';
import { LoginService } from '@prism-frontend/services/legacy/login.service';
import { SpinnerService } from '@prism-frontend/services/utils/spinner.service';
import {
	APINotFoundErrorResponseBody,
	APIValidationErrorResponseBody,
	APIValidationErrorResponseBodyLegacy,
} from '@prism-frontend/typedefs/api-response-types';
import { ApiConfig } from '@prism-frontend/utils/static/app.api.config';
import { errorDebug } from '@prism-frontend/utils/static/getDebug';
import { SegmentService } from 'ngx-segment-analytics';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import swal from 'sweetalert2';

// TODO PRSM-XXXX when calling actuallyThrowError we are usually not throwing the full error
//      which makes it hard to handle the errors downstream inline. We should
//      discuss & revisit this approach
const actuallyThrowError: (error: Error) => Observable<never> = throwError;
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	public constructor(
		private spinnerService: SpinnerService,
		private refreshService: RefreshService,
		private loginService: LoginService,
		private snackBarService: MatSnackBar,
		private segmentService: SegmentService,
		private apiConfig: ApiConfig
	) {}

	/**
	 * Return rxjs/EMPTY to stop error propagation
	 * Return actuallyThrowError(...) to let the error travel further
	 *
	 * TODO: Use standard HTTP status codes when possible instead of string 'error_type' responses
	 */
	public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			// eslint-disable-next-line complexity
			catchError((response: HttpErrorResponse): Observable<never> => {
				this.spinnerService.removeRequest();

				if (this.shouldSkip(request.url)) {
					return actuallyThrowError(new Error(response.error.message));
				}
				// Handle known Error responses
				switch (response.error.error_type) {
					case 'Version_Mismatch':
						this.refreshService.doRefresh();
						return EMPTY;
					case 'Throttled':
						// TODO PRSM-XXXX figure out how to retry, but normally we should never get this error
						const sanitizedUrl: string = (request.url || '').replaceAll(/\d+/,  '<id>');
						return actuallyThrowError(new Error(`Request throttled for endpoint: ${sanitizedUrl}`));
					case 'Unauthenticated':
						this.loginService.killToken(true);
						return EMPTY;
					case 'Login_Failed':
					case 'Login_Attempts_Exceeded':
						return actuallyThrowError(new Error(response.error.message));
					case 'Permission_Denied':
						// Throw a warning for this error, ideally we should never see it
						//  but it will indicate a permissions leak in the code
						// eslint-disable-next-line no-console
						console.error(
							'Warning! User was able to initiate a non-permissible request',
							(<APINotFoundErrorResponseBody>response.error).errors.meta_type
						);
						this.logErrorsAsJSON([request, response]);
						return actuallyThrowError(new Error(response.error.message));
					case 'Not_Found':
						this.showErrorSnackbar(`Prism could not find the requested ${response.error.meta_type}`);
						// eslint-disable-next-line no-console
						console.warn(JSON.stringify(request, null, 2));
						// eslint-disable-next-line no-console
						console.warn(JSON.stringify(response, null, 2));
						return actuallyThrowError(new Error(response.error.message));
					case 'Linked_Promoter_Event_Deleted':
						return actuallyThrowError(
							new Error(response.error.message, { cause: response.error.error_type })
						);
				}

				// Handle errors by status code
				switch (response.status) {
					case 404:
						this.showErrorSnackbar('Prism could not find the requested data.');
						// eslint-disable-next-line no-console
						console.error('Not found', response);
						this.logErrorsAsJSON([request, response]);
						return actuallyThrowError(new Error(response.error.message));
					case 410:
						errorDebug('Gone', response);
						this.logErrorsAsJSON([request, response]);
						return actuallyThrowError(response.error);
					case 422:
						let message: string = ``;
						let validationMessages: string = ``;
						if (response.error.message) {
							message = `Server error: ${response.error.message}`;
						}
						if (response.error?.errors?.validation_messages) {
							const error: APIValidationErrorResponseBody = response.error;
							validationMessages = Object.values(error.errors.validation_messages).flat().join(', ');
							message = `'Validation error: ${message} - ${validationMessages}`;
						} else if (response.error?.errors) {
							const error: APIValidationErrorResponseBodyLegacy = response.error;
							validationMessages = Object.values(error.errors).flat().join(', ');
							message = `'Validation error: ${message} - ${validationMessages}`;
						}

						// eslint-disable-next-line no-console
						console.error('422 Unprocessable Entity', response);
						this.logErrorsAsJSON([request, response]);
						this.segmentService.track('server-error');

						if (request.context?.get(DISABLE_AUTO_ERROR_HANDLING)) {
							return actuallyThrowError(response.error);
						}

						this.showErrorSnackbar(message);
						return actuallyThrowError(new Error(response.error.message));
					case 500:
						this.showErrorSnackbar(
							'There was a problem with the last operation. Please try again, or contact us if the error continues.'
						);
						// eslint-disable-next-line no-console
						console.error('Internal Server Error', response);
						this.logErrorsAsJSON([request, response]);
						this.segmentService.track('server-error');
						return actuallyThrowError(new Error(response.error.message));
					case 503:
						// Redirect to maintenance page
						location.href = '/maintenance';
						return EMPTY;
					case 504:
						this.showErrorSnackbar('Timeout');
						// eslint-disable-next-line no-console
						console.error('Timeout', response);
						this.logErrorsAsJSON([request, response]);
						return actuallyThrowError(new Error(response.error.message));
				}

				// If we get a status 0 we are having a connection issue
				//  (most likely), no need to report a serious error, just
				//  remind them to check their internet status
				if (response.status === 0) {
					swal.fire('Internet connected?', 'Please check your internet connection.', 'question').then(
						(): void => {
							return location.reload();
						}
					);
					return EMPTY;
				}
				// We got an error type we don't know how to handle,
				// show our embarassing snackbar :`(
				// eslint-disable-next-line no-console
				console.error('Warning! Unhandled error type received from server');
				this.logErrorsAsJSON([request, response]);
				this.segmentService.track('server-error');
				this.showErrorSnackbar(
					'There was a problem with the last operation. Please try again, or contact us if the error continues.'
				);
				return actuallyThrowError(new Error('API error'));
			})
		);
	}

	/**
	 * Show a snackbar error message. This should be a last
	 * resort if we cannot handle the error
	 */
	private showErrorSnackbar(message: string): void {
		this.snackBarService.openFromComponent(SnackbarErrorComponent, {
			panelClass: ['snack-bar-error'],
			duration: 5000,
			data: {
				message,
			},
		});
		// eslint-disable-next-line no-console
		console.error(message);
	}

	private shouldSkip(url: string): boolean {
		/**
		 * Not the most elegant solution but interceptors always run before
		 * local HTTP error handlers so there's no way to handle error locally
		 * and skip the global ErrorInterceptor. Also, until Angular 8 (#18155, PR 25751),
		 * there's no way to pass extra parameters to interceptors via HttpClient
		 */
		const apiRoutesToSkip: string[] = [
			this.apiConfig.GET_EVENT_FEED,
			this.apiConfig.GET_EVENT_FEED_TOKEN,
			this.apiConfig.ARTISTSEARCH,
		];

		return apiRoutesToSkip.some((apiRoute: string): boolean => {
			const regexString: string = apiRoute.replace('%i', '[0-9]+').replace('/', '\\/');
			const regExp: RegExp = new RegExp(regexString);
			return regExp.test(url);
		});
	}

	private logErrorsAsJSON(errors: unknown[]): void {
		errors.forEach((e: unknown): void => {
			// eslint-disable-next-line no-console
			console.error(JSON.stringify(e, null, 2));
		});
	}
}
