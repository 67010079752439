import { RENDER_AS_EMPTY } from '@prism-frontend/components/ems-field/ems-field.typedefs';
import { EMS, EMSCustomField } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { EMSFieldDefs, EMSFieldMeta, EMSFieldsMeta } from '@prism-frontend/typedefs/ems/EMSFieldMeta';
import { starify } from '@prism-frontend/utils/static/test-helpers/listAllPropsOnObject';
import * as _ from 'lodash';
import objectPathWild from 'object-path-wild';

/**
 * Extracts values from the EMS object based on the given EMS metadata ID.
 *
 * @param {Partial<EMS>} ems - The EMS object to extract values from.
 * @param {keyof EMSFieldDefs} emsMetadataId - The EMS metadata ID specifying the path to extract.
 * @returns {unknown} - The extracted value or values from the EMS object.
 */
export function emsValue(ems: Partial<EMS>, emsMetadataId: keyof EMSFieldDefs): unknown {
	if (!_.isObject(ems)) {
		throw new Error('emsValue: ems is not an object');
	}
	if (!_.isString(emsMetadataId)) {
		throw new Error('emsValue: emsMetadataId is not a string');
	}
	const starifiedFieldPath: string = starify(emsMetadataId, '.*');
	const metadata: EMSFieldMeta = EMSFieldsMeta[starifiedFieldPath];
	if (
		emsMetadataId !== RENDER_AS_EMPTY &&
		!emsMetadataId.endsWith('costCalc') &&
		!emsMetadataId.endsWith('emsPath') &&
		!emsMetadataId.endsWith('emsMetadataId') &&
		!metadata
	) {
		// eslint-disable-next-line no-console
		console.warn(`Metadata not found for: ${emsMetadataId}, emsMetadataId: ${starifiedFieldPath}`);
	}
	const shouldReturnArray: boolean = emsMetadataId.indexOf('.*') !== -1;

	/* > objectPathWild({data:[{test: 'test'}]}, 'data.*')
	 * [ { test: 'test' } ]
	 * > objectPathWild({data:[{test: 'test'}]}, 'data')
	 * [ [ { test: 'test' } ] ]
	 *
	 * Note that the .* at the end results in the correct return value, but the lack
	 * of a .* results in a double nested array.
	 */
	const results: unknown[] = objectPathWild(ems, emsMetadataId);

	if (_.isArray(results) && !shouldReturnArray) {
		return results[0];
	}
	return results;
}

export function customFieldValue(customFieldsByKey: Record<string, EMSCustomField>, customFieldKey: string): unknown {
	const emsCustomField: EMSCustomField = customFieldsByKey[customFieldKey];
	if (!emsCustomField) {
		return undefined;
	}
	if (emsCustomField.flatType.startsWith('advanced_')) {
		throw new Error('Advanced custom fields are not supported to be used as data chips');
	}
	const value: unknown = emsCustomField.rawValue;
	if (value === null || value === undefined) {
		return '';
	}
	// PRSM-9274 support either raw or formatted value
	return `${value}`;
}
