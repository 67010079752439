import { BooleanInputConfig } from '@prism-frontend/components/simple-form-field/components/boolean-input/boolean-input.component';
import { DateInputConfig } from '@prism-frontend/components/simple-form-field/components/date-input/date-input.component';
import { NumberInputConfig } from '@prism-frontend/components/simple-form-field/components/number-input/number-input.component';
import { TextInputConfig } from '@prism-frontend/components/simple-form-field/components/text-input/text-input.component';
import { AllSimpleFormFieldConfigs } from '@prism-frontend/components/simple-form/simple-form.typedefs';
import { dealStatusToLabel } from '@prism-frontend/entities/deal-tracker/ALL_DEAL_STATUS_OPTIONS';
import { DealStatus } from '@prism-frontend/typedefs/enums/DealStatus';
import { EMSFieldType } from '@prism-frontend/typedefs/enums/EMSFieldType';
import { Currency } from '@prism-frontend/typedefs/enums/currency';
import {
	DISPLAY_DATE_FORMAT_ABBREVIATED,
	DISPLAY_DATE_FORMAT_ABBREVIATED_AND_TIME,
	shouldDisplayTime,
} from '@prism-frontend/utils/static/dateHelper';
import { formatAmount } from '@prism-frontend/utils/static/format-amount';
import { Debug, getDebug } from '@prism-frontend/utils/static/getDebug';
import _ from 'lodash';
import moment from 'moment';

const debug: Debug = getDebug('ems');

type FormatFieldByTypeMethod = (value: unknown, _currency: Currency, _event_id: number) => string;
type EMSFieldFormConfigByType = (value: unknown, _currency: Currency) => AllSimpleFormFieldConfigs<unknown>;

/**
 * format different EMS fields for rendering
 */
export const FormatFieldByType: {
	[key in EMSFieldType]?: FormatFieldByTypeMethod;
} = {
	[EMSFieldType.dollarAmount]: (value: unknown, currency: Currency): string => {
		return formatAmount(Number(value), currency);
	},
	[EMSFieldType.percentage]: (value: unknown, _currency: Currency): string => {
		return `${value || 0}%`;
	},
	[EMSFieldType.number]: (value: unknown, _currency: Currency): string => {
		if (!value) {
			return value as string;
		}
		// ensure we support displaying up to 7 decimal places, since that is the limit
		// we allow to show for numbers
		return Number(value).toLocaleString(undefined, { maximumFractionDigits: 7 });
	},
	[EMSFieldType.boolean]: (value: unknown, _currency: Currency): string => {
		return value ? 'Yes' : 'No';
	},
	[EMSFieldType.id]: (value: unknown, _currency: Currency): string => {
		if (!value) {
			return value as string;
		}
		return value.toString();
	},
	[EMSFieldType.VariableCostType]: (value: unknown, _currency: Currency): string => {
		// TODO PRSM-XXXX map to human readable
		return value as string;
	},
	[EMSFieldType.DealTypes]: (value: unknown, _currency: Currency): string => {
		// TODO PRSM-XXXX map to human readable
		return value as string;
	},
	[EMSFieldType.CostGroupCategory]: (value: unknown, _currency: Currency): string => {
		// TODO PRSM-XXXX map to human readable
		return value as string;
	},
	[EMSFieldType.CostGroupType]: (value: unknown, _currency: Currency): string => {
		// TODO PRSM-XXXX map to human readable
		return value as string;
	},
	[EMSFieldType.DealStatus]: (value: unknown, _currency: Currency): string => {
		return dealStatusToLabel(value as DealStatus);
	},
	[EMSFieldType.date]: (value: unknown, _currency: Currency): string => {
		if (!value) {
			return '';
		}
		// If value is a string but not a date, return the string. This is to account for "Do not announce"
		// A value of "Do not announce" surfaces when a user toggled do_not_announce in an event's settings to
		// indicate that the deal should never be announced and therfore doesn't have a date: https://onesolstice.atlassian.net/browse/PRSM-8358
		if (value === 'Do Not Announce') {
			return value;
		}
		// display dates with moment formatting
		return shouldDisplayTime(value)
			? moment(value).format(DISPLAY_DATE_FORMAT_ABBREVIATED_AND_TIME)
			: moment(value).format(DISPLAY_DATE_FORMAT_ABBREVIATED);
	},
	[EMSFieldType.stringArray]: (value: unknown, _currency: Currency, event_id: number): string => {
		if (_.isArray(value)) {
			return (value as string[]).join(', ');
		}
		// TODO PRSM-8808 remove once be handles this properly
		debug(`The ems field value is not a stringArray ${event_id}`, value);
		// if for some reason the incoming value is not an array as expected
		// try parsing it values and treat it as an array
		const parsedValue: string[] = Object.values(value);
		if (!parsedValue || !parsedValue.length) {
			return '';
		}
		return parsedValue.join(', ');
	},
};

/**
 * simple form field config options for EMS types
 *
 */
export const EMSSimpleFormConfigByType: {
	[key in EMSFieldType]?: EMSFieldFormConfigByType;
} = {
	[EMSFieldType.dollarAmount]: (_value: unknown, currency: Currency): NumberInputConfig<unknown> => {
		return {
			fieldType: 'number',
			key: '' as never,
			required: false,
			currency,
			maxDecimalPlaces: 2,
			changeValuesOnBlur: true,
			changeValuesOnEnter: true,
		};
	},
	[EMSFieldType.percentage]: (): NumberInputConfig<unknown> => {
		return {
			fieldType: 'number',
			key: '' as never,
			required: false,
			suffixText: '%',
			maxDecimalPlaces: 2,
			changeValuesOnBlur: true,
			changeValuesOnEnter: true,
		};
	},
	[EMSFieldType.number]: (_value: unknown): NumberInputConfig<unknown> => {
		return {
			fieldType: 'number',
			key: '' as never,
			required: false,
			maxDecimalPlaces: 4,
			changeValuesOnBlur: true,
			changeValuesOnEnter: true,
		};
	},
	[EMSFieldType.date]: (_value: unknown): DateInputConfig<unknown> => {
		return {
			fieldType: 'date',
			key: '' as never,
			required: false,
		};
	},
	[EMSFieldType.boolean]: (_value: unknown): BooleanInputConfig<unknown> => {
		return {
			fieldType: 'boolean',
			key: '' as never,
			required: false,
		};
	},
	[EMSFieldType.string]: (_value: unknown): TextInputConfig<unknown> => {
		return {
			fieldType: 'text',
			key: '' as never,
			required: false,
			changeValuesOnBlur: true,
			changeValuesOnEnter: true,
		};
	},
};
