import { CostCalc } from '@prism-frontend/typedefs/enums/calc';
import { EventFeeType } from '@prism-frontend/typedefs/enums/EventFeeType';
import { EventFee } from '@prism-frontend/typedefs/eventFee';
import { EventPropsForTicket, TicketCalculation, TicketModel } from '@prism-frontend/typedefs/ticket';
import { plainToInstance } from 'class-transformer';
import { IsNumber, IsString } from 'class-validator';

class FlatTicketRevenueBackend extends TicketModel {
	@IsNumber() public id: number = null;

	@IsString() public name: string = '';
	@IsString() public created_at: string = '';
	@IsString() public updated_at: string = '';
	@IsString() public deleted_at: string = '';

	@IsNumber() public potential_gross: number = 0;
	@IsNumber() public actual_gross: number = 0;
}

export class FlatTicketRevenue extends FlatTicketRevenueBackend implements TicketCalculation {
	public constructor(ticket?: Partial<FlatTicketRevenue>) {
		super();
		return plainToInstance(FlatTicketRevenue, ticket);
	}

	public static getEventFeesForTicket(
		_ticket: FlatTicketRevenue,
		eventFees: EventFee[],
		onlyPreSettlementFees: boolean
	): EventFee[] {
		return eventFees.filter((eventFee: EventFee): boolean => {
			if (eventFee.type === EventFeeType.PERCENT_OF_GROSS) {
				return true;
			}
			if (eventFee.type === EventFeeType.PERCENT_OF_ADJUSTED_GROSS && !onlyPreSettlementFees) {
				return true;
			}
			return false;
		});
	}

	public ticketRevenue(costCalc: CostCalc): number {
		switch (costCalc) {
			case CostCalc.Budgeted:
			case CostCalc.Estimated:
			case CostCalc.Potential:
				return this.potential_gross;
			case CostCalc.Actual:
			case CostCalc.Reported:
				return this.actual_gross;
			default:
				throw new Error(`Unrecognized CostCalc ${costCalc}.`);
		}
	}

	public netGross(costCalc: CostCalc, eventProps: EventPropsForTicket): number {
		const adjustedGross: number = this.adjustedGross(costCalc, eventProps);
		let netGross: number = adjustedGross * eventProps.withoutTaxMultiplier;

		netGross -= this.calculateAdjustedGrossFees(eventProps, adjustedGross);
		return netGross;
	}

	public adjustedGross(costCalc: CostCalc, eventProps: EventPropsForTicket): number {
		// for adjusted gross only consider pre-settlement fees
		return this.ticketRevenue(costCalc) - this.totalEventFees(costCalc, eventProps, true);
	}

	public totalTax(costCalc: CostCalc, eventProps: EventPropsForTicket): number {
		const adjustedGross: number = this.adjustedGross(costCalc, eventProps);
		const netGross: number = this.netGross(costCalc, eventProps);
		return adjustedGross - netGross - this.calculateAdjustedGrossFees(eventProps, adjustedGross);
	}

	public totalEventFees(costCalc: CostCalc, eventProps: EventPropsForTicket, onlyPreSettlementFees: boolean): number {
		const relevantEventFees: EventFee[] = FlatTicketRevenue.getEventFeesForTicket(
			this,
			eventProps.allEventFees,
			onlyPreSettlementFees
		);
		let totalEventFees: number = 0;
		relevantEventFees.forEach((eventFee: EventFee): void => {
			totalEventFees += eventFee.eventFee(costCalc, [], [this], eventProps);
		});
		return totalEventFees;
	}
}
