import { DocumentNode, gql } from '@apollo/client/core';
import { CoreUserFragment } from '@prism-frontend/typedefs/graphql/UserQuery';
import { Staff } from '@prism-frontend/typedefs/organization';
import { User } from '@prism-frontend/typedefs/user';

export const AllAgentsQuery: DocumentNode = gql`
	query AgentsQuery {
		users(is_agent: true) {
			...CoreUserFragment
		}
	}
	${CoreUserFragment}
`;

export type AgentSuggestion = Pick<User, 'id' | 'email'> & {
	staff: Pick<Staff, 'id' | 'name'>;
};

export interface AllAgentsQueryResponse {
	users: AgentSuggestion[];
}
