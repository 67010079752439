import { DocumentNode, gql } from '@apollo/client/core';
import { ContactRole } from '@prism-frontend/typedefs/ContactRole';
import {
	fetchPaginationProps,
	PaginationDetails,
	PaginationProps,
	PaginationQueryProps,
} from '@prism-frontend/typedefs/graphql/PaginationDetails';

const paginationProps: PaginationProps = fetchPaginationProps();

// a graphql fragment to be used in other queries when a full contact role model is needed
export const ContactRolePropsFragment: DocumentNode = gql`
	fragment ContactRoleProps on ContactRole {
		id
		name
		description
		show_in_docs
	}
`;

// a query to populate the contact select dropdown component
export const PaginatedContactRoleQuery: DocumentNode = gql`
query PaginatedContactRoleQuery(
    $search: String
    ${paginationProps.queryParams}
) {
    contactRoleList(
        search: $search
        ${paginationProps.queryArguments}
    )  {
        data {
            ...ContactRoleProps
        }
        ${paginationProps.responseProps}
    }
}
${ContactRolePropsFragment}
`;

export const CreateContactRole: DocumentNode = gql`
	mutation CreateContactRole($name: String!, $description: String, $show_in_docs: Boolean) {
		createContactRole(name: $name, description: $description, show_in_docs: $show_in_docs) {
			...ContactRoleProps
		}
	}
	${ContactRolePropsFragment}
`;

export const UpdateContactRoleMutation: DocumentNode = gql`
	mutation UpdateContactRole($id: Int!, $name: String!, $description: String, $show_in_docs: Boolean) {
		updateContactRole(id: $id, name: $name, description: $description, show_in_docs: $show_in_docs) {
			...ContactRoleProps
		}
	}
	${ContactRolePropsFragment}
`;

export const DeleteContactRoleMutation: DocumentNode = gql`
	mutation DeleteContactRole($id: Int!) {
		deleteContactRole(id: $id)
	}
`;

export interface UpdateContactRoleMutationArgs extends Pick<ContactRole, 'id' | 'name'> {
	description?: string;
	show_in_docs?: boolean;
}

export interface UpdateContactRoleMutationResponse {
	updateContactRole: ContactRole;
}

export interface CreateContactRoleMutationArgs extends Pick<ContactRole, 'name'> {
	description?: string;
	show_in_docs?: boolean;
}

export interface CreateContactRoleMutationResponse {
	createContactRole: ContactRole;
}

export interface DeleteContactRoleMutationArgs extends Pick<ContactRole, 'id'> {}

export interface PaginatedContactRoleArguments extends PaginationQueryProps {
	search?: string;
}

export interface PaginatedContactRoleQueryResult {
	contactRoleList: {
		data: ContactRole[];
	} & PaginationDetails;
}
