import { IsInt, IsNumber, IsOptional, IsString } from 'class-validator';

export class BalancePayment {
	// db fields
	@IsInt() public id: number = null;
	@IsInt() public deal_id: number = null;
	@IsInt() public invoice_id: number = null;
	@IsString() public created_at?: string;

	@IsNumber()
	public amount: number = 0;

	@IsOptional()
	@IsNumber()
	public exchange_rate_usd: number | null = null;
}
