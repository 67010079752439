import { Injectable } from '@angular/core';
import { performVersionUpgrades } from '@prism-frontend/services/api/json-blob-settings.service';

/* Alternative to localstorage, memory
	storage for certain browsers in private mode */
class LocalStorageAlternative {
	private structureLocalStorage: { [key: string]: string } = {};

	public setItem(key: string, value: string): void {
		this.structureLocalStorage[key] = value;
	}

	public getItem(key: string): string | null {
		if (typeof this.structureLocalStorage[key] !== 'undefined') {
			return this.structureLocalStorage[key];
		}
		return null;
	}

	public removeItem(key: string): void {
		this.structureLocalStorage[key] = undefined;
	}
}

@Injectable({
	providedIn: 'root',
})
export class StorageService {
	private storageEngine: Storage | LocalStorageAlternative;

	public constructor() {
		try {
			localStorage.setItem('storage_test', '');
			localStorage.removeItem('storage_test');
			this.storageEngine = localStorage;
		} catch (err) {
			this.storageEngine = new LocalStorageAlternative();
		}
	}

	public setItem(key: string, value: string): void {
		this.storageEngine.setItem(key, value);
	}

	public getItem(key: string): string | null {
		return this.storageEngine.getItem(key);
	}

	public getAndParseItem<T>(key: string, defaultValue: T = null): T {
		const valueAsString: string = this.storageEngine.getItem(key);
		if (valueAsString === null) {
			return defaultValue;
		}
		try {
			const value: T = JSON.parse(valueAsString);
			return value;
		} catch (e) {
			return defaultValue;
		}
	}

	public removeItem(key: string): void {
		this.storageEngine.removeItem(key);
	}

	/* The proceding two methods provide a quick-and-dirty means of storing
	 * versioned objects in user's localStorage database, which we can upgrade
	 * over time across different deploys of the app. This enables us to enrich
	 * the data we store in localStorage over time, without having to worry about
	 * which version of the code last stored something to localStorage for a
	 * given user.
	 */

	/* This method works very similar to localStorage.setItem, except it provides
	 * two conveniences:
	 * 1. localStorage.setItem requires a string whereas this method requires an
	 *    object, which gets stringified for you before stuffing to localStorage.
	 *    The corresponding getVersionedItem parses the stored value back into an
	 *    object before returning it.
	 * 2. setVersionedItem requires an additional `version` parameter, which will
	 *    be stored along-side the object in the user's localStorage database.
	 *    This extra bit of metadata is used by getVersionedItem for ensuring
	 *    an object is returned that works with the current version of the code.
	 */
	public setVersionedItem<T>(key: string, version: number, value: T): void {
		this.storageEngine.setItem(
			key,
			JSON.stringify({
				version,
				value,
			})
		);
	}

	/**
	 * This method works very similar to localStorage.getItem, except it provides
	 * a few conveniences.
	 * 1. localStorage.getItem returns a string value whereas this method parses
	 *    the value that was stored in localStorage and returns it as an object
	 *    instead
	 * 2. In the event that there was nothing stored in localStorage for the given key,
	 *    this method will return a defaultValue, which is a required second argument.
	 * 3. This method checks the version of the object, as it was stored last by setVersionedItem
	 *    and runs it through a series of upgrades (with custom logic at each step)
	 *    to ensure the code will work with whatever the user had stored in
	 *    localStorage previously. If you are familiar with the way DB migrations
	 *    work across different versions of native apps, this approach is similar.
	 * @param key  They key in localStorage which we want to fetch.
	 * @param upgrades	This is an object mapping numbers to functions. For any
	 *   given key, n the upgrade performed by the function is from `n` to `n+1`.
	 *   Thus the "latestVersion" of the object represented by key will be assumed
	 *   to be the <largest key of upgrades> + 1. The method representing the largest
	 *   key in upgrades will return an object of type T, while methods from previous
	 *   versions will likely accept and return some version of T from an older version
	 *   of the code.
	 *   The upgrades will be run in succession, so if the user had version 1 stored,
	 *   when fetching this item, upgrades from key 1 => latestVersion - 1 will execute.
	 */
	public getVersionedItem<T>(key: string, defaultValue: T, upgrades: { [key: number]: (arg: T) => T }): T {
		// Defaults for if the key didn't exist
		let retVal: T = defaultValue;
		let currentlyStoredVersion: number = 0;

		// Attempt to pull the existing value from localStorage
		const results: {
			version: number;
			value: T;
		} = this.getAndParseItem(key, null);
		// If something was in localStorage already
		if (results) {
			// replace our defaults with what came from the user's localStorage
			retVal = results.value;
			currentlyStoredVersion = results.version;
		}

		retVal = performVersionUpgrades(retVal, currentlyStoredVersion, upgrades) as T;
		return retVal;
	}
}

// Called during login/logout/log-in-as to prevent stale cache state when a different user may log in in the same browser
export function invalidateLocalStorageCaches(): void {
	const pattern: RegExp = /(last.+)|(data-.+)/;
	try {
		const allLocalStorageKeys: string[] = Object.keys(window.localStorage);
		allLocalStorageKeys.forEach((key: string): void => {
			if (pattern.test(key)) {
				localStorage.removeItem(key);
			}
		});
	} catch (e) {
		// eslint-disable-next-line no-console
		console.warn(e);
	}
}
