import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { take } from 'rxjs';
type SnackbarUniqueKey =
	| 'editCosts'
	| 'editEventFee'
	| 'editTicket'
	| 'editTourTicket'
	| 'editTicketCommission'
	| 'editTour'
	| 'saveEventData';
/**
 * This service is a wrapper for the Angular Material Snackbar service.
 * Its main use its to provide a simplier api to show snackbars over the app
 * either propagating any amount of snackbar messages or considering unique
 * messages to avoid showing the same snackbar multiple times, useful when
 * inline editing too fast.
 */
@Injectable({ providedIn: 'root' })
export class SnackbarService {
	private snackbarNotifications: Set<SnackbarUniqueKey> = new Set<SnackbarUniqueKey>();

	public constructor(public matSnackBar: MatSnackBar) {}

	public open(message: string, duration: number = 1500, action?: string): void {
		this.matSnackBar.open(message, action, {
			duration,
		});
	}

	/**
	 * Will trigger a snackbar message only if the associated key for that message
	 * is not already being displayed in a previous snackbar. Avoids multiple messages
	 * to popup to the user.
	 * @param key the key to identify the snackbar message
	 * @param message the message of the snackbar
	 * @param duration the duration of the snackbar
	 * @param action the action for the snackbar, if any
	 */
	public openUnique(key: SnackbarUniqueKey, message: string, duration: number = 1500, action?: string): void {
		if (this.snackbarNotifications.has(key)) {
			return;
		}
		this.snackbarNotifications.add(key);
		this.matSnackBar
			.open(message, action, {
				duration,
			})
			.afterDismissed()
			.pipe(take(1))
			.subscribe((): void => {
				this.snackbarNotifications.delete(key);
			});
	}

	public openFromComponent(component: ComponentType<unknown>, config?: MatSnackBarConfig): void {
		this.matSnackBar.openFromComponent(component, config);
	}

	public dismiss(): void {
		this.matSnackBar.dismiss();
	}
}
