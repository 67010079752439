import { Injectable } from '@angular/core';
import { Artist } from '@prism-frontend/entities/artists/artist-typedefs';
import { ApiService } from '@prism-frontend/services/legacy/api.service';
import { FeatureGateService } from '@prism-frontend/services/utils/feature-gate.service';
import { DocTypes } from '@prism-frontend/typedefs/enums/doc-types';
import { PrismEvent, SourcePrismEvent } from '@prism-frontend/typedefs/event';
import { LinkDealEventData } from '@prism-frontend/typedefs/LinkDealEventData';
import { Organization, PartnerOrganization } from '@prism-frontend/typedefs/organization';
import { TalentData } from '@prism-frontend/typedefs/talentData';
import { errorDebug } from '@prism-frontend/utils/static/getDebug';
import { plainToInstance } from 'class-transformer';

/**
 * This includes the base data for a linked event. it includes a bit of information
 * about the source event, but only as much as was provided in the PDF that was sent
 * to the user. if they got the hash to check, it was from a PDF, and that infromation
 * would be made public to them
 */
export interface LinkDealMetadata {
	// source event, talent, and org. these exist in a promoter org
	// and are always returned if theres a matching link hash
	source_event: SourcePrismEvent;
	source_talent_data: TalentData[];
	source_organization: PartnerOrganization;

	// if these values exist, then a link already exists
	// with the `source_talent_deal` in this org
	local_event: PrismEvent;
	local_talent_data: TalentData[];

	// for agents, an optional matching talent agent record
	// that gets linked by spotify ID from the source_talent_data
	matching_client: Artist | null;

	// if the promoter has the connected show beta enabled or not
	promoter_has_connected_show_enabled: boolean;
	// the talent deal url_hash we use to track the talent for the linked event
	deal_hash: string;
}

/**
 * this typedef is returned by the check link token endpoint. Besides the base metadata,
 * it includes information about the token used to link the event, its code and status
 * wether its been used or still valid
 */
export interface LinkDealMetadataAndTokenHash extends LinkDealMetadata {
	token: string;
	status: 'valid' | 'used';
}

/**
 * this is the payload we send to the create link deal endpoint
 */
export type CreateLinkDealMetadata = LinkDealMetadataAndTokenHash;

/**
 * this is the payload we send to the refresh link deal endpoint
 */
export type RefreshLinkDealMetadata = Pick<
	LinkDealMetadataAndTokenHash,
	'deal_hash' | 'source_organization' | 'source_event' | 'token' | 'promoter_has_connected_show_enabled'
>;

interface LinkNewTokenPostPayload {
	// the doc type for which the token is being created
	doc_type: DocTypes;
}
export interface LinkNewDealPostPayload {
	// the unique token of the event to be linked
	url_hash: string;

	// if linking to an existing event
	event_id: number | null;
}

export interface LinkDealPostPayload extends LinkNewDealPostPayload {
	// if creating a new event to link to
	event_name: string | null;
	venue_id: number | null;
	event_stage_ids: number[] | null;
}

/**
 * this is the response from the create link token endpoint
 * which returns a new one-time token to be used in the share
 * link deal url to be generated
 */
interface LinkDealUniqueTokenResponse {
	token: string;
}

/**
 * represents the response error data we get back from the
 * check token endpoint if the token has already been used
 * to create a link deal event. It contains info about the
 * event that was created with the token so the user is redirected
 */
interface LinkDealUsedTokenResponse {
	local_event_id: number;
	used_at: string;
	used_by: string;
}

@Injectable({ providedIn: 'root' })
export class LinkDealApiService {
	public constructor(private apiService: ApiService, private featureGateService: FeatureGateService) {}

	/**
	 * Based on a deal ID, create a unique token that can be used to create a link
	 * deal event which will be attached in the generated PDF
	 * @param dealId The deal ID to create a token for
	 * @returns The one-time token that can be used to create a link deal event
	 */
	public async createEventLinkToken(dealId: number, docType: DocTypes): Promise<string> {
		return this.apiService
			.postP<LinkNewTokenPostPayload, LinkDealUniqueTokenResponse>(
				[this.apiService.ep.LINK_DEAL_CHECK_TOKEN, dealId],
				{
					doc_type: docType,
				}
			)
			.then((response: LinkDealUniqueTokenResponse): string => {
				return response.token;
			});
	}

	/**
	 * Checks if a token or url_hash is valid and returns the metadata for the link deal.
	 * Offers should contain a token in the link deal url.
	 * This function will call the corresponding BE service and parse the response
	 * accordingly to provide a valid link deal metadata in any case
	 * @param token The token to check for a valid link deal
	 * @returns The metadata for the link deal
	 */
	public async checkLinkDealToken(token: string): Promise<CreateLinkDealMetadata> {
		return this.apiService
			.getP<unknown, LinkDealMetadata>([this.apiService.ep.LINK_DEAL_CHECK_TOKEN, token])
			.then((metadata: LinkDealMetadata): CreateLinkDealMetadata => {
				return {
					source_event: plainToInstance(PrismEvent, metadata.source_event),
					source_talent_data: plainToInstance(TalentData, metadata.source_talent_data),
					source_organization: plainToInstance(Organization, metadata.source_organization),
					local_event: metadata.local_event ? plainToInstance(PrismEvent, metadata.local_event) : null,
					local_talent_data: metadata.local_talent_data
						? plainToInstance(TalentData, metadata.local_talent_data)
						: null,
					matching_client: metadata.matching_client
						? plainToInstance(Artist, metadata.matching_client)
						: null,
					promoter_has_connected_show_enabled: metadata.promoter_has_connected_show_enabled,
					deal_hash: metadata.deal_hash,
					token,
					status: 'valid',
				};
			})
			.catch((error: Error | LinkDealUsedTokenResponse): CreateLinkDealMetadata => {
				// if the token has been used to create an event, pull the id from the response
				// and return the metadata for the linked event to redirect the user
				if ((error as LinkDealUsedTokenResponse).local_event_id) {
					return {
						source_event: null,
						source_talent_data: null,
						source_organization: null,
						local_event: plainToInstance(PrismEvent, {
							id: (error as LinkDealUsedTokenResponse).local_event_id,
						}),
						local_talent_data: null,
						matching_client: null,
						promoter_has_connected_show_enabled: undefined,
						deal_hash: null,
						token: token,
						status: 'used',
					};
				}
				// at this point the token is either invalid or it has expired, we can throw the error
				errorDebug(`Error while trying to check the link deal for token: ${token}:`, error);
				throw error;
			});
	}

	/**
	 * Loads a token for a link deal event based on the doc type and talent deal ID
	 * from a particular event. If the doc type doesn't support sharing a link deal
	 * or when the event is a rental or when we can't find a particular talent for the
	 * provided id, then null is returned. Othewise we check the connected show environment
	 * feature flag and if it's enabled, we call the api service to generate a new token
	 * otherwise we return the url_hash from the talent deal as we previously did
	 *
	 * @param docType The doc type to be generated
	 * @param event The event data
	 * @param talentDealId The talent deal ID to be used
	 * @returns The token to be used for the link deal or null
	 */
	public async loadTokenForLinkDeal(
		docType: DocTypes,
		event: PrismEvent,
		talentDealId: number | string
	): Promise<string | null> {
		if (docType === DocTypes.Advance || docType === DocTypes.Contract || event.isRental) {
			return Promise.resolve(null);
		}
		const talentLinkDeal: TalentData = event.talent_data.find((talent: TalentData): boolean => {
			return talent.id === talentDealId;
		});
		if (!talentLinkDeal) {
			return Promise.resolve(null);
		}

		return this.createEventLinkToken(talentLinkDeal.id, docType);
	}

	/**
	 * create a new event link. We can either send some payload information for the first time
	 * we are linking a deal event or we can send information about a new deal we want to add
	 * to an existing linked event, for instance when having a headliner and a support talent deals
	 *
	 * @param payload the payload needed to link a deal, either for an existing linked deal
	 * or for a new event to be linked
	 */
	public async createEventLink(payload: LinkDealPostPayload | LinkNewDealPostPayload): Promise<LinkDealEventData> {
		return this.apiService.postP<unknown, LinkDealEventData>([this.apiService.ep.LINK_DEAL_CREATE_LINK], {
			...payload,
		});
	}

	/**
	 * refresh an existing event link
	 */
	public async refreshEventLink(hash: string): Promise<LinkDealEventData> {
		return this.apiService.putP<unknown, LinkDealEventData>([this.apiService.ep.LINK_DEAL_CHECK_TOKEN, hash]);
	}
}
