import { environment } from 'environments/environment';

export type PrismHelpArticleKey =
	| 'additionalRevenueIncNetGross'
	| 'adjustments'
	| 'agentAdjustments'
	| 'base-tax'
	| 'breakeven'
	| 'connectedShowsImport'
	| 'contacts'
	| 'coproBonusTerms'
	| 'coproEstimatedEarnings'
	| 'coproShareOfProfitLoss'
	| 'dealTrackingAgents'
	| 'dealTrackingPromoters'
	| 'glossary'
	| 'mailMerge'
	| 'organizationSettings'
	| 'payments'
	| 'platformRebate'
	| 'presettlement-help'
	| 'royalty'
	| 'tours'
	| 'withholding'
	| 'knowledgeBase'
	| 'dealConflicts'
	| 'ticketingIntegrations';

export interface PrismHelpArticleConfig {
	title: string;
	link: string;
}

export const helpLinks: { [key in PrismHelpArticleKey]: PrismHelpArticleConfig } = {
	additionalRevenueIncNetGross: {
		title: 'Additional Revenue Included in Net Gross',
		link: `${getHelpCenterURL()}/articles/6817807-additional-revenue-included-in-net-gross`,
	},
	adjustments: {
		title: 'Adjustments',
		link: `${getHelpCenterURL()}/articles/5955781-all-new-adjustments`,
	},
	agentAdjustments: {
		title: 'Adjustments',
		link: `${getHelpCenterURL()}/articles/5979682-adjustments-for-agents`,
	},
	'base-tax': {
		title: 'Learn more about Taxes and Fees',
		link: `${getHelpCenterURL()}/articles/1553992-prism-s-sales-tax-calculation`,
	},
	breakeven: {
		title: 'How is break even calculated?',
		link: `${getHelpCenterURL()}/prism-guides/breakeven`,
	},
	connectedShowsImport: {
		title: 'Connected Show Automated Import',
		link: `${getHelpCenterURL()}/articles/6237165-connected-show-limited-beta-general-faqs`,
	},
	contacts: {
		title: 'What are contacts and contact roles?',
		link: `${getHelpCenterURL()}/articles/4778215-contacts-and-contact-roles#h_48ecdcfdf9`,
	},
	coproBonusTerms: {
		title: 'What is a Per-ticket bonus?',
		link: `${getHelpCenterURL()}/articles/6448836-co-pro`,
	},
	coproEstimatedEarnings: {
		title: 'In this table view, a co-promoter’s profit displays as a positive number, whereas on your internal settlement a co-promoter’s profit will be displayed as a negative. See the help doc for more information',
		link: `${getHelpCenterURL()}/articles/6448836-co-pro`,
	},
	coproShareOfProfitLoss: {
		title: 'What is the Co-promoter share of profit/loss?',
		link: `${getHelpCenterURL()}/articles/6448836-co-pro`,
	},
	dealTrackingAgents: {
		title: 'Deal Tracking',
		link: `${getHelpCenterURL()}/articles/6134086-agency-contract-tracking`,
	},
	dealTrackingPromoters: {
		title: 'Deal Tracking',
		link: `${getHelpCenterURL()}/articles/6134084-deal-tracking-for-promoters`,
	},
	glossary: {
		title: 'Glossary',
		link: `${getHelpCenterURL()}/articles/3918410-glossary-offer-calculations-figures`,
	},
	mailMerge: {
		title: 'What is Mail Merge?',
		link: `${getHelpCenterURL()}/articles/6724238-mail-merge`,
	},
	organizationSettings: {
		title: 'Organization Settings',
		link: `${getHelpCenterURL()}/articles/5246791-organization-settings`,
	},
	payments: {
		title: 'What are "Payments"?',
		link: `${getHelpCenterURL()}/articles/4685207-payments-deposits`,
	},
	platformRebate: {
		title: 'Platform Rebates',
		link: `${getHelpCenterURL()}/articles/5554775-platform-rebates`,
	},
	'presettlement-help': {
		title: 'Knowledge Base - Pre-settlement Ticketing Fees',
		link: `${getHelpCenterURL()}/articles/4529720-pre-settlement-ticketing-fees`,
	},
	royalty: {
		title: 'What is a Royalty?',
		link: `${getHelpCenterURL()}/articles/8498583-presenting-broadway-in-prism#h_53f82e30ce`,
	},
	tours: {
		title: 'Tours',
		link: `${getHelpCenterURL()}/articles/4047698-prism-tours`,
	},
	withholding: {
		title: 'What is Withholding?',
		link: `${getHelpCenterURL()}/articles/5520220-withholdings`,
	},
	knowledgeBase: {
		title: 'Knowledge Base',
		link: `${getHelpCenterURL()}/?utm_source=prism-nav`,
	},
	dealConflicts: {
		title: 'Deal Conflicts',
		link: `${getHelpCenterURL()}/articles/2142931-deal-conflicts`,
	},
	ticketingIntegrations: {
		title: 'Ticketing Integrations',
		link: `${getHelpCenterURL()}/articles/2586795-ticketing-integrations`,
	},
};

export function getHelpArticleLink(key: PrismHelpArticleKey): string {
	if (!(key in helpLinks)) {
		throw new Error(`invalid help article key: ${key}`);
	}
	return helpLinks[key].link;
}

function getHelpCenterURL(): string {
	const helpCenterURL: string = environment.help_center_url;
	if (helpCenterURL.endsWith('/')) {
		throw new Error('Help Center URL should not end with a slash');
	}
	return helpCenterURL;
}
