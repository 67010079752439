import { Injectable } from '@angular/core';
import { VariableCost } from '@prism-frontend/entities/variable-costs/variable-cost-typedefs';
import { ApiService } from '@prism-frontend/services/legacy/api.service';
import { PermissionsService } from '@prism-frontend/services/legacy/api/permissions.service';
import { SnackbarService } from '@prism-frontend/services/ui/snackbar.service';
import { OrganizationTypeGateService } from '@prism-frontend/services/utils/organization-type-gate.service';
import { Cost, CostInterface, CostOption, CreateCostInterface } from '@prism-frontend/typedefs/cost';
import { CostGroup, CostGroupInterface, CreateCostGroupInterface } from '@prism-frontend/typedefs/costGroup';
import { BroadwayCostGroupTypeOptions, CostGroupTypeOptions } from '@prism-frontend/typedefs/CostGroupTypeOptions';
import { CostGroupCategory } from '@prism-frontend/typedefs/enums/CostGroupCategory';
import { PrismEvent } from '@prism-frontend/typedefs/event';
import { Permission } from '@prism-frontend/typedefs/permission';
import { SpendInterface } from '@prism-frontend/typedefs/spend';
import { plainToInstance } from 'class-transformer';
import _ from 'lodash';
import { SegmentService } from 'ngx-segment-analytics';

@Injectable({
	providedIn: 'root',
})
export class CostService {
	public constructor(
		public permissionsService: PermissionsService,
		private apiService: ApiService,
		private organizationTypeGateService: OrganizationTypeGateService,
		public snackBarService: SnackbarService,
		public segment: SegmentService
	) {}

	/**
	 * Gets the cost category options a user can edit, depending on their permissions.
	 * If no event is provided, it checks permissions in general - not in the context of a specific event.
	 *
	 * @public
	 * @param {PrismEvent} [event] - The event in the context of which to check permissions. If not provided, permissions are checked outside the context of a specific event.
	 * @returns {CostOption[]} Returns an array of cost categories that the user has permission to edit.
	 */
	public getCategoryOptions(event?: PrismEvent): CostOption[] {
		const categoryOptions: CostOption[] = [];
		if (this.userCan('edit-general-budget', event)) {
			categoryOptions.push({
				label: 'General',
				value: CostGroupCategory.General,
			});
		}

		if (this.userCan('edit-production-budget', event)) {
			categoryOptions.push({
				label: 'Production',
				value: CostGroupCategory.Production,
			});
		}

		if (this.userCan('edit-marketing-budget', event)) {
			categoryOptions.push({
				label: 'Marketing',
				value: CostGroupCategory.Marketing,
			});
		}

		if (this.userCan('edit-talent-budget', event)) {
			categoryOptions.push({
				label: 'Talent',
				value: CostGroupCategory.Talent,
			});
		}

		return categoryOptions;
	}

	public getTypeOptions(): CostOption[] {
		if (this.organizationTypeGateService.isBroadway) {
			return BroadwayCostGroupTypeOptions.concat(CostGroupTypeOptions);
		}
		return CostGroupTypeOptions;
	}

	private getCostGroupBaseUrl(entityType: 'events' | 'tours', entityId: number): string {
		return `${entityType}/${entityId}/cost_group`;
	}

	private getCostGroupUrl(costGroup: CreateCostGroupInterface | CostGroupInterface): string {
		if (!costGroup.event_id && !costGroup.tour_id) {
			throw new Error('Cost group must have an event_id or tour_id');
		}

		const entityType: 'events' | 'tours' = costGroup.event_id ? 'events' : 'tours';
		const entityId: number = costGroup.event_id || costGroup.tour_id;
		const groupId: number | null = costGroup['id'] || null;
		return `${this.getCostGroupBaseUrl(entityType, entityId)}${_.isNil(groupId) ? '' : `/${groupId}`}`;
	}

	private getCostItemUrl(cost: CreateCostInterface | CostInterface, costGroup: CostGroupInterface): string {
		const costId: number | null = cost['id'] || null;
		return `${this.getCostGroupUrl(costGroup)}/item${_.isNil(costId) ? '' : `/${costId}`}`;
	}

	public async getCostGroups(entityType: 'events' | 'tours', entityId: number): Promise<CostGroup[]> {
		const costGroups: CostGroup[] = await this.apiService.getP<unknown, CostGroup[]>(
			this.getCostGroupBaseUrl(entityType, entityId)
		);
		return costGroups.map((group: CostGroup): CostGroup => {
			return plainToInstance(CostGroup, group);
		});
	}

	public async createNewCostGroup(costGroupToCreate: CreateCostGroupInterface): Promise<CostGroup> {
		const url: string = this.getCostGroupUrl(costGroupToCreate);
		const response: CostGroupInterface = await this.apiService.postP<unknown, CostGroupInterface>(
			[url],
			costGroupToCreate
		);
		this.segment.track('create-cost-group');
		this.snackBarService.open('Created successfully!');

		const cost: Cost = new Cost({
			cost_group_id: response.id,
		});
		const newCostGroup: CostGroup = new CostGroup({
			...response,
			costs: [await this.createCostItem(cost, response)],
		});

		return newCostGroup;
	}

	public async saveCostGroup(group: CostGroupInterface): Promise<CostGroupInterface> {
		const response: CostGroupInterface = await this.apiService.putP<unknown, CostGroupInterface>(
			[this.getCostGroupUrl(group)],
			group
		);
		this.segment.track('save-cost-group');
		this.snackBarService.open('Saved successfully!');

		return response;
	}

	public async deleteCostGroup(group: CostGroupInterface): Promise<void> {
		await this.apiService.deleteP([this.getCostGroupUrl(group)]);
		this.segment.track('delete-cost-group');
		this.snackBarService.open('Deleted successfully!');
	}

	public async createCostItem(cost: CreateCostInterface, costGroup: CostGroupInterface): Promise<Cost> {
		const response: Cost = await this.apiService.postP<unknown, Cost>([this.getCostItemUrl(cost, costGroup)], cost);
		this.segment.track(`create-cost-item`);
		return new Cost({
			...response,
		});
	}

	public async editCostItem(cost: Cost, costGroup: CostGroupInterface): Promise<Cost> {
		const response: Cost = await this.apiService.putP<unknown, Cost>([this.getCostItemUrl(cost, costGroup)], cost);
		this.segment.track(`update-cost-item`);
		this.snackBarService.openUnique('editCosts', 'Saved successfully!');
		return new Cost({
			...response,
		});
	}

	public async deleteCostGroupItem(cost: Cost, costGroup: CostGroupInterface): Promise<void> {
		await this.apiService.deleteP<unknown>([this.getCostItemUrl(cost, costGroup)]);
		this.snackBarService.open('Deleted successfully!');
		this.segment.track('delete-cost-group-item');
	}

	public saveCostItemSpend(eventId: number, groupId: number, costId: number, spend: SpendInterface): void {
		const payload: { spend: SpendInterface } = {
			spend: spend,
		};
		this.apiService
			.postS<unknown, SpendInterface & { new_id: number }>(
				[this.apiService.ep.EVENTS_COSTS_SAVE_CI_SPEND, eventId, groupId, costId],
				payload
			)
			.subscribe((response: SpendInterface & { new_id: number }): void => {
				if (response.new_id) {
					spend.id = response.new_id;
				}
				this.segment.track('save-cost-item-spend');
				this.snackBarService.open('Saved successfully!');
			});
	}

	public deleteCostItemSpend(eventId: number, groupId: number, costId: number, spendId: number): void {
		this.apiService
			.postS<unknown, { status?: string }>(
				[this.apiService.ep.EVENTS_COSTS_DELETE_CI_SPEND, eventId, groupId, costId, spendId],
				{}
			)
			.subscribe((response: { status?: string }): void => {
				if (response.status !== 'ok') {
					return;
				}
				this.segment.track('delete-cost-item-spend');
				this.snackBarService.open('Deleted successfully!');
			});
	}

	public async saveVariableCost(eventId: number, item: VariableCost): Promise<VariableCost> {
		const payload: { variable_cost: VariableCost } = {
			variable_cost: item,
		};
		const response: VariableCost = await this.apiService.postP<{ variable_cost: VariableCost }, VariableCost>(
			[this.apiService.ep.EVENTS_COSTS_SAVE_VC, eventId],
			payload
		);

		this.segment.track('save-variable-cost');
		this.snackBarService.openUnique('editCosts', 'Saved successfully!');

		return response;
	}

	public deleteVariableCost(eventId: number, vcId: number): Promise<void> {
		this.apiService
			.postP<unknown, { status?: string }>([this.apiService.ep.EVENTS_COSTS_DELETE_VC, eventId, vcId], {})
			.then((): void => {
				this.segment.track('delete-variable-cost');
				this.snackBarService.open('Deleted successfully!');
			});

		return Promise.resolve();
	}

	/**
	 * Checks if a user has a specific permission. If an event is provided,
	 * it checks the permission in the context of that specific event.
	 *
	 * @private
	 * @param {Permission} permission - The permission to be checked.
	 * @param {PrismEvent} [event] - The event in the context of which to check the permission.
	 * @returns {boolean} Returns `true` if the user has the specified permission, otherwise returns `false`.
	 */
	private userCan(permission: Permission, event?: PrismEvent): boolean {
		if (event) {
			return this.permissionsService.userCanOnEvent(permission, event);
		}

		return this.permissionsService.userCan(permission);
	}
}
