import { castToNumber } from '@prism-frontend/utils/transformers/castToNumber';
import { Transform } from 'class-transformer';
import { IsNumber } from 'class-validator';

export class SpendInterface {
	public id?: number;
	public name: string;

	@IsNumber()
	@Transform(castToNumber())
	public cost: number;

	public date: string;
	public notes: string;
}
