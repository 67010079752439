import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonRowLayoutOptions } from '@prism-frontend/components/dialog-button-row/dialog-button-row.component';

/**
 * The global height and width of this component are specified in the style.scss file
 *  under the .confirm-dialog-container .mat-dialog-container entry
 */

export interface ConfirmDialogData {
	heading: string;
	message: string;
	// Optional button text overrides
	hideCancelButton?: boolean;
	cancelButtonCaption?: string;
	confirmButtonCaption?: string;
	extraClassesConfirm?: string;
	showMessageAsHtml?: boolean;
}

@Component({
	selector: 'confirm-dialog',
	templateUrl: 'confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
	public constructor(
		private dialogRef: MatDialogRef<ConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
	) {}

	public ngOnInit(): void {
		// This is to override the default dialog container styling
		this.dialogRef.addPanelClass('confirm-dialog-container');
	}

	public get showCancelButton(): boolean {
		return !this.data.hideCancelButton;
	}

	public get cancelButtonTitle(): string {
		return this.data.cancelButtonCaption ? this.data.cancelButtonCaption : 'Cancel';
	}

	public get confirmButtonTitle(): string {
		return this.data.confirmButtonCaption ? this.data.confirmButtonCaption : 'Confirm';
	}

	public get extraClassesConfirm(): string {
		return this.data.extraClassesConfirm ? ` ${this.data.extraClassesConfirm}` : '';
	}

	public get buttonLayout(): ButtonRowLayoutOptions {
		return this.data.hideCancelButton ? 'confirm-only' : 'confirm-and-cancel';
	}

	public onNoClick(): void {
		this.dialogRef.close(false);
	}

	public onYesClick(): void {
		this.dialogRef.close(true);
	}
}
