import {
	CustomFieldBackend,
	customFieldKey,
	CUSTOM_FIELD_TYPE_LABELS,
	flattenCustomFieldType,
} from '@prism-frontend/entities/custom-fields/custom-fields-typedefs';
import { InterpolatedDataOption } from '@prism-frontend/entities/email-templates/data-interpolation/InterpolatedDataOption';
import { resolvePermissions } from '@prism-frontend/typedefs/ems/ems-field-explainer-helpers';
import { SurfacedEMSPropConfig } from '@prism-frontend/typedefs/ems/EMSPropSpecifier';

export function mapSurfacedEMSPropConfigToInterpolatedDataOption(
	emsPropConfig: SurfacedEMSPropConfig
): InterpolatedDataOption {
	return {
		label: emsPropConfig.label,
		description: emsPropConfig.description,
		identifier: `context.ems.${emsPropConfig.costCalc}.${emsPropConfig.fieldMetadata.emsPublicIdentifier}`,
		category: emsPropConfig.category,
		permissions: resolvePermissions(emsPropConfig.fieldMetadata, emsPropConfig.costCalc, []),
	};
}

export function mapSurfacedCustomFieldPropConfigToInterpolatedDataOption(
	customField: CustomFieldBackend
): InterpolatedDataOption {
	return {
		label: `Custom Field - ${customField.name}`,
		description: `(${CUSTOM_FIELD_TYPE_LABELS[flattenCustomFieldType(customField)]}) - ${customField.description}`,
		identifier: `context.customField.${customFieldKey(customField)}`,
		category: 'Custom Fields',
		permissions: [],
	};
}
