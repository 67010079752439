import { ContactRolePropsFragment } from '@prism-frontend/typedefs/graphql/ContactRole';
import { TalentFragment } from '@prism-frontend/typedefs/graphql/TalentFragment';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

/**
 * Having this fragment in its own file is required since this
 * is being used in other fragments that causes compiling issues
 * of type 'Cannot access XFragment before initialization'.
 * Moving this into an independant file any other graph ql fragment
 * can import solves this problem.
 */

const BaseArtistFragment: DocumentNode = gql`
	fragment BaseArtistFragment on TalentAgent {
		id
		notes
		manager_contact_id
		contract_signer_id
		talent_id
		is_archived
		talent {
			...CoreTalent
		}
	}
	${TalentFragment}
`;

/**
 * CoreContactPropsFragment represents the top-level properties of a contact object,
 * like name, email, and roles. it does not include more complex relationships (specifically artists)
 * since we do not always want to load artists in every graphql query
 */
export const CoreContactPropsFragment: DocumentNode = gql`
	fragment CoreContactPropsFragment on Contact {
		address
		address_1_long
		address_1_short
		address_2_long
		address_2_short
		city_long
		city_short
		company
		contact_roles {
			...ContactRoleProps
		}
		country_long
		country_short
		created_at
		email
		google_place_id
		id
		name
		notes
		phone
		state_long
		state_short
		location_migration_failure
		updated_at
		zip
	}
	${ContactRolePropsFragment}
`;

/**
 * ContactPropsWithArtistsFragment is the contact Fragment to use if your graphql query requires
 * that the contact be pulled in with all of the related artists as well
 *
 * this typically happens in a place where you can edit contacts - like the contact book page, artist contact page,
 * or the event page
 */
export const ContactPropsWithArtistsFragment: DocumentNode = gql`
	fragment ContactPropsWithArtistsFragment on Contact {
		...CoreContactPropsFragment
		talent_agents {
			...BaseArtistFragment
		}
	}
	${BaseArtistFragment}
	${CoreContactPropsFragment}
`;
