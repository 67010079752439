import { allCostGroupCategories, CostGroupCategoryType } from '@prism-frontend/typedefs/enums/CostGroupCategory';

export type Permission =
	| 'bulk-email'
	| 'clear-holds'
	| 'confirm-event'
	| 'create-holds'
	| 'create-venue'
	| 'delete-event'
	| 'edit-actuals'
	| 'edit-advance'
	| 'edit-contacts'
	| 'edit-contactbook'
	| 'edit-ros'
	| 'edit-roster'
	| 'edit-talent'
	| 'edit-templates'
	| 'edit-tickets'
	| 'edit-variable-costs'
	| 'finalize-settlement'
	| 'initiate-settlement'
	| 'manage-adjustments'
	| 'manage-holds'
	| 'update-venue'
	| 'view-actuals'
	| 'view-advance'
	| 'view-budget-summary'
	| 'view-confirmed'
	| 'view-contacts'
	| 'view-contactbook'
	| 'view-external-settlement-final'
	| 'view-external-settlement-in-progress'
	| 'view-holds'
	| 'view-internal-settlement-final'
	| 'view-internal-settlement-in-progress'
	| 'view-past-event'
	| 'view-reporting'
	| 'view-ros'
	| 'view-roster'
	| 'view-talent'
	| 'view-talent-financials'
	| 'view-templates'
	| 'view-ticket-rebate'
	| 'view-tickets'
	| 'view-tour-index'
	| 'view-variable-costs'
	| 'view-addl-revenue'
	| 'edit-addl-revenue'
	| 'settle-artist-balance'
	| 'edit-rental-deal'
	| 'manage-email-notifications'
	| 'view-tours-internal-settlement'
	| 'view-insights'
	| CostPermissions;

export type CostPermissions = `${'view' | 'edit'}-${CostGroupCategoryType}-budget`;

export function isCostPermission(permissions: Permission | Permission[]): permissions is CostPermissions {
	if (!Array.isArray(permissions)) {
		return checkCostPermission(permissions);
	}
	return (permissions as Permission[]).reduce((userCan: boolean, permission: Permission): boolean => {
		return userCan && checkCostPermission(permission);
	}, true);
}

function checkCostPermission(permission: Permission): boolean {
	return new RegExp(`^(view|edit)-(${allCostGroupCategories.join('|')})+-budget`).test(permission);
}

// this exists to programatically generate ALL_PERMISSIONS for ease of iteration
// (you cannot iterate over typescript string types at runtime)
const allPermissionObject: { [key in Permission]: true } = {
	'bulk-email': true,
	'clear-holds': true,
	'confirm-event': true,
	'create-holds': true,
	'create-venue': true,
	'delete-event': true,
	'edit-actuals': true,
	'edit-advance': true,
	'edit-contacts': true,
	'edit-contactbook': true,
	'edit-general-budget': true,
	'edit-marketing-budget': true,
	'edit-production-budget': true,
	'edit-ros': true,
	'edit-roster': true,
	'edit-talent': true,
	'edit-talent-budget': true,
	'edit-templates': true,
	'edit-tickets': true,
	'edit-variable-costs': true,
	'finalize-settlement': true,
	'initiate-settlement': true,
	'manage-adjustments': true,
	'manage-holds': true,
	'update-venue': true,
	'view-actuals': true,
	'view-advance': true,
	'view-budget-summary': true,
	'view-confirmed': true,
	'view-contacts': true,
	'view-contactbook': true,
	'view-external-settlement-final': true,
	'view-external-settlement-in-progress': true,
	'view-general-budget': true,
	'view-holds': true,
	'view-internal-settlement-final': true,
	'view-internal-settlement-in-progress': true,
	'view-marketing-budget': true,
	'view-past-event': true,
	'view-production-budget': true,
	'view-reporting': true,
	'view-ros': true,
	'view-roster': true,
	'view-talent': true,
	'view-talent-budget': true,
	'view-talent-financials': true,
	'view-templates': true,
	'view-ticket-rebate': true,
	'view-tickets': true,
	'view-tour-index': true,
	'view-variable-costs': true,
	'view-addl-revenue': true,
	'edit-addl-revenue': true,
	'settle-artist-balance': true,
	'edit-rental-deal': true,
	'manage-email-notifications': true,
	'view-tours-internal-settlement': true,
	'view-insights': true,
};

export const ALL_PERMISSIONS: Permission[] = Object.keys(allPermissionObject) as Permission[];
