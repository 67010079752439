import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn } from '@angular/router';
import { PermissionsService } from '@prism-frontend/services/legacy/api/permissions.service';
import { LoginService } from '@prism-frontend/services/legacy/login.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const canActivatePrismAdmin: CanActivateFn = (): Observable<boolean> => {
	const loginService: LoginService = inject(LoginService);
	const permissionsService: PermissionsService = inject(PermissionsService);
	return loginService.userLoadedStream$.pipe(
		map((value: boolean): boolean => {
			if (value) {
				return permissionsService.isPrismAdmin || loginService.isLoggedInAsUser;
			}
			return false;
		})
	);
};

export const canActivateChildPrismAdmin: CanActivateChildFn = (): Observable<boolean> => {
	const loginService: LoginService = inject(LoginService);
	const permissionsService: PermissionsService = inject(PermissionsService);
	// Send back a status when userdata is finished loading
	return loginService.userLoadedStream$.pipe(
		map((value: boolean): boolean => {
			if (value) {
				// This handles the in between state when
				// a prism admin is switching back to their own
				// account credentials
				if (loginService.hasPrismAdminKey) {
					return true;
				}
				// otherwise this is the default identifier
				return permissionsService.isPrismAdmin;
			}
			return false;
		})
	);
};
