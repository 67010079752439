import { DocumentNode, gql } from '@apollo/client/core';
import { PrismEvent } from '@prism-frontend/typedefs/event';

export const DuplicateEventMutation: DocumentNode = gql`
	mutation DuplicateEvent($id: Int!, $name: String!) {
		duplicateEvent(id: $id, name: $name)
	}
`;

export type DuplicateEventArguments = Pick<PrismEvent, 'id' | 'name'>;

export interface DuplicateConactResponse {
	duplicateEvent: number;
}
