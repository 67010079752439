import { joinArray } from '@prism-frontend/utils/static/join-array';
import { plainToClass } from 'class-transformer';
import { IsBoolean, IsString } from 'class-validator';
import { extend } from 'lodash';

export type AddressOmitProps =
	| 'concatenatedAddressFields'
	| 'addressObject'
	| 'address1Value'
	| 'address2Value'
	| 'cityValue'
	| 'stateValue'
	| 'countryValue'
	| 'cityStateValue'
	| 'cityStateZipCountryValue'
	| 'cityStateShortValue'
	| 'copyHoldsAddress';

export interface AddressInterface {
	location_migration_failure: boolean;
	address_1_long: string;
	address_1_short: string;
	address_2_long: string;
	address_2_short: string;
	city_short: string;
	city_long: string;
	state_short: string;
	state_long: string;
	country_short: string;
	country_long: string;
	google_place_id: string;
	zip: string;
	/**
	 * @deprecated Use concatenatedAddressFields instead.
	 */
	location?: string;
	/**
	 * @deprecated Use concatenatedAddressFields instead.
	 */
	address?: string;
}

export class Address implements AddressInterface {
	@IsBoolean()
	public location_migration_failure: boolean;

	@IsString()
	public address_1_long: string;

	@IsString()
	public address_1_short: string;

	@IsString()
	public address_2_long: string;

	@IsString()
	public address_2_short: string;

	@IsString()
	public city_short: string;

	@IsString()
	public city_long: string;

	@IsString()
	public state_short: string;

	@IsString()
	public state_long: string;

	@IsString()
	public country_short: string;

	@IsString()
	public country_long: string;

	@IsString()
	public google_place_id: string;

	@IsString()
	public zip: string;

	/**
	 * @deprecated
	 */
	@IsString()
	public location?: string;

	/**
	 * @deprecated
	 */
	@IsString()
	public address?: string;

	public constructor(address?: Partial<Address>) {
		return plainToClass(Address, address);
	}

	public get address1Value(): string {
		return this.address_1_long || this.address_1_short;
	}

	public get address2Value(): string {
		return this.address_2_long || this.address_2_short;
	}

	public get cityValue(): string {
		return this.city_long || this.city_short;
	}

	public get stateValue(): string {
		return this.state_long || this.state_short;
	}

	public get countryValue(): string {
		return this.country_long || this.country_short;
	}

	public get cityStateValue(): string {
		return joinArray([this.cityValue, this.stateValue], ', ');
	}

	public get cityStateShortValue(): string {
		const stateShort: string = this.state_short || this.state_long;
		return joinArray([this.cityValue, stateShort], ', ');
	}

	public get cityStateZipCountryValue(): string {
		return joinArray([this.cityStateValue, this.zip, this.countryValue], ' ');
	}

	/**
	 * either location or address (its location on venues, address on contacts)
	 * stores either the old address value when location_migration_failure = true
	 * OR the concatenated new fields when location_migration_failure = false
	 */
	public get concatenatedAddressFields(): string {
		if (this.location_migration_failure) {
			return this.address || this.location || this.address_1_long || '';
		}

		return joinArray([this.address1Value, this.address2Value, this.cityStateZipCountryValue], '\n');
	}

	public get copyHoldsAddress(): string {
		const includesCityAndState: boolean = !!this.cityValue && !!this.stateValue;
		return includesCityAndState ? this.cityStateShortValue : this.concatenatedAddressFields;
	}

	public get addressObject(): Address {
		const address: AddressInterface = {
			address: this.concatenatedAddressFields,
			address_1_long:
				this.address_1_long || (this.location_migration_failure ? this.concatenatedAddressFields : ''),
			address_1_short: this.address_1_short,
			address_2_long: this.address_2_long,
			address_2_short: this.address_2_short,
			city_long: this.city_long,
			city_short: this.city_short,
			country_long: this.country_long,
			country_short: this.country_short,
			google_place_id: this.google_place_id,
			location: this.concatenatedAddressFields,
			state_long: this.state_long,
			state_short: this.state_short,
			zip: this.zip,
			location_migration_failure: this.location_migration_failure,
		};
		return new Address(address);
	}

	public set addressObject(address: Address) {
		extend(this, address);
	}
}
