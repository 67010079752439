import { DocumentNode, gql } from '@apollo/client/core';
import { CityStateFilter } from '@prism-frontend/components/event-filters/event-filters-components/venue-city/venue-city.component';
import { EventCustomFieldPropsFragment } from '@prism-frontend/entities/custom-fields/custom-fields-graphql/CustomFieldsQuery';
import { Currency } from '@prism-frontend/typedefs/enums/currency';
import { DealStatus } from '@prism-frontend/typedefs/enums/DealStatus';
import { EventStatus } from '@prism-frontend/typedefs/enums/event-status';
import { EventSortOrderChoices } from '@prism-frontend/typedefs/enums/EventSortOrderChoices';
import { PaymentStatusBackend } from '@prism-frontend/typedefs/enums/PaymentStatus';
import { PrismEvent } from '@prism-frontend/typedefs/event';
import { CoreContactPropsFragment } from '@prism-frontend/typedefs/graphql/CoreContactFragment';
import { FullDepositFragment } from '@prism-frontend/typedefs/graphql/Deposit';
import { FlatTicketRevenuePropsFragment } from '@prism-frontend/typedefs/graphql/FlatTicketRevenue';
import {
	fetchPaginationProps,
	PaginationDetails,
	PaginationProps,
} from '@prism-frontend/typedefs/graphql/PaginationDetails';
import { PartnerDealPropsFragment } from '@prism-frontend/typedefs/graphql/PartnerDealQuery';
import { FullSettlementFragment } from '@prism-frontend/typedefs/graphql/Settlement';
import { StageProps } from '@prism-frontend/typedefs/graphql/StageProps';
import {
	CoreEventTalentFragment,
	FullEventTalentFragment,
	RunOfShowFragment,
} from '@prism-frontend/typedefs/graphql/TalentData';
import { CoreTourFragment } from '@prism-frontend/typedefs/graphql/Tour';
import { VenueProps } from '@prism-frontend/typedefs/graphql/VenueQuery';

// Fragments docs: https://www.apollographql.com/docs/react/data/fragments/

// TODO PRSM-4164 make this into a graphql fragement
const partnershipQueryString: string = `{
	id
	venue_organization_id
	promoter_organization {
		name
	}
	promoter_organization_id
	venue_venue {
		name
	}
	venue_venue_id
	promoter_venue_id
	enabled
	created_at
	venue_user_id
	promoter_user_id
	highest_allowable_hold_level
}`;

// TODO PRSM-4164 make this into a graphql fragement
const eventLinkQueryString: string = `{
	id
	partnership_id
	promoter_event_id
	venue_event_id
	created_at
	updated_at
	partnership ${partnershipQueryString}
}`;

/**
 * Core Event things:
 * 		1) top level information
 */
const CoreEventFragment: DocumentNode = gql`
fragment CoreEvent on Event {
	id
	name
	actual_attendance
	age_limit
	has_age_limit
	is_all_ages
	estimated_attendance
	actual_additional_support
	budgeted_additional_support
	currency
	all_day
	calculated_start_date
	created_by
	convert_to_usd
	imported
	settled_exchange_rate
	offer_exchange_rate
	usd_exchange_rate
	use_custom_exchange_rates
	confirmed_by_user_id
	platform_active_sales
	organization_id
	end_time
	tax_rate
	tax_type
	last_applied_template_id
	timezone
	venue_id
	is_shared
	is_promoter_shared_event
	is_venue_shared_event
	is_event_owned_by_agency
	genre_strings
	# TODO PRSM-6028 Change outside_promoter to is_rental
	outside_promoter
	promoter_profit
	facility_fee
	confirmed
	on_sale_date
	pre_sale_date
	announce_date
	do_not_announce
	confirmed_time
	updated_at
	created_at
	archived_at
	display_updated_at
	permissions
	platform_images {
		image
		thumbnail
		image_style
	}
	platform_type
	promoter_data {
		id
		event_id
		# TODO PRSM-6192 Change promoter_contact_id to renter_contact_id
		promoter_contact_id
		bar_minimum
		bonus_per_ticket
		deposits {
			...FullDepositFragment
		}
		in_house_tickets
		manual_room_fee
		# TODO PRSM-6192 Change promoter_percentage to renter_percentage
		promoter_percentage
		room_fee
		offer_terms
		payout_adjustments {
			id
			event_promoter_id
			name
			amount
			notes
		}
		settlement {
			...FullSettlementFragment
		}
		promoter {
			...CoreContactPropsFragment
		}
	}
	flat_ticket_revenues {
		...FlatTicketRevenueProps
	}
	tickets {
		id
		event_id
		name
		ticket_price
		sold
		est_sold
		platform_id
		platform_total
		platform_tax_paid
		platform_rebate
		allotment
		comps
		kills
		order
		updated_at
		created_at
	}
	tour {
		...CoreTourFragment
	}
	tour_id
	data {
		chosen_hold_id
		logo_id
		mad_stage_ids
	}
	datez {
		date
		id
		event_id
		hold_id
		stage_id
		date
		all_day
		start_time
		end_time
		deleted_at
	}
	event_fees {
		id
		name
		type
		amount
		ticket_id
		event_id
		updated_at
		created_at
		estimated_fee
		potential_fee
		actual_fee
	}
	additional_revenue {
		id
		actual_ticket_id
		actual_type
		amount
		est_amount
		est_ticket_id
		est_type
		event_id
		name
		order
		include_in_copro_split
		include_in_net_gross
	}
	variable_costs {
		id
		name
		terms
		amount
		reported
		ticket_id
		event_id
		order
		updated_at
		created_at
	}
	cost_groups {
		id
		event_id
		type
		category
		updated_at
		created_at
		costs {
			id
			actual_cost
			additional
			budget
			cost_group_id
			estimated_cost
			name
			reported
			reported_cost
			quantity
			order
			updated_at
			created_at
			spends {
				name
				cost
				date
				notes
				id
				cost_item_id
				updated_at
				created_at
			}
		}
	}
	text_status {
		text
		short_text
		code
	}
	venue {
		...VenueProps
	}
	stages {
		...StageProps
	}
	advance_data {
		additionalAdvanceText
		request_debug_id
		advanceEventDetails {
			fbEventURL
			ticketingURL
			staffing
		}
	}
	run_of_show {
		...RunOfShowFragment
	}
	venue_event_link ${eventLinkQueryString}
	promoter_event_link ${eventLinkQueryString}
	custom_fields {
		...CustomFieldPropsEvent
	}
	created_by_user {
		id
		email
		name
	}
	partner_deals {
		...PartnerDealProps
	}
	contacts {
		...CoreContactPropsFragment
		include_in_pdfs
		event_notes
	}

}
${VenueProps}
${StageProps}
${RunOfShowFragment}
${FullDepositFragment}
${FullSettlementFragment}
${RunOfShowFragment}
${CoreContactPropsFragment}
${CoreTourFragment}
${PartnerDealPropsFragment}
${FlatTicketRevenuePropsFragment}
`;

const coreEventQueryString: string = `
	...CoreEvent
	talent_data {
		...CoreEventTalent
	}
`;

const CoreEventQueryFragments: DocumentNode = gql`
	${CoreEventFragment}
	${CoreEventTalentFragment}
	${EventCustomFieldPropsFragment}
`;

// TODO PRSM-4164 make this into a graphql fragement
const fullEventQueryString: string = `{
	...CoreEvent
	talent_data {
		...FullEventTalent
	}
}`;

const fullEventQueryFragments: DocumentNode = gql`
	${CoreEventQueryFragments}
	${FullEventTalentFragment}
	${CoreContactPropsFragment}
`;

export const EventsByIDQuery: DocumentNode = gql`
	query Events(
		$id: Int
	) {
		events(
			id: $id
		) ${fullEventQueryString}
	}
	${fullEventQueryFragments}
`;

const eventsQueryParameters: string = `
$bypassCache: Boolean
$dates__date__gte: String
$dates__date__lte: String
$created_at_gte: String
$created_at_lte: String
$name: String
$stages: [Int]
$places: [CityStateInput]
$states: [String]
$artists: [String]
$talent_ids: [Int]
$talent_agent_ids: [Int]
$manager_ids: [Int]
$agent_ids: [Int]
$companies: [String]
$contacts: [String]
$event_status: [Int]
$deal_status: [String]
$payment_status: [String]
$payment_types: [String]
$genres: [Int]
$currencies: [String]
$isOutsidePromoter: [Int]
$has_unsettled_deal: Boolean
$event_owner: [Int]
$include_mad: Boolean
$only_mad: Boolean
$include_archived_events: Boolean
$contract_due_at_gte: String
$contract_due_at_lte: String
$orderBy: String
$sortDirection: String`;

// TODO PRSM-7243 add created_at_gte && lte once BE PR is ready
const eventsQueryArguments: string = `
bypassCache: $bypassCache
dates__date__gte: $dates__date__gte
dates__date__lte: $dates__date__lte
created_at_gte: $created_at_gte
created_at_lte: $created_at_lte
name: $name
stages: $stages
places: $places
states: $states
artists: $artists
talent_ids: $talent_ids
talent_agent_ids: $talent_agent_ids
manager_ids: $manager_ids
agent_ids: $agent_ids
companies: $companies
contacts: $contacts
event_status: $event_status
deal_status: $deal_status
payment_status: $payment_status
payment_types: $payment_types
genres: $genres
currencies: $currencies
# TODO PRSM-6028 Change isOutsidePromoter to isRental
isOutsidePromoter: $isOutsidePromoter
has_unsettled_deal: $has_unsettled_deal
event_owner: $event_owner
include_mad: $include_mad
only_mad: $only_mad
include_archived_events: $include_archived_events
contract_due_at_gte: $contract_due_at_gte
contract_due_at_lte: $contract_due_at_lte
orderBy: $orderBy
sortDirection: $sortDirection`;

const paginationProps: PaginationProps = fetchPaginationProps();

export const EmsRollupsBatchIdQuery: DocumentNode = gql`
	query EmsRollupsQuery(
		${eventsQueryParameters}
	) {
		emsList(
			${eventsQueryArguments}
		){
			batchId
			cacheGeneratedAt
		}
	}
`;

export const PaginatedEventQuery: DocumentNode = gql`
	query PaginatedEventCount(
		${eventsQueryParameters}
		${paginationProps.queryParams}
	) {
		eventList(
			${eventsQueryArguments}
			${paginationProps.queryArguments}
		)  {
			data {
				${coreEventQueryString}
			}
			${paginationProps.responseProps}
		}
	}
	${CoreEventQueryFragments}
`;

export const SimpleEventQuery: DocumentNode = gql`
	query SimpleEventQuery(
		$name: String!
		${paginationProps.queryParams}
		$orderBy: String!
		$sortDirection: String!
	) {
		eventList(
			name: $name
			${paginationProps.queryArguments}
			orderBy: $orderBy
			sortDirection: $sortDirection
		) {
			data {
				id
				name
				confirmed
				datez {
					date
					id
					event_id
					hold_id
					stage_id
					date
					all_day
					start_time
					end_time
					deleted_at
				}
				venue_id
			}
			${paginationProps.responseProps}
		}
	}
`;

export interface EventsQueryResult {
	events: Array<PrismEvent>;
}

export interface EventsQueryVariables {
	dates__date__gte: string;
	dates__date__lte: string;
	created_at_gte: string;
	created_at_lte: string;
	event_status: EventStatus[];
	deal_status: DealStatus[];
	payment_status: PaymentStatusBackend[];
	payment_types: string[];
	name: string;
	stages: number[];
	places: CityStateFilter[];
	states: string[];
	event_owner: number[];
	artists: string[];
	talent_ids: number[];
	talent_agent_ids: number[];
	manager_ids: number[];
	agent_ids: number[];
	companies: string[];
	contacts: string[];
	genres: number[];
	currencies: Currency[];
	include_mad: boolean;
	only_mad: boolean;
	// TODO PRSM-6028 Change isOutsidePromoter to isRental
	isOutsidePromoter: number[];
	include_archived_events: boolean;
	contract_due_at_gte: string;
	contract_due_at_lte: string;
	/**
	 * This is an optional param. When true, returns only events that contain
	 * at least one unsettled deal. When false, contains all events that contain
	 * unsettled deals. If omitted, retuirns all events, regardless
	 * of the event's deal's fully settled status.
	 */
	has_unsettled_deal?: boolean;
	/**
	 * The field used to order the events. Currently supports:
	 * EventDate, LastUpdated, AnnounceDate or OnSaleDate
	 */
	orderBy?: string;
	/**
	 * The direction to sort the orderBy field on events.
	 * Either 'asc' or 'desc'
	 */
	sortDirection?: EventSortOrderChoices;
}

export interface PaginatedEventsQueryVariables extends EventsQueryVariables {
	page: number;
	limit: number;
}

export interface EventsByIDQueryVariables {
	id: number;
}

export interface PaginatedEventQueryResult {
	eventList: {
		data: PrismEvent[];
	} & PaginationDetails;
}
