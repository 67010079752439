import { NgModule } from '@angular/core';
import { PrismBadgeComponent } from '@prism-frontend/components/prism-badge/prism-badge.component';

@NgModule({
	imports: [],
	exports: [PrismBadgeComponent],
	declarations: [PrismBadgeComponent],
	providers: [],
})
export class PrismBadgeModule {}
