import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventGraphqlService } from '@prism-frontend/services/api/graphql/event-graphql.service';
import { VenueService } from '@prism-frontend/services/api/list-services/venue.service';
import { PromiseQueueService } from '@prism-frontend/services/utils/promise-queue.service';
import { eventStatusToString } from '@prism-frontend/typedefs/enums/event-status';
import { PrismEvent } from '@prism-frontend/typedefs/event';
import { PaginatedEventQueryResult } from '@prism-frontend/typedefs/graphql/EventsQuery';
import { Venue } from '@prism-frontend/typedefs/venue';
import { WithAutoUnsubscribeComponent } from '@prism-frontend/utils/static/auto-unsubscribe';
import { getDatePillText } from '@prism-frontend/utils/static/dateHelper';
import { Subject } from 'rxjs';

// TODO PRSM-XXXX permissions for this?
@Component({
	selector: 'event-picker',
	template: `
		<ng-select
			class="js-event-picker-ng-select"
			data-testid="js-jump-event-picker"
			[typeahead]="_searchTerm$"
			[items]="searchTerm ? _eventSearchResults : initialSuggestions"
			[loading]="_loading"
			[(ngModel)]="selectedEvent"
			loadingText="Loading Events"
			(change)="ngSelectChange($event)"
			(scrollToEnd)="searchNextPage()"
		>
			<ng-template ng-label-tmp let-item="item">
				<ng-container *ngTemplateOutlet="eventName; context: { event: item }"></ng-container>
			</ng-template>
			<ng-template ng-option-tmp let-item="item">
				<ng-container *ngTemplateOutlet="eventName; context: { event: item }"></ng-container>
			</ng-template>

			<ng-template #eventName let-event="event">
				<span class="event-row">
					<span class="event-details" data-testid="js-event-details">
						<event-status-icon [event]="event"></event-status-icon>
						<span class="event-name" data-testid="js-event-name">{{ getEventName(event) }} </span>
						<span class="venue-details" data-testid="js-venue-details">
							<span class="event-venue-name" data-testid="js-event-venue-name">{{
								getEventVenueName(event)
							}}</span>
							<span class="event-city-and-state" data-testid="js-event-city-state"
								>{{ getEventCityAndState(event) }}
							</span>
						</span>
					</span>
					<span class="event-holds-and-date" data-testid="js-event-holds-and-date">
						<span class="holds" data-testid="js-event-holds">{{ getEventHolds(event) }}</span>
						<span class="event-date" data-testid="js-event-date">
							<span class="event-date-chip">{{ getEventDateText(event) }}</span>
						</span>
					</span>
				</span>
			</ng-template>
		</ng-select>
	`,
	styleUrls: ['./event-picker.component.scss'],
})
export class EventPickerComponent extends WithAutoUnsubscribeComponent implements OnInit {
	@Input() public uniqueId: string = 'event-picker';

	@Input() public searchLabel: string = 'Search Event';

	@Input() public multiple: boolean = false;

	/**
	 * A list of events to be shown when making focus over the search input
	 * for the user to be able to select an option even without searching for
	 * any event in particular
	 */
	@Input() public initialSuggestions: PrismEvent[] = [];

	@Input() public selectedEvent: PrismEvent | PrismEvent[] | undefined = undefined;

	@Output() public selectedEventChange: EventEmitter<PrismEvent[]> = new EventEmitter<PrismEvent[]>();

	// TODO PRSM-6545 some of these properties can be folded into simple-picker
	public _searchTerm$: Subject<string> = new Subject<string>();
	public searchTerm: string = '';
	public _eventSearchResults: PrismEvent[] = [];
	public _loading: boolean = false;
	private allEventsLoaded: boolean = false;

	private _controller: AbortController;

	public constructor(
		private promiseQueueService: PromiseQueueService<PrismEvent[]>,
		private eventGraphqlService: EventGraphqlService,
		private venueService: VenueService
	) {
		super();
	}

	public ngOnInit(): void {
		// TODO PRSM-6545 this can be folded into simple-picker
		this.addSubscription(
			this._searchTerm$.subscribe((term: string): void => {
				this.searchTerm = term;
				this.searchEvents(true);
			})
		);
	}

	public getEventStatusString(event: PrismEvent): string {
		return eventStatusToString(event.confirmed).toLowerCase();
	}

	public ngSelectChange(eventOrEvents: PrismEvent | PrismEvent[] | undefined): void {
		let events: PrismEvent[] = [];
		if (Array.isArray(eventOrEvents)) {
			events = eventOrEvents;
		} else if (!!eventOrEvents) {
			events = [eventOrEvents];
		}
		this.selectedEventChange.emit(events);
	}

	// TODO PRSM-6545 this can be folded into simple-picker
	public searchNextPage(): void {
		if (this.allEventsLoaded) {
			return;
		}

		this.searchEvents();
	}

	// TODO PRSM-6545 this can be folded into simple-picker
	private searchEvents(triggerNewSearch: boolean = false): void {
		// if we're triggering a new search, reset some data
		if (triggerNewSearch) {
			this._eventSearchResults = [];
			this.allEventsLoaded = false;
		}

		// if we have a gql request in progress, cancel it
		if (this._controller) {
			this._controller.abort();
			this._controller = undefined;
		}

		this._controller = window.AbortController ? new AbortController() : undefined;

		this._loading = !!this.searchTerm;

		// push a new event-search into the promise queue
		this.promiseQueueService.push(this.uniqueId, 'event-search', async (): Promise<PrismEvent[]> => {
			// dont hit the API if the new search term is empty
			if (!this.searchTerm) {
				return Promise.resolve([]);
			}

			this.eventGraphqlService
				.simpleEventSearch(this.searchTerm, this._eventSearchResults.length, this._controller?.signal)
				.then((firstPageResults: PaginatedEventQueryResult): void => {
					this._eventSearchResults = this._eventSearchResults.concat(firstPageResults.eventList.data);
					this.allEventsLoaded = !firstPageResults.eventList.has_more_pages;
				})
				.finally((): void => {
					this._loading = false;
				});
		});
	}

	public getEventName(event: PrismEvent): string {
		return event.name;
	}

	public getEventDateText(event: PrismEvent): string {
		if (event.datez.length === 0) {
			return '(TBD)';
		}
		const eventDates: string = getDatePillText(event.datez, true)[0];
		return eventDates;
	}

	public getEventHolds(event: PrismEvent): string {
		if (event.isConfirmed || event.datez.length === 0) {
			return '';
		}
		const eventHolds: string = getDatePillText(event.datez, event.isConfirmed)[0];
		return eventHolds;
	}

	public getEventVenueName(event: PrismEvent): string {
		if (!event.venue_id) {
			return '(TBD)';
		}
		const venue: Venue = this.venueService.venuesById$.value[event.venue_id];
		return venue.name ? `${venue.name}` : '';
	}

	public getEventCityAndState(event: PrismEvent): string {
		if (!event.venue_id) {
			return '(TBD)';
		}
		const venue: Venue = this.venueService.venuesById$.value[event.venue_id];
		return venue.cityStateShortValue;
	}
}
