<confirm-dialog-container
	class="confirm-container"
	data-testid="js-confirm-dialog-container"
	[buttonTextCancel]="cancelButtonTitle"
	[buttonTextConfirm]="confirmButtonTitle"
	[extraClassesConfirm]="extraClassesConfirm"
	[buttonLayout]="buttonLayout"
	(confirm)="onYesClick()"
	(cancel)="onNoClick()"
>
	<h2 mat-dialog-title dialog-header>{{ data.heading }}</h2>
	<mat-dialog-content class="confirm-message" dialog-body>
		<ng-container *ngIf="!data.showMessageAsHtml; else htmlMessage">
			<span>{{ data.message }}</span>
		</ng-container>
	</mat-dialog-content>
</confirm-dialog-container>

<ng-template #htmlMessage>
	<div [innerHTML]="data.message | safeHtml"></div>
</ng-template>
