import { Injectable } from '@angular/core';
import { VenueGraphqlService } from '@prism-frontend/services/api/graphql/venue-graphql.service';
import { Stage } from '@prism-frontend/typedefs/stage';
import { Venue } from '@prism-frontend/typedefs/venue';
import { WithAutoUnsubscribeService } from '@prism-frontend/utils/static/auto-unsubscribe';
import { filterIrrelevantVenues } from '@prism-frontend/utils/static/filterIrrelevantVenues';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

interface CityStateGroupingProperties {
	groupBy: keyof Venue;
	filterBy: keyof Venue;
	short: keyof Venue;
	long: keyof Venue;
}

interface CityStatePropertiesToGroup {
	city: CityStateGroupingProperties;
	state: CityStateGroupingProperties;
}

const cityStatePropertiesToCheck: CityStatePropertiesToGroup = {
	city: {
		groupBy: 'cityStateShortValue',
		filterBy: 'cityValue',
		short: 'city_short',
		long: 'city_long',
	},
	state: {
		groupBy: 'stateValue',
		filterBy: 'stateValue',
		short: 'state_short',
		long: 'state_long',
	},
};

@Injectable({
	providedIn: 'root',
})
export class VenueService extends WithAutoUnsubscribeService {
	/**
	 * All venues obtained from GraphQl. It includes active and inactive venues, but also venues with no stages.
	 * It is desirable to remove this observable in the future.
	 */
	public allDeprecated$: BehaviorSubject<Venue[]> = new BehaviorSubject<Venue[]>([]);
	/**
	 * All venues (active or inactive) that contains at least one stage (active or inactive).
	 * If a venue has no stages the venue WILL NOT BE included.
	 * If a venue has only inactive stages the venue WILL BE included.
	 */
	public allVenuesWithAtLeastOneStage$: BehaviorSubject<Venue[]> = new BehaviorSubject<Venue[]>([]);
	/**
	 * All active venues with active stages. This list will include only venues that are active AND have active stages.
	 * If a venue is not active it WILL NOT BE included.
	 * If a venue has no stages the venue WILL NOT BE included.
	 * If a venue has only inactive stages the venue WILL NOT BE included.
	 */
	public allActiveVenuesWithAtLeastOneStage$: BehaviorSubject<Venue[]> = new BehaviorSubject<Venue[]>([]);

	public stages$: BehaviorSubject<Stage[]> = new BehaviorSubject<Stage[]>([]);
	public stagesById$: BehaviorSubject<Record<number, Stage>> = new BehaviorSubject<Record<number, Stage>>({});
	public doesOrgHaveAtLeastOneSharedVenue$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public venuesById$: BehaviorSubject<Record<number, Venue>> = new BehaviorSubject<Record<number, Venue>>({});

	public allVenuesStates$: BehaviorSubject<Venue[]> = new BehaviorSubject<Venue[]>([]);
	public allVenuesCities$: BehaviorSubject<Venue[]> = new BehaviorSubject<Venue[]>([]);

	public constructor(private venueGraphQlService: VenueGraphqlService) {
		super();
	}

	public async refreshListFromAPI(): Promise<Venue[]> {
		const venues: Venue[] = await this.venueGraphQlService.fetchVenues();
		this.updateVenuesData(venues);
		return venues;
	}

	public ensureListLoaded(): Promise<Venue[]> {
		if (!this.allDeprecated$.value.length) {
			return this.refreshListFromAPI();
		}
		return Promise.resolve(this.allDeprecated$.value);
	}

	public async createVenue(venueData: Venue): Promise<Venue> {
		const venue: Venue = await this.venueGraphQlService.createVenue(venueData);
		this.updateVenuesData([...this.allDeprecated$.value, venue]);
		this.refreshListFromAPI();
		return venue;
	}

	public async updateVenue(venue: Venue): Promise<Venue> {
		const updatedVenue: Venue = await this.venueGraphQlService.updateVenue(venue);
		this.refreshListFromAPI();
		return updatedVenue;
	}

	private updateVenuesData(venues: Venue[]): void {
		this.allDeprecated$.next(venues);
		this.allActiveVenuesWithAtLeastOneStage$.next(filterIrrelevantVenues(venues, false));
		this.allVenuesWithAtLeastOneStage$.next(filterIrrelevantVenues(venues, true));
		this.stagesById$.next(
			_.chain(venues)
				.map((venue: Venue): Stage[] => {
					return venue.stages;
				})
				.flatten()
				.tap((stages: Stage[]): void => {
					this.stages$.next(stages);
				})
				.map((stage: Stage): [number, Stage] => {
					return [stage.id, stage];
				})
				.fromPairs()
				.value()
		);
		this.venuesById$.next(_.keyBy(venues, 'id'));

		this.doesOrgHaveAtLeastOneSharedVenue$.next(
			this.allActiveVenuesWithAtLeastOneStage$.value.reduce((memo: boolean, venue: Venue): boolean => {
				return memo || venue.can_request_to_host_venue;
			}, false)
		);

		this.updateCityStatesData();
	}

	private updateCityStatesData(): void {
		// PROCESS CITIES
		const _allCities: Venue[] = this.groupByCity(this.allVenuesWithAtLeastOneStage$.value);
		this.allVenuesCities$.next(_allCities);
		// PROCESS STATES
		const _allStates: Venue[] = this.groupByState(this.allVenuesWithAtLeastOneStage$.value);
		this.allVenuesStates$.next(_allStates);
	}

	private groupByCity(data: Venue[]): Venue[] {
		return this.groupVenueBy(data, 'city');
	}

	private groupByState(data: Venue[]): Venue[] {
		return this.groupVenueBy(data, 'state');
	}

	private groupVenueBy(data: Venue[], groupBy: 'city' | 'state'): Venue[] {
		const cityStateProperties: CityStateGroupingProperties = cityStatePropertiesToCheck[groupBy];
		return (
			_.chain(data)
				// filter out empty cities/states
				.filter((cityState: Venue): boolean => {
					return cityState[cityStateProperties.filterBy] && cityState[cityStateProperties.filterBy] !== '';
				})
				// group them by their label to be shown
				.groupBy((cityState: Venue): string => {
					return `${cityState[cityStateProperties.groupBy]}`;
				})
				// from each group find the most complete city/state
				.mapValues((groupedCityStates: Venue[]): Venue => {
					if (groupedCityStates.length > 1) {
						// find one that has both long and short values
						const cityStateLongShort: Venue = groupedCityStates.find((cityState: Venue): boolean => {
							return (
								cityState[cityStateProperties.long] !== '' &&
								cityState[cityStateProperties.short] !== ''
							);
						});
						if (cityStateLongShort) {
							return cityStateLongShort;
						}
						// find one with long value
						const cityStateLong: Venue = groupedCityStates.find((cityState: Venue): boolean => {
							return cityState[cityStateProperties.long] !== '';
						});
						if (cityStateLong) {
							return cityStateLong;
						}
					}
					// default to the first one which should have short value
					return groupedCityStates[0];
				})
				.toArray()
				// sort alphabeticallly for rendering on the UI by their label to be shown
				// either by city or by state name
				.sortBy((cityStates: Venue): string => {
					return cityStates[cityStateProperties.groupBy] as string;
				})
				.value()
		);
	}
}
