import { DocumentNode, gql } from '@apollo/client/core';
import _ from 'lodash';
type GqlTypes = 'String' | 'Float' | 'Int' | 'Boolean' | 'String!' | 'Float!' | 'Int!' | 'Boolean!' | string;

export interface FinalMutationThings {
	createMutation: DocumentNode;
	updateMutation: DocumentNode;
}
interface MutationStrings {
	mutationArgs: string;
	mutationArgsMapping: string;
}

type GenerateMutationsConfig<T> = {
	[key in keyof T]?: GqlTypes;
};

function generateMutationArgsFromObject<T>(config: GenerateMutationsConfig<T>): string {
	return _.chain(Object.keys(config))
		.map((key: keyof T): string => {
			const type: GqlTypes = config[key];
			return `$${String(key)}: ${type}`;
		})
		.join('\n')
		.value();
}

function generateMutationArgsMappingFromObject<T>(config: GenerateMutationsConfig<T>): string {
	return _.chain(Object.keys(config))
		.map((key: keyof T): string => {
			return `${String(key)}: $${String(key)}`;
		})
		.join('\n')
		.value();
}

/**
 * Helps generate graphql query strings and argument mappings out of an object
 * that describes the arguments as keys from the underlying object: GQlTypes
 *
 * Example:
 *
 * Input
 *	location: String
 *	currency: String
 *
 * Output:
 *	mutationArgs:
 *
 *		$location: String
 *		$currency: String
 *
 *	mutationArgsMapping:
 *
 *		location: $location
 *		currency: $currency
 */
export function generateMutations<T>(
	entityName: string,
	entityFragment: DocumentNode,
	config: GenerateMutationsConfig<T>,
	createOnlyConfig: GenerateMutationsConfig<T>
): FinalMutationThings {
	const forCreate: MutationStrings = {
		mutationArgs: generateMutationArgsFromObject({
			...config,
			...createOnlyConfig,
		}),
		mutationArgsMapping: generateMutationArgsMappingFromObject({
			...config,
			...createOnlyConfig,
		}),
	};
	const createMutation: DocumentNode = gql`
mutation create${entityName}(
	${forCreate.mutationArgs}
) {
	create${entityName}(
		${forCreate.mutationArgsMapping}
	) {
		...${entityName}Props
	}
}
${entityFragment}
`;
	const forUpdate: MutationStrings = {
		mutationArgs: generateMutationArgsFromObject({
			id: 'Int!',
			...config,
		}),
		mutationArgsMapping: generateMutationArgsMappingFromObject({
			id: 'Int!',
			...config,
		}),
	};
	const updateMutation: DocumentNode = gql`
mutation update${entityName}(
	${forUpdate.mutationArgs}
) {
	update${entityName}(
		${forUpdate.mutationArgsMapping}
	) {
		...${entityName}Props
	}
}
${entityFragment}
`;
	return {
		createMutation,
		updateMutation,
	};
}
