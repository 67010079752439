import { isMacintosh } from '@prism-frontend/utils/static/isMacintosh';
/**
 * Returns the label to display for cmd or ctrl (hotkeys)
 * @param isMac override. Will default to determining if the platform is a mac
 * 	using winow.navigator props
 */
export function platformMetaKeyLabel(isMac: boolean = isMacintosh()): string {
	let controlKey: string = 'ctrl';
	if (isMac) {
		controlKey = '⌘';
	}
	return controlKey;
}
