import { Injectable } from '@angular/core';

@Injectable()
export class SpinnerService {
	private spinnerRef: SpinnerFlag;
	private requests: number = 0;

	public setAppRef(ref: SpinnerFlag): void {
		this.spinnerRef = ref;
	}

	// Loading screen stuff
	private showSpinner(): void {
		if (this.spinnerRef) {
			this.spinnerRef.flag = false;
		}
	}

	private hideSpinner(): void {
		if (this.spinnerRef) {
			this.spinnerRef.flag = true;
		}
	}

	public addRequest(): void {
		if (this.requests === 0) {
			this.showSpinner();
		}
		this.requests++;
	}

	public removeRequest(): void {
		if (this.requests === 0) {
			return;
		}
		this.requests--;
		if (this.requests <= 0) {
			// set the value to 0 every time in case we drop to negatives
			this.requests = 0;
			this.hideSpinner();
		}
	}
}

export class SpinnerFlag {
	public flag: boolean = true;
}
