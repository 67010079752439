import { Injectable } from '@angular/core';
import { PrismGraphqlService } from '@prism-frontend/services/api/graphql/prism-graphql.service';
import { instantiateApiResponse } from '@prism-frontend/services/legacy/api.service';
import { AgentSuggestion, AllAgentsQuery, AllAgentsQueryResponse } from '@prism-frontend/typedefs/graphql/AgentQuery';
import { User } from '@prism-frontend/typedefs/organization';
import { InstantiatedAPIResponseAndValidationResults } from '@prism-frontend/utils/decorators/validate-children';

@Injectable()
export class AgentGraphqlService {
	public constructor(private prismGraphqlService: PrismGraphqlService) {}

	public fetchAllAgents(): Promise<AgentSuggestion[]> {
		return this.prismGraphqlService
			.query<AllAgentsQueryResponse>({
				query: AllAgentsQuery,
			})
			.then(async (response: AllAgentsQueryResponse): Promise<AgentSuggestion[]> => {
				const results: InstantiatedAPIResponseAndValidationResults<AgentSuggestion[]> =
					await instantiateApiResponse(User, response.users);
				return results.data;
			});
	}
}
