import { CostCalc } from '@prism-frontend/typedefs/enums/calc';
import { CostGroupCategory } from '@prism-frontend/typedefs/enums/CostGroupCategory';
import { CostGroupType } from '@prism-frontend/typedefs/enums/CostGroupType';
import { OrderedModel } from '@prism-frontend/typedefs/enums/OrderedModel';
import { Orderable } from '@prism-frontend/typedefs/orderable';
import { SpendInterface } from '@prism-frontend/typedefs/spend';
import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { castToNumber } from '@prism-frontend/utils/transformers/castToNumber';
import { plainToClass, Transform, Type } from 'class-transformer';
import { IsBoolean, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';
import { isNil } from 'lodash';

export interface CostOption<T = CostGroupType | CostGroupCategory> {
	label: string;
	value: T;
}

export interface CostInterface {
	id?: number;
	order: number;
	name: string;
	quantity: number;
	budget: number;
	cost_group_id: number;
	actual_cost: number;
	estimated_cost: number;
	reported_cost: number;
	notes: string;
	reported: boolean;
	additional: boolean;
	copro_overridden_amount: number;
	copro_cost_hidden: boolean;
}

export type CreateCostInterface = Omit<CostInterface, 'id'>;

export class Cost implements CostInterface, Orderable {
	public readonly modelName: OrderedModel = OrderedModel.CostItem;

	public constructor(cost?: Partial<Cost>) {
		return plainToClass(Cost, cost);
	}

	@IsNumber()
	@IsOptional()
	public id?: number;

	@IsNumber()
	@Transform(castToNumber())
	public order: number;

	@IsString() public name: string = '';

	@IsNumber()
	@Transform(castToNumber())
	public quantity: number = 1;

	@IsNumber()
	@Transform(castToNumber())
	public budget: number = 0;

	@IsNumber()
	@Transform(castToNumber())
	public cost_group_id: number;

	@IsNumber()
	@Transform(castToNumber())
	public actual_cost: number = null;

	@IsNumber()
	@Transform(castToNumber())
	public estimated_cost: number = null;

	@IsNumber()
	@Transform(castToNumber())
	public reported_cost: number = null;

	@IsNumber()
	@Transform(castToNumber())
	public cost: number = null;

	@IsString() public notes: string = '';

	@IsBoolean()
	@Transform(castToBoolean())
	public reported: boolean = true;

	@IsBoolean()
	@Transform(castToBoolean())
	public additional: boolean = false;

	@Type((): typeof SpendInterface => {
		return SpendInterface;
	})
	@ValidateNested()
	public spends: SpendInterface[] = [];

	@IsNumber()
	@IsOptional()
	@Transform(castToNumber(true))
	public copro_overridden_amount: number | null = null;

	@IsOptional()
	@IsString()
	public copro_last_updated_at: string | null = null;

	@IsBoolean()
	@Transform(castToBoolean())
	public copro_cost_hidden: boolean = false;

	/**
	 *
	 * @param costCalc the cost calc
	 * @param external external or no?
	 * @param computeForCoPro (optional) true if cmoputing co pro numbers, false otherwise
	 * @returns the total value used for this fixed cost in this fixed cost computation
	 */
	public totalByCostCalc(
		costCalc: CostCalc,
		external: boolean,
		computeForCoPro: boolean,
		computeNonReported: boolean
	): number {
		if (external && !computeNonReported && !this.reported) {
			return 0;
		}
		if (computeForCoPro && !isNil(this.copro_overridden_amount)) {
			return this.copro_overridden_amount;
		}
		if (external && costCalc === CostCalc.Estimated) {
			costCalc = CostCalc.Budgeted;
		}
		switch (costCalc) {
			case CostCalc.Actual:
				return +this.totalActual;
			case CostCalc.Budgeted:
				return +this.total;
			case CostCalc.Potential:
			case CostCalc.Estimated:
				return +this.estimated_cost;
			case CostCalc.Reported:
				return +this.reported_cost;
		}
	}

	public costAmountByCostCalc(costCalc: CostCalc, external: boolean, computeForCoPro: boolean): number {
		if (costCalc === CostCalc.Budgeted) {
			return this.budget;
		}

		return this.totalByCostCalc(costCalc, external, computeForCoPro, false);
	}

	public quantityByCostCalc(costCalc: CostCalc): number {
		if (costCalc === CostCalc.Budgeted) {
			return this.quantity;
		}

		return 1;
	}

	/**
	 * Calculates the difference between the budgeted cost and other cost total
	 * depending on the cost calc used, either actual or reported
	 * @param costCalc The cost calc to use
	 * @returns The difference between the budgeted cost and the other cost total
	 */
	public differenceByCostCalc(costCalc: CostCalc): number {
		switch (costCalc) {
			case CostCalc.Actual:
				return +this.totalActual - this.total;
			case CostCalc.Reported:
				if (this.reported) {
					return +this.reported_cost - this.total;
				}
		}

		return 0;
	}

	public get actualEmpty(): boolean {
		return this.actual_cost === null;
	}

	public get hasSpends(): boolean {
		return this.spends.length > 0;
	}
	public get totalSpends(): number {
		let total: number = 0;
		this.spends.forEach((item: SpendInterface): void => {
			total += Number(item.cost);
		});
		return total;
	}
	public get totalSpent(): number {
		if (this.hasSpends) {
			return Number(this.totalSpends);
		}
		return Number(this.actual_cost);
	}
	public get spendQuantity(): number {
		return this.spends.length;
	}
	public get totalActual(): number {
		if (this.hasSpends) {
			return this.totalSpends;
		}
		return this.actual_cost;
	}
	public get total(): number {
		return this.quantity * this.budget;
	}
	public get over_under(): number {
		return this.totalActual - this.total;
	}
	public get over_under_reported(): number {
		return this.reported_cost - this.total;
	}

	/**
	 * returns the total if the cost is reported, 0 otherwise
	 */
	public get reportedTotal(): number {
		if (!this.reported) {
			return 0;
		}
		return this.total;
	}
}
