import { all, ConfigOptions, create, MathExpression, MathJsInstance, Matrix } from 'mathjs';

const config: ConfigOptions = {};
// https://mathjs.org/docs/expressions/security.html
const math: Partial<MathJsInstance> = create(all, config);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const limitedEvaluate: (expr: MathExpression | Matrix, scope?: object) => any = math.evaluate;
// we capture the original divide function from math js so we can reference it over its override
const originalDivide: (x: math.Unit, y: math.Unit) => number | math.Unit = math.divide;

math.import(
	{
		import: function (): void {
			throw new Error('Function import is disabled');
		},
		createUnit: function (): void {
			throw new Error('Function createUnit is disabled');
		},
		evaluate: function (): void {
			throw new Error('Function evaluate is disabled');
		},
		parse: function (): void {
			throw new Error('Function parse is disabled');
		},
		simplify: function (): void {
			throw new Error('Function simplify is disabled');
		},
		derivative: function (): void {
			throw new Error('Function derivative is disabled');
		},
		/**
		 * We override the divide function to return 0 when the denominator is 0
		 * rather than resulting to infinity. When this happens the whole expression
		 * resolves to this rather than the division portion which is ok from a math
		 * standpoint though will cause some undesired / unexpected / confusing values
		 * for users on some custom fields calculations depending its context.
		 * With this override we turn this as follows:
		 * Before we resolved: (100 / 0) + 50 = Initity + 50 = Initity = 0
		 * Since we where explicitly turning any Initity result into 0 for the calculation
		 * Now we do: (100 / 0) + 50 = Initity + 50 = 0 + 50 = 50
		 * We just make sure to turn any division by 0 into 0 and move on with the calculation
		 * Which will result in a more meaninful value for users
		 *
		 * @param numerator The numerator of the division
		 * @param denominator The denominator of the division
		 * @returns The division result, or 0 if the denominator is 0
		 */
		divide: function (numerator: math.Unit, denominator: math.Unit): number | math.Unit {
			if (math.isZero(denominator)) {
				return 0;
			}
			return originalDivide(numerator, denominator);
		},
	},
	{ override: true }
);
