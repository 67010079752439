import { Injectable } from '@angular/core';
import { StorageService } from '@prism-frontend/services/legacy/storage.service';

type ThemeOptions = 'light' | 'dark';

const DEFAULT_THEME: ThemeOptions = 'light';

const USER_THEME_PREFERENCE_KEY: string = 'USER_THEME_PREFERNCE';
const USER_PREFERES_SYSTEM_THEME_KEY: string = 'USER_PREFERES_SYSTEM_THEME_KEY';

/**
 * control light theme/dark theme state in UI elements that matches system behavior
 *
 * the styles are controlled by media queries in CSS
 */
@Injectable({ providedIn: 'root' })
export class ThemeService {
	private _prefersSystemTheme: boolean = true;
	private _userPreferedTheme: ThemeOptions = DEFAULT_THEME;

	public constructor(private storageService: StorageService) {
		this.initializeVariables();

		// subscribe to system theme changes
		window?.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (): void => {
			this.setBodyClass();
		});
	}

	private initializeVariables(): void {
		this._userPreferedTheme = (this.storageService.getItem(USER_THEME_PREFERENCE_KEY) ||
			DEFAULT_THEME) as ThemeOptions;
		this._prefersSystemTheme = !!this.storageService.getItem(USER_PREFERES_SYSTEM_THEME_KEY);
		this.setBodyClass();
	}

	private get systemPrefersDarkTheme(): boolean {
		return window.matchMedia('(prefers-color-scheme: dark)').matches;
	}

	private get systemTheme(): ThemeOptions {
		return this.systemPrefersDarkTheme ? 'dark' : 'light';
	}

	/**
	 * set body class to the current state of the service
	 */
	private setBodyClass(): void {
		if (!document?.body) {
			return;
		}

		document.body.classList.remove('dark-theme', 'prefers-system-theme');

		if (this._prefersSystemTheme) {
			document.body.classList.add('prefers-system-theme');
			if (this.activeTheme === 'dark') {
				document.body.classList.add('dark-theme');
			}
			return;
		}

		if (this._userPreferedTheme === 'dark') {
			document.body.classList.add('dark-theme');
			return;
		}
	}

	/**
	 * the active theme being rendered in the app
	 */
	public get activeTheme(): ThemeOptions {
		if (this._prefersSystemTheme) {
			return this.systemTheme;
		}

		return this._userPreferedTheme;
	}

	public get userPrefersSystemTheme(): boolean {
		return this._prefersSystemTheme;
	}

	/**
	 * call this when toggling the user's preference for system theme on or off
	 */
	public toggleSystemThemePreference(): void {
		this._prefersSystemTheme = !this._prefersSystemTheme;

		if (this._prefersSystemTheme) {
			this.storageService.setItem(USER_PREFERES_SYSTEM_THEME_KEY, 'true');
		} else {
			this.storageService.removeItem(USER_PREFERES_SYSTEM_THEME_KEY);
		}

		this.setBodyClass();
	}

	public toggleLightDarkTheme(): void {
		this._userPreferedTheme = this._userPreferedTheme === 'light' ? 'dark' : 'light';
		this._prefersSystemTheme = false;
		this.storageService.setItem(USER_THEME_PREFERENCE_KEY, this._userPreferedTheme);
		this.setBodyClass();
	}
}
