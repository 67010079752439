export enum InvoiceType {
	Receipt = 'Receipt',
	Invoice = 'Invoice',
}

/**
 * determines if the agent owes the artist, or if the artist owes the agent
 * based on the total of the balance payment.
 *
 * if balanceTotal > 0: agent owes the artist
 * if balanceTotal < 0: artist owes the agent
 *
 * @param balanceTotal the balanceTotal from a given invoice or receipt
 * @returns true if the agent owes the artist, false otherwise
 */
export function doesAgentOwe(balanceTotal: number): boolean {
	return balanceTotal >= 0;
}

/**
 * determines if a given total balance from is an invoice or receipt
 *
 * @param balanceTotal the balanceTotal from a given invoice or receipt
 * @returns 'receipt' if the agent owes the artist, 'invoice' otherwise
 */
export function invoiceOrReceipt(balanceTotal: number): InvoiceType {
	return doesAgentOwe(balanceTotal) ? InvoiceType.Receipt : InvoiceType.Invoice;
}
