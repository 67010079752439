import { FlatTicketRevenue } from '@prism-frontend/typedefs/FlatTicketRevenue';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

interface FlatTicketRevenueMutationVariables {
	name: string;
	potential_gross: number;
	actual_gross: number;
}

export type CreateFlatTicketRevenueMutationVariables = FlatTicketRevenueMutationVariables & {
	event_id: number;
};

export type UpdateFlatTicketRevenueMutationVariables = FlatTicketRevenueMutationVariables & {
	id: number;
};

export interface DeleteFlatTicketRevenueMutationVariables {
	id: number;
}

export interface CreateFlatTicketRevenueMutationResponse {
	createFlatTicketRevenue: FlatTicketRevenue;
}

export interface UpdateFlatTicketRevenueMutationResponse {
	updateFlatTicketRevenue: FlatTicketRevenue;
}

// eslint-disable-next-line
interface DeleteFlatTicketRevenueMutationResponse {
	deleteFlatTicketRevenue: number;
}

const flatTicketRevenueMutationArguments: string = `
    $name: String!,
    $potential_gross: Float!,
    $actual_gross: Float!
`;

const flatTicketRevenueMutationArgumentMapping: string = `
    name: $name,
    potential_gross: $potential_gross,
    actual_gross: $actual_gross
`;

export const FlatTicketRevenuePropsFragment: DocumentNode = gql`
	fragment FlatTicketRevenueProps on FlatTicketRevenue {
		id
		event_id
		name
		created_at
		updated_at
		potential_gross
		actual_gross
	}
`;

export const CreateFlatTicketRevenueMutation: DocumentNode = gql`
    mutation createFlatTicketRevenue(
        $event_id: Int!,
        ${flatTicketRevenueMutationArguments}
    ) {
        createFlatTicketRevenue(
            event_id: $event_id
            ${flatTicketRevenueMutationArgumentMapping}
        ) {
            ...FlatTicketRevenueProps
        }
    }
    ${FlatTicketRevenuePropsFragment}
`;

export const UpdateFlatTicketRevenueMutation: DocumentNode = gql`
    mutation updateFlatTicketRevenue(
        $id: Int!,
        ${flatTicketRevenueMutationArguments}
    ) {
        updateFlatTicketRevenue(
            id: $id
            ${flatTicketRevenueMutationArgumentMapping}
        ) {
            ...FlatTicketRevenueProps
        }
    }
    ${FlatTicketRevenuePropsFragment}
`;

export const DeleteFlatTicketRevenueMutation: DocumentNode = gql`
	mutation DeleteFlatTicketRevenueMutation($id: Int!) {
		deleteFlatTicketRevenue(id: $id)
	}
`;
