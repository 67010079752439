import { DocumentNode, gql } from '@apollo/client/core';
import { Deposit } from '@prism-frontend/typedefs/deposit';

export const FullDepositFragment: DocumentNode = gql`
	fragment FullDepositFragment on Deposit {
		id
		event_talent_id
		event_promoter_id
		amount
		flat_deposit
		status
		deposit_days
		use_specific_date
		specific_due_date
		notes
		collected_by
		paid_date
		paid_amount
	}
`;

export const UpdateDepositStatusMutation: DocumentNode = gql`
	mutation UpdateDeposit(
		$id: Int!
		$status: String
		$notes: String
		$collected_by: String
		$paid_date: String
		$paid_amount: Float
	) {
		UpdateDeposit(
			id: $id
			status: $status
			notes: $notes
			collected_by: $collected_by
			paid_date: $paid_date
			paid_amount: $paid_amount
		) {
			...FullDepositFragment
		}
	}
	${FullDepositFragment}
`;

export type UpdateDepositStatusArguments = Pick<
	Deposit,
	'id' | 'notes' | 'status' | 'paid_amount' | 'collected_by' | 'paid_date'
>;
