enum AllPaymentStatuses {
	UNTRACKED = 'Untracked',
	PENDING = 'Pending',
	UPCOMING = 'Upcoming',
	OVERDUE = 'Overdue',
	PAID = 'Paid',
	WAIVED = 'Waived',
	NOT_PAID = 'Not Paid',
}

/**
 * Used for payment status change events
 * Holds the new status as well as the paid amount
 * to be set for that payment, if any
 */
export interface PaymentStatusChanged {
	status: PaymentStatusBackend;
	paidAmount: number | null;
}

export enum PaymentStatusBackend {
	UNTRACKED = AllPaymentStatuses.UNTRACKED,
	PENDING = AllPaymentStatuses.PENDING,
	PAID = AllPaymentStatuses.PAID,
	WAIVED = AllPaymentStatuses.WAIVED,
}

export enum PaymentStatusFrontend {
	UNTRACKED = AllPaymentStatuses.UNTRACKED,
	UPCOMING = AllPaymentStatuses.UPCOMING,
	OVERDUE = AllPaymentStatuses.OVERDUE,
	PAID = AllPaymentStatuses.PAID,
	NOT_PAID = AllPaymentStatuses.NOT_PAID,
}

export const AllPaymentStatusFrontend: [
	PaymentStatusFrontend,
	PaymentStatusFrontend,
	PaymentStatusFrontend,
	PaymentStatusFrontend,
	PaymentStatusFrontend
] = [
	PaymentStatusFrontend.UNTRACKED,
	PaymentStatusFrontend.UPCOMING,
	PaymentStatusFrontend.OVERDUE,
	PaymentStatusFrontend.PAID,
	PaymentStatusFrontend.NOT_PAID,
];

export enum PaymentType {
	Deposit = 'deposit',
	Settlement = 'settlement',
	Withholding = 'withholding',
}

export const PaymentTypeLabel: Record<PaymentType, string> = {
	[PaymentType.Deposit]: 'Deposit',
	[PaymentType.Settlement]: 'Settlement',
	[PaymentType.Withholding]: 'Withholding',
};

export const AllPaymentTypes: PaymentType[] = [PaymentType.Deposit, PaymentType.Settlement, PaymentType.Withholding];
