import { Injectable } from '@angular/core';
import { ApiService, instantiateApiResponse } from '@prism-frontend/services/legacy/api.service';
import { PrismAnalyticsService } from '@prism-frontend/services/utils/prism-analytics.service';
import { DesiredHoldType } from '@prism-frontend/typedefs/DesiredHoldType';
import {
	CreateEventApiResponse,
	EventsCreatePostData,
	UpdateEventHoldsPayload,
} from '@prism-frontend/typedefs/EventsCreatePostData';
import { HoldForCalendar } from '@prism-frontend/typedefs/hold-for-calendar';
import { InstantiatedAPIResponseAndValidationResults } from '@prism-frontend/utils/decorators/validate-children';
import _ from 'lodash';

@Injectable({
	providedIn: 'root',
})
export class ManageHoldsService {
	public constructor(private apiService: ApiService, private prismAnalyticsService: PrismAnalyticsService) {}

	public static desiredHoldTypeFe2Be(
		desiredHoldType: DesiredHoldType,
		preferH1: boolean
	): {
		desired_hold_type: 'next-available' | 'best-available' | 'confirmed';
		add_partnership_holds: boolean;
	} {
		switch (desiredHoldType) {
			case 'request':
				return {
					desired_hold_type: preferH1 ? 'best-available' : 'next-available',
					add_partnership_holds: true,
				};
			case 'confirmed':
				return {
					desired_hold_type: 'confirmed',
					add_partnership_holds: false,
				};
			case 'next-available':
				return {
					desired_hold_type: 'next-available',
					add_partnership_holds: false,
				};
			case 'best-available':
				return {
					desired_hold_type: 'best-available',
					add_partnership_holds: false,
				};
		}
	}

	public createEvent(
		isSharedEventRequest: boolean,
		postData: EventsCreatePostData
	): Promise<CreateEventApiResponse | null> {
		return this.apiService
			.postP(this.apiService.ep.EVENTS_CREATE, postData)
			.then((response: CreateEventApiResponse): CreateEventApiResponse => {
				this.prismAnalyticsService.fireEvent({
					eventName: 'create-event',
					isShared: isSharedEventRequest,
					dateCount: _.has(postData, 'dates') ? postData['dates'].length : null,
					holdCount: _.has(postData, 'holds') ? postData['holds'].length : null,
					dates_as_single_events: postData.dates_as_single_events,
					desired_hold_type: postData.desired_hold_type,
					hasTalent: !_.isNil(postData.talent_id),
					hasTemplate: !_.isNil(postData.show_template_id),
					isForTour: !_.isNil(postData.event.tour_id),
					outside_promoter: postData.outside_promoter,
					should_send_confirmation_email: postData.should_send_confirmation_email,
				});
				return response;
			})
			.catch((): null => {
				this.prismAnalyticsService.fireEvent({
					eventName: 'create-event-error',
				});
				return null;
			});
	}

	public updateHoldsForEvent(
		isSharedEventRequest: boolean,
		eventId: number,
		payload: UpdateEventHoldsPayload
	): Promise<CreateEventApiResponse | null> {
		return this.apiService
			.putP([this.apiService.ep.EVENTS_HOLDS_UPDATE, eventId], payload)
			.then((response: CreateEventApiResponse): CreateEventApiResponse => {
				this.prismAnalyticsService.fireEvent({
					eventName: 'update-holds',
					isShared: isSharedEventRequest,
					holdCount: payload.holds.length,
					isForTour: !_.isNil(payload.tour_id),
				});
				return response;
			})
			.catch((): null => {
				this.prismAnalyticsService.fireEvent({
					eventName: 'update-holds-error',
				});
				return null;
			});
	}

	public loadHoldsForEvent(eventId: number): Promise<HoldForCalendar[]> {
		return this.apiService
			.getP([this.apiService.ep.EVENTS_HOLDS, eventId])
			.then((results: object[]): Promise<HoldForCalendar[]> => {
				return instantiateApiResponse<HoldForCalendar[]>(HoldForCalendar, results).then(
					(response: InstantiatedAPIResponseAndValidationResults<HoldForCalendar[]>): HoldForCalendar[] => {
						return response.data;
					}
				);
			});
	}
}
