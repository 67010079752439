import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserTokenStorageService } from '@prism-frontend/services/legacy/user-token-storage.service';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor<T> implements HttpInterceptor {
	public constructor(public userTokenStorageService: UserTokenStorageService) {}

	public intercept(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
		// Default App headers
		const headers: { [key: string]: string } = {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...this.userTokenStorageService.authHeaders(),
		};

		// Add headers to request
		request = request.clone({
			setHeaders: headers,
		});

		return next.handle(request);
	}
}
